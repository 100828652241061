import { jsxs, jsx, Fragment } from "react/jsx-runtime";
import SvgIcon from "@mui/material/SvgIcon";
import { getCSSColor, useSelection, IconOverlay, useMultiToast, SelectionContext, DRAG_SOURCES_TYPE, useDeletionConfirmationDialog } from "@eclipse-sirius/sirius-components-core";
import { getTextDecorationLineValue, useClickHandler, PropertySectionLabel } from "@eclipse-sirius/sirius-components-forms";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState, useRef, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TreeView, splitText } from "@eclipse-sirius/sirius-components-trees";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
const ReferenceIcon = (props) => {
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: [
        /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("rect", { fill: "none", height: "24", width: "24" }) }),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsx("polygon", { points: "17,17 22,12 17,7 15.59,8.41 18.17,11 9,11 9,13 18.17,13 15.59,15.59" }),
          /* @__PURE__ */ jsx("path", { d: "M19,19H5V5h14v2h2V5c0-1.1-0.89-2-2-2H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.11,0,2-0.9,2-2v-2h-2V19z" })
        ] })
      ]
    }
  );
};
const isReferenceWidget$1 = (widget) => widget.__typename === "ReferenceWidget";
const useStyles$4 = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    referenceValueStyle: {
      color: color ? getCSSColor(color, theme) : void 0,
      fontSize: fontSize ? fontSize : void 0,
      fontStyle: italic ? "italic" : "unset",
      fontWeight: bold ? "bold" : "unset",
      textDecorationLine: getTextDecorationLineValue(underline ?? null, strikeThrough ?? null)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    propertySection: {
      overflowX: "hidden"
    }
  })
);
const ReferencePreview = ({ widget }) => {
  let style = null;
  if (isReferenceWidget$1(widget)) {
    const referenceWidget = widget;
    style = referenceWidget.style;
  }
  const props = {
    color: (style == null ? void 0 : style.color) ?? null,
    fontSize: (style == null ? void 0 : style.fontSize) ?? null,
    italic: (style == null ? void 0 : style.italic) ?? null,
    bold: (style == null ? void 0 : style.bold) ?? null,
    underline: (style == null ? void 0 : style.underline) ?? null,
    strikeThrough: (style == null ? void 0 : style.strikeThrough) ?? null
  };
  const { classes } = useStyles$4(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  if (!isReferenceWidget$1(widget)) {
    return null;
  }
  const options = [{ label: "Referenced Value", iconURL: "/api/images/icons/full/obj16/Entity.svg" }];
  return /* @__PURE__ */ jsxs("div", { className: classes.propertySection, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      Autocomplete,
      {
        "data-testid": widget.label,
        multiple: true,
        filterSelectedOptions: true,
        disabled: false,
        open: false,
        loading: false,
        options,
        value: options,
        disableClearable: true,
        renderTags: (value, getTagProps) => value.map(({ label, iconURL }, index) => /* @__PURE__ */ jsx(
          Chip,
          {
            classes: { label: classes.referenceValueStyle },
            label,
            "data-testid": `reference-value-${label}`,
            icon: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(IconOverlay, { iconURL: [iconURL], alt: "" }) }),
            ...getTagProps({ index })
          }
        )),
        renderInput: (params) => {
          var _a;
          return /* @__PURE__ */ jsx(
            TextField,
            {
              inputRef: ref,
              ...params,
              variant: "standard",
              error: widget.diagnostics.length > 0,
              helperText: (_a = widget.diagnostics[0]) == null ? void 0 : _a.message,
              InputProps: {
                readOnly: true,
                ...params.InputProps,
                endAdornment: /* @__PURE__ */ jsxs(Fragment, { children: [
                  params.InputProps.endAdornment,
                  /* @__PURE__ */ jsxs(InputAdornment, { position: "end", children: [
                    /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        "aria-label": "edit",
                        size: "small",
                        title: "Edit",
                        disabled: false,
                        "data-testid": `${widget.label}-more`,
                        children: /* @__PURE__ */ jsx(MoreHorizIcon, {})
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        "aria-label": "add",
                        size: "small",
                        title: "Create an object",
                        disabled: false,
                        "data-testid": `${widget.label}-add`,
                        children: /* @__PURE__ */ jsx(AddIcon, {})
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      IconButton,
                      {
                        "aria-label": "clear",
                        size: "small",
                        title: "Clear",
                        disabled: false,
                        "data-testid": `${widget.label}-clear`,
                        children: /* @__PURE__ */ jsx(DeleteIcon, {})
                      }
                    )
                  ] })
                ] })
              }
            }
          );
        }
      }
    )
  ] });
};
const useStyles$3 = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    optionLabel: {
      paddingLeft: theme.spacing(0.5)
    },
    referenceValueStyle: {
      color: color ? getCSSColor(color, theme) : void 0,
      fontSize: fontSize ? fontSize : void 0,
      fontStyle: italic ? "italic" : "unset",
      fontWeight: bold ? "bold" : "unset",
      textDecorationLine: getTextDecorationLineValue(underline ?? null, strikeThrough ?? null)
    },
    endAdornmentButton: {
      position: "absolute",
      display: "flex",
      right: theme.spacing(2.5),
      "& > *": {
        padding: 0
      }
    }
  })
);
const getReferenceValueOptionsQuery$1 = gql`
  query getReferenceValueOptions($editingContextId: ID!, $representationId: ID!, $referenceWidgetId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $representationId) {
          description {
            ... on FormDescription {
              referenceValueOptions(referenceWidgetId: $referenceWidgetId) {
                id
                label
                kind
                iconURL
              }
            }
          }
        }
      }
    }
  }
`;
const isFormDescription$1 = (representationDescription) => representationDescription.__typename === "FormDescription";
const ValuedReferenceAutocomplete = ({
  editingContextId,
  formId,
  widget,
  readOnly,
  onDragEnter,
  onDragOver,
  onDrop,
  onMoreClick,
  onCreateClick,
  optionClickHandler,
  clearReference,
  removeReferenceValue,
  addReferenceValues,
  setReferenceValue
}) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null,
    fontSize: ((_b = widget.style) == null ? void 0 : _b.fontSize) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null,
    underline: ((_e = widget.style) == null ? void 0 : _e.underline) ?? null,
    strikeThrough: ((_f = widget.style) == null ? void 0 : _f.strikeThrough) ?? null
  };
  const { classes } = useStyles$3(props);
  const theme = useTheme();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "view" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage } = useMultiToast();
  const [state, setState] = useState({ open: false, options: null });
  const loading = state.open && state.options === null;
  const [
    getReferenceValueOptions,
    {
      loading: childReferenceValueOptionsLoading,
      data: childReferenceValueOptionsData,
      error: childReferenceValueOptionsError
    }
  ] = useLazyQuery(
    getReferenceValueOptionsQuery$1
  );
  useEffect(() => {
    if (!childReferenceValueOptionsLoading) {
      if (childReferenceValueOptionsError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (childReferenceValueOptionsData) {
        const representationDescription = childReferenceValueOptionsData.viewer.editingContext.representation.description;
        if (isFormDescription$1(representationDescription)) {
          setState((prevState) => {
            return {
              ...prevState,
              options: representationDescription.referenceValueOptions
            };
          });
        }
      }
    }
  }, [childReferenceValueOptionsLoading, childReferenceValueOptionsData, childReferenceValueOptionsError, coreT]);
  useEffect(() => {
    if (loading) {
      getReferenceValueOptions({
        variables: {
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id
        }
      });
    }
  }, [loading]);
  const handleRemoveReferenceValue = (updatedValues) => {
    widget.referenceValues.forEach((value) => {
      if (!updatedValues.find((updateValue) => updateValue.id === value.id)) {
        removeReferenceValue(value.id);
      }
    });
  };
  const getOnlyNewValueIds = (updatedValues) => {
    var _a2;
    if (((_a2 = widget.referenceValues) == null ? void 0 : _a2.length) > 0) {
      return updatedValues.filter((updatedValue) => widget.referenceValues.some((value) => value.id !== updatedValue.id)).map((value) => value.id);
    } else {
      return updatedValues.map((value) => value.id);
    }
  };
  const handleAutocompleteChange = (_event, updatedValues, reason) => {
    if (reason === "removeOption") {
      handleRemoveReferenceValue(updatedValues);
    } else {
      const newValueIds = getOnlyNewValueIds(updatedValues);
      if (widget.reference.manyValued) {
        addReferenceValues(newValueIds);
      } else {
        setReferenceValue(newValueIds[0] ?? null);
      }
    }
  };
  useEffect(() => {
    if (!state.open) {
      setState((prevState) => {
        return {
          ...prevState,
          options: null
        };
      });
    }
  }, [widget]);
  let placeholder;
  if (widget.reference.manyValued) {
    placeholder = t("values");
  } else {
    placeholder = widget.referenceValues.length > 0 ? "" : t("value");
  }
  return /* @__PURE__ */ jsx(
    Autocomplete,
    {
      "data-testid": widget.label,
      multiple: true,
      filterSelectedOptions: true,
      disabled: readOnly || widget.readOnly,
      open: state.open,
      onOpen: () => setState((prevState) => {
        return {
          ...prevState,
          open: true
        };
      }),
      onClose: () => setState((prevState) => {
        return {
          ...prevState,
          open: false
        };
      }),
      loading,
      options: state.options || [],
      getOptionKey: (option) => option.id,
      getOptionLabel: (option) => option.label,
      value: widget.referenceValues,
      isOptionEqualToValue: (option, value) => option.id === value.id,
      onChange: handleAutocompleteChange,
      renderOption: (props2, option) => /* @__PURE__ */ jsxs("li", { ...props2, children: [
        /* @__PURE__ */ jsx(IconOverlay, { iconURL: option.iconURL, alt: option.kind }),
        /* @__PURE__ */ jsx("span", { className: classes.optionLabel, "data-testid": `option-${option.label}`, children: option.label })
      ] }),
      disableClearable: true,
      openText: t("open"),
      closeText: t("close"),
      renderTags: (value, getTagProps) => value.map((option, index) => /* @__PURE__ */ jsx(
        Chip,
        {
          classes: { label: classes.referenceValueStyle },
          label: option.label,
          "data-testid": `reference-value-${option.label}`,
          icon: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(IconOverlay, { iconURL: option.iconURL, alt: option.kind }) }),
          clickable: !readOnly && !widget.readOnly,
          onClick: () => optionClickHandler(option),
          ...getTagProps({ index })
        }
      )),
      renderInput: (params) => {
        var _a2;
        return /* @__PURE__ */ jsx(
          TextField,
          {
            ...params,
            variant: "standard",
            placeholder,
            error: widget.diagnostics.length > 0,
            helperText: (_a2 = widget.diagnostics[0]) == null ? void 0 : _a2.message,
            InputProps: {
              ...params.InputProps,
              style: { paddingRight: theme.spacing(10) },
              // Offset required to prevent values from being displayed below the buttons
              endAdornment: /* @__PURE__ */ jsxs(Fragment, { children: [
                params.InputProps.endAdornment,
                /* @__PURE__ */ jsxs(InputAdornment, { position: "end", className: classes.endAdornmentButton, children: [
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      "aria-label": "edit",
                      size: "small",
                      title: t("edit"),
                      disabled: readOnly || widget.readOnly,
                      "data-testid": `${widget.label}-more`,
                      onClick: onMoreClick,
                      children: /* @__PURE__ */ jsx(MoreHorizIcon, {})
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      "aria-label": "add",
                      size: "small",
                      title: t("createObject"),
                      disabled: readOnly || widget.readOnly,
                      "data-testid": `${widget.label}-add`,
                      onClick: onCreateClick,
                      children: /* @__PURE__ */ jsx(AddIcon, {})
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      "aria-label": "clear",
                      size: "small",
                      title: t("clear"),
                      disabled: readOnly || widget.readOnly,
                      "data-testid": `${widget.label}-clear`,
                      onClick: clearReference,
                      children: /* @__PURE__ */ jsx(DeleteIcon, {})
                    }
                  )
                ] })
              ] })
            }
          }
        );
      },
      onDragEnter,
      onDragOver,
      onDrop
    }
  );
};
const useFilterBarStyles = makeStyles()((theme) => ({
  filterbar: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden"
  },
  textfield: {
    height: theme.spacing(3),
    fontSize: theme.typography.fontSize
  },
  placeHolderIcon: {
    color: theme.palette.text.disabled
  }
}));
const ModelBrowserFilterBar = ({ onTextChange, onTextClear, text }) => {
  const { classes } = useFilterBarStyles();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "filter" });
  return /* @__PURE__ */ jsx("div", { className: classes.filterbar, children: /* @__PURE__ */ jsx(
    TextField,
    {
      variant: "standard",
      id: "filterbar-textfield",
      "data-testid": "filterbar-textfield",
      name: "filterbar-textfield",
      placeholder: t("typeToFilter"),
      spellCheck: false,
      size: "small",
      margin: "none",
      autoFocus: true,
      multiline: false,
      fullWidth: true,
      value: text,
      onChange: onTextChange,
      InputProps: {
        startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: /* @__PURE__ */ jsx(SearchIcon, { fontSize: "small", className: classes.placeHolderIcon }) }),
        endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(IconButton, { "data-testid": "filterbar-clear-button", "aria-label": "clear", size: "small", onClick: onTextClear, children: /* @__PURE__ */ jsx(ClearIcon, { fontSize: "small" }) }) }),
        className: classes.textfield
      }
    }
  ) });
};
const useTreeStyle = makeStyles()((theme) => ({
  title: {
    opacity: 0.6,
    fontSize: theme.typography.caption.fontSize
  },
  borderStyle: {
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    height: 300,
    overflow: "auto"
  }
}));
const ModelBrowserTreeView = ({
  editingContextId,
  widget,
  markedItemIds,
  enableMultiSelection,
  title,
  leafType,
  ownerKind
}) => {
  const { classes } = useTreeStyle();
  const [state, setState] = useState({ filterBarText: "" });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      ModelBrowserFilterBar,
      {
        onTextChange: (event) => setState({ filterBarText: event.target.value }),
        onTextClear: () => setState({ filterBarText: "" }),
        text: state.filterBarText
      }
    ),
    /* @__PURE__ */ jsx("span", { className: classes.title, children: title }),
    /* @__PURE__ */ jsx("div", { className: classes.borderStyle, children: /* @__PURE__ */ jsx(
      TreeView,
      {
        editingContextId,
        readOnly: true,
        treeId: `modelBrowser://${leafType}?ownerKind=${encodeURIComponent(
          ownerKind
        )}&targetType=${encodeURIComponent(widget.reference.referenceKind)}&ownerId=${widget.ownerId}&descriptionId=${encodeURIComponent(widget.descriptionId)}&isContainment=${widget.reference.containment}`,
        enableMultiSelection,
        synchronizedWithSelection: true,
        activeFilterIds: [],
        textToFilter: state.filterBarText,
        textToHighlight: state.filterBarText,
        markedItemIds,
        treeItemActionRender: (props) => /* @__PURE__ */ jsx(WidgetReferenceTreeItemAction, { ...props })
      }
    ) })
  ] });
};
const WidgetReferenceTreeItemAction = ({ onExpandAll, item, isHovered }) => {
  if (!onExpandAll || !item || !item.hasChildren || !isHovered) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    IconButton,
    {
      size: "small",
      "data-testid": "expand-all",
      title: "expand all",
      onClick: () => {
        onExpandAll(item);
      },
      children: /* @__PURE__ */ jsx(UnfoldMoreIcon, { style: { fontSize: 12 } })
    }
  );
};
const useBrowserModalStyles = makeStyles()((_) => ({
  content: {
    height: 400
  }
}));
const BrowseModal = ({ editingContextId, widget, onClose }) => {
  const { classes: styles } = useBrowserModalStyles();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "select" });
  const [browserSelection, setBrowserSelection] = useState({ entries: widget.referenceValues });
  return /* @__PURE__ */ jsx(
    SelectionContext.Provider,
    {
      value: {
        selection: browserSelection,
        setSelection: setBrowserSelection
      },
      children: /* @__PURE__ */ jsxs(
        Dialog,
        {
          open: true,
          onClose: () => onClose(null),
          "aria-labelledby": "dialog-title",
          fullWidth: true,
          "data-testid": "browse-modal",
          children: [
            /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
            /* @__PURE__ */ jsx(DialogContent, { className: styles.content, children: /* @__PURE__ */ jsx(
              ModelBrowserTreeView,
              {
                editingContextId,
                widget,
                markedItemIds: [],
                enableMultiSelection: widget.reference.manyValued,
                title: t("choices"),
                leafType: "reference",
                ownerKind: widget.reference.ownerKind
              }
            ) }),
            /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "primary",
                type: "button",
                "data-testid": "select-value",
                onClick: () => {
                  const selectedElement = (browserSelection == null ? void 0 : browserSelection.entries.length) > 0 ? browserSelection.entries[0] : null;
                  if (selectedElement) {
                    onClose(selectedElement.id);
                  }
                },
                children: t("submit")
              }
            ) })
          ]
        }
      )
    }
  );
};
const isCreateElementSuccessPayload = (payload) => {
  return payload.__typename === "CreateElementInReferenceSuccessPayload";
};
const createModalMachine = Machine(
  {
    id: "CreateModal",
    type: "parallel",
    context: {
      domains: [],
      selectedDomainId: "",
      selectedChildCreationDescriptionId: "",
      creationDescriptions: [],
      newObjectId: null,
      containerSelected: { entries: [] },
      containerId: null,
      containerKind: null
    },
    states: {
      createModal: {
        initial: "selectContainmentMode",
        states: {
          selectContainmentMode: {
            on: {
              CHANGE_CONTAINMENT_MODE: [
                {
                  cond: "isContainmentReference",
                  target: "loadingChildCreationDescription",
                  actions: "updateContainer"
                },
                {
                  target: "selectContainer"
                }
              ]
            }
          },
          selectContainer: {
            on: {
              CHANGE_CONTAINER_SELECTION: [
                {
                  actions: "updateContainerSelection",
                  cond: "isRootContainer",
                  target: "loadingDomains"
                },
                {
                  actions: "updateContainerSelection",
                  target: "loadingChildCreationDescription"
                }
              ]
            }
          },
          loadingChildCreationDescription: {
            on: {
              HANDLE_FETCHED_CHILD_CREATION_DESCRIPTIONS: [
                {
                  target: "validForChild",
                  actions: "updateChildCreationDescriptions"
                }
              ],
              CHANGE_CONTAINER_SELECTION: [
                {
                  actions: "updateContainerSelection",
                  cond: "isRootContainer",
                  target: "loadingDomains"
                },
                {
                  actions: "updateContainerSelection",
                  target: "loadingChildCreationDescription"
                }
              ]
            }
          },
          loadingDomains: {
            on: {
              HANDLE_FETCHED_DOMAINS: [
                {
                  actions: "updateDomains",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ],
              CHANGE_CONTAINER_SELECTION: [
                {
                  actions: "updateContainerSelection",
                  cond: "isRootContainer",
                  target: "loadingDomains"
                },
                {
                  actions: "updateContainerSelection",
                  target: "loadingChildCreationDescription"
                }
              ]
            }
          },
          loadingRootObjectCreationDescriptions: {
            on: {
              CHANGE_DOMAIN: [
                {
                  actions: "updateDomain"
                }
              ],
              HANDLE_FETCHED_ROOT_OBJECT_CREATION_DESCRIPTIONS: [
                {
                  target: "validForRoot",
                  actions: "updateRootChildCreationDescriptions"
                }
              ]
            }
          },
          validForChild: {
            on: {
              CHANGE_CONTAINER_SELECTION: [
                {
                  actions: "updateContainerSelection",
                  cond: "isRootContainer",
                  target: "loadingDomains"
                },
                {
                  actions: "updateContainerSelection",
                  target: "loadingChildCreationDescription"
                }
              ],
              CHANGE_CHILD_CREATION_DESCRIPTION: [
                {
                  actions: "updateChildCreationDescription"
                }
              ],
              CHANGE_DOMAIN: [
                {
                  actions: "updateDomain",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ],
              CREATE_CHILD: [
                {
                  target: "creatingChild"
                }
              ]
            }
          },
          validForRoot: {
            on: {
              CHANGE_CONTAINER_SELECTION: [
                {
                  actions: "updateContainerSelection",
                  cond: "isRootContainer",
                  target: "loadingDomains"
                },
                {
                  actions: "updateContainerSelection",
                  target: "loadingChildCreationDescription"
                }
              ],
              CHANGE_CHILD_CREATION_DESCRIPTION: [
                {
                  actions: "updateChildCreationDescription"
                }
              ],
              CHANGE_DOMAIN: [
                {
                  actions: "updateDomain",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ],
              CREATE_ROOT: [
                {
                  target: "creatingRoot"
                }
              ]
            }
          },
          creatingChild: {
            on: {
              HANDLE_CREATE_ELEMENT_RESPONSE: [
                {
                  cond: "isResponseCreateElementSuccessful",
                  target: "success",
                  actions: "updateNewElementId"
                },
                {
                  target: "validForChild"
                }
              ]
            }
          },
          creatingRoot: {
            on: {
              HANDLE_CREATE_ELEMENT_RESPONSE: [
                {
                  cond: "isResponseCreateElementSuccessful",
                  target: "success",
                  actions: "updateNewElementId"
                },
                {
                  target: "validForRoot"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isResponseCreateElementSuccessful: (_, event) => {
        const { data } = event;
        return data.createElementInReference.__typename === "CreateElementInReferenceSuccessPayload";
      },
      isContainmentReference: (_, event) => {
        const { containment } = event;
        return containment;
      },
      isRootContainer: (_, event) => {
        var _a;
        const { container } = event;
        return ((_a = container.entries[0]) == null ? void 0 : _a.kind) === "siriusWeb://document";
      }
    },
    actions: {
      updateDomains: assign((_, event) => {
        var _a;
        const { data } = event;
        const { domains } = data.viewer.editingContext;
        const selectedDomainId = ((_a = domains[0]) == null ? void 0 : _a.id) || "";
        return { domains, selectedDomainId };
      }),
      updateDomain: assign((_, event) => {
        const { domainId } = event;
        return { selectedDomainId: domainId };
      }),
      updateChildCreationDescriptions: assign((_, event) => {
        var _a;
        const { data } = event;
        const { referenceWidgetChildCreationDescriptions } = data.viewer.editingContext;
        const selectedChildCreationDescriptionId = ((_a = referenceWidgetChildCreationDescriptions[0]) == null ? void 0 : _a.id) || "";
        return { creationDescriptions: referenceWidgetChildCreationDescriptions, selectedChildCreationDescriptionId };
      }),
      updateRootChildCreationDescriptions: assign((_, event) => {
        var _a;
        const { data } = event;
        const { referenceWidgetRootCreationDescriptions } = data.viewer.editingContext;
        const selectedChildCreationDescriptionId = ((_a = referenceWidgetRootCreationDescriptions[0]) == null ? void 0 : _a.id) || "";
        return { creationDescriptions: referenceWidgetRootCreationDescriptions, selectedChildCreationDescriptionId };
      }),
      updateChildCreationDescription: assign((_, event) => {
        const { childCreationDescriptionId } = event;
        return { selectedChildCreationDescriptionId: childCreationDescriptionId };
      }),
      updateContainer: assign((_, event) => {
        const { containerKind, containerId } = event;
        return {
          containerId,
          containerKind
        };
      }),
      updateContainerSelection: assign((_, event) => {
        var _a, _b;
        const { container } = event;
        return {
          containerSelected: container,
          containerId: (_a = container.entries[0]) == null ? void 0 : _a.id,
          containerKind: (_b = container.entries[0]) == null ? void 0 : _b.kind
        };
      }),
      updateNewElementId: assign((_, event) => {
        const { data } = event;
        if (isCreateElementSuccessPayload(data.createElementInReference)) {
          const { object } = data.createElementInReference;
          return { newObjectId: object.id };
        }
        return {};
      })
    }
  }
);
const useStyle = makeStyles()((theme) => ({
  title: {
    opacity: 0.6,
    fontSize: theme.typography.caption.fontSize
  },
  select: {
    "&": {
      display: "flex",
      alignItems: "center"
    }
  },
  iconRoot: {
    minWidth: theme.spacing(3)
  }
}));
const createElementInReferenceMutation = gql`
  mutation createElementInReference($input: CreateElementInReferenceInput!) {
    createElementInReference(input: $input) {
      __typename
      ... on CreateElementInReferenceSuccessPayload {
        object {
          id
          label
          kind
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const getChildCreationDescriptionsQuery = gql`
  query getChildCreationDescriptions(
    $editingContextId: ID!
    $kind: ID!
    $referenceKind: String
    $descriptionId: String!
  ) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        referenceWidgetChildCreationDescriptions(
          kind: $kind
          referenceKind: $referenceKind
          descriptionId: $descriptionId
        ) {
          id
          label
          iconURL
        }
      }
    }
  }
`;
const getRootObjectCreationDescriptionsQuery = gql`
  query getRootObjectCreationDescriptions(
    $editingContextId: ID!
    $domainId: ID!
    $referenceKind: String
    $descriptionId: String!
  ) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        referenceWidgetRootCreationDescriptions(
          domainId: $domainId
          referenceKind: $referenceKind
          descriptionId: $descriptionId
        ) {
          id
          label
          iconURL
        }
      }
    }
  }
`;
const getDomainsQuery = gql`
  query getDomains($editingContextId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        domains(rootDomainsOnly: false) {
          id
          label
        }
      }
    }
  }
`;
const isErrorPayload$1 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$1 = (payload) => payload.__typename === "CreateElementInReferenceSuccessPayload";
const CreateModal = ({ editingContextId, widget, onClose, formId }) => {
  const { classes } = useStyle();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "create" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const [{ value, context }, dispatch] = useMachine(createModalMachine);
  const { createModal } = value;
  const {
    domains,
    selectedChildCreationDescriptionId,
    creationDescriptions,
    newObjectId,
    containerSelected,
    containerId,
    containerKind,
    selectedDomainId
  } = context;
  useEffect(() => {
    const changeContainmentModeEvent = {
      containment: widget.reference.containment,
      containerKind: widget.reference.containment ? widget.reference.ownerKind : null,
      containerId: widget.reference.containment ? widget.ownerId : null,
      type: "CHANGE_CONTAINMENT_MODE"
    };
    dispatch(changeContainmentModeEvent);
  }, []);
  const [getDomains, { loading: domainsLoading, data: domainsData, error: domainsError }] = useLazyQuery(getDomainsQuery, {
    variables: { editingContextId }
  });
  useEffect(() => {
    if (!domainsLoading) {
      if (domainsError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (domainsData) {
        const fetchDomainsEvent = {
          type: "HANDLE_FETCHED_DOMAINS",
          data: domainsData
        };
        dispatch(fetchDomainsEvent);
      }
    }
  }, [coreT, domainsLoading, domainsData, domainsError]);
  const [
    getRootObjectCreationDescriptions,
    { loading: descriptionsLoading, data: descriptionsData, error: descriptionError }
  ] = useLazyQuery(
    getRootObjectCreationDescriptionsQuery
  );
  useEffect(() => {
    if (!descriptionsLoading) {
      if (descriptionError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (descriptionsData) {
        const fetchDescriptionsEvent = {
          type: "HANDLE_FETCHED_ROOT_OBJECT_CREATION_DESCRIPTIONS",
          data: descriptionsData
        };
        dispatch(fetchDescriptionsEvent);
      }
    }
  }, [coreT, descriptionsLoading, descriptionsData, descriptionError]);
  const [
    getChildCreationDescription,
    {
      loading: childCreationDescriptionsLoading,
      data: childCreationDescriptionsData,
      error: childCreationDescriptionsError
    }
  ] = useLazyQuery(
    getChildCreationDescriptionsQuery
  );
  useEffect(() => {
    if (!childCreationDescriptionsLoading) {
      if (childCreationDescriptionsError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (childCreationDescriptionsData) {
        const fetchChildCreationDescriptionsEvent = {
          type: "HANDLE_FETCHED_CHILD_CREATION_DESCRIPTIONS",
          data: childCreationDescriptionsData
        };
        dispatch(fetchChildCreationDescriptionsEvent);
      }
    }
  }, [childCreationDescriptionsLoading, childCreationDescriptionsData, childCreationDescriptionsError, coreT]);
  const [
    createElementInReference,
    { loading: createElementLoading, error: createElementError, data: createElementData }
  ] = useMutation(
    createElementInReferenceMutation
  );
  useEffect(() => {
    if (!createElementLoading) {
      if (createElementError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (createElementData) {
        const handleResponseEvent = {
          type: "HANDLE_CREATE_ELEMENT_RESPONSE",
          data: createElementData
        };
        dispatch(handleResponseEvent);
        const { createElementInReference: createElementInReference2 } = createElementData;
        if (isErrorPayload$1(createElementInReference2) || isSuccessPayload$1(createElementInReference2)) {
          const { messages } = createElementInReference2;
          addMessages(messages);
        }
      }
    }
  }, [coreT, createElementLoading, createElementData, createElementError]);
  const onCreateObject = () => {
    let input = null;
    if (containerId) {
      if (createModal === "validForChild") {
        dispatch({ type: "CREATE_CHILD" });
        input = {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id,
          containerId,
          domainId: null,
          creationDescriptionId: selectedChildCreationDescriptionId,
          descriptionId: widget.descriptionId
        };
      } else if (createModal === "validForRoot") {
        dispatch({ type: "CREATE_ROOT" });
        input = {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id,
          containerId,
          domainId: selectedDomainId,
          creationDescriptionId: selectedChildCreationDescriptionId,
          descriptionId: widget.descriptionId
        };
      }
    }
    if (input) {
      createElementInReference({ variables: { input } });
    }
  };
  const onDomainChange = (event) => {
    const { value: value2 } = event.target;
    const changeDomainEvent = { type: "CHANGE_DOMAIN", domainId: value2 };
    dispatch(changeDomainEvent);
  };
  const onChildCreationDescriptionChange = (event) => {
    const value2 = event.target.value;
    const changeChildCreationDescriptionEvent = {
      type: "CHANGE_CHILD_CREATION_DESCRIPTION",
      childCreationDescriptionId: value2
    };
    dispatch(changeChildCreationDescriptionEvent);
  };
  useEffect(() => {
    if (createModal === "loadingChildCreationDescription" && containerKind) {
      getChildCreationDescription({
        variables: {
          editingContextId,
          kind: containerKind,
          referenceKind: widget.reference.referenceKind,
          descriptionId: widget.descriptionId
        }
      });
    }
    if (createModal === "loadingDomains") {
      getDomains({ variables: { editingContextId } });
    }
    if (createModal === "loadingRootObjectCreationDescriptions" && selectedDomainId) {
      getRootObjectCreationDescriptions({
        variables: {
          editingContextId,
          domainId: selectedDomainId,
          referenceKind: widget.reference.referenceKind,
          descriptionId: widget.descriptionId
        }
      });
    }
    if (createModal === "success") {
      onClose(newObjectId);
    }
  }, [createModal]);
  const onBrowserSelection = (selection) => {
    const changeContainerSelectionEvent = {
      type: "CHANGE_CONTAINER_SELECTION",
      container: selection
    };
    dispatch(changeContainerSelectionEvent);
  };
  return /* @__PURE__ */ jsx(
    SelectionContext.Provider,
    {
      value: {
        selection: containerSelected,
        setSelection: onBrowserSelection
      },
      children: /* @__PURE__ */ jsxs(
        Dialog,
        {
          open: true,
          onClose: () => onClose(null),
          "aria-labelledby": "dialog-title",
          fullWidth: true,
          "data-testid": "create-modal",
          children: [
            /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
            /* @__PURE__ */ jsxs(DialogContent, { children: [
              widget.reference.containment ? null : /* @__PURE__ */ jsx(
                ModelBrowserTreeView,
                {
                  editingContextId,
                  widget,
                  markedItemIds: [],
                  enableMultiSelection: false,
                  title: t("container.label"),
                  leafType: "container",
                  ownerKind: widget.reference.referenceKind
                }
              ),
              containerKind === "siriusWeb://document" && /* @__PURE__ */ jsxs(Fragment, { children: [
                /* @__PURE__ */ jsx("span", { className: classes.title, children: t("domain.label") }),
                /* @__PURE__ */ jsx(
                  Select,
                  {
                    variant: "standard",
                    value: selectedDomainId,
                    onChange: onDomainChange,
                    disabled: createModal === "loadingDomains" || createModal === "creatingChild",
                    labelId: "createModalChildCreationDescriptionLabel",
                    fullWidth: true,
                    "data-testid": "domain",
                    children: domains.map((domain) => /* @__PURE__ */ jsx(MenuItem, { value: domain.id, children: domain.label }, domain.id))
                  }
                )
              ] }),
              /* @__PURE__ */ jsx("span", { className: classes.title, children: t("type.label") }),
              /* @__PURE__ */ jsx(
                Select,
                {
                  variant: "standard",
                  classes: { select: classes.select },
                  value: selectedChildCreationDescriptionId,
                  onChange: onChildCreationDescriptionChange,
                  disabled: createModal !== "validForChild" && createModal !== "validForRoot",
                  labelId: "createModalChildCreationDescriptionLabel",
                  fullWidth: true,
                  "data-testid": "childCreationDescription",
                  children: creationDescriptions.map((creationDescription) => /* @__PURE__ */ jsxs(MenuItem, { value: creationDescription.id, children: [
                    creationDescription.iconURL.length > 0 && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.iconRoot, children: /* @__PURE__ */ jsx(IconOverlay, { iconURL: creationDescription.iconURL, alt: creationDescription.label }) }),
                    /* @__PURE__ */ jsx(ListItemText, { primary: creationDescription.label })
                  ] }, creationDescription.id))
                }
              )
            ] }),
            /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "primary",
                type: "button",
                "data-testid": "create-object",
                onClick: onCreateObject,
                disabled: createModal !== "validForChild" && createModal !== "validForRoot",
                children: t("submit")
              }
            ) })
          ]
        }
      )
    }
  );
};
const useStyles$2 = makeStyles()((theme) => ({
  selectable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  selected: {
    cursor: "pointer",
    backgroundColor: theme.palette.action.selected
  },
  selectedLabel: {
    fontWeight: "bold"
  },
  title: {
    opacity: 0.6,
    fontSize: theme.typography.caption.fontSize
  },
  borderStyle: {
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    height: 300,
    overflow: "auto"
  },
  dragIcon: {
    display: "block"
  },
  noDragIcon: {
    display: "none"
  }
}));
const useLabelStyles = makeStyles()((theme) => ({
  highlight: {
    backgroundColor: theme.palette.navigation.leftBackground
  }
}));
const HighlightedLabel = ({ label, textToHighlight }) => {
  var _a;
  const { classes } = useLabelStyles();
  let itemLabel;
  const splitLabelWithTextToHighlight = splitText(label, textToHighlight);
  if (textToHighlight === null || textToHighlight === "" || splitLabelWithTextToHighlight.length === 1 && ((_a = splitLabelWithTextToHighlight[0]) == null ? void 0 : _a.toLocaleLowerCase()) !== label.toLocaleLowerCase()) {
    itemLabel = /* @__PURE__ */ jsx(Fragment, { children: label });
  } else {
    const languages = Array.from(navigator.languages);
    itemLabel = /* @__PURE__ */ jsx(Fragment, { children: splitLabelWithTextToHighlight.map((value, index) => {
      const shouldHighlight = value.localeCompare(textToHighlight, languages, { sensitivity: "base" }) === 0;
      return /* @__PURE__ */ jsx(
        "span",
        {
          "data-testid": `${label}-${value}-${index}`,
          className: shouldHighlight ? classes.highlight : "",
          children: value
        },
        value + index
      );
    }) });
  }
  return /* @__PURE__ */ jsx(Typography, { children: itemLabel });
};
const FilterableSortableList = ({
  items,
  options,
  setItems,
  handleDragItemStart,
  handleDragItemEnd,
  handleDropNewItem,
  onClick,
  selectedItems,
  moveElement
}) => {
  const { classes } = useStyles$2();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "edit" });
  const [state, setState] = useState({
    filterBarText: "",
    hoveringItemId: void 0,
    draggingItemId: void 0,
    draggingStartIndex: -1,
    draggingIndex: -1
  });
  const handleMouseEnter = (id) => {
    setState((prevState) => {
      return {
        ...prevState,
        hoveringItemId: id
      };
    });
  };
  const handleMouseLeave = () => {
    setState((prevState) => {
      return {
        ...prevState,
        hoveringItemId: void 0
      };
    });
  };
  const handleDragOverNewItem = (event) => {
    event.preventDefault();
  };
  const handleDragStartOrder = (id, index) => {
    setState((prevState) => {
      return {
        ...prevState,
        draggingItemId: id,
        draggingStartIndex: index
      };
    });
  };
  const handleDragEndOrder = () => {
    if (state.draggingItemId) {
      moveElement(state.draggingItemId, state.draggingStartIndex, state.draggingIndex);
    }
    setState((prevState) => {
      return {
        ...prevState,
        draggingItemId: void 0
      };
    });
  };
  const handleDragOverOrder = (event, index) => {
    event.preventDefault();
    if (state.draggingItemId) {
      const newList = Array.from(items);
      const draggedItem = newList.find((item) => item.id === state.draggingItemId);
      if (draggedItem) {
        const draggedItemIndex = newList.indexOf(draggedItem);
        newList.splice(draggedItemIndex, 1);
        newList.splice(index, 0, draggedItem);
        setItems(newList);
        setState((prevState) => {
          return {
            ...prevState,
            draggingIndex: index
          };
        });
      }
    }
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      ModelBrowserFilterBar,
      {
        onTextChange: (event) => setState((prevState) => {
          return {
            ...prevState,
            filterBarText: event.target.value
          };
        }),
        onTextClear: () => setState((prevState) => {
          return {
            ...prevState,
            filterBarText: ""
          };
        }),
        text: state.filterBarText
      }
    ),
    /* @__PURE__ */ jsx("span", { className: classes.title, children: t("selected") }),
    /* @__PURE__ */ jsx("div", { className: classes.borderStyle, onDrop: handleDropNewItem, onDragOver: handleDragOverNewItem, children: /* @__PURE__ */ jsx(List, { dense: true, component: "div", role: "list", "data-testid": "selected-items-list", children: items.filter(({ label }) => {
      var _a;
      if (state.filterBarText === null || state.filterBarText === "") {
        return true;
      }
      const splitLabelWithTextToHighlight = splitText(label, state.filterBarText);
      return splitLabelWithTextToHighlight.length > 1 || splitLabelWithTextToHighlight.length === 1 && ((_a = splitLabelWithTextToHighlight[0]) == null ? void 0 : _a.toLocaleLowerCase()) === state.filterBarText.toLocaleLowerCase();
    }).map(({ id, kind, label }, index) => {
      var _a;
      const iconURL = ((_a = options.find((option) => option.id === id)) == null ? void 0 : _a.iconURL) ?? [];
      const labelId = `transfer-list-item-${id}-label`;
      const selected = selectedItems.some((entry) => entry.id === id);
      const hover = state.hoveringItemId === id;
      return /* @__PURE__ */ jsxs(
        ListItem,
        {
          role: "listitem",
          className: selected ? classes.selected : classes.selectable,
          onDragOver: (event) => handleDragOverOrder(event, index),
          onDragEnd: handleDragItemEnd,
          onClick: (event) => onClick(event, { id, kind, label }),
          onMouseEnter: () => handleMouseEnter(id),
          onMouseLeave: handleMouseLeave,
          "data-testid": label,
          children: [
            /* @__PURE__ */ jsx(
              ListItemIcon,
              {
                className: hover ? classes.dragIcon : classes.noDragIcon,
                draggable: true,
                onDragStart: () => handleDragStartOrder(id, index),
                onDragEnd: handleDragEndOrder,
                children: /* @__PURE__ */ jsx(DragHandleIcon, {})
              }
            ),
            /* @__PURE__ */ jsx(ListItemIcon, { draggable: true, onDragStart: () => handleDragItemStart(id), children: /* @__PURE__ */ jsx(IconOverlay, { iconURL, alt: kind }) }),
            /* @__PURE__ */ jsx(
              ListItemText,
              {
                id: labelId,
                draggable: true,
                onDragStart: () => handleDragItemStart(id),
                primary: /* @__PURE__ */ jsx(HighlightedLabel, { label, textToHighlight: state.filterBarText }),
                classes: { primary: selected ? classes.selectedLabel : "" }
              }
            )
          ]
        },
        id
      );
    }) }) })
  ] });
};
const useStyles$1 = makeStyles()((theme) => ({
  dialogContent: {
    overflowX: "hidden"
  },
  root: {
    margin: "auto"
  },
  paper: {
    width: 400,
    height: 370,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));
const getReferenceValueOptionsQuery = gql`
  query getReferenceValueOptions($editingContextId: ID!, $representationId: ID!, $referenceWidgetId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $representationId) {
          description {
            ... on FormDescription {
              referenceValueOptions(referenceWidgetId: $referenceWidgetId) {
                id
                label
                kind
                iconURL
              }
            }
          }
        }
      }
    }
  }
`;
const isFormDescription = (representationDescription) => representationDescription.__typename === "FormDescription";
const TransferModal = ({
  editingContextId,
  formId,
  widget,
  onClose,
  addElements,
  removeElement,
  moveElement
}) => {
  const { classes } = useStyles$1();
  const { t } = useTranslation("siriusComponentsWidgetReference", { keyPrefix: "edit" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage } = useMultiToast();
  const [state, setState] = useState({
    right: widget.referenceValues,
    rightSelection: [],
    draggingRightItemId: void 0,
    leftSelection: [],
    options: []
  });
  const {
    loading: childReferenceValueOptionsLoading,
    data: childReferenceValueOptionsData,
    error: childReferenceValueOptionsError
  } = useQuery(
    getReferenceValueOptionsQuery,
    {
      variables: {
        editingContextId,
        representationId: formId,
        referenceWidgetId: widget.id
      }
    }
  );
  useEffect(() => {
    if (!childReferenceValueOptionsLoading) {
      if (childReferenceValueOptionsError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (childReferenceValueOptionsData) {
        const representationDescription = childReferenceValueOptionsData.viewer.editingContext.representation.description;
        if (isFormDescription(representationDescription)) {
          setState((prevState) => {
            return {
              ...prevState,
              options: representationDescription.referenceValueOptions
            };
          });
        }
      }
    }
  }, [childReferenceValueOptionsLoading, childReferenceValueOptionsData, childReferenceValueOptionsError, coreT]);
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        rightSelection: prevState.rightSelection.filter(
          (sel) => widget.referenceValues.map((ref) => ref.id).includes(sel.id)
        ),
        right: widget.referenceValues
      };
    });
  }, [widget.referenceValues]);
  const handleLeftSelection = (selection) => {
    setState((prevState) => {
      return {
        ...prevState,
        rightSelection: [],
        leftSelection: selection.entries
      };
    });
  };
  const handleDragOverLeft = (event) => {
    event.preventDefault();
  };
  const handleDropLeft = (event) => {
    event.preventDefault();
    if (state.draggingRightItemId) {
      removeElement(state.draggingRightItemId);
    }
  };
  const handleDragStart = (id) => {
    setState((prevState) => {
      return {
        ...prevState,
        draggingRightItemId: id
      };
    });
  };
  const handleDragEnd = () => {
    setState((prevState) => {
      return {
        ...prevState,
        draggingRightItemId: void 0
      };
    });
  };
  const handleDropRight = (event) => {
    event.preventDefault();
    const dragSourcesStringified = event.dataTransfer.getData(DRAG_SOURCES_TYPE);
    if (dragSourcesStringified) {
      const sources = JSON.parse(dragSourcesStringified);
      if (Array.isArray(sources) && sources.length > 0) {
        const entriesDragged = sources;
        const newElementIds = entriesDragged.filter((newEntry) => !state.right.some((existingEntry) => existingEntry.id === newEntry.id)).map((element) => element.id);
        addElements(newElementIds);
      }
    }
  };
  const onClick = (event, item) => {
    if (event.ctrlKey || event.metaKey) {
      event.stopPropagation();
      const isItemInSelection = state.rightSelection.find((entry) => entry.id === item.id);
      const newSelection = isItemInSelection ? state.rightSelection.filter((entry) => entry.id !== item.id) : [...state.rightSelection, item];
      setState((prevState) => {
        return {
          ...prevState,
          rightSelection: newSelection,
          leftSelection: newSelection
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          rightSelection: [item],
          leftSelection: [item]
        };
      });
    }
  };
  const handleDispatchRight = () => {
    addElements(state.leftSelection.map((element) => element.id));
  };
  const handleDispatchLeft = () => {
    state.rightSelection.forEach((element) => removeElement(element.id));
  };
  return /* @__PURE__ */ jsx(
    SelectionContext.Provider,
    {
      value: {
        selection: { entries: state.leftSelection },
        setSelection: handleLeftSelection
      },
      children: /* @__PURE__ */ jsxs(
        Dialog,
        {
          open: true,
          onClose: () => onClose(),
          "aria-labelledby": "dialog-title",
          maxWidth: false,
          "data-testid": "transfer-modal",
          children: [
            /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
            /* @__PURE__ */ jsx(DialogContent, { className: classes.dialogContent, children: /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 2, justifyContent: "center", alignItems: "center", className: classes.root, children: [
              /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx("div", { className: classes.paper, onDragOver: handleDragOverLeft, onDrop: handleDropLeft, children: /* @__PURE__ */ jsx(
                ModelBrowserTreeView,
                {
                  editingContextId,
                  widget,
                  markedItemIds: state.right.map((entry) => entry.id),
                  enableMultiSelection: widget.reference.manyValued,
                  title: t("choices"),
                  leafType: "reference",
                  ownerKind: widget.reference.ownerKind
                }
              ) }) }),
              /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [
                /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    className: classes.button,
                    onClick: handleDispatchRight,
                    disabled: !state.leftSelection.some(
                      (leftEntry) => !state.right.some((rightEntry) => rightEntry.id === leftEntry.id)
                    ),
                    "aria-label": "move selected right",
                    "data-testid": "move-right",
                    children: /* @__PURE__ */ jsx(ChevronRightIcon, {})
                  }
                ),
                /* @__PURE__ */ jsx(
                  IconButton,
                  {
                    className: classes.button,
                    onClick: handleDispatchLeft,
                    disabled: state.rightSelection.length === 0,
                    "aria-label": "move selected left",
                    "data-testid": "move-left",
                    children: /* @__PURE__ */ jsx(ChevronLeftIcon, {})
                  }
                )
              ] }) }),
              /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx("div", { className: classes.paper, children: /* @__PURE__ */ jsx(
                FilterableSortableList,
                {
                  items: state.right,
                  options: [...state.options, ...widget.referenceValues],
                  setItems: (items) => setState((prevState) => {
                    return {
                      ...prevState,
                      right: items
                    };
                  }),
                  handleDragItemStart: handleDragStart,
                  handleDragItemEnd: handleDragEnd,
                  handleDropNewItem: handleDropRight,
                  onClick,
                  selectedItems: state.rightSelection,
                  moveElement
                }
              ) }) })
            ] }) }),
            /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "primary",
                type: "button",
                "data-testid": "close-transfer-modal",
                onClick: () => onClose(),
                children: t("close")
              }
            ) })
          ]
        }
      )
    }
  );
};
const useStyles = makeStyles()(() => ({
  root: {
    overflow: "hidden"
  }
}));
const clearReferenceMutation = gql`
  mutation clearReference($input: ClearReferenceInput!) {
    clearReference(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const removeReferenceValueMutation = gql`
  mutation removeReferenceValue($input: RemoveReferenceValueInput!) {
    removeReferenceValue(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const setReferenceValueMutation = gql`
  mutation setReferenceValue($input: SetReferenceValueInput!) {
    setReferenceValue(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const addReferenceValuesMutation = gql`
  mutation addReferenceValues($input: AddReferenceValuesInput!) {
    addReferenceValues(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const moveReferenceValueMutation = gql`
  mutation moveReferenceValue($input: MoveReferenceValueInput!) {
    moveReferenceValue(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload = (payload) => payload.__typename === "SuccessPayload";
const isReferenceWidget = (widget) => widget.__typename === "ReferenceWidget";
const ReferencePropertySection = ({
  widget,
  ...props
}) => {
  if (isReferenceWidget(widget)) {
    return /* @__PURE__ */ jsx(RawReferencePropertySection, { widget, ...props });
  }
  return null;
};
const RawReferencePropertySection = ({
  editingContextId,
  formId,
  widget,
  readOnly
}) => {
  const { classes } = useStyles();
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { setSelection } = useSelection();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const [clearReference, { loading: clearLoading, error: clearError, data: clearData }] = useMutation(clearReferenceMutation);
  const [removeReferenceValue, { loading: removeLoading, error: removeError, data: removeData }] = useMutation(removeReferenceValueMutation);
  const [setReferenceValue, { loading: setLoading, error: setError, data: setData }] = useMutation(setReferenceValueMutation);
  const [addReferenceValues, { loading: addLoading, error: addError, data: addData }] = useMutation(addReferenceValuesMutation);
  const [moveReferenceValue, { loading: moveLoading, error: moveError, data: moveData }] = useMutation(moveReferenceValueMutation);
  const onReferenceValueSimpleClick = (item) => {
    const { id, kind } = item;
    setSelection({ entries: [{ id, kind }] });
  };
  const onReferenceValueDoubleClick = (item) => {
    const { id, kind } = item;
    setSelection({ entries: [{ id, kind }] });
  };
  const clickHandler = useClickHandler(onReferenceValueSimpleClick, onReferenceValueDoubleClick);
  const { addErrorMessage, addMessages } = useMultiToast();
  useEffect(() => {
    if (!clearLoading) {
      if (clearError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (clearData) {
        const { clearReference: clearReference2 } = clearData;
        if (isErrorPayload(clearReference2) || isSuccessPayload(clearReference2)) {
          addMessages(clearReference2.messages);
        }
      }
    }
  }, [clearLoading, clearError, clearData, coreT]);
  useEffect(() => {
    if (!removeLoading) {
      if (removeError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (removeData) {
        const { removeReferenceValue: removeReferenceValue2 } = removeData;
        if (isErrorPayload(removeReferenceValue2) || isSuccessPayload(removeReferenceValue2)) {
          addMessages(removeReferenceValue2.messages);
        }
      }
    }
  }, [coreT, removeLoading, removeError, removeData]);
  useEffect(() => {
    if (!setLoading) {
      if (setError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (setData) {
        const { setReferenceValue: setReferenceValue2 } = setData;
        if (isErrorPayload(setReferenceValue2) || isSuccessPayload(setReferenceValue2)) {
          addMessages(setReferenceValue2.messages);
        }
      }
    }
  }, [coreT, setLoading, setError, setData]);
  useEffect(() => {
    if (!addLoading) {
      if (addError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (addData) {
        const { addReferenceValues: addReferenceValues2 } = addData;
        if (isErrorPayload(addReferenceValues2) || isSuccessPayload(addReferenceValues2)) {
          addMessages(addReferenceValues2.messages);
        }
      }
    }
  }, [addLoading, addError, addData, coreT]);
  useEffect(() => {
    if (!moveLoading) {
      if (moveError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (moveData) {
        const { moveReferenceValue: moveReferenceValue2 } = moveData;
        if (isErrorPayload(moveReferenceValue2) || isSuccessPayload(moveReferenceValue2)) {
          addMessages(moveReferenceValue2.messages);
        }
      }
    }
  }, [coreT, moveLoading, moveError, moveData]);
  const callClearReference = () => {
    const variables = {
      input: {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: formId,
        referenceWidgetId: widget.id
      }
    };
    if (widget.reference.containment) {
      showDeletionConfirmation(() => {
        clearReference({ variables });
      });
    } else {
      clearReference({ variables });
    }
  };
  const callSetReferenceValue = (newValueId) => {
    if (newValueId) {
      const variables = {
        input: {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id,
          newValueId
        }
      };
      setReferenceValue({ variables });
    }
  };
  const callAddReferenceValues = (newValueIds) => {
    if (newValueIds) {
      const variables = {
        input: {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id,
          newValueIds
        }
      };
      addReferenceValues({ variables });
    }
  };
  const callRemoveReferenceValue = (valueId) => {
    if (valueId) {
      const variables = {
        input: {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: formId,
          referenceWidgetId: widget.id,
          referenceValueId: valueId
        }
      };
      if (widget.reference.containment) {
        showDeletionConfirmation(() => {
          removeReferenceValue({ variables });
        });
      } else {
        removeReferenceValue({ variables });
      }
    }
  };
  const callMoveReferenceValue = (valueId, fromIndex, toIndex) => {
    if (valueId && fromIndex !== -1 && toIndex !== -1) {
      if (valueId) {
        const variables = {
          input: {
            id: crypto.randomUUID(),
            editingContextId,
            representationId: formId,
            referenceWidgetId: widget.id,
            referenceValueId: valueId,
            fromIndex,
            toIndex
          }
        };
        moveReferenceValue({ variables });
      }
    }
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    if (readOnly || widget.readOnly) {
      addErrorMessage("This widget is currently read-only");
    } else {
      const dragSourcesStringified = event.dataTransfer.getData(DRAG_SOURCES_TYPE);
      if (dragSourcesStringified) {
        const sources = JSON.parse(dragSourcesStringified);
        if (Array.isArray(sources) && sources.length > 0) {
          const entries = sources;
          const semanticElementIds = entries.filter((entry) => entry.kind.startsWith("siriusComponents://semantic?")).map((entry) => entry.id);
          if (widget.reference.manyValued) {
            callAddReferenceValues([...semanticElementIds]);
          } else {
            if (semanticElementIds.length > 1) {
              addErrorMessage("Single-valued reference can only accept a single value");
            } else {
              callSetReferenceValue(semanticElementIds[0] ?? null);
            }
          }
        }
      }
    }
  };
  const [modalDisplayed, setModalDisplayed] = useState(null);
  const onBrowse = () => {
    setModalDisplayed("browse");
  };
  const onCreate = () => {
    setModalDisplayed("create");
  };
  const setSelectedElement = (selectedElementId) => {
    setModalDisplayed(null);
    if (selectedElementId && selectedElementId.length > 0) {
      callSetReferenceValue(selectedElementId);
    }
  };
  const addNewElement = (selectedElementId) => {
    setModalDisplayed(null);
    if (selectedElementId) {
      if (widget.reference.manyValued) {
        callAddReferenceValues([selectedElementId]);
      } else {
        callSetReferenceValue(selectedElementId);
      }
    }
  };
  let modal = null;
  if (modalDisplayed === "browse") {
    modal = widget.reference.manyValued ? /* @__PURE__ */ jsx(
      TransferModal,
      {
        editingContextId,
        formId,
        onClose: () => setModalDisplayed(null),
        addElements: callAddReferenceValues,
        removeElement: callRemoveReferenceValue,
        moveElement: callMoveReferenceValue,
        widget
      }
    ) : /* @__PURE__ */ jsx(BrowseModal, { editingContextId, onClose: setSelectedElement, widget });
  } else if (modalDisplayed === "create") {
    modal = /* @__PURE__ */ jsx(CreateModal, { editingContextId, onClose: addNewElement, widget, formId });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
      /* @__PURE__ */ jsx(
        PropertySectionLabel,
        {
          editingContextId,
          formId,
          widget,
          "data-testid": widget.label + "_" + widget.reference.referenceKind
        }
      ),
      /* @__PURE__ */ jsx(
        ValuedReferenceAutocomplete,
        {
          editingContextId,
          formId,
          widget,
          readOnly,
          onDragEnter: handleDragEnter,
          onDragOver: handleDragOver,
          onDrop: handleDrop,
          onMoreClick: onBrowse,
          onCreateClick: onCreate,
          optionClickHandler: clickHandler,
          clearReference: callClearReference,
          removeReferenceValue: callRemoveReferenceValue,
          addReferenceValues: callAddReferenceValues,
          setReferenceValue: callSetReferenceValue
        }
      )
    ] }),
    modal
  ] });
};
const siriusComponentsWidgetReference$1 = {
  filter: {
    typeToFilter: "Type to filter"
  },
  view: {
    clear: "Clear",
    close: "Close",
    createObject: "Create an object",
    edit: "Edit",
    open: "Open",
    value: "Value",
    values: "Values"
  },
  select: {
    title: "Select an object",
    choices: "Choices",
    submit: "Select"
  },
  create: {
    title: "Create an object",
    container: {
      label: "Select the container"
    },
    domain: {
      label: "Select the domain"
    },
    type: {
      label: "Select the object type"
    },
    submit: "Create"
  },
  edit: {
    title: "Edit reference",
    choices: "Choices",
    selected: "Selected",
    close: "Close"
  }
};
const en = {
  siriusComponentsWidgetReference: siriusComponentsWidgetReference$1
};
const siriusComponentsWidgetReference = {
  filter: {
    typeToFilter: "Фильтр"
  },
  view: {
    clear: "Очистить",
    close: "Закрыть",
    createObject: "Создать объект",
    edit: "Изменить",
    open: "Открыть",
    value: "Значение",
    values: "Значения"
  },
  select: {
    title: "Выберите объект",
    choices: "Доступные значения",
    submit: "Выбрать"
  },
  create: {
    title: "Создать объект",
    container: {
      label: "Выберите контейнер"
    },
    domain: {
      label: "Выберите предметную область"
    },
    type: {
      label: "Выберите тип объекта"
    },
    submit: "Создать"
  },
  edit: {
    title: "Изменить ссылку",
    choices: "Доступные значения",
    selected: "Выбранные значения",
    close: "Закрыть"
  }
};
const ru = {
  siriusComponentsWidgetReference
};
export {
  ReferenceIcon,
  ReferencePreview,
  ReferencePropertySection,
  addReferenceValuesMutation,
  clearReferenceMutation,
  moveReferenceValueMutation,
  removeReferenceValueMutation,
  setReferenceValueMutation,
  en as siriusComponentsWidgetReferenceEn,
  ru as siriusComponentsWidgetReferenceRu
};
