import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import { ToastContext, Toast, useMultiToast, useSelection, IconOverlay, ServerContext, ExtensionRegistry, workbenchMainAreaExtensionPoint, workbenchViewContributionExtensionPoint, representationFactoryExtensionPoint, useData, useComponent, useComponents, SelectionContextProvider, Workbench, theme, ExtensionProvider, RepresentationPathContext, ConfirmationDialogContextProvider } from "@eclipse-sirius/sirius-components-core";
import { DiagramContext, diagramPanelActionExtensionPoint, diagramDialogContributionExtensionPoint, DiagramRepresentation, NodeTypeContext } from "@eclipse-sirius/sirius-components-diagrams";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, emphasize, createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState, useRef, useContext, forwardRef, Fragment as Fragment$1, useMemo, Component, createElement } from "react";
import { Link, Redirect, useParams, useHistory, useRouteMatch, generatePath, Switch, Route, BrowserRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from "notistack";
import { DeckRepresentation } from "@eclipse-sirius/sirius-components-deck";
import { FormDescriptionEditorRepresentation } from "@eclipse-sirius/sirius-components-formdescriptioneditors";
import { formRefreshedEventPayloadFragment, FormBasedView, Group, FormContext, ListPropertySection, TreePropertySection, widgetContributionExtensionPoint, FormRepresentation } from "@eclipse-sirius/sirius-components-forms";
import { GanttRepresentation } from "@eclipse-sirius/sirius-components-gantt";
import { PortalRepresentation } from "@eclipse-sirius/sirius-components-portals";
import { treeItemContextMenuEntryExtensionPoint, ExplorerView, TreeToolBarContext, TreeToolBarContribution } from "@eclipse-sirius/sirius-components-trees";
import { gql as gql$1, useSubscription, useMutation, useLazyQuery, useQuery, DocumentTransform, HttpLink, split, InMemoryCache, ApolloClient, ApolloProvider } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "tss-react/mui";
import { useMachine } from "@xstate/react";
import { useTranslation } from "react-i18next";
import { Machine, assign } from "xstate";
import { ReferenceIcon, ReferencePreview, ReferencePropertySection } from "@eclipse-sirius/sirius-components-widget-reference";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Filter from "@mui/icons-material/Filter";
import ImageIcon from "@mui/icons-material/Image";
import LinkIcon from "@mui/icons-material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import WarningIcon from "@mui/icons-material/Warning";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import NoteAdd from "@mui/icons-material/NoteAdd";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collections from "@mui/icons-material/Collections";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Pagination from "@mui/material/Pagination";
import { parse, Kind, visit } from "graphql";
import CircularProgress from "@mui/material/CircularProgress";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import Box from "@mui/material/Box";
import DialogContentText from "@mui/material/DialogContentText";
import FormGroup from "@mui/material/FormGroup";
import { SelectionDialog } from "@eclipse-sirius/sirius-components-selection";
import { createFragmentRegistry } from "@apollo/client/cache";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import AppBar from "@mui/material/AppBar";
import Link$1 from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import SvgIcon from "@mui/material/SvgIcon";
import HelpIcon from "@mui/icons-material/Help";
import Menu from "@mui/material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import PublishIcon from "@mui/icons-material/Publish";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme: theme2 }) => ({
  "&.notistack-MuiContent-default": {
    backgroundColor: theme2.palette.primary.main
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: theme2.palette.success.main
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme2.palette.error.main
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme2.palette.warning.main
  },
  "&.notistack-MuiContent-info": {
    backgroundColor: theme2.palette.info.main
  }
}));
const ToastCloseButton = ({ toastKey }) => {
  const { closeSnackbar } = useSnackbar();
  return /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: () => closeSnackbar(toastKey), children: /* @__PURE__ */ jsx(CloseIcon, { fontSize: "small" }) });
};
const ToastContextInitializer = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  return /* @__PURE__ */ jsx(
    ToastContext.Provider,
    {
      value: {
        enqueueSnackbar
      },
      children
    }
  );
};
const ToastProvider = ({ children }) => {
  return /* @__PURE__ */ jsx(
    SnackbarProvider,
    {
      maxSnack: 5,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      action: (key) => /* @__PURE__ */ jsx(ToastCloseButton, { toastKey: key }),
      autoHideDuration: 1e4,
      "data-testid": "toast",
      Components: {
        default: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent
      },
      children: /* @__PURE__ */ jsx(ToastContextInitializer, { children })
    }
  );
};
const defaultNodeTypeRegistry = {
  nodeConverters: [],
  nodeLayoutHandlers: [],
  nodeTypeContributions: []
};
const DiagramRepresentationConfiguration = ({}) => {
  return null;
};
const isValidationRefreshedEventPayload = (payload) => payload.__typename === "ValidationRefreshedEventPayload";
const validationViewMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      validation: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      validationView: {
        initial: "idle",
        states: {
          idle: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  cond: "isValidationRefreshedEventPayload",
                  target: "ready",
                  actions: "handleSubscriptionResult"
                },
                {
                  target: "idle",
                  actions: "handleSubscriptionResult"
                }
              ]
            }
          },
          ready: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: {
                target: "ready",
                actions: "handleSubscriptionResult"
              },
              HANDLE_COMPLETE: {
                target: "complete"
              }
            }
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isValidationRefreshedEventPayload: (_, event) => {
        const { result } = event;
        const { data } = result;
        return !!data && isValidationRefreshedEventPayload(data.validationEvent);
      }
    },
    actions: {
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (data && isValidationRefreshedEventPayload(data.validationEvent)) {
          const { validation } = data.validationEvent;
          const categories = [];
          const processedValidation = { categories };
          validation.diagnostics.forEach((diagnostic) => {
            let category = categories.find((category2) => category2.kind === diagnostic.kind);
            if (!category) {
              category = {
                kind: diagnostic.kind,
                diagnostics: []
              };
              categories.push(category);
            }
            category.diagnostics.push({ id: diagnostic.id, message: diagnostic.message });
          });
          return { validation: processedValidation };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const validationEventSubscription = gql$1`
  subscription validationEvent($input: ValidationEventInput!) {
    validationEvent(input: $input) {
      __typename
      ... on ValidationRefreshedEventPayload {
        id
        validation {
          id
          diagnostics {
            id
            kind
            message
          }
        }
      }
    }
  }
`;
const useValidationViewStyle = makeStyles()((theme2) => ({
  root: {
    padding: "8px"
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    color: theme2.palette.text.secondary
  },
  accordionDetailsRoot: {
    flexDirection: "column"
  },
  divider: {
    margin: "8px 0"
  },
  idle: {
    padding: theme2.spacing(1)
  }
}));
const ValidationView = ({ editingContextId }) => {
  const { classes } = useValidationViewStyle();
  const [{ value: value2, context }, dispatch] = useMachine(validationViewMachine);
  const { toast, validationView } = value2;
  const { id, validation, message } = context;
  const { t } = useTranslation("siriusComponentsValidation");
  const { error } = useSubscription(
    validationEventSubscription,
    {
      variables: {
        input: {
          id,
          editingContextId
        }
      },
      fetchPolicy: "no-cache",
      onData: ({ data }) => {
        const handleDataEvent = {
          type: "HANDLE_SUBSCRIPTION_RESULT",
          result: data
        };
        dispatch(handleDataEvent);
      },
      onComplete: () => {
        const completeEvent = { type: "HANDLE_COMPLETE" };
        dispatch(completeEvent);
      }
    }
  );
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  let content = null;
  if (validationView === "ready" && validation) {
    const accordions = validation.categories.map((category) => {
      const details = category.diagnostics.map((diagnostic) => {
        return /* @__PURE__ */ jsx(Typography, { children: diagnostic.message }, diagnostic.id);
      }).reduce((prev, current, index) => [
        prev,
        /* @__PURE__ */ jsx(Divider, { className: classes.divider }, `Divider-${index}`),
        current
      ]);
      return /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsxs(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: [
          /* @__PURE__ */ jsx(Typography, { className: classes.heading, children: category.kind }),
          /* @__PURE__ */ jsx(Typography, { className: classes.secondaryHeading, children: t("diagnosticCount", { count: category.diagnostics.length }) })
        ] }),
        /* @__PURE__ */ jsx(AccordionDetails, { className: classes.accordionDetailsRoot, children: details })
      ] }, category.kind);
    });
    if (accordions.length > 0) {
      content = /* @__PURE__ */ jsx("div", { className: classes.root, children: accordions });
    } else {
      content = /* @__PURE__ */ jsx("div", { className: classes.idle, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: t("noDiagnostic") }) });
    }
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    content,
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: message ?? "",
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const isFormRefreshedEventPayload$3 = (payload) => payload.__typename === "FormRefreshedEventPayload";
const getDiagramFilterEventSubscription = `
  subscription diagramFilterEvent($input: DiagramFilterEventInput!) {
    diagramFilterEvent(input: $input) {
      __typename
      ... on FormRefreshedEventPayload {
        ...formRefreshedEventPayloadFragment
      }
    }
  }
  ${formRefreshedEventPayloadFragment}
  `;
const useDiagramFilterSubscription = (editingContextId, objectIds, skip) => {
  const [state, setState] = useState({
    id: crypto.randomUUID(),
    form: null,
    complete: false
  });
  const input = {
    id: state.id,
    editingContextId,
    objectIds
  };
  const variables = { input };
  const onData = ({ data }) => {
    const { data: gqlDiagramFilterEventSubscription } = data;
    if (gqlDiagramFilterEventSubscription) {
      const { diagramFilterEvent: payload } = gqlDiagramFilterEventSubscription;
      if (isFormRefreshedEventPayload$3(payload)) {
        const { form } = payload;
        setState((prevState) => ({ ...prevState, form }));
      }
    }
  };
  const onComplete = () => setState((prevState) => ({ ...prevState, complete: true }));
  const { error, loading } = useSubscription(
    gql$1(getDiagramFilterEventSubscription),
    {
      variables,
      fetchPolicy: "no-cache",
      skip,
      onData,
      onComplete
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage("An unexpected error has occurred, please refresh the page");
    }
  }, [error]);
  return {
    loading,
    form: state.form,
    complete: state.complete
  };
};
const useDiagramFilterViewStyles = makeStyles()((theme2) => ({
  idle: {
    padding: theme2.spacing(1)
  },
  content: {
    padding: theme2.spacing(1)
  }
}));
const DiagramFilterForm = ({ editingContextId, diagramId, readOnly }) => {
  const { form } = useDiagramFilterSubscription(editingContextId, [diagramId]);
  const { classes } = useDiagramFilterViewStyles();
  const extractFirstGroup = (props, form2) => {
    var _a;
    const group = (_a = form2.pages[0]) == null ? void 0 : _a.groups[0];
    if (group) {
      return /* @__PURE__ */ jsx("div", { className: classes.content, children: /* @__PURE__ */ jsx(Group, { editingContextId: props.editingContextId, formId: form2.id, readOnly: props.readOnly, group }) });
    } else {
      return /* @__PURE__ */ jsx("div", { className: classes.content });
    }
  };
  if (!form) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    FormBasedView,
    {
      editingContextId,
      form,
      readOnly,
      postProcessor: extractFirstGroup
    }
  );
};
const DiagramFilter = ({ editingContextId, diagramId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handlePanel = () => setIsOpen(() => true);
  const anchorRef = useRef(null);
  const { readOnly } = useContext(DiagramContext);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Tooltip, { title: "Filter elements", ref: anchorRef, children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        "aria-label": "Filter elements",
        onClick: handlePanel,
        "data-testid": "Filter elements",
        disabled: readOnly,
        children: /* @__PURE__ */ jsx(FilterListIcon, {})
      }
    ) }) }),
    /* @__PURE__ */ jsx(
      Popper,
      {
        open: isOpen,
        anchorEl: anchorRef.current,
        placement: "bottom-start",
        disablePortal: true,
        style: { zIndex: "9999" },
        children: /* @__PURE__ */ jsx(Paper, { style: { maxHeight: "50vh", overflow: "auto" }, children: /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => setIsOpen(false), children: /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(DiagramFilterForm, { editingContextId, diagramId, readOnly: false }) }) }) })
      }
    )
  ] });
};
const httpOptionsConfigurersExtensionPoint = {
  identifier: "apolloClient#httpOptionsConfigurers",
  fallback: []
};
const webSocketOptionsConfigurersExtensionPoint = {
  identifier: "apolloClient#webSocketOptionsConfigurers",
  fallback: []
};
const cacheOptionsConfigurersExtensionPoint = {
  identifier: "apolloClient#cacheOptionsConfigurers",
  fallback: []
};
const apolloClientOptionsConfigurersExtensionPoint = {
  identifier: "apolloClient#apolloClientOptionsConfigurers",
  fallback: []
};
const useNewDocumentAreaStyles = makeStyles()((theme2) => ({
  cardContent: {
    overflowY: "auto",
    maxHeight: theme2.spacing(50)
  },
  item: {
    padding: 0
  }
}));
const invokeEditingContextActionMutation = gql$1`
  mutation invokeEditingContextAction($input: InvokeEditingContextActionInput!) {
    invokeEditingContextAction(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$c = (payload) => payload.__typename === "ErrorPayload";
const NewDocumentArea = ({ editingContextId, editingContextActions, readOnly }) => {
  const { classes } = useNewDocumentAreaStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [state, setState] = useState({
    message: null
  });
  const [
    invokeEditingContextAction,
    { loading: loadingEditingContextAction, data: dataEditingContextAction, error: errorEditingContextAction }
  ] = useMutation(
    invokeEditingContextActionMutation
  );
  const onInvokeEditingContextAction = (actionId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      actionId
    };
    const variables = {
      input
    };
    invokeEditingContextAction({ variables });
  };
  useEffect(() => {
    if (!loadingEditingContextAction) {
      if (errorEditingContextAction) {
        setState({ message: coreT("errors.unexpected") });
      }
      if (dataEditingContextAction) {
        const { invokeEditingContextAction: invokeEditingContextAction2 } = dataEditingContextAction;
        if (isErrorPayload$c(invokeEditingContextAction2)) {
          setState({ message: invokeEditingContextAction2.message });
        }
      }
    }
  }, [loadingEditingContextAction, errorEditingContextAction, dataEditingContextAction, coreT]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Card, { "data-testid": "actions", children: /* @__PURE__ */ jsxs(CardContent, { className: classes.cardContent, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "h6", children: t("createModel") }),
      /* @__PURE__ */ jsx(Typography, { color: "textSecondary", children: readOnly ? t("noAccessToCreateModel") : t("selectModelToCreate") }),
      /* @__PURE__ */ jsx(List, { dense: true, children: readOnly ? null : editingContextActions.map((editingContextAction) => {
        return /* @__PURE__ */ jsxs(
          ListItem,
          {
            className: classes.item,
            dense: true,
            disableGutters: true,
            button: true,
            "data-testid": editingContextAction.id,
            onClick: () => {
              onInvokeEditingContextAction(editingContextAction.id);
            },
            children: [
              /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(NoteAdd, { htmlColor: "primary", fontSize: "small" }) }),
              /* @__PURE__ */ jsx(ListItemText, { primary: editingContextAction.label })
            ]
          },
          editingContextAction.id
        );
      }) })
    ] }) }),
    /* @__PURE__ */ jsx(Toast, { message: state.message, open: !!state.message, onClose: () => setState({ message: null }) })
  ] });
};
const useNewRepresentationAreaStyles = makeStyles()((theme2) => ({
  subtitles: {
    textOverflow: 'ellipsis " [..]";'
  },
  cardContent: {
    overflowY: "auto",
    maxHeight: theme2.spacing(50)
  },
  item: {
    padding: 0
  }
}));
const createRepresentationMutation$1 = gql$1`
  mutation createRepresentation($input: CreateRepresentationInput!) {
    createRepresentation(input: $input) {
      __typename
      ... on CreateRepresentationSuccessPayload {
        representation {
          id
          label
          kind
          __typename
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$b = (payload) => payload.__typename === "ErrorPayload";
const NewRepresentationArea = ({
  editingContextId,
  representationDescriptions,
  readOnly
}) => {
  const [state, setState] = useState({
    message: null
  });
  const { classes } = useNewRepresentationAreaStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { selection, setSelection } = useSelection();
  const selectedItem = selection.entries.length > 0 ? selection.entries[0] : null;
  const [createRepresentation, { loading, data, error }] = useMutation(createRepresentationMutation$1);
  useEffect(() => {
    if (!loading) {
      if (error) {
        setState({ message: coreT("errors.unexpected") });
      }
      if (data) {
        const { createRepresentation: createRepresentation2 } = data;
        if (createRepresentation2.representation) {
          const { id, kind } = createRepresentation2.representation;
          setSelection({ entries: [{ id, kind }] });
        }
        if (isErrorPayload$b(createRepresentation2)) {
          setState({ message: createRepresentation2.message });
        }
      }
    }
  }, [loading, error, data, coreT]);
  const onCreateRepresentation = (representationDescriptionId) => {
    const selected = representationDescriptions.find((candidate) => candidate.id === representationDescriptionId);
    const objectId = selectedItem.id;
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      objectId,
      representationDescriptionId,
      representationName: selected.defaultName
    };
    createRepresentation({ variables: { input } });
  };
  const subtitle = selectedItem && representationDescriptions.length > 0 ? t("selectRepresentationToCreate") : t("noRepresentations");
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Card, { children: /* @__PURE__ */ jsxs(CardContent, { className: classes.cardContent, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "h6", children: t("createRepresentation") }),
      /* @__PURE__ */ jsx(Typography, { className: classes.subtitles, color: "textSecondary", children: readOnly ? t("noAccessToCreateRepresentation") : subtitle }),
      /* @__PURE__ */ jsx(List, { dense: true, children: readOnly ? null : representationDescriptions.sort((a, b) => a.defaultName.localeCompare(b.defaultName)).map((representationDescription) => {
        return /* @__PURE__ */ jsxs(
          ListItem,
          {
            className: classes.item,
            dense: true,
            disableGutters: true,
            button: true,
            "data-testid": representationDescription.id,
            onClick: () => {
              onCreateRepresentation(representationDescription.id);
            },
            children: [
              /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(Collections, { htmlColor: "primary", fontSize: "small" }) }),
              /* @__PURE__ */ jsx(ListItemText, { primary: representationDescription.defaultName })
            ]
          },
          representationDescription.id
        );
      }) })
    ] }) }),
    /* @__PURE__ */ jsx(Toast, { message: state.message, open: !!state.message, onClose: () => setState({ message: null }) })
  ] });
};
const useRepresentationAreaStyles = makeStyles()((theme2) => ({
  cardContent: {
    overflowY: "auto",
    maxHeight: theme2.spacing(50)
  },
  item: {
    padding: 0
  }
}));
const RepresentationsArea = ({ representations }) => {
  const { setSelection } = useSelection();
  const { classes } = useRepresentationAreaStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  return /* @__PURE__ */ jsx(Card, { children: /* @__PURE__ */ jsxs(CardContent, { className: classes.cardContent, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "h6", children: t("openRepresentation") }),
    /* @__PURE__ */ jsx(Typography, { color: "textSecondary", children: t("selectRepresentationToOpen") }),
    /* @__PURE__ */ jsx(List, { dense: true, children: representations.sort((a, b) => a.label.localeCompare(b.label)).map((representation) => {
      return /* @__PURE__ */ jsxs(
        ListItem,
        {
          className: classes.item,
          dense: true,
          disableGutters: true,
          button: true,
          "data-testid": `onboard-open-${representation.label}`,
          onClick: () => setSelection({
            entries: [{ id: representation.id, kind: representation.kind }]
          }),
          children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(Collections, { htmlColor: "primary", fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: representation.label })
          ]
        },
        representation.id
      );
    }) })
  ] }) });
};
const getOnboardDataQuery = gql$1`
  query getOnboardData($editingContextId: ID!, $objectId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representationDescriptions(objectId: $objectId) {
          edges {
            node {
              id
              label
              defaultName
            }
          }
        }
        representations {
          edges {
            node {
              id
              label
              kind
            }
          }
        }
        actions {
          edges {
            node {
              id
              label
            }
          }
        }
      }
    }
  }
`;
const INITIAL_STATE = {
  editingContextActions: [],
  representationDescriptions: [],
  representations: []
};
const useOnboardAreaStyles = makeStyles()((theme2) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    padding: theme2.spacing(5),
    overflowY: "auto",
    overflowX: "auto"
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
    gap: theme2.spacing(2)
  },
  box: {}
}));
const OnboardArea = ({ editingContextId, readOnly }) => {
  const { classes } = useOnboardAreaStyles();
  const [state, setState] = useState(INITIAL_STATE);
  const { editingContextActions, representationDescriptions, representations } = state;
  const { selection } = useSelection();
  const objectId = selection.entries.length > 0 ? selection.entries[0].id : "";
  const [getOnboardData, { loading, data, error }] = useLazyQuery(getOnboardDataQuery);
  useEffect(() => {
    getOnboardData({ variables: { editingContextId, objectId } });
  }, [editingContextId, objectId, getOnboardData]);
  useEffect(() => {
    if (!loading && !error && (data == null ? void 0 : data.viewer)) {
      const { viewer } = data;
      const representations2 = viewer.editingContext.representations.edges.map((edge) => edge.node);
      const editingContextActions2 = viewer.editingContext.actions.edges.map((edge) => edge.node);
      const representationDescriptions2 = viewer.editingContext.representationDescriptions.edges.map(
        (edge) => edge.node
      );
      setState({
        representations: representations2,
        editingContextActions: editingContextActions2,
        representationDescriptions: representationDescriptions2
      });
    }
  }, [editingContextId, objectId, loading, data, error]);
  return /* @__PURE__ */ jsx("div", { className: classes.container, "data-testid": "onboard-area", children: /* @__PURE__ */ jsxs("div", { className: classes.grid, children: [
    /* @__PURE__ */ jsx(
      NewDocumentArea,
      {
        editingContextId,
        editingContextActions,
        readOnly
      }
    ),
    /* @__PURE__ */ jsx(
      NewRepresentationArea,
      {
        editingContextId,
        representationDescriptions,
        readOnly
      }
    ),
    /* @__PURE__ */ jsx(RepresentationsArea, { representations })
  ] }) });
};
const DiagramTreeItemContextMenuContribution = forwardRef(
  ({ treeId, item }, _) => {
    if (!treeId.startsWith("explorer://") || item.kind !== "siriusComponents://representation?type=Diagram") {
      return null;
    }
    return /* @__PURE__ */ jsx(Fragment$1, {}, "diagram-tree-item-context-menu-contribution");
  }
);
const isCreateRootObjectSuccessPayload = (payload) => {
  return payload.__typename === "CreateRootObjectSuccessPayload";
};
const newRootObjectModalMachine = Machine(
  {
    id: "NewRootObjectModal",
    type: "parallel",
    context: {
      domains: [],
      selectedDomainId: "",
      rootObjectCreationDescriptions: [],
      selectedRootObjectCreationDescriptionId: "",
      suggestedRootObject: true,
      objectToSelect: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      newRootObjectModal: {
        initial: "loadingDomains",
        states: {
          loadingDomains: {
            on: {
              HANDLE_FETCHED_DOMAINS: [
                {
                  actions: "updateDomains",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ]
            }
          },
          loadingRootObjectCreationDescriptions: {
            on: {
              HANDLE_FETCHED_ROOT_OBJECT_CREATION_DESCRIPTIONS: [
                {
                  actions: "updateRootObjectCreationDescriptions",
                  target: "valid"
                }
              ]
            }
          },
          valid: {
            on: {
              CHANGE_DOMAIN: [
                {
                  actions: "updateDomain",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ],
              CHANGE_ROOT_OBJECT_CREATION_DESCRIPTION: [
                {
                  actions: "updateRootObjectCreationDescription"
                }
              ],
              CHANGE_SUGGESTED: [
                {
                  actions: "updateSuggested",
                  target: "loadingRootObjectCreationDescriptions"
                }
              ],
              CREATE_ROOT_OBJECT: [
                {
                  target: "creatingRootObject"
                }
              ]
            }
          },
          creatingRootObject: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success",
                  actions: "updateObjectToSelect"
                },
                {
                  target: "valid"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.createRootObject.__typename === "CreateRootObjectSuccessPayload";
      }
    },
    actions: {
      updateDomains: assign((_, event) => {
        const { data } = event;
        const { domains } = data.viewer.editingContext;
        const selectedDomainId = domains.length > 0 ? domains[0].id : "";
        return { domains, selectedDomainId };
      }),
      updateRootObjectCreationDescriptions: assign((_, event) => {
        const { data } = event;
        const { rootObjectCreationDescriptions } = data.viewer.editingContext;
        const selectedRootObjectCreationDescriptionId = rootObjectCreationDescriptions.length > 0 ? rootObjectCreationDescriptions[0].id : "";
        return { rootObjectCreationDescriptions, selectedRootObjectCreationDescriptionId };
      }),
      updateDomain: assign((_, event) => {
        const { domainId } = event;
        return { selectedDomainId: domainId };
      }),
      updateRootObjectCreationDescription: assign((_, event) => {
        const { rootObjectCreationDescriptionId } = event;
        return { selectedRootObjectCreationDescriptionId: rootObjectCreationDescriptionId };
      }),
      updateSuggested: assign((_, event) => {
        const { suggestedRootObject } = event;
        return { suggestedRootObject };
      }),
      updateObjectToSelect: assign((_, event) => {
        const { data } = event;
        if (isCreateRootObjectSuccessPayload(data.createRootObject)) {
          const { object } = data.createRootObject;
          return { objectToSelect: object };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const createRootObjectMutation = gql$1`
  mutation createRootObject($input: CreateRootObjectInput!) {
    createRootObject(input: $input) {
      __typename
      ... on CreateRootObjectSuccessPayload {
        object {
          id
          label
          kind
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const getRootDomainsQuery = gql$1`
  query getRootDomains($editingContextId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        domains(rootDomainsOnly: true) {
          id
          label
        }
      }
    }
  }
`;
const getRootObjectCreationDescriptionsQuery = gql$1`
  query getRootObjectCreationDescriptions($editingContextId: ID!, $domainId: ID!, $suggested: Boolean!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        rootObjectCreationDescriptions(domainId: $domainId, suggested: $suggested) {
          id
          label
          iconURL
        }
      }
    }
  }
`;
const useNewRootObjectModalStyles = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme2.spacing(1)
    }
  },
  select: {
    "&": {
      display: "flex",
      alignItems: "center"
    }
  },
  iconRoot: {
    minWidth: theme2.spacing(3)
  }
}));
const NewRootObjectModal = ({ editingContextId, item, onObjectCreated, onClose }) => {
  const { classes } = useNewRootObjectModalStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "object.newRoot" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [{ value: value2, context }, dispatch] = useMachine(
    newRootObjectModalMachine
  );
  const { newRootObjectModal, toast } = value2;
  const {
    domains,
    selectedDomainId,
    rootObjectCreationDescriptions,
    selectedRootObjectCreationDescriptionId,
    suggestedRootObject,
    objectToSelect,
    message
  } = context;
  const {
    loading: domainsLoading,
    data: domainsData,
    error: domainsError
  } = useQuery(getRootDomainsQuery, {
    variables: { editingContextId }
  });
  useEffect(() => {
    if (!domainsLoading) {
      if (domainsError) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (domainsData) {
        const fetchDomainsEvent = {
          type: "HANDLE_FETCHED_DOMAINS",
          data: domainsData
        };
        dispatch(fetchDomainsEvent);
      }
    }
  }, [domainsLoading, domainsData, domainsError, dispatch, coreT]);
  const [
    getRootObjectCreationDescriptions,
    { loading: descriptionsLoading, data: descriptionsData, error: descriptionError }
  ] = useLazyQuery(
    getRootObjectCreationDescriptionsQuery
  );
  useEffect(() => {
    if (!descriptionsLoading) {
      if (descriptionError) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (descriptionsData) {
        const fetchDescriptionsEvent = {
          type: "HANDLE_FETCHED_ROOT_OBJECT_CREATION_DESCRIPTIONS",
          data: descriptionsData
        };
        dispatch(fetchDescriptionsEvent);
      }
    }
  }, [descriptionsLoading, descriptionsData, descriptionError, dispatch, coreT]);
  useEffect(() => {
    if (newRootObjectModal === "loadingRootObjectCreationDescriptions" && selectedDomainId) {
      getRootObjectCreationDescriptions({
        variables: { editingContextId, domainId: selectedDomainId, suggested: suggestedRootObject }
      });
    }
  }, [newRootObjectModal, getRootObjectCreationDescriptions, editingContextId, selectedDomainId, suggestedRootObject]);
  const [
    createRootObject,
    { loading: createRootObjectLoading, data: createRootObjectData, error: createRootObjectError }
  ] = useMutation(createRootObjectMutation);
  useEffect(() => {
    if (!createRootObjectLoading) {
      if (createRootObjectError) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (createRootObjectData) {
        const handleResponseEvent = { type: "HANDLE_RESPONSE", data: createRootObjectData };
        dispatch(handleResponseEvent);
      }
    }
  }, [createRootObjectLoading, createRootObjectError, createRootObjectData, dispatch, coreT]);
  const onCreateRootObject = () => {
    dispatch({ type: "CREATE_ROOT_OBJECT" });
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      documentId: item.id,
      domainId: selectedDomainId,
      rootObjectCreationDescriptionId: selectedRootObjectCreationDescriptionId
    };
    createRootObject({ variables: { input } });
  };
  const onDomainChange = (event) => {
    const { value: value22 } = event.target;
    const changeDomainEvent = { type: "CHANGE_DOMAIN", domainId: value22 };
    dispatch(changeDomainEvent);
  };
  const onRootObjectCreationDescriptionChange = (event) => {
    const { value: value22 } = event.target;
    const changeRootObjectCreationDescriptionEvent = {
      type: "CHANGE_ROOT_OBJECT_CREATION_DESCRIPTION",
      rootObjectCreationDescriptionId: value22
    };
    dispatch(changeRootObjectCreationDescriptionEvent);
  };
  const onSuggestedRootObjectChange = (event) => {
    const { checked } = event.target;
    const changeSuggestedRootObject = { type: "CHANGE_SUGGESTED", suggestedRootObject: checked };
    dispatch(changeSuggestedRootObject);
  };
  useEffect(() => {
    if (newRootObjectModal === "success") {
      onObjectCreated({ entries: [objectToSelect] });
    }
  }, [newRootObjectModal, onObjectCreated, objectToSelect]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
      /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
      /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs("div", { className: classes.form, children: [
        /* @__PURE__ */ jsx(InputLabel, { id: "domainsLabel", children: t("domain.label") }),
        /* @__PURE__ */ jsx(
          Select,
          {
            variant: "standard",
            value: selectedDomainId,
            onChange: onDomainChange,
            disabled: newRootObjectModal !== "valid",
            labelId: "domainsLabel",
            fullWidth: true,
            "data-testid": "domain",
            children: domains.map((domain) => /* @__PURE__ */ jsx(MenuItem, { value: domain.id, children: domain.label }, domain.id))
          }
        ),
        /* @__PURE__ */ jsx(InputLabel, { id: "rootObjectCreationDescriptionsLabel", children: t("type.label") }),
        /* @__PURE__ */ jsx(
          Select,
          {
            variant: "standard",
            classes: { select: classes.select },
            value: selectedRootObjectCreationDescriptionId,
            onChange: onRootObjectCreationDescriptionChange,
            disabled: newRootObjectModal !== "valid",
            labelId: "rootObjectCreationDescriptionsLabel",
            fullWidth: true,
            "data-testid": "type",
            children: rootObjectCreationDescriptions.map((rootObjectCreationDescription) => /* @__PURE__ */ jsxs(MenuItem, { value: rootObjectCreationDescription.id, children: [
              rootObjectCreationDescription.iconURL.length > 0 && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.iconRoot, children: /* @__PURE__ */ jsx(
                IconOverlay,
                {
                  iconURL: rootObjectCreationDescription.iconURL,
                  alt: rootObjectCreationDescription.label
                }
              ) }),
              /* @__PURE__ */ jsx(ListItemText, { primary: rootObjectCreationDescription.label })
            ] }, rootObjectCreationDescription.id))
          }
        ),
        /* @__PURE__ */ jsx(
          FormControlLabel,
          {
            control: /* @__PURE__ */ jsx(
              Checkbox,
              {
                checked: suggestedRootObject,
                onChange: onSuggestedRootObjectChange,
                name: "suggested",
                color: "primary",
                "data-testid": "suggested"
              }
            ),
            label: t("suggestedType.label")
          }
        )
      ] }) }),
      /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: newRootObjectModal !== "valid",
          "data-testid": "create-object",
          color: "primary",
          onClick: onCreateRootObject,
          children: t("submit")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const DocumentTreeItemContextMenuContribution = forwardRef(
  ({ editingContextId, treeId, item, readOnly, expandItem, onClose }, ref) => {
    const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
    const { httpOrigin } = useContext(ServerContext);
    const [modal, setModal] = useState(null);
    const { setSelection } = useSelection();
    if (!treeId.startsWith("explorer://") || !item.kind.startsWith("siriusWeb://document")) {
      return null;
    }
    const onObjectCreated = (selection) => {
      setSelection(selection);
      expandItem();
      onClose();
    };
    let modalElement = null;
    if (modal === "CreateNewRootObject") {
      modalElement = /* @__PURE__ */ jsx(
        NewRootObjectModal,
        {
          editingContextId,
          item,
          onObjectCreated,
          onClose
        }
      );
    }
    return /* @__PURE__ */ jsxs(Fragment$1, { children: [
      /* @__PURE__ */ jsxs(
        MenuItem,
        {
          "data-testid": "new-object",
          onClick: () => setModal("CreateNewRootObject"),
          ref,
          disabled: readOnly,
          "aria-disabled": true,
          children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(AddIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("newObject") })
          ]
        },
        "new-object"
      ),
      /* @__PURE__ */ jsxs(
        MenuItem,
        {
          divider: true,
          onClick: onClose,
          component: "a",
          href: `${httpOrigin}/api/editingcontexts/${editingContextId}/documents/${item.id}`,
          type: "application/octet-stream",
          "data-testid": "download",
          "aria-disabled": true,
          children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(GetAppIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("download"), "aria-disabled": true })
          ]
        },
        "download"
      ),
      modalElement
    ] }, "document-tree-item-context-menu-contribution");
  }
);
const isCreateChildSuccessPayload = (payload) => {
  return payload.__typename === "CreateChildSuccessPayload";
};
const newObjectModalMachine = Machine(
  {
    id: "NewObjectModal",
    type: "parallel",
    context: {
      selectedChildCreationDescriptionId: "",
      childCreationDescriptions: [],
      objectToSelect: null
    },
    states: {
      newObjectModal: {
        initial: "loading",
        states: {
          loading: {
            on: {
              HANDLE_FETCHED_CHILD_CREATION_DESCRIPTIONS: [
                {
                  target: "valid",
                  actions: "updateChildCreationDescriptions"
                }
              ]
            }
          },
          valid: {
            on: {
              CHANGE_CHILD_CREATION_DESCRIPTION: [
                {
                  actions: "updateChildCreationDescription"
                }
              ],
              CREATE_CHILD: [
                {
                  target: "creatingChild"
                }
              ]
            }
          },
          creatingChild: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success",
                  actions: "updateObjectToSelect"
                },
                {
                  target: "valid"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.createChild.__typename === "CreateChildSuccessPayload";
      }
    },
    actions: {
      updateChildCreationDescriptions: assign((_, event) => {
        const { data } = event;
        const { childCreationDescriptions } = data.viewer.editingContext;
        const selectedChildCreationDescriptionId = childCreationDescriptions.length > 0 ? childCreationDescriptions[0].id : "";
        return { childCreationDescriptions, selectedChildCreationDescriptionId };
      }),
      updateChildCreationDescription: assign((_, event) => {
        const { childCreationDescriptionId } = event;
        return { selectedChildCreationDescriptionId: childCreationDescriptionId };
      }),
      updateObjectToSelect: assign((_, event) => {
        const { data } = event;
        if (isCreateChildSuccessPayload(data.createChild)) {
          const { object } = data.createChild;
          return { objectToSelect: object };
        }
        return {};
      })
    }
  }
);
const createChildMutation = gql$1`
  mutation createChild($input: CreateChildInput!) {
    createChild(input: $input) {
      __typename
      ... on CreateChildSuccessPayload {
        object {
          id
          label
          kind
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const getChildCreationDescriptionsQuery = gql$1`
  query getChildCreationDescriptions($editingContextId: ID!, $kind: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        childCreationDescriptions(kind: $kind) {
          id
          label
          iconURL
        }
      }
    }
  }
`;
const useNewObjectModalStyles = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme2.spacing(1)
    }
  },
  select: {
    "&": {
      display: "flex",
      alignItems: "center"
    }
  },
  iconRoot: {
    minWidth: theme2.spacing(3)
  }
}));
const isErrorPayload$a = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload = (payload) => payload.__typename === "CreateChildSuccessPayload";
const NewObjectModal = ({ editingContextId, item, onObjectCreated, onClose }) => {
  const { classes } = useNewObjectModalStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "object.create" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const [{ value: value2, context }, dispatch] = useMachine(newObjectModalMachine);
  const { newObjectModal } = value2;
  const { selectedChildCreationDescriptionId, childCreationDescriptions, objectToSelect } = context;
  const {
    loading: childCreationDescriptionsLoading,
    data: childCreationDescriptionsData,
    error: childCreationDescriptionsError
  } = useQuery(
    getChildCreationDescriptionsQuery,
    { variables: { editingContextId, kind: item.kind } }
  );
  useEffect(() => {
    if (!childCreationDescriptionsLoading) {
      if (childCreationDescriptionsError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (childCreationDescriptionsData) {
        const fetchChildCreationDescriptionsEvent = {
          type: "HANDLE_FETCHED_CHILD_CREATION_DESCRIPTIONS",
          data: childCreationDescriptionsData
        };
        dispatch(fetchChildCreationDescriptionsEvent);
      }
    }
  }, [
    childCreationDescriptionsLoading,
    childCreationDescriptionsData,
    childCreationDescriptionsError,
    coreT,
    dispatch
  ]);
  const onChildCreationDescriptionChange = (event) => {
    const value22 = event.target.value;
    const changeChildCreationDescriptionEvent = {
      type: "CHANGE_CHILD_CREATION_DESCRIPTION",
      childCreationDescriptionId: value22
    };
    dispatch(changeChildCreationDescriptionEvent);
  };
  const [createChild, { loading: createChildLoading, data: createChildData, error: createChildError }] = useMutation(createChildMutation);
  useEffect(() => {
    if (!createChildLoading) {
      if (createChildError) {
        addErrorMessage(coreT("errors.unexpected"));
      }
      if (createChildData) {
        const handleResponseEvent = { type: "HANDLE_RESPONSE", data: createChildData };
        dispatch(handleResponseEvent);
        const { createChild: createChild2 } = createChildData;
        if (isErrorPayload$a(createChild2) || isSuccessPayload(createChild2)) {
          const { messages } = createChild2;
          addMessages(messages);
        }
      }
    }
  }, [coreT, createChildLoading, createChildData, createChildError, dispatch]);
  const onCreateObject = () => {
    dispatch({ type: "CREATE_CHILD" });
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      objectId: item.id,
      childCreationDescriptionId: selectedChildCreationDescriptionId
    };
    createChild({ variables: { input } });
  };
  useEffect(() => {
    if (newObjectModal === "success") {
      onObjectCreated({ entries: [objectToSelect] });
    }
  }, [newObjectModal, onObjectCreated, objectToSelect]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    Dialog,
    {
      open: true,
      onClose,
      "aria-labelledby": "dialog-title",
      maxWidth: "xs",
      fullWidth: true,
      "data-testid": "new-object-modal",
      children: [
        /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
        /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs("div", { className: classes.form, children: [
          /* @__PURE__ */ jsx(InputLabel, { id: "newObjectModalChildCreationDescriptionLabel", children: t("type.label") }),
          /* @__PURE__ */ jsx(
            Select,
            {
              variant: "standard",
              classes: { select: classes.select },
              value: selectedChildCreationDescriptionId,
              onChange: onChildCreationDescriptionChange,
              disabled: newObjectModal === "loading" || newObjectModal === "creatingChild",
              labelId: "newObjectModalChildCreationDescriptionLabel",
              fullWidth: true,
              "data-testid": "childCreationDescription",
              children: childCreationDescriptions.map((childCreationDescription) => /* @__PURE__ */ jsxs(MenuItem, { value: childCreationDescription.id, children: [
                childCreationDescription.iconURL.length > 0 && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.iconRoot, children: /* @__PURE__ */ jsx(IconOverlay, { iconURL: childCreationDescription.iconURL, alt: childCreationDescription.label }) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: childCreationDescription.label })
              ] }, childCreationDescription.id))
            }
          )
        ] }) }),
        /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
          Button,
          {
            variant: "contained",
            disabled: newObjectModal !== "valid",
            "data-testid": "create-object",
            color: "primary",
            onClick: onCreateObject,
            children: t("submit")
          }
        ) })
      ]
    }
  ) });
};
const newRepresentationModalMachine = Machine(
  {
    id: "NewRepresentationsModal",
    type: "parallel",
    context: {
      name: "",
      nameIsInvalid: false,
      nameHasBeenModified: false,
      selectedRepresentationDescriptionId: "",
      representationDescriptions: [],
      message: null,
      createdRepresentationId: null,
      createdRepresentationKind: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      newRepresentationModal: {
        initial: "loading",
        states: {
          loading: {
            on: {
              HANDLE_FETCHED_REPRESENTATION_CREATION_DESCRIPTIONS: [
                {
                  target: "valid",
                  actions: "updateRepresentationDescriptions"
                }
              ]
            }
          },
          invalid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isNameInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ],
              CHANGE_REPRESENTATION_CREATION_DESCRIPTION: [
                {
                  actions: "updateRepresentationDescription"
                }
              ]
            }
          },
          valid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isNameInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ],
              CHANGE_REPRESENTATION_CREATION_DESCRIPTION: [
                {
                  actions: "updateRepresentationDescription"
                }
              ],
              CREATE_REPRESENTATION: [
                {
                  target: "creatingRepresentation"
                }
              ]
            }
          },
          creatingRepresentation: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  actions: "updateCreatedRepresentation",
                  target: "success"
                },
                {
                  target: "valid"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isNameInvalid: (_, event) => {
        const { name } = event;
        return name.trim().length === 0;
      },
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.createRepresentation.__typename === "CreateRepresentationSuccessPayload";
      }
    },
    actions: {
      updateRepresentationDescriptions: assign((_, event) => {
        const { data } = event;
        const representationDescriptions = new Array();
        data.viewer.editingContext.representationDescriptions.edges.forEach(
          (edge) => representationDescriptions.push(edge.node)
        );
        const selectedRepresentationDescriptionId = representationDescriptions.length > 0 ? representationDescriptions.sort((a, b) => a.label.localeCompare(b.label))[0].id : "";
        const name = representationDescriptions.length > 0 ? representationDescriptions.sort((a, b) => a.label.localeCompare(b.label))[0].defaultName : "";
        return {
          representationDescriptions,
          selectedRepresentationDescriptionId,
          name,
          nameIsInvalid: name.trim().length === 0
        };
      }),
      updateName: assign((_, event) => {
        const { name } = event;
        return { name, nameIsInvalid: name.trim().length === 0, nameHasBeenModified: true };
      }),
      updateRepresentationDescription: assign((context, event) => {
        const { representationDescriptionId } = event;
        if (!context.nameHasBeenModified) {
          const name = context.representationDescriptions.filter(
            (representationDescription) => representationDescription.id === representationDescriptionId
          )[0].defaultName;
          return { selectedRepresentationDescriptionId: representationDescriptionId, name };
        }
        return { selectedRepresentationDescriptionId: representationDescriptionId };
      }),
      updateCreatedRepresentation: assign((_, event) => {
        const { data } = event;
        const createRepresentation = data.createRepresentation;
        return {
          createdRepresentationId: createRepresentation.representation.id,
          createdRepresentationKind: createRepresentation.representation.kind
        };
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const createRepresentationMutation = gql$1`
  mutation createRepresentation($input: CreateRepresentationInput!) {
    createRepresentation(input: $input) {
      __typename
      ... on CreateRepresentationSuccessPayload {
        representation {
          id
          kind
          __typename
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const getRepresentationDescriptionsQuery = gql$1`
  query getRepresentationDescriptions($editingContextId: ID!, $objectId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representationDescriptions(objectId: $objectId) {
          edges {
            node {
              id
              label
              defaultName
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`;
const useNewRepresentationModalStyles = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme2.spacing(1)
    }
  }
}));
const isErrorPayload$9 = (payload) => payload.__typename === "ErrorPayload";
const NewRepresentationModal = ({
  editingContextId,
  item,
  onRepresentationCreated,
  onClose
}) => {
  const { classes } = useNewRepresentationModalStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "representation.create" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [{ value: value2, context }, dispatch] = useMachine(
    newRepresentationModalMachine
  );
  const { newRepresentationModal, toast } = value2;
  const {
    name,
    nameIsInvalid,
    selectedRepresentationDescriptionId,
    representationDescriptions,
    createdRepresentationId,
    createdRepresentationKind,
    message
  } = context;
  const {
    loading: representationDescriptionsLoading,
    data: representationDescriptionsData,
    error: representationDescriptionsError
  } = useQuery(
    getRepresentationDescriptionsQuery,
    { variables: { editingContextId, objectId: item.id } }
  );
  useEffect(() => {
    if (!representationDescriptionsLoading) {
      if (representationDescriptionsError) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (representationDescriptionsData) {
        const fetchRepresentationDescriptionsEvent = {
          type: "HANDLE_FETCHED_REPRESENTATION_CREATION_DESCRIPTIONS",
          data: representationDescriptionsData
        };
        dispatch(fetchRepresentationDescriptionsEvent);
      }
    }
  }, [
    representationDescriptionsLoading,
    representationDescriptionsData,
    representationDescriptionsError,
    dispatch,
    coreT
  ]);
  const onNameChange = (event) => {
    const value22 = event.target.value;
    const changeNamedEvent = { type: "CHANGE_NAME", name: value22 };
    dispatch(changeNamedEvent);
  };
  const onRepresentationDescriptionChange = (event) => {
    const value22 = event.target.value;
    const changeRepresentationDescriptionEvent = {
      type: "CHANGE_REPRESENTATION_CREATION_DESCRIPTION",
      representationDescriptionId: value22
    };
    dispatch(changeRepresentationDescriptionEvent);
  };
  const [
    createRepresentation,
    { loading: createRepresentationLoading, data: createRepresentationData, error: createRepresentationError }
  ] = useMutation(createRepresentationMutation);
  useEffect(() => {
    if (!createRepresentationLoading) {
      if (createRepresentationError) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (createRepresentationData) {
        const handleResponseEvent = { type: "HANDLE_RESPONSE", data: createRepresentationData };
        dispatch(handleResponseEvent);
        const { createRepresentation: createRepresentation2 } = createRepresentationData;
        if (isErrorPayload$9(createRepresentation2)) {
          const { message: message2 } = createRepresentation2;
          const showToastEvent = { type: "SHOW_TOAST", message: message2 };
          dispatch(showToastEvent);
        }
      }
    }
  }, [createRepresentationLoading, createRepresentationData, createRepresentationError, dispatch, coreT]);
  const onCreateRepresentation = () => {
    dispatch({ type: "CREATE_REPRESENTATION" });
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      objectId: item.id,
      representationDescriptionId: selectedRepresentationDescriptionId,
      representationName: name
    };
    createRepresentation({ variables: { input } });
  };
  useEffect(() => {
    if (newRepresentationModal === "success") {
      onRepresentationCreated({
        entries: [
          {
            id: createdRepresentationId,
            kind: createdRepresentationKind
          }
        ]
      });
    }
  }, [createdRepresentationId, createdRepresentationKind, newRepresentationModal, onRepresentationCreated]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
      /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
      /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs("div", { className: classes.form, children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            variant: "standard",
            error: nameIsInvalid,
            helperText: t("name.helperText"),
            label: t("name.label"),
            name: "name",
            value: name,
            placeholder: t("name.placeholder"),
            inputProps: { "data-testid": "name" },
            autoFocus: true,
            onChange: onNameChange,
            disabled: newRepresentationModal === "loading" || newRepresentationModal === "creatingRepresentation"
          }
        ),
        /* @__PURE__ */ jsx(InputLabel, { id: "newRepresentationModalRepresentationDescriptionLabel", children: t("type.label") }),
        /* @__PURE__ */ jsx(
          Select,
          {
            variant: "standard",
            value: selectedRepresentationDescriptionId,
            onChange: onRepresentationDescriptionChange,
            disabled: newRepresentationModal === "loading" || newRepresentationModal === "creatingRepresentation",
            labelId: "newRepresentationModalRepresentationDescriptionLabel",
            inputProps: { "data-testid": "representationDescription-input" },
            fullWidth: true,
            "data-testid": "representationDescription",
            children: representationDescriptions.sort((a, b) => a.label.localeCompare(b.label)).map((representationDescription) => /* @__PURE__ */ jsx(
              MenuItem,
              {
                value: representationDescription.id,
                "data-testid": representationDescription.label,
                children: representationDescription.label
              },
              representationDescription.id
            ))
          }
        )
      ] }) }),
      /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: newRepresentationModal !== "valid",
          "data-testid": "create-representation",
          color: "primary",
          onClick: onCreateRepresentation,
          children: t("submit")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const ObjectTreeItemContextMenuContribution = forwardRef(
  ({ editingContextId, treeId, item, readOnly, expandItem, onClose }, ref) => {
    const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
    const [modal, setModal] = useState(null);
    const { setSelection } = useSelection();
    if (!treeId.startsWith("explorer://") || !item.kind.startsWith("siriusComponents://semantic")) {
      return null;
    }
    const onObjectCreated = (selection) => {
      setSelection(selection);
      expandItem();
      onClose();
    };
    let modalElement = null;
    if (modal === "CreateNewObject") {
      modalElement = /* @__PURE__ */ jsx(
        NewObjectModal,
        {
          editingContextId,
          item,
          onObjectCreated,
          onClose
        }
      );
    } else if (modal === "CreateNewRepresentation") {
      modalElement = /* @__PURE__ */ jsx(
        NewRepresentationModal,
        {
          editingContextId,
          item,
          onRepresentationCreated: onObjectCreated,
          onClose
        }
      );
    }
    return /* @__PURE__ */ jsxs(Fragment$1, { children: [
      /* @__PURE__ */ jsxs(
        MenuItem,
        {
          onClick: () => setModal("CreateNewObject"),
          "data-testid": "new-object",
          disabled: readOnly,
          ref,
          "aria-disabled": true,
          children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(AddIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("newObject") })
          ]
        },
        "new-object"
      ),
      /* @__PURE__ */ jsxs(
        MenuItem,
        {
          onClick: () => setModal("CreateNewRepresentation"),
          "data-testid": "new-representation",
          disabled: readOnly,
          "aria-disabled": true,
          children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(AddIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("newRepresentation") })
          ]
        },
        "new-representation"
      ),
      modalElement
    ] }, "object-tree-item-context-menu-contribution");
  }
);
const isFormRefreshedEventPayload$2 = (payload) => payload.__typename === "FormRefreshedEventPayload";
const getDetailsViewEventSubscription = `
  subscription detailsEvent($input: DetailsEventInput!) {
    detailsEvent(input: $input) {
      __typename
      ... on FormRefreshedEventPayload {
        ...formRefreshedEventPayloadFragment
      }
    }
  }
  ${formRefreshedEventPayloadFragment}
  `;
const useDetailsViewSubscription = (editingContextId, objectIds, skip) => {
  const [state, setState] = useState({
    id: crypto.randomUUID(),
    form: null,
    complete: false,
    payload: null
  });
  const input = {
    id: state.id,
    editingContextId,
    objectIds
  };
  const variables = { input };
  const onData = ({ data }) => {
    const { data: gqlDetailsEventSubscription } = data;
    if (gqlDetailsEventSubscription) {
      const { detailsEvent: payload } = gqlDetailsEventSubscription;
      setState((prevState) => ({ ...prevState, payload }));
      if (isFormRefreshedEventPayload$2(payload)) {
        const { form } = payload;
        setState((prevState) => ({ ...prevState, form }));
      }
    }
  };
  const onComplete = () => setState((prevState) => ({ ...prevState, complete: true }));
  const { error, loading } = useSubscription(
    gql$1(getDetailsViewEventSubscription),
    {
      variables,
      fetchPolicy: "no-cache",
      skip,
      onData,
      onComplete
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage("An unexpected error has occurred, please refresh the page");
    }
  }, [error]);
  return {
    loading,
    form: state.form,
    payload: state.payload,
    complete: state.complete
  };
};
const useDetailsViewStyles = makeStyles()((theme2) => ({
  idle: {
    padding: theme2.spacing(1)
  }
}));
const DetailsView = ({ editingContextId, readOnly }) => {
  const [state, setState] = useState({
    currentSelection: { entries: [] }
  });
  const { selection } = useSelection();
  const currentSelectionKey = state.currentSelection.entries.map((entry) => entry.id).sort().join(":");
  const newSelectionKey = selection.entries.map((entry) => entry.id).sort().join(":");
  useEffect(() => {
    if (selection.entries.length > 0 && currentSelectionKey !== newSelectionKey) {
      setState((prevState) => ({ ...prevState, currentSelection: selection }));
    } else if (selection.entries.length === 0) {
      setState((prevState) => ({ ...prevState, currentSelection: { entries: [] } }));
    }
  }, [currentSelectionKey, newSelectionKey]);
  const objectIds = state.currentSelection.entries.map((entry) => entry.id);
  const skip = objectIds.length === 0;
  const { form, payload } = useDetailsViewSubscription(editingContextId, objectIds, skip);
  const { classes } = useDetailsViewStyles();
  if (!form) {
    return /* @__PURE__ */ jsx("div", { className: classes.idle, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "No object selected" }) });
  }
  return /* @__PURE__ */ jsx("div", { "data-representation-kind": "form-details", children: /* @__PURE__ */ jsx(
    FormContext.Provider,
    {
      value: {
        payload
      },
      children: /* @__PURE__ */ jsx(FormBasedView, { editingContextId, form, readOnly })
    }
  ) });
};
const isFormRefreshedEventPayload$1 = (payload) => payload.__typename === "FormRefreshedEventPayload";
const getRelatedElementsViewEventSubscription = `
  subscription relatedElementsEvent($input: RelatedElementsEventInput!) {
    relatedElementsEvent(input: $input) {
      __typename
      ... on FormRefreshedEventPayload {
        ...formRefreshedEventPayloadFragment
      }
    }
  }
  ${formRefreshedEventPayloadFragment}
  `;
const useRelatedElementsViewSubscription = (editingContextId, objectIds, skip) => {
  const [state, setState] = useState({
    id: crypto.randomUUID(),
    form: null,
    complete: false
  });
  const input = {
    id: state.id,
    editingContextId,
    objectIds
  };
  const variables = { input };
  const onData = ({ data }) => {
    const { data: gqlRelatedElementsEventSubscription } = data;
    if (gqlRelatedElementsEventSubscription) {
      const { relatedElementsEvent: payload } = gqlRelatedElementsEventSubscription;
      if (isFormRefreshedEventPayload$1(payload)) {
        const { form } = payload;
        setState((prevState) => ({ ...prevState, form }));
      }
    }
  };
  const onComplete = () => setState((prevState) => ({ ...prevState, complete: true }));
  const { error, loading } = useSubscription(
    gql$1(getRelatedElementsViewEventSubscription),
    {
      variables,
      fetchPolicy: "no-cache",
      skip,
      onData,
      onComplete
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage("An unexpected error has occurred, please refresh the page");
    }
  }, [error]);
  return {
    loading,
    form: state.form,
    complete: state.complete
  };
};
const useRelatedElementsViewStyles = makeStyles()((theme2) => ({
  idle: {
    padding: theme2.spacing(1)
  },
  content: {
    padding: theme2.spacing(1)
  }
}));
const RelatedElementsView = ({ editingContextId, readOnly }) => {
  const [state, setState] = useState({
    currentSelection: { entries: [] }
  });
  const { selection } = useSelection();
  const currentSelectionKey = state.currentSelection.entries.map((entry) => entry.id).sort().join(":");
  const newSelectionKey = selection.entries.map((entry) => entry.id).sort().join(":");
  useEffect(() => {
    if (selection.entries.length > 0 && currentSelectionKey !== newSelectionKey) {
      setState((prevState) => ({ ...prevState, currentSelection: selection }));
    } else if (selection.entries.length === 0) {
      setState((prevState) => ({ ...prevState, currentSelection: { entries: [] } }));
    }
  }, [currentSelectionKey, newSelectionKey]);
  const objectIds = state.currentSelection.entries.map((entry) => entry.id);
  const skip = objectIds.length === 0;
  const { form } = useRelatedElementsViewSubscription(editingContextId, objectIds, skip);
  const { classes } = useRelatedElementsViewStyles();
  const extractFirstGroup = (props, form2) => {
    var _a;
    const group = (_a = form2.pages[0]) == null ? void 0 : _a.groups[0];
    if (group) {
      return /* @__PURE__ */ jsx("div", { className: classes.content, children: /* @__PURE__ */ jsx(Group, { editingContextId: props.editingContextId, formId: form2.id, readOnly: props.readOnly, group }) });
    } else {
      return /* @__PURE__ */ jsx("div", { className: classes.content });
    }
  };
  if (!form) {
    return /* @__PURE__ */ jsx("div", { className: classes.idle, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "No object selected" }) });
  }
  return /* @__PURE__ */ jsx(
    FormBasedView,
    {
      editingContextId,
      form,
      readOnly,
      postProcessor: extractFirstGroup
    }
  );
};
const isFormRefreshedEventPayload = (payload) => payload.__typename === "FormRefreshedEventPayload";
const getRepresentationsViewEventSubscription = `
  subscription representationsEvent($input: RepresentationsEventInput!) {
    representationsEvent(input: $input) {
      __typename
      ... on FormRefreshedEventPayload {
        ...formRefreshedEventPayloadFragment
      }
    }
  }
  ${formRefreshedEventPayloadFragment}
  `;
const useRepresentationsViewSubscription = (editingContextId, objectIds, skip) => {
  const [state, setState] = useState({
    id: crypto.randomUUID(),
    form: null,
    complete: false
  });
  const input = {
    id: state.id,
    editingContextId,
    objectIds
  };
  const variables = { input };
  const onData = ({ data }) => {
    const { data: gqlRepresentationsEventSubscription } = data;
    if (gqlRepresentationsEventSubscription) {
      const { representationsEvent: payload } = gqlRepresentationsEventSubscription;
      if (isFormRefreshedEventPayload(payload)) {
        const { form } = payload;
        setState((prevState) => ({ ...prevState, form }));
      }
    }
  };
  const onComplete = () => setState((prevState) => ({ ...prevState, complete: true }));
  const { error, loading } = useSubscription(
    gql$1(getRepresentationsViewEventSubscription),
    {
      variables,
      fetchPolicy: "no-cache",
      skip,
      onData,
      onComplete
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage("An unexpected error has occurred, please refresh the page");
    }
  }, [error]);
  return {
    loading,
    form: state.form,
    complete: state.complete
  };
};
const useRepresentationsViewStyles = makeStyles()((theme2) => ({
  idle: {
    padding: theme2.spacing(1)
  },
  content: {
    padding: theme2.spacing(1)
  }
}));
const isList = (widget) => widget && widget.__typename === "List";
const isTree = (widget) => widget && widget.__typename === "TreeWidget";
const RepresentationsView = ({ editingContextId, readOnly }) => {
  const [state, setState] = useState({
    currentSelection: { entries: [] }
  });
  const { selection } = useSelection();
  const currentSelectionKey = state.currentSelection.entries.map((entry) => entry.id).sort().join(":");
  const newSelectionKey = selection.entries.map((entry) => entry.id).sort().join(":");
  useEffect(() => {
    if (selection.entries.length > 0 && currentSelectionKey !== newSelectionKey) {
      setState((prevState) => ({ ...prevState, currentSelection: selection }));
    } else if (selection.entries.length === 0) {
      setState((prevState) => ({ ...prevState, currentSelection: { entries: [] } }));
    }
  }, [currentSelectionKey, newSelectionKey]);
  const objectIds = state.currentSelection.entries.map((entry) => entry.id);
  const skip = objectIds.length === 0;
  const { form } = useRepresentationsViewSubscription(editingContextId, objectIds, skip);
  const { classes } = useRepresentationsViewStyles();
  const extractPlainList = (props, form2) => {
    var _a, _b;
    const widget = (_b = (_a = form2.pages[0]) == null ? void 0 : _a.groups[0]) == null ? void 0 : _b.widgets[0];
    if (isList(widget)) {
      return /* @__PURE__ */ jsx("div", { className: classes.content, children: /* @__PURE__ */ jsx(
        ListPropertySection,
        {
          editingContextId: props.editingContextId,
          formId: form2.id,
          readOnly: props.readOnly,
          widget
        }
      ) });
    } else if (isTree(widget)) {
      return /* @__PURE__ */ jsx("div", { className: classes.content, children: /* @__PURE__ */ jsx(
        TreePropertySection,
        {
          editingContextId: props.editingContextId,
          formId: form2.id,
          readOnly: props.readOnly,
          widget
        }
      ) });
    } else {
      return /* @__PURE__ */ jsx("div", { className: classes.content });
    }
  };
  if (!form) {
    return /* @__PURE__ */ jsx("div", { className: classes.idle, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "No object selected" }) });
  }
  return /* @__PURE__ */ jsx(
    FormBasedView,
    {
      editingContextId,
      form,
      readOnly,
      postProcessor: extractPlainList
    }
  );
};
const createProjectAreaCardExtensionPoint = {
  identifier: "createProjectArea#card",
  FallbackComponent: () => null
};
const useNewProjectCardStyles = makeStyles()((theme2) => ({
  projectCard: {
    width: theme2.spacing(30),
    height: theme2.spacing(18),
    display: "grid",
    gridTemplateRows: "1fr min-content"
  },
  projectCardActions: {
    minWidth: 0
  },
  projectCardLabel: {
    textTransform: "none",
    fontWeight: 400,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  projectCardIcon: {
    fontSize: theme2.spacing(8)
  },
  blankProjectCard: {
    backgroundColor: theme2.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
const NewProjectCard = () => {
  const { classes } = useNewProjectCardStyles();
  return /* @__PURE__ */ jsx(Button, { to: `/new/project`, component: Link, "data-testid": "create", children: /* @__PURE__ */ jsxs(Card, { className: classes.projectCard, children: [
    /* @__PURE__ */ jsx(CardContent, { className: classes.blankProjectCard, children: /* @__PURE__ */ jsx(AddIcon, { className: classes.projectCardIcon, htmlColor: "white" }) }),
    /* @__PURE__ */ jsx(CardActions, { className: classes.projectCardActions, children: /* @__PURE__ */ jsx(Tooltip, { title: "Blank project", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.projectCardLabel, children: "+ Blank project" }) }) })
  ] }) });
};
var __assign = function() {
  __assign = Object.assign || function __assign2(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p))
          t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
typeof SuppressedError === "function" ? SuppressedError : function(error, suppressed, message) {
  var e = new Error(message);
  return e.name = "SuppressedError", e.error = error, e.suppressed = suppressed, e;
};
var docCache = /* @__PURE__ */ new Map();
var fragmentSourceMap = /* @__PURE__ */ new Map();
var printFragmentWarnings = true;
var experimentalFragmentVariables = false;
function normalize(string) {
  return string.replace(/[\s,]+/g, " ").trim();
}
function cacheKeyFromLoc(loc) {
  return normalize(loc.source.body.substring(loc.start, loc.end));
}
function processFragments(ast) {
  var seenKeys = /* @__PURE__ */ new Set();
  var definitions = [];
  ast.definitions.forEach(function(fragmentDefinition) {
    if (fragmentDefinition.kind === "FragmentDefinition") {
      var fragmentName = fragmentDefinition.name.value;
      var sourceKey = cacheKeyFromLoc(fragmentDefinition.loc);
      var sourceKeySet = fragmentSourceMap.get(fragmentName);
      if (sourceKeySet && !sourceKeySet.has(sourceKey)) {
        if (printFragmentWarnings) {
          console.warn("Warning: fragment with name " + fragmentName + " already exists.\ngraphql-tag enforces all fragment names across your application to be unique; read more about\nthis in the docs: http://dev.apollodata.com/core/fragments.html#unique-names");
        }
      } else if (!sourceKeySet) {
        fragmentSourceMap.set(fragmentName, sourceKeySet = /* @__PURE__ */ new Set());
      }
      sourceKeySet.add(sourceKey);
      if (!seenKeys.has(sourceKey)) {
        seenKeys.add(sourceKey);
        definitions.push(fragmentDefinition);
      }
    } else {
      definitions.push(fragmentDefinition);
    }
  });
  return __assign(__assign({}, ast), { definitions });
}
function stripLoc(doc) {
  var workSet = new Set(doc.definitions);
  workSet.forEach(function(node) {
    if (node.loc)
      delete node.loc;
    Object.keys(node).forEach(function(key) {
      var value2 = node[key];
      if (value2 && typeof value2 === "object") {
        workSet.add(value2);
      }
    });
  });
  var loc = doc.loc;
  if (loc) {
    delete loc.startToken;
    delete loc.endToken;
  }
  return doc;
}
function parseDocument(source) {
  var cacheKey = normalize(source);
  if (!docCache.has(cacheKey)) {
    var parsed = parse(source, {
      experimentalFragmentVariables,
      allowLegacyFragmentVariables: experimentalFragmentVariables
    });
    if (!parsed || parsed.kind !== "Document") {
      throw new Error("Not a valid GraphQL document.");
    }
    docCache.set(cacheKey, stripLoc(processFragments(parsed)));
  }
  return docCache.get(cacheKey);
}
function gql(literals) {
  var args = [];
  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }
  if (typeof literals === "string") {
    literals = [literals];
  }
  var result = literals[0];
  args.forEach(function(arg, i) {
    if (arg && arg.kind === "Document") {
      result += arg.loc.source.body;
    } else {
      result += arg;
    }
    result += literals[i + 1];
  });
  return parseDocument(result);
}
function resetCaches() {
  docCache.clear();
  fragmentSourceMap.clear();
}
function disableFragmentWarnings() {
  printFragmentWarnings = false;
}
function enableExperimentalFragmentVariables() {
  experimentalFragmentVariables = true;
}
function disableExperimentalFragmentVariables() {
  experimentalFragmentVariables = false;
}
var extras = {
  gql,
  resetCaches,
  disableFragmentWarnings,
  enableExperimentalFragmentVariables,
  disableExperimentalFragmentVariables
};
(function(gql_1) {
  gql_1.gql = extras.gql, gql_1.resetCaches = extras.resetCaches, gql_1.disableFragmentWarnings = extras.disableFragmentWarnings, gql_1.enableExperimentalFragmentVariables = extras.enableExperimentalFragmentVariables, gql_1.disableExperimentalFragmentVariables = extras.disableExperimentalFragmentVariables;
})(gql || (gql = {}));
gql["default"] = gql;
const useProjectTemplateStyles = makeStyles()((theme2) => ({
  projectTemplateCard: {
    width: theme2.spacing(30),
    height: theme2.spacing(18),
    display: "grid",
    gridTemplateRows: "1fr min-content"
  },
  templateCardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0
  },
  templateCardActions: {
    minWidth: 0
  },
  projectTemplateLabel: {
    textTransform: "none",
    fontWeight: 400,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));
const ProjectTemplateCard = ({ template, running, disabled, onCreateProject }) => {
  const { classes } = useProjectTemplateStyles();
  const { httpOrigin } = useContext(ServerContext);
  return /* @__PURE__ */ jsx(Button, { disabled, onClick: onCreateProject, "data-testid": `create-template-${template.label}`, children: /* @__PURE__ */ jsxs(Card, { className: classes.projectTemplateCard, children: [
    /* @__PURE__ */ jsx(CardContent, { className: classes.templateCardContent, children: running ? /* @__PURE__ */ jsx(CircularProgress, {}) : /* @__PURE__ */ jsx("img", { height: "80px", alt: `New ${template.label}`, src: httpOrigin + template.imageURL }) }),
    /* @__PURE__ */ jsx(CardActions, { className: classes.templateCardActions, children: /* @__PURE__ */ jsx(Tooltip, { title: template.label, children: /* @__PURE__ */ jsxs(Typography, { variant: "body1", className: classes.projectTemplateLabel, children: [
      "+ ",
      template.label
    ] }) }) })
  ] }) });
};
const useUploadProjectCardStyles = makeStyles()((theme2) => ({
  projectCard: {
    width: theme2.spacing(30),
    height: theme2.spacing(18),
    display: "grid",
    gridTemplateRows: "1fr min-content"
  },
  projectCardActions: {
    minWidth: 0
  },
  projectCardLabel: {
    textTransform: "none",
    fontWeight: 400,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  projectCardIcon: {
    fontSize: theme2.spacing(8)
  },
  uploadProjectCard: {
    backgroundColor: theme2.palette.divider,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
const UploadProjectCard = () => {
  const { classes } = useUploadProjectCardStyles();
  return /* @__PURE__ */ jsx(Button, { to: `/upload/project`, component: Link, "data-testid": "upload", children: /* @__PURE__ */ jsxs(Card, { className: classes.projectCard, children: [
    /* @__PURE__ */ jsx(CardContent, { className: classes.uploadProjectCard, children: /* @__PURE__ */ jsx(CloudUploadOutlinedIcon, { className: classes.projectCardIcon, htmlColor: "white" }) }),
    /* @__PURE__ */ jsx(CardActions, { className: classes.projectCardActions, children: /* @__PURE__ */ jsx(Tooltip, { title: "Upload project", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.projectCardLabel, children: "+ Upload project" }) }) })
  ] }) });
};
const redirectUrlFromTemplate = (projectCreatedFromTemplate) => {
  const { project, representationToOpen } = projectCreatedFromTemplate;
  if (representationToOpen) {
    return `/projects/${project.id}/edit/${representationToOpen.id}`;
  }
  return `/projects/${project.id}/edit`;
};
const createProjectFromTemplateMutation = gql$1`
  mutation createProjectFromTemplate($input: CreateProjectFromTemplateInput!) {
    createProjectFromTemplate(input: $input) {
      __typename
      ... on CreateProjectFromTemplateSuccessPayload {
        project {
          id
        }
        representationToOpen {
          id
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$8 = (payload) => payload.__typename === "ErrorPayload";
const isCreateProjectFromTemplateSuccessPayload = (payload) => payload.__typename === "CreateProjectFromTemplateSuccessPayload";
const useCreateProjectFromTemplate = () => {
  const [performProjectCreationFromTemplate, { loading, data, error }] = useMutation(createProjectFromTemplateMutation);
  const { addErrorMessage, addMessages } = useMultiToast();
  const { t: coreT } = useTranslation("siriusComponentsCore");
  useEffect(() => {
    if (error) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (data) {
      const { createProjectFromTemplate: createProjectFromTemplate2 } = data;
      if (isErrorPayload$8(createProjectFromTemplate2)) {
        addMessages(createProjectFromTemplate2.messages);
      }
    }
  }, [coreT, data, error]);
  const createProjectFromTemplate = (templateId) => {
    const variables = {
      input: {
        id: crypto.randomUUID(),
        templateId
      }
    };
    performProjectCreationFromTemplate({ variables });
  };
  let projectCreatedFromTemplate = null;
  if (data && isCreateProjectFromTemplateSuccessPayload(data.createProjectFromTemplate)) {
    projectCreatedFromTemplate = data.createProjectFromTemplate;
  }
  return {
    createProjectFromTemplate,
    loading,
    projectCreatedFromTemplate
  };
};
const getProjectTemplatesQuery = gql$1`
  query getProjectTemplates($page: Int!, $limit: Int!) {
    viewer {
      projectTemplates(page: $page, limit: $limit) {
        edges {
          node {
            id
            label
            imageURL
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          count
        }
      }
    }
  }
`;
const useProjectTemplates = (page, limit, skip) => {
  const variables = {
    page,
    limit
  };
  const { data, loading, error } = useQuery(
    getProjectTemplatesQuery,
    { variables, skip }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  return {
    data,
    loading
  };
};
gql`
  query getProjectTemplates($page: Int!) {
    viewer {
      projectTemplates(page: $page, limit: 12) {
        edges {
          node {
            id
            label
            imageURL
          }
        }
        pageInfo {
          count
        }
      }
    }
  }
`;
const useProjectTemplatesModalStyles = makeStyles()((theme2) => ({
  content: {
    display: "grid",
    gridTemplateRows: "1fr min-content",
    gap: theme2.spacing(2)
  },
  templateCards: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr 1fr 1fr"
  },
  navigation: {
    justifySelf: "center"
  }
}));
const ProjectTemplatesModal = ({ onClose }) => {
  const { classes: styles } = useProjectTemplatesModalStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [state, setState] = useState({
    page: 0,
    limit: 12,
    runningTemplate: null,
    projectTemplates: null,
    count: null
  });
  const lastPage = state.count ? Math.ceil((state.count + 2) / 12) : 0;
  const lastPageWithTemplates = state.count ? Math.ceil(state.count / 12) : 0;
  const skip = state.projectTemplates !== null && state.page > lastPageWithTemplates;
  const { data, loading } = useProjectTemplates(state.page, state.limit, skip);
  useEffect(() => {
    if (data) {
      const projectTemplates = data.viewer.projectTemplates.edges.map((edge) => edge.node) ?? [];
      const count = data.viewer.projectTemplates.pageInfo.count;
      setState((prevState) => ({ ...prevState, projectTemplates, count }));
    }
  }, [data]);
  const {
    createProjectFromTemplate,
    loading: createProjectLoading,
    projectCreatedFromTemplate
  } = useCreateProjectFromTemplate();
  const redirectUrl = projectCreatedFromTemplate ? redirectUrlFromTemplate(projectCreatedFromTemplate) : null;
  if (redirectUrl) {
    return /* @__PURE__ */ jsx(Redirect, { to: redirectUrl });
  }
  const cards = [];
  if (state.projectTemplates && state.page <= lastPageWithTemplates) {
    state.projectTemplates.map((template) => /* @__PURE__ */ jsx(
      ProjectTemplateCard,
      {
        template,
        disabled: createProjectLoading,
        running: template === state.runningTemplate,
        onCreateProject: () => createProjectFromTemplate(template.id)
      },
      template.id
    )).forEach((card) => cards.push(card));
    if (cards.length < 12) {
      cards.push(/* @__PURE__ */ jsx(NewProjectCard, {}, "new-project"));
    }
    if (cards.length < 12) {
      cards.push(/* @__PURE__ */ jsx(UploadProjectCard, {}, "upload-project"));
    }
  } else if (state.count % 12 === 11) {
    cards.push(/* @__PURE__ */ jsx(UploadProjectCard, {}, "upload-project"));
  } else if (state.count % 12 === 0) {
    cards.push(/* @__PURE__ */ jsx(NewProjectCard, {}, "new-project"));
    cards.push(/* @__PURE__ */ jsx(UploadProjectCard, {}, "upload-project"));
  }
  let content;
  if (loading) {
    content = /* @__PURE__ */ jsx(Typography, { children: coreT("messages.loading") });
  } else {
    content = /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx("div", { className: styles.templateCards, "data-testid": "project-templates-cards", children: cards }),
      /* @__PURE__ */ jsx(
        Pagination,
        {
          className: styles.navigation,
          page: state.page + 1,
          count: lastPage,
          onChange: (_, value2) => {
            const page = value2;
            setState((prevState) => ({ ...prevState, page }));
          }
        }
      )
    ] });
  }
  return /* @__PURE__ */ jsxs(
    Dialog,
    {
      open: true,
      onClose: () => {
        if (!loading) {
          onClose();
        }
      },
      "aria-labelledby": "dialog-title",
      "data-testid": "project-templates-modal",
      maxWidth: "md",
      fullWidth: true,
      children: [
        /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("selectProjectTemplate") }),
        /* @__PURE__ */ jsx(DialogContent, { className: styles.content, children: content })
      ]
    }
  );
};
const useShowAllProjectTemplatesCardStyles = makeStyles()((theme2) => ({
  projectCard: {
    width: theme2.spacing(30),
    height: theme2.spacing(18),
    display: "grid",
    gridTemplateRows: "1fr min-content"
  },
  projectCardActions: {
    minWidth: 0
  },
  projectCardLabel: {
    textTransform: "none",
    fontWeight: 400,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  projectCardIcon: {
    fontSize: theme2.spacing(8)
  },
  blankProjectCard: {
    backgroundColor: theme2.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  uploadProjectCard: {
    backgroundColor: theme2.palette.divider,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  showAllTemplatesCardContent: {
    backgroundColor: theme2.palette.divider,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
const ShowAllProjectTemplatesCard = () => {
  const { classes } = useShowAllProjectTemplatesCardStyles();
  const [state, setState] = useState({
    modalDisplayed: null
  });
  const showAllTemplatesModal = () => setState((prevState) => ({ ...prevState, modalDisplayed: "SHOW_ALL_TEMPLATES" }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modalDisplayed: null }));
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Button, { onClick: showAllTemplatesModal, "data-testid": "show-all-templates", children: /* @__PURE__ */ jsxs(Card, { className: classes.projectCard, children: [
      /* @__PURE__ */ jsx(CardContent, { className: classes.showAllTemplatesCardContent, children: /* @__PURE__ */ jsx(MoreHorizIcon, { className: classes.projectCardIcon, htmlColor: "white" }) }),
      /* @__PURE__ */ jsx(CardActions, { className: classes.projectCardActions, children: /* @__PURE__ */ jsx(Tooltip, { title: "Show all templates", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.projectCardLabel, children: "Show all templates" }) }) })
    ] }) }),
    state.modalDisplayed === "SHOW_ALL_TEMPLATES" ? /* @__PURE__ */ jsx(ProjectTemplatesModal, { onClose: closeModal }) : null
  ] });
};
const deleteImageModalMachine = Machine(
  {
    type: "parallel",
    context: {
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      deleteImageModal: {
        initial: "idle",
        states: {
          idle: {
            on: {
              REQUEST_IMAGE_DELETION: {
                target: "deletingImage"
              }
            }
          },
          deletingImage: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success"
                },
                {
                  target: "idle"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.deleteImage.__typename === "SuccessPayload";
      }
    }
  }
);
const deleteImageMutation = gql$1`
  mutation deleteImage($input: DeleteImageInput!) {
    deleteImage(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$7 = (payload) => payload.__typename === "ErrorPayload";
const DeleteImageModal = ({ imageId, onImageDeleted, onClose }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.delete" });
  const [{ value: value2, context }, dispatch] = useMachine(
    deleteImageModalMachine
  );
  const { toast, deleteImageModal } = value2;
  const { message } = context;
  const [deleteImage, { loading, data, error }] = useMutation(deleteImageMutation);
  useEffect(() => {
    if (!loading) {
      if (error) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: error.message
        };
        dispatch(showToastEvent);
      }
      if (data) {
        const event = { type: "HANDLE_RESPONSE", data };
        dispatch(event);
        const { deleteImage: deleteImage2 } = data;
        if (isErrorPayload$7(deleteImage2)) {
          const { message: message2 } = deleteImage2;
          const showToastEvent = { type: "SHOW_TOAST", message: message2 };
          dispatch(showToastEvent);
        }
      }
    }
  }, [loading, data, error, dispatch]);
  const onDeleteImage = (event) => {
    const requestImageDeletionEvent = { type: "REQUEST_IMAGE_DELETION" };
    dispatch(requestImageDeletionEvent);
    event.preventDefault();
    const variables = {
      input: {
        id: crypto.randomUUID(),
        imageId
      }
    };
    deleteImage({ variables });
  };
  useEffect(() => {
    if (deleteImageModal === "success") {
      onImageDeleted();
    }
  }, [deleteImageModal, onImageDeleted]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
      /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
      /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(DialogContentText, { children: t("content") }) }),
      /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: deleteImageModal !== "idle",
          onClick: onDeleteImage,
          color: "primary",
          "data-testid": "delete-image",
          children: t("submit")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const isNameInvalid$1 = (name) => name.trim().length < 3;
const renameImageModalMachine = Machine(
  {
    type: "parallel",
    context: {
      name: null,
      nameIsInvalid: false,
      initialName: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      renameImageModal: {
        initial: "pristine",
        states: {
          pristine: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isPristine",
                  target: "pristine",
                  actions: "updateName"
                },
                {
                  cond: "isInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ]
            }
          },
          valid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isPristine",
                  target: "pristine",
                  actions: "updateName"
                },
                {
                  cond: "isInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ],
              REQUEST_IMAGE_RENAMING: {
                target: "renamingImage"
              }
            }
          },
          invalid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isPristine",
                  target: "pristine",
                  actions: "updateName"
                },
                {
                  cond: "isInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ]
            }
          },
          renamingImage: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success"
                },
                {
                  target: "valid"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isInvalid: (_, event) => {
        const { name } = event;
        return isNameInvalid$1(name);
      },
      isPristine: (context, event) => {
        const { name } = event;
        const { initialName } = context;
        return name === initialName;
      },
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.renameImage.__typename === "SuccessPayload";
      }
    },
    actions: {
      updateName: assign((_, event) => {
        const { name } = event;
        return { name, nameIsInvalid: isNameInvalid$1(name) };
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const renameImageMutation = gql$1`
  mutation renameImage($input: RenameImageInput!) {
    renameImage(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$6 = (payload) => payload.__typename === "ErrorPayload";
const RenameImageModal = ({ imageId, initialImageName, onImageRenamed, onClose }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.rename" });
  const [{ value: value2, context }, dispatch] = useMachine(
    renameImageModalMachine,
    {
      context: {
        name: initialImageName,
        initialName: initialImageName
      }
    }
  );
  const { toast, renameImageModal } = value2;
  const { name, nameIsInvalid, message } = context;
  const onNewName = (event) => {
    const name2 = event.target.value;
    const changeNameEvent = { type: "CHANGE_NAME", name: name2 };
    dispatch(changeNameEvent);
  };
  const [renameImage, { loading, data, error }] = useMutation(renameImageMutation);
  useEffect(() => {
    if (!loading) {
      if (error) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: error.message
        };
        dispatch(showToastEvent);
      }
      if (data) {
        const event = { type: "HANDLE_RESPONSE", data };
        dispatch(event);
        const { renameImage: renameImage2 } = data;
        if (isErrorPayload$6(renameImage2)) {
          const { message: message2 } = renameImage2;
          const showToastEvent = { type: "SHOW_TOAST", message: message2 };
          dispatch(showToastEvent);
        }
      }
    }
  }, [loading, data, error, dispatch]);
  const onRenameImage = (event) => {
    const requestImageRenamingEvent = { type: "REQUEST_IMAGE_RENAMING" };
    dispatch(requestImageRenamingEvent);
    event.preventDefault();
    const variables = {
      input: {
        id: crypto.randomUUID(),
        imageId,
        newLabel: name
      }
    };
    renameImage({ variables });
  };
  useEffect(() => {
    if (renameImageModal === "success") {
      onImageRenamed();
    }
  }, [renameImageModal, onImageRenamed]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
      /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
      /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(
        TextField,
        {
          variant: "standard",
          value: name,
          onChange: onNewName,
          error: nameIsInvalid,
          helperText: t("name.helperText"),
          label: t("name.label"),
          placeholder: t("name.placeholder"),
          "data-testid": "name",
          autoFocus: true,
          fullWidth: true
        }
      ) }),
      /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: renameImageModal !== "valid",
          onClick: onRenameImage,
          color: "primary",
          "data-testid": "rename-image",
          children: t("submit")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const ImagePreviewTooltip = withStyles(Tooltip, () => ({
  tooltip: {
    backgroundColor: "#f5f5f5",
    color: "rgba(0, 0, 0)",
    maxWidth: 220,
    border: "1px solid #dadde9"
  }
}));
const ImageRow = ({ image, onImageUpdated }) => {
  const { httpOrigin } = useContext(ServerContext);
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.list" });
  const [state, setState] = useState({
    modal: null,
    showEditIcon: false
  });
  const onRename = () => setState((prevState) => ({ ...prevState, modal: "Rename" }));
  const onDelete = () => setState((prevState) => ({ ...prevState, modal: "Delete" }));
  const toggleShowEditIcon = (value2) => setState((prevState) => ({ ...prevState, showEditIcon: value2 }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modal: null }));
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(TableRow, { hover: true, onMouseEnter: () => toggleShowEditIcon(true), onMouseLeave: () => toggleShowEditIcon(false), children: [
      /* @__PURE__ */ jsx(TableCell, { children: /* @__PURE__ */ jsxs(Typography, { children: [
        image.label,
        " ",
        state.showEditIcon ? /* @__PURE__ */ jsx(IconButton, { onClick: onRename, children: /* @__PURE__ */ jsx(EditOutlinedIcon, { fontSize: "small" }) }) : null
      ] }) }),
      /* @__PURE__ */ jsx(TableCell, { children: /* @__PURE__ */ jsx(Typography, { component: "div", children: /* @__PURE__ */ jsxs(Box, { fontFamily: "Monospace", fontSize: "small", children: [
        image.id,
        " ",
        /* @__PURE__ */ jsx(Tooltip, { title: t("copyId"), children: /* @__PURE__ */ jsx(IconButton, { onClick: () => navigator.clipboard.writeText(image.id), children: /* @__PURE__ */ jsx(FileCopyOutlinedIcon, { fontSize: "small" }) }) }),
        /* @__PURE__ */ jsx(ImagePreviewTooltip, { enterDelay: 250, title: /* @__PURE__ */ jsx("img", { src: httpOrigin + image.url, width: 120 }), children: /* @__PURE__ */ jsx(IconButton, { children: /* @__PURE__ */ jsx(ImageOutlinedIcon, { fontSize: "small" }) }) })
      ] }) }) }),
      /* @__PURE__ */ jsx(TableCell, { align: "center", children: /* @__PURE__ */ jsx(IconButton, { onClick: onDelete, children: /* @__PURE__ */ jsx(DeleteIcon, {}) }) })
    ] }),
    state.modal === "Rename" ? /* @__PURE__ */ jsx(
      RenameImageModal,
      {
        imageId: image.id,
        initialImageName: image.label,
        onImageRenamed: () => {
          onImageUpdated();
          closeModal();
        },
        onClose: closeModal
      }
    ) : null,
    state.modal === "Delete" ? /* @__PURE__ */ jsx(
      DeleteImageModal,
      {
        imageId: image.id,
        onImageDeleted: () => {
          onImageUpdated();
          closeModal();
        },
        onClose: closeModal
      }
    ) : null
  ] });
};
const ImageTable = ({ images, onImageUpdated }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.list" });
  return /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsx(TableContainer, { children: /* @__PURE__ */ jsxs(Table, { children: [
    /* @__PURE__ */ jsxs("colgroup", { children: [
      /* @__PURE__ */ jsx("col", { width: "35%" }),
      /* @__PURE__ */ jsx("col", { width: "60%" }),
      /* @__PURE__ */ jsx("col", { width: "5%" })
    ] }),
    /* @__PURE__ */ jsx(TableHead, { children: /* @__PURE__ */ jsxs(TableRow, { children: [
      /* @__PURE__ */ jsx(TableCell, { variant: "head", children: t("label") }),
      /* @__PURE__ */ jsx(TableCell, { variant: "head", children: t("id") }),
      /* @__PURE__ */ jsx(TableCell, { variant: "head", align: "center", children: t("delete") })
    ] }) }),
    /* @__PURE__ */ jsx(TableBody, { "data-testid": "images", children: images.map((image) => /* @__PURE__ */ jsx(ImageRow, { image, onImageUpdated }, image.id)) })
  ] }) }) });
};
const useFileUploadViewStyles = makeStyles()((theme2) => ({
  fileUpload: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    cursor: "pointer",
    height: 150,
    alignItems: "center",
    justifyItems: "center",
    border: `1px dashed ${theme2.palette.secondary.main}`
  },
  inputfile: {
    width: 0.1,
    height: 0.1,
    opacity: "0",
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  },
  message: {
    fontSize: "16px",
    color: theme2.palette.text.primary,
    fontWeight: "bold"
  }
}));
const FileUpload = ({ onFileSelected, "data-testid": dataTestId }) => {
  const { classes: styles } = useFileUploadViewStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "core" });
  const fileInput = React.createRef();
  const [state, setState] = useState({
    file: null,
    message: t("clickToSelectFile")
  });
  const { file, message } = state;
  useEffect(() => {
    let message2 = t("clickToSelectFile");
    if (file) {
      message2 = file.name;
    }
    setState((prevState) => {
      return { ...prevState, message: message2 };
    });
  }, [file, t]);
  const onFileInputChange = () => {
    const { files } = fileInput.current;
    let file2 = null;
    if (files.length === 1) {
      file2 = files[0];
    }
    onFileSelected(file2);
    setState((prevState) => {
      return { ...prevState, file: file2 };
    });
  };
  return /* @__PURE__ */ jsxs("label", { className: styles.fileUpload, children: [
    /* @__PURE__ */ jsx(
      "input",
      {
        type: "file",
        name: "file",
        id: "file",
        className: styles.inputfile,
        ref: fileInput,
        onChange: onFileInputChange,
        "data-testid": dataTestId
      }
    ),
    /* @__PURE__ */ jsx(Typography, { className: styles.message, children: message })
  ] });
};
const sendFile = async (httpOrigin, query, variables, file) => {
  const operations = {
    query,
    variables
  };
  const formData = new FormData();
  formData.append("operations", JSON.stringify(operations));
  formData.append("map", JSON.stringify({ "0": "variables.file" }));
  formData.append("0", file);
  const csrfToken = getCookie("XSRF-TOKEN");
  const response = await fetch(`${httpOrigin}/api/graphql/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
    mode: "cors",
    headers: {
      "X-XSRF-TOKEN": csrfToken
    }
  });
  return await response.json();
};
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
const uploadImageModalMachine = Machine(
  {
    type: "parallel",
    context: {
      message: void 0,
      file: void 0
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      uploadImageModal: {
        initial: "pristine",
        states: {
          pristine: {
            on: {
              SELECT_IMAGE: {
                target: "imageSelected",
                actions: "updateSelectedFile"
              }
            }
          },
          imageSelected: {
            on: {
              REQUEST_IMAGE_UPLOADING: {
                target: "uploadingImage"
              },
              SELECT_IMAGE: {
                target: "imageSelected",
                actions: "updateSelectedFile"
              }
            }
          },
          uploadingImage: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success"
                },
                {
                  target: "imageSelected"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.uploadImage.__typename === "UploadImageSuccessPayload";
      }
    },
    actions: {
      updateSelectedFile: assign((_, event) => {
        const { file } = event;
        return { file };
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const uploadImageMutationFile = gql$1`
  mutation uploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      __typename
      ... on UploadImageSuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`.loc.source.body;
const useUploadImageModalStyle = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(1),
    paddingLeft: theme2.spacing(2),
    paddingRight: theme2.spacing(2),
    "& > *": {
      marginBottom: theme2.spacing(2)
    }
  }
}));
const isErrorPayload$5 = (payload) => payload.__typename === "ErrorPayload";
const UploadImageModal = ({ projectId, onImageUploaded, onClose }) => {
  const { classes } = useUploadImageModalStyle();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.upload" });
  const { httpOrigin } = useContext(ServerContext);
  const [{ value: value2, context }, dispatch] = useMachine(
    uploadImageModalMachine
  );
  const { toast, uploadImageModal } = value2;
  const { file, message } = context;
  const [label, setLabel] = useState("");
  const uploadImage = async (event) => {
    const requestImageUploadingEvent = { type: "REQUEST_IMAGE_UPLOADING" };
    dispatch(requestImageUploadingEvent);
    event.preventDefault();
    const variables = {
      input: {
        id: crypto.randomUUID(),
        projectId,
        label,
        file: null
        // the file will be send as a part of the multipart POST query.
      }
    };
    try {
      const { data, error } = await sendFile(httpOrigin, uploadImageMutationFile, variables, file);
      if (error) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: "An unexpected error has occurred, the file uploaded may be too large"
        };
        dispatch(showToastEvent);
      }
      if (data) {
        const event2 = { type: "HANDLE_RESPONSE", data };
        dispatch(event2);
        const { uploadImage: uploadImage2 } = data;
        if (isErrorPayload$5(uploadImage2)) {
          const { message: message2 } = uploadImage2;
          const showToastEvent = { type: "SHOW_TOAST", message: message2 };
          dispatch(showToastEvent);
        }
      }
    } catch (exception) {
      const showToastEvent = {
        type: "SHOW_TOAST",
        message: "An unexpected error has occurred, the file uploaded may be too large"
      };
      dispatch(showToastEvent);
    }
  };
  const onFileSelected = (file2) => {
    const selectImageEvent = { type: "SELECT_IMAGE", file: file2 };
    dispatch(selectImageEvent);
  };
  useEffect(() => {
    if (uploadImageModal === "success") {
      onImageUploaded();
    }
  }, [uploadImageModal, onImageUploaded]);
  const canSubmit = uploadImageModal === "imageSelected";
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", fullWidth: true, children: [
      /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
      /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs("form", { id: "upload-form-id", onSubmit: uploadImage, encType: "multipart/form-data", className: classes.form, children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            variant: "standard",
            label: t("label.label"),
            name: "label",
            value: label,
            placeholder: t("label.placeholder"),
            "data-testid": "label",
            inputProps: { "data-testid": "label-input" },
            autoFocus: true,
            onChange: (event) => setLabel(event.target.value),
            disabled: uploadImageModal === "uploadingImage"
          }
        ),
        /* @__PURE__ */ jsx(FormGroup, { children: /* @__PURE__ */ jsx(FileUpload, { onFileSelected, "data-testid": "file" }) })
      ] }) }),
      /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: !canSubmit,
          color: "primary",
          type: "submit",
          form: "upload-form-id",
          "data-testid": "upload-image",
          children: t("submit")
        }
      ) })
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const getProjectImagesQuery = gql$1`
  query projectImages($projectId: ID!) {
    viewer {
      project(projectId: $projectId) {
        images {
          id
          label
          url
        }
      }
    }
  }
`;
const useProjectImages = (projectId) => {
  const variables = {
    projectId
  };
  const { data, loading, error, refetch } = useQuery(
    getProjectImagesQuery,
    { variables }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  const refreshImages = () => refetch(variables);
  return { data: data ?? null, loading, refreshImages };
};
const useProjectImagesSettingsStyles = makeStyles()((theme2) => ({
  imageSettingsViewContainer: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme2.spacing(5)
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginLeft: theme2.spacing(2)
    }
  }
}));
const ProjectImagesSettings = ({}) => {
  var _a;
  const [state, setState] = useState({
    modal: null
  });
  const { classes } = useProjectImagesSettingsStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "image.list" });
  const { projectId } = useParams();
  const { data, loading, refreshImages } = useProjectImages(projectId);
  const onTriggerUpload = () => setState((prevState) => ({ ...prevState, modal: "Upload" }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modal: null }));
  const onImageUpdated = () => {
    closeModal();
    refreshImages();
  };
  let main = null;
  if (!loading && data) {
    const images = ((_a = data == null ? void 0 : data.viewer.project) == null ? void 0 : _a.images) ?? [];
    if (images.length > 0) {
      main = /* @__PURE__ */ jsx(ImageTable, { images, onImageUpdated });
    } else {
      main = /* @__PURE__ */ jsx(Grid, { container: true, justifyContent: "center", children: /* @__PURE__ */ jsx(Grid, { item: true, xs: 6, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", align: "center", gutterBottom: true, children: t("empty") }) }) });
    }
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.imageSettingsViewContainer, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.header, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h5", children: t("title") }),
        /* @__PURE__ */ jsx("div", { className: classes.actions, children: /* @__PURE__ */ jsx(Button, { "data-testid": "upload-image", color: "primary", variant: "outlined", onClick: onTriggerUpload, children: t("upload") }) })
      ] }),
      main
    ] }),
    state.modal === "Upload" ? /* @__PURE__ */ jsx(UploadImageModal, { projectId, onImageUploaded: onImageUpdated, onClose: closeModal }) : null
  ] });
};
const shouldTransform$1 = (document2) => {
  var _a;
  return document2.definitions[0] && document2.definitions[0].kind === Kind.OPERATION_DEFINITION && ((_a = document2.definitions[0].name) == null ? void 0 : _a.value) === "diagramEvent";
};
const isNodeStyleFragment = (field) => {
  if (field.name.value === "style") {
    const inLinesFragment = field.selectionSet.selections.filter((selection) => selection.kind === Kind.INLINE_FRAGMENT).map((inlineFragment) => inlineFragment.typeCondition.name.value);
    if (inLinesFragment.includes("RectangularNodeStyle") && inLinesFragment.includes("ImageNodeStyle")) {
      return true;
    }
  }
  return false;
};
const borderColorField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "borderColor"
  }
};
const borderSizeField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "borderSize"
  }
};
const borderStyleField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "borderStyle"
  }
};
const backgroundField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "background"
  }
};
const ellipseNodeStyleDocumentTransform = new DocumentTransform((document2) => {
  if (shouldTransform$1(document2)) {
    const transformedDocument = visit(document2, {
      Field(field) {
        var _a;
        if (!isNodeStyleFragment(field)) {
          return void 0;
        }
        const selections = ((_a = field.selectionSet) == null ? void 0 : _a.selections) ?? [];
        const ellipseNodeStyleInlineFragment = {
          kind: Kind.INLINE_FRAGMENT,
          selectionSet: {
            kind: Kind.SELECTION_SET,
            selections: [borderColorField, borderSizeField, borderStyleField, backgroundField]
          },
          typeCondition: {
            kind: Kind.NAMED_TYPE,
            name: {
              kind: Kind.NAME,
              value: "EllipseNodeStyle"
            }
          }
        };
        return {
          ...field,
          selectionSet: {
            ...field.selectionSet,
            selections: [...selections, ellipseNodeStyleInlineFragment]
          }
        };
      }
    });
    return transformedDocument;
  }
  return document2;
});
const shouldTransform = (document2) => {
  var _a, _b;
  return document2.definitions[0] && document2.definitions[0].kind === Kind.OPERATION_DEFINITION && (((_a = document2.definitions[0].name) == null ? void 0 : _a.value) === "detailsEvent" || ((_b = document2.definitions[0].name) == null ? void 0 : _b.value) === "formEvent");
};
const isWidgetFragment = (field) => {
  if (field.name.value === "widgets" || field.name.value === "children") {
    const fragmentSpreads = field.selectionSet.selections.filter((selection) => selection.kind === Kind.FRAGMENT_SPREAD).map((fragmentSpread) => fragmentSpread.name.value);
    if (fragmentSpreads.includes("widgetFields")) {
      return true;
    }
  }
  return false;
};
const labelField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "label"
  }
};
const iconURLField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "iconURL"
  }
};
const ownerIdField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "ownerId"
  }
};
const descriptionIdField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "descriptionId"
  }
};
const ownerKindField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "ownerKind"
  }
};
const referenceKindField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "referenceKind"
  }
};
const containmentField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "containment"
  }
};
const manyValuedField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "manyValued"
  }
};
const referenceField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "reference"
  },
  selectionSet: {
    kind: Kind.SELECTION_SET,
    selections: [ownerKindField, referenceKindField, containmentField, manyValuedField]
  }
};
const idField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "id"
  }
};
const kindField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "kind"
  }
};
const colorField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "color"
  }
};
const fontSizeField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "fontSize"
  }
};
const italicField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "italic"
  }
};
const boldField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "bold"
  }
};
const underlineField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "underline"
  }
};
const strikeThroughField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "strikeThrough"
  }
};
const referenceValuesField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "referenceValues"
  },
  selectionSet: {
    kind: Kind.SELECTION_SET,
    selections: [idField, labelField, kindField, iconURLField]
  }
};
const styleField = {
  kind: Kind.FIELD,
  name: {
    kind: Kind.NAME,
    value: "style"
  },
  selectionSet: {
    kind: Kind.SELECTION_SET,
    selections: [colorField, fontSizeField, italicField, boldField, underlineField, strikeThroughField]
  }
};
const referenceWidgetDocumentTransform = new DocumentTransform((document2) => {
  if (shouldTransform(document2)) {
    return visit(document2, {
      Field(field) {
        var _a;
        if (!isWidgetFragment(field)) {
          return void 0;
        }
        const selections = ((_a = field.selectionSet) == null ? void 0 : _a.selections) ?? [];
        const referenceWidgetInlineFragment = {
          kind: Kind.INLINE_FRAGMENT,
          selectionSet: {
            kind: Kind.SELECTION_SET,
            selections: [
              labelField,
              iconURLField,
              ownerIdField,
              descriptionIdField,
              referenceField,
              referenceValuesField,
              styleField
            ]
          },
          typeCondition: {
            kind: Kind.NAMED_TYPE,
            name: {
              kind: Kind.NAME,
              value: "ReferenceWidget"
            }
          }
        };
        return {
          ...field,
          selectionSet: {
            ...field.selectionSet,
            selections: [...selections, referenceWidgetInlineFragment]
          }
        };
      }
    });
  }
  return document2;
});
const projectSettingsTabExtensionPoint = {
  identifier: "projectSettings#tabContribution",
  fallback: []
};
const getType = (representation) => {
  const query = representation.kind.substring(representation.kind.indexOf("?") + 1, representation.kind.length);
  const params = new URLSearchParams(query);
  const type = params.get("type");
  return type;
};
const defaultExtensionRegistry = new ExtensionRegistry();
defaultExtensionRegistry.addComponent(workbenchMainAreaExtensionPoint, {
  identifier: `siriusweb_${workbenchMainAreaExtensionPoint.identifier}`,
  Component: OnboardArea
});
const workbenchViewContributions = [
  {
    side: "left",
    title: "Explorer",
    icon: /* @__PURE__ */ jsx(AccountTreeIcon, {}),
    component: ExplorerView
  },
  {
    side: "left",
    title: "Validation",
    icon: /* @__PURE__ */ jsx(WarningIcon, {}),
    component: ValidationView
  },
  {
    side: "right",
    title: "Details",
    icon: /* @__PURE__ */ jsx(MenuIcon, {}),
    component: DetailsView
  },
  {
    side: "right",
    title: "Representations",
    icon: /* @__PURE__ */ jsx(Filter, {}),
    component: RepresentationsView
  },
  {
    side: "right",
    title: "Related Elements",
    icon: /* @__PURE__ */ jsx(LinkIcon, {}),
    component: RelatedElementsView
  }
];
defaultExtensionRegistry.putData(workbenchViewContributionExtensionPoint, {
  identifier: `siriusweb_${workbenchViewContributionExtensionPoint.identifier}`,
  data: workbenchViewContributions
});
const representationFactories = [
  (representationMetadata) => getType(representationMetadata) === "Diagram" ? DiagramRepresentation : null,
  (representationMetadata) => getType(representationMetadata) === "Form" ? FormRepresentation : null,
  (representationMetadata) => getType(representationMetadata) === "FormDescriptionEditor" ? FormDescriptionEditorRepresentation : null,
  (representationMetadata) => getType(representationMetadata) === "Gantt" ? GanttRepresentation : null,
  (representationMetadata) => getType(representationMetadata) === "Deck" ? DeckRepresentation : null,
  (representationMetadata) => getType(representationMetadata) === "Portal" ? PortalRepresentation : null
];
defaultExtensionRegistry.putData(representationFactoryExtensionPoint, {
  identifier: `siriusweb_${representationFactoryExtensionPoint.identifier}`,
  data: representationFactories
});
defaultExtensionRegistry.addComponent(createProjectAreaCardExtensionPoint, {
  identifier: `siriusweb_${createProjectAreaCardExtensionPoint}_newProjectCard`,
  Component: NewProjectCard
});
defaultExtensionRegistry.addComponent(createProjectAreaCardExtensionPoint, {
  identifier: `siriusweb_${createProjectAreaCardExtensionPoint}_uploadProjectCard`,
  Component: UploadProjectCard
});
defaultExtensionRegistry.addComponent(createProjectAreaCardExtensionPoint, {
  identifier: `siriusweb_${createProjectAreaCardExtensionPoint}_showAllProjectTemplatesCard`,
  Component: ShowAllProjectTemplatesCard
});
defaultExtensionRegistry.addComponent(diagramPanelActionExtensionPoint, {
  identifier: `siriusweb_${diagramPanelActionExtensionPoint.identifier}_filter`,
  Component: DiagramFilter
});
const diagramDialogContributions = [
  {
    canHandle: (dialogDescriptionId) => {
      return dialogDescriptionId.startsWith("siriusComponents://selectionDialogDescription");
    },
    component: SelectionDialog
  }
];
defaultExtensionRegistry.putData(diagramDialogContributionExtensionPoint, {
  identifier: `siriusweb_${diagramDialogContributionExtensionPoint.identifier}`,
  data: diagramDialogContributions
});
defaultExtensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `siriusweb_${treeItemContextMenuEntryExtensionPoint.identifier}_document`,
  Component: DocumentTreeItemContextMenuContribution
});
defaultExtensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `siriusweb_${treeItemContextMenuEntryExtensionPoint.identifier}_object`,
  Component: ObjectTreeItemContextMenuContribution
});
defaultExtensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `siriusweb_${treeItemContextMenuEntryExtensionPoint.identifier}_diagram`,
  Component: DiagramTreeItemContextMenuContribution
});
const nodesApolloClientOptionsConfigurer = (currentOptions) => {
  const { documentTransform } = currentOptions;
  const newDocumentTransform = documentTransform ? documentTransform.concat(ellipseNodeStyleDocumentTransform) : ellipseNodeStyleDocumentTransform;
  return {
    ...currentOptions,
    documentTransform: newDocumentTransform
  };
};
const widgetsApolloClientOptionsConfigurer = (currentOptions) => {
  const { documentTransform } = currentOptions;
  const newDocumentTransform = documentTransform ? documentTransform.concat(referenceWidgetDocumentTransform) : referenceWidgetDocumentTransform;
  return {
    ...currentOptions,
    documentTransform: newDocumentTransform
  };
};
defaultExtensionRegistry.putData(apolloClientOptionsConfigurersExtensionPoint, {
  identifier: `siriusWeb_${apolloClientOptionsConfigurersExtensionPoint.identifier}`,
  data: [nodesApolloClientOptionsConfigurer, widgetsApolloClientOptionsConfigurer]
});
const isReferenceWidget = (widget) => widget.__typename === "ReferenceWidget";
defaultExtensionRegistry.putData(widgetContributionExtensionPoint, {
  identifier: `siriusWeb_${widgetContributionExtensionPoint.identifier}_referenceWidget`,
  data: [
    {
      name: "ReferenceWidget",
      icon: /* @__PURE__ */ jsx(ReferenceIcon, {}),
      previewComponent: ReferencePreview,
      component: (widget) => {
        let propertySectionComponent = null;
        if (isReferenceWidget(widget)) {
          propertySectionComponent = ReferencePropertySection;
        }
        return propertySectionComponent;
      }
    }
  ]
});
const defaultSettingPages = [
  {
    id: "images",
    title: "Images",
    icon: /* @__PURE__ */ jsx(ImageIcon, {}),
    component: ProjectImagesSettings
  }
];
defaultExtensionRegistry.putData(projectSettingsTabExtensionPoint, {
  identifier: `siriusWeb_${projectSettingsTabExtensionPoint.identifier}`,
  data: defaultSettingPages
});
class DefaultExtensionRegistryMergeStrategy {
  mergeComponentExtensions(_identifier, existingValues, newValues) {
    return [...existingValues, ...newValues];
  }
  mergeDataExtensions(_identifier, _existingValue, newValue) {
    return newValue;
  }
}
const EditingContextStereotypesFragment = gql$1`
  fragment EditingContextStereotypes on EditingContext {
    stereotypes {
      edges {
        node {
          ...Stereotype
        }
      }
    }
  }
`;
const StereotypeFragment = gql$1`
  fragment Stereotype on Stereotype {
    id
    label
  }
`;
const ViewerProjectsFragment = gql$1`
  fragment ViewerProjects on Viewer {
    projects(page: $page, limit: $limit) {
      edges {
        node {
          ...Project
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        count
      }
    }
  }
`;
const ProjectFragment = gql$1`
  fragment Project on Project {
    id
    name
  }
`;
const useCreateApolloClient = (httpOrigin, wsOrigin) => {
  let httpOptions = {
    uri: `${httpOrigin}/api/graphql`
  };
  const { data: httpOptionsConfigurers } = useData(httpOptionsConfigurersExtensionPoint);
  httpOptionsConfigurers.forEach((configurer) => {
    httpOptions = configurer(httpOptions);
  });
  let webSocketOptions = {
    uri: `${wsOrigin}/subscriptions`,
    options: {
      reconnect: true,
      lazy: true
    }
  };
  const { data: webSocketOptionsConfigurers } = useData(webSocketOptionsConfigurersExtensionPoint);
  webSocketOptionsConfigurers.forEach((configurer) => {
    webSocketOptions = configurer(webSocketOptions);
  });
  let fragmentRegistry = createFragmentRegistry();
  fragmentRegistry.register(
    ViewerProjectsFragment,
    ProjectFragment,
    EditingContextStereotypesFragment,
    StereotypeFragment
  );
  let cacheOptions = {
    fragments: fragmentRegistry
  };
  const { data: cacheOptionsConfigurers } = useData(cacheOptionsConfigurersExtensionPoint);
  cacheOptionsConfigurers.forEach((configurer) => {
    cacheOptions = configurer(cacheOptions);
  });
  const httpLink = new HttpLink(httpOptions);
  const wsLink = new WebSocketLink(webSocketOptions);
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    httpLink
  );
  const cache = new InMemoryCache(cacheOptions);
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache"
    },
    query: {
      fetchPolicy: "no-cache"
    },
    mutate: {
      fetchPolicy: "no-cache"
    }
  };
  let apolloClientOptions = {
    link,
    cache,
    connectToDevTools: true,
    defaultOptions
  };
  const { data: apolloClientOptionsConfigurers } = useData(apolloClientOptionsConfigurersExtensionPoint);
  apolloClientOptionsConfigurers.forEach((configurer) => {
    apolloClientOptions = configurer(apolloClientOptions);
  });
  const apolloClient = useMemo(() => new ApolloClient(apolloClientOptions), [apolloClientOptions]);
  return apolloClient;
};
const ApolloGraphQLProvider = ({ children, httpOrigin, wsOrigin }) => {
  const apolloClient = useCreateApolloClient(httpOrigin, wsOrigin);
  return /* @__PURE__ */ jsx(ApolloProvider, { client: apolloClient, children });
};
const withErrorBoundary = (Child) => {
  return class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        children: this.props.children
      };
    }
    /**
     * This is called by React when one of our children component has raised an error.
     * We simply take note of it in the state for the next render() call to decide
     * what to do.
     *
     * @param {*} error the error that was raised.
     */
    static getDerivedStateFromError(error) {
      console.error("ErrorBoundary caught error", error);
      if (error) {
        return { error };
      }
      return { error: null };
    }
    /**
     * Some magic spell to make this actually work. Ask @sbegaudeau.
     *
     * @param {*} props
     * @param {*} state
     */
    static getDerivedStateFromProps(props, state) {
      if (state.children && state.children !== props.children) {
        return { error: null, children: props.children };
      }
      return state;
    }
    render() {
      if (this.state.error) {
        return /* @__PURE__ */ jsx("p", { children: "An error has occurred, please contact your administrator or refresh the page..." });
      }
      return /* @__PURE__ */ jsx(Child, {});
    }
  };
};
const SiriusIcon = (props) => {
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 25",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: [
        /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0)", children: [
          /* @__PURE__ */ jsx("path", { d: "M10.1054 17.4819C13.7518 18.4703 16.5064 21.1326 17.7781 24.4164C15.9477 22.008 13.1123 20.0623 9.66922 19.1289C6.21873 18.1936 2.80132 18.4462 0.0227487 19.6124C2.74792 17.4056 6.45436 16.4922 10.1054 17.4819Z" }),
          /* @__PURE__ */ jsx("path", { d: "M6.936 11.9146C7.90778 8.24374 6.97835 4.50902 4.76483 1.75357C5.93765 4.55604 6.20426 8.00141 5.28665 11.4677C4.36706 14.9414 2.43235 17.79 0.024883 19.6238C3.30927 18.3588 5.96295 15.5903 6.936 11.9146Z" }),
          /* @__PURE__ */ jsx("path", { d: "M7.4569 13.6389L9.49624 12.8515L10.1683 10.7577L10.956 12.8147L13.0362 13.498L10.9969 14.2854L10.3248 16.3792L9.53719 14.3223L7.4569 13.6389Z" }),
          /* @__PURE__ */ jsx("path", { d: "M13.9123 7.37704C10.2681 6.3929 7.51923 3.72617 6.25486 0.432119C8.0799 2.84596 10.9111 4.7931 14.3522 5.72239C17.8007 6.65367 21.2188 6.39226 24.0001 5.21506C21.2699 7.43693 17.5613 8.36247 13.9123 7.37704Z" }),
          /* @__PURE__ */ jsx("path", { d: "M17.0984 12.939C16.1219 16.6119 17.0389 20.3445 19.2373 23.0951C18.0756 20.2952 17.8181 16.8505 18.7401 13.3824C19.6642 9.90675 21.5978 7.05405 24 5.21509C20.7257 6.48713 18.0761 9.26133 17.0984 12.939Z" }),
          /* @__PURE__ */ jsx("path", { d: "M13.5109 10.7228L14.7562 10.2402L15.1683 8.9612L15.6471 10.2163L16.9161 10.6316L15.6708 11.1143L15.2587 12.3932L14.7799 11.1381L13.5109 10.7228Z" })
        ] }),
        /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "clip0", children: /* @__PURE__ */ jsx("rect", { width: "24.0006", height: "25" }) }) })
      ]
    }
  );
};
const NavigationBarIcon = ({}) => /* @__PURE__ */ jsx(SiriusIcon, { fontSize: "large" });
const navigationBarIconExtensionPoint = {
  identifier: "navigationBar#icon",
  FallbackComponent: NavigationBarIcon
};
const navigationBarLeftContributionExtensionPoint = {
  identifier: "navigationBar#leftContribution",
  FallbackComponent: () => null
};
const navigationBarRightContributionExtensionPoint = {
  identifier: "navigationBar#rightContribution",
  FallbackComponent: () => null
};
const useHelpStyle = makeStyles()((theme2) => ({
  onDarkBackground: {
    "&:hover": {
      backgroundColor: emphasize(theme2.palette.secondary.main, 0.08)
    }
  }
}));
const Help = ({ onClick }) => {
  const { classes } = useHelpStyle();
  return /* @__PURE__ */ jsx(IconButton, { className: classes.onDarkBackground, color: "inherit", onClick, children: /* @__PURE__ */ jsx(HelpIcon, {}) });
};
const navigationBarMenuIconExtensionPoint = {
  identifier: "navigationBarMenu#icon",
  FallbackComponent: Help
};
const navigationBarMenuHelpURLExtensionPoint = {
  identifier: "navigationBarMenu#helpURL",
  fallback: "https://eclipse.dev/sirius/sirius-web.html"
};
const navigationBarMenuEntryExtensionPoint = {
  identifier: "navigationBarMenu#entry",
  FallbackComponent: () => null
};
const NavigationBarMenu = ({}) => {
  const [state, setState] = useState({
    menuAnchor: null
  });
  const { Component: MenuIcon2 } = useComponent(navigationBarMenuIconExtensionPoint);
  const { data: url } = useData(navigationBarMenuHelpURLExtensionPoint);
  const menuItemComponentExtensions = useComponents(
    navigationBarMenuEntryExtensionPoint
  );
  const handleClick = (event) => setState((prevState) => ({ ...prevState, menuAnchor: event.currentTarget }));
  const handleClose = () => setState((prevState) => ({ ...prevState, menuAnchor: null }));
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(MenuIcon2, { onClick: handleClick }),
    /* @__PURE__ */ jsxs(
      Menu,
      {
        "data-test-id": "navigationbar-menu",
        open: Boolean(state.menuAnchor),
        anchorEl: state.menuAnchor,
        onClose: handleClose,
        children: [
          /* @__PURE__ */ jsxs(MenuItem, { component: "a", href: url, target: "_blank", rel: "noopener noreferrer", children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(HelpIcon, {}) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: "Help" })
          ] }),
          menuItemComponentExtensions.map(({ Component: NavigationBarMenuItem }, index) => /* @__PURE__ */ jsx(NavigationBarMenuItem, {}, index))
        ]
      }
    )
  ] });
};
const useNavigationBarStyles = makeStyles()((theme2) => ({
  navbar: {
    display: "flex",
    flexDirection: "column"
  },
  appBarHeader: {
    height: "4px",
    backgroundColor: theme2.palette.navigationBar.border
  },
  toolbar: {
    display: "grid",
    gridTemplateColumns: "1fr min-content 1fr",
    backgroundColor: theme2.palette.navigationBar.background
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme2.spacing(1)
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  right: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme2.spacing(1)
  },
  onDarkBackground: {
    "&:hover": {
      backgroundColor: emphasize(theme2.palette.secondary.main, 0.08)
    }
  }
}));
const NavigationBar = ({ children }) => {
  const { classes } = useNavigationBarStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "navigationBar" });
  const { Component: Icon } = useComponent(navigationBarIconExtensionPoint);
  const leftContributions = useComponents(navigationBarLeftContributionExtensionPoint);
  const rightContributions = useComponents(navigationBarRightContributionExtensionPoint);
  return /* @__PURE__ */ jsxs("div", { className: classes.navbar, children: [
    /* @__PURE__ */ jsx("div", { className: classes.appBarHeader }),
    /* @__PURE__ */ jsx(AppBar, { position: "static", children: /* @__PURE__ */ jsxs(Toolbar, { className: classes.toolbar, variant: "dense", children: [
      /* @__PURE__ */ jsxs("div", { className: classes.left, children: [
        /* @__PURE__ */ jsx(Tooltip, { title: t("backToHomepage"), children: /* @__PURE__ */ jsx(Link$1, { component: Link, to: "/", className: classes.link, color: "inherit", children: /* @__PURE__ */ jsx(IconButton, { className: classes.onDarkBackground, color: "inherit", children: /* @__PURE__ */ jsx(Icon, {}) }) }) }),
        leftContributions.map(({ Component: LeftContribution }, index) => /* @__PURE__ */ jsx(LeftContribution, {}, index))
      ] }),
      /* @__PURE__ */ jsx("div", { children }),
      /* @__PURE__ */ jsxs("div", { className: classes.right, children: [
        rightContributions.map(({ Component: RightContribution }, index) => /* @__PURE__ */ jsx(RightContribution, {}, index)),
        /* @__PURE__ */ jsx(NavigationBarMenu, {})
      ] })
    ] }) })
  ] });
};
const deleteProjectMutation = gql$1`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$4 = (payload) => payload.__typename === "ErrorPayload";
const useDeleteProject = () => {
  const [performProjectDeletion, { loading, data, error }] = useMutation(deleteProjectMutation);
  const { addErrorMessage, addMessages } = useMultiToast();
  const { t: coreT } = useTranslation("siriusComponentsCore");
  useEffect(() => {
    if (error) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (data) {
      const { deleteProject: deleteProject2 } = data;
      if (isErrorPayload$4(deleteProject2)) {
        addMessages(deleteProject2.messages);
      }
    }
  }, [coreT, data, error]);
  const deleteProject = (projectId) => {
    const variables = {
      input: {
        id: crypto.randomUUID(),
        projectId
      }
    };
    performProjectDeletion({ variables });
  };
  const projectDeleted = (data == null ? void 0 : data.deleteProject.__typename) === "SuccessPayload";
  return {
    deleteProject,
    loading,
    projectDeleted
  };
};
const DeleteProjectModal = ({ project, onCancel, onSuccess }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.delete" });
  const { deleteProject, loading, projectDeleted } = useDeleteProject();
  const onDeleteProject = (event) => {
    event.preventDefault();
    deleteProject(project.id);
  };
  useEffect(() => {
    if (projectDeleted) {
      onSuccess();
    }
  }, [projectDeleted, onSuccess]);
  return /* @__PURE__ */ jsxs(Dialog, { open: true, onClose: onCancel, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
    /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title", { name: project.name }) }),
    /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(DialogContentText, { children: t("content") }) }),
    /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
      Button,
      {
        variant: "contained",
        disabled: loading,
        onClick: onDeleteProject,
        color: "primary",
        "data-testid": "delete-project",
        children: t("submit")
      }
    ) })
  ] });
};
const renameProjectMutation = gql$1`
  mutation renameProject($input: RenameProjectInput!) {
    renameProject(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$3 = (payload) => payload.__typename === "ErrorPayload";
const useRenameProject = () => {
  const [performProjectRename, { loading, data, error }] = useMutation(renameProjectMutation);
  const { addErrorMessage, addMessages } = useMultiToast();
  const { t: coreT } = useTranslation("siriusComponentsCore");
  useEffect(() => {
    if (error) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (data) {
      const { renameProject: renameProject2 } = data;
      if (isErrorPayload$3(renameProject2)) {
        addMessages(renameProject2.messages);
      }
    }
  }, [coreT, data, error]);
  const renameProject = (projectId, newName) => {
    const variables = {
      input: {
        id: crypto.randomUUID(),
        projectId,
        newName
      }
    };
    performProjectRename({ variables });
  };
  const projectRenamed = (data == null ? void 0 : data.renameProject.__typename) === "RenameProjectSuccessPayload";
  return {
    renameProject,
    loading,
    projectRenamed
  };
};
const RenameProjectModal = ({ project, onCancel, onSuccess }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.rename" });
  const [state, setState] = useState({
    newName: project.name,
    pristine: true
  });
  const onNewName = (event) => {
    const newName = event.target.value;
    setState((prevState) => ({ ...prevState, newName, pristine: false }));
  };
  const { renameProject, loading, projectRenamed } = useRenameProject();
  const onRenameProject = (event) => {
    event.preventDefault();
    renameProject(project.id, state.newName);
  };
  useEffect(() => {
    if (projectRenamed) {
      onSuccess();
    }
  }, [projectRenamed, onSuccess]);
  const nameIsInvalid = !state.pristine && (state.newName.trim().length === 0 || state.newName.trim().length > 1024);
  return /* @__PURE__ */ jsxs(Dialog, { open: true, onClose: onCancel, "aria-labelledby": "dialog-title", maxWidth: "xs", fullWidth: true, children: [
    /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
    /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsx(
      TextField,
      {
        variant: "standard",
        value: state.newName,
        onChange: onNewName,
        error: nameIsInvalid,
        helperText: t("name.helperText"),
        label: t("name.label"),
        placeholder: t("name.placeholder"),
        "data-testid": "rename-textfield",
        autoFocus: true,
        fullWidth: true
      }
    ) }),
    /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
      Button,
      {
        variant: "contained",
        disabled: loading,
        onClick: onRenameProject,
        color: "primary",
        "data-testid": "rename-project",
        children: t("submit")
      }
    ) })
  ] });
};
const value = {
  project: {
    id: "",
    name: "",
    natures: [],
    currentEditingContext: {
      id: ""
    }
  }
};
const ProjectContext = React.createContext(value);
const useCurrentProject = () => {
  const { project } = useContext(ProjectContext);
  return { project };
};
const editProjectNavbarSubtitleExtensionPoint = {
  identifier: "editProjectNavbar#subtitle",
  FallbackComponent: () => null
};
const isProjectRenamedEventPayload = (payload) => payload.__typename === "ProjectRenamedEventPayload";
const editProjectNavbarMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      to: null,
      modalDisplayed: null,
      projectMenuAnchor: null,
      projectName: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      navbar: {
        initial: "empty",
        states: {
          empty: {
            on: {
              HANDLE_SHOW_CONTEXT_MENU_EVENT: {
                target: "contextualMenuDisplayedState",
                actions: "showContextMenu"
              },
              HANDLE_SUBSCRIPTION_RESULT: {
                actions: "handleSubscriptionResult"
              },
              HANDLE_COMPLETE: {
                target: "complete"
              }
            }
          },
          contextualMenuDisplayedState: {
            on: {
              HANDLE_CLOSE_CONTEXT_MENU_EVENT: {
                target: "empty",
                actions: "closeContextMenu"
              },
              HANDLE_SHOW_MODAL_EVENT: {
                target: "modalDisplayedState",
                actions: "showModal"
              },
              HANDLE_SUBSCRIPTION_RESULT: {
                actions: "handleSubscriptionResult"
              }
            }
          },
          modalDisplayedState: {
            on: {
              HANDLE_CLOSE_MODAL_EVENT: {
                target: "empty",
                actions: "closeModal"
              },
              HANDLE_REDIRECTING_EVENT: {
                target: "redirectState",
                actions: "redirecting"
              },
              HANDLE_SUBSCRIPTION_RESULT: {
                actions: "handleSubscriptionResult"
              }
            }
          },
          redirectState: {
            type: "final"
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    actions: {
      showContextMenu: assign((_, event) => {
        const { projectMenuAnchor } = event;
        return { projectMenuAnchor };
      }),
      hideContextMenu: assign((_) => {
        return { projectMenuAnchor: null };
      }),
      showModal: assign((_, event) => {
        const { modalName } = event;
        return { projectMenuAnchor: null, modalDisplayed: modalName };
      }),
      closeModal: assign((_) => {
        return { modalDisplayed: null };
      }),
      redirecting: assign((_, event) => {
        const { to } = event;
        return { to, projectMenuAnchor: null, modalDisplayed: null };
      }),
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (isProjectRenamedEventPayload(data.projectEvent)) {
          const { newName } = data.projectEvent;
          return {
            projectName: newName
          };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const projectEventSubscription = gql$1`
  subscription projectEvent($input: ProjectEventInput!) {
    projectEvent(input: $input) {
      __typename
      ... on ProjectRenamedEventPayload {
        projectId
        newName
      }
    }
  }
`;
const useEditProjectViewNavbarStyles = makeStyles()((theme2) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  titleLabel: {
    marginRight: theme2.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100ch"
  },
  onDarkBackground: {
    "&:hover": {
      backgroundColor: emphasize(theme2.palette.secondary.main, 0.08)
    }
  }
}));
const EditProjectNavbar = ({}) => {
  const { project } = useCurrentProject();
  const { classes } = useEditProjectViewNavbarStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  const { httpOrigin } = useContext(ServerContext);
  const [{ value: value2, context }, dispatch] = useMachine(
    editProjectNavbarMachine,
    {
      context: {
        projectName: project.name
      }
    }
  );
  const { toast, navbar } = value2;
  const { id, to, modalDisplayed, projectMenuAnchor, projectName, message } = context;
  const { error } = useSubscription(projectEventSubscription, {
    variables: {
      input: {
        id,
        projectId: project.id
      }
    },
    fetchPolicy: "no-cache",
    skip: navbar === "complete",
    onData: ({ data }) => {
      const handleDataEvent = {
        type: "HANDLE_SUBSCRIPTION_RESULT",
        result: data
      };
      dispatch(handleDataEvent);
    },
    onComplete: () => {
      const completeEvent = { type: "HANDLE_COMPLETE" };
      dispatch(completeEvent);
    }
  });
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  const onMoreClick = (event) => {
    if (navbar === "empty") {
      const showContextMenu = {
        type: "HANDLE_SHOW_CONTEXT_MENU_EVENT",
        projectMenuAnchor: event.currentTarget
      };
      dispatch(showContextMenu);
    }
  };
  const onCloseModal = () => {
    dispatch({ type: "HANDLE_CLOSE_MODAL_EVENT" });
  };
  const onCloseContextMenu = () => {
    dispatch({ type: "HANDLE_CLOSE_CONTEXT_MENU_EVENT" });
  };
  const onProjectDeleted = () => {
    const redirectingEvent = {
      type: "HANDLE_REDIRECTING_EVENT",
      to: "/projects"
    };
    dispatch(redirectingEvent);
  };
  if (navbar === "redirectState") {
    return /* @__PURE__ */ jsx(Redirect, { to, push: true });
  }
  let modal = null;
  if (project && navbar === "modalDisplayedState") {
    if (modalDisplayed === "RenameProject") {
      modal = /* @__PURE__ */ jsx(RenameProjectModal, { project, onSuccess: onCloseModal, onCancel: onCloseModal });
    } else if (modalDisplayed === "DeleteProject") {
      modal = /* @__PURE__ */ jsx(DeleteProjectModal, { project, onSuccess: onProjectDeleted, onCancel: onCloseModal });
    }
  }
  const { Component: ProjectSubtitle } = useComponent(editProjectNavbarSubtitleExtensionPoint);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(NavigationBar, { children: /* @__PURE__ */ jsxs("div", { className: classes.center, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.title, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h6", noWrap: true, className: classes.titleLabel, "data-testid": `navbar-${projectName}`, children: projectName }),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            className: classes.onDarkBackground,
            edge: "start",
            size: "small",
            "aria-label": "more",
            "aria-controls": "more-menu",
            "aria-haspopup": "true",
            onClick: onMoreClick,
            color: "inherit",
            "data-testid": "more",
            children: /* @__PURE__ */ jsx(MoreVertIcon, {})
          }
        )
      ] }),
      /* @__PURE__ */ jsx(ProjectSubtitle, {})
    ] }) }),
    /* @__PURE__ */ jsxs(
      Menu,
      {
        open: navbar === "contextualMenuDisplayedState",
        anchorEl: projectMenuAnchor,
        "data-testid": "navbar-contextmenu",
        onClose: onCloseContextMenu,
        children: [
          /* @__PURE__ */ jsxs(
            MenuItem,
            {
              onClick: () => {
                const showModal = {
                  type: "HANDLE_SHOW_MODAL_EVENT",
                  modalName: "RenameProject"
                };
                dispatch(showModal);
              },
              "data-testid": "rename",
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(EditIcon, {}) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("rename") })
              ]
            }
          ),
          /* @__PURE__ */ jsxs(
            MenuItem,
            {
              component: "a",
              href: `${httpOrigin}/api/projects/${project == null ? void 0 : project.id}`,
              type: "application/octet-stream",
              onClick: onCloseContextMenu,
              "data-testid": "download-link",
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(GetAppIcon, {}) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("download") })
              ]
            }
          ),
          /* @__PURE__ */ jsxs(
            MenuItem,
            {
              divider: true,
              component: Link,
              to: `/projects/${project == null ? void 0 : project.id}/settings`,
              onClick: onCloseContextMenu,
              "data-testid": "project-settings-link",
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(SettingsIcon, {}) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("settings") })
              ]
            }
          ),
          /* @__PURE__ */ jsxs(
            MenuItem,
            {
              onClick: () => {
                const showModal = {
                  type: "HANDLE_SHOW_MODAL_EVENT",
                  modalName: "DeleteProject"
                };
                dispatch(showModal);
              },
              "data-testid": "delete",
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(DeleteIcon, {}) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("delete") })
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    ),
    modal
  ] });
};
const editProjectViewMachine = Machine(
  {
    context: {
      project: null,
      representation: null,
      message: null
    },
    initial: "loading",
    states: {
      loading: {
        on: {
          HANDLE_FETCHED_PROJECT: [
            {
              cond: "isMissing",
              target: "missing"
            },
            {
              target: "loaded",
              actions: "updateProject"
            }
          ]
        }
      },
      loaded: {
        on: {
          SELECT_REPRESENTATION: {
            target: "loaded",
            actions: "selectRepresentation"
          }
        }
      },
      missing: {
        type: "final"
      }
    }
  },
  {
    guards: {
      isMissing: (_, event) => {
        const { data } = event;
        return !data.viewer.project;
      }
    },
    actions: {
      updateProject: assign((_, event) => {
        const { data } = event;
        const { project: gQLProject } = data.viewer;
        const natures = gQLProject.natures.map((gqlNature) => ({ name: gqlNature.name }));
        const currentEditingContext = { id: gQLProject.currentEditingContext.id };
        const project = { id: gQLProject.id, name: gQLProject.name, natures, currentEditingContext };
        let representation = null;
        if (gQLProject.currentEditingContext.representation) {
          representation = {
            id: gQLProject.currentEditingContext.representation.id,
            label: gQLProject.currentEditingContext.representation.label,
            kind: gQLProject.currentEditingContext.representation.kind
          };
        }
        return { project, representation };
      }),
      selectRepresentation: assign((_, event) => {
        const { representation } = event;
        return { representation };
      })
    }
  }
);
const createDocumentMutation = gql$1`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$2 = (payload) => payload.__typename === "ErrorPayload";
const isCreateDocumentSuccessPayload = (payload) => payload.__typename === "CreateDocumentSuccessPayload";
const useCreateDocument = () => {
  const [performDocumentCreation, { data, loading, error }] = useMutation(createDocumentMutation);
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (data) {
      const { createDocument: createDocument2 } = data;
      if (isErrorPayload$2(createDocument2)) {
        addMessages(createDocument2.messages);
      }
    }
  }, [coreT, data, error]);
  const createDocument = (editingContextId, stereotypeId, name) => {
    const variables = {
      input: {
        id: crypto.randomUUID(),
        editingContextId,
        stereotypeId,
        name
      }
    };
    performDocumentCreation({ variables });
  };
  let documentCreated = null;
  if (data && isCreateDocumentSuccessPayload(data.createDocument)) {
    documentCreated = data.createDocument;
  }
  return {
    createDocument,
    loading,
    documentCreated
  };
};
const getStereotypesQuery = gql$1`
  query getStereotypes($editingContextId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        ...EditingContextStereotypes
      }
    }
  }
`;
const useStereotypes = (editingContextId) => {
  const variables = {
    editingContextId
  };
  const { data, loading, error } = useQuery(
    getStereotypesQuery,
    { variables }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  return { data: data ?? null, loading };
};
const useNewDocumentModalStyles = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme2.spacing(1)
    }
  }
}));
const NewDocumentModal = ({ editingContextId, onClose }) => {
  var _a, _b;
  const { classes } = useNewDocumentModalStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "model.create" });
  const [state, setState] = useState({
    name: "",
    nameIsInvalid: true,
    pristine: true,
    stereotypeId: ""
  });
  const { data, loading: loadingStereotypes } = useStereotypes(editingContextId);
  const stereotypes = (((_b = (_a = data == null ? void 0 : data.viewer) == null ? void 0 : _a.editingContext) == null ? void 0 : _b.stereotypes.edges) ?? []).map((edge) => edge.node);
  useEffect(() => {
    if (state.stereotypeId === "" && stereotypes.length > 0) {
      setState((prevState) => ({ ...prevState, stereotypeId: stereotypes[0].id }));
    }
  }, [stereotypes]);
  const onNameChange = (event) => {
    const value2 = event.target.value;
    const nameIsInvalid = value2.trim().length === 0;
    setState((prevState) => ({ ...prevState, name: value2, nameIsInvalid, pristine: false }));
  };
  const onStereotypeChange = (event) => {
    const value2 = event.target.value;
    setState((prevState) => ({ ...prevState, stereotypeId: value2.toString() }));
  };
  const { createDocument, loading: documentCreationLoading, documentCreated } = useCreateDocument();
  const onCreateDocument = () => {
    createDocument(editingContextId, state.stereotypeId, state.name);
  };
  useEffect(() => {
    if (documentCreated) {
      onClose();
    }
  }, [documentCreated, onClose]);
  return /* @__PURE__ */ jsxs(
    Dialog,
    {
      open: true,
      onClose,
      "aria-labelledby": "dialog-title",
      maxWidth: "xs",
      fullWidth: true,
      "data-testid": "create-new-model",
      children: [
        /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
        /* @__PURE__ */ jsx(DialogContent, { children: /* @__PURE__ */ jsxs("div", { className: classes.form, children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              variant: "standard",
              error: !state.pristine && state.nameIsInvalid,
              helperText: t("name.helperText"),
              label: t("name.label"),
              name: "name",
              value: state.name,
              placeholder: t("name.placeholder"),
              "data-testid": "name",
              inputProps: { "data-testid": "name-input" },
              autoFocus: true,
              onChange: onNameChange,
              disabled: loadingStereotypes || documentCreationLoading
            }
          ),
          /* @__PURE__ */ jsx(InputLabel, { id: "newDocumentModalStereotypeLabel", children: t("modelType.label") }),
          /* @__PURE__ */ jsx(
            Select,
            {
              variant: "standard",
              value: state.stereotypeId,
              onChange: onStereotypeChange,
              disabled: loadingStereotypes || documentCreationLoading,
              labelId: "newDocumentModalStereotypeLabel",
              fullWidth: true,
              inputProps: { "data-testid": "stereotype-input" },
              "data-testid": "stereotype",
              children: stereotypes.map((stereotype) => /* @__PURE__ */ jsx(MenuItem, { value: stereotype.id, children: stereotype.label }, stereotype.id))
            }
          )
        ] }) }),
        /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
          Button,
          {
            variant: "contained",
            disabled: state.nameIsInvalid || loadingStereotypes || documentCreationLoading,
            "data-testid": "create-document",
            color: "primary",
            onClick: onCreateDocument,
            children: t("submit")
          }
        ) })
      ]
    }
  );
};
const NewDocumentModalContribution = ({ disabled, editingContextId }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  const [modal, setModal] = useState(null);
  let modalElement = null;
  if (modal === "NewDocument") {
    modalElement = /* @__PURE__ */ jsx(NewDocumentModal, { editingContextId, onClose: () => setModal(null) });
  }
  return /* @__PURE__ */ jsxs(Fragment$1, { children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        disabled,
        size: "small",
        color: "inherit",
        "aria-label": t("newModel"),
        title: t("newModel"),
        onClick: () => setModal("NewDocument"),
        "data-testid": "new-model",
        children: /* @__PURE__ */ jsx(AddIcon, {})
      }
    ),
    modalElement
  ] }, "new-document-modal-contribution");
};
const useUploadDocumentReportStyles = makeStyles()((theme2) => ({
  report: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme2.spacing(2)
  },
  message: {
    color: theme2.palette.text.secondary
  }
}));
const UploadDocumentReport = ({ uploadedDocument }) => {
  const [state, setState] = useState({
    downloaded: false
  });
  const onDownloadReport = () => {
    if (uploadedDocument) {
      const { report } = uploadedDocument;
      const fileName = "upload-document-report.txt";
      const blob = new Blob([report], { type: "text/plain" });
      const hyperlink = document.createElement("a");
      hyperlink.setAttribute("download", fileName);
      hyperlink.setAttribute("href", window.URL.createObjectURL(blob));
      hyperlink.click();
      setState((prevState) => ({ ...prevState, downloaded: true }));
    }
  };
  const { classes } = useUploadDocumentReportStyles();
  if (!uploadedDocument) {
    return null;
  }
  return /* @__PURE__ */ jsxs("div", { className: classes.report, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "The document has been successfully uploaded" }),
    (uploadedDocument == null ? void 0 : uploadedDocument.report) ? /* @__PURE__ */ jsx(
      Button,
      {
        variant: "outlined",
        size: "small",
        disabled: state.downloaded,
        color: "primary",
        type: "button",
        form: "upload-form-id",
        startIcon: /* @__PURE__ */ jsx(GetAppIcon, {}),
        "data-testid": "upload-document-download-report",
        onClick: () => onDownloadReport(),
        children: "Download report"
      }
    ) : null
  ] });
};
const uploadDocumentMutationFile = gql$1`
  mutation uploadDocument($input: UploadDocumentInput!) {
    uploadDocument(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
      ... on UploadDocumentSuccessPayload {
        report
      }
    }
  }
`;
const isUploadDocumentSuccessPayload = (payload) => payload.__typename === "UploadDocumentSuccessPayload";
const isErrorPayload$1 = (payload) => payload.__typename === "ErrorPayload";
const useUploadDocument = () => {
  const [state, setState] = useState({
    loading: false,
    uploadedDocument: null
  });
  const { httpOrigin } = useContext(ServerContext);
  const { addErrorMessage } = useMultiToast();
  const uploadDocument = (editingContextId, file) => {
    var _a;
    setState((prevState) => ({ ...prevState, loading: true }));
    const variables = {
      input: {
        id: crypto.randomUUID(),
        editingContextId,
        file: null
        // the file will be send as a part of the multipart POST query.
      }
    };
    try {
      sendFile(httpOrigin, ((_a = uploadDocumentMutationFile.loc) == null ? void 0 : _a.source.body) ?? "", variables, file).then((result) => {
        const { data, error } = result;
        if (error) {
          addErrorMessage("An unexpected error has occurred, the file uploaded may be too large");
        }
        if (data) {
          const { uploadDocument: uploadDocument2 } = data;
          if (isErrorPayload$1(uploadDocument2)) {
            const { message } = uploadDocument2;
            addErrorMessage(message);
            setState((prevState) => ({ ...prevState, loading: false, uploadedDocument: null }));
          } else if (isUploadDocumentSuccessPayload(uploadDocument2)) {
            setState((prevState) => ({ ...prevState, loading: false, uploadedDocument: uploadDocument2 }));
          }
        }
      });
    } catch (exception) {
      addErrorMessage("An unexpected error has occurred, the file uploaded may be too large");
      setState((prevState) => ({ ...prevState, loading: false, uploadedDocument: null }));
    }
  };
  const { loading, uploadedDocument } = state;
  return {
    uploadDocument,
    loading,
    uploadedDocument
  };
};
const useFormStyles = makeStyles()((theme2) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(1),
    paddingLeft: theme2.spacing(2),
    paddingRight: theme2.spacing(2),
    "& > *": {
      marginBottom: theme2.spacing(2)
    }
  }
}));
const UploadDocumentModal = ({ editingContextId, onClose }) => {
  const [state, setState] = useState({
    file: null
  });
  const { classes: styles } = useFormStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "model.upload" });
  const { uploadDocument, loading, uploadedDocument } = useUploadDocument();
  const onFileSelected = (file) => setState((prevState) => ({ ...prevState, file }));
  const performDocumentUpload = (event) => {
    event.preventDefault();
    uploadDocument(editingContextId, state.file);
  };
  return /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", fullWidth: true, children: [
    /* @__PURE__ */ jsx(DialogTitle, { id: "dialog-title", children: t("title") }),
    /* @__PURE__ */ jsxs(DialogContent, { children: [
      /* @__PURE__ */ jsx(
        "form",
        {
          id: "upload-form-id",
          onSubmit: performDocumentUpload,
          encType: "multipart/form-data",
          className: styles.form,
          children: /* @__PURE__ */ jsx(FormGroup, { children: /* @__PURE__ */ jsx(FileUpload, { onFileSelected, "data-testid": "file" }) })
        }
      ),
      /* @__PURE__ */ jsx(UploadDocumentReport, { uploadedDocument })
    ] }),
    /* @__PURE__ */ jsxs(DialogActions, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          disabled: !state.file || loading || !!uploadedDocument,
          color: "primary",
          type: "submit",
          form: "upload-form-id",
          "data-testid": "upload-document-submit",
          children: t("submit")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: uploadedDocument === null ? "outlined" : "contained",
          color: "primary",
          type: "button",
          form: "upload-form-id",
          "data-testid": "upload-document-close",
          onClick: () => onClose(),
          children: t("cancel")
        }
      )
    ] })
  ] });
};
const UploadDocumentModalContribution = ({
  disabled,
  editingContextId
}) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.edit" });
  const [modal, setModal] = useState(null);
  const onFinished = () => {
    setModal(null);
  };
  let modalElement = null;
  if (modal === "UploadDocument") {
    modalElement = /* @__PURE__ */ jsx(UploadDocumentModal, { editingContextId, onClose: onFinished });
  }
  return /* @__PURE__ */ jsxs(Fragment$1, { children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        disabled,
        size: "small",
        color: "inherit",
        "aria-label": t("uploadModel"),
        title: t("uploadModel"),
        onClick: () => setModal("UploadDocument"),
        "data-testid": "upload-document-icon",
        children: /* @__PURE__ */ jsx(PublishIcon, {})
      }
    ),
    modalElement
  ] }, "upload-document-modal-contribution");
};
const getProjectQuery$1 = gql$1`
  query getProjectAndRepresentation($projectId: ID!, $representationId: ID!, $includeRepresentation: Boolean!) {
    viewer {
      project(projectId: $projectId) {
        id
        name
        natures {
          name
        }
        currentEditingContext {
          id
          representation(representationId: $representationId) @include(if: $includeRepresentation) {
            id
            label
            kind
          }
        }
      }
    }
  }
`;
const useProjectAndRepresentationMetadata = (projectId, representationId) => {
  const { loading, data, error } = useQuery(getProjectQuery$1, {
    variables: {
      projectId,
      representationId: representationId ?? "",
      includeRepresentation: !!representationId
    }
  });
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  return {
    data: data ?? null,
    loading
  };
};
const useEditProjectViewStyles = makeStyles()((_) => ({
  editProjectView: {
    display: "grid",
    gridTemplateRows: "min-content minmax(0, 1fr)",
    gridTemplateColumns: "1fr",
    height: "100vh",
    width: "100vw"
  }
}));
const EditProjectView = () => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const { projectId, representationId } = useParams();
  const { classes } = useEditProjectViewStyles();
  const [{ value: value2, context }, dispatch] = useMachine(
    editProjectViewMachine
  );
  const { data } = useProjectAndRepresentationMetadata(projectId, representationId);
  useEffect(() => {
    if (data) {
      const fetchProjectEvent = { type: "HANDLE_FETCHED_PROJECT", data };
      dispatch(fetchProjectEvent);
    }
  }, [data]);
  const onRepresentationSelected = (representationMetadata) => {
    const selectRepresentationEvent = {
      type: "SELECT_REPRESENTATION",
      representation: representationMetadata
    };
    dispatch(selectRepresentationEvent);
  };
  useEffect(() => {
    if (context.representation && context.representation.id !== representationId) {
      const pathname = generatePath(routeMatch.path, { projectId, representationId: context.representation.id });
      history.push({ pathname });
    } else if (value2 === "loaded" && context.representation === null && representationId) {
      const pathname = generatePath(routeMatch.path, { projectId, representationId: null });
      history.push({ pathname });
    }
  }, [value2, projectId, routeMatch, history, context.representation, representationId]);
  let content = null;
  if (value2 === "loading") {
    content = /* @__PURE__ */ jsx(NavigationBar, {});
  }
  if (value2 === "missing") {
    content = /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(NavigationBar, {}),
      /* @__PURE__ */ jsx(Grid, { container: true, justifyContent: "center", alignItems: "center", children: /* @__PURE__ */ jsx(Typography, { variant: "h4", align: "center", gutterBottom: true, children: "The project does not exist" }) })
    ] });
  }
  if (value2 === "loaded" && context.project) {
    const initialSelection = {
      entries: context.representation ? [
        {
          id: context.representation.id,
          kind: context.representation.kind
        }
      ] : []
    };
    content = /* @__PURE__ */ jsx(ProjectContext.Provider, { value: { project: context.project }, children: /* @__PURE__ */ jsxs(SelectionContextProvider, { initialSelection, children: [
      /* @__PURE__ */ jsx(EditProjectNavbar, {}),
      /* @__PURE__ */ jsx(TreeToolBarProvider, { children: /* @__PURE__ */ jsx(
        Workbench,
        {
          editingContextId: context.project.currentEditingContext.id,
          initialRepresentationSelected: context.representation,
          onRepresentationSelected,
          readOnly: false
        }
      ) })
    ] }) });
  }
  return /* @__PURE__ */ jsx("div", { className: classes.editProjectView, children: content });
};
const TreeToolBarProvider = ({ children }) => {
  const treeToolBarContributions = [
    /* @__PURE__ */ jsx(TreeToolBarContribution, { component: NewDocumentModalContribution }),
    /* @__PURE__ */ jsx(TreeToolBarContribution, { component: UploadDocumentModalContribution })
  ];
  return /* @__PURE__ */ jsx(TreeToolBarContext.Provider, { value: treeToolBarContributions, children });
};
const useFooterStyles = makeStyles()((theme2) => ({
  footer: {
    display: "flex",
    justifyContent: "center",
    margin: theme2.spacing(2),
    "& > *": {
      marginLeft: theme2.spacing(0.5),
      marginRight: theme2.spacing(0.5)
    }
  }
}));
const SiriusWebFooter = ({}) => {
  const { classes } = useFooterStyles();
  return /* @__PURE__ */ jsxs("footer", { className: classes.footer, children: [
    /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
      "© ",
      (/* @__PURE__ */ new Date()).getFullYear(),
      " Obeo. Powered by "
    ] }),
    /* @__PURE__ */ jsx(Link$1, { variant: "caption", href: "https://www.eclipse.dev/sirius", rel: "noopener noreferrer", target: "_blank", children: "Sirius Web" })
  ] });
};
const footerExtensionPoint = {
  identifier: "footer",
  FallbackComponent: SiriusWebFooter
};
const isChangeNameEvent = (event) => !!event.name;
const isNameInvalid = (name) => name.trim().length < 3 || name.trim().length > 1024;
const isCreateProjectSuccessPayload = (payload) => payload.__typename === "CreateProjectSuccessPayload";
const newProjectViewMachine = Machine(
  {
    id: "NewProjectView",
    type: "parallel",
    context: {
      name: "",
      nameIsInvalid: false,
      message: null,
      newProjectId: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      newProjectView: {
        initial: "pristine",
        states: {
          pristine: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isFormInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ]
            }
          },
          invalid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isFormInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ]
            }
          },
          valid: {
            on: {
              CHANGE_NAME: [
                {
                  cond: "isFormInvalid",
                  target: "invalid",
                  actions: "updateName"
                },
                {
                  target: "valid",
                  actions: "updateName"
                }
              ],
              REQUEST_PROJECT_CREATION: [
                {
                  target: "creatingProject"
                }
              ]
            }
          },
          creatingProject: {
            on: {
              HANDLE_RESPONSE: [
                {
                  cond: "isResponseSuccessful",
                  target: "success",
                  actions: "updateProjectId"
                },
                {
                  target: "valid"
                }
              ]
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isFormInvalid: (_, event) => {
        if (isChangeNameEvent(event)) {
          const { name } = event;
          return isNameInvalid(name);
        }
        return true;
      },
      isResponseSuccessful: (_, event) => {
        const { data } = event;
        return data.createProject.__typename === "CreateProjectSuccessPayload";
      }
    },
    actions: {
      updateName: assign((_, event) => {
        const { name } = event;
        return { name, nameIsInvalid: isNameInvalid(name) };
      }),
      updateProjectId: assign((_, event) => {
        const { data } = event;
        const { createProject } = data;
        if (isCreateProjectSuccessPayload(createProject)) {
          return { newProjectId: createProject.project.id };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const createProjectMutation = gql$1`
  mutation createProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      __typename
      ... on CreateProjectSuccessPayload {
        project {
          id
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const useNewProjectViewStyles = makeStyles()((theme2) => ({
  newProjectViewContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(8)
  },
  newProjectView: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr min-content",
    minHeight: "100vh"
  },
  main: {
    paddingTop: theme2.spacing(3),
    paddingBottom: theme2.spacing(3)
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme2.spacing(2)
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(1),
    paddingLeft: theme2.spacing(2),
    paddingRight: theme2.spacing(2),
    "& > *": {
      marginBottom: theme2.spacing(2)
    }
  }
}));
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const NewProjectView = () => {
  const { classes } = useNewProjectViewStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.create" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [{ value: value2, context }, dispatch] = useMachine(newProjectViewMachine);
  const { newProjectView, toast } = value2;
  const { name, nameIsInvalid, message, newProjectId } = context;
  const [createProject, { loading, data, error }] = useMutation(createProjectMutation);
  const { Component: Footer } = useComponent(footerExtensionPoint);
  const onNameChange = (event) => {
    const value22 = event.target.value;
    const changeNameEvent = { type: "CHANGE_NAME", name: value22 };
    dispatch(changeNameEvent);
  };
  const onCreateNewProject = (event) => {
    event.preventDefault();
    const variables = {
      input: {
        id: crypto.randomUUID(),
        name: name.trim(),
        natures: []
      }
    };
    const submitEvent = { type: "REQUEST_PROJECT_CREATION" };
    dispatch(submitEvent);
    createProject({ variables });
  };
  useEffect(() => {
    if (!loading) {
      if (error) {
        const showToastEvent = {
          type: "SHOW_TOAST",
          message: coreT("errors.unexpected")
        };
        dispatch(showToastEvent);
      }
      if (data) {
        const handleResponseEvent = { type: "HANDLE_RESPONSE", data };
        dispatch(handleResponseEvent);
        const { createProject: createProject2 } = data;
        if (isErrorPayload(createProject2)) {
          const { message: message2 } = createProject2;
          const showToastEvent = { type: "SHOW_TOAST", message: message2 };
          dispatch(showToastEvent);
        }
      }
    }
  }, [loading, data, error, dispatch, coreT]);
  if (newProjectView === "success") {
    return /* @__PURE__ */ jsx(Redirect, { to: `/projects/${newProjectId}/edit`, push: true });
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.newProjectView, children: [
      /* @__PURE__ */ jsx(NavigationBar, {}),
      /* @__PURE__ */ jsx("main", { className: classes.main, children: /* @__PURE__ */ jsx(Container, { maxWidth: "sm", children: /* @__PURE__ */ jsxs("div", { className: classes.newProjectViewContainer, children: [
        /* @__PURE__ */ jsxs("div", { className: classes.titleContainer, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h2", align: "center", gutterBottom: true, children: t("title") }),
          /* @__PURE__ */ jsx(Typography, { variant: "h4", align: "center", gutterBottom: true, children: t("description") })
        ] }),
        /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs("form", { onSubmit: onCreateNewProject, className: classes.form, children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              variant: "standard",
              error: nameIsInvalid,
              helperText: t("name.helperText"),
              label: t("name.label"),
              name: "name",
              value: name,
              placeholder: t("name.placeholder"),
              inputProps: { "data-testid": "name" },
              autoFocus: true,
              onChange: onNameChange
            }
          ),
          /* @__PURE__ */ jsx("div", { className: classes.buttons, children: /* @__PURE__ */ jsx(
            Button,
            {
              variant: "contained",
              type: "submit",
              disabled: newProjectView !== "valid",
              "data-testid": "create-project",
              color: "primary",
              children: t("submit")
            }
          ) })
        ] }) })
      ] }) }) }),
      /* @__PURE__ */ jsx(Footer, {})
    ] }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const useCreateProjectAreaStyles = makeStyles()((theme2) => ({
  createProjectArea: {
    display: "flex",
    flexDirection: "column",
    gap: theme2.spacing(5)
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  content: {
    display: "grid",
    gap: theme2.spacing(1),
    gridTemplateColumns: "repeat(6, 1fr)"
  }
}));
const CreateProjectArea = ({}) => {
  const { classes } = useCreateProjectAreaStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  const createProjectAreaCards = useComponents(createProjectAreaCardExtensionPoint);
  const limit = Math.max(0, 6 - createProjectAreaCards.length);
  const [state, setState] = useState({
    page: 0,
    limit,
    runningTemplate: null
  });
  const { data } = useProjectTemplates(state.page, state.limit);
  const projectTemplates = (data == null ? void 0 : data.viewer.projectTemplates.edges.map((edge) => edge.node)) ?? [];
  const { createProjectFromTemplate, loading, projectCreatedFromTemplate } = useCreateProjectFromTemplate();
  const onCreateProject = (template) => {
    if (!state.runningTemplate) {
      createProjectFromTemplate(template.id);
      setState((prevState) => ({ ...prevState, runningTemplate: template }));
    }
  };
  const redirectUrl = projectCreatedFromTemplate ? redirectUrlFromTemplate(projectCreatedFromTemplate) : null;
  if (redirectUrl) {
    return /* @__PURE__ */ jsx(Redirect, { to: redirectUrl });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs("div", { className: classes.createProjectArea, children: [
    /* @__PURE__ */ jsx("div", { className: classes.header, children: /* @__PURE__ */ jsx(Typography, { variant: "h4", children: t("createNewProject") }) }),
    /* @__PURE__ */ jsxs("div", { className: classes.content, children: [
      projectTemplates.map((template) => {
        var _a;
        return /* @__PURE__ */ jsx(
          ProjectTemplateCard,
          {
            template,
            disabled: loading,
            running: template.id === ((_a = state.runningTemplate) == null ? void 0 : _a.id),
            onCreateProject: () => onCreateProject(template)
          },
          template.id
        );
      }),
      createProjectAreaCards.map(({ Component: Card2 }, index) => /* @__PURE__ */ jsx(Card2, {}, index))
    ] })
  ] }) });
};
const projectActionButtonMenuItemExtensionPoint = {
  identifier: "projectActionButton#menuItem",
  fallback: []
};
const ProjectActionButton = ({ project, onChange }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  const [state, setState] = useState({
    contextMenuAnchorElement: null
  });
  const onClick = (event) => setState((prevState) => ({ ...prevState, contextMenuAnchorElement: event.currentTarget }));
  const onCloseContextMenu = () => setState((prevState) => ({ ...prevState, contextMenuAnchorElement: null }));
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Tooltip, { title: t("more"), children: /* @__PURE__ */ jsx(IconButton, { "aria-label": "more", onClick, size: "small", "data-testid": "more", children: /* @__PURE__ */ jsx(MoreHorizIcon, { fontSize: "small" }) }) }),
    state.contextMenuAnchorElement ? /* @__PURE__ */ jsx(
      ProjectContextMenu,
      {
        menuAnchor: state.contextMenuAnchorElement,
        project,
        onChange,
        onClose: onCloseContextMenu
      }
    ) : null
  ] });
};
const modals = {
  RENAME_PROJECT_DIALOG: RenameProjectModal,
  DELETE_PROJECT_DIALOG: DeleteProjectModal
};
const ProjectContextMenu = ({ menuAnchor, project, onChange, onClose }) => {
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  const [state, setState] = useState({
    modalToDisplay: null
  });
  const onRename = () => setState((prevState) => ({ ...prevState, modalToDisplay: "RENAME_PROJECT_DIALOG" }));
  const onDelete = () => setState((prevState) => ({ ...prevState, modalToDisplay: "DELETE_PROJECT_DIALOG" }));
  const onCancel = () => setState((prevState) => ({ ...prevState, modalToDisplay: null }));
  const onSuccess = () => {
    setState((prevState) => ({ ...prevState, modalToDisplay: null }));
    onChange();
  };
  const ModalComponent = modals[state.modalToDisplay];
  const { httpOrigin } = useContext(ServerContext);
  const { data: menuItemProps } = useData(projectActionButtonMenuItemExtensionPoint);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Menu,
      {
        "data-testid": "project-actions-contextmenu",
        id: "project-actions-contextmenu",
        anchorEl: menuAnchor,
        keepMounted: true,
        open: true,
        onClose,
        children: [
          /* @__PURE__ */ jsxs(MenuItem, { onClick: onRename, "data-testid": "rename", children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(EditIcon, {}) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("rename") })
          ] }),
          /* @__PURE__ */ jsxs(MenuItem, { component: "a", href: `${httpOrigin}/api/projects/${project.id}`, type: "application/octet-stream", children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(GetAppIcon, {}) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("download") })
          ] }),
          /* @__PURE__ */ jsxs(MenuItem, { onClick: onDelete, "data-testid": "delete", children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(DeleteIcon, {}) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("delete") })
          ] }),
          menuItemProps.map((props, index) => /* @__PURE__ */ createElement(MenuItem, { ...props, key: index }))
        ]
      }
    ),
    ModalComponent ? /* @__PURE__ */ jsx(ModalComponent, { project, onSuccess, onCancel }) : null
  ] });
};
const useProjectsRowStyles = makeStyles()(() => ({
  projectLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100ch"
  }
}));
const ProjectRow = ({ project, onChange }) => {
  const { classes } = useProjectsRowStyles();
  return /* @__PURE__ */ jsxs(TableRow, { hover: true, children: [
    /* @__PURE__ */ jsx(TableCell, { children: /* @__PURE__ */ jsx(
      Link$1,
      {
        component: Link,
        to: `/projects/${project.id}/edit`,
        color: "inherit",
        className: classes.projectLabel,
        children: project.name
      }
    ) }),
    /* @__PURE__ */ jsx(TableCell, { align: "right", children: /* @__PURE__ */ jsx(ProjectActionButton, { project, onChange }) })
  ] }, project.id);
};
const projectsTableRowExtensionPoint = {
  identifier: "projectsTable#row",
  FallbackComponent: ProjectRow
};
const ProjectsTable = ({ projects, page, limit, count, onChange, onPageChange }) => {
  const { Component: ProjectRow2 } = useComponent(projectsTableRowExtensionPoint);
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  return /* @__PURE__ */ jsxs(Paper, { children: [
    /* @__PURE__ */ jsx(TableContainer, { children: /* @__PURE__ */ jsxs(Table, { children: [
      /* @__PURE__ */ jsxs("colgroup", { children: [
        /* @__PURE__ */ jsx("col", { width: "60%" }),
        /* @__PURE__ */ jsx("col", { width: "40%" })
      ] }),
      /* @__PURE__ */ jsx(TableHead, { children: /* @__PURE__ */ jsxs(TableRow, { children: [
        /* @__PURE__ */ jsx(TableCell, { variant: "head", children: t("name") }),
        /* @__PURE__ */ jsx(TableCell, { variant: "head" })
      ] }) }),
      /* @__PURE__ */ jsx(TableBody, { "data-testid": "projects", children: projects.map((project) => /* @__PURE__ */ jsx(ProjectRow2, { project, onChange }, project.id)) })
    ] }) }),
    /* @__PURE__ */ jsx(
      TablePagination,
      {
        component: "div",
        rowsPerPageOptions: [],
        rowsPerPage: limit,
        page,
        onPageChange: (_event, page2) => onPageChange(page2),
        count
      }
    )
  ] });
};
const getProjectsQuery = gql$1`
  query getProjects($page: Int!, $limit: Int!) {
    viewer {
      ...ViewerProjects
    }
  }
`;
const useProjects = (page, limit) => {
  const variables = {
    page,
    limit
  };
  const { data, loading, error, refetch } = useQuery(
    getProjectsQuery,
    {
      variables
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  const refreshProjects = () => refetch(variables);
  return { data, loading, refreshProjects };
};
const useListProjectsAreaStyles = makeStyles()((theme2) => ({
  listProjectsArea: {
    display: "flex",
    flexDirection: "column",
    gap: theme2.spacing(5)
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));
const ListProjectsArea = ({}) => {
  const [state, setState] = useState({
    page: 0,
    limit: 20
  });
  const onPageChange = (page) => setState((prevState) => ({ ...prevState, page }));
  const { data, refreshProjects } = useProjects(state.page, state.limit);
  const projects = (data == null ? void 0 : data.viewer.projects.edges.map((edge) => edge.node)) ?? [];
  const count = (data == null ? void 0 : data.viewer.projects.pageInfo.count) ?? 0;
  const { classes } = useListProjectsAreaStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  return /* @__PURE__ */ jsxs("div", { className: classes.listProjectsArea, children: [
    /* @__PURE__ */ jsx("div", { className: classes.header, children: /* @__PURE__ */ jsx(Typography, { variant: "h4", children: t("existingProjects") }) }),
    /* @__PURE__ */ jsx("div", { children: projects.length === 0 ? /* @__PURE__ */ jsx(NoProjectsFound, {}) : /* @__PURE__ */ jsx(
      ProjectsTable,
      {
        projects,
        page: state.page,
        limit: state.limit,
        count,
        onChange: () => refreshProjects(),
        onPageChange
      }
    ) })
  ] });
};
const useNoProjectsFoundStyles = makeStyles()(() => ({
  noProjectsFound: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));
const NoProjectsFound = ({}) => {
  const { classes } = useNoProjectsFoundStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.list" });
  return /* @__PURE__ */ jsx("div", { className: classes.noProjectsFound, children: /* @__PURE__ */ jsx(Typography, { variant: "h4", align: "center", children: t("empty") }) });
};
const useProjectsViewStyles = makeStyles()((theme2) => ({
  projectsView: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr min-content",
    minHeight: "100vh"
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: theme2.spacing(5),
    paddingTop: theme2.spacing(3),
    paddingBottom: theme2.spacing(3)
  }
}));
const ProjectBrowser = () => {
  const { classes } = useProjectsViewStyles();
  const { Component: Footer } = useComponent(footerExtensionPoint);
  return /* @__PURE__ */ jsxs("div", { className: classes.projectsView, children: [
    /* @__PURE__ */ jsx(NavigationBar, {}),
    /* @__PURE__ */ jsx(Container, { maxWidth: "xl", children: /* @__PURE__ */ jsx(Grid, { container: true, justifyContent: "center", children: /* @__PURE__ */ jsx(Grid, { item: true, xs: 8, children: /* @__PURE__ */ jsxs("main", { className: classes.main, children: [
      /* @__PURE__ */ jsx(CreateProjectArea, {}),
      /* @__PURE__ */ jsx(ListProjectsArea, {})
    ] }) }) }) }),
    /* @__PURE__ */ jsx(Footer, {})
  ] });
};
const getProjectQuery = gql$1`
  query getProject($projectId: ID!) {
    viewer {
      project(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
const useProjectSettingsViewStyles = makeStyles()((theme2) => ({
  projectSettingsView: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr min-content",
    minHeight: "100vh"
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  titleLabel: {
    marginRight: theme2.spacing(2),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100ch",
    color: "inherit"
  },
  main: {
    paddingTop: theme2.spacing(3),
    paddingBottom: theme2.spacing(3)
  },
  header: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    padding: theme2.spacing(3)
  },
  tabs: {
    display: "grid",
    gridTemplateColumns: "200px 1fr",
    gridTemplateRows: "1fr",
    gap: theme2.spacing(4)
  },
  tab: {
    justifyContent: "start",
    minHeight: 0
  },
  noSettingsFound: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme2.spacing(20)
  }
}));
const ProjectSettingsView = () => {
  var _a;
  const { classes } = useProjectSettingsViewStyles();
  const { projectId } = useParams();
  const { data: projectSettingsTabContributions } = useData(projectSettingsTabExtensionPoint);
  const initialSelectTabId = ((_a = projectSettingsTabContributions[0]) == null ? void 0 : _a.id) ?? null;
  const [state, setState] = useState({
    selectedTabId: initialSelectTabId
  });
  const { loading, data, error } = useQuery(getProjectQuery, {
    variables: {
      projectId
    }
  });
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  const project = data == null ? void 0 : data.viewer.project;
  const handleTabChange = (_event, newValue) => setState((prevState) => ({ ...prevState, selectedTabId: newValue }));
  const settingContentContribution = projectSettingsTabContributions.filter(
    (contribution) => contribution.id === state.selectedTabId
  )[0];
  const SettingContent = () => {
    if (settingContentContribution) {
      const { component: Component2 } = settingContentContribution;
      const props = {};
      return /* @__PURE__ */ jsx(Component2, { ...props });
    }
    return null;
  };
  const { Component: Footer } = useComponent(footerExtensionPoint);
  if (loading) {
    return null;
  }
  if (!project) {
    return /* @__PURE__ */ jsx(Redirect, { to: "/" });
  }
  const { id, name } = project;
  return /* @__PURE__ */ jsxs("div", { className: classes.projectSettingsView, children: [
    /* @__PURE__ */ jsx(NavigationBar, { children: /* @__PURE__ */ jsx("div", { className: classes.center, children: /* @__PURE__ */ jsx("div", { className: classes.title, children: /* @__PURE__ */ jsx(
      Link$1,
      {
        variant: "h6",
        component: Link,
        to: `/projects/${id}/edit`,
        noWrap: true,
        className: classes.titleLabel,
        "data-testid": `navbar-${name}`,
        children: name
      }
    ) }) }) }),
    /* @__PURE__ */ jsx("main", { className: classes.main, children: /* @__PURE__ */ jsxs(Container, { maxWidth: "xl", children: [
      /* @__PURE__ */ jsx("div", { className: classes.header, children: /* @__PURE__ */ jsx(Typography, { variant: "h4", children: "Settings" }) }),
      projectSettingsTabContributions.length > 0 && settingContentContribution != null ? /* @__PURE__ */ jsxs("div", { className: classes.tabs, children: [
        /* @__PURE__ */ jsx(Tabs, { value: state.selectedTabId, onChange: handleTabChange, orientation: "vertical", children: projectSettingsTabContributions.map(({ id: id2, title, icon }) => /* @__PURE__ */ jsx(Tab, { className: classes.tab, label: title, icon, iconPosition: "start", value: id2 }, id2)) }),
        /* @__PURE__ */ jsx(SettingContent, {})
      ] }) : /* @__PURE__ */ jsx("div", { className: classes.noSettingsFound, children: /* @__PURE__ */ jsx(Typography, { variant: "h5", children: "No setting pages found" }) })
    ] }) }),
    /* @__PURE__ */ jsx(Footer, {})
  ] });
};
const uploadProjectMachine = Machine(
  {
    id: "UploadProjectView",
    type: "parallel",
    context: {
      file: null,
      message: null,
      newProjectId: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      uploadProjectView: {
        initial: "pristine",
        states: {
          pristine: {
            on: {
              HANDLE_SELECTED_FILE: {
                target: "fileSelected",
                actions: "setFile"
              }
            }
          },
          fileSelected: {
            on: {
              HANDLE_UPLOAD: {
                target: "uploading"
              },
              HANDLE_SELECTED_FILE: {
                target: "fileSelected",
                actions: "setFile"
              }
            }
          },
          uploading: {
            on: {
              HANDLE_RESPONSE: {
                target: "success",
                actions: "setNewProjectId"
              }
            }
          },
          success: {
            type: "final"
          }
        }
      }
    }
  },
  {
    actions: {
      setFile: assign((_, event) => {
        const { file } = event;
        return { file };
      }),
      setNewProjectId: assign((_, event) => {
        const { data } = event;
        return { newProjectId: data.uploadProject.project.id };
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const uploadProjectMutation = gql$1`
  mutation uploadProject($input: UploadProjectInput!) {
    uploadProject(input: $input) {
      __typename
      ... on UploadProjectSuccessPayload {
        project {
          id
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`.loc.source.body;
const useUploadProjectViewStyles = makeStyles()((theme2) => ({
  uploadProjectViewContainer: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(8)
  },
  uploadProjectView: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr min-content",
    minHeight: "100vh"
  },
  main: {
    paddingTop: theme2.spacing(3),
    paddingBottom: theme2.spacing(3)
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme2.spacing(2)
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingTop: theme2.spacing(1),
    paddingLeft: theme2.spacing(2),
    paddingRight: theme2.spacing(2),
    "& > *": {
      marginBottom: theme2.spacing(2)
    }
  }
}));
const UploadProjectView = () => {
  const { classes } = useUploadProjectViewStyles();
  const { t } = useTranslation("siriusWebApplication", { keyPrefix: "project.upload" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [{ value: value2, context }, dispatch] = useMachine(uploadProjectMachine);
  const { uploadProjectView, toast } = value2;
  const { file, newProjectId, message } = context;
  const { httpOrigin } = useContext(ServerContext);
  const onUploadProject = async (event) => {
    event.preventDefault();
    const variables = {
      input: {
        id: crypto.randomUUID(),
        file: null
      }
    };
    try {
      dispatch({ type: "HANDLE_UPLOAD" });
      const response = await sendFile(httpOrigin, uploadProjectMutation, variables, file);
      const { data, error } = response;
      if (error) {
        dispatch({ type: "SHOW_TOAST", message: coreT("errors.fileTooLarge") });
      }
      if (data) {
        const typename = data.uploadProject.__typename;
        if (typename === "UploadProjectSuccessPayload") {
          dispatch({ type: "HANDLE_RESPONSE", data });
        } else if (typename === "ErrorMessage") {
          dispatch({ type: "SHOW_TOAST", message: data.uploadProject.message });
        }
      }
    } catch (exception) {
      dispatch({ type: "SHOW_TOAST", message: coreT("errors.fileTooLarge") });
    }
  };
  const onFileSelected = (file2) => {
    dispatch({ type: "HANDLE_SELECTED_FILE", file: file2 });
  };
  if (uploadProjectView === "success") {
    return /* @__PURE__ */ jsx(Redirect, { to: `/projects/${newProjectId}/edit`, push: true });
  }
  return /* @__PURE__ */ jsxs("div", { className: classes.uploadProjectView, children: [
    /* @__PURE__ */ jsx(NavigationBar, {}),
    /* @__PURE__ */ jsx("main", { className: classes.main, children: /* @__PURE__ */ jsx(Container, { maxWidth: "sm", children: /* @__PURE__ */ jsxs("div", { className: classes.uploadProjectViewContainer, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.titleContainer, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h2", align: "center", gutterBottom: true, children: t("title") }),
        /* @__PURE__ */ jsx(Typography, { variant: "h4", align: "center", gutterBottom: true, children: t("description") })
      ] }),
      /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs("form", { onSubmit: onUploadProject, encType: "multipart/form-data", className: classes.form, children: [
        /* @__PURE__ */ jsx(FileUpload, { onFileSelected, "data-testid": "file" }),
        /* @__PURE__ */ jsx("div", { className: classes.buttons, children: /* @__PURE__ */ jsx(
          Button,
          {
            variant: "contained",
            type: "submit",
            color: "primary",
            disabled: uploadProjectView !== "fileSelected",
            "data-testid": "upload-project",
            children: t("submit")
          }
        ) })
      ] }) })
    ] }) }) }),
    /* @__PURE__ */ jsx(Toast, { message, open: toast === "visible", onClose: () => dispatch({ type: "HIDE_TOAST" }) })
  ] });
};
const routerExtensionPoint = {
  identifier: "router#routes",
  fallback: []
};
const Router = () => {
  const { data: routes } = useData(routerExtensionPoint);
  return /* @__PURE__ */ jsxs(Switch, { children: [
    /* @__PURE__ */ jsx(Route, { exact: true, path: "/new/project", component: withErrorBoundary(NewProjectView) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: "/upload/project", component: withErrorBoundary(UploadProjectView) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: "/projects", component: withErrorBoundary(ProjectBrowser) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: "/projects/:projectId/edit/:representationId?", component: withErrorBoundary(EditProjectView) }),
    /* @__PURE__ */ jsx(Route, { exact: true, path: "/projects/:projectId/settings", component: withErrorBoundary(ProjectSettingsView) }),
    routes.map((props, index) => /* @__PURE__ */ jsx(Route, { ...props }, index)),
    /* @__PURE__ */ jsx(Redirect, { to: "/projects" })
  ] });
};
const baseTheme = createTheme({
  ...theme,
  palette: {
    mode: "light",
    primary: {
      main: "#BE1A78",
      dark: "#851254",
      light: "#CB4793"
    },
    secondary: {
      main: "#261E58",
      dark: "#1A153D",
      light: "#514B79"
    },
    text: {
      primary: "#261E58",
      disabled: "#B3BFC5"
    },
    error: {
      main: "#DE1000",
      dark: "#9B0B00",
      light: "#E43F33"
    },
    success: {
      main: "#43A047",
      dark: "#327836",
      light: "#4EBA54"
    },
    warning: {
      main: "#FF9800",
      dark: "#D98200",
      light: "#FFB800"
    },
    info: {
      main: "#2196F3",
      dark: "#1D7DCC",
      light: "#24A7FF"
    },
    divider: "#B3BFC5",
    navigation: {
      leftBackground: "#BE1A7880",
      rightBackground: "#261E5880"
    },
    navigationBar: {
      border: "#BE1A78",
      background: "#261E58"
    },
    selected: "#BE1A78",
    action: {
      hover: "#BE1A7826",
      selected: "#BE1A7842"
    }
  }
});
const siriusWebTheme = createTheme(
  {
    components: {
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: baseTheme.palette.primary.main
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: baseTheme.palette.common.black
          }
        }
      },
      MuiLink: {
        defaultProps: {
          underline: "hover"
        }
      }
    }
  },
  baseTheme
);
const style = {
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
  minHeight: "100vh"
};
const SiriusWebApplication = ({
  httpOrigin,
  wsOrigin,
  extensionRegistry,
  extensionRegistryMergeStrategy,
  theme: theme2,
  children
}) => {
  const siriusWebTheme$1 = theme2 ? theme2 : siriusWebTheme;
  let nodeTypeRegistryValue = { ...defaultNodeTypeRegistry };
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === DiagramRepresentationConfiguration) {
      const { nodeTypeRegistry } = child.props;
      if (nodeTypeRegistry) {
        nodeTypeRegistryValue = nodeTypeRegistry;
      }
    }
  });
  const getRepresentationPath = (editingContextId, representationId) => {
    return `/projects/${editingContextId}/edit/${representationId}`;
  };
  if (extensionRegistry) {
    defaultExtensionRegistry.addAll(
      extensionRegistry,
      extensionRegistryMergeStrategy ? extensionRegistryMergeStrategy : new DefaultExtensionRegistryMergeStrategy()
    );
  }
  return /* @__PURE__ */ jsx(ExtensionProvider, { registry: defaultExtensionRegistry, children: /* @__PURE__ */ jsx(ApolloGraphQLProvider, { httpOrigin, wsOrigin, children: /* @__PURE__ */ jsx(BrowserRouter, { children: /* @__PURE__ */ jsxs(ThemeProvider, { theme: siriusWebTheme$1, children: [
    /* @__PURE__ */ jsx(CssBaseline, {}),
    /* @__PURE__ */ jsx(ServerContext.Provider, { value: { httpOrigin }, children: /* @__PURE__ */ jsx(RepresentationPathContext.Provider, { value: { getRepresentationPath }, children: /* @__PURE__ */ jsx(ToastProvider, { children: /* @__PURE__ */ jsx(ConfirmationDialogContextProvider, { children: /* @__PURE__ */ jsx(NodeTypeContext.Provider, { value: nodeTypeRegistryValue, children: /* @__PURE__ */ jsx("div", { style, children: /* @__PURE__ */ jsx(Router, {}) }) }) }) }) }) })
  ] }) }) }) });
};
const siriusWebApplication$1 = {
  workbench: {
    details: "Details",
    explorer: "Explorer",
    relatedElements: "Related Elements",
    representations: "Representations",
    validation: "Validation"
  },
  core: {
    clickToSelectFile: "Click here to select a file"
  },
  navigationBar: {
    backToHomepage: "Back to the homepage"
  },
  project: {
    list: {
      blankProject: "Blank project",
      createNewProject: "Create a new project",
      "delete": "Delete",
      download: "Download",
      empty: "No projects found, start by creating one",
      existingProjects: "Existing Projects",
      more: "More",
      name: "Name",
      rename: "Rename",
      selectProjectTemplate: "Select a project template",
      showAllTemplates: "Show all templates",
      uploadProject: "Upload project"
    },
    create: {
      title: "Create a new project",
      description: "Get started by creating a new project",
      name: {
        label: "Name",
        placeholder: "Enter the project name",
        helperText: "The name must contain between 3 and 1024 characters"
      },
      submit: "Create"
    },
    upload: {
      title: "Upload a project",
      description: "Start with an existing project",
      clickToSelectFile: "Click here to select a file",
      submit: "Upload"
    },
    rename: {
      title: "Rename the project",
      name: {
        label: "Name",
        placeholder: "Enter a new project name",
        helperText: "The name is required and must contain less than 1024 characters"
      },
      submit: "Rename"
    },
    "delete": {
      title: 'Delete the project "{{name}}"',
      content: "This action will delete everything in the project. All data and all representations will be lost. It cannot be reversed.",
      submit: "Delete"
    },
    edit: {
      createModel: "Create a new Model",
      createRepresentation: "Create a new Representation",
      "delete": "Delete",
      download: "Download",
      newModel: "New model",
      noAccessToCreateModel: "You need edit access to create models",
      noAccessToCreateRepresentation: "You need edit access to create representations",
      noRepresentations: "There are no representations available for the current selection",
      openRepresentation: "Open an existing Representation",
      rename: "Rename",
      selectModelToCreate: "Select the model to create",
      selectRepresentationToCreate: "Select the representation to create",
      selectRepresentationToOpen: "Select the representation to open",
      settings: "Settings",
      newObject: "New object",
      newRepresentation: "New representation",
      uploadModel: "Upload model"
    }
  },
  model: {
    create: {
      title: "Create a new model",
      name: {
        label: "Name",
        placeholder: "Enter the name of the model",
        helperText: "The name cannot be empty"
      },
      modelType: {
        label: "Model type"
      },
      submit: "Create"
    },
    upload: {
      title: "Upload new model",
      submit: "Upload",
      cancel: "Close"
    }
  },
  object: {
    newRoot: {
      title: "Create a new root object",
      domain: {
        label: "Domain"
      },
      type: {
        label: "Object type"
      },
      suggestedType: {
        label: "Show only suggested root type"
      },
      submit: "Create"
    },
    create: {
      title: "Create a new object",
      type: {
        label: "Object type"
      },
      submit: "Create"
    }
  },
  representation: {
    create: {
      title: "Create a new representation",
      name: {
        label: "Name",
        placeholder: "Enter the name of the representation",
        helperText: "The name cannot be empty"
      },
      type: {
        label: "Representation type"
      },
      submit: "Create"
    }
  },
  image: {
    list: {
      copyId: "Copy ID to clipboard",
      "delete": "Delete",
      empty: "No project images available, start by uploading one",
      id: "ID",
      label: "Label",
      title: "Project Images",
      upload: "Upload"
    },
    upload: {
      title: "Upload new image",
      label: {
        label: "Label",
        placeholder: "Label for the image"
      },
      submit: "Upload"
    },
    rename: {
      title: "Rename the image",
      name: {
        label: "Name",
        placeholder: "Enter the new image name",
        helperText: "The name must contain at least 3 characters"
      },
      submit: "Rename"
    },
    "delete": {
      title: "Delete the image",
      content: "This action will delete the image and might break projects which use it. It cannot be reversed.",
      submit: "Delete"
    }
  }
};
const en = {
  siriusWebApplication: siriusWebApplication$1
};
const siriusWebApplication = {
  workbench: {
    details: "Свойства",
    explorer: "Навигатор",
    relatedElements: "Связанные элементы",
    representations: "Представления",
    validation: "Валидация"
  },
  core: {
    clickToSelectFile: "Кликните здесь для выбора файла"
  },
  navigationBar: {
    backToHomepage: "Вернуться на главную страницу"
  },
  project: {
    list: {
      blankProject: "Пустой проект",
      createNewProject: "Создать новый проект",
      "delete": "Удалить",
      download: "Скачать",
      empty: "Проекты не найдены, начните работу с создания проекта",
      existingProjects: "Существующие проекты",
      more: "Больше",
      name: "Название",
      rename: "Переименовать",
      selectProjectTemplate: "Выберите шаблон проекта",
      showAllTemplates: "Показать все шаблоны",
      uploadProject: "Загрузить проект"
    },
    create: {
      title: "Создать проект",
      description: "Начните работу с создания нового проекта",
      name: {
        label: "Название",
        placeholder: "Укажите название проекта",
        helperText: "Название должно содержать от 3 до 1024 символов"
      },
      submit: "Создать"
    },
    upload: {
      title: "Загрузить проект",
      description: "Начните работу с существующим проектом",
      clickToSelectFile: "Кликните здесь для выбора файла",
      submit: "Загрузить"
    },
    rename: {
      title: "Переименовать проект",
      name: {
        label: "Название",
        placeholder: "Укажите новое название проекта",
        helperText: "Название обязательно и должно содержать менее 1024 символов"
      },
      submit: "Переименовать"
    },
    "delete": {
      title: 'Удалить проект "{{name}}"',
      content: "Это действие удалит всё содержимое проекта. Все данные и представления будут потеряны. Это не может быть отменено.",
      submit: "Удалить"
    },
    edit: {
      createModel: "Создать новую модель",
      createRepresentation: "Создать новое представление",
      "delete": "Удалить",
      download: "Скачать",
      newModel: "Новая модель",
      noAccessToCreateModel: "Вам необходимы права на редактирование, чтобы создавать модели",
      noAccessToCreateRepresentation: "Вам необходимы права на редактирование, чтобы создавать представления",
      noRepresentations: "Для выбранных объектов нет представлений, которые могут быть созданы",
      openRepresentation: "Открыть существующее представление",
      rename: "Переименовать",
      selectModelToCreate: "Выберите модель для создания",
      selectRepresentationToCreate: "Выберите представление, которое необходимо создать",
      selectRepresentationToOpen: "Выберите представление, чтобы открыть его",
      settings: "Настройки",
      newObject: "Новый объект",
      newRepresentation: "Новое представление",
      uploadModel: "Загрузить модель"
    }
  },
  model: {
    create: {
      title: "Создать новую модель",
      name: {
        label: "Название",
        placeholder: "Укажите название модели",
        helperText: "Название не может быть пустым"
      },
      modelType: {
        label: "Тип модели"
      },
      submit: "Создать"
    },
    upload: {
      title: "Загрузить новую модель",
      submit: "Загрузить",
      cancel: "Закрыть"
    }
  },
  object: {
    newRoot: {
      title: "Создать новый корневой объект",
      domain: {
        label: "Предметная область"
      },
      type: {
        label: "Тип объекта"
      },
      suggestedType: {
        label: "Показывать только рекомендуемые типы"
      },
      submit: "Создать"
    },
    create: {
      title: "Создать новый объект",
      type: {
        label: "Тип объекта"
      },
      submit: "Создать"
    }
  },
  representation: {
    create: {
      title: "Создать представление",
      name: {
        label: "Название",
        placeholder: "Укажите название представления",
        helperText: "Название не может быть пустым"
      },
      type: {
        label: "Тип представления"
      },
      submit: "Создать"
    }
  },
  image: {
    list: {
      copyId: "Скопировать идентификатор в буфер обмена",
      "delete": "Удалить",
      empty: "Изображений нет, начните работу с загрузки изображения",
      id: "Идентификатор",
      label: "Название",
      title: "Изображения для проекта",
      upload: "Загрузить"
    },
    upload: {
      title: "Загрузить новое изображение",
      label: {
        label: "Название",
        placeholder: "Название изображения"
      },
      submit: "Загрузить"
    },
    rename: {
      title: "Переименовать изображение",
      name: {
        label: "Название",
        placeholder: "Укажите название изображения",
        helperText: "Название должно содержать не менее 3 символов"
      },
      submit: "Переименовать"
    },
    "delete": {
      title: "Удалить изображение",
      content: "Это действие удалит изображение и может нарушить работу проектов, использующих его. Это не может быть отменено.",
      submit: "Удалить"
    }
  }
};
const ru = {
  siriusWebApplication
};
export {
  DefaultExtensionRegistryMergeStrategy,
  DetailsView,
  DiagramFilter,
  DiagramRepresentationConfiguration,
  DiagramTreeItemContextMenuContribution,
  DocumentTreeItemContextMenuContribution,
  EditProjectView,
  EditingContextStereotypesFragment,
  NavigationBar,
  NewProjectCard,
  NewProjectView,
  ObjectTreeItemContextMenuContribution,
  OnboardArea,
  ProjectBrowser,
  ProjectFragment,
  ProjectImagesSettings,
  ProjectSettingsView,
  RelatedElementsView,
  RepresentationsView,
  Router,
  ShowAllProjectTemplatesCard,
  SiriusWebApplication,
  StereotypeFragment,
  ToastProvider,
  UploadProjectCard,
  UploadProjectView,
  ViewerProjectsFragment,
  apolloClientOptionsConfigurersExtensionPoint,
  cacheOptionsConfigurersExtensionPoint,
  createProjectAreaCardExtensionPoint,
  defaultExtensionRegistry,
  editProjectNavbarSubtitleExtensionPoint,
  footerExtensionPoint,
  httpOptionsConfigurersExtensionPoint,
  navigationBarIconExtensionPoint,
  navigationBarLeftContributionExtensionPoint,
  navigationBarMenuEntryExtensionPoint,
  navigationBarMenuHelpURLExtensionPoint,
  navigationBarMenuIconExtensionPoint,
  navigationBarRightContributionExtensionPoint,
  projectActionButtonMenuItemExtensionPoint,
  projectSettingsTabExtensionPoint,
  projectsTableRowExtensionPoint,
  routerExtensionPoint,
  en as siriusWebApplicationEn,
  ru as siriusWebApplicationRu,
  useCurrentProject,
  webSocketOptionsConfigurersExtensionPoint,
  withErrorBoundary
};
