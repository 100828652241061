import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import Board from "@ObeoNetwork/react-trello";
import { styled, useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import React, { forwardRef, useRef, useImperativeHandle, useState, useCallback, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { gql, useMutation, useSubscription } from "@apollo/client";
import { useReporting, ShareRepresentationModal, getCSSColor, useSelection, useMultiToast } from "@eclipse-sirius/sirius-components-core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Tooltip from "@mui/material/Tooltip";
const DeckInput = forwardRef(
  ({ value: value2, placeholder, onSave, style, multiline, ...otherProps }, ref) => {
    const StyledTextField = styled(TextField)(({ theme }) => ({
      "& .MuiInputBase-multiline": {
        padding: "0px 0px 0px 0px"
      },
      "& input": {
        padding: "0px 0px 0px 0px"
      },
      "& input:focus": {
        backgroundColor: "white",
        boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.light}`
      },
      "& textarea": {
        padding: "0px 0px 0px 0px"
      },
      "& textarea:focus": {
        backgroundColor: "white",
        boxShadow: `inset 0 0 0 1px ${theme.palette.secondary.light}`
      }
    }));
    const onFocus = (e) => e.target.select();
    const textInput = useRef(null);
    const onMouseDown = (e) => {
      var _a;
      if (document.activeElement != e.target) {
        e.preventDefault();
        (_a = textInput.current) == null ? void 0 : _a.focus();
      }
    };
    const onBlur = () => {
      updateValue();
    };
    const onKeyDown = (e) => {
      var _a, _b, _c;
      if (e.key == "Enter") {
        (_a = textInput.current) == null ? void 0 : _a.blur();
        e.preventDefault();
      }
      if (e.key == "Escape") {
        setValue(value2);
        (_b = textInput.current) == null ? void 0 : _b.blur();
        e.preventDefault();
      }
      if (e.key == "Tab") {
        (_c = textInput.current) == null ? void 0 : _c.blur();
      }
    };
    const getValue = () => {
      var _a;
      return ((_a = textInput.current) == null ? void 0 : _a.value) ?? "";
    };
    const setValue = (value22) => {
      if (textInput.current) {
        textInput.current.value = value22;
      }
    };
    const updateValue = () => {
      if (getValue() != value2) {
        onSave(getValue());
      }
    };
    const handleOnClick = (e) => {
      e.stopPropagation();
    };
    useImperativeHandle(ref, () => textInput.current);
    return /* @__PURE__ */ jsx(
      StyledTextField,
      {
        inputRef: textInput,
        onMouseDown,
        onFocus,
        onBlur,
        onKeyDown,
        onClick: handleOnClick,
        placeholder: (value2 == null ? void 0 : value2.length) == 0 ? void 0 : placeholder,
        defaultValue: value2,
        autoComplete: "off",
        autoCorrect: "off",
        autoCapitalize: "off",
        spellCheck: "false",
        multiline,
        fullWidth: true,
        inputProps: {
          style: {
            ...style
          }
        },
        InputProps: {
          disableUnderline: true
        },
        size: "small",
        ...otherProps
      }
    );
  }
);
const cardLabelFontStyle = {
  fontSize: "10px",
  textAlign: "right"
};
const cardDetailFontStyle = {
  fontSize: "12px",
  minHeight: "20px"
};
const DeckDraggableCardWrapper = styled("article")(({ theme }) => ({
  borderRadius: " 3px",
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: "white",
  position: "relative",
  padding: "10px",
  cursor: "pointer",
  maxWidth: "250px",
  marginBottom: "7px",
  minWidth: "230px"
}));
const DeckCardHeader = styled("article")(({ theme }) => ({
  marginBottom: "10px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingBottom: "6px",
  color: theme.palette.text.primary
}));
const DeckCardRightContent = styled("span")({
  width: " 38%",
  paddingRight: "10px"
});
const CardDeleteIconButton = styled(IconButton)({
  position: "absolute",
  top: "2px",
  right: "2px",
  padding: "0",
  " & .MuiSvgIcon-fontSizeSmall": {
    fontSize: "12px"
  }
});
const Detail = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  whiteSpace: "pre-wrap"
}));
const Footer = styled("div")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: "6px",
  textAlign: "right",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
  flexWrap: "wrap"
}));
const TagSpan = styled("span")({
  padding: " 2px 3px",
  borderRadius: "3px",
  margin: "2px 5px",
  fontSize: "70%"
});
const titleFontStyle = {
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "18px"
};
const Title = styled("span")(({ theme }) => ({
  width: "70%",
  color: theme.palette.text.primary
}));
const DeckTitle = styled(Title)((props) => ({
  cursor: props.draggable ? "grab" : `auto`
}));
const RightContent = styled("span")({
  width: "38%",
  textAlign: "right",
  paddingRight: "10px",
  fontSize: "13px"
});
const DeckDeleteButton = (props) => {
  return /* @__PURE__ */ jsx(CardDeleteIconButton, { tabIndex: -1, "aria-label": "deleteCard", ...props, children: /* @__PURE__ */ jsx(DeleteIcon, { fontSize: "small" }) });
};
const DeckTag = ({ title, color, bgcolor, tagStyle }) => {
  const style = { color: color || "white", backgroundColor: bgcolor || "orange", ...tagStyle };
  return /* @__PURE__ */ jsx(TagSpan, { style, children: title });
};
const DeckCard = ({
  style,
  tagStyle,
  titleStyle = titleFontStyle,
  onClick,
  onDelete,
  onChange,
  className,
  id,
  title,
  label,
  description,
  tags,
  cardDraggable,
  editable,
  t: translate
}) => {
  const updateCard2 = (updateCardValue) => {
    onChange(updateCardValue);
  };
  const handleOnDelete = (e) => {
    onDelete();
    e.stopPropagation();
  };
  const theme = useTheme();
  const cardStyle = {
    border: editable ? `2px solid ${theme.palette.selected}` : void 0,
    ...style
  };
  const titleInputRef = useRef(null);
  const handleKeyDown = (e) => {
    if (titleInputRef.current) {
      if (e.key === "F2") {
        titleInputRef.current.select();
      }
    }
  };
  return /* @__PURE__ */ jsxs(
    DeckDraggableCardWrapper,
    {
      tabIndex: 0,
      "data-id": id,
      onClick,
      style: cardStyle,
      className,
      onKeyDown: handleKeyDown,
      "data-testid": `card-${title}`,
      onDragStart: (e) => e.preventDefault(),
      children: [
        /* @__PURE__ */ jsxs(DeckCardHeader, { children: [
          /* @__PURE__ */ jsx(DeckTitle, { draggable: cardDraggable, style: titleStyle, theme, "data-testid": `card-${title}-title`, children: editable ? /* @__PURE__ */ jsx(
            DeckInput,
            {
              ref: titleInputRef,
              value: title,
              placeholder: translate("placeholder.title"),
              onSave: (value2) => updateCard2({ title: value2, label, description, id }),
              style: titleStyle,
              "data-testid": "card-input-title"
            }
          ) : title }),
          /* @__PURE__ */ jsx(DeckCardRightContent, { style: cardLabelFontStyle, children: editable ? /* @__PURE__ */ jsx(
            DeckInput,
            {
              value: label,
              placeholder: translate("placeholder.label"),
              onSave: (value2) => updateCard2({ title, label: value2, description, id }),
              style: cardLabelFontStyle,
              "data-testid": "card-input-label"
            }
          ) : label }),
          editable && /* @__PURE__ */ jsx(DeckDeleteButton, { onClick: handleOnDelete })
        ] }),
        /* @__PURE__ */ jsx(Detail, { style: cardDetailFontStyle, children: editable ? /* @__PURE__ */ jsx(
          DeckInput,
          {
            value: description,
            placeholder: translate("placeholder.description"),
            onSave: (value2) => updateCard2({ title, label, description: value2, id }),
            style: cardDetailFontStyle,
            multiline: true,
            "data-testid": "card-input-details"
          }
        ) : description }),
        tags && tags.length > 0 && /* @__PURE__ */ jsx(Footer, { children: tags.map((tag) => /* @__PURE__ */ jsx(DeckTag, { ...tag, tagStyle }, tag.title)) })
      ]
    }
  );
};
const useZoom = (contentNode, containerNode) => {
  const [zoom, setZoom] = useState(1);
  const zoomIn = useCallback(() => {
    setZoom((prevZoom) => prevZoom * 1.25);
  }, []);
  const zoomOut = useCallback(() => {
    setZoom((prevZoom) => prevZoom * 0.8);
  }, []);
  const resetZoom = useCallback(() => {
    setZoom(() => 1);
  }, []);
  const fitToScreen = useCallback(() => {
    if (containerNode.current && contentNode.current) {
      const containerWidth = containerNode.current.clientWidth;
      const containerHeight = containerNode.current.clientHeight;
      const contentWidth = contentNode.current.clientWidth;
      const contentHeight = contentNode.current.clientHeight;
      const scaleX = containerWidth / contentWidth;
      const scaleY = containerHeight / contentHeight;
      const newScale = Math.min(scaleX, scaleY);
      setZoom(() => newScale);
    }
  }, []);
  return {
    zoom,
    zoomIn,
    zoomOut,
    fitToScreen,
    resetZoom
  };
};
const LaneHeader = styled("header")({
  marginBottom: " 10px",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "0px 5px"
});
const value = {
  editingContextId: "",
  deckId: ""
};
const DeckContext = React.createContext(value);
const deleteCardMutation = gql`
  mutation deleteDeckCard($input: DeleteDeckCardInput!) {
    deleteDeckCard(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const editCardMutation = gql`
  mutation editDeckCard($input: EditDeckCardInput!) {
    editDeckCard(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const createDeckCardMutation = gql`
  mutation createDeckCard($input: CreateDeckCardInput!) {
    createDeckCard(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const dropDeckCardMutation = gql`
  mutation dropDeckCard($input: DropDeckCardInput!) {
    dropDeckCard(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const editLaneMutation = gql`
  mutation editDeckLane($input: EditDeckLaneInput!) {
    editDeckLane(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const dropDeckLaneMutation = gql`
  mutation dropDeckLane($input: DropDeckLaneInput!) {
    dropDeckLane(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const changeLaneCollapsedStateMutation = gql`
  mutation changeLaneCollapsedState($input: ChangeLaneCollapsedStateInput!) {
    changeLaneCollapsedState(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const changeCardsVisibilityMutation = gql`
  mutation changeCardsVisibility($input: ChangeCardsVisibilityInput!) {
    changeCardsVisibility(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const useDeckMutations = (editingContextId, representationId) => {
  const [rawDeleteDeckCard, rawDeleteDeckCardResult] = useMutation(
    deleteCardMutation
  );
  useReporting(rawDeleteDeckCardResult, (data) => data.deleteDeckCard);
  const deleteCard = (cardId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      cardId
    };
    rawDeleteDeckCard({ variables: { input } });
  };
  const [rawEditDeckCard, rawEditDeckCardResult] = useMutation(editCardMutation);
  useReporting(rawEditDeckCardResult, (data) => data.editDeckCard);
  const editDeckCard = (card) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      cardId: card.id,
      newTitle: card.title,
      newLabel: card.label,
      newDescription: card.description
    };
    rawEditDeckCard({ variables: { input } });
  };
  const [rawCreateDeckCard, rawCreateDeckCardResult] = useMutation(
    createDeckCardMutation
  );
  useReporting(rawCreateDeckCardResult, (data) => data.createDeckCard);
  const createCard = (card, laneId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      currentLaneId: laneId,
      title: card.title,
      label: card.label,
      description: card.description
    };
    rawCreateDeckCard({ variables: { input } });
  };
  const [rawDropDeckCard, rawDropDeckCardResult] = useMutation(
    dropDeckCardMutation
  );
  useReporting(rawDropDeckCardResult, (data) => data.dropDeckCard);
  const dropDeckCard = (oldLaneId, newLaneId, cardId, addedIndex) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      oldLaneId,
      newLaneId,
      cardId,
      addedIndex
    };
    rawDropDeckCard({ variables: { input } });
  };
  const [rawEditDeckLane, rawEditDeckLaneResult] = useMutation(editLaneMutation);
  useReporting(rawEditDeckLaneResult, (data) => data.editDeckLane);
  const editDeckLane = (laneId, newValue) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      laneId,
      newTitle: newValue.title
    };
    rawEditDeckLane({ variables: { input } });
  };
  const [rawDropDeckLane, rawDropDeckLaneResult] = useMutation(
    dropDeckLaneMutation
  );
  useReporting(rawDropDeckLaneResult, (data) => data.dropDeckLane);
  const dropDeckLane = (newIndex, lane) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      laneId: lane.id,
      newIndex
    };
    rawDropDeckLane({ variables: { input } });
  };
  const [rawChangeLaneCollapsedState, rawChangeLaneCollapsedStateResult] = useMutation(changeLaneCollapsedStateMutation);
  useReporting(
    rawChangeLaneCollapsedStateResult,
    (data) => data.changeLaneCollapsedState
  );
  const changeLaneCollapsedState = (laneId, collapsed) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      laneId,
      collapsed
    };
    rawChangeLaneCollapsedState({ variables: { input } });
  };
  const [rawChangeCardsVisibility, rawChangeCardsVisibilityResult] = useMutation(changeCardsVisibilityMutation);
  useReporting(rawChangeCardsVisibilityResult, (data) => data.changeCardsVisibility);
  const changeCardsVisibility = (hiddenCardsIds, visibleCardsIds) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      hiddenCardsIds,
      visibleCardsIds
    };
    rawChangeCardsVisibility({ variables: { input } });
  };
  return {
    deleteCard,
    editDeckCard,
    createCard,
    dropDeckCard,
    editDeckLane,
    dropDeckLane,
    changeLaneCollapsedState,
    changeCardsVisibility
  };
};
const useCardVisibility = (cards) => {
  const initialState = cards.filter((card) => card.visible).map((card) => card.id);
  const [selectedCardsIds, setSelectedCardsIds] = useState(initialState);
  const handleVisibilityChanged = (id) => {
    const cardIndex = selectedCardsIds.findIndex((cardId) => cardId === id);
    if (cardIndex > -1) {
      const newSelectedCards = selectedCardsIds.slice();
      newSelectedCards.splice(cardIndex, 1);
      setSelectedCardsIds(() => newSelectedCards);
    } else {
      const newSelectedCards = selectedCardsIds.slice();
      newSelectedCards.push(id);
      setSelectedCardsIds(() => newSelectedCards);
    }
  };
  return { selectedCardsIds, handleVisibilityChanged };
};
const useStyle = makeStyles()((theme) => ({
  listItemText: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary
  },
  listItemIconVisible: {
    color: theme.palette.text.primary
  },
  listItemIconHidden: {
    color: theme.palette.text.disabled
  },
  buttonContainer: {
    display: "flex"
  }
}));
const LaneContextMenu = ({ menuAnchor, onClose, onChangesApplied, cards }) => {
  const { handleVisibilityChanged, selectedCardsIds } = useCardVisibility(cards);
  const onApplyClicked = () => {
    onChangesApplied(selectedCardsIds);
  };
  const { classes } = useStyle();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    Menu,
    {
      id: "lane-contextmenu",
      anchorEl: menuAnchor,
      keepMounted: true,
      open: true,
      onClose,
      "data-testid": "lane-contextmenu",
      disableRestoreFocus: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      children: [
        /* @__PURE__ */ jsxs(
          List,
          {
            dense: true,
            component: "nav",
            "aria-labelledby": "nested-list-subheader",
            subheader: /* @__PURE__ */ jsx(ListSubheader, { className: classes.listItemText, inset: false, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: "Visible Cards" }) }),
            children: [
              /* @__PURE__ */ jsx(Divider, {}),
              cards.map((card) => /* @__PURE__ */ jsxs(
                ListItem,
                {
                  button: true,
                  dense: true,
                  onClick: () => handleVisibilityChanged(card.id),
                  "data-testid": `hide-reveal-card-${card.title}`,
                  children: [
                    /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemText, children: selectedCardsIds.includes(card.id) ? /* @__PURE__ */ jsx(Visibility, { className: classes.listItemIconVisible, fontSize: "small", "data-testid": "visibility-on" }) : /* @__PURE__ */ jsx(VisibilityOff, { className: classes.listItemIconHidden, fontSize: "small", "data-testid": "visibility-off" }) }),
                    /* @__PURE__ */ jsx(ListItemText, { className: classes.listItemText, primary: card.title })
                  ]
                },
                card.id
              ))
            ]
          }
        ),
        /* @__PURE__ */ jsx(Box, { sx: { display: "flex", paddingX: "10px", justifyContent: "flex-end" }, children: /* @__PURE__ */ jsx(
          Button,
          {
            size: "small",
            "data-testid": "apply-card-visibility",
            color: "primary",
            onClick: onApplyClicked,
            variant: "contained",
            children: "Apply"
          }
        ) })
      ]
    }
  ) });
};
const useLaneContextMenu = (cards, id) => {
  const { editingContextId, deckId } = useContext(DeckContext);
  const { changeCardsVisibility } = useDeckMutations(editingContextId, deckId);
  const initialState = {
    showContextMenu: false,
    menuAnchor: null
  };
  const [state, setState] = useState(initialState);
  const { showContextMenu, menuAnchor } = state;
  const openContextMenu = (event) => {
    if (!showContextMenu) {
      const { currentTarget } = event;
      if (currentTarget instanceof Element) {
        setState(() => {
          return {
            showContextMenu: true,
            menuAnchor: currentTarget
          };
        });
      }
    }
  };
  let contextMenu = null;
  if (showContextMenu && menuAnchor) {
    const applyChanges = (visibleCardsIds) => {
      const hiddenCards = cards.map((card) => card.id).filter((cardId) => !visibleCardsIds.includes(cardId));
      changeCardsVisibility(hiddenCards, visibleCardsIds);
      closeContextMenu();
    };
    const closeContextMenu = () => {
      setState(() => {
        return {
          modalDisplayed: null,
          showContextMenu: false,
          menuAnchor: null
        };
      });
    };
    contextMenu = /* @__PURE__ */ jsx(
      LaneContextMenu,
      {
        menuAnchor,
        laneId: id,
        cards,
        onClose: closeContextMenu,
        onChangesApplied: applyChanges
      }
    );
  }
  return { openContextMenu, contextMenu };
};
const useLaneHeaderStyle = makeStyles()((theme) => ({
  more: {
    hover: {
      backgroundColor: theme.palette.action.hover
    },
    focus: {
      backgroundColor: theme.palette.action.selected
    }
  }
}));
const DeckLaneHeader = ({
  updateTitle,
  editLaneTitle,
  label,
  title,
  t: translate,
  laneDraggable,
  cards,
  id,
  titleStyle = titleFontStyle
}) => {
  const theme = useTheme();
  const titleInputRef = useRef(null);
  const headerRef = useRef(null);
  const { openContextMenu, contextMenu } = useLaneContextMenu(cards, id);
  useEffect(() => {
    if (headerRef.current) {
      if (editLaneTitle) {
        headerRef.current.focus();
      }
    }
  }, [editLaneTitle]);
  const handleKeyDown = (e) => {
    if (titleInputRef.current) {
      if (e.key === "F2") {
        titleInputRef.current.select();
      }
    }
  };
  const { classes } = useLaneHeaderStyle();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(LaneHeader, { onKeyDown: handleKeyDown, tabIndex: 0, ref: headerRef, children: [
      /* @__PURE__ */ jsx(DeckTitle, { draggable: laneDraggable, style: titleStyle, theme, "data-testid": `lane-${title}-title`, children: editLaneTitle ? /* @__PURE__ */ jsx(
        DeckInput,
        {
          ref: titleInputRef,
          value: title,
          placeholder: translate("placeholder.title"),
          onSave: updateTitle,
          style: titleStyle,
          "data-testid": "lane-input-title"
        }
      ) : title }),
      label && /* @__PURE__ */ jsx(RightContent, { children: label }),
      /* @__PURE__ */ jsx(IconButton, { className: classes.more, size: "small", onClick: openContextMenu, "data-testid": `lane-${title}-more`, children: /* @__PURE__ */ jsx(MoreVertIcon, { style: { fontSize: 14 } }) })
    ] }),
    contextMenu
  ] });
};
const useFullscreen = (domNode) => {
  const [fullscreen, setFullscreenState] = useState(false);
  useEffect(() => {
    const onFullscreenChange = () => setFullscreenState(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);
  const setFullscreen = useCallback(
    (fullscreen2) => {
      if (domNode.current) {
        if (fullscreen2) {
          domNode.current.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    [domNode.current]
  );
  return {
    fullscreen,
    setFullscreen
  };
};
const useToolbarStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    height: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider
  }
}));
const DeckToolbar = ({
  editingContextId,
  representationId,
  onZoomIn,
  onZoomOut,
  onFitToScreen,
  fullscreenNode,
  onResetZoom
}) => {
  const { classes } = useToolbarStyles();
  const [state, setState] = useState({ modal: null });
  const { fullscreen, setFullscreen } = useFullscreen(fullscreenNode);
  const onShare = () => setState((prevState) => ({ ...prevState, modal: "share" }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modal: null }));
  let modalElement = null;
  if (state.modal === "share") {
    modalElement = /* @__PURE__ */ jsx(
      ShareRepresentationModal,
      {
        editingContextId,
        representationId,
        onClose: closeModal
      }
    );
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.toolbar, children: [
      fullscreen ? /* @__PURE__ */ jsx(Tooltip, { title: "Exit full screen mode", children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "exit full screen mode",
          onClick: () => setFullscreen(false),
          children: /* @__PURE__ */ jsx(FullscreenExitIcon, {})
        }
      ) }) : /* @__PURE__ */ jsx(Tooltip, { title: "Toggle full screen mode", children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "toggle full screen mode",
          onClick: () => setFullscreen(true),
          children: /* @__PURE__ */ jsx(FullscreenIcon, {})
        }
      ) }),
      !fullscreen ? (
        //We disable the Fit to Screen but in Full screen mode because of issues to compute the parent container size.
        /* @__PURE__ */ jsx(Tooltip, { title: "Fit to Screen", children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            color: "inherit",
            "aria-label": "fit to screen",
            onClick: onFitToScreen,
            "data-testid": "fit-to-screen",
            children: /* @__PURE__ */ jsx(AspectRatioIcon, {})
          }
        ) })
      ) : null,
      /* @__PURE__ */ jsx(Tooltip, { title: "Zoom In", children: /* @__PURE__ */ jsx(IconButton, { size: "small", color: "inherit", "aria-label": "zoom in", onClick: onZoomIn, "data-testid": "zoomIn", children: /* @__PURE__ */ jsx(ZoomInIcon, {}) }) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Zoom Out", children: /* @__PURE__ */ jsx(IconButton, { size: "small", color: "inherit", "aria-label": "zoom out", onClick: onZoomOut, "data-testid": "zoomOut", children: /* @__PURE__ */ jsx(ZoomOutIcon, {}) }) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Reset zoom", children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "reset zoom",
          onClick: onResetZoom,
          "data-testid": "resetZoom",
          children: /* @__PURE__ */ jsx(SearchIcon, {})
        }
      ) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Share", children: /* @__PURE__ */ jsx(IconButton, { size: "small", color: "inherit", "aria-label": "share", onClick: onShare, "data-testid": "share", children: /* @__PURE__ */ jsx(ShareIcon, {}) }) })
    ] }),
    modalElement
  ] });
};
const useDeckStyle = makeStyles()((theme) => ({
  boardContainer: {
    //We need to make the board display flex to fit to screen when the deck is smaller than the representation container.
    //Without that, the board div occupies the whole representation container width.
    display: "flex"
  },
  deckContainer: {
    backgroundColor: theme.palette.background.default
  }
}));
const Deck = ({
  editingContextId,
  representationId,
  data,
  onCardClick,
  onLaneClick,
  onCardDelete,
  onCardAdd,
  onCardUpdate,
  onCardMoveAcrossLanes,
  onLaneUpdate,
  onLaneCollapseUpdate,
  handleLaneDragEnd
}) => {
  var _a;
  const theme = useTheme();
  const boardContainerRef = useRef(null);
  const deckContainerRef = useRef(null);
  const representationContainerRef = useRef(null);
  const boardRef = useRef(null);
  const { zoom, zoomIn, zoomOut, fitToScreen, resetZoom } = useZoom(boardRef, representationContainerRef);
  const { classes: deckClasses } = useDeckStyle();
  useEffect(() => {
    var _a2, _b;
    const representationContainer = (_a2 = deckContainerRef.current) == null ? void 0 : _a2.parentElement;
    const boardElement = (_b = boardContainerRef.current) == null ? void 0 : _b.firstChild;
    if ((representationContainer == null ? void 0 : representationContainer.nodeName) === "DIV" && (boardElement == null ? void 0 : boardElement.nodeName) === "DIV") {
      boardRef.current = boardElement;
      representationContainerRef.current = representationContainer;
    }
  }, [boardContainerRef, deckContainerRef]);
  const backgroundColor = (_a = data.style) == null ? void 0 : _a.backgroundColor;
  const boardStyle = {
    backgroundColor: backgroundColor ?? theme.palette.background.default,
    transform: `scale(${zoom})`,
    transformOrigin: "0 0",
    transitionDuration: "0.3s"
  };
  const components = {
    Card: DeckCard,
    LaneHeader: DeckLaneHeader
  };
  const getGhostParent = () => {
    return boardContainerRef.current ? boardContainerRef.current : void 0;
  };
  return /* @__PURE__ */ jsx(DeckContext.Provider, { value: { editingContextId, deckId: representationId }, children: /* @__PURE__ */ jsxs("div", { ref: deckContainerRef, className: deckClasses.deckContainer, children: [
    /* @__PURE__ */ jsx(
      DeckToolbar,
      {
        editingContextId,
        representationId,
        onZoomIn: zoomIn,
        onZoomOut: zoomOut,
        onFitToScreen: fitToScreen,
        onResetZoom: resetZoom,
        fullscreenNode: deckContainerRef
      }
    ),
    /* @__PURE__ */ jsx("div", { ref: boardContainerRef, className: deckClasses.boardContainer, children: /* @__PURE__ */ jsx(
      Board,
      {
        data,
        draggable: true,
        onCardClick,
        onLaneClick,
        components,
        onCardDelete,
        onCardAdd,
        onCardUpdate,
        onLaneUpdate,
        onLaneCollapseUpdate,
        onCardMoveAcrossLanes,
        handleLaneDragEnd,
        getGhostParent,
        style: boardStyle,
        "data-testid": `deck-representation`
      }
    ) })
  ] }) });
};
const convertToTrelloDeckData = (deck, selectedElementIds, theme) => {
  var _a;
  const backgroundColor = ((_a = deck.style) == null ? void 0 : _a.backgroundColor) ? getCSSColor(deck.style.backgroundColor, theme) : void 0;
  const data = {
    lanes: [],
    style: { backgroundColor }
  };
  for (const lane of deck.lanes) {
    const cards = lane.cards.map((card) => convertGQLCard(card, selectedElementIds, theme));
    const { id, label, title, targetObjectId, collapsed, collapsible, style } = lane;
    const selectedLane = selectedElementIds.includes(targetObjectId);
    const cssStyleFromDescription = style ? convertElementStyle(style, theme) : {};
    const { backgroundColor: backgroundColor2, ...otherStyleProps } = cssStyleFromDescription;
    const laneStyle = {
      border: selectedLane ? `2px solid ${theme.palette.selected}` : void 0,
      width: "280px",
      backgroundColor: backgroundColor2
    };
    const convertedLane = {
      id,
      label,
      title,
      editLaneTitle: selectedLane,
      editable: true,
      cards,
      style: laneStyle,
      titleStyle: otherStyleProps,
      collapsible,
      collapsed,
      "data-testid": `lane-${lane.title}`
    };
    data.lanes.push(convertedLane);
  }
  return data;
};
const convertGQLCard = (card, selectedElementIds, theme) => {
  let editable = false;
  const { targetObjectId, targetObjectLabel, targetObjectKind, style, ...otherCardProps } = card;
  const metadata = {
    selection: {
      id: targetObjectId,
      label: targetObjectLabel,
      kind: targetObjectKind
    }
  };
  if (selectedElementIds.includes(card.targetObjectId)) {
    editable = true;
  }
  const cssStyle = style ? convertElementStyle(style, theme) : {};
  const { backgroundColor, ...otherStyle } = cssStyle;
  return {
    ...otherCardProps,
    editable,
    metadata,
    style: { backgroundColor },
    titleStyle: otherStyle
  };
};
const convertElementStyle = (style, theme) => {
  return {
    backgroundColor: getCSSColor(style.backgroundColor, theme),
    color: getCSSColor(style.color, theme),
    fontSize: style.fontSize,
    fontStyle: style.italic ? "italic" : "normal",
    fontWeight: style.bold ? "bold" : "normal",
    textDecoration: getTextDecoration(style)
  };
};
const getTextDecoration = (style) => {
  let decoration = "";
  if (style == null ? void 0 : style.strikeThrough) {
    decoration = decoration + "line-through";
  }
  if (style == null ? void 0 : style.underline) {
    let separator = decoration.length > 0 ? " " : "";
    decoration = decoration + separator + "underline";
  }
  return decoration.length > 0 ? decoration : "none";
};
const moveCardInDeckLanes = (deck, oldLaneId, newLaneId, cardId, addedIndex) => {
  const deckToReturn = {
    ...deck
  };
  const newLane = deckToReturn.lanes.find((lane) => lane.id === newLaneId);
  let oldLane;
  if (oldLaneId !== newLaneId) {
    oldLane = deckToReturn.lanes.find((lane) => lane.id === oldLaneId);
  } else {
    oldLane = newLane;
  }
  const cardToMove = oldLane == null ? void 0 : oldLane.cards.find((card) => card.id === cardId);
  if (oldLane && cardToMove) {
    const index = oldLane.cards.indexOf(cardToMove);
    if (index != void 0 && index > -1) {
      oldLane.cards.splice(index, 1);
    }
  }
  if (newLane && cardToMove) {
    newLane.cards.splice(addedIndex, 0, cardToMove);
  }
  return deckToReturn;
};
const findLaneById = (deck, laneId) => {
  return deck.lanes.find((lane) => lane.id === laneId);
};
const updateCard = (deck, newCard) => {
  const newDeck = {
    ...deck
  };
  newDeck.lanes.forEach((lane) => {
    const index = lane.cards.findIndex((card) => card.id === newCard.id);
    if (index > -1) {
      const removedCards = lane.cards.splice(index, 1);
      if (removedCards.length === 1) {
        const removedCard = removedCards[0];
        const cardToUpdate = {
          ...removedCard,
          description: newCard.description,
          label: newCard.label,
          title: newCard.title
        };
        lane.cards.splice(index, 0, cardToUpdate);
      }
    }
  });
  return newDeck;
};
const updateLane = (deck, laneId, newTitle) => {
  const newDeck = {
    ...deck
  };
  const index = newDeck.lanes.findIndex((lane) => lane.id === laneId);
  if (index > -1) {
    const removedLanes = newDeck.lanes.splice(index, 1);
    if (removedLanes.length === 1) {
      const removedLane = removedLanes[0];
      const laneToUpdate = {
        ...removedLane,
        title: newTitle
      };
      newDeck.lanes.splice(index, 0, laneToUpdate);
    }
  }
  return newDeck;
};
const moveLaneInDeck = (deck, oldIndex, newIndex) => {
  const deckToReturn = {
    ...deck
  };
  const laneToMove = deckToReturn.lanes[oldIndex];
  if (laneToMove) {
    deckToReturn.lanes.splice(oldIndex, 1);
    deckToReturn.lanes.splice(newIndex, 0, laneToMove);
  }
  return deckToReturn;
};
const deckEventSubscription = gql`
  subscription deckEvent($input: DeckEventInput!) {
    deckEvent(input: $input) {
      __typename
      ... on ErrorPayload {
        id
        messages {
          body
          level
        }
      }
      ... on DeckRefreshedEventPayload {
        id
        deck {
          id
          style {
            backgroundColor
          }
          targetObjectId
          lanes {
            id
            title
            label
            targetObjectId
            collapsible
            collapsed
            cards {
              id
              targetObjectId
              targetObjectKind
              targetObjectLabel
              title
              label
              description
              visible
              style {
                bold
                color
                fontSize
                italic
                strikeThrough
                underline
                backgroundColor
              }
            }
            style {
              bold
              color
              fontSize
              italic
              strikeThrough
              underline
              backgroundColor
            }
          }
        }
      }
    }
  }
`;
const useDeckRepresentationStyles = makeStyles()(() => ({
  complete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
const isDeckRefreshedEventPayload = (payload) => payload.__typename === "DeckRefreshedEventPayload";
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const DeckRepresentation = ({ editingContextId, representationId }) => {
  const theme = useTheme();
  const { classes } = useDeckRepresentationStyles();
  const { selection, setSelection } = useSelection();
  const { addErrorMessage, addMessages } = useMultiToast();
  const [{ id, deck, complete }, setState] = useState({
    id: crypto.randomUUID(),
    deck: void 0,
    complete: false
  });
  const { error } = useSubscription(deckEventSubscription, {
    variables: {
      input: {
        id,
        editingContextId,
        deckId: representationId
      }
    },
    fetchPolicy: "no-cache",
    onData: ({ data }) => {
      if (data == null ? void 0 : data.data) {
        const { deckEvent } = data.data;
        if (isDeckRefreshedEventPayload(deckEvent)) {
          setState((prevState) => {
            return { ...prevState, deck: deckEvent.deck };
          });
        } else if (isErrorPayload(deckEvent)) {
          addMessages(deckEvent.messages);
        }
      }
    },
    onComplete: () => {
      setState((prevState) => {
        return { ...prevState, complete: true, deck: void 0 };
      });
    }
  });
  const { deleteCard, editDeckCard, createCard, dropDeckCard, editDeckLane, dropDeckLane, changeLaneCollapsedState } = useDeckMutations(editingContextId, representationId);
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  useEffect(() => {
    if (deck && selection.entries) {
      const selectionIds = selection.entries.map((entry) => entry.id);
      const tempselectedElementIds = [];
      deck.lanes.flatMap((lane) => lane.cards).forEach((card) => {
        if (selectionIds.includes(card.targetObjectId)) {
          tempselectedElementIds.push(card.id);
        }
      });
      setState((prevState) => {
        return { ...prevState, selectedElementIds: tempselectedElementIds };
      });
    }
  }, [selection]);
  const handleEditCard = (_laneId, card) => {
    if (deck) {
      const updatedDeck = updateCard(deck, card);
      setState((prevState) => {
        return { ...prevState, deck: updatedDeck };
      });
    }
    editDeckCard(card);
  };
  const handleEditLane = (laneId, newValue) => {
    if (deck) {
      const updatedDeck = updateLane(deck, laneId, newValue.title);
      setState((prevState) => {
        return { ...prevState, deck: updatedDeck };
      });
    }
    editDeckLane(laneId, newValue);
  };
  const handleCardClicked = (_cardId, metadata, _laneId) => {
    setSelection({
      entries: [metadata.selection]
    });
  };
  const handleDropDeckCard = (oldLaneId, newLaneId, cardId, addedIndex) => {
    if (deck) {
      const updatedDeck = moveCardInDeckLanes(deck, oldLaneId, newLaneId, cardId, addedIndex);
      setState((prevState) => {
        return { ...prevState, deck: updatedDeck };
      });
    }
    dropDeckCard(oldLaneId, newLaneId, cardId, addedIndex);
  };
  const handleLaneClicked = (laneId) => {
    if (deck) {
      const lane = findLaneById(deck, laneId);
      if (lane) {
        setSelection({
          entries: [
            {
              id: lane.targetObjectId,
              kind: "lane"
            }
          ]
        });
      }
    }
  };
  const handleLaneDragEnd = (oldIndex, newIndex, lane) => {
    if (deck) {
      const updatedDeck = moveLaneInDeck(deck, oldIndex, newIndex);
      setState((prevState) => {
        return { ...prevState, deck: updatedDeck };
      });
    }
    dropDeckLane(newIndex, lane);
  };
  let content = null;
  if (complete) {
    content = /* @__PURE__ */ jsx("div", { className: classes.complete, children: /* @__PURE__ */ jsx(Typography, { variant: "h5", align: "center", children: "The Deck does not exist anymore" }) });
  } else if (deck) {
    const selectedElementIds = selection.entries.map((entry) => entry.id);
    const data = convertToTrelloDeckData(deck, selectedElementIds, theme);
    content = /* @__PURE__ */ jsx(
      Deck,
      {
        editingContextId,
        representationId,
        data,
        onCardClick: handleCardClicked,
        onCardDelete: deleteCard,
        onCardAdd: createCard,
        onCardUpdate: handleEditCard,
        onCardMoveAcrossLanes: handleDropDeckCard,
        onLaneClick: handleLaneClicked,
        onLaneUpdate: handleEditLane,
        onLaneCollapseUpdate: changeLaneCollapsedState,
        handleLaneDragEnd
      }
    );
  }
  return /* @__PURE__ */ jsx(Fragment, { children: content });
};
export {
  Deck,
  DeckCard,
  DeckRepresentation
};
