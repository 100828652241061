import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { TitleColumn, GanttDateRoundingTimeUnit, ViewMode, Gantt as Gantt$1 } from "@ObeoNetwork/gantt-task-react";
import "@ObeoNetwork/gantt-task-react/dist/style.css";
import { useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "tss-react/mui";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";
import SvgIcon from "@mui/material/SvgIcon";
import { ShareRepresentationModal, useReporting, useMultiToast, useSelection } from "@eclipse-sirius/sirius-components-core";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ShareIcon from "@mui/icons-material/Share";
import ViewColumn from "@mui/icons-material/ViewColumn";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { gql, useMutation, useSubscription } from "@apollo/client";
import Typography from "@mui/material/Typography";
var GQLGanttDateRoundingTimeUnit = /* @__PURE__ */ ((GQLGanttDateRoundingTimeUnit2) => {
  GQLGanttDateRoundingTimeUnit2[GQLGanttDateRoundingTimeUnit2["MINUTE"] = 0] = "MINUTE";
  GQLGanttDateRoundingTimeUnit2[GQLGanttDateRoundingTimeUnit2["HOUR"] = 1] = "HOUR";
  GQLGanttDateRoundingTimeUnit2[GQLGanttDateRoundingTimeUnit2["DAY"] = 2] = "DAY";
  return GQLGanttDateRoundingTimeUnit2;
})(GQLGanttDateRoundingTimeUnit || {});
var TaskListColumnEnum = /* @__PURE__ */ ((TaskListColumnEnum2) => {
  TaskListColumnEnum2["NAME"] = "NAME";
  TaskListColumnEnum2["START_DATE"] = "START_DATE";
  TaskListColumnEnum2["END_DATE"] = "END_DATE";
  TaskListColumnEnum2["PROGRESS"] = "PROGRESS";
  TaskListColumnEnum2["ASSIGNEES"] = "ASSIGNEES";
  return TaskListColumnEnum2;
})(TaskListColumnEnum || {});
function getTaskFromGQLTask(gQLTasks, parentId) {
  const tasks = [];
  gQLTasks.forEach((gQLTask) => {
    let type = "task";
    const isProject = gQLTask.subTasks && gQLTask.subTasks.length > 0;
    if (isProject) {
      type = "project";
    } else if (gQLTask.detail.startTime === gQLTask.detail.endTime) {
      type = "milestone";
    }
    const dependencies = gQLTask.taskDependencyIds.map((dependencyTaskId) => {
      return {
        sourceId: dependencyTaskId,
        sourceTarget: "endOfTask",
        ownTarget: "startOfTask"
      };
    });
    let task;
    if (gQLTask.detail.startTime && gQLTask.detail.endTime) {
      task = {
        id: gQLTask.id,
        name: gQLTask.detail.name,
        start: new Date(gQLTask.detail.startTime),
        end: new Date(gQLTask.detail.endTime),
        progress: gQLTask.detail.progress,
        type,
        dependencies,
        parent: parentId,
        hideChildren: gQLTask.detail.collapsed,
        targetObjectId: gQLTask.targetObjectId,
        targetObjectKind: gQLTask.targetObjectKind,
        targetObjectLabel: gQLTask.targetObjectLabel
      };
    } else {
      task = {
        id: gQLTask.id,
        name: gQLTask.detail.name,
        parent: parentId,
        type: "empty",
        targetObjectId: gQLTask.targetObjectId,
        targetObjectKind: gQLTask.targetObjectKind,
        targetObjectLabel: gQLTask.targetObjectLabel
      };
    }
    tasks.push(task);
    if (isProject) {
      if (gQLTask.detail.computeStartEndDynamically) {
        task.isDisabled = true;
      }
      const children = getTaskFromGQLTask(gQLTask.subTasks, gQLTask.id);
      tasks.push(...children);
    }
  });
  return tasks;
}
const updateTask = (gantt, taskId, newDetail) => {
  if (gantt == null ? void 0 : gantt.tasks) {
    const task = findTask(gantt.tasks, taskId);
    if (!!task) {
      task.detail = newDetail;
    }
  }
};
const findTask = (tasks, taskId) => {
  let foundTask = void 0;
  if (tasks) {
    tasks.every((value) => {
      if (value.id === taskId) {
        foundTask = value;
      } else {
        foundTask = findTask(value.subTasks, taskId);
      }
      return !foundTask;
    });
  }
  return foundTask;
};
const ProgressColumn = ({ data: { task } }) => {
  if (task.type === "project" || task.type === "task") {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      task.progress,
      "%"
    ] });
  }
  return null;
};
const StartDateColumn = ({ data: { task } }) => {
  if (task.type !== "empty") {
    return /* @__PURE__ */ jsx("div", { children: getFormattedDate(task.start) });
  }
  return null;
};
const EndDateColumn = ({ data: { task } }) => {
  if (task.type !== "empty") {
    return /* @__PURE__ */ jsx("div", { children: getFormattedDate(task.end) });
  }
  return null;
};
const getFormattedDate = (date) => {
  const locale = navigator.languages[0];
  return date.toLocaleDateString(locale, { weekday: "short", month: "short", day: "numeric", year: "numeric" });
};
const getDisplayedColumns = (gqlColumns) => {
  const columnEnums = [
    TaskListColumnEnum.NAME,
    TaskListColumnEnum.START_DATE,
    TaskListColumnEnum.END_DATE,
    TaskListColumnEnum.PROGRESS
  ];
  const columns = [];
  columnEnums.forEach((columnType) => {
    const gqlColumn = gqlColumns.find((col) => col.id == columnType);
    if (gqlColumn && gqlColumn.displayed) {
      if (columnType === TaskListColumnEnum.NAME) {
        columns.push({
          Cell: TitleColumn,
          width: gqlColumn.width,
          title: "Name",
          id: TaskListColumnEnum.NAME
        });
      } else if (columnType === TaskListColumnEnum.START_DATE) {
        columns.push({
          Cell: StartDateColumn,
          width: gqlColumn.width,
          title: "Start Date",
          id: TaskListColumnEnum.START_DATE
        });
      } else if (columnType === TaskListColumnEnum.END_DATE) {
        columns.push({
          Cell: EndDateColumn,
          width: gqlColumn.width,
          title: "End Date",
          id: TaskListColumnEnum.END_DATE
        });
      } else if (columnType === TaskListColumnEnum.PROGRESS) {
        columns.push({
          Cell: ProgressColumn,
          width: gqlColumn.width,
          title: "Progress",
          id: TaskListColumnEnum.PROGRESS
        });
      }
    }
  });
  return columns;
};
const getSelectedColumns = (gqlColumns) => {
  return gqlColumns.filter((col) => col.displayed === true).map((col) => TaskListColumnEnum[col.id]);
};
const getDayOfTheYear = (date) => {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date.getTime() - start.getTime() + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1e3;
  const oneDay = 1e3 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);
  return day;
};
const roundDate = (date, dateExtremity, action, dateMoveStep) => {
  let value = dateMoveStep.value;
  const dimension = dateMoveStep.timeUnit;
  const newdate = new Date(date);
  if (dimension == GanttDateRoundingTimeUnit.DAY) {
    let dayOfTheYear = getDayOfTheYear(newdate);
    if (newdate.getMinutes() != 0 || newdate.getHours() != 0 || (dayOfTheYear - 1) % value > 0) {
      newdate.setMinutes(0);
      newdate.setHours(0);
      if (dateExtremity == "startOfTask" || action == "move") {
        dayOfTheYear = Math.floor((dayOfTheYear - 1) / value) * value + 1;
      } else if (dateExtremity == "endOfTask") {
        dayOfTheYear = Math.ceil(dayOfTheYear / value) * value + 1;
      }
      newdate.setMonth(0);
      newdate.setDate(1);
      newdate.setDate(dayOfTheYear);
    }
  } else if (dimension == GanttDateRoundingTimeUnit.HOUR) {
    if (newdate.getMinutes() != 0 || newdate.getHours() % value > 0) {
      newdate.setMinutes(0);
      let hours = newdate.getHours();
      if (dateExtremity == "startOfTask" || action == "move") {
        hours = Math.floor(newdate.getHours() / value) * value;
      } else if (dateExtremity == "endOfTask") {
        hours = Math.ceil(newdate.getHours() / value) * value;
      }
      newdate.setHours(hours);
    }
  } else if (dimension == GanttDateRoundingTimeUnit.MINUTE) {
    if (newdate.getMinutes() % value > 0) {
      let minutes = newdate.getMinutes();
      if (dateExtremity == "startOfTask" || action == "move") {
        minutes = Math.floor(newdate.getMinutes() / value) * value;
      } else if (dateExtremity == "endOfTask") {
        minutes = Math.ceil(newdate.getMinutes() / value) * value;
      }
      newdate.setMinutes(minutes);
    }
  }
  newdate.setSeconds(0);
  newdate.setMilliseconds(0);
  return newdate;
};
const checkIsHoliday = (date, _, __, dateExtremity) => {
  let isHoliday = false;
  const day = date.getDay();
  const isMondayStart = date.getDay() == 1 && date.getHours() == 0 && date.getMinutes() == 0;
  const isStaturdayStart = date.getDay() == 6 && date.getHours() == 0 && date.getMinutes() == 0;
  if (dateExtremity == "startOfTask") {
    isHoliday = day == 6 || day == 0 && !isMondayStart;
  } else if (dateExtremity == "endOfTask") {
    isHoliday = day == 6 && !isStaturdayStart || day == 0 || isMondayStart;
  }
  return isHoliday;
};
const TaskIcon = (props) => {
  return /* @__PURE__ */ jsxs(SvgIcon, { xmlns: "http://www.w3.org/2000/svg", height: "24", viewBox: "0 -960 960 960", width: "24", role: "img", ...props, children: [
    /* @__PURE__ */ jsx("defs", { id: "task-icon" }),
    /* @__PURE__ */ jsx("path", { d: "m438-240 226-226-58-58-169 169-84-84-57 57 142 142ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" })
  ] });
};
const useContextualPaletteStyle = makeStyles()((theme) => ({
  taskButtonEntries: {
    display: "grid",
    gridTemplateColumns: `repeat(2, 28px)`,
    gridTemplateRows: "28px",
    gridAutoRows: "28px",
    placeItems: "center"
  },
  taskDependencyButtonEntries: {
    display: "grid",
    gridTemplateColumns: `repeat(1, 28px)`,
    gridTemplateRows: "28px",
    gridAutoRows: "28px",
    placeItems: "center"
  },
  toolIcon: {
    color: theme.palette.text.primary
  },
  close: {
    gridRowStart: "1",
    gridRowEnd: "2",
    gridColumnStart: "-2",
    gridColumnEnd: "-1"
  }
}));
const getTaskContextualPalette = ({ onCreateTask, onDeleteTask }) => {
  const TaskContextualPalette = ({ selectedTask, onClosePalette }) => {
    const { classes } = useContextualPaletteStyle();
    const handleCreateTask = (task) => {
      onClosePalette();
      onCreateTask(task);
    };
    const handleDeleteTask = (task) => {
      onClosePalette();
      onDeleteTask(task);
    };
    return /* @__PURE__ */ jsxs("div", { className: classes.taskButtonEntries, children: [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          className: classes.toolIcon,
          size: "small",
          "aria-label": "create task",
          title: "Create task",
          onClick: () => handleCreateTask(selectedTask),
          "data-testid": "create-task",
          children: /* @__PURE__ */ jsx(TaskIcon, { fontSize: "small" })
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          className: classes.toolIcon,
          size: "small",
          "aria-label": "Delete task",
          title: "Delete task",
          onClick: () => handleDeleteTask(selectedTask),
          "data-testid": "delete-task",
          children: /* @__PURE__ */ jsx(Delete, { fontSize: "small" })
        }
      )
    ] });
  };
  return TaskContextualPalette;
};
const getTaskDependencyContextualPalette = ({
  onDeleteTaskDependency
}) => {
  const handleTaskDependencyDelete = (taskFrom, taskTo, onClosePalette) => {
    onClosePalette();
    onDeleteTaskDependency(taskFrom.id, taskTo.id);
  };
  const { classes } = useContextualPaletteStyle();
  const DependencyContextualPalette = ({
    taskFrom,
    taskTo,
    onClosePalette
  }) => {
    return /* @__PURE__ */ jsx("div", { className: classes.taskDependencyButtonEntries, children: /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        "aria-label": "Delete task dependency",
        title: "Delete task dependency",
        onClick: () => handleTaskDependencyDelete(taskFrom, taskTo, onClosePalette),
        "data-testid": "delete-task-dependency",
        children: /* @__PURE__ */ jsx(Delete, { fontSize: "small" })
      }
    ) });
  };
  return DependencyContextualPalette;
};
const useFullscreen = (domNode) => {
  const [fullscreen, setFullscreenState] = useState(false);
  useEffect(() => {
    const onFullscreenChange = () => setFullscreenState(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);
  const setFullscreen = useCallback(
    (fullscreen2) => {
      if (domNode.current) {
        if (fullscreen2) {
          domNode.current.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    [domNode.current]
  );
  return {
    fullscreen,
    setFullscreen
  };
};
const useToolbarStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    height: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider
  },
  selectFormControl: {
    minWidth: 115
  },
  selectColumns: {
    minWidth: 15,
    maxWidth: 15
  }
}));
const Toolbar = ({
  editingContextId,
  representationId,
  zoomLevel,
  columns,
  tasks,
  onChangeZoomLevel,
  onChangeDisplayColumns,
  onChangeColumns,
  fullscreenNode
}) => {
  const [state, setState] = useState({ modal: null });
  const { fullscreen, setFullscreen } = useFullscreen(fullscreenNode);
  const { classes } = useToolbarStyles();
  const onShare = () => setState((prevState) => ({ ...prevState, modal: "share" }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modal: null }));
  const isTask = (task) => {
    return task.type !== "empty";
  };
  const onFitToScreen = () => {
    const minTime = Math.min.apply(
      null,
      tasks.filter((t) => isTask(t)).map((t) => t).filter((task) => Boolean(task.start)).map((task) => task.start ? task.start.getTime() : 0)
    );
    const maxTime = Math.max.apply(
      null,
      tasks.filter((t) => isTask(t)).map((t) => t).filter((task) => Boolean(task.end)).map((task) => task.end ? task.end.getTime() : 0)
    );
    const fullTime = (maxTime - minTime) / 1e3 / 3600;
    let zoomLevel2 = ViewMode.Day;
    if (!isFinite(fullTime)) {
      zoomLevel2 = ViewMode.Day;
    } else if (fullTime < 10) {
      zoomLevel2 = ViewMode.Hour;
    } else if (fullTime < 48) {
      zoomLevel2 = ViewMode.QuarterDay;
    } else if (fullTime < 24 * 4) {
      zoomLevel2 = ViewMode.HalfDay;
    } else if (fullTime < 24 * 10) {
      zoomLevel2 = ViewMode.Day;
    } else if (fullTime < 24 * 25) {
      zoomLevel2 = ViewMode.TwoDays;
    } else if (fullTime < 24 * 60) {
      zoomLevel2 = ViewMode.Week;
    } else if (fullTime < 24 * 30 * 7) {
      zoomLevel2 = ViewMode.Month;
    } else {
      zoomLevel2 = ViewMode.Year;
    }
    onChangeZoomLevel(zoomLevel2);
  };
  useEffect(() => {
    onFitToScreen();
  }, []);
  const handleDisplayColumns = () => {
    onChangeDisplayColumns();
  };
  const updateZoomLevel = (event) => {
    const newZoomLevel = event.target.value;
    onChangeZoomLevel(newZoomLevel);
  };
  let modalElement = null;
  if (state.modal === "share") {
    modalElement = /* @__PURE__ */ jsx(
      ShareRepresentationModal,
      {
        editingContextId,
        representationId,
        onClose: closeModal
      }
    );
  }
  const onZoomIn = () => {
    if (zoomLevel !== ViewMode.Hour) {
      const currentIndex = Object.values(ViewMode).indexOf(zoomLevel);
      const newZoomLevel = Object.values(ViewMode).at(currentIndex - 1);
      onChangeZoomLevel(newZoomLevel ? newZoomLevel : ViewMode.Hour);
    }
  };
  const onZoomOut = () => {
    if (zoomLevel !== ViewMode.Year) {
      const currentIndex = Object.values(ViewMode).indexOf(zoomLevel);
      const newZoomLevel = Object.values(ViewMode).at(currentIndex + 1);
      onChangeZoomLevel(newZoomLevel ? newZoomLevel : ViewMode.Year);
    }
  };
  const handleChangeColumns = (event) => {
    const columnTypes = event.target.value;
    onChangeColumns(columnTypes);
  };
  const allColumns = [
    { type: TaskListColumnEnum.NAME, name: "Name" },
    { type: TaskListColumnEnum.START_DATE, name: "Start Date" },
    { type: TaskListColumnEnum.END_DATE, name: "End Date" },
    { type: TaskListColumnEnum.PROGRESS, name: "Progress" }
  ];
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.toolbar, children: [
      fullscreen ? /* @__PURE__ */ jsx(Tooltip, { title: "Exit full screen mode", children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "exit full screen mode",
          onClick: () => setFullscreen(false),
          children: /* @__PURE__ */ jsx(FullscreenExitIcon, {})
        }
      ) }) : /* @__PURE__ */ jsx(Tooltip, { title: "Toggle full screen mode", children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "toggle full screen mode",
          onClick: () => setFullscreen(true),
          children: /* @__PURE__ */ jsx(FullscreenIcon, {})
        }
      ) }),
      /* @__PURE__ */ jsx(FormControl, { className: classes.selectFormControl, children: /* @__PURE__ */ jsxs(
        Select,
        {
          value: zoomLevel,
          onChange: updateZoomLevel,
          variant: "standard",
          disableUnderline: true,
          title: "Zoom level",
          "data-testid": "zoom-level",
          children: [
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.Hour, "data-testid": "zoom-level-Hour", children: "Hour" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.QuarterDay, children: "Quarter Day" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.HalfDay, children: "Half Day" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.Day, children: "Day" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.TwoDays, children: "Two Days" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.Week, children: "Week" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.Month, children: "Month" }),
            /* @__PURE__ */ jsx(MenuItem, { value: ViewMode.Year, children: "Year" })
          ]
        }
      ) }),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "zoom in",
          title: "Zoom in",
          onClick: onZoomIn,
          "data-testid": "zoom-in",
          children: /* @__PURE__ */ jsx(ZoomInIcon, { fontSize: "small" })
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "zoom out",
          title: "Zoom out",
          onClick: onZoomOut,
          "data-testid": "zoom-out",
          children: /* @__PURE__ */ jsx(ZoomOutIcon, { fontSize: "small" })
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "fit to screen",
          title: "Fit to screen",
          onClick: onFitToScreen,
          "data-testid": "fit-to-screen",
          children: /* @__PURE__ */ jsx(AspectRatioIcon, { fontSize: "small" })
        }
      ),
      /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          color: "inherit",
          "aria-label": "display task list columns",
          title: "Display columns",
          onClick: handleDisplayColumns,
          "data-testid": "display-task-list-columns",
          children: /* @__PURE__ */ jsx(ViewColumn, { fontSize: "small" })
        }
      ),
      /* @__PURE__ */ jsx(FormControl, { className: classes.selectColumns, children: /* @__PURE__ */ jsx(
        Select,
        {
          variant: "standard",
          labelId: "columns-checkbox-label",
          id: "columns-checkbox",
          multiple: true,
          disableUnderline: true,
          value: columns,
          onChange: handleChangeColumns,
          renderValue: () => "",
          "data-testid": "columns-select",
          children: allColumns.map((column) => /* @__PURE__ */ jsxs(MenuItem, { value: column.type, disabled: column.type == TaskListColumnEnum.NAME, children: [
            /* @__PURE__ */ jsx(Checkbox, { checked: columns.indexOf(column.type) > -1, "data-testid": `columnType-${column.type}` }),
            /* @__PURE__ */ jsx(ListItemText, { primary: column.name })
          ] }, column.type))
        }
      ) }),
      /* @__PURE__ */ jsx(IconButton, { size: "small", color: "inherit", "aria-label": "share", title: "Share", onClick: onShare, "data-testid": "share", children: /* @__PURE__ */ jsx(ShareIcon, { fontSize: "small" }) })
    ] }),
    modalElement
  ] });
};
const useGanttStyle = makeStyles()((theme) => ({
  ganttContainer: {
    backgroundColor: theme.palette.background.default,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column"
  },
  collapseToggleArrow: {
    cursor: "pointer"
  },
  noChildren: {
    paddingLeft: "20px"
  }
}));
const Gantt = ({
  editingContextId,
  representationId,
  tasks,
  gqlColumns,
  gqlDateRounding,
  setSelection,
  onCreateTask,
  onEditTask,
  onDeleteTask,
  onDropTask,
  onCreateTaskDependency,
  onDeleteTaskDependency,
  onChangeTaskCollapseState,
  onChangeColumn
}) => {
  const [{ zoomLevel, selectedColumns, displayedColumns, displayColumns }, setState] = useState({
    zoomLevel: ViewMode.Day,
    selectedColumns: getSelectedColumns(gqlColumns),
    displayedColumns: getDisplayedColumns(gqlColumns),
    displayColumns: true
  });
  const ganttContainerRef = useRef(null);
  const { classes: ganttClasses } = useGanttStyle();
  const theme = useTheme();
  const onwheel = (wheelEvent) => {
    if (wheelEvent.ctrlKey) {
      wheelEvent.preventDefault();
      const deltaY = wheelEvent.deltaY;
      if (deltaY < 0 && zoomLevel !== ViewMode.Hour) {
        const currentIndex = Object.values(ViewMode).indexOf(zoomLevel);
        const newZoomLevel = Object.values(ViewMode).at(currentIndex - 1);
        if (newZoomLevel) {
          setState((prevState) => {
            return { ...prevState, zoomLevel: newZoomLevel };
          });
        }
      } else if (deltaY > 0 && zoomLevel !== ViewMode.Year) {
        const currentIndex = Object.values(ViewMode).indexOf(zoomLevel);
        const newZoomLevel = Object.values(ViewMode).at(currentIndex + 1);
        if (newZoomLevel) {
          setState((prevState) => {
            return { ...prevState, zoomLevel: newZoomLevel };
          });
        }
      }
    }
  };
  const handleSelection = (task) => {
    const selectableTask = task;
    const newSelection = {
      entries: [
        {
          id: selectableTask.targetObjectId,
          kind: selectableTask.targetObjectKind
        }
      ]
    };
    setSelection(newSelection);
  };
  const onChangeZoomLevel = (zoomLevel2) => {
    setState((prevState) => {
      return { ...prevState, zoomLevel: zoomLevel2 };
    });
  };
  const handleMoveTaskAfter = (task, taskForMove) => {
    const index = tasks.filter((t) => t.parent === task.parent).findIndex((t) => t.id === task.id);
    const parent = tasks.find((t) => t.id === task.parent);
    onDropTask(taskForMove, parent, index + 1);
  };
  const handleMoveTaskBefore = (task, taskForMove) => {
    const index = tasks.filter((t) => t.parent === task.parent).findIndex((t) => t.id === task.id);
    const parent = tasks.find((t) => t.id === task.parent);
    onDropTask(taskForMove, parent, index);
  };
  const handleMoveTaskInside = (parent, childs) => {
    if (childs[0]) {
      onDropTask(childs[0], parent, -1);
    }
  };
  const handleRelationChange = (from, to) => {
    if (from[0].id !== to[0].id) {
      onCreateTaskDependency(from[0].id, to[0].id);
    }
  };
  useEffect(() => {
    if (gqlColumns) {
      setState((prevState) => {
        return {
          ...prevState,
          selectedColumns: getSelectedColumns(gqlColumns),
          displayedColumns: getDisplayedColumns(gqlColumns)
        };
      });
    }
  }, [gqlColumns]);
  const onChangeDisplayColumns = () => {
    setState((prevState) => {
      return { ...prevState, displayColumns: !displayColumns };
    });
  };
  const onChangeColumns = (columnTypes) => {
    setState((prevState) => {
      const allColumns = gqlColumns.map((gqlColumn) => {
        return { ...gqlColumn, displayed: columnTypes.find((colType) => colType === gqlColumn.id) !== void 0 };
      });
      return {
        ...prevState,
        selectedColumns: getSelectedColumns(allColumns),
        displayedColumns: getDisplayedColumns(allColumns)
      };
    });
    const changedColumn = gqlColumns.find((gqlColumn) => {
      const colThatIsDisplayed = columnTypes.find((colType) => colType === gqlColumn.id);
      return colThatIsDisplayed === void 0 && gqlColumn.displayed || colThatIsDisplayed !== void 0 && !gqlColumn.displayed;
    });
    if (changedColumn) {
      onChangeColumn(changedColumn.id, !changedColumn.displayed, changedColumn.width);
    }
  };
  const handleResizeColumn = (nextColumns, columnIndex) => {
    const changedColumn = nextColumns.at(columnIndex);
    if (changedColumn) {
      onChangeColumn(changedColumn.id, true, changedColumn.width);
      setState((prevState) => {
        return { ...prevState, displayedColumns: Array.from(nextColumns) };
      });
    }
  };
  let tableColumns = [];
  if (displayColumns) {
    tableColumns = displayedColumns;
  }
  const colors = {
    taskDragColor: theme.palette.action.selected,
    selectedTaskBackgroundColor: theme.palette.action.selected
  };
  const authorizedRelations = ["endToStart"];
  const distances = {
    expandIconWidth: 25
  };
  const icons = {
    renderClosedIcon: (task) => /* @__PURE__ */ jsx(ChevronRightIcon, { className: ganttClasses.collapseToggleArrow, "data-testid": `collapsed-task-${task.name}` }),
    renderNoChildrenIcon: () => /* @__PURE__ */ jsx("div", { className: ganttClasses.noChildren }),
    renderOpenedIcon: (task) => /* @__PURE__ */ jsx(ExpandMoreIcon, { className: ganttClasses.collapseToggleArrow, "data-testid": `expanded-task-${task.name}` })
  };
  const getTimeUnit = (timeUnit) => {
    if (timeUnit.toString() == GQLGanttDateRoundingTimeUnit[GQLGanttDateRoundingTimeUnit.DAY]) {
      return GanttDateRoundingTimeUnit.DAY;
    } else if (timeUnit.toString() == GQLGanttDateRoundingTimeUnit[GQLGanttDateRoundingTimeUnit.HOUR]) {
      return GanttDateRoundingTimeUnit.HOUR;
    } else if (timeUnit.toString() == GQLGanttDateRoundingTimeUnit[GQLGanttDateRoundingTimeUnit.MINUTE]) {
      return GanttDateRoundingTimeUnit.MINUTE;
    }
    return GanttDateRoundingTimeUnit.DAY;
  };
  const dateRounding = {
    value: gqlDateRounding.value,
    timeUnit: getTimeUnit(gqlDateRounding.timeUnit)
  };
  return /* @__PURE__ */ jsxs("div", { ref: ganttContainerRef, className: ganttClasses.ganttContainer, "data-testid": `gantt-representation`, children: [
    /* @__PURE__ */ jsx(
      Toolbar,
      {
        editingContextId,
        representationId,
        zoomLevel,
        columns: selectedColumns,
        tasks,
        onChangeZoomLevel,
        onChangeDisplayColumns,
        onChangeColumns,
        fullscreenNode: ganttContainerRef
      }
    ),
    /* @__PURE__ */ jsx(
      Gantt$1,
      {
        tasks,
        distances,
        columns: tableColumns,
        colors,
        viewMode: zoomLevel,
        onDateChange: onEditTask,
        onProgressChange: onEditTask,
        onDelete: onDeleteTask,
        onDoubleClick: (task) => onChangeTaskCollapseState(task.id, !task.hideChildren),
        onClick: handleSelection,
        roundDate: (date, _, dateExtremity, action) => roundDate(date, dateExtremity, action, dateRounding),
        checkIsHoliday,
        dateMoveStep: dateRounding,
        onWheel: onwheel,
        ContextualPalette: getTaskContextualPalette({
          onCreateTask,
          onDeleteTask: (task) => onDeleteTask([task]),
          onEditTask
        }),
        TaskDependencyContextualPalette: getTaskDependencyContextualPalette({
          onDeleteTaskDependency
        }),
        isMoveChildsWithParent: false,
        onMoveTaskBefore: handleMoveTaskBefore,
        onMoveTaskAfter: handleMoveTaskAfter,
        onMoveTaskInside: handleMoveTaskInside,
        onRelationChange: handleRelationChange,
        authorizedRelations,
        onChangeExpandState: (changedTask) => onChangeTaskCollapseState(changedTask.id, !!changedTask.hideChildren),
        icons,
        onResizeColumn: handleResizeColumn,
        isAdjustToWorkingDates: true
      }
    )
  ] });
};
const deleteTaskMutation = gql`
  mutation deleteGanttTask($input: DeleteGanttTaskInput!) {
    deleteGanttTask(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const editTaskMutation = gql`
  mutation editGanttTask($input: EditGanttTaskInput!) {
    editGanttTask(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const createTaskMutation = gql`
  mutation createGanttTask($input: CreateGanttTaskInput!) {
    createGanttTask(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const dropTaskMutation = gql`
  mutation dropGanttTask($input: DropGanttTaskInput!) {
    dropGanttTask(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const createTaskDependencyMutation = gql`
  mutation createGanttTaskDependency($input: CreateGanttTaskDependencyInput!) {
    createGanttTaskDependency(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const deleteTaskDependencyMutation = gql`
  mutation deleteTaskDependency($input: DeleteGanttTaskDependencyInput!) {
    deleteGanttTaskDependency(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const changeTaskCollapseStateMutation = gql`
  mutation changeGanttTaskCollapseState($input: ChangeGanttTaskCollapseStateInput!) {
    changeGanttTaskCollapseState(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const changeColumnMutation = gql`
  mutation changeGanttColumn($input: ChangeGanttColumnInput!) {
    changeGanttColumn(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const useGanttMutations = (editingContextId, representationId) => {
  const [mutationDeleteGanttTask, mutationDeleteTaskResult] = useMutation(
    deleteTaskMutation
  );
  useReporting(mutationDeleteTaskResult, (data) => data.deleteGanttTask);
  const deleteTask = (tasks) => {
    var _a;
    const taskId = (_a = tasks == null ? void 0 : tasks.at(0)) == null ? void 0 : _a.id;
    if (taskId) {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        taskId
      };
      mutationDeleteGanttTask({ variables: { input } });
    }
  };
  const [mutationCreateTask, mutationCreateTaskResult] = useMutation(
    createTaskMutation
  );
  useReporting(mutationCreateTaskResult, (data) => data.createGanttTask);
  const createTask = (task) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      currentTaskId: task.id
    };
    mutationCreateTask({ variables: { input } });
  };
  const [mutationEditTask, mutationEditTaskResult] = useMutation(
    editTaskMutation
  );
  useReporting(mutationEditTaskResult, (data) => data.editGanttTask);
  const editTask = (task) => {
    var _a, _b;
    const newDetail = {
      name: task.name,
      description: "",
      startTime: (_a = task == null ? void 0 : task.start) == null ? void 0 : _a.toISOString(),
      endTime: (_b = task == null ? void 0 : task.end) == null ? void 0 : _b.toISOString(),
      progress: task == null ? void 0 : task.progress,
      computeStartEndDynamically: task.isDisabled
    };
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      taskId: task.id,
      newDetail
    };
    mutationEditTask({ variables: { input } });
  };
  const [mutationDropTask, mutationDropTaskResult] = useMutation(
    dropTaskMutation
  );
  useReporting(mutationDropTaskResult, (data) => data.dropGanttTask);
  const dropTask = (droppedTask, targetTask, dropIndex) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      droppedTaskId: droppedTask.id,
      dropIndex
    };
    if (targetTask) {
      input.targetTaskId = targetTask.id;
    }
    mutationDropTask({ variables: { input } });
  };
  const [mutationCreateTaskDependency, mutationCreateTaskDependencyResult] = useMutation(createTaskDependencyMutation);
  useReporting(
    mutationCreateTaskDependencyResult,
    (data) => data.createGanttTaskDependency
  );
  const createTaskDependency = (sourceTaskId, targetTaskId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      sourceTaskId,
      targetTaskId
    };
    mutationCreateTaskDependency({ variables: { input } });
  };
  const [mutationDeleteTaskDependency, mutationDeleteTaskDependencyResult] = useMutation(deleteTaskDependencyMutation);
  useReporting(
    mutationDeleteTaskDependencyResult,
    (data) => data.deleteGanttTaskDependency
  );
  const deleteTaskDependency = (sourceTaskId, targetTaskId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      sourceTaskId,
      targetTaskId
    };
    mutationDeleteTaskDependency({ variables: { input } });
  };
  const [mutationChangeTaskCollapseState, mutationChangeTaskCollapseStateResult] = useMutation(changeTaskCollapseStateMutation);
  useReporting(
    mutationChangeTaskCollapseStateResult,
    (data) => data.changeGanttTaskCollapseState
  );
  const changeTaskCollapseState = (taskId, collapsed) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      taskId,
      collapsed
    };
    mutationChangeTaskCollapseState({ variables: { input } });
  };
  const [mutationChangeColumn, mutationChangeColumnResult] = useMutation(
    changeColumnMutation
  );
  useReporting(mutationChangeColumnResult, (data) => data.changeGanttColumn);
  const changeColumn = (columnId, displayed, width) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      columnId,
      displayed,
      width
    };
    mutationChangeColumn({ variables: { input } });
  };
  return {
    deleteTask,
    createTask,
    editTask,
    dropTask,
    createTaskDependency,
    deleteTaskDependency,
    changeTaskCollapseState,
    changeColumn
  };
};
const ganttEventSubscription = gql`
  subscription ganttEvent($input: GanttEventInput!) {
    ganttEvent(input: $input) {
      __typename
      ... on ErrorPayload {
        id
        messages {
          body
          level
        }
      }
      ... on GanttRefreshedEventPayload {
        id
        gantt {
          id
          metadata {
            kind
            label
          }
          targetObjectId
          dateRounding {
            value
            timeUnit
          }
          tasks {
            ...taskFields
            subTasks {
              ...taskFields
              subTasks {
                ...taskFields
                subTasks {
                  ...taskFields
                }
              }
            }
          }
          columns {
            id
            displayed
            width
          }
        }
      }
    }
  }

  fragment taskFields on Task {
    id
    targetObjectId
    targetObjectKind
    targetObjectLabel
    detail {
      name
      description
      startTime
      endTime
      progress
      computeStartEndDynamically
      collapsed
    }
    style {
      labelColor
      backgroundColor
      progressColor
    }
    taskDependencyIds
  }
`;
const useGanttRepresentationStyles = makeStyles()((theme) => ({
  page: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    overflowY: "scroll"
  },
  complete: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    maxHeight: theme.spacing(4),
    textTransform: "none"
  },
  toolbarAction: {
    paddingRight: theme.spacing(1),
    whiteSpace: "nowrap"
  }
}));
const isGanttRefreshedEventPayload = (payload) => payload.__typename === "GanttRefreshedEventPayload";
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const GanttRepresentation = ({ editingContextId, representationId }) => {
  const { classes } = useGanttRepresentationStyles();
  const { addErrorMessage, addMessages } = useMultiToast();
  const { setSelection } = useSelection();
  const [{ id, gantt, complete }, setState] = useState({
    id: crypto.randomUUID(),
    gantt: null,
    complete: false
  });
  const { error } = useSubscription(ganttEventSubscription, {
    variables: {
      input: {
        id,
        editingContextId,
        ganttId: representationId
      }
    },
    fetchPolicy: "no-cache",
    onData: ({ data: subscriptionData }) => {
      if (subscriptionData == null ? void 0 : subscriptionData.data) {
        const { ganttEvent } = subscriptionData.data;
        if (isGanttRefreshedEventPayload(ganttEvent)) {
          setState((previousState) => {
            return { ...previousState, gantt: ganttEvent.gantt };
          });
        } else if (isErrorPayload(ganttEvent)) {
          addMessages(ganttEvent.messages);
        }
      }
    },
    onComplete: () => {
      setState((previousState) => {
        return { ...previousState, complete: true, gantt: null };
      });
    }
  });
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  const {
    deleteTask,
    editTask,
    createTask,
    dropTask,
    createTaskDependency,
    deleteTaskDependency,
    changeTaskCollapseState,
    changeColumn
  } = useGanttMutations(editingContextId, representationId);
  const handleEditTask = (task) => {
    var _a, _b;
    const newDetail = {
      name: task.name,
      description: "",
      startTime: (_a = task == null ? void 0 : task.start) == null ? void 0 : _a.toISOString(),
      endTime: (_b = task == null ? void 0 : task.end) == null ? void 0 : _b.toISOString(),
      progress: task == null ? void 0 : task.progress,
      computeStartEndDynamically: task.isDisabled,
      collapsed: task == null ? void 0 : task.hideChildren
    };
    updateTask(gantt, task.id, newDetail);
    editTask(task);
  };
  const onExpandCollapse = () => {
  };
  let content = null;
  if (complete) {
    content = /* @__PURE__ */ jsx("div", { className: classes.complete, children: /* @__PURE__ */ jsx(Typography, { variant: "h5", align: "center", children: "The Gantt does not exist anymore" }) });
  } else if (gantt) {
    const tasks = getTaskFromGQLTask(gantt.tasks, "");
    content = /* @__PURE__ */ jsx(
      Gantt,
      {
        editingContextId,
        representationId,
        tasks,
        gqlColumns: gantt.columns,
        gqlDateRounding: gantt.dateRounding,
        setSelection,
        onCreateTask: createTask,
        onEditTask: handleEditTask,
        onDeleteTask: deleteTask,
        onExpandCollapse,
        onDropTask: dropTask,
        onCreateTaskDependency: createTaskDependency,
        onChangeTaskCollapseState: changeTaskCollapseState,
        onChangeColumn: changeColumn,
        onDeleteTaskDependency: deleteTaskDependency
      }
    );
  }
  return /* @__PURE__ */ jsx(Fragment, { children: content });
};
export {
  Gantt,
  GanttRepresentation
};
