import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import { gql, useSubscription } from "@apollo/client";
import { Toast } from "@eclipse-sirius/sirius-components-core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { useMachine } from "@xstate/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Machine, assign } from "xstate";
const isValidationRefreshedEventPayload = (payload) => payload.__typename === "ValidationRefreshedEventPayload";
const validationViewMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      validation: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      validationView: {
        initial: "idle",
        states: {
          idle: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  cond: "isValidationRefreshedEventPayload",
                  target: "ready",
                  actions: "handleSubscriptionResult"
                },
                {
                  target: "idle",
                  actions: "handleSubscriptionResult"
                }
              ]
            }
          },
          ready: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: {
                target: "ready",
                actions: "handleSubscriptionResult"
              },
              HANDLE_COMPLETE: {
                target: "complete"
              }
            }
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isValidationRefreshedEventPayload: (_, event) => {
        const { result } = event;
        const { data } = result;
        return !!data && isValidationRefreshedEventPayload(data.validationEvent);
      }
    },
    actions: {
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (data && isValidationRefreshedEventPayload(data.validationEvent)) {
          const { validation } = data.validationEvent;
          const categories = [];
          const processedValidation = { categories };
          validation.diagnostics.forEach((diagnostic) => {
            let category = categories.find((category2) => category2.kind === diagnostic.kind);
            if (!category) {
              category = {
                kind: diagnostic.kind,
                diagnostics: []
              };
              categories.push(category);
            }
            category.diagnostics.push({ id: diagnostic.id, message: diagnostic.message });
          });
          return { validation: processedValidation };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const validationEventSubscription = gql`
  subscription validationEvent($input: ValidationEventInput!) {
    validationEvent(input: $input) {
      __typename
      ... on ValidationRefreshedEventPayload {
        id
        validation {
          id
          diagnostics {
            id
            kind
            message
          }
        }
      }
    }
  }
`;
const useValidationViewStyle = makeStyles()((theme) => ({
  root: {
    padding: "8px"
  },
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0
  },
  secondaryHeading: {
    color: theme.palette.text.secondary
  },
  accordionDetailsRoot: {
    flexDirection: "column"
  },
  divider: {
    margin: "8px 0"
  },
  idle: {
    padding: theme.spacing(1)
  }
}));
const ValidationView = ({ editingContextId }) => {
  const { classes } = useValidationViewStyle();
  const [{ value, context }, dispatch] = useMachine(validationViewMachine);
  const { toast, validationView } = value;
  const { id, validation, message } = context;
  const { t } = useTranslation("siriusComponentsValidation");
  const { error } = useSubscription(
    validationEventSubscription,
    {
      variables: {
        input: {
          id,
          editingContextId
        }
      },
      fetchPolicy: "no-cache",
      onData: ({ data }) => {
        const handleDataEvent = {
          type: "HANDLE_SUBSCRIPTION_RESULT",
          result: data
        };
        dispatch(handleDataEvent);
      },
      onComplete: () => {
        const completeEvent = { type: "HANDLE_COMPLETE" };
        dispatch(completeEvent);
      }
    }
  );
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  let content = null;
  if (validationView === "ready" && validation) {
    const accordions = validation.categories.map((category) => {
      const details = category.diagnostics.map((diagnostic) => {
        return /* @__PURE__ */ jsx(Typography, { children: diagnostic.message }, diagnostic.id);
      }).reduce((prev, current, index) => [
        prev,
        /* @__PURE__ */ jsx(Divider, { className: classes.divider }, `Divider-${index}`),
        current
      ]);
      return /* @__PURE__ */ jsxs(Accordion, { children: [
        /* @__PURE__ */ jsxs(AccordionSummary, { expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}), children: [
          /* @__PURE__ */ jsx(Typography, { className: classes.heading, children: category.kind }),
          /* @__PURE__ */ jsx(Typography, { className: classes.secondaryHeading, children: t("diagnosticCount", { count: category.diagnostics.length }) })
        ] }),
        /* @__PURE__ */ jsx(AccordionDetails, { className: classes.accordionDetailsRoot, children: details })
      ] }, category.kind);
    });
    if (accordions.length > 0) {
      content = /* @__PURE__ */ jsx("div", { className: classes.root, children: accordions });
    } else {
      content = /* @__PURE__ */ jsx("div", { className: classes.idle, children: /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", children: t("noDiagnostic") }) });
    }
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    content,
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: message ?? "",
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const siriusComponentsValidation$1 = {
  diagnosticCount: "{{count}} diagnostics",
  diagnosticCount_one: "{{count}} diagnostic",
  noDiagnostic: "No diagnostic available"
};
const en = {
  siriusComponentsValidation: siriusComponentsValidation$1
};
const siriusComponentsValidation = {
  diagnosticCount: "{{count}} ошибок",
  diagnosticCount_one: "{{count}} ошибка",
  diagnosticCount_few: "{{count}} ошибки",
  noDiagnostic: "Нет ошибок"
};
const ru = {
  siriusComponentsValidation
};
export {
  ValidationView,
  en as siriusComponentsValidationEn,
  ru as siriusComponentsValidationRu
};
