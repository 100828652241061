import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { useReporting, ShareRepresentationModal, useData, representationFactoryExtensionPoint, useMultiToast, useSelection, SelectionContext, DRAG_SOURCES_TYPE } from "@eclipse-sirius/sirius-components-core";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect, useCallback, useRef } from "react";
import GridLayout, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { makeStyles } from "tss-react/mui";
import { gql, useSubscription, useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PanToolIcon from "@mui/icons-material/PanTool";
import ShareIcon from "@mui/icons-material/Share";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const portalEventSubscription = gql`
  subscription portalEvent($input: PortalEventInput!) {
    portalEvent(input: $input) {
      __typename
      ... on PortalRefreshedEventPayload {
        id
        portal {
          id
          targetObjectId
          views {
            id
            representationMetadata {
              id
              kind
              label
            }
          }
          layoutData {
            portalViewId
            x
            y
            width
            height
          }
        }
      }
    }
  }
`;
const isPortalRefreshedEventPayload = (payload) => payload.__typename === "PortalRefreshedEventPayload";
const usePortal = (editingContextId, representationId) => {
  const [state, setState] = useState({
    subscriptionId: crypto.randomUUID(),
    portal: null,
    complete: false,
    message: null
  });
  const variables = {
    input: {
      id: state.subscriptionId,
      editingContextId,
      portalId: representationId
    }
  };
  const { error } = useSubscription(portalEventSubscription, {
    variables,
    fetchPolicy: "no-cache",
    onData: ({ data }) => {
      if (data.data) {
        const { portalEvent } = data.data;
        if (isPortalRefreshedEventPayload(portalEvent)) {
          setState((prevState) => ({ ...prevState, portal: portalEvent.portal }));
        }
      }
    },
    onComplete: () => {
      setState((prevState) => ({ ...prevState, portal: null, complete: true }));
    }
  });
  useEffect(() => {
    if (error) {
      setState((prevState) => ({ ...prevState, message: error.message }));
    }
  }, [error]);
  return { portal: state.portal, complete: state.complete, message: state.message };
};
const addPortalViewMutation = gql`
  mutation addPortalView($input: AddPortalViewInput!) {
    addPortalView(input: $input) {
      __typename
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const removePortalViewMutation = gql`
  mutation removePortalView($input: RemovePortalViewInput!) {
    removePortalView(input: $input) {
      __typename
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const layoutPortalMutation = gql`
  mutation layoutPortal($input: LayoutPortalInput!) {
    layoutPortal(input: $input) {
      __typename
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const usePortalMutations = (editingContextId, portalId) => {
  const [rawAddPortalView, rawAddPortalViewResult] = useMutation(addPortalViewMutation);
  const addPortalView = (viewRepresentationId, x, y, width, height) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId: portalId,
      viewRepresentationId,
      x,
      y,
      width,
      height
    };
    rawAddPortalView({ variables: { input } });
  };
  useReporting(rawAddPortalViewResult, (data) => data.addPortalView);
  const [rawRemovePortalView, rawRemovePortalViewResult] = useMutation(removePortalViewMutation);
  const removePortalView = (portalViewId) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId: portalId,
      portalViewId
    };
    rawRemovePortalView({ variables: { input } });
  };
  useReporting(rawRemovePortalViewResult, (data) => data.removePortalView);
  const [layoutsInProgress, setLayoutsInProgress] = useState(0);
  const [rawLayoutPortal, rawLayoutPortalResult] = useMutation(layoutPortalMutation);
  const layoutPortal = (layoutData) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId: portalId,
      layoutData
    };
    setLayoutsInProgress((prevState) => prevState + 1);
    rawLayoutPortal({ variables: { input } });
  };
  useEffect(() => {
    if (!rawLayoutPortalResult.loading) {
      setLayoutsInProgress((prevState) => prevState - 1);
    }
  }, [rawLayoutPortalResult.loading]);
  useReporting(rawLayoutPortalResult, (data) => data.layoutPortal);
  return {
    addPortalView,
    removePortalView,
    layoutPortal,
    layoutInProgress: layoutsInProgress > 0
  };
};
const useFullscreen = (domNode) => {
  const [fullscreen, setFullscreenState] = useState(false);
  useEffect(() => {
    const onFullscreenChange = () => setFullscreenState(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);
  const setFullscreen = useCallback(
    (fullscreen2) => {
      if (domNode.current) {
        if (fullscreen2) {
          domNode.current.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    [domNode.current]
  );
  return {
    fullscreen,
    setFullscreen
  };
};
const PortalToolbar = ({
  editingContextId,
  representationId,
  fullscreenNode,
  portalMode,
  setPortalMode
}) => {
  const { fullscreen, setFullscreen } = useFullscreen(fullscreenNode);
  const [state, setState] = useState({ modal: null });
  const onShare = () => setState((prevState) => ({ ...prevState, modal: "share" }));
  const closeModal = () => setState((prevState) => ({ ...prevState, modal: null }));
  let modalElement = null;
  if (state.modal === "share") {
    modalElement = /* @__PURE__ */ jsx(
      ShareRepresentationModal,
      {
        editingContextId,
        representationId,
        onClose: closeModal
      }
    );
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(Paper, { "data-testid": "portal-toolbar", children: [
      fullscreen ? /* @__PURE__ */ jsx(Tooltip, { title: "Exit full screen mode", children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "exit full screen mode", onClick: () => setFullscreen(false), children: /* @__PURE__ */ jsx(FullscreenExitIcon, {}) }) }) : /* @__PURE__ */ jsx(Tooltip, { title: "Toggle full screen mode", children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "toggle full screen mode", onClick: () => setFullscreen(true), children: /* @__PURE__ */ jsx(FullscreenIcon, {}) }) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Share portal", children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "share portal", onClick: onShare, "data-testid": "share", children: /* @__PURE__ */ jsx(ShareIcon, {}) }) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Edit portal configuration", children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          "aria-label": "edit portal configuration",
          disabled: portalMode === "edit" || portalMode === "read-only",
          onClick: () => setPortalMode("edit"),
          "data-testid": "portal-edit-portal-mode",
          children: /* @__PURE__ */ jsx(EditIcon, {})
        }
      ) }) }),
      /* @__PURE__ */ jsx(Tooltip, { title: "Edit representations", children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          "aria-label": "edit representations",
          disabled: portalMode === "direct" || portalMode === "read-only",
          onClick: () => setPortalMode("direct"),
          "data-testid": "portal-edit-representations-mode",
          children: /* @__PURE__ */ jsx(PanToolIcon, {})
        }
      ) }) })
    ] }),
    modalElement
  ] });
};
const useFrameStyles = makeStyles()((theme) => ({
  representationFrame: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content minmax(0, 1fr)",
    overflow: "auto",
    border: "1px solid",
    borderColor: theme.palette.grey[500]
  },
  frameHeader: {
    backgroundColor: theme.palette.grey[300],
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 1
  },
  title: {
    paddingLeft: theme.spacing(1),
    position: "sticky",
    left: 0
  },
  removeIcon: {
    position: "sticky",
    right: 0
  }
}));
const RepresentationFrame = ({
  editingContextId,
  representation,
  portalMode,
  onDelete
}) => {
  const { data: representationFactories } = useData(representationFactoryExtensionPoint);
  const RepresentationComponent = representationFactories.map((representationFactory) => representationFactory(representation)).find((component) => component != null);
  const { classes } = useFrameStyles();
  if (RepresentationComponent) {
    const props = {
      editingContextId,
      representationId: representation.id,
      readOnly: portalMode === "edit" || portalMode === "read-only"
    };
    return /* @__PURE__ */ jsxs("div", { "data-testid": `representation-frame-${representation.label}`, className: classes.representationFrame, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.frameHeader, "data-testid": "representation-frame-header", children: [
        /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: classes.title + " draggable", children: representation.label }),
        portalMode === "edit" ? /* @__PURE__ */ jsx(
          IconButton,
          {
            "aria-label": "remove",
            className: classes.removeIcon,
            onClick: (e) => {
              e.preventDefault();
              onDelete();
            },
            size: "small",
            children: /* @__PURE__ */ jsx(CloseOutlinedIcon, { fontSize: "small" })
          }
        ) : null
      ] }),
      /* @__PURE__ */ jsx(RepresentationComponent, { ...props }, `${editingContextId}#${representation.id}`)
    ] });
  } else {
    return /* @__PURE__ */ jsx("div", { children: "Unknown representation kind" });
  }
};
const usePortalRepresentationStyles = makeStyles()((theme) => ({
  portalRepresentationArea: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content 1fr",
    backgroundColor: theme.palette.background.default
  },
  dropArea: {
    backgroundColor: theme.palette.grey[200],
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[400],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));
const ResponsiveGridLayout = WidthProvider(GridLayout);
const getFirstDroppedElementId = (event) => {
  const dragSourcesStringified = event.dataTransfer.getData(DRAG_SOURCES_TYPE);
  if (dragSourcesStringified) {
    const sources = JSON.parse(dragSourcesStringified);
    if (Array.isArray(sources)) {
      const sourceIds = sources.filter((source) => source == null ? void 0 : source.id).map((source) => source.id);
      if (sourceIds.length > 0) {
        return sourceIds[0] || null;
      }
    }
  }
  return null;
};
const PortalRepresentation = ({
  editingContextId,
  representationId,
  readOnly
}) => {
  const theme = useTheme();
  const { classes } = usePortalRepresentationStyles();
  const domNode = useRef(null);
  const { addErrorMessage } = useMultiToast();
  const { selection, setSelection } = useSelection();
  const { portal, complete, message } = usePortal(editingContextId, representationId);
  const { addPortalView, removePortalView, layoutPortal, layoutInProgress } = usePortalMutations(
    editingContextId,
    representationId
  );
  const [mode, setMode] = useState(null);
  const portalHasViews = portal && portal.views.length > 0;
  useEffect(() => {
    if (readOnly) {
      setMode("read-only");
    } else if (portal !== null && (mode === null || mode === "read-only")) {
      setMode(portalHasViews ? "direct" : "edit");
    }
  }, [readOnly, portal, portalHasViews]);
  const portalIncludesRepresentation = (representationId2) => {
    return portal == null ? void 0 : portal.views.find((view) => {
      var _a;
      return ((_a = view == null ? void 0 : view.representationMetadata) == null ? void 0 : _a.id) === representationId2;
    });
  };
  const handleDrop = (event, item) => {
    event.preventDefault();
    if (mode === "read-only") {
      return;
    }
    const droppedRepresentationId = getFirstDroppedElementId(event);
    if (droppedRepresentationId === null) {
      addErrorMessage("Invalid drop.");
    } else if (portalIncludesRepresentation(droppedRepresentationId)) {
      addErrorMessage("The representation is already present in this portal.");
    } else {
      addPortalView(droppedRepresentationId, item.x, item.y, item.w, item.h);
    }
  };
  const handleDeleteView = (view) => {
    removePortalView(view.id);
  };
  const handleLayoutChange = (layout) => {
    if (!layoutInProgress) {
      const newLayoutData = layout.map((layoutItem) => ({
        portalViewId: layoutItem.i,
        x: layoutItem.x,
        y: layoutItem.y,
        width: layoutItem.w,
        height: layoutItem.h
      }));
      layoutPortal(newLayoutData);
    }
  };
  const nonPropagatingSetSelection = useCallback(
    (selection2) => {
      const filteredEntries = selection2.entries.filter(
        (entry) => !entry.kind.startsWith("siriusComponents://representation")
      );
      if (filteredEntries.length > 0) {
        setSelection({ entries: filteredEntries });
      }
    },
    [setSelection]
  );
  let items = [
    /* @__PURE__ */ jsxs(
      "div",
      {
        className: classes.dropArea,
        "data-testid": "portal-drop-area",
        "data-grid": {
          x: 0,
          y: 0,
          w: 10,
          h: 20,
          static: true
        },
        children: [
          /* @__PURE__ */ jsx(AddIcon, { fontSize: "large" }),
          /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", align: "center", children: "Add representations by dropping them from the explorer" })
        ]
      },
      "drop-area"
    )
  ];
  if (mode && portal && portal.views.length > 0) {
    items = portal.views.filter((view) => {
      var _a;
      return ((_a = view == null ? void 0 : view.representationMetadata) == null ? void 0 : _a.id) !== representationId;
    }).map((view) => {
      var _a;
      const layout = (_a = portal.layoutData) == null ? void 0 : _a.find((viewLayoutData) => viewLayoutData.portalViewId === view.id);
      if (layout && view.representationMetadata) {
        return /* @__PURE__ */ jsx(
          "div",
          {
            "data-grid": {
              x: layout.x,
              y: layout.y,
              w: layout.width,
              h: layout.height,
              static: mode === "direct"
            },
            style: { display: "grid" },
            children: /* @__PURE__ */ jsx(
              RepresentationFrame,
              {
                editingContextId,
                representation: view.representationMetadata,
                portalMode: mode,
                onDelete: () => {
                  if (mode !== "read-only") {
                    handleDeleteView(view);
                  }
                }
              }
            )
          },
          view.id
        );
      } else {
        return /* @__PURE__ */ jsx("div", {}, view.id);
      }
    });
  }
  if (message) {
    return /* @__PURE__ */ jsx("div", { children: message });
  }
  if (complete) {
    return /* @__PURE__ */ jsx("div", { children: "The representation is not available anymore" });
  }
  if (!portal || !mode) {
    return /* @__PURE__ */ jsx("div", {});
  }
  const cellSize = parseInt(theme.spacing(3));
  return /* @__PURE__ */ jsxs("div", { className: classes.portalRepresentationArea, ref: domNode, "data-representation-kind": "portal", children: [
    /* @__PURE__ */ jsx(
      PortalToolbar,
      {
        editingContextId,
        representationId,
        fullscreenNode: domNode,
        portalMode: mode,
        setPortalMode: (newMode) => setMode(newMode)
      }
    ),
    /* @__PURE__ */ jsx(SelectionContext.Provider, { value: { selection, setSelection: nonPropagatingSetSelection }, children: /* @__PURE__ */ jsx(
      ResponsiveGridLayout,
      {
        "data-testid": "portal-grid-layout",
        className: "layout",
        rowHeight: cellSize,
        autoSize: true,
        margin: [parseInt(theme.spacing(1)), parseInt(theme.spacing(1))],
        compactType: portalHasViews ? "vertical" : null,
        draggableHandle: ".draggable",
        isDraggable: mode === "edit",
        isResizable: mode === "edit",
        isDroppable: mode === "edit",
        allowOverlap: !portalHasViews,
        droppingItem: { i: "drop-item", w: 4, h: 6 },
        onDrop: (_layout, item, event) => {
          if (mode !== "read-only") {
            handleDrop(event, item);
          }
        },
        onLayoutChange: handleLayoutChange,
        children: items
      }
    ) })
  ] });
};
export {
  PortalRepresentation
};
