import React, { useState, useEffect, useRef, useContext } from "react";
import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { gql, useMutation, useQuery, useLazyQuery, useSubscription } from "@apollo/client";
import { useDeletionConfirmationDialog, useComponents, Toast, useMultiToast, useSelection, IconOverlay, DRAG_SOURCES_TYPE, useData } from "@eclipse-sirius/sirius-components-core";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UnfoldMore from "@mui/icons-material/UnfoldMore";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useMachine } from "@xstate/react";
import Typography from "@mui/material/Typography";
import CropDinIcon from "@mui/icons-material/CropDin";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Machine, assign } from "xstate";
const siriusComponentsTrees$1 = {
  "delete": "Delete",
  disableSynchronization: "Disable synchronization with representation",
  enableSynchronization: "Enable synchronization with representation",
  enterNewValue: "Enter the new value",
  expandAll: "Expand all",
  filters: "Filters",
  rename: "Rename"
};
const en = {
  siriusComponentsTrees: siriusComponentsTrees$1
};
const siriusComponentsTrees = {
  "delete": "Удалить",
  disableSynchronization: "Отключить синхронизацию с представлением",
  enableSynchronization: "Включить синхронизацию с представлением",
  enterNewValue: "Укажите новое значение",
  expandAll: "Развернуть всё",
  filters: "Фильтры",
  rename: "Переименовать"
};
const ru = {
  siriusComponentsTrees
};
const TreeToolBarContext = React.createContext([]);
const TreeToolBarContribution = ({}) => {
  return null;
};
const treeItemContextMenuEntryExtensionPoint = {
  identifier: "treeItem#contextMenuEntry",
  FallbackComponent: () => null
};
const deleteTreeItemMutation = gql`
  mutation deleteTreeItem($input: DeleteTreeItemInput!) {
    deleteTreeItem(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$1 = (payload) => payload.__typename === "ErrorPayload";
const TreeItemContextMenu = ({
  menuAnchor,
  editingContextId,
  treeId,
  item,
  readOnly,
  depth,
  onExpand,
  onExpandAll,
  enterEditingMode,
  onClose
}) => {
  const { t } = useTranslation("siriusComponentsTrees");
  const [state, setState] = useState({ message: null });
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const treeItemMenuContextComponents = useComponents(
    treeItemContextMenuEntryExtensionPoint
  );
  const expandItem = () => {
    if (!item.expanded && item.hasChildren) {
      onExpand(item.id, depth);
    }
  };
  const [deleteTreeItem, { loading: deleteTreeItemLoading, data: deleteTreeItemData, error: deleteTreeItemError }] = useMutation(deleteTreeItemMutation);
  const deleteItem = () => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId: treeId,
      treeItemId: item.id
    };
    showDeletionConfirmation(() => {
      deleteTreeItem({ variables: { input } });
      onClose();
    });
  };
  useEffect(() => {
    if (!deleteTreeItemLoading) {
      if (deleteTreeItemError) {
        setState({
          message: "An error has occurred while executing this action, please contact the server administrator"
        });
      }
      if (deleteTreeItemData) {
        const { deleteTreeItem: deleteTreeItem2 } = deleteTreeItemData;
        if (isErrorPayload$1(deleteTreeItem2)) {
          const { message } = deleteTreeItem2;
          setState({ message });
        }
      }
    }
  }, [deleteTreeItemLoading, deleteTreeItemError, deleteTreeItemData]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Menu,
      {
        id: "treeitem-contextmenu",
        anchorEl: menuAnchor,
        keepMounted: true,
        open: true,
        onClose,
        "data-testid": "treeitem-contextmenu",
        disableRestoreFocus: true,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        children: [
          treeItemMenuContextComponents.map(({ Component: TreeItemMenuContextComponent }, index) => /* @__PURE__ */ jsx(
            TreeItemMenuContextComponent,
            {
              editingContextId,
              item,
              readOnly,
              onClose,
              expandItem,
              treeId
            },
            index.toString()
          )),
          item.hasChildren ? /* @__PURE__ */ jsxs(
            MenuItem,
            {
              "data-testid": "expand-all",
              onClick: () => {
                onExpandAll(item);
                onClose();
              },
              disabled: readOnly,
              "aria-disabled": true,
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(UnfoldMore, { fontSize: "small" }) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("expandAll") })
              ]
            },
            "expand-all"
          ) : null,
          item.editable ? /* @__PURE__ */ jsxs(
            MenuItem,
            {
              onClick: enterEditingMode,
              "data-testid": "rename-tree-item",
              disabled: readOnly,
              "aria-disabled": true,
              children: [
                /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(EditIcon, { fontSize: "small" }) }),
                /* @__PURE__ */ jsx(ListItemText, { primary: t("rename") })
              ]
            },
            "rename"
          ) : null,
          item.deletable ? /* @__PURE__ */ jsxs(MenuItem, { onClick: deleteItem, "data-testid": "delete", disabled: readOnly, "aria-disabled": true, children: [
            /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(DeleteIcon, { fontSize: "small" }) }),
            /* @__PURE__ */ jsx(ListItemText, { primary: t("delete") })
          ] }, "delete-tree-item") : null
        ]
      }
    ),
    /* @__PURE__ */ jsx(Toast, { message: state.message, open: !!state.message, onClose: () => setState({ message: null }) })
  ] });
};
const splitText = (label, userInput) => {
  if (!userInput) {
    return [label];
  }
  const caseInsensitiveSplitLabel = label.toLocaleLowerCase().split(userInput.toLocaleLowerCase()).flatMap((value, index2, array) => {
    if (index2 === 0 && value === "") {
      return [];
    } else if (index2 === array.length - 1 && value === "") {
      return [userInput.toLocaleLowerCase()];
    } else if (index2 === 0) {
      return [value];
    }
    return [userInput.toLocaleLowerCase(), value];
  });
  const splitLabel = [];
  let index = 0;
  for (const caseInsensitiveSegment of caseInsensitiveSplitLabel) {
    const caseSensitiveSegment = label.substring(index, index + caseInsensitiveSegment.length);
    splitLabel.push(caseSensitiveSegment);
    index = index + caseInsensitiveSegment.length;
  }
  return splitLabel;
};
const isFilterCandidate = (treeItem, textToFilter) => {
  let filter = false;
  const splitLabelWithTextToHighlight = splitText(treeItem.label, textToFilter);
  if (textToFilter === null || textToFilter === "") {
    filter = false;
  } else if (splitLabelWithTextToHighlight.length > 1) {
    filter = false;
  } else if (!treeItem.hasChildren && splitLabelWithTextToHighlight.length === 1 && splitLabelWithTextToHighlight[0].toLocaleLowerCase() !== textToFilter.toLocaleLowerCase()) {
    filter = true;
  } else if (splitLabelWithTextToHighlight.length === 1 && splitLabelWithTextToHighlight[0].toLocaleLowerCase() === textToFilter.toLocaleLowerCase()) {
    filter = false;
  } else if (textToFilter && treeItem.hasChildren && treeItem.expanded && treeItem.children.map((child) => child.label.toLocaleLowerCase().split(textToFilter.toLocaleLowerCase()).length).every((v) => v === 1)) {
    filter = treeItem.children.map((child) => isFilterCandidate(child, textToFilter)).every((v) => v === true);
  }
  return filter;
};
const useTreeFiltersMenuStyles = makeStyles()((_) => ({
  root: {
    display: "flex"
  },
  treeFilter: {
    marginLeft: "0px"
  }
}));
const TreeFiltersMenu = ({ filters, onTreeFilterMenuItemClick }) => {
  const { classes } = useTreeFiltersMenuStyles();
  const { t } = useTranslation("siriusComponentsTrees");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen2) => !prevOpen2);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const updateTreeFilters = (filter, newState) => {
    const updatedFilters = filters.map((f) => f.id === filter.id ? { ...f, state: newState } : f);
    onTreeFilterMenuItemClick(updatedFilters);
  };
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        "data-testid": `tree-filter-menu-icon`,
        color: "inherit",
        size: "small",
        ref: anchorRef,
        "aria-haspopup": "true",
        title: t("filters"),
        onClick: handleToggle,
        children: /* @__PURE__ */ jsx(FilterListIcon, { color: open ? "disabled" : "inherit" })
      }
    ),
    /* @__PURE__ */ jsx(
      Popper,
      {
        "data-testid": `tree-filter-menu`,
        open,
        anchorEl: anchorRef.current,
        role: void 0,
        placement: "bottom-start",
        transition: true,
        children: ({ TransitionProps }) => /* @__PURE__ */ jsx(Fade, { ...TransitionProps, timeout: 350, children: /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: handleClose, children: /* @__PURE__ */ jsx(FormGroup, { children: filters.map((filter) => /* @__PURE__ */ jsx(
          FormControlLabel,
          {
            className: classes.treeFilter,
            control: /* @__PURE__ */ jsx(
              Checkbox,
              {
                "data-testid": `tree-filter-menu-checkbox-${filter.label}`,
                checked: filter.state,
                onChange: (event) => updateTreeFilters(filter, event.target.checked),
                name: filter.label
              }
            ),
            label: filter.label
          },
          filter.id
        )) }) }) }) })
      }
    )
  ] });
};
const useTreeToolbarStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    height: theme.spacing(4),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    justifyContent: "right",
    borderBottomColor: theme.palette.divider
  }
}));
const TreeToolBar = ({
  editingContextId,
  onSynchronizedClick,
  synchronized,
  treeFilters,
  onTreeFilterMenuItemClick,
  treeToolBarContributionComponents,
  readOnly
}) => {
  const { classes } = useTreeToolbarStyles();
  const { t } = useTranslation("siriusComponentsTrees");
  let treeFiltersMenu;
  if (treeFilters.length > 0) {
    treeFiltersMenu = /* @__PURE__ */ jsx(TreeFiltersMenu, { filters: treeFilters, onTreeFilterMenuItemClick });
  }
  const preferenceButtonSynchronizeTitle = synchronized ? t("disableSynchronization") : t("enableSynchronization");
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs("div", { className: classes.toolbar, children: [
    treeToolBarContributionComponents.map((component, index) => {
      const props = {
        editingContextId,
        disabled: readOnly,
        key: index.toString()
      };
      const element = React.createElement(component, props);
      return element;
    }),
    treeFiltersMenu,
    /* @__PURE__ */ jsx(
      IconButton,
      {
        color: "inherit",
        size: "small",
        "aria-label": preferenceButtonSynchronizeTitle,
        title: preferenceButtonSynchronizeTitle,
        onClick: onSynchronizedClick,
        "data-testid": "tree-synchronize",
        children: /* @__PURE__ */ jsx(SwapHorizIcon, { color: synchronized ? "inherit" : "disabled" })
      }
    )
  ] }) });
};
const useFilterBarStyles = makeStyles()((theme) => ({
  filterbar: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  textfield: {
    height: theme.spacing(3),
    fontSize: theme.typography.fontSize
  }
}));
const FilterBar = ({ onTextChange, onFilterButtonClick, onClose }) => {
  const { classes } = useFilterBarStyles();
  const initialState = {
    filterEnabled: false
  };
  const [state, setState] = useState(initialState);
  return /* @__PURE__ */ jsxs("div", { className: classes.filterbar, children: [
    /* @__PURE__ */ jsx(
      TextField,
      {
        id: "filterbar-textfield",
        "data-testid": "filterbar-textfield",
        name: "filterbar-textfield",
        placeholder: "Type to filter",
        spellCheck: false,
        variant: "outlined",
        size: "small",
        margin: "none",
        autoFocus: true,
        multiline: false,
        fullWidth: true,
        onChange: onTextChange,
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(
            IconButton,
            {
              "data-testid": "filterbar-filter-button",
              "aria-label": "filter",
              size: "small",
              onClick: () => {
                onFilterButtonClick(!state.filterEnabled);
                setState((prevState) => {
                  return { filterEnabled: !prevState.filterEnabled };
                });
              },
              children: /* @__PURE__ */ jsx(FilterListIcon, { fontSize: "small", color: state.filterEnabled ? "primary" : "secondary" })
            }
          ) }),
          className: classes.textfield
        }
      }
    ),
    /* @__PURE__ */ jsx(
      IconButton,
      {
        "data-testid": "filterbar-close-button",
        size: "small",
        "aria-label": "close",
        color: "inherit",
        onClick: onClose,
        children: /* @__PURE__ */ jsx(CloseIcon, { fontSize: "small" })
      }
    )
  ] });
};
const useTreeItemArrowStyle = makeStyles()(() => ({
  arrow: {
    cursor: "pointer"
  },
  noChildren: {
    paddingLeft: "20px"
  }
}));
const TreeItemArrow = ({ item, depth, onExpand, "data-testid": dataTestid }) => {
  const { classes } = useTreeItemArrowStyle();
  if (item.hasChildren) {
    const onClick = () => onExpand(item.id, depth);
    if (item.expanded) {
      return /* @__PURE__ */ jsx(ExpandMoreIcon, { className: classes.arrow, style: { fontSize: 20 }, onClick, "data-testid": dataTestid });
    } else {
      return /* @__PURE__ */ jsx(
        ChevronRightIcon,
        {
          className: classes.arrow,
          style: { fontSize: 20 },
          onClick,
          "data-testid": dataTestid
        }
      );
    }
  }
  return /* @__PURE__ */ jsx("div", { className: classes.noChildren });
};
const renameTreeItemMutation = gql`
  mutation renameTreeItem($input: RenameTreeItemInput!) {
    renameTreeItem(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const initialDirectEditElementLabeQuery = gql`
  query initialDirectEditElementLabel($editingContextId: ID!, $representationId: ID!, $treeItemId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $representationId) {
          description {
            ... on TreeDescription {
              initialDirectEditTreeItemLabel(treeItemId: $treeItemId)
            }
          }
        }
      }
    }
  }
`;
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload = (payload) => payload.__typename === "SuccessPayload";
const TreeItemDirectEditInput = ({
  editingContextId,
  treeId,
  treeItemId,
  editingKey,
  onClose
}) => {
  const initialLabel = editingKey === null || editingKey === "" ? "" : editingKey;
  const { t } = useTranslation("siriusComponentsTrees");
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [state, setState] = useState({
    newLabel: initialLabel
  });
  const { addErrorMessage } = useMultiToast();
  const textInput = useRef(null);
  const editionFinished = useRef(false);
  const { data: initialLabelTreeItemItemData, error: initialLabelTreeItemItemError } = useQuery(initialDirectEditElementLabeQuery, {
    variables: {
      editingContextId,
      representationId: treeId,
      treeItemId
    }
  });
  useEffect(() => {
    let cleanup = void 0;
    if (initialLabelTreeItemItemError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (initialLabelTreeItemItemData == null ? void 0 : initialLabelTreeItemItemData.viewer.editingContext.representation.description.initialDirectEditTreeItemLabel) {
      const initialLabel2 = initialLabelTreeItemItemData == null ? void 0 : initialLabelTreeItemItemData.viewer.editingContext.representation.description.initialDirectEditTreeItemLabel;
      if (!editingKey) {
        setState((prevState) => {
          return { ...prevState, newLabel: initialLabel2 };
        });
        const timeOutId = setTimeout(() => {
          textInput.current.select();
        }, 0);
        cleanup = () => clearTimeout(timeOutId);
      }
    }
    return cleanup;
  }, [coreT, initialLabelTreeItemItemError, initialLabelTreeItemItemData]);
  const [renameTreeItem, { data: renameTreeItemData, error: renameTreeItemError }] = useMutation(renameTreeItemMutation);
  useEffect(() => {
    if (renameTreeItemError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (renameTreeItemData) {
      const { renameTreeItem: renameTreeItem2 } = renameTreeItemData;
      if (isErrorPayload(renameTreeItem2)) {
        addErrorMessage(renameTreeItem2.message);
      } else if (isSuccessPayload(renameTreeItem2)) {
        if (renameTreeItem2.__typename === "SuccessPayload") {
          onClose();
        }
      }
    }
  }, [coreT, renameTreeItemData, renameTreeItemError]);
  const doRename = () => {
    renameTreeItem({
      variables: {
        input: {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: treeId,
          treeItemId,
          newLabel: state.newLabel
        }
      }
    });
  };
  const handleChange = (event) => {
    const newLabel = event.target.value;
    setState((prevState) => {
      return { ...prevState, newLabel };
    });
  };
  const onFinishEditing = (event) => {
    const { key } = event;
    if (key === "Enter" && !event.shiftKey) {
      editionFinished.current = true;
      event.preventDefault();
      doRename();
    } else if (key === "Escape") {
      editionFinished.current = true;
      onClose();
    }
  };
  const onFocusIn = (event) => event.target.select();
  const onBlur = () => {
    if (!editionFinished.current) {
      doRename();
    }
  };
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    TextField,
    {
      variant: "standard",
      name: "name",
      size: "small",
      inputRef: textInput,
      placeholder: t("enterNewValue"),
      value: state.newLabel,
      onChange: handleChange,
      onFocus: onFocusIn,
      onKeyDown: onFinishEditing,
      onBlur,
      autoFocus: true,
      spellCheck: false,
      "data-testid": "name-edit"
    }
  ) });
};
const useTreeItemActionStyle = makeStyles()((theme) => ({
  more: {
    hover: {
      backgroundColor: theme.palette.action.hover
    },
    focus: {
      backgroundColor: theme.palette.action.selected
    }
  }
}));
const TreeItemAction = ({
  editingContextId,
  treeId,
  item,
  readOnly,
  depth,
  onExpand,
  onExpandAll,
  onEnterEditingMode
}) => {
  const { classes } = useTreeItemActionStyle();
  const [state, setState] = useState({
    showContextMenu: false,
    menuAnchor: null
  });
  const openContextMenu = (event) => {
    if (!state.showContextMenu) {
      const { currentTarget } = event;
      setState((prevState) => ({
        ...prevState,
        showContextMenu: true,
        menuAnchor: currentTarget
      }));
    }
  };
  let contextMenu = null;
  if (state.showContextMenu) {
    const closeContextMenu = () => {
      setState((prevState) => ({
        ...prevState,
        showContextMenu: false,
        menuAnchor: null
      }));
    };
    const enterEditingMode = () => {
      setState((prevState) => ({
        ...prevState,
        showContextMenu: false,
        menuAnchor: null
      }));
      onEnterEditingMode();
    };
    contextMenu = /* @__PURE__ */ jsx(
      TreeItemContextMenu,
      {
        menuAnchor: state.menuAnchor,
        editingContextId,
        treeId,
        item,
        readOnly,
        depth,
        onExpand,
        onExpandAll,
        enterEditingMode,
        onClose: closeContextMenu
      }
    );
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(IconButton, { className: classes.more, size: "small", onClick: openContextMenu, "data-testid": `${item.label}-more`, children: /* @__PURE__ */ jsx(MoreVertIcon, { style: { fontSize: 12 } }) }),
    contextMenu
  ] });
};
const useTreeItemStyle = makeStyles()((theme) => ({
  treeItem: {
    display: "flex",
    flexDirection: "row",
    height: "24px",
    gap: theme.spacing(0.5),
    alignItems: "center",
    userSelect: "none",
    "&:focus-within": {
      borderWidth: "1px",
      borderColor: "black",
      borderStyle: "dotted"
    }
  },
  treeItemHover: {
    backgroundColor: theme.palette.action.hover
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected
    }
  },
  nonSelectable: {
    opacity: 0.6
  },
  arrow: {
    cursor: "pointer"
  },
  content: {
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 20px",
    columnGap: "8px",
    alignItems: "center",
    gridColumnStart: "2",
    gridColumnEnd: "3"
  },
  imageAndLabel: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    alignItems: "center"
  },
  imageAndLabelSelectable: {
    cursor: "pointer"
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100ch"
  },
  selectedLabel: {
    fontWeight: "bold"
  },
  marked: {
    fontWeight: "bold"
  },
  ul: {
    marginLeft: theme.spacing(3)
  },
  highlight: {
    backgroundColor: theme.palette.navigation.leftBackground
  }
}));
const directEditActivationValidCharacters = /[\w&é§èàùçÔØÁÛÊË"«»’”„´$¥€£\\¿?!=+-,;:%/{}[\]–#@*.]/;
const TreeItem = ({
  editingContextId,
  treeId,
  item,
  depth,
  onExpand,
  onExpandAll,
  readOnly,
  textToHighlight,
  textToFilter,
  enableMultiSelection,
  markedItemIds,
  treeItemActionRender
}) => {
  var _a;
  const { classes } = useTreeItemStyle();
  const initialState = {
    editingMode: false,
    editingKey: null,
    isHovered: false
  };
  const [state, setState] = useState(initialState);
  const { editingMode } = state;
  const refDom = useRef();
  const { selection, setSelection } = useSelection();
  const handleMouseEnter = () => {
    setState((prevState) => {
      return { ...prevState, isHovered: true };
    });
  };
  const handleMouseLeave = () => {
    setState((prevState) => {
      return { ...prevState, isHovered: false };
    });
  };
  const onTreeItemAction = () => {
    setState((prevState) => {
      return { ...prevState, isHovered: false };
    });
  };
  const enterEditingMode = () => {
    setState((prevState) => ({
      ...prevState,
      editingMode: true,
      editingKey: null
    }));
  };
  let content = null;
  if (item.expanded && item.children) {
    content = /* @__PURE__ */ jsx("ul", { className: classes.ul, children: item.children.map((childItem) => {
      return /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx(
        TreeItem,
        {
          editingContextId,
          treeId,
          item: childItem,
          depth: depth + 1,
          onExpand,
          onExpandAll,
          enableMultiSelection,
          readOnly,
          textToHighlight,
          textToFilter,
          markedItemIds,
          treeItemActionRender
        }
      ) }, childItem.id);
    }) });
  }
  let className = classes.treeItem;
  let dataTestid = void 0;
  const selected = selection.entries.find((entry) => entry.id === item.id);
  if (selected) {
    className = `${className} ${classes.selected}`;
    dataTestid = "selected";
  }
  if (state.isHovered && item.selectable) {
    className = `${className} ${classes.treeItemHover}`;
  }
  useEffect(() => {
    var _a2, _b;
    if (selected) {
      if ((_a2 = refDom.current) == null ? void 0 : _a2.scrollIntoViewIfNeeded) {
        refDom.current.scrollIntoViewIfNeeded(true);
      } else {
        (_b = refDom.current) == null ? void 0 : _b.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selected]);
  let image = /* @__PURE__ */ jsx(CropDinIcon, {});
  if (((_a = item.iconURL) == null ? void 0 : _a.length) > 0) {
    image = /* @__PURE__ */ jsx(IconOverlay, { iconURL: item.iconURL, alt: item.kind });
  }
  let text = null;
  const onCloseEditingMode = () => {
    setState((prevState) => {
      return { ...prevState, editingMode: false };
    });
    refDom.current.focus();
  };
  const marked = markedItemIds.some((id) => id === item.id);
  if (editingMode) {
    text = /* @__PURE__ */ jsx(
      TreeItemDirectEditInput,
      {
        editingContextId,
        treeId,
        treeItemId: item.id,
        editingKey: state.editingKey,
        onClose: onCloseEditingMode
      }
    );
  } else {
    let itemLabel;
    const splitLabelWithTextToHighlight = splitText(item.label, textToHighlight);
    if (textToHighlight === null || textToHighlight === "" || splitLabelWithTextToHighlight.length === 1 && splitLabelWithTextToHighlight[0].toLocaleLowerCase() !== item.label.toLocaleLowerCase()) {
      itemLabel = /* @__PURE__ */ jsx(Fragment, { children: item.label });
    } else {
      const languages = Array.from(navigator.languages);
      itemLabel = /* @__PURE__ */ jsx(Fragment, { children: splitLabelWithTextToHighlight.map((value, index) => {
        const shouldHighlight = value.localeCompare(textToHighlight, languages, { sensitivity: "base" }) === 0;
        return /* @__PURE__ */ jsx(
          "span",
          {
            "data-testid": `${item.label}-${value}-${index}`,
            className: shouldHighlight ? classes.highlight : "",
            children: value
          },
          value + index
        );
      }) });
    }
    text = /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "body2",
        className: `${classes.label} ${selected ? classes.selectedLabel : ""} ${marked ? classes.marked : ""}`,
        children: itemLabel
      }
    );
  }
  const onClick = (event) => {
    if (!state.editingMode && event.currentTarget.contains(event.target)) {
      refDom.current.focus();
      if (!item.selectable) {
        return;
      }
      if ((event.ctrlKey || event.metaKey) && enableMultiSelection) {
        event.stopPropagation();
        const isItemInSelection = selection.entries.find((entry) => entry.id === item.id);
        if (isItemInSelection) {
          const newSelection = { entries: selection.entries.filter((entry) => entry.id !== item.id) };
          setSelection(newSelection);
        } else {
          const { id, label, kind } = item;
          const newEntry = { id, label, kind };
          const newSelection = { entries: [...selection.entries, newEntry] };
          setSelection(newSelection);
        }
      } else {
        const { id, kind } = item;
        setSelection({ entries: [{ id, kind }] });
      }
    }
  };
  const onBeginEditing = (event) => {
    if (!item.editable || editingMode || readOnly || !event.currentTarget.contains(event.target)) {
      return;
    }
    const { key } = event;
    if ((event.altKey || event.shiftKey) && event.getModifierState(key)) {
      return;
    }
    const validFirstInputChar = !event.metaKey && !event.ctrlKey && key.length === 1 && directEditActivationValidCharacters.test(key);
    if (validFirstInputChar) {
      setState((prevState) => {
        return { ...prevState, editingMode: true, editingKey: key };
      });
    }
  };
  const dragStart = (event) => {
    const isDraggedItemSelected = selection.entries.map((entry) => entry.id).includes(item.id);
    if (!isDraggedItemSelected) {
      const itemEntry = { id: item.id, kind: item.kind };
      event.dataTransfer.setData(DRAG_SOURCES_TYPE, JSON.stringify([itemEntry]));
    } else if (selection.entries.length > 0) {
      event.dataTransfer.setData(DRAG_SOURCES_TYPE, JSON.stringify(selection.entries));
    }
  };
  const dragOver = (event) => {
    event.stopPropagation();
  };
  let tooltipText = "";
  if (item.kind.startsWith("siriusComponents://semantic")) {
    const query = item.kind.substring(item.kind.indexOf("?") + 1, item.kind.length);
    const params = new URLSearchParams(query);
    if (params.has("domain") && params.has("entity")) {
      tooltipText = params.get("domain") + "::" + params.get("entity");
    }
  } else if (item.kind.startsWith("siriusComponents://representation")) {
    const query = item.kind.substring(item.kind.indexOf("?") + 1, item.kind.length);
    const params = new URLSearchParams(query);
    if (params.has("type")) {
      tooltipText = params.get("type");
    }
  }
  let currentTreeItem;
  if (textToFilter && isFilterCandidate(item, textToFilter)) {
    currentTreeItem = null;
  } else {
    currentTreeItem = /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(
        "div",
        {
          className,
          draggable: true,
          onClick,
          onDragStart: dragStart,
          onDragOver: dragOver,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
          children: [
            /* @__PURE__ */ jsx(TreeItemArrow, { item, depth, onExpand, "data-testid": `${item.label}-toggle` }),
            /* @__PURE__ */ jsx(
              "div",
              {
                ref: refDom,
                tabIndex: 0,
                onKeyDown: onBeginEditing,
                "data-treeitemid": item.id,
                "data-treeitemlabel": item.label,
                "data-treeitemkind": item.kind,
                "data-haschildren": item.hasChildren.toString(),
                "data-depth": depth,
                "data-expanded": item.expanded.toString(),
                "data-testid": dataTestid,
                children: /* @__PURE__ */ jsxs("div", { className: `${classes.content} ${item.selectable ? "" : classes.nonSelectable}`, children: [
                  /* @__PURE__ */ jsxs(
                    "div",
                    {
                      className: `${classes.imageAndLabel} ${item.selectable ? classes.imageAndLabelSelectable : ""}`,
                      onDoubleClick: () => item.hasChildren && onExpand(item.id, depth),
                      title: tooltipText,
                      "data-testid": item.label,
                      children: [
                        image,
                        text
                      ]
                    }
                  ),
                  /* @__PURE__ */ jsx("div", { onClick: onTreeItemAction, children: treeItemActionRender ? treeItemActionRender({
                    editingContextId,
                    treeId,
                    item,
                    depth,
                    onExpand,
                    onExpandAll,
                    readOnly,
                    onEnterEditingMode: enterEditingMode,
                    isHovered: state.isHovered
                  }) : /* @__PURE__ */ jsx(
                    TreeItemAction,
                    {
                      editingContextId,
                      treeId,
                      item,
                      depth,
                      onExpand,
                      onExpandAll,
                      readOnly,
                      onEnterEditingMode: enterEditingMode,
                      isHovered: state.isHovered
                    }
                  ) })
                ] })
              }
            )
          ]
        }
      ),
      content
    ] });
  }
  return /* @__PURE__ */ jsx(Fragment, { children: currentTreeItem });
};
const useTreeStyle = makeStyles()((_) => ({
  ul: {
    width: "inherit",
    minWidth: "fit-content"
  }
}));
const Tree = ({
  editingContextId,
  tree,
  onExpand,
  onExpandAll,
  readOnly,
  enableMultiSelection = true,
  textToHighlight,
  textToFilter,
  markedItemIds,
  treeItemActionRender
}) => {
  const { classes } = useTreeStyle();
  const treeElement = useRef(null);
  useEffect(() => {
    const downHandler = (event) => {
      if ((event.key === "ArrowLeft" || event.key === "ArrowRight" || event.key === "ArrowUp" || event.key === "ArrowDown") && event.target.tagName !== "INPUT") {
        event.preventDefault();
        const previousItem = document.activeElement;
        const dataset = previousItem.dataset;
        if (dataset.treeitemid) {
          const treeItemDomElements = document.querySelectorAll("[data-treeitemid]");
          const index = Array.from(treeItemDomElements).indexOf(previousItem);
          const id = dataset.treeitemid;
          const hasChildren = dataset.haschildren === "true";
          const isExpanded = dataset.expanded === "true";
          switch (event.key) {
            case "ArrowLeft":
              if (hasChildren && isExpanded) {
                onExpand(id, dataset.depth);
              } else if (index > 0) {
                const parentDepth = (dataset.depth - 1).toString();
                let positionFromParent = 0;
                while (!(treeItemDomElements[index - positionFromParent].dataset.depth === parentDepth)) {
                  positionFromParent++;
                }
                treeItemDomElements[index - positionFromParent].click();
              }
              break;
            case "ArrowRight":
              if (hasChildren && !isExpanded) {
                onExpand(id, dataset.depth);
              } else if (index < treeItemDomElements.length - 1) {
                treeItemDomElements[index + 1].click();
              }
              break;
            case "ArrowUp":
              if (index > 0) {
                treeItemDomElements[index - 1].click();
              }
              break;
            case "ArrowDown":
              if (index < treeItemDomElements.length - 1) {
                treeItemDomElements[index + 1].click();
              }
              break;
          }
        }
      }
    };
    const element = treeElement == null ? void 0 : treeElement.current;
    if (element) {
      element.addEventListener("keydown", downHandler);
      return () => {
        element.removeEventListener("keydown", downHandler);
      };
    }
    return null;
  }, [treeElement, onExpand]);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx("div", { ref: treeElement, children: /* @__PURE__ */ jsx("ul", { className: classes.ul, "data-testid": "tree-root-elements", children: tree.children.map((item) => /* @__PURE__ */ jsx("li", { children: /* @__PURE__ */ jsx(
    TreeItem,
    {
      editingContextId,
      treeId: tree.id,
      item,
      depth: 1,
      onExpand,
      onExpandAll,
      enableMultiSelection,
      readOnly,
      textToHighlight,
      textToFilter,
      markedItemIds,
      treeItemActionRender
    }
  ) }, item.id)) }) }) });
};
const treeViewTreeConverterExtensionPoint = {
  identifier: "treeView#treeConverter",
  fallback: []
};
const isTreeRefreshedEventPayload = (payload) => payload.__typename === "TreeRefreshedEventPayload";
const treeViewMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      tree: null,
      expanded: [],
      maxDepth: 1,
      autoExpandToRevealSelection: true,
      treeItemToExpandAll: null,
      synchronizedWithSelection: true,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      treeView: {
        initial: "idle",
        states: {
          idle: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  cond: "isTreeRefreshedEventPayload",
                  target: "ready",
                  actions: "handleSubscriptionResult"
                },
                {
                  target: "idle",
                  actions: "handleSubscriptionResult"
                }
              ]
            }
          },
          ready: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: {
                target: "ready",
                actions: "handleSubscriptionResult"
              },
              AUTO_EXPAND_TO_REVEAL_SELECTION: {
                actions: "autoExpandToRevealSelection"
              },
              HANDLE_EXPANDED: {
                actions: "expand"
              },
              HANDLE_ON_EXPAND_ALL: {
                actions: "onExpandAll"
              },
              HANDLE_EXPAND_ALL_TREE_PATH: {
                actions: "expandAllTreePath"
              },
              HANDLE_TREE_PATH: {
                actions: "handleTreePath"
              },
              HANDLE_COMPLETE: {
                target: "complete"
              },
              SYNCHRONIZE_WITH_SELECTION: {
                actions: "synchronizeWithSelection"
              }
            }
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isTreeRefreshedEventPayload: (_, event) => {
        const { result } = event;
        const { data } = result;
        return isTreeRefreshedEventPayload(data.treeEvent);
      }
    },
    actions: {
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (isTreeRefreshedEventPayload(data.treeEvent)) {
          const { tree } = data.treeEvent;
          return { tree };
        }
        return {};
      }),
      synchronizeWithSelection: assign((_, event) => {
        const { synchronizedWithSelection } = event;
        if (synchronizedWithSelection) {
          return { synchronizedWithSelection, autoExpandToRevealSelection: true };
        }
        return { synchronizedWithSelection, autoExpandToRevealSelection: false };
      }),
      autoExpandToRevealSelection: assign((context, event) => {
        const { autoExpandToRevealSelection } = event;
        const { synchronizedWithSelection } = context;
        if (synchronizedWithSelection) {
          return { autoExpandToRevealSelection };
        }
        return {};
      }),
      expand: assign((context, event) => {
        const { expanded, maxDepth } = context;
        const { id, depth } = event;
        if (expanded.includes(id)) {
          const newExpanded = [...expanded];
          newExpanded.splice(newExpanded.indexOf(id), 1);
          return { expanded: newExpanded, autoExpandToRevealSelection: false, maxDepth: Math.max(maxDepth, depth) };
        }
        return { expanded: [...expanded, id], maxDepth: Math.max(maxDepth, depth) };
      }),
      onExpandAll: assign((_, event) => {
        const { treeItemId } = event;
        return { treeItemToExpandAll: treeItemId };
      }),
      expandAllTreePath: assign((context, event) => {
        var _a, _b;
        const { expanded, maxDepth } = context;
        const { expandAllTreePathData } = event;
        if ((_b = (_a = expandAllTreePathData.viewer) == null ? void 0 : _a.editingContext) == null ? void 0 : _b.expandAllTreePath) {
          const { treeItemIdsToExpand, maxDepth: expandedMaxDepth } = expandAllTreePathData.viewer.editingContext.expandAllTreePath;
          const newExpanded = [...expanded];
          treeItemIdsToExpand == null ? void 0 : treeItemIdsToExpand.forEach((itemToExpand) => {
            if (!expanded.includes(itemToExpand)) {
              newExpanded.push(itemToExpand);
            }
          });
          return {
            expanded: newExpanded,
            maxDepth: Math.max(expandedMaxDepth, maxDepth),
            treeItemToExpandAll: null
          };
        }
        return { treeItemToExpandAll: null };
      }),
      handleTreePath: assign((context, event) => {
        var _a, _b;
        const { expanded, maxDepth } = context;
        const { treePathData } = event;
        if ((_b = (_a = treePathData.viewer) == null ? void 0 : _a.editingContext) == null ? void 0 : _b.treePath) {
          const { treeItemIdsToExpand, maxDepth: expandedMaxDepth } = treePathData.viewer.editingContext.treePath;
          const newExpanded = [...expanded];
          treeItemIdsToExpand == null ? void 0 : treeItemIdsToExpand.forEach((itemToExpand) => {
            if (!expanded.includes(itemToExpand)) {
              newExpanded.push(itemToExpand);
            }
          });
          return { expanded: newExpanded, maxDepth: Math.max(expandedMaxDepth, maxDepth) };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const getTreeEventSubscription = (depth) => {
  const treeChildren = recursiveGetChildren(depth);
  const subscription = `
subscription treeEvent($input: TreeEventInput!) {
  treeEvent(input: $input) {
    __typename
    ... on TreeRefreshedEventPayload {
      id
      tree {
        id
        children {
          ${treeChildren}
        }
      }
    }
  }
}
`;
  return subscription + fragment;
};
const fragment = `
fragment treeItemFields on TreeItem {
  id
  hasChildren
  expanded
  label
  editable
  deletable
  selectable
  kind
  iconURL
}
`;
const recursiveGetChildren = (depth) => {
  let children = "";
  if (depth > 0) {
    children = `
    children {
      ${recursiveGetChildren(depth - 1)}
    }`;
  }
  return `...treeItemFields${children}`;
};
const getTreePathQuery = gql`
  query getTreePath($editingContextId: ID!, $treeId: ID!, $selectionEntryIds: [ID!]!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        treePath(treeId: $treeId, selectionEntryIds: $selectionEntryIds) {
          treeItemIdsToExpand
          maxDepth
        }
      }
    }
  }
`;
const getExpandAllTreePathQuery = gql`
  query getExpandAllTreePath($editingContextId: ID!, $treeId: ID!, $treeItemId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        expandAllTreePath(treeId: $treeId, treeItemId: $treeItemId) {
          treeItemIdsToExpand
          maxDepth
        }
      }
    }
  }
`;
const TreeView = ({
  editingContextId,
  readOnly,
  treeId,
  enableMultiSelection,
  synchronizedWithSelection,
  activeFilterIds,
  textToHighlight,
  textToFilter,
  markedItemIds = [],
  treeItemActionRender
}) => {
  const [{ value, context }, dispatch] = useMachine(treeViewMachine, {
    context: {
      synchronizedWithSelection
    }
  });
  const { selection } = useSelection();
  const { toast, treeView } = value;
  const { id, tree, expanded, maxDepth, autoExpandToRevealSelection, treeItemToExpandAll, message } = context;
  const [getTreePath, { loading: treePathLoading, data: treePathData, error: treePathError }] = useLazyQuery(getTreePathQuery);
  const [
    getExpandAllTreePath,
    { loading: expandAllTreePathLoading, data: expandAllTreePathData, error: expandAllTreePathError }
  ] = useLazyQuery(getExpandAllTreePathQuery);
  const selectionKey = selection == null ? void 0 : selection.entries.map((entry) => entry.id).sort().join(":");
  useEffect(() => {
    if (tree && autoExpandToRevealSelection) {
      const variables = {
        editingContextId,
        treeId: tree.id,
        selectionEntryIds: selection.entries.map((entry) => entry.id)
      };
      getTreePath({ variables });
    }
  }, [editingContextId, tree, selectionKey, autoExpandToRevealSelection, getTreePath]);
  useEffect(() => {
    if (!treePathLoading) {
      if (treePathData) {
        const handleTreePathEvent = { type: "HANDLE_TREE_PATH", treePathData };
        dispatch(handleTreePathEvent);
      }
      if (treePathError) {
        const { message: message2 } = treePathError;
        const showToastEvent = { type: "SHOW_TOAST", message: message2 };
        dispatch(showToastEvent);
      }
    }
  }, [treePathLoading, treePathData, treePathError]);
  useEffect(() => {
    if (tree && treeItemToExpandAll) {
      const variables = {
        editingContextId,
        treeId: tree.id,
        treeItemId: treeItemToExpandAll
      };
      getExpandAllTreePath({ variables });
    }
  }, [editingContextId, tree, treeItemToExpandAll, getExpandAllTreePathQuery]);
  useEffect(() => {
    if (!expandAllTreePathLoading) {
      if (expandAllTreePathData) {
        const handleExpandAllTreePathEvent = {
          type: "HANDLE_EXPAND_ALL_TREE_PATH",
          expandAllTreePathData
        };
        dispatch(handleExpandAllTreePathEvent);
      }
      if (expandAllTreePathError) {
        const { message: message2 } = expandAllTreePathError;
        const showToastEvent = { type: "SHOW_TOAST", message: message2 };
        dispatch(showToastEvent);
      }
    }
  }, [expandAllTreePathLoading, expandAllTreePathData, expandAllTreePathError]);
  const { error } = useSubscription(gql(getTreeEventSubscription(maxDepth)), {
    variables: {
      input: {
        id,
        treeId,
        editingContextId,
        expanded,
        activeFilterIds
      }
    },
    fetchPolicy: "no-cache",
    skip: treeView === "complete",
    onData: ({ data }) => {
      const handleDataEvent = {
        type: "HANDLE_SUBSCRIPTION_RESULT",
        result: data
      };
      dispatch(handleDataEvent);
    },
    onComplete: () => {
      const completeEvent = { type: "HANDLE_COMPLETE" };
      dispatch(completeEvent);
    }
  });
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  useEffect(() => {
    const autoExpandToRevealSelectionEvent = {
      type: "AUTO_EXPAND_TO_REVEAL_SELECTION",
      autoExpandToRevealSelection: true
    };
    dispatch(autoExpandToRevealSelectionEvent);
  }, [selection]);
  useEffect(() => {
    const synchronizeWithSelectionEvent = {
      type: "SYNCHRONIZE_WITH_SELECTION",
      synchronizedWithSelection
    };
    dispatch(synchronizeWithSelectionEvent);
  }, [synchronizedWithSelection]);
  const onExpand = (id2, depth) => {
    const handleExpandedEvent = { type: "HANDLE_EXPANDED", id: id2, depth };
    dispatch(handleExpandedEvent);
  };
  const onExpandAll = (treeItem) => {
    const handleOnExpandAllEvent = { type: "HANDLE_ON_EXPAND_ALL", treeItemId: treeItem.id };
    dispatch(handleOnExpandAllEvent);
  };
  const { data: treeConverters } = useData(treeViewTreeConverterExtensionPoint);
  let convertedTree = tree;
  treeConverters.forEach((treeConverter) => {
    convertedTree = treeConverter.convert(editingContextId, convertedTree);
  });
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("div", { "data-testid": treeId, children: tree ? /* @__PURE__ */ jsx(
      Tree,
      {
        editingContextId,
        tree: convertedTree,
        onExpand,
        onExpandAll,
        readOnly,
        enableMultiSelection,
        markedItemIds,
        textToFilter,
        textToHighlight,
        treeItemActionRender
      }
    ) : null }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
const getAllTreeFiltersQuery = gql`
  query getAllTreeFilters($editingContextId: ID!, $treeId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $treeId) {
          description {
            ... on TreeDescription {
              filters {
                id
                label
                defaultState
              }
            }
          }
        }
      }
    }
  }
`;
const useTreeFilters = (editingContextId, treeId) => {
  const { loading, data, error } = useQuery(
    getAllTreeFiltersQuery,
    {
      variables: {
        editingContextId,
        treeId
      }
    }
  );
  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      const { message } = error;
      addErrorMessage(message);
    }
  }, [error]);
  const treeFilters = (data == null ? void 0 : data.viewer.editingContext.representation.description.filters) || [];
  return {
    treeFilters,
    loading
  };
};
const useStyles = makeStyles()((theme) => ({
  treeView: {
    display: "flex",
    flexDirection: "column"
  },
  treeContent: {
    paddingTop: theme.spacing(1),
    flexGrow: 1,
    overflow: "auto"
  }
}));
const ExplorerView = ({ editingContextId, readOnly }) => {
  const { classes: styles } = useStyles();
  const initialState = {
    synchronizedWithSelection: true,
    filterBar: false,
    filterBarText: "",
    filterBarTreeFiltering: false,
    treeFilters: []
  };
  const [state, setState] = useState(initialState);
  const treeToolBarContributionComponents = useContext(TreeToolBarContext).map(
    (contribution) => contribution.props.component
  );
  const { loading, treeFilters } = useTreeFilters(editingContextId, "explorer://");
  useEffect(() => {
    if (!loading) {
      const allFilters = treeFilters.map((gqlTreeFilter) => ({
        id: gqlTreeFilter.id,
        label: gqlTreeFilter.label,
        state: gqlTreeFilter.defaultState
      }));
      setState((prevState) => ({ ...prevState, treeFilters: allFilters }));
    }
  }, [loading, treeFilters]);
  const treeElement = useRef(null);
  useEffect(() => {
    const downHandler = (event) => {
      if ((event.ctrlKey === true || event.metaKey === true) && event.key === "f" && event.target.tagName !== "INPUT") {
        event.preventDefault();
        setState((prevState) => {
          return { ...prevState, filterBar: true, filterBarText: "", filterBarTreeFiltering: false };
        });
      }
    };
    const element = treeElement == null ? void 0 : treeElement.current;
    if (element) {
      element.addEventListener("keydown", downHandler);
      return () => {
        element.removeEventListener("keydown", downHandler);
      };
    }
    return null;
  }, [treeElement]);
  let filterBar;
  if (state.filterBar) {
    filterBar = /* @__PURE__ */ jsx(
      FilterBar,
      {
        onTextChange: (event) => {
          const {
            target: { value }
          } = event;
          setState((prevState) => {
            return { ...prevState, filterBarText: value };
          });
        },
        onFilterButtonClick: (enabled) => setState((prevState) => {
          return { ...prevState, filterBarTreeFiltering: enabled };
        }),
        onClose: () => setState((prevState) => {
          return { ...prevState, filterBar: false, filterBarText: "", filterBarTreeFiltering: false };
        })
      }
    );
  }
  const activeTreeFilterIds = state.treeFilters.filter((filter) => filter.state).map((filter) => filter.id);
  return /* @__PURE__ */ jsxs("div", { className: styles.treeView, ref: treeElement, children: [
    /* @__PURE__ */ jsx(
      TreeToolBar,
      {
        editingContextId,
        readOnly,
        onSynchronizedClick: () => setState((prevState) => {
          return { ...prevState, synchronizedWithSelection: !state.synchronizedWithSelection };
        }),
        synchronized: state.synchronizedWithSelection,
        treeFilters: state.treeFilters,
        onTreeFilterMenuItemClick: (treeFilters2) => setState((prevState) => {
          return { ...prevState, treeFilters: treeFilters2 };
        }),
        treeToolBarContributionComponents
      }
    ),
    /* @__PURE__ */ jsxs("div", { className: styles.treeContent, children: [
      filterBar,
      /* @__PURE__ */ jsx(
        TreeView,
        {
          editingContextId,
          readOnly,
          treeId: "explorer://",
          enableMultiSelection: true,
          synchronizedWithSelection: state.synchronizedWithSelection,
          activeFilterIds: activeTreeFilterIds,
          textToHighlight: state.filterBarText,
          textToFilter: state.filterBarTreeFiltering ? state.filterBarText : null
        }
      )
    ] })
  ] });
};
export {
  ExplorerView,
  TreeItemContextMenu,
  TreeToolBarContext,
  TreeToolBarContribution,
  TreeView,
  isFilterCandidate,
  en as siriusComponentsTreesEn,
  ru as siriusComponentsTreesRu,
  splitText,
  treeItemContextMenuEntryExtensionPoint,
  treeViewTreeConverterExtensionPoint
};
