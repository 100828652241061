import IconButton from "@mui/material/IconButton";
import useAuth from "../application/AuthProvider";
import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PersonIcon from '@mui/icons-material/Person';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export const UserMenu = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { user, login, logout } = useAuth();
  
    if (!user) {
      return (
        <Button variant="contained" onClick={login}>
          Вход
        </Button>
      );
    }
  
    return (
      <>
        <PersonIcon />
        <Typography id="header-username">{user?.name}</Typography>
        <IconButton
          id="nav-user-menu-button"
          onClick={event => setAnchorEl(event.currentTarget)}
          color="inherit"
          aria-controls="menu-appbar"
          aria-haspopup="true">
          <ExpandMore />
        </IconButton>
        <Menu
          className="header-menu"
          id="menu-appbar"
          anchorEl={anchorEl}
          open={anchorEl !== undefined}
          onClose={() => setAnchorEl(undefined)}>
          <MenuItem onClick={logout}>Выход</MenuItem>
        </Menu>
      </>
    );
  };
  