import { jsxs, Fragment, jsx } from "react/jsx-runtime";
import { gql, useSubscription } from "@apollo/client";
import { IconOverlay, Toast } from "@eclipse-sirius/sirius-components-core";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "tss-react/mui";
import CropDinIcon from "@mui/icons-material/CropDin";
import { useMachine } from "@xstate/react";
import { useEffect } from "react";
import { Machine, assign } from "xstate";
const isSelectionRefreshedEventPayload = (payload) => payload.__typename === "SelectionRefreshedEventPayload";
const selectionDialogMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      selection: null,
      message: null,
      selectedObjectId: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      selectionDialog: {
        initial: "idle",
        states: {
          idle: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  cond: "isSelectionRefreshedEventPayload",
                  target: "ready",
                  actions: "handleSubscriptionResult"
                }
              ]
            }
          },
          ready: {
            on: {
              HANDLE_SELECTION_UPDATED: [
                {
                  target: "ready",
                  actions: "handleSelectionUpdated"
                }
              ],
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  cond: "isSelectionRefreshedEventPayload",
                  target: "ready",
                  actions: "handleSubscriptionResult"
                }
              ],
              HANDLE_COMPLETE: {
                target: "complete"
              }
            }
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    guards: {
      isSelectionRefreshedEventPayload: (_, event) => {
        const { result } = event;
        const { data } = result;
        return !!data && isSelectionRefreshedEventPayload(data.selectionEvent);
      }
    },
    actions: {
      handleSelectionUpdated: assign((_, event) => {
        const { selectedObjectId } = event;
        return { selectedObjectId };
      }),
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (data && isSelectionRefreshedEventPayload(data.selectionEvent)) {
          const { selection } = data.selectionEvent;
          return { selection };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const selectionEventSubscription = gql`
  subscription selectionEvent($input: SelectionEventInput!) {
    selectionEvent(input: $input) {
      __typename
      ... on SelectionRefreshedEventPayload {
        selection {
          id
          targetObjectId
          message
          objects {
            id
            label
            iconURL
          }
        }
      }
    }
  }
`;
const useSelectionObjectModalStyles = makeStyles()((_theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 300
  }
}));
const SELECTION_DIALOG_TYPE = "selectionDialogDescription";
const SelectionDialog = ({
  editingContextId,
  dialogDescriptionId,
  targetObjectId,
  onClose,
  onFinish
}) => {
  const { classes } = useSelectionObjectModalStyles();
  const [{ value, context }, dispatch] = useMachine(
    selectionDialogMachine
  );
  const { toast, selectionDialog } = value;
  const { id, selection, message, selectedObjectId } = context;
  const { error } = useSubscription(selectionEventSubscription, {
    variables: {
      input: {
        id,
        editingContextId,
        selectionId: dialogDescriptionId,
        targetObjectId
      }
    },
    fetchPolicy: "no-cache",
    skip: selectionDialog === "complete",
    onData: ({ data }) => {
      const handleDataEvent = {
        type: "HANDLE_SUBSCRIPTION_RESULT",
        result: data
      };
      dispatch(handleDataEvent);
    },
    onComplete: () => {
      const completeEvent = { type: "HANDLE_COMPLETE" };
      dispatch(completeEvent);
    }
  });
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (selectionDialog === "complete") {
      onClose();
    }
  }, [selectionDialog, onClose]);
  const handleListItemClick = (selectedObjectId2) => {
    dispatch({ type: "HANDLE_SELECTION_UPDATED", selectedObjectId: selectedObjectId2 });
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Dialog,
      {
        open: true,
        onClose,
        "aria-labelledby": "dialog-title",
        maxWidth: "xs",
        fullWidth: true,
        "data-testid": "selection-dialog",
        children: [
          /* @__PURE__ */ jsx(DialogTitle, { id: "selection-dialog-title", children: "Selection Dialog" }),
          /* @__PURE__ */ jsxs(DialogContent, { children: [
            /* @__PURE__ */ jsx(DialogContentText, { "data-testid": "selection-dialog-message", children: selection == null ? void 0 : selection.message }),
            /* @__PURE__ */ jsx(List, { className: classes.root, children: selection == null ? void 0 : selection.objects.map((selectionObject) => /* @__PURE__ */ jsxs(
              ListItem,
              {
                button: true,
                selected: selectedObjectId === selectionObject.id,
                onClick: () => handleListItemClick(selectionObject.id),
                "data-testid": selectionObject.label,
                children: [
                  /* @__PURE__ */ jsx(ListItemIcon, { children: selectionObject.iconURL.length > 0 ? /* @__PURE__ */ jsx(
                    IconOverlay,
                    {
                      iconURL: selectionObject.iconURL,
                      alt: selectionObject.label,
                      customIconHeight: 24,
                      customIconWidth: 24
                    }
                  ) : /* @__PURE__ */ jsx(CropDinIcon, { style: { fontSize: 24 } }) }),
                  /* @__PURE__ */ jsx(ListItemText, { primary: selectionObject.label })
                ]
              },
              `item-${selectionObject.id}`
            )) })
          ] }),
          /* @__PURE__ */ jsx(DialogActions, { children: /* @__PURE__ */ jsx(
            Button,
            {
              variant: "contained",
              disabled: selectedObjectId === null,
              "data-testid": "finish-action",
              color: "primary",
              onClick: () => {
                if (selectedObjectId) {
                  onFinish([{ name: "selectedObject", value: selectedObjectId, type: "OBJECT_ID" }]);
                }
              },
              children: "Finish"
            }
          ) })
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: message ?? "",
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
export {
  SELECTION_DIALOG_TYPE,
  SelectionDialog
};
