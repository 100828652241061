import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface AuthContextType {
  user?: User;
  error?: unknown;
  login: () => void;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  service: AuthService;
  children: ReactNode | ReactNode[] | null;
}

export function AuthProvider({ service, children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<unknown>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const location = useLocation();

  const LOGOUT_PAGE = '/logout-page';
  const LICENSE_ERROR_PAGE = '/license-error-page';

  useEffect(() => {
    const interval = setInterval(() => {
      // This will be called every 1 minute
      if (user !== undefined) {
        checkAuthTimer();
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkAuth();
  }, [location.pathname]);

  function checkAuth() {
    service
      .getUser()
      .then((u) => {
          setUser(u);
          setError(null);
      })
      .catch((e) => {
        if (e.status === 401 && window.location.pathname !== LOGOUT_PAGE) {
          window.location.assign(LOGOUT_PAGE);
          return;
        }
        if (e.status === 429 && window.location.pathname !== LICENSE_ERROR_PAGE) {
          window.location.assign(LICENSE_ERROR_PAGE);
          return;
        }
          setError(e);
      })
      .finally(() => {
          setLoaded(true);
      });
  }

  function checkAuthTimer() {
    service
      .getUser()
      .then((u) => {
        if (user === undefined || u.id !== user?.id) {
          setUser(u);
          setError(null);
        }
      })
      .catch(() => {
        window.location.assign(LOGOUT_PAGE);
      })
      .finally(() => {
        if (!loaded) {
          setLoaded(true);
        }
      });
  }

  function login() {
    service.login(window.location.href);
  }

  async function logout() {
    await service.logout();
    setUser(undefined);
  }

  const memoedValue = useMemo(
    () => ({
      user,
      error,
      login,
      logout
    }),
    [user, error]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}

export interface AuthService {
  getUser: () => Promise<User>;
  login: (uri: string) => void;
  logout: () => void;
}

export interface User {
  id: string;
  name: string;
  email: string;
  firstNameAndLastName: string;
  fullNameAndLogin: string;
  timeZone: string;
  _links: {
    self: {
      href: string;
    };
  };
}

export class WebPmtAuthService {
  httpOrigin: string;

  constructor(httpOrigin: string) {
    this.httpOrigin = httpOrigin;
  }

  async getUser() {
    const response = await fetch(`${this.httpOrigin}/api/users/current`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      return response.json();
    }
    if (response.status === 204) {
      return null;
    }
    throw new ApiError(response.status, 'Get user failed');
  }

  login(uri: string) {
    window.location.assign(`${this.httpOrigin}/authenticate?uri=${uri}`);
  }

  logout() {
    window.location.assign(`${this.httpOrigin}/logout`);
  }
}

export class ApiError extends Error {
  status: number;
  data: unknown;

  constructor(status: number, data: unknown) {
    super('HTTP status ' + status);
    this.name = this.constructor.name;
    this.status = status;
    this.data = data;
  }
}
