import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import { gql, useMutation, useSubscription } from "@apollo/client";
import { getCSSColor, ServerContext, useSelection, Toast, useData, useDeletionConfirmationDialog } from "@eclipse-sirius/sirius-components-core";
import Typography from "@mui/material/Typography";
import { makeStyles, withStyles } from "tss-react/mui";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import WebIcon from "@mui/icons-material/Web";
import { useMachine } from "@xstate/react";
import React, { useContext, useState, useEffect, useRef, createElement } from "react";
import { widgetFields, getTextDecorationLineValue, widgetContributionExtensionPoint } from "@eclipse-sirius/sirius-components-forms";
import { Machine, assign } from "xstate";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Button$1 from "@mui/material/Button";
import { BarChart, PieChart } from "@eclipse-sirius/sirius-components-charts";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ExtensionIcon from "@mui/icons-material/Extension";
import TextField from "@mui/material/TextField";
import ImageIcon from "@mui/icons-material/Image";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CodeIcon from "@mui/icons-material/Code";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import SubjectIcon from "@mui/icons-material/Subject";
import TitleIcon from "@mui/icons-material/Title";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import Slider from "@mui/material/Slider";
import { useMutation as useMutation$1 } from "@apollo/client/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EventIcon from "@mui/icons-material/Event";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import LinearScaleOutlinedIcon from "@mui/icons-material/LinearScaleOutlined";
import LinkIcon from "@mui/icons-material/Link";
import PieChartIcon from "@mui/icons-material/PieChart";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import SvgIcon from "@mui/material/SvgIcon";
const widgetContainerContent = (depth) => {
  if (depth === 0) {
    return "...widgetFields";
  } else {
    const children = widgetContainerContent(depth - 1);
    return `
      ...widgetFields
      ... on FlexboxContainer {
        ...flexboxContainerProperFields
        children {
          ${children}
        }
      }
      ... on FormDescriptionEditorIf {
        ...ifProperFields
        children {
          ${children}
        }
      }
      ... on FormDescriptionEditorFor {
        ...forProperFields
        children {
          ${children}
        }
      }
    `;
  }
};
const formDescriptionEditorEventSubscription = `
  ${widgetFields}

  fragment ifProperFields on FormDescriptionEditorIf {
    ...commonFields
    label
  }

  fragment forProperFields on FormDescriptionEditorFor {
    ...commonFields
    label
  }

  fragment flexboxContainerProperFields on FlexboxContainer {
    ...commonFields
    label
    flexDirection
    flexWrap
    flexGrow
    borderStyle {
      color
      lineStyle
      size
      radius
    }
  }

  subscription formDescriptionEditorEvent($input: FormDescriptionEditorEventInput!) {
    formDescriptionEditorEvent(input: $input) {
      __typename
      ... on ErrorPayload {
        id
        message
      }
      ... on FormDescriptionEditorRefreshedEventPayload {
        id
        formDescriptionEditor {
          id
          pages {
            id
            label
            toolbarActions {
              ...commonFields
              ...toolbarActionFields
            }
            groups {
              id
              label
              displayMode
              borderStyle {
                color
                lineStyle
                size
                radius
              }
              toolbarActions {
                ...commonFields
                ...toolbarActionFields
              }
              widgets {
                ${widgetContainerContent(4)}
              }
            }
          }
        }
      }
    }
  }
`;
const addGroupMutation = gql`
  mutation addGroup($input: AddGroupInput!) {
    addGroup(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const deleteGroupMutation = gql`
  mutation deleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const moveGroupMutation = gql`
  mutation moveGroup($input: MoveGroupInput!) {
    moveGroup(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const addWidgetMutation = gql`
  mutation addWidget($input: AddWidgetInput!) {
    addWidget(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const deleteWidgetMutation = gql`
  mutation deleteWidget($input: DeleteWidgetInput!) {
    deleteWidget(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const moveWidgetMutation = gql`
  mutation moveWidget($input: MoveWidgetInput!) {
    moveWidget(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const addToolbarActionMutation = gql`
  mutation addToolbarAction($input: AddToolbarActionInput!) {
    addToolbarAction(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const deleteToolbarActionMutation = gql`
  mutation deleteToolbarAction($input: DeleteToolbarActionInput!) {
    deleteToolbarAction(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const moveToolbarActionMutation = gql`
  mutation moveToolbarAction($input: MoveToolbarActionInput!) {
    moveToolbarAction(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const addPageMutation = gql`
  mutation addPage($input: AddPageInput!) {
    addPage(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const movePageMutation = gql`
  mutation movePage($input: MovePageInput!) {
    movePage(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const deletePageMutation = gql`
  mutation deletePage($input: DeletePageInput!) {
    deletePage(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isFormDescriptionEditorRefreshedEventPayload = (payload) => payload.__typename === "FormDescriptionEditorRefreshedEventPayload";
const formDescriptionEditorRepresentationMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      formDescriptionEditor: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      formDescriptionEditorRepresentation: {
        initial: "loading",
        states: {
          empty: {},
          loading: {
            on: {
              INITIALIZE: [
                {
                  target: "ready",
                  actions: "initialize"
                }
              ]
            }
          },
          ready: {
            on: {
              HANDLE_SUBSCRIPTION_RESULT: [
                {
                  actions: "handleSubscriptionResult"
                }
              ],
              HANDLE_COMPLETE: [
                {
                  target: "complete",
                  actions: "handleComplete"
                }
              ]
            }
          },
          complete: {}
        }
      }
    }
  },
  {
    actions: {
      initialize: assign((_) => {
        return {
          message: void 0
        };
      }),
      handleComplete: assign((_) => {
        return {};
      }),
      handleSubscriptionResult: assign((_, event) => {
        const { result } = event;
        const { data } = result;
        if (isFormDescriptionEditorRefreshedEventPayload(data.formDescriptionEditorEvent)) {
          const { formDescriptionEditor } = data.formDescriptionEditorEvent;
          return { formDescriptionEditor };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const defaultValue = {
  editingContextId: "",
  representationId: "",
  readOnly: false,
  formDescriptionEditor: { id: "", pages: [] }
};
const FormDescriptionEditorContext = React.createContext(defaultValue);
const FormDescriptionEditorContextProvider = ({
  editingContextId,
  representationId,
  formDescriptionEditor,
  readOnly,
  children
}) => {
  const value = {
    editingContextId,
    representationId,
    formDescriptionEditor,
    readOnly
  };
  return /* @__PURE__ */ jsx(FormDescriptionEditorContext.Provider, { value, children });
};
const useFormDescriptionEditor = () => {
  return useContext(FormDescriptionEditorContext);
};
const useStyles$i = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough, iconOnly }) => ({
    style: {
      minWidth: "32px",
      lineHeight: 1.25,
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : theme.palette.primary.light,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : "white",
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough),
      "&:hover": {
        backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : theme.palette.primary.main,
        color: foregroundColor ? getCSSColor(foregroundColor, theme) : "white",
        fontSize: fontSize ? fontSize : null,
        fontStyle: italic ? "italic" : null,
        fontWeight: bold ? "bold" : null,
        textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
      }
    },
    selected: {
      minWidth: "32px",
      lineHeight: 1.25,
      backgroundColor: theme.palette.secondary.light,
      color: "white",
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough),
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white",
        fontSize: fontSize ? fontSize : null,
        fontStyle: italic ? "italic" : null,
        fontWeight: bold ? "bold" : null,
        textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
      }
    },
    toolbarAction: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1
    },
    placeholder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "whitesmoke",
      border: "1px solid whitesmoke",
      borderRadius: "5px",
      height: "inherit",
      width: "20px"
    },
    dragOver: {
      borderWidth: "1px",
      borderStyle: "dashed",
      borderColor: theme.palette.primary.main
    },
    icon: {
      marginRight: iconOnly ? theme.spacing(0) : theme.spacing(2)
    }
  })
);
const isErrorPayload$7 = (payload) => payload.__typename === "ErrorPayload";
const ToolbarActionWidget = ({ toolbarActions, containerId, toolbarAction }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const initialState = {
    buttonLabel: toolbarAction.buttonLabel,
    imageURL: toolbarAction.imageURL,
    validImage: false,
    message: null,
    selected: false
  };
  const [state, setState] = useState(initialState);
  const props = {
    backgroundColor: ((_a = toolbarAction.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = toolbarAction.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = toolbarAction.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = toolbarAction.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = toolbarAction.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = toolbarAction.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = toolbarAction.style) == null ? void 0 : _g.strikeThrough) ?? null,
    iconOnly: state.buttonLabel ? false : true
  };
  const { classes } = useStyles$i(props);
  const { httpOrigin } = useContext(ServerContext);
  const { selection, setSelection } = useSelection();
  const onErrorLoadingImage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        validImage: false
      };
    });
  };
  useEffect(() => {
    let newURL = null;
    let validURL = true;
    if (!toolbarAction.imageURL) {
      validURL = false;
    } else if (toolbarAction.imageURL.startsWith("http://") || toolbarAction.imageURL.startsWith("https://")) {
      newURL = toolbarAction.imageURL;
    } else {
      newURL = httpOrigin + toolbarAction.imageURL;
    }
    const buttonLabel = toolbarAction.buttonLabel;
    const isButtonLabelBlank = buttonLabel == null || buttonLabel.trim() === "";
    let newButtonLabel = null;
    if (validURL && isButtonLabelBlank) {
      newButtonLabel = null;
    } else if (!isButtonLabelBlank && !buttonLabel.startsWith("aql:")) {
      newButtonLabel = buttonLabel;
    } else {
      newButtonLabel = "Lorem";
    }
    setState((prevState) => {
      return {
        ...prevState,
        buttonLabel: newButtonLabel,
        imageURL: newURL,
        validImage: validURL
      };
    });
  }, [toolbarAction.imageURL, toolbarAction.buttonLabel]);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === toolbarAction.id)) {
      ref.current.focus();
      setState((prevState) => {
        return {
          ...prevState,
          selected: true
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          selected: false
        };
      });
    }
  }, [selection, toolbarAction]);
  const [
    deleteToolbarAction,
    { loading: deleteToolbarActionLoading, data: deleteToolbarActionData, error: deleteToolbarActionError }
  ] = useMutation(
    deleteToolbarActionMutation
  );
  useEffect(() => {
    if (!deleteToolbarActionLoading) {
      if (deleteToolbarActionError) {
        setState((prevState) => {
          return {
            ...prevState,
            message: deleteToolbarActionError.message
          };
        });
      }
      if (deleteToolbarActionData) {
        const { deleteToolbarAction: deleteToolbarAction2 } = deleteToolbarActionData;
        if (isErrorPayload$7(deleteToolbarAction2)) {
          setState((prevState) => {
            return {
              ...prevState,
              message: deleteToolbarAction2.message
            };
          });
        }
      }
    }
  }, [deleteToolbarActionLoading, deleteToolbarActionData, deleteToolbarActionError]);
  const [
    moveToolbarAction,
    { loading: moveToolbarActionLoading, data: moveToolbarActionData, error: moveToolbarActionError }
  ] = useMutation(moveToolbarActionMutation);
  useEffect(() => {
    if (!moveToolbarActionLoading) {
      if (moveToolbarActionError) {
        setState((prevState) => {
          return {
            ...prevState,
            message: moveToolbarActionError.message
          };
        });
      }
      if (moveToolbarActionData) {
        const { moveToolbarAction: moveToolbarAction2 } = moveToolbarActionData;
        if (isErrorPayload$7(moveToolbarAction2)) {
          setState((prevState) => {
            return {
              ...prevState,
              message: moveToolbarAction2.message
            };
          });
        }
      }
    }
  }, [moveToolbarActionLoading, moveToolbarActionData, moveToolbarActionError]);
  const handleDragStart = (event) => {
    event.dataTransfer.setData("draggedElementId", toolbarAction.id);
    event.dataTransfer.setData("draggedElementType", "ToolbarActionWidget");
    event.stopPropagation();
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    onDropBefore(event, toolbarAction);
  };
  const onDropBefore = (event, toolbarAction2) => {
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "ToolbarActionWidget") {
      return;
    }
    let index = toolbarActions.indexOf(toolbarAction2);
    if (index <= 0) {
      index = 0;
    }
    const movedToolbarActionIndex = toolbarActions.findIndex((tba) => tba.id === id);
    if (movedToolbarActionIndex > -1 && movedToolbarActionIndex < index) {
      index--;
    }
    const moveToolbarActionInput = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      containerId,
      toolbarActionId: id,
      index
    };
    const moveToolbarActionVariables = { input: moveToolbarActionInput };
    moveToolbarAction({ variables: moveToolbarActionVariables });
  };
  const handleClick = (event) => {
    const newSelection = {
      entries: [
        {
          id: toolbarAction.id,
          kind: `siriusComponents://semantic?domain=view&entity=${toolbarAction.__typename}Description`
        }
      ]
    };
    setSelection(newSelection);
    event.stopPropagation();
  };
  const handleDelete = (event) => {
    event.preventDefault();
    if (event.key === "Delete") {
      const deleteToolbarActionInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        toolbarActionId: toolbarAction.id
      };
      const deleteToolbarActionVariables = { input: deleteToolbarActionInput };
      deleteToolbarAction({ variables: deleteToolbarActionVariables });
      event.stopPropagation();
    }
  };
  return /* @__PURE__ */ jsxs("div", { className: classes.toolbarAction, children: [
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `ToolbarAction-DropArea-${toolbarAction.id}`,
        className: classes.placeholder,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop
      }
    ),
    /* @__PURE__ */ jsx(
      "div",
      {
        onClick: readOnly ? noop : handleClick,
        onKeyDown: readOnly ? noop : handleDelete,
        draggable: !readOnly,
        onDragStart: readOnly ? noop : handleDragStart,
        children: /* @__PURE__ */ jsxs(
          Button$1,
          {
            "data-testid": toolbarAction.label,
            classes: state.selected ? { root: classes.selected } : { root: classes.style },
            variant: "contained",
            onFocus: () => setState((prevState) => {
              return {
                ...prevState,
                selected: true
              };
            }),
            onBlur: () => setState((prevState) => {
              return {
                ...prevState,
                selected: false
              };
            }),
            ref,
            children: [
              state.validImage && state.imageURL ? /* @__PURE__ */ jsx(
                "img",
                {
                  className: classes.icon,
                  width: "16",
                  height: "16",
                  alt: toolbarAction.label,
                  src: state.imageURL,
                  onError: onErrorLoadingImage
                }
              ) : null,
              state.buttonLabel
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: state.message,
        open: !!state.message,
        onClose: () => setState((prevState) => {
          return {
            ...prevState,
            message: null
          };
        })
      }
    )
  ] });
};
const useToolbarActionsStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  toolbarAction: {
    whiteSpace: "nowrap"
  },
  toolbarActionDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    border: "1px solid whitesmoke",
    borderRadius: "5px",
    width: "20px",
    height: "30px"
  },
  newToolbarAction: {
    paddingLeft: theme.spacing(1)
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  }
}));
const isErrorPayload$6 = (payload) => payload.__typename === "ErrorPayload";
const ToolbarActions = ({ toolbarActions, containerId }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = useToolbarActionsStyles();
  const [message, setMessage] = useState(null);
  const [
    addToolbarAction,
    { loading: addToolbarActionLoading, data: addToolbarActionData, error: addToolbarActionError }
  ] = useMutation(addToolbarActionMutation);
  useEffect(() => {
    if (!addToolbarActionLoading) {
      if (addToolbarActionError) {
        setMessage(addToolbarActionError.message);
      }
      if (addToolbarActionData) {
        const { addToolbarAction: addToolbarAction2 } = addToolbarActionData;
        if (isErrorPayload$6(addToolbarAction2)) {
          setMessage(addToolbarAction2.message);
        }
      }
    }
  }, [addToolbarActionLoading, addToolbarActionData, addToolbarActionError]);
  const [
    moveToolbarAction,
    { loading: moveToolbarActionLoading, data: moveToolbarActionData, error: moveToolbarActionError }
  ] = useMutation(moveToolbarActionMutation);
  useEffect(() => {
    if (!moveToolbarActionLoading) {
      if (moveToolbarActionError) {
        setMessage(moveToolbarActionError.message);
      }
      if (moveToolbarActionData) {
        const { moveToolbarAction: moveToolbarAction2 } = moveToolbarActionData;
        if (isErrorPayload$6(moveToolbarAction2)) {
          setMessage(moveToolbarAction2.message);
        }
      }
    }
  }, [moveToolbarActionLoading, moveToolbarActionData, moveToolbarActionError]);
  const handleAddToolbarAction = () => {
    const addToolbarActionInput = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      containerId
    };
    const addToolbarActionVariables = { input: addToolbarActionInput };
    addToolbarAction({ variables: addToolbarActionVariables });
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "ToolbarActionWidget") {
      return;
    }
    let index = toolbarActions.length;
    if (toolbarActions.find((tba) => tba.id === id)) {
      index--;
    }
    const moveToolbarActionInput = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId,
      containerId,
      toolbarActionId: id,
      index
    };
    const moveToolbarActionVariables = { input: moveToolbarActionInput };
    moveToolbarAction({ variables: moveToolbarActionVariables });
  };
  return /* @__PURE__ */ jsxs("div", { className: classes.toolbar, children: [
    toolbarActions.map((toolbarAction) => /* @__PURE__ */ jsx("div", { className: classes.toolbarAction, children: /* @__PURE__ */ jsx(
      ToolbarActionWidget,
      {
        "data-testid": toolbarAction.id,
        toolbarActions,
        containerId,
        toolbarAction
      }
    ) }, toolbarAction.id)),
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `ToolbarActions-DropArea-${containerId}`,
        className: classes.toolbarActionDropArea,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop
      }
    ),
    /* @__PURE__ */ jsx("div", { className: classes.newToolbarAction, children: /* @__PURE__ */ jsx(Tooltip, { title: "Add new Toolbar Action", arrow: true, "data-testid": `ToolbarActions-NewAction-${containerId}`, children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "add", color: "primary", onClick: readOnly ? noop : handleAddToolbarAction, children: /* @__PURE__ */ jsx(AddIcon, {}) }) }) }),
    /* @__PURE__ */ jsx(Toast, { message, open: !!message, onClose: () => setMessage(null) })
  ] });
};
const useStyles$h = makeStyles()((theme) => ({
  selected: {
    color: theme.palette.primary.main
  },
  chart: {
    overflowX: "auto"
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const BarChartWidget = ({ widget }) => {
  const { classes } = useStyles$h();
  const barChartWidget = widget.chart;
  const chart = {
    metadata: {
      label: "Frequency"
    },
    entries: [
      { key: "A", value: 0.08167 },
      { key: "B", value: 0.01492 },
      { key: "C", value: 0.02782 },
      { key: "D", value: 0.04253 },
      { key: "E", value: 0.12702 },
      { key: "F", value: 0.02288 },
      { key: "G", value: 0.02015 },
      { key: "H", value: 0.06094 },
      { key: "I", value: 0.06966 },
      { key: "J", value: 153e-5 },
      { key: "K", value: 772e-5 },
      { key: "L", value: 0.04025 },
      { key: "M", value: 0.02406 },
      { key: "N", value: 0.06749 },
      { key: "O", value: 0.07507 },
      { key: "P", value: 0.01929 },
      { key: "Q", value: 95e-5 },
      { key: "R", value: 0.05987 },
      { key: "S", value: 0.06327 },
      { key: "T", value: 0.09056 },
      { key: "U", value: 0.02758 },
      { key: "V", value: 978e-5 },
      { key: "W", value: 0.0236 },
      { key: "X", value: 15e-4 },
      { key: "Y", value: 0.01974 },
      { key: "Z", value: 74e-5 }
    ],
    style: barChartWidget.style,
    width: barChartWidget.width,
    height: barChartWidget.height
  };
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs(
    "div",
    {
      "data-testid": barChartWidget.metadata.label,
      onFocus: () => setSelected(true),
      onBlur: () => setSelected(false),
      ref,
      tabIndex: 0,
      children: [
        /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
          widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
        ] }),
        /* @__PURE__ */ jsx("div", { className: classes.chart, children: /* @__PURE__ */ jsx(BarChart, { chart }) })
      ]
    }
  );
};
const useStyles$g = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough, iconOnly }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : theme.palette.primary.light,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : "white",
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough),
      "&:hover": {
        backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : theme.palette.primary.main,
        color: foregroundColor ? getCSSColor(foregroundColor, theme) : "white",
        fontSize: fontSize ? fontSize : null,
        fontStyle: italic ? "italic" : null,
        fontWeight: bold ? "bold" : null,
        textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
      }
    },
    icon: {
      marginRight: iconOnly ? theme.spacing(0) : theme.spacing(2)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const ButtonWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const initialState = {
    buttonLabel: widget.buttonLabel,
    imageURL: widget.imageURL,
    validImage: false,
    selected: false
  };
  const [state, setState] = useState(initialState);
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = widget.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = widget.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = widget.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = widget.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = widget.style) == null ? void 0 : _g.strikeThrough) ?? null,
    iconOnly: state.buttonLabel ? false : true
  };
  const { classes } = useStyles$g(props);
  const { httpOrigin } = useContext(ServerContext);
  const { selection } = useSelection();
  const onErrorLoadingImage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        validImage: false
      };
    });
  };
  useEffect(() => {
    let newURL = null;
    let validURL = true;
    if (!widget.imageURL) {
      validURL = false;
    } else if (widget.imageURL.startsWith("http://") || widget.imageURL.startsWith("https://")) {
      newURL = widget.imageURL;
    } else {
      newURL = httpOrigin + widget.imageURL;
    }
    const buttonLabel = widget.buttonLabel;
    const isButtonLabelBlank = buttonLabel == null || buttonLabel.trim() === "";
    let newButtonLabel = null;
    if (validURL && isButtonLabelBlank) {
      newButtonLabel = null;
    } else if (!isButtonLabelBlank && !buttonLabel.startsWith("aql:")) {
      newButtonLabel = buttonLabel;
    } else {
      newButtonLabel = "Lorem";
    }
    setState((prevState) => {
      return {
        ...prevState,
        buttonLabel: newButtonLabel,
        imageURL: newURL,
        validImage: validURL
      };
    });
  }, [widget.imageURL, widget.buttonLabel]);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setState((prevState) => {
        return {
          ...prevState,
          selected: true
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          selected: false
        };
      });
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: state.selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsxs(
      Button$1,
      {
        "data-testid": widget.label,
        classes: { root: classes.style },
        variant: "contained",
        onFocus: () => setState((prevState) => {
          return {
            ...prevState,
            selected: true
          };
        }),
        onBlur: () => setState((prevState) => {
          return {
            ...prevState,
            selected: false
          };
        }),
        ref,
        children: [
          state.validImage && state.imageURL ? /* @__PURE__ */ jsx(
            "img",
            {
              className: classes.icon,
              width: "16",
              height: "16",
              alt: widget.label,
              src: state.imageURL,
              onError: onErrorLoadingImage
            }
          ) : null,
          state.buttonLabel
        ]
      }
    )
  ] });
};
const useStyles$f = makeStyles()((theme, { color }) => ({
  style: {
    color: color ? getCSSColor(color, theme) : theme.palette.primary.light,
    "&.Mui-checked": {
      color: color ? getCSSColor(color, theme) : theme.palette.primary.light
    }
  },
  selected: {
    color: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const CheckboxWidget = ({ widget }) => {
  var _a, _b;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null
  };
  const { classes } = useStyles$f(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsx(
    FormControlLabel,
    {
      labelPlacement: ((_b = widget.style) == null ? void 0 : _b.labelPlacement) ?? "end",
      label: /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
        widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
      ] }),
      control: /* @__PURE__ */ jsx(
        Checkbox,
        {
          "data-testid": widget.label,
          checked: true,
          inputRef: ref,
          onFocus: () => setSelected(true),
          onBlur: () => setSelected(false),
          classes: { root: classes.style }
        }
      )
    }
  );
};
const useStyles$e = makeStyles()((theme) => ({
  style: {
    color: theme.palette.secondary.main
  },
  selected: {
    color: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const CustomWidget = ({ widget }) => {
  const props = {};
  const { classes } = useStyles$e(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      "div",
      {
        ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        tabIndex: 0,
        className: classes.style,
        children: /* @__PURE__ */ jsx(ExtensionIcon, {})
      }
    )
  ] });
};
const useDataTimeWidgetStyles = makeStyles()(
  (theme, { backgroundColor, foregroundColor, italic, bold }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : null,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null
    },
    textfield: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    selected: {
      color: theme.palette.primary.main
    },
    input: {}
  })
);
const DateTimeWidget = ({ widget }) => {
  var _a, _b, _c, _d;
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null
  };
  const { classes } = useDataTimeWidgetStyles(props);
  const [state, setState] = useState({
    selected: false
  });
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setState((prevState) => ({ ...prevState, selected: true }));
    } else {
      setState((prevState) => ({ ...prevState, selected: false }));
    }
  }, [selection, widget]);
  const { value, type } = getValueAndType(widget.type);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: state.selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      TextField,
      {
        variant: "standard",
        id: "datetime",
        type,
        value,
        className: classes.textfield,
        onFocus: () => setState((prevState) => ({ ...prevState, selected: true })),
        onBlur: () => setState((prevState) => ({ ...prevState, selected: false })),
        InputProps: widget.style ? {
          className: classes.style
        } : {},
        inputProps: {
          "data-testid": `datetime-${widget.label}`,
          className: classes.input
        }
      }
    )
  ] });
};
const getValueAndType = (dateTimeType) => {
  const dateTime = /* @__PURE__ */ new Date();
  const year = String(dateTime.getFullYear()).padStart(4, "0");
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  if (dateTimeType === "DATE") {
    return { value: `${year}-${month}-${day}`, type: "date" };
  } else if (dateTimeType === "TIME") {
    return { value: `${hours}:${minutes}`, type: "time" };
  }
  return { value: `${year}-${month}-${day}T${hours}:${minutes}`, type: "datetime-local" };
};
const isKind = (value) => {
  return value === "Textfield" || value === "TextArea" || value === "Checkbox" || value === "Radio" || value === "Select" || value === "MultiSelect" || value === "Button" || value === "SplitButton" || value === "Label" || value === "Link" || value === "List" || value === "Tree" || value === "BarChart" || value === "PieChart" || value === "FlexboxContainer" || value === "Image" || value === "RichText" || value === "Slider" || value === "DateTime" || value === "FormElementFor" || value === "FormElementIf";
};
const isGroup = (element) => {
  return element.__typename === "Group";
};
const isFlexboxContainer = (element) => {
  return element.__typename === "FlexboxContainer";
};
const isErrorPayload$5 = (payload) => payload.__typename === "ErrorPayload";
const useStyles$d = makeStyles()((theme, { flexDirection, flexWrap, borderStyle }) => ({
  selected: {
    color: theme.palette.primary.main
  },
  containerAndLabel: {
    margin: borderStyle ? theme.spacing(0.5) : 0,
    padding: borderStyle ? theme.spacing(0.5) : 0,
    borderWidth: (borderStyle == null ? void 0 : borderStyle.size) || 1,
    borderColor: getCSSColor(borderStyle == null ? void 0 : borderStyle.color, theme) || "gray",
    borderStyle: (borderStyle == null ? void 0 : borderStyle.lineStyle) || "solid",
    borderRadius: (borderStyle == null ? void 0 : borderStyle.radius) || 0
  },
  container: {
    display: "flex",
    flexWrap,
    flexDirection,
    "& > *": {
      marginBottom: theme.spacing(0)
    },
    paddingLeft: theme.spacing(1)
  },
  bottomDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "gray",
    height: "30px",
    width: "100%"
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const FlexboxContainerWidget = ({ page, widget }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = useStyles$d({
    flexDirection: widget.flexDirection,
    flexWrap: widget.flexWrap,
    borderStyle: widget.borderStyle
  });
  const initialState = { message: null, selected: false };
  const [state, setState] = useState(initialState);
  const { message, selected } = state;
  const { selection } = useSelection();
  const [addWidget, { loading: addWidgetLoading, data: addWidgetData, error: addWidgetError }] = useMutation(addWidgetMutation);
  useEffect(() => {
    if (!addWidgetLoading) {
      if (addWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: addWidgetError.message };
        });
      }
      if (addWidgetData) {
        const { addWidget: addWidget2 } = addWidgetData;
        if (isErrorPayload$5(addWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: addWidget2.message };
          });
        }
      }
    }
  }, [addWidgetLoading, addWidgetData, addWidgetError]);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setState((prevState) => {
        return { ...prevState, selected: true };
      });
    } else {
      setState((prevState) => {
        return { ...prevState, selected: false };
      });
    }
  }, [selection, widget]);
  const [moveWidget, { loading: moveWidgetLoading, data: moveWidgetData, error: moveWidgetError }] = useMutation(moveWidgetMutation);
  useEffect(() => {
    if (!moveWidgetLoading) {
      if (moveWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: moveWidgetError.message };
        });
      }
      if (moveWidgetData) {
        const { moveWidget: moveWidget2 } = moveWidgetData;
        if (isErrorPayload$5(moveWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: moveWidget2.message };
          });
        }
      }
    }
  }, [moveWidgetLoading, moveWidgetData, moveWidgetError]);
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const { data: widgetContributions } = useData(widgetContributionExtensionPoint);
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Widget") {
      return;
    }
    let index = widget.children.length || 0;
    if (isKind(id) || widgetContributions.find((widgetContribution) => widgetContribution.name === id)) {
      const addWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: widget.id,
        kind: id,
        index
      };
      const addWidgetVariables = { input: addWidgetInput };
      addWidget({ variables: addWidgetVariables });
    } else {
      if (widget.children.find((w) => w.id === id)) {
        index--;
      }
      const moveWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: widget.id,
        widgetId: id,
        index
      };
      const moveWidgetVariables = { input: moveWidgetInput };
      moveWidget({ variables: moveWidgetVariables });
    }
  };
  let children = widget.children.map((childWidget) => {
    return /* @__PURE__ */ jsx(
      WidgetEntry,
      {
        page,
        container: widget,
        widget: childWidget,
        flexDirection: widget.flexDirection,
        flexGrow: widget.flexGrow
      },
      childWidget.id
    );
  });
  return /* @__PURE__ */ jsxs("div", { className: classes.containerAndLabel, tabIndex: 0, ref, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "subtitle2",
          className: selected ? classes.selected : "",
          onFocus: () => setState((prevState) => {
            return { ...prevState, selected: true };
          }),
          onBlur: () => setState((prevState) => {
            return { ...prevState, selected: false };
          }),
          children: widget.label
        }
      ),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx("div", { className: classes.container, children }),
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `${widget.__typename}-Widgets-DropArea-${widget.id}`,
        className: classes.bottomDropArea,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop,
        children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Drag and drop a widget here" })
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: !!message,
        onClose: () => setState((prevState) => {
          return { ...prevState, message: null };
        })
      }
    )
  ] });
};
const useStyles$c = makeStyles()((theme, { maxWidth }) => {
  let gridTemplateColumns;
  if (maxWidth) {
    let max = maxWidth;
    if (maxWidth.match(/[0-9]$/)) {
      max = maxWidth + "px";
    }
    gridTemplateColumns = `minmax(auto, ${max})`;
  } else {
    gridTemplateColumns = "1fr";
  }
  return {
    container: {
      display: "grid",
      gridTemplateColumns
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  };
});
const ImageWidget = ({ widget }) => {
  const props = {
    maxWidth: widget.maxWidth
  };
  const { classes } = useStyles$c(props);
  const { httpOrigin } = useContext(ServerContext);
  const initialState = { imageURL: widget.url, validImage: false, selected: false };
  const [state, setState] = useState(initialState);
  const { selection } = useSelection();
  const onErrorLoadingImage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        validImage: false
      };
    });
  };
  useEffect(() => {
    let newURL = null;
    let validURL = true;
    if (!widget.url) {
      validURL = false;
    } else if (widget.url.startsWith("http://") || widget.url.startsWith("https://")) {
      newURL = widget.url;
    } else {
      newURL = httpOrigin + widget.url;
    }
    setState((prevState) => {
      return {
        ...prevState,
        imageURL: newURL,
        validImage: validURL
      };
    });
  }, [widget.url]);
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setState((prevState) => {
        return {
          ...prevState,
          selected: true
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          selected: false
        };
      });
    }
  }, [selection, widget]);
  let imageElement = null;
  if (state.validImage && state.imageURL) {
    imageElement = /* @__PURE__ */ jsx("img", { id: widget.id, src: state.imageURL, width: "100%", onError: onErrorLoadingImage });
  } else {
    imageElement = /* @__PURE__ */ jsx(ImageIcon, { style: { fontSize: 72 }, color: "secondary" });
  }
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: state.selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      "div",
      {
        onFocus: () => setState((prevState) => {
          return {
            ...prevState,
            selected: true
          };
        }),
        onBlur: () => setState((prevState) => {
          return {
            ...prevState,
            selected: false
          };
        }),
        ref,
        tabIndex: 0,
        className: classes.container,
        children: imageElement
      }
    )
  ] });
};
const useStyles$b = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      color: color ? getCSSColor(color, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const LabelWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null,
    fontSize: ((_b = widget.style) == null ? void 0 : _b.fontSize) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null,
    underline: ((_e = widget.style) == null ? void 0 : _e.underline) ?? null,
    strikeThrough: ((_f = widget.style) == null ? void 0 : _f.strikeThrough) ?? null
  };
  const { classes } = useStyles$b(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      Typography,
      {
        ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        tabIndex: 0,
        className: classes.style,
        children: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      }
    )
  ] });
};
const useStyles$a = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      color: color ? getCSSColor(color, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const LinkWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null,
    fontSize: ((_b = widget.style) == null ? void 0 : _b.fontSize) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null,
    underline: ((_e = widget.style) == null ? void 0 : _e.underline) ?? null,
    strikeThrough: ((_f = widget.style) == null ? void 0 : _f.strikeThrough) ?? null
  };
  const { classes } = useStyles$a(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsx("div", { className: selected ? classes.selected : "", children: /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
    /* @__PURE__ */ jsx(
      Link,
      {
        ref,
        className: classes.style,
        onClick: (event) => {
          event.preventDefault();
          setSelected(true);
        },
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        tabIndex: 0,
        href: "#",
        rel: "noopener noreferrer",
        target: "_blank",
        children: widget.label
      }
    ),
    widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
  ] }) });
};
const useStyles$9 = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      color: color ? getCSSColor(color, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    icon: {
      width: "16px",
      height: "16px",
      marginRight: theme.spacing(2)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const ListWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null,
    fontSize: ((_b = widget.style) == null ? void 0 : _b.fontSize) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null,
    underline: ((_e = widget.style) == null ? void 0 : _e.underline) ?? null,
    strikeThrough: ((_f = widget.style) == null ? void 0 : _f.strikeThrough) ?? null
  };
  const { classes } = useStyles$9(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [widget, selection]);
  return /* @__PURE__ */ jsxs("div", { onFocus: () => setSelected(true), onBlur: () => setSelected(false), ref, tabIndex: 0, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(Table, { size: "small", children: /* @__PURE__ */ jsxs(TableBody, { children: [
      /* @__PURE__ */ jsxs(TableRow, { children: [
        /* @__PURE__ */ jsxs(TableCell, { children: [
          " ",
          /* @__PURE__ */ jsxs(Typography, { color: "textPrimary", className: classes.style, children: [
            /* @__PURE__ */ jsx(ImageIcon, { className: classes.icon }),
            "Value 1"
          ] })
        ] }),
        /* @__PURE__ */ jsx(TableCell, { align: "right", children: /* @__PURE__ */ jsx(IconButton, { "aria-label": "deleteListItem", children: /* @__PURE__ */ jsx(DeleteIcon, {}) }) })
      ] }),
      /* @__PURE__ */ jsxs(TableRow, { children: [
        /* @__PURE__ */ jsxs(TableCell, { children: [
          " ",
          /* @__PURE__ */ jsxs(Typography, { color: "textPrimary", className: classes.style, children: [
            /* @__PURE__ */ jsx(ImageIcon, { className: classes.icon }),
            "Value 2"
          ] })
        ] }),
        /* @__PURE__ */ jsx(TableCell, { align: "right", children: /* @__PURE__ */ jsx(IconButton, { "aria-label": "deleteListItem", children: /* @__PURE__ */ jsx(DeleteIcon, {}) }) })
      ] }),
      /* @__PURE__ */ jsxs(TableRow, { children: [
        /* @__PURE__ */ jsxs(TableCell, { children: [
          " ",
          /* @__PURE__ */ jsxs(Typography, { color: "textPrimary", className: classes.style, children: [
            /* @__PURE__ */ jsx(ImageIcon, { className: classes.icon }),
            "Value 3"
          ] })
        ] }),
        /* @__PURE__ */ jsx(TableCell, { align: "right", children: /* @__PURE__ */ jsx(IconButton, { "aria-label": "deleteListItem", children: /* @__PURE__ */ jsx(DeleteIcon, {}) }) })
      ] })
    ] }) })
  ] });
};
const useStyles$8 = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : null,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const MultiSelectWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = widget.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = widget.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = widget.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = widget.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = widget.style) == null ? void 0 : _g.strikeThrough) ?? null
  };
  const { classes } = useStyles$8(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    let cleanup = void 0;
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      const timeoutId = setTimeout(function() {
        ref.current.focus();
      }, 50);
      cleanup = () => clearTimeout(timeoutId);
      setSelected(true);
    } else {
      setSelected(false);
    }
    return cleanup;
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsxs(
      Select,
      {
        variant: "standard",
        "data-testid": widget.label,
        label: widget.label,
        multiple: true,
        fullWidth: true,
        value: ["value1", "value3"],
        renderValue: () => "Value 1, Value 3",
        inputRef: ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        inputProps: widget.style ? {
          className: classes.style
        } : {},
        children: [
          /* @__PURE__ */ jsxs(MenuItem, { value: "value1", children: [
            /* @__PURE__ */ jsx(Checkbox, { checked: true }),
            /* @__PURE__ */ jsx(
              ListItemText,
              {
                primary: "Value 1",
                primaryTypographyProps: widget.style ? {
                  className: classes.style
                } : {}
              }
            )
          ] }, "value1"),
          /* @__PURE__ */ jsxs(MenuItem, { value: "value2", children: [
            /* @__PURE__ */ jsx(Checkbox, { checked: false }),
            /* @__PURE__ */ jsx(
              ListItemText,
              {
                primary: "Value 2",
                primaryTypographyProps: widget.style ? {
                  className: classes.style
                } : {}
              }
            )
          ] }, "value2"),
          /* @__PURE__ */ jsxs(MenuItem, { value: "value3", children: [
            /* @__PURE__ */ jsx(Checkbox, { checked: true }),
            /* @__PURE__ */ jsx(
              ListItemText,
              {
                primary: "Value 3",
                primaryTypographyProps: widget.style ? {
                  className: classes.style
                } : {}
              }
            )
          ] }, "value3")
        ]
      }
    )
  ] });
};
const useStyles$7 = makeStyles()((theme) => ({
  selected: {
    color: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const PieChartWidget = ({ widget }) => {
  const { classes } = useStyles$7();
  const pieChartWidget = widget.chart;
  const chart = {
    entries: [
      { key: "<5", value: 19912018 },
      { key: "5-9", value: 20501982 },
      { key: "10-14", value: 20679786 },
      { key: "15-19", value: 21354481 },
      { key: "20-24", value: 22604232 },
      { key: "25-29", value: 21698010 },
      { key: "30-34", value: 21183639 },
      { key: "35-39", value: 19855782 },
      { key: "40-44", value: 20796128 },
      { key: "45-49", value: 21370368 },
      { key: "50-54", value: 22525490 },
      { key: "55-59", value: 21001947 },
      { key: "60-64", value: 18415681 },
      { key: "65-69", value: 14547446 },
      { key: "70-74", value: 10587721 },
      { key: "75-79", value: 7730129 },
      { key: "80-84", value: 5811429 },
      { key: "≥85", value: 5938752 }
    ],
    style: pieChartWidget.style
  };
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs(
    "div",
    {
      "data-testid": pieChartWidget.metadata.label,
      onFocus: () => setSelected(true),
      onBlur: () => setSelected(false),
      ref,
      tabIndex: 0,
      children: [
        /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: pieChartWidget.metadata.label }),
          widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
        ] }),
        /* @__PURE__ */ jsx(PieChart, { width: 300, height: 300, chart })
      ]
    }
  );
};
const useStyles$6 = makeStyles()(
  (theme, { color, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      color: color ? getCSSColor(color, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const RadioWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f;
  const props = {
    color: ((_a = widget.style) == null ? void 0 : _a.color) ?? null,
    fontSize: ((_b = widget.style) == null ? void 0 : _b.fontSize) ?? null,
    italic: ((_c = widget.style) == null ? void 0 : _c.italic) ?? null,
    bold: ((_d = widget.style) == null ? void 0 : _d.bold) ?? null,
    underline: ((_e = widget.style) == null ? void 0 : _e.underline) ?? null,
    strikeThrough: ((_f = widget.style) == null ? void 0 : _f.strikeThrough) ?? null
  };
  const { classes } = useStyles$6(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsxs(
      RadioGroup,
      {
        "data-testid": widget.label,
        "aria-label": widget.label,
        name: widget.label,
        defaultValue: "value2",
        row: true,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        children: [
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              value: "value1",
              control: /* @__PURE__ */ jsx(Radio, { color: "primary", "data-testid": "value1" }),
              label: /* @__PURE__ */ jsx(
                Typography,
                {
                  classes: widget.style ? {
                    root: classes.style
                  } : {},
                  children: "Value 1"
                }
              ),
              checked: false
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              value: "value2",
              control: /* @__PURE__ */ jsx(Radio, { color: "primary", "data-testid": "value2", inputRef: ref }),
              label: /* @__PURE__ */ jsx(
                Typography,
                {
                  classes: widget.style ? {
                    root: classes.style
                  } : {},
                  children: "Value 2"
                }
              ),
              checked: true
            }
          ),
          /* @__PURE__ */ jsx(
            FormControlLabel,
            {
              value: "value3",
              control: /* @__PURE__ */ jsx(Radio, { color: "primary", "data-testid": "value2" }),
              label: /* @__PURE__ */ jsx(
                Typography,
                {
                  classes: widget.style ? {
                    root: classes.style
                  } : {},
                  children: "Value 3"
                }
              ),
              checked: false
            }
          )
        ]
      }
    )
  ] });
};
const useStyles$5 = makeStyles()((theme) => ({
  selected: {
    color: theme.palette.primary.main
  },
  editorContainer: {
    marginTop: theme.spacing(2),
    borderRadius: "2px",
    borderColor: theme.palette.divider,
    borderWidth: "1px",
    borderStyle: "solid",
    color: theme.palette.text.primary,
    position: "relative",
    fontWeight: Number(theme.typography.fontWeightRegular),
    textAlign: "left"
  },
  paper: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexWrap: "wrap"
  },
  divider: {
    margin: theme.spacing(1, 0.5)
  },
  button: {
    color: theme.palette.primary.light,
    "&.Mui-selected": {
      color: theme.palette.primary.main
    }
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const StyledToggleButtonGroup = withStyles(ToggleButtonGroup, (theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: "none",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius
    }
  }
}));
const RichTextWidget = ({ widget }) => {
  const { classes } = useStyles$5();
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsxs("div", { onFocus: () => setSelected(true), onBlur: () => setSelected(false), ref, tabIndex: 0, children: [
      /* @__PURE__ */ jsxs(Paper, { elevation: 0, className: classes.paper, children: [
        /* @__PURE__ */ jsxs(StyledToggleButtonGroup, { size: "small", children: [
          /* @__PURE__ */ jsx(
            ToggleButton,
            {
              classes: { root: classes.button },
              selected: true,
              disabled: false,
              value: "paragraph",
              children: /* @__PURE__ */ jsx(SubjectIcon, { fontSize: "small" })
            },
            "paragraph"
          ),
          /* @__PURE__ */ jsx(
            ToggleButton,
            {
              classes: { root: classes.button },
              selected: false,
              disabled: false,
              value: "header1",
              children: /* @__PURE__ */ jsx(TitleIcon, { fontSize: "small" })
            },
            "header1"
          ),
          /* @__PURE__ */ jsx(
            ToggleButton,
            {
              classes: { root: classes.button },
              selected: false,
              disabled: false,
              value: "bullet-list",
              children: /* @__PURE__ */ jsx(FormatListBulletedIcon, { fontSize: "small" })
            },
            "bullet-list"
          ),
          /* @__PURE__ */ jsx(
            ToggleButton,
            {
              classes: { root: classes.button },
              selected: false,
              disabled: false,
              value: "number-list",
              children: /* @__PURE__ */ jsx(FormatListNumberedIcon, { fontSize: "small" })
            },
            "number-list"
          )
        ] }),
        /* @__PURE__ */ jsx(Divider, { flexItem: true, orientation: "vertical", className: classes.divider }),
        /* @__PURE__ */ jsxs(StyledToggleButtonGroup, { size: "small", children: [
          /* @__PURE__ */ jsx(ToggleButton, { classes: { root: classes.button }, disabled: false, value: "bold", children: /* @__PURE__ */ jsx(FormatBoldIcon, { fontSize: "small" }) }, "bold"),
          /* @__PURE__ */ jsx(ToggleButton, { classes: { root: classes.button }, value: "italic", children: /* @__PURE__ */ jsx(FormatItalicIcon, { fontSize: "small" }) }, "italic"),
          /* @__PURE__ */ jsx(ToggleButton, { classes: { root: classes.button }, disabled: false, value: "code", children: /* @__PURE__ */ jsx(CodeIcon, { fontSize: "small" }) }, "code"),
          /* @__PURE__ */ jsx(
            ToggleButton,
            {
              classes: { root: classes.button },
              disabled: false,
              value: "strikethrough",
              children: /* @__PURE__ */ jsx(StrikethroughSIcon, { fontSize: "small" })
            },
            "strikethrough"
          )
        ] })
      ] }),
      /* @__PURE__ */ jsxs("div", { className: classes.editorContainer, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "h4", children: "Rich text document" }),
        /* @__PURE__ */ jsxs(Typography, { variant: "body1", gutterBottom: true, children: [
          "Your ",
          /* @__PURE__ */ jsx("b", { children: "rich text" }),
          "."
        ] })
      ] })
    ] })
  ] });
};
const useStyles$4 = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : null,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const SelectWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = widget.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = widget.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = widget.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = widget.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = widget.style) == null ? void 0 : _g.strikeThrough) ?? null
  };
  const { classes } = useStyles$4(props);
  const { t } = useTranslation("siriusComponentsFormDescriptionEditors");
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    let cleanup = void 0;
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      const timeoutId = setTimeout(function() {
        ref.current.focus();
      }, 50);
      setSelected(true);
      cleanup = () => clearTimeout(timeoutId);
    } else {
      setSelected(false);
    }
    return cleanup;
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsxs(
      Select,
      {
        variant: "standard",
        "data-testid": widget.label,
        label: widget.label,
        fullWidth: true,
        value: "value1",
        inputRef: ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        inputProps: widget.style ? {
          className: classes.style
        } : {},
        children: [
          /* @__PURE__ */ jsx(
            MenuItem,
            {
              value: "",
              classes: widget.style ? {
                root: classes.style
              } : {},
              children: /* @__PURE__ */ jsx("em", { children: t("none") })
            }
          ),
          /* @__PURE__ */ jsx(
            MenuItem,
            {
              value: "value1",
              classes: widget.style ? {
                root: classes.style
              } : {},
              children: "Value 1"
            }
          ),
          /* @__PURE__ */ jsx(
            MenuItem,
            {
              value: "value2",
              classes: widget.style ? {
                root: classes.style
              } : {},
              children: "Value 2"
            }
          ),
          /* @__PURE__ */ jsx(
            MenuItem,
            {
              value: "value3",
              classes: widget.style ? {
                root: classes.style
              } : {},
              children: "Value 3"
            }
          )
        ]
      }
    )
  ] });
};
const useSliderWidgetStyles = makeStyles()((theme) => ({
  style: {
    color: theme.palette.secondary.main
  },
  selected: {
    color: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const SliderWidget = ({ widget }) => {
  const { classes } = useSliderWidgetStyles();
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      Slider,
      {
        ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        tabIndex: 0,
        min: widget.minValue,
        max: widget.maxValue,
        value: widget.currentValue,
        className: classes.style,
        children: /* @__PURE__ */ jsx(ExtensionIcon, {})
      }
    )
  ] });
};
const isErrorPayload$4 = (payload) => payload.__typename === "ErrorPayload";
const actionStyle = (theme, style) => {
  const actionStyle2 = {
    backgroundColor: style.backgroundColor ? getCSSColor(style.backgroundColor, theme) : theme.palette.primary.light,
    color: style.foregroundColor ? getCSSColor(style.foregroundColor, theme) : "white",
    fontSize: style.fontSize ? style.fontSize : void 0,
    fontStyle: style.italic ? "italic" : void 0,
    fontWeight: style.bold ? "bold" : void 0,
    textDecorationLine: style.underline || style.strikeThrough ? getTextDecorationLineValue(style.underline, style.strikeThrough) : void 0
  };
  return actionStyle2;
};
const imageStyle = (theme, iconOnly) => {
  return {
    marginRight: iconOnly ? theme.spacing(2) : theme.spacing(0),
    width: theme.spacing(2),
    height: theme.spacing(2)
  };
};
const useStyles$3 = makeStyles()((theme) => ({
  bottomDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "gray",
    height: "30px",
    width: "100%"
  },
  helpOutlineOutlined: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.subtitle1.fontSize
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  },
  selected: {
    color: theme.palette.primary.main
  },
  containerAndLabel: {
    margin: "0px",
    padding: "0px",
    borderWidth: "1px",
    borderColor: "gray",
    borderStyle: "solid",
    borderRadius: "0px"
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const SplitButtonWidget = ({ widget }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const initialState = {
    selected: false,
    open: false,
    selectedIndex: 0,
    message: "",
    actionsButtonLabel: [],
    actionsImageURL: [],
    actionsIsValidImage: []
  };
  const [state, setState] = useState(initialState);
  const theme = useTheme();
  const { httpOrigin } = useContext(ServerContext);
  const { selection } = useSelection();
  const buttonGroupRef = useRef(null);
  const widgetRef = useRef(null);
  const [addWidget, { loading: addWidgetLoading, data: addWidgetData, error: addWidgetError }] = useMutation$1(addWidgetMutation);
  useEffect(() => {
    if (!addWidgetLoading) {
      if (addWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: addWidgetError.message };
        });
      }
      if (addWidgetData) {
        const { addWidget: addWidget2 } = addWidgetData;
        if (isErrorPayload$4(addWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: addWidget2.message };
          });
        }
      }
    }
  }, [addWidgetLoading, addWidgetData, addWidgetError]);
  useEffect(() => {
    const actionsButtonLabel = [];
    const actionsImageURL = [];
    const actionsIsValidImage = [];
    widget.actions.forEach((action, index) => {
      actionsIsValidImage[index] = true;
      if (!action.imageURL) {
        actionsIsValidImage[index] = false;
      } else if (action.imageURL.startsWith("http://") || action.imageURL.startsWith("https://")) {
        actionsImageURL[index] = action.imageURL;
      } else {
        actionsImageURL[index] = httpOrigin + action.imageURL;
      }
      const buttonLabel = action.buttonLabel;
      const isButtonLabelBlank = buttonLabel == null || buttonLabel.trim() === "";
      if (actionsIsValidImage[index] && isButtonLabelBlank) {
        actionsButtonLabel[index] = null;
      } else if (!isButtonLabelBlank && !buttonLabel.startsWith("aql:")) {
        actionsButtonLabel[index] = buttonLabel;
      } else {
        actionsButtonLabel[index] = "Lorem";
      }
    });
    setState((prevState) => {
      return {
        ...prevState,
        actionsButtonLabel,
        actionsImageURL,
        actionsIsValidImage,
        selectedIndex: state.selectedIndex >= widget.actions.length - 1 ? 0 : state.selectedIndex
      };
    });
  }, [
    widget.actions.map((action) => action.imageURL).join(),
    widget.actions.map((action) => action.buttonLabel).join()
  ]);
  useEffect(() => {
    if (widgetRef.current && selection.entries.find((entry) => entry.id === widget.id)) {
      widgetRef.current.focus();
      setState((prevState) => ({ ...prevState, selected: true }));
    } else {
      setState((prevState) => ({ ...prevState, selected: false }));
    }
  }, [selection, widget]);
  const { classes } = useStyles$3();
  const handleMenuItemClick = (_event, index) => {
    setState((prevState) => ({ ...prevState, open: false, selectedIndex: index }));
  };
  const handleToggle = () => {
    setState((prevState) => ({ ...prevState, open: !prevState.open }));
  };
  const handleClose = (event) => {
    if (widgetRef.current && widgetRef.current.contains(event.target)) {
      return;
    }
    setState((prevState) => ({ ...prevState, open: false }));
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Widget") {
      return;
    }
    if (id === "Button") {
      const addWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: widget.id,
        kind: id,
        index: widget.actions.length || 0
      };
      const addWidgetVariables = { input: addWidgetInput };
      addWidget({ variables: addWidgetVariables });
    }
  };
  const getStyle = (index) => {
    if (!widget.actions.at(index) || !widget.actions.at(index).style) {
      const defaultProps = {
        backgroundColor: null,
        foregroundColor: null,
        fontSize: theme.typography.subtitle2.fontSize,
        italic: null,
        bold: null,
        underline: null,
        strikeThrough: null
      };
      return actionStyle(theme, defaultProps);
    }
    return actionStyle(theme, widget.actions[index].style);
  };
  return /* @__PURE__ */ jsxs("div", { className: classes.containerAndLabel, tabIndex: 0, ref: widgetRef, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: state.selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", className: classes.helpOutlineOutlined }) : null
    ] }),
    /* @__PURE__ */ jsxs(
      ButtonGroup,
      {
        variant: "contained",
        color: "primary",
        ref: buttonGroupRef,
        "aria-label": "split button",
        onFocus: () => setState((prevState) => {
          return {
            ...prevState,
            selected: true
          };
        }),
        onBlur: () => setState((prevState) => {
          return {
            ...prevState,
            selected: false
          };
        }),
        children: [
          /* @__PURE__ */ jsxs(
            Button$1,
            {
              "data-testid": widget.label,
              variant: "contained",
              color: "primary",
              style: { ...getStyle(state.selectedIndex) },
              children: [
                state.actionsIsValidImage[state.selectedIndex] && state.actionsImageURL[state.selectedIndex] ? /* @__PURE__ */ jsx(
                  "img",
                  {
                    style: { ...imageStyle(theme, state.actionsIsValidImage[state.selectedIndex]) },
                    alt: widget.actions[state.selectedIndex].label,
                    src: state.actionsImageURL[state.selectedIndex]
                  }
                ) : null,
                state.actionsButtonLabel[state.selectedIndex]
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            Button$1,
            {
              color: "primary",
              size: "small",
              "aria-controls": state.open ? "split-button-menu" : void 0,
              "aria-expanded": state.open ? "true" : void 0,
              "aria-label": "select button action",
              "aria-haspopup": "menu",
              role: "show-actions",
              onClick: handleToggle,
              style: { ...getStyle(state.selectedIndex) },
              children: /* @__PURE__ */ jsx(ArrowDropDownIcon, {})
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(Popper, { open: state.open, anchorEl: buttonGroupRef.current, transition: true, placement: "bottom", children: ({ TransitionProps, placement }) => /* @__PURE__ */ jsx(
      Grow,
      {
        ...TransitionProps,
        style: {
          transformOrigin: placement === "bottom" ? "center top" : "center bottom"
        },
        children: /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: handleClose, children: /* @__PURE__ */ jsx(MenuList, { id: "split-button-menu", children: widget.actions.map((option, index) => /* @__PURE__ */ jsxs(
          MenuItem,
          {
            selected: index === state.selectedIndex,
            onClick: (event) => handleMenuItemClick(event, index),
            style: { ...getStyle(index) },
            children: [
              state.actionsIsValidImage[index] && state.actionsImageURL[index] ? /* @__PURE__ */ jsx(
                "img",
                {
                  style: { ...imageStyle(theme, state.actionsIsValidImage[index]) },
                  alt: option.label,
                  src: state.actionsImageURL[index]
                }
              ) : null,
              state.actionsButtonLabel[index]
            ]
          },
          index
        )) }) }) })
      }
    ) }),
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `${widget.__typename}-Widgets-DropArea-${widget.id}`,
        className: classes.bottomDropArea,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop,
        children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Drag and drop a button widget here" })
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: state.message,
        open: !!state.message,
        onClose: () => setState((prevState) => {
          return { ...prevState, message: null };
        })
      }
    )
  ] });
};
const useStyles$2 = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : null,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const TextAreaWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = widget.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = widget.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = widget.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = widget.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = widget.style) == null ? void 0 : _g.strikeThrough) ?? null
  };
  const { classes } = useStyles$2(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      TextField,
      {
        variant: "standard",
        "data-testid": widget.label,
        multiline: true,
        minRows: 3,
        fullWidth: true,
        inputRef: ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        InputProps: widget.style ? {
          className: classes.style,
          readOnly: true
        } : { readOnly: true },
        value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      }
    )
  ] });
};
const useStyles$1 = makeStyles()(
  (theme, { backgroundColor, foregroundColor, fontSize, italic, bold, underline, strikeThrough }) => ({
    style: {
      backgroundColor: backgroundColor ? getCSSColor(backgroundColor, theme) : null,
      color: foregroundColor ? getCSSColor(foregroundColor, theme) : null,
      fontSize: fontSize ? fontSize : null,
      fontStyle: italic ? "italic" : null,
      fontWeight: bold ? "bold" : null,
      textDecorationLine: getTextDecorationLineValue(underline, strikeThrough)
    },
    selected: {
      color: theme.palette.primary.main
    },
    propertySectionLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
const TextfieldWidget = ({ widget }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const props = {
    backgroundColor: ((_a = widget.style) == null ? void 0 : _a.backgroundColor) ?? null,
    foregroundColor: ((_b = widget.style) == null ? void 0 : _b.foregroundColor) ?? null,
    fontSize: ((_c = widget.style) == null ? void 0 : _c.fontSize) ?? null,
    italic: ((_d = widget.style) == null ? void 0 : _d.italic) ?? null,
    bold: ((_e = widget.style) == null ? void 0 : _e.bold) ?? null,
    underline: ((_f = widget.style) == null ? void 0 : _f.underline) ?? null,
    strikeThrough: ((_g = widget.style) == null ? void 0 : _g.strikeThrough) ?? null
  };
  const { classes } = useStyles$1(props);
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selection, widget]);
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }),
    /* @__PURE__ */ jsx(
      TextField,
      {
        variant: "standard",
        "data-testid": widget.label,
        fullWidth: true,
        inputRef: ref,
        onFocus: () => setSelected(true),
        onBlur: () => setSelected(false),
        InputProps: widget.style ? {
          className: classes.style,
          readOnly: true
        } : { readOnly: true },
        value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      }
    )
  ] });
};
const useStyles = makeStyles()((theme) => ({
  selected: {
    color: theme.palette.primary.main
  },
  propertySectionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
}));
const TreeWidget = ({ widget }) => {
  const { classes } = useStyles();
  const [selected, setSelected] = useState(false);
  const { selection } = useSelection();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === widget.id)) {
      ref.current.focus();
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [widget, selection]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("div", { onFocus: () => setSelected(true), onBlur: () => setSelected(false), ref, tabIndex: 0, children: /* @__PURE__ */ jsxs("div", { className: classes.propertySectionLabel, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "subtitle2", className: selected ? classes.selected : "", children: widget.label }),
      widget.hasHelpText ? /* @__PURE__ */ jsx(HelpOutlineOutlined, { color: "secondary", style: { marginLeft: 8, fontSize: 16 } }) : null
    ] }) }),
    /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsxs(
      SimpleTreeView,
      {
        "aria-label": "Model browser",
        slots: { collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon },
        expandedItems: ["5"],
        children: [
          /* @__PURE__ */ jsx(TreeItem, { itemId: "1", label: "Item1", children: /* @__PURE__ */ jsx(TreeItem, { itemId: "2", label: "Item1.1" }) }),
          /* @__PURE__ */ jsxs(TreeItem, { itemId: "5", label: "Item2", children: [
            /* @__PURE__ */ jsx(TreeItem, { itemId: "10", label: "Item2.1" }),
            /* @__PURE__ */ jsx(TreeItem, { itemId: "6", label: "Item2.2" })
          ] })
        ]
      }
    ) })
  ] });
};
const useWidgetEntryStyles = makeStyles()((theme, { flexDirection, flexGrow, kind: _kind }, classes) => ({
  widget: {
    display: "flex",
    flexDirection,
    flexGrow
  },
  widgetElement: {
    flexGrow,
    border: "1px solid transparent",
    "&:hover": {
      borderColor: theme.palette.primary.main
    },
    [`&:has(.${classes.widgetElement}:hover)`]: {
      borderStyle: "dashed"
    }
  },
  placeholder: {
    height: flexDirection === "column" || flexDirection === "column-reverse" ? "10px" : "inherit",
    width: flexDirection === "row" || flexDirection === "row-reverse" ? "10px" : "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    border: "1px solid whitesmoke",
    borderRadius: "5px"
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  }
}));
const WidgetTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    margin: "0px",
    borderRadius: "0px"
  }
}));
const isErrorPayload$3 = (payload) => payload.__typename === "ErrorPayload";
const WidgetEntry = ({ page, container, widget, flexDirection, flexGrow }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = useWidgetEntryStyles({ flexDirection, flexGrow, kind: widget.__typename });
  const initialState = { message: null };
  const [state, setState] = useState(initialState);
  const { message } = state;
  const { data: widgetContributions } = useData(widgetContributionExtensionPoint);
  const { setSelection } = useSelection();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const [addWidget, { loading: addWidgetLoading, data: addWidgetData, error: addWidgetError }] = useMutation(addWidgetMutation);
  useEffect(() => {
    if (!addWidgetLoading) {
      if (addWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: addWidgetError.message };
        });
      }
      if (addWidgetData) {
        const { addWidget: addWidget2 } = addWidgetData;
        if (isErrorPayload$3(addWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: addWidget2.message };
          });
        }
      }
    }
  }, [addWidgetLoading, addWidgetData, addWidgetError]);
  const [deleteWidget, { loading: deleteWidgetLoading, data: deleteWidgetData, error: deleteWidgetError }] = useMutation(deleteWidgetMutation);
  useEffect(() => {
    if (!deleteWidgetLoading) {
      if (deleteWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: deleteWidgetError.message };
        });
      }
      if (deleteWidgetData) {
        const { deleteWidget: deleteWidget2 } = deleteWidgetData;
        if (isErrorPayload$3(deleteWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: deleteWidget2.message };
          });
        }
      }
    }
  }, [deleteWidgetLoading, deleteWidgetData, deleteWidgetError]);
  const [moveWidget, { loading: moveWidgetLoading, data: moveWidgetData, error: moveWidgetError }] = useMutation(moveWidgetMutation);
  useEffect(() => {
    if (!moveWidgetLoading) {
      if (moveWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: moveWidgetError.message };
        });
      }
      if (moveWidgetData) {
        const { moveWidget: moveWidget2 } = moveWidgetData;
        if (isErrorPayload$3(moveWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: moveWidget2.message };
          });
        }
      }
    }
  }, [moveWidgetLoading, moveWidgetData, moveWidgetError]);
  const handleClick = (event) => {
    const newSelection = {
      entries: [
        {
          id: widget.id,
          kind: `siriusComponents://semantic?domain=view&entity=${widget.__typename}Description`
        }
      ]
    };
    setSelection(newSelection);
    event.stopPropagation();
  };
  const handleDelete = (event) => {
    event.preventDefault();
    if (event.key === "Delete") {
      const deleteWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        widgetId: widget.id
      };
      const deleteWidgetVariables = { input: deleteWidgetInput };
      showDeletionConfirmation(() => {
        deleteWidget({ variables: deleteWidgetVariables });
      });
      event.stopPropagation();
    }
  };
  const handleDragStart = (event) => {
    event.dataTransfer.setData("draggedElementId", widget.id);
    event.dataTransfer.setData("draggedElementType", "Widget");
    event.stopPropagation();
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    onDropBefore(event, widget);
  };
  const onDropBefore = (event, widget2) => {
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Widget") {
      return;
    }
    let children = null;
    if (isGroup(container)) {
      children = container.widgets;
    } else if (isFlexboxContainer(container)) {
      children = container.children;
    }
    let index = children.indexOf(widget2);
    if (index <= 0) {
      index = 0;
    }
    if (isKind(id) || widgetContributions.find((widgetContribution) => widgetContribution.name === id)) {
      const addWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: container.id,
        kind: id,
        index
      };
      const addWidgetVariables = { input: addWidgetInput };
      addWidget({ variables: addWidgetVariables });
    } else {
      const movedWidgetIndex = children.findIndex((w) => w.id === id);
      if (movedWidgetIndex > -1 && movedWidgetIndex < index) {
        index--;
      }
      const moveWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: container.id,
        widgetId: id,
        index
      };
      const moveWidgetVariables = { input: moveWidgetInput };
      moveWidget({ variables: moveWidgetVariables });
    }
  };
  let widgetElement = null;
  if (widget.__typename === "Button") {
    widgetElement = /* @__PURE__ */ jsx(
      ButtonWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "SplitButton") {
    widgetElement = /* @__PURE__ */ jsx(
      SplitButtonWidget,
      {
        "data-testid": widget.id,
        editingContextId,
        representationId,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Checkbox") {
    widgetElement = /* @__PURE__ */ jsx(
      CheckboxWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "DateTime") {
    widgetElement = /* @__PURE__ */ jsx(
      DateTimeWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "FlexboxContainer") {
    widgetElement = /* @__PURE__ */ jsx(
      FlexboxContainerWidget,
      {
        "data-testid": widget.id,
        page,
        container,
        widget
      }
    );
  } else if (widget.__typename === "FormDescriptionEditorIf") {
    const ifPreview = {
      ...widget,
      flexDirection: "column",
      flexWrap: "nowrap",
      flexGrow: 1,
      children: widget.children
    };
    widgetElement = /* @__PURE__ */ jsx(FlexboxContainerWidget, { "data-testid": widget.id, page, container, widget: ifPreview });
  } else if (widget.__typename === "FormDescriptionEditorFor") {
    const forPreview = {
      ...widget,
      flexDirection: "column",
      flexWrap: "nowrap",
      flexGrow: 1,
      children: widget.children
    };
    widgetElement = /* @__PURE__ */ jsx(FlexboxContainerWidget, { "data-testid": widget.id, page, container, widget: forPreview });
  } else if (widget.__typename === "Image") {
    widgetElement = /* @__PURE__ */ jsx(ImageWidget, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
  } else if (widget.__typename === "LabelWidget") {
    widgetElement = /* @__PURE__ */ jsx(
      LabelWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Link") {
    widgetElement = /* @__PURE__ */ jsx(LinkWidget, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
  } else if (widget.__typename === "List") {
    widgetElement = /* @__PURE__ */ jsx(ListWidget, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
  } else if (widget.__typename === "MultiSelect") {
    widgetElement = /* @__PURE__ */ jsx(
      MultiSelectWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Radio") {
    widgetElement = /* @__PURE__ */ jsx(RadioWidget, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
  } else if (widget.__typename === "RichText") {
    widgetElement = /* @__PURE__ */ jsx(
      RichTextWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Select") {
    widgetElement = /* @__PURE__ */ jsx(
      SelectWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Slider") {
    widgetElement = /* @__PURE__ */ jsx(
      SliderWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Textarea") {
    widgetElement = /* @__PURE__ */ jsx(
      TextAreaWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "Textfield") {
    widgetElement = /* @__PURE__ */ jsx(
      TextfieldWidget,
      {
        "data-testid": widget.id,
        widget,
        onDropBefore: readOnly ? noop : onDropBefore
      }
    );
  } else if (widget.__typename === "TreeWidget") {
    widgetElement = /* @__PURE__ */ jsx(TreeWidget, { "data-testid": widget.id, widget, onDropBefore });
  } else if (widget.__typename === "ChartWidget") {
    const chartWidget = widget;
    if (chartWidget.chart.__typename === "BarChart") {
      widgetElement = /* @__PURE__ */ jsx(
        BarChartWidget,
        {
          "data-testid": widget.id,
          widget,
          onDropBefore: readOnly ? noop : onDropBefore
        }
      );
    } else if (chartWidget.chart.__typename === "PieChart") {
      widgetElement = /* @__PURE__ */ jsx(
        PieChartWidget,
        {
          "data-testid": widget.id,
          widget,
          onDropBefore: readOnly ? noop : onDropBefore
        }
      );
    }
  } else {
    const widgetContribution = widgetContributions.find(
      (widgetContribution2) => widgetContribution2.name === widget.__typename
    );
    if (widgetContribution) {
      if (widgetContribution.previewComponent) {
        const PreviewComponent = widgetContribution.previewComponent;
        widgetElement = /* @__PURE__ */ jsx(PreviewComponent, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
      } else if (widgetContribution.component(widget)) {
        widgetElement = /* @__PURE__ */ jsx(CustomWidget, { "data-testid": widget.id, widget, onDropBefore: readOnly ? noop : onDropBefore });
      }
    } else {
      console.error(`Unsupported widget type ${widget.__typename}`);
    }
  }
  return /* @__PURE__ */ jsxs("div", { className: classes.widget, children: [
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `WidgetEntry-DropArea-${widget.id}`,
        className: classes.placeholder,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop
      }
    ),
    /* @__PURE__ */ jsx(WidgetTooltip, { title: widget.__typename, placement: "top-end", children: /* @__PURE__ */ jsx(
      "div",
      {
        className: classes.widgetElement,
        onClick: readOnly ? noop : handleClick,
        tabIndex: 0,
        onKeyDown: readOnly ? noop : handleDelete,
        draggable: !readOnly,
        onDragStart: readOnly ? noop : handleDragStart,
        children: widgetElement
      }
    ) }),
    /* @__PURE__ */ jsx(Toast, { message, open: !!message, onClose: () => setState({ message: null }) })
  ] });
};
const useGroupEntryStyles = makeStyles()(
  (theme, { borderStyle }, classes) => ({
    group: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      borderWidth: borderStyle ? borderStyle.size : 1,
      borderColor: borderStyle ? getCSSColor(borderStyle == null ? void 0 : borderStyle.color, theme) || "transparent" : "gray",
      borderStyle: (borderStyle == null ? void 0 : borderStyle.lineStyle) || "solid",
      borderRadius: borderStyle ? borderStyle.radius : 10,
      paddingTop: "1px",
      "&:hover": {
        borderColor: theme.palette.primary.main
      },
      [`&:has(.${classes.verticalSections}:hover)`]: {
        borderStyle: "dashed"
      },
      [`&:has(.${classes.adaptableSections}:hover)`]: {
        borderStyle: "dashed"
      }
    },
    labelAndToolbar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(1),
      overflowX: "auto"
    },
    verticalSections: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      paddingLeft: theme.spacing(1),
      overflowX: "auto"
    },
    adaptableSections: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      gap: theme.spacing(2),
      "& > *": {
        marginBottom: theme.spacing(2)
      }
    },
    placeholder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "whitesmoke",
      border: "1px solid whitesmoke",
      borderRadius: "5px",
      height: "20px",
      width: "inherit"
    },
    dragOver: {
      borderWidth: "1px",
      borderStyle: "dashed",
      borderColor: theme.palette.primary.main
    },
    bottomDropArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "whitesmoke",
      borderRadius: "10px",
      color: "gray",
      height: "60px"
    },
    selected: {
      color: theme.palette.primary.main
    }
  })
);
const GroupTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    margin: "0px",
    borderRadius: "0px"
  }
}));
const isErrorPayload$2 = (payload) => payload.__typename === "ErrorPayload";
const Group = ({ page, group }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = useGroupEntryStyles({
    borderStyle: group.borderStyle
  });
  const initialState = { message: null, selected: false };
  const [state, setState] = useState(initialState);
  const { message, selected } = state;
  const { selection, setSelection } = useSelection();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === group.id)) {
      ref.current.focus();
      setState((prevState) => {
        return { ...prevState, selected: true };
      });
    } else {
      setState((prevState) => {
        return { ...prevState, selected: false };
      });
    }
  }, [selection, group]);
  const [addWidget, { loading: addWidgetLoading, data: addWidgetData, error: addWidgetError }] = useMutation(addWidgetMutation);
  useEffect(() => {
    if (!addWidgetLoading) {
      if (addWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: addWidgetError.message };
        });
      }
      if (addWidgetData) {
        const { addWidget: addWidget2 } = addWidgetData;
        if (isErrorPayload$2(addWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: addWidget2.message };
          });
        }
      }
    }
  }, [addWidgetLoading, addWidgetData, addWidgetError]);
  const [moveWidget, { loading: moveWidgetLoading, data: moveWidgetData, error: moveWidgetError }] = useMutation(moveWidgetMutation);
  useEffect(() => {
    if (!moveWidgetLoading) {
      if (moveWidgetError) {
        setState((prevState) => {
          return { ...prevState, message: moveWidgetError.message };
        });
      }
      if (moveWidgetData) {
        const { moveWidget: moveWidget2 } = moveWidgetData;
        if (isErrorPayload$2(moveWidget2)) {
          setState((prevState) => {
            return { ...prevState, message: moveWidget2.message };
          });
        }
      }
    }
  }, [moveWidgetLoading, moveWidgetData, moveWidgetError]);
  const [addGroup, { loading: addGroupLoading, data: addGroupData, error: addGroupError }] = useMutation(addGroupMutation);
  useEffect(() => {
    if (!addGroupLoading) {
      if (addGroupError) {
        setState((prevState) => {
          return { ...prevState, message: addGroupError.message };
        });
      }
      if (addGroupData) {
        const { addGroup: addGroup2 } = addGroupData;
        if (isErrorPayload$2(addGroup2)) {
          setState((prevState) => {
            return { ...prevState, message: addGroup2.message };
          });
        }
      }
    }
  }, [addGroupLoading, addGroupData, addGroupError]);
  const [deleteGroup, { loading: deleteGroupLoading, data: deleteGroupData, error: deleteGroupError }] = useMutation(deleteGroupMutation);
  useEffect(() => {
    if (!deleteGroupLoading) {
      if (deleteGroupError) {
        setState((prevState) => {
          return { ...prevState, message: deleteGroupError.message };
        });
      }
      if (deleteGroupData) {
        const { deleteGroup: deleteGroup2 } = deleteGroupData;
        if (isErrorPayload$2(deleteGroup2)) {
          setState((prevState) => {
            return { ...prevState, message: deleteGroup2.message };
          });
        }
      }
    }
  }, [deleteGroupLoading, deleteGroupData, deleteGroupError]);
  const [moveGroup, { loading: moveGroupLoading, data: moveGroupData, error: moveGroupError }] = useMutation(moveGroupMutation);
  useEffect(() => {
    if (!moveGroupLoading) {
      if (moveGroupError) {
        setState((prevState) => {
          return { ...prevState, message: moveGroupError.message };
        });
      }
      if (moveGroupData) {
        const { moveGroup: moveGroup2 } = moveGroupData;
        if (isErrorPayload$2(moveGroup2)) {
          setState((prevState) => {
            return { ...prevState, message: moveGroup2.message };
          });
        }
      }
    }
  }, [moveGroupLoading, moveGroupData, moveGroupError]);
  const handleClick = (event) => {
    const newSelection = {
      entries: [
        {
          id: group.id,
          kind: `siriusComponents://semantic?domain=view&entity=GroupDescription`
        }
      ]
    };
    setSelection(newSelection);
    event.stopPropagation();
  };
  const handleDelete = (event) => {
    event.preventDefault();
    if (event.key === "Delete") {
      const deleteGroupInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        groupId: group.id
      };
      const deleteGroupVariables = { input: deleteGroupInput };
      showDeletionConfirmation(() => {
        deleteGroup({ variables: deleteGroupVariables });
      });
      event.stopPropagation();
    }
  };
  const handleDragStart = (event) => {
    event.dataTransfer.setData("draggedElementId", group.id);
    event.dataTransfer.setData("draggedElementType", "Group");
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const { data: widgetContributions } = useData(widgetContributionExtensionPoint);
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Group") {
      return;
    }
    if (id === "Group") {
      let newGroupIndex = page.groups.indexOf(group);
      if (newGroupIndex <= 0) {
        newGroupIndex = 0;
      }
      const addGroupInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: page.id,
        index: newGroupIndex
      };
      const addGroupVariables = { input: addGroupInput };
      addGroup({ variables: addGroupVariables });
    } else if (page.groups.find((g) => g.id === id)) {
      let groupNewIndex = page.groups.indexOf(group);
      if (groupNewIndex <= 0) {
        groupNewIndex = 0;
      }
      const movedGroupIndex = page.groups.findIndex((g) => g.id === id);
      if (movedGroupIndex > -1 && movedGroupIndex < groupNewIndex) {
        groupNewIndex--;
      }
      const moveGroupInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: page.id,
        groupId: id,
        index: groupNewIndex
      };
      const moveGroupVariables = { input: moveGroupInput };
      moveGroup({ variables: moveGroupVariables });
    }
  };
  const handleDropBottom = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Widget") {
      return;
    }
    let widgetIndex = group.widgets.length;
    if (isKind(id) || widgetContributions.find((widgetContribution) => widgetContribution.name === id)) {
      const addWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: group.id,
        kind: id,
        index: widgetIndex
      };
      const addWidgetVariables = { input: addWidgetInput };
      addWidget({ variables: addWidgetVariables });
    } else {
      if (group.widgets.find((w) => w.id === id)) {
        widgetIndex--;
      }
      const moveWidgetInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        containerId: group.id,
        widgetId: id,
        index: widgetIndex
      };
      const moveWidgetVariables = { input: moveWidgetInput };
      moveWidget({ variables: moveWidgetVariables });
    }
  };
  const [visibleWidgetIds, setVisibleWidgetIds] = useState([]);
  useEffect(() => {
    setVisibleWidgetIds(group.widgets.map((widget) => widget.id));
  }, [group]);
  let widgetSelector = void 0;
  if (group.displayMode === "TOGGLEABLE_AREAS") {
    widgetSelector = /* @__PURE__ */ jsx(
      ToggleButtonGroup,
      {
        size: "small",
        value: visibleWidgetIds,
        onChange: (_, newVisibleIds) => setVisibleWidgetIds(newVisibleIds),
        children: group.widgets.map((widget) => {
          return /* @__PURE__ */ jsx(ToggleButton, { value: widget.id, children: widget.label }, widget.id);
        })
      }
    );
  }
  const toolbar = /* @__PURE__ */ jsx(
    ToolbarActions,
    {
      "data-testid": `Group-ToolbarActions-${group.id}`,
      toolbarActions: group.toolbarActions,
      containerId: group.id
    }
  );
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": `Group-DropArea-${group.id}`,
        className: classes.placeholder,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop
      }
    ),
    /* @__PURE__ */ jsx(GroupTooltip, { title: group.__typename, placement: "top-end", children: /* @__PURE__ */ jsxs(
      "div",
      {
        "data-testid": group.id,
        className: classes.group,
        onClick: readOnly ? noop : handleClick,
        onKeyDown: readOnly ? noop : handleDelete,
        draggable: !readOnly,
        onDragStart: readOnly ? noop : handleDragStart,
        children: [
          /* @__PURE__ */ jsxs(
            "div",
            {
              className: `${classes.labelAndToolbar} ${selected ? classes.selected : ""}`,
              onFocus: () => setState((prevState) => {
                return { ...prevState, selected: true };
              }),
              onBlur: () => setState((prevState) => {
                return { ...prevState, selected: false };
              }),
              ref,
              tabIndex: 0,
              children: [
                group.displayMode === "TOGGLEABLE_AREAS" ? widgetSelector : /* @__PURE__ */ jsx(Typography, { variant: "subtitle1", children: group.label }),
                toolbar
              ]
            }
          ),
          /* @__PURE__ */ jsx("div", { className: group.displayMode === "LIST" ? classes.verticalSections : classes.adaptableSections, children: group.widgets.filter((widget) => visibleWidgetIds.includes(widget.id)).map((widget) => /* @__PURE__ */ jsx(
            WidgetEntry,
            {
              page,
              container: group,
              widget,
              flexDirection: group.displayMode === "LIST" ? "column" : "row",
              flexGrow: 1
            },
            widget.id
          )) }),
          /* @__PURE__ */ jsx(
            "div",
            {
              "data-testid": `Group-Widgets-DropArea-${group.id}`,
              className: classes.bottomDropArea,
              onDragEnter: readOnly ? noop : handleDragEnter,
              onDragOver: readOnly ? noop : handleDragOver,
              onDragLeave: readOnly ? noop : handleDragLeave,
              onDrop: readOnly ? noop : handleDropBottom,
              children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Drag and drop a widget here" })
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: !!message,
        onClose: () => setState((prevState) => {
          return { ...prevState, message: null };
        })
      }
    )
  ] });
};
const isErrorPayload$1 = (payload) => payload.__typename === "ErrorPayload";
const usePageStyles = makeStyles()((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginBottom: theme.spacing(2)
    },
    overflowY: "auto"
  },
  preview: {
    width: "100%",
    padding: "4px 8px 4px 8px",
    overflowY: "auto"
  },
  bottomDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "gray",
    height: "60px"
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  }
}));
const Page = ({ page }) => {
  const { editingContextId, representationId, readOnly } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = usePageStyles();
  const initialState = { message: null, selected: false };
  const [state, setState] = useState(initialState);
  const { message } = state;
  const ref = useRef(null);
  const { selection } = useSelection();
  useEffect(() => {
    if (ref.current && selection.entries.find((entry) => entry.id === page.id)) {
      ref.current.focus();
      setState((prevState) => {
        return { ...prevState, selected: true };
      });
    } else {
      setState((prevState) => {
        return { ...prevState, selected: false };
      });
    }
  }, [selection, page]);
  const [addGroup, { loading: addGroupLoading, data: addGroupData, error: addGroupError }] = useMutation(addGroupMutation);
  useEffect(() => {
    if (!addGroupLoading) {
      if (addGroupError) {
        setState((prevState) => {
          return { ...prevState, message: addGroupError.message };
        });
      }
      if (addGroupData) {
        const { addGroup: addGroup2 } = addGroupData;
        if (isErrorPayload$1(addGroup2)) {
          setState((prevState) => {
            return { ...prevState, message: addGroup2.message };
          });
        }
      }
    }
  }, [addGroupLoading, addGroupData, addGroupError]);
  const [moveGroup, { loading: moveGroupLoading, data: moveGroupData, error: moveGroupError }] = useMutation(moveGroupMutation);
  useEffect(() => {
    if (!moveGroupLoading) {
      if (moveGroupError) {
        setState((prevState) => {
          return { ...prevState, message: moveGroupError.message };
        });
      }
      if (moveGroupData) {
        const { moveGroup: moveGroup2 } = moveGroupData;
        if (isErrorPayload$1(moveGroup2)) {
          setState((prevState) => {
            return { ...prevState, message: moveGroup2.message };
          });
        }
      }
    }
  }, [moveGroupLoading, moveGroupData, moveGroupError]);
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Group") {
      return;
    }
    let index = page.groups.length;
    if (id === "Group") {
      const addGroupInput = {
        id: crypto.randomUUID(),
        editingContextId,
        pageId: page.id,
        representationId,
        index
      };
      const addGroupVariables = { input: addGroupInput };
      addGroup({ variables: addGroupVariables });
    } else if (page.groups.find((g) => g.id === id)) {
      index--;
      const moveGroupInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: page.id,
        groupId: id,
        index
      };
      const moveGroupVariables = { input: moveGroupInput };
      moveGroup({ variables: moveGroupVariables });
    }
  };
  return /* @__PURE__ */ jsxs("div", { className: classes.preview, children: [
    /* @__PURE__ */ jsx("div", { className: classes.page, children: page.groups.map((group) => {
      return /* @__PURE__ */ jsx(Group, { page, group }, group.id);
    }) }),
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-testid": "Page-DropArea",
        className: classes.bottomDropArea,
        onDragEnter: readOnly ? noop : handleDragEnter,
        onDragOver: readOnly ? noop : handleDragOver,
        onDragLeave: readOnly ? noop : handleDragLeave,
        onDrop: readOnly ? noop : handleDrop,
        children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Drag and drop a group here" })
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: !!message,
        onClose: () => setState((prevState) => {
          return { ...prevState, message: null };
        })
      }
    )
  ] });
};
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const recursiveWidgetSearch = (widget, entryId) => {
  if (entryId === widget.id) {
    return true;
  }
  if (isFlexboxContainer(widget)) {
    {
      return widget.children.some((widget2) => recursiveWidgetSearch(widget2, entryId));
    }
  }
  return false;
};
const usePageListStyles = makeStyles()((theme) => ({
  rightDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "gray",
    height: "40px",
    width: "50%"
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  },
  pagesListDropAreaAndToolbar: {
    display: "flex",
    justifyContent: "space-between"
  },
  tabsRoot: {
    minHeight: theme.spacing(4),
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  },
  tabRoot: {
    minHeight: theme.spacing(4),
    textTransform: "none"
  },
  tabLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "inherit"
  },
  tabLabelText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
}));
const a11yProps = (id) => {
  return {
    id: `simple-tab-${id}`,
    "aria-controls": `simple-tabpanel-${id}`
  };
};
const PageList = () => {
  const { editingContextId, representationId, readOnly, formDescriptionEditor } = useFormDescriptionEditor();
  const noop = () => {
  };
  const { classes } = usePageListStyles();
  const { pages } = formDescriptionEditor;
  const [state, setState] = useState({ message: null, selectedPage: pages[0], pages });
  const { message } = state;
  const { selection, setSelection } = useSelection();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  useEffect(() => {
    const entry = selection.entries.at(0);
    if (!entry) {
      return;
    }
    let pageToSelect;
    pageToSelect = state.pages.find((page) => entry.id === page.id);
    if (!pageToSelect) {
      pageToSelect = state.pages.find((page) => page.groups.some((group) => entry.id === group.id));
    }
    if (!pageToSelect) {
      pageToSelect = state.pages.find(
        (page) => page.groups.some((group) => group.toolbarActions.some((toolbar) => entry.id === toolbar.id))
      );
    }
    if (!pageToSelect) {
      pageToSelect = state.pages.find((page) => page.toolbarActions.some((toolbar) => entry.id === toolbar.id));
    }
    if (!pageToSelect) {
      pageToSelect = state.pages.find(
        (page) => page.groups.some((group) => group.widgets.some((widget) => recursiveWidgetSearch(widget, entry.id)))
      );
    }
    if (pageToSelect && pageToSelect.id !== state.selectedPage.id) {
      setState((prevState) => {
        return { ...prevState, selectedPage: pageToSelect };
      });
    }
  }, [selection]);
  useEffect(() => {
    setState((prevState) => {
      const selectedPage = pages.find((page) => page.id === state.selectedPage.id);
      if (selectedPage) {
        return { ...prevState, selectedPage, pages };
      }
      return { ...prevState, selectedPage: pages[0], pages };
    });
  }, [pages, state.selectedPage.id]);
  const onChangeTab = (_, value) => {
    const selectedPage = pages.find((page) => page.id === value);
    setState((prevState) => {
      return { ...prevState, selectedPage };
    });
  };
  const [addPage, { loading: addPageLoading, data: addPageData, error: addPageError }] = useMutation(addPageMutation);
  useEffect(() => {
    if (!addPageLoading) {
      if (addPageError) {
        setState((prevState) => {
          return { ...prevState, message: addPageError.message };
        });
      }
      if (addPageData) {
        const { addPage: addPage2 } = addPageData;
        if (isErrorPayload(addPage2)) {
          setState((prevState) => {
            return { ...prevState, message: addPage2.message };
          });
        }
      }
    }
  }, [addPageLoading, addPageData, addPageError]);
  const [movePage, { loading: movePageLoading, data: movePageData, error: movePageError }] = useMutation(movePageMutation);
  useEffect(() => {
    if (!movePageLoading) {
      if (movePageError) {
        setState((prevState) => {
          return { ...prevState, message: movePageError.message };
        });
      }
      if (movePageData) {
        const { movePage: movePage2 } = movePageData;
        if (isErrorPayload(movePage2)) {
          setState((prevState) => {
            return { ...prevState, message: movePage2.message };
          });
        }
      }
    }
  }, [movePageLoading, movePageData, movePageError]);
  const [deletePage, { loading: deletePageLoading, data: deletePageData, error: deletePageError }] = useMutation(deletePageMutation);
  useEffect(() => {
    if (!deletePageLoading) {
      if (deletePageError) {
        setState((prevState) => {
          return { ...prevState, message: deletePageError.message };
        });
      }
      if (deletePageData) {
        const { deletePage: deletePage2 } = deletePageData;
        if (isErrorPayload(deletePage2)) {
          setState((prevState) => {
            return { ...prevState, message: deletePage2.message };
          });
        }
      }
    }
  }, [deletePageLoading, deletePageData, deletePageError]);
  const handleClick = (event) => {
    const currentPage = pages.find((page) => page.id === event.currentTarget.id);
    if (currentPage) {
      const newSelection = {
        entries: [
          {
            id: currentPage.id,
            kind: `siriusComponents://semantic?domain=view&entity=PageDescription`
          }
        ]
      };
      setSelection(newSelection);
    }
    event.stopPropagation();
  };
  const handleDelete = (event) => {
    event.preventDefault();
    if (event.key === "Delete") {
      const deletePageInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: state.selectedPage.id
      };
      const deletePageVariables = { input: deletePageInput };
      showDeletionConfirmation(() => {
        deletePage({ variables: deletePageVariables });
      });
      event.stopPropagation();
    }
  };
  const handleDragStart = (event) => {
    event.dataTransfer.setData("draggedElementId", event.currentTarget.id);
    event.dataTransfer.setData("draggedElementType", "Page");
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
    event.currentTarget.classList.add(classes.dragOver);
  };
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
  };
  const handleDropTab = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const draggedElementId = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Page") {
      return;
    }
    const dropLocationId = event.currentTarget.id;
    const index = pages.findIndex((p) => p.id === dropLocationId);
    if (index < 0) {
      return;
    }
    if (draggedElementId === "Page") {
      const addPageInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        index
      };
      const addPageVariables = { input: addPageInput };
      addPage({ variables: addPageVariables });
    } else if (formDescriptionEditor.pages.find((g) => g.id === draggedElementId)) {
      const movePageInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: draggedElementId,
        index
      };
      const movePageVariables = { input: movePageInput };
      movePage({ variables: movePageVariables });
    }
  };
  const handleDropArea = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove(classes.dragOver);
    const id = event.dataTransfer.getData("draggedElementId");
    const type = event.dataTransfer.getData("draggedElementType");
    if (type !== "Page") {
      return;
    }
    let index = formDescriptionEditor.pages.length;
    if (id === "Page") {
      const addPageInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        index
      };
      const addPageVariables = { input: addPageInput };
      addPage({ variables: addPageVariables });
    } else if (pages.find((g) => g.id === id)) {
      index--;
      const movePageInput = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId,
        pageId: id,
        index
      };
      const movePageVariables = { input: movePageInput };
      movePage({ variables: movePageVariables });
    }
  };
  const selectedPageToolbar = /* @__PURE__ */ jsx(
    ToolbarActions,
    {
      "data-testid": `Page-ToolbarActions-${state.selectedPage.id}`,
      toolbarActions: state.selectedPage.toolbarActions,
      containerId: state.selectedPage.id
    }
  );
  return /* @__PURE__ */ jsxs("div", { children: [
    /* @__PURE__ */ jsxs("div", { className: classes.pagesListDropAreaAndToolbar, children: [
      /* @__PURE__ */ jsx(
        Tabs,
        {
          classes: { root: classes.tabsRoot },
          value: state.selectedPage.id,
          onChange: readOnly ? noop : onChangeTab,
          variant: "scrollable",
          scrollButtons: true,
          textColor: "primary",
          indicatorColor: "primary",
          children: state.pages.map((page) => {
            return /* @__PURE__ */ createElement(
              Tab,
              {
                ...a11yProps(page.id),
                "data-testid": `Page-${page.id}`,
                classes: { root: classes.tabRoot },
                id: page.id,
                value: page.id,
                label: /* @__PURE__ */ jsx("div", { className: classes.tabLabel, children: /* @__PURE__ */ jsx("div", { className: classes.tabLabelText, children: page.label }) }),
                key: page.id,
                onClick: readOnly ? noop : handleClick,
                onKeyDown: readOnly ? noop : handleDelete,
                draggable: !readOnly,
                onDragStart: readOnly ? noop : handleDragStart,
                onDragEnter: readOnly ? noop : handleDragEnter,
                onDragOver: readOnly ? noop : handleDragOver,
                onDragLeave: readOnly ? noop : handleDragLeave,
                onDrop: readOnly ? noop : handleDropTab
              }
            );
          })
        }
      ),
      /* @__PURE__ */ jsx(
        "div",
        {
          "data-testid": "PageList-DropArea",
          className: classes.rightDropArea,
          onDragEnter: readOnly ? noop : handleDragEnter,
          onDragOver: readOnly ? noop : handleDragOver,
          onDragLeave: readOnly ? noop : handleDragLeave,
          onDrop: readOnly ? noop : handleDropArea,
          children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Drag and drop a page here" })
        }
      ),
      selectedPageToolbar
    ] }),
    /* @__PURE__ */ jsx(Page, { page: state.selectedPage }),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message,
        open: !!message,
        onClose: () => setState((prevState) => {
          return { ...prevState, message: null };
        })
      }
    )
  ] });
};
const Button = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M22,9v6c0,1.1-0.9,2-2,2h-1l0-2h1V9H4v6h6v2H4c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h16C21.1,7,22,7.9,22,9z M14.5,19 l1.09-2.41L18,15.5l-2.41-1.09L14.5,12l-1.09,2.41L11,15.5l2.41,1.09L14.5,19z M17,14l0.62-1.38L19,12l-1.38-0.62L17,10l-0.62,1.38 L15,12l1.38,0.62L17,14z M14.5,19l1.09-2.41L18,15.5l-2.41-1.09L14.5,12l-1.09,2.41L11,15.5l2.41,1.09L14.5,19z M17,14l0.62-1.38 L19,12l-1.38-0.62L17,10l-0.62,1.38L15,12l1.38,0.62L17,14z" })
    }
  );
};
const coreWidgets = [
  {
    name: "BarChart",
    label: "Bar Chart",
    icon: /* @__PURE__ */ jsx(BarChartIcon, {})
  },
  {
    name: "Button",
    icon: /* @__PURE__ */ jsx(Button, { width: "24px", height: "24px", color: "secondary" })
  },
  {
    name: "Checkbox",
    icon: /* @__PURE__ */ jsx(CheckBoxIcon, {})
  },
  {
    name: "FlexboxContainer",
    label: "Flexbox Container",
    icon: /* @__PURE__ */ jsx(ViewColumnIcon, { width: "24px", height: "24px", color: "secondary" })
  },
  {
    name: "Image",
    icon: /* @__PURE__ */ jsx(ImageIcon, { width: "24px", height: "24px", color: "secondary" })
  },
  {
    name: "Label",
    icon: /* @__PURE__ */ jsx(LabelOutlinedIcon, {})
  },
  {
    name: "Link",
    icon: /* @__PURE__ */ jsx(LinkIcon, {})
  },
  {
    name: "List",
    icon: /* @__PURE__ */ jsx(FormatListBulletedIcon, {})
  },
  {
    name: "MultiSelect",
    icon: /* @__PURE__ */ jsx(ArrowDropDownCircleIcon, {})
  },
  {
    name: "PieChart",
    label: "Pie Chart",
    icon: /* @__PURE__ */ jsx(PieChartIcon, {})
  },
  {
    name: "Radio",
    icon: /* @__PURE__ */ jsx(RadioButtonCheckedIcon, {})
  },
  {
    name: "RichText",
    icon: /* @__PURE__ */ jsx(TextFormatIcon, {})
  },
  {
    name: "Select",
    icon: /* @__PURE__ */ jsx(ArrowDropDownCircleIcon, {})
  },
  {
    name: "Slider",
    icon: /* @__PURE__ */ jsx(LinearScaleOutlinedIcon, {})
  },
  {
    name: "Tree",
    icon: /* @__PURE__ */ jsx(AccountTreeIcon, {})
  },
  {
    name: "SplitButton",
    label: "Split Button",
    icon: /* @__PURE__ */ jsx(Button, { width: "24px", height: "24px", color: "secondary" })
  },
  {
    name: "TextArea",
    label: "Text Area",
    icon: /* @__PURE__ */ jsx(TextFieldsIcon, {})
  },
  {
    name: "Textfield",
    icon: /* @__PURE__ */ jsx(TextFieldsIcon, {})
  },
  {
    name: "DateTime",
    icon: /* @__PURE__ */ jsx(EventIcon, {})
  }
];
const ForIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 -960 960 960",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M480-80q-75 0-140.5-28T225-185q-49-49-77-114.5T120-440h60q0 125 87.5 212.5T480-140q125 0 212.5-87.5T780-440q0-125-85-212.5T485-740h-23l73 73-41 42-147-147 147-147 41 41-78 78h23q75 0 140.5 28T735-695q49 49 77 114.5T840-440q0 75-28 140.5T735-185q-49 49-114.5 77T480-80Z" })
    }
  );
};
const IfIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 -960 960 960",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M160-448v-60h306l232-232H575v-60h225v225h-60v-122L491-448H160Zm415 288v-60h123L524-395l42-42 174 174v-122h60v225H575Z" })
    }
  );
};
const useFormDescriptionEditorStyles = makeStyles()((theme) => ({
  formDescriptionEditor: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowX: "auto"
  },
  hover: {
    borderColor: theme.palette.primary.main
  },
  header: {
    padding: "4px 8px 4px 8px",
    display: "flex",
    flexDirection: "row"
  },
  disabledOverlay: {
    opacity: 0.5
  },
  main: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    borderTop: "1px solid grey",
    overflowY: "auto"
  },
  widgets: {
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid grey",
    padding: "4px 8px 4px 8px",
    overflowY: "auto"
  },
  widgetKind: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: "8px",
    cursor: "move"
  },
  preview: {
    width: "100%",
    padding: "4px 8px 4px 8px",
    overflowY: "auto"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "auto"
  },
  bottomDropArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "gray",
    height: "60px"
  },
  dragOver: {
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.palette.primary.main
  },
  subscribers: {
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  },
  avatar: {
    fontSize: "1rem",
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  noFormDescriptionEditor: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center"
  }
}));
const FormDescriptionEditorRepresentation = ({
  editingContextId,
  representationId,
  readOnly
}) => {
  const { classes } = useFormDescriptionEditorStyles();
  const noop = () => {
  };
  const [{ value, context }, dispatch] = useMachine(formDescriptionEditorRepresentationMachine);
  const { toast, formDescriptionEditorRepresentation } = value;
  const { id, formDescriptionEditor, message } = context;
  const { data: widgetContributions } = useData(widgetContributionExtensionPoint);
  const allWidgets = [...coreWidgets];
  widgetContributions.forEach((widgetContribution) => {
    allWidgets.push({
      name: widgetContribution.name,
      label: widgetContribution.name,
      icon: widgetContribution.icon
    });
  });
  allWidgets.sort((a, b) => (a.label || a.name).localeCompare(b.label || b.name));
  const input = {
    id,
    editingContextId,
    formDescriptionEditorId: representationId
  };
  const variables = { input };
  const { error } = useSubscription(
    gql(formDescriptionEditorEventSubscription),
    {
      variables,
      fetchPolicy: "no-cache",
      skip: formDescriptionEditorRepresentation !== "ready",
      onData: ({ data }) => {
        const handleDataEvent = {
          type: "HANDLE_SUBSCRIPTION_RESULT",
          result: data
        };
        dispatch(handleDataEvent);
      },
      onComplete: () => {
        dispatch({ type: "HANDLE_COMPLETE" });
      }
    }
  );
  useEffect(() => {
    if (error) {
      const message2 = "An error has occurred while trying to retrieve the form description editor";
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
      dispatch({ type: "HANDLE_COMPLETE" });
    }
  }, [error, dispatch]);
  useEffect(() => {
    if (formDescriptionEditorRepresentation === "loading") {
      const initializeRepresentationEvent = {
        type: "INITIALIZE"
      };
      dispatch(initializeRepresentationEvent);
    }
  }, [formDescriptionEditorRepresentation, dispatch]);
  const handleDragStartPage = (event) => {
    event.dataTransfer.setData("draggedElementId", event.currentTarget.id);
    event.dataTransfer.setData("draggedElementType", "Page");
  };
  const handleDragStartGroup = (event) => {
    event.dataTransfer.setData("draggedElementId", event.currentTarget.id);
    event.dataTransfer.setData("draggedElementType", "Group");
  };
  const handleDragStartWidget = (event) => {
    event.dataTransfer.setData("draggedElementId", event.currentTarget.id);
    event.dataTransfer.setData("draggedElementType", "Widget");
  };
  let content = null;
  if (formDescriptionEditorRepresentation === "ready" && formDescriptionEditor) {
    content = /* @__PURE__ */ jsxs("div", { className: classes.main, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.widgets, children: [
        /* @__PURE__ */ jsx(Typography, { gutterBottom: true, children: "Pages" }),
        /* @__PURE__ */ jsxs(
          "div",
          {
            id: "Page",
            "data-testid": "FormDescriptionEditor-Page",
            draggable: !readOnly,
            className: classes.widgetKind,
            onDragStart: readOnly ? noop : handleDragStartPage,
            children: [
              /* @__PURE__ */ jsx(WebIcon, {}),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", gutterBottom: true, children: "Page" })
            ]
          }
        ),
        /* @__PURE__ */ jsx(Typography, { gutterBottom: true, children: "Groups" }),
        /* @__PURE__ */ jsxs(
          "div",
          {
            id: "Group",
            "data-testid": "FormDescriptionEditor-Group",
            draggable: !readOnly,
            className: classes.widgetKind,
            onDragStart: readOnly ? noop : handleDragStartGroup,
            children: [
              /* @__PURE__ */ jsx(ViewAgendaIcon, {}),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", gutterBottom: true, children: "Group" })
            ]
          }
        ),
        /* @__PURE__ */ jsx(Typography, { gutterBottom: true, children: "Controls" }),
        /* @__PURE__ */ jsxs(
          "div",
          {
            id: "FormElementFor",
            "data-testid": "FormDescriptionEditor-FormElementFor",
            draggable: !readOnly,
            className: classes.widgetKind,
            onDragStart: readOnly ? noop : handleDragStartWidget,
            children: [
              /* @__PURE__ */ jsx(ForIcon, {}),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", gutterBottom: true, children: "For" })
            ]
          }
        ),
        /* @__PURE__ */ jsxs(
          "div",
          {
            id: "FormElementIf",
            "data-testid": "FormDescriptionEditor-FormElementIf",
            draggable: !readOnly,
            className: classes.widgetKind,
            onDragStart: readOnly ? noop : handleDragStartWidget,
            children: [
              /* @__PURE__ */ jsx(IfIcon, {}),
              /* @__PURE__ */ jsx(Typography, { variant: "caption", gutterBottom: true, children: "If" })
            ]
          }
        ),
        /* @__PURE__ */ jsx(Typography, { gutterBottom: true, children: "Widgets" }),
        allWidgets.map((widgetDescriptor) => {
          return /* @__PURE__ */ jsxs(
            "div",
            {
              id: widgetDescriptor.name,
              "data-testid": `FormDescriptionEditor-${widgetDescriptor.name}`,
              draggable: !readOnly,
              className: classes.widgetKind,
              onDragStart: readOnly ? noop : handleDragStartWidget,
              children: [
                widgetDescriptor.icon,
                /* @__PURE__ */ jsx(Typography, { variant: "caption", gutterBottom: true, align: "center", children: widgetDescriptor.label || widgetDescriptor.name })
              ]
            },
            widgetDescriptor.name
          );
        })
      ] }),
      /* @__PURE__ */ jsx("div", { className: classes.preview, children: /* @__PURE__ */ jsx("div", { className: classes.body, children: /* @__PURE__ */ jsx(PageList, {}) }) })
    ] });
  }
  if (formDescriptionEditorRepresentation === "complete") {
    content = /* @__PURE__ */ jsx("div", { className: classes.main + " " + classes.noFormDescriptionEditor, children: /* @__PURE__ */ jsx(Typography, { variant: "h5", align: "center", "data-testid": "FormDescriptionEditor-complete-message", children: "The form description editor does not exist" }) });
  }
  return /* @__PURE__ */ jsx(
    FormDescriptionEditorContextProvider,
    {
      editingContextId,
      representationId,
      readOnly,
      formDescriptionEditor,
      children: /* @__PURE__ */ jsxs("div", { className: classes.formDescriptionEditor, children: [
        /* @__PURE__ */ jsx("div", { className: classes.header, children: /* @__PURE__ */ jsx(Typography, { children: "Form" }) }),
        readOnly ? /* @__PURE__ */ jsx("div", { className: classes.disabledOverlay, children: content }) : content,
        /* @__PURE__ */ jsx(
          Toast,
          {
            message,
            open: toast === "visible",
            onClose: () => dispatch({ type: "HIDE_TOAST" })
          }
        )
      ] })
    }
  );
};
const siriusComponentsFormDescriptionEditors$1 = {
  none: "None"
};
const en = {
  siriusComponentsFormDescriptionEditors: siriusComponentsFormDescriptionEditors$1
};
const siriusComponentsFormDescriptionEditors = {
  none: "Нет"
};
const ru = {
  siriusComponentsFormDescriptionEditors
};
export {
  FormDescriptionEditorRepresentation,
  en as siriusComponentsFormDescriptionEditorsEn,
  ru as siriusComponentsFormDescriptionEditorsRu
};
