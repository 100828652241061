import React, { useState, useContext, useMemo, useEffect, useCallback, memo, useRef, createElement, Fragment as Fragment$1 } from "react";
import { boxToRect, rectToBox, internalsSymbol, Position, useReactFlow, applyNodeChanges, applyEdgeChanges, useStoreApi, useUpdateNodeInternals, useNodes, Handle, useStore as useStore$1, useViewport, useKeyPress, NodeResizeControl, ResizeControlVariant, NodeResizer, ReactFlowProvider, Panel, getSmoothStepPath, BaseEdge, EdgeLabelRenderer, getConnectedEdges, useOnSelectionChange, getRectOfNodes, getTransformForBounds, useNodesInitialized, ConnectionMode, ConnectionLineType, Background, BackgroundVariant, ReactFlow } from "reactflow";
import { jsxs, jsx, Fragment } from "react/jsx-runtime";
import { useData, useMultiToast, DRAG_SOURCES_TYPE, IconOverlay, getCSSColor, useDeletionConfirmationDialog, useComponents, ServerContext, theme, ToastContext, ShareRepresentationModal, useSelection } from "@eclipse-sirius/sirius-components-core";
import { useTheme, ThemeProvider, alpha } from "@mui/material/styles";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { ApolloProvider } from "@apollo/client/react";
import ReactDOM, { createPortal } from "react-dom";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";
import AdjustIcon from "@mui/icons-material/Adjust";
import TonalityIcon from "@mui/icons-material/Tonality";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import GridOffIcon from "@mui/icons-material/GridOff";
import GridOnIcon from "@mui/icons-material/GridOn";
import ImageIcon from "@mui/icons-material/Image";
import ShareIcon from "@mui/icons-material/Share";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ELK from "elkjs/lib/elk.bundled";
import { toSvg } from "html-to-image";
import "reactflow/dist/style.css";
const value$1 = {
  editingContextId: "",
  diagramId: "",
  refreshEventPayloadId: "",
  payload: null,
  readOnly: false
};
const DiagramContext = React.createContext(value$1);
const value = {
  nodeConverters: [],
  nodeLayoutHandlers: [],
  nodeTypeContributions: []
};
const NodeTypeContext = React.createContext(value);
var GQLViewModifier = /* @__PURE__ */ ((GQLViewModifier2) => {
  GQLViewModifier2["Normal"] = "Normal";
  GQLViewModifier2["Faded"] = "Faded";
  GQLViewModifier2["Hidden"] = "Hidden";
  return GQLViewModifier2;
})(GQLViewModifier || {});
var BorderNodePosition = /* @__PURE__ */ ((BorderNodePosition2) => {
  BorderNodePosition2[BorderNodePosition2["NORTH"] = 0] = "NORTH";
  BorderNodePosition2[BorderNodePosition2["EAST"] = 1] = "EAST";
  BorderNodePosition2[BorderNodePosition2["SOUTH"] = 2] = "SOUTH";
  BorderNodePosition2[BorderNodePosition2["WEST"] = 3] = "WEST";
  return BorderNodePosition2;
})(BorderNodePosition || {});
const gap$1 = 20;
const rectangularNodePadding$1 = 8;
const defaultNodeMargin = 8;
const borderNodeOffset = 5;
const defaultWidth = 150;
const defaultHeight = 70;
const labelVerticalPadding = 8;
const labelHorizontalPadding = 16;
const borderNodeReferencePositionRatio = 0.2;
const verticalHelperLinesSnapGap = 10;
const horizontalHelperLinesSnapGap = 10;
const verticalLayoutDirectionGap = 50;
const horizontalLayoutDirectionGap = 50;
const isEastBorderNode = (borderNode) => {
  return borderNode.data.isBorderNode && borderNode.data.borderNodePosition === BorderNodePosition.EAST;
};
const isWestBorderNode = (borderNode) => {
  return borderNode.data.isBorderNode && borderNode.data.borderNodePosition === BorderNodePosition.WEST;
};
const isNorthBorderNode = (borderNode) => {
  return borderNode.data.isBorderNode && borderNode.data.borderNodePosition === BorderNodePosition.NORTH;
};
const isSouthBorderNode = (borderNode) => {
  return borderNode.data.isBorderNode && borderNode.data.borderNodePosition === BorderNodePosition.SOUTH;
};
const getBorderNodeExtent = (parentNode, borderNode) => {
  let coordinateExtent = "parent";
  if (parentNode.width && parentNode.height && borderNode.height && borderNode.width) {
    coordinateExtent = [
      [0 - borderNode.width + borderNodeOffset, 0 - borderNode.height + borderNodeOffset],
      [parentNode.width - borderNodeOffset, parentNode.height - borderNodeOffset]
    ];
  }
  return coordinateExtent;
};
const computeBorderNodeExtents = (nodes) => {
  nodes.filter((node) => node.data.isBorderNode).forEach((borderNode) => {
    const parentNode = nodes.find((node) => node.id === borderNode.parentNode);
    if (parentNode) {
      borderNode.extent = getBorderNodeExtent(parentNode, borderNode);
    }
  });
};
const computeBorderNodePositions = (nodes) => {
  nodes.filter((node) => node.data.isBorderNode).forEach((borderNode) => {
    const parentNode = nodes.find((node) => node.id === borderNode.parentNode);
    if (parentNode) {
      const newPosition = findBorderNodePosition(borderNode.position, borderNode, parentNode);
      borderNode.data.borderNodePosition = newPosition ?? BorderNodePosition.EAST;
    }
  });
};
const findBorderNodePosition = (borderNodePosition, borderNode, parentNode) => {
  if (borderNodePosition && borderNode.width && borderNode.height && (parentNode == null ? void 0 : parentNode.width) && parentNode.height) {
    if (Math.trunc(borderNodePosition.x + borderNode.width) - borderNodeOffset === 0) {
      return BorderNodePosition.WEST;
    }
    if (Math.trunc(borderNodePosition.x) + borderNodeOffset === Math.trunc(parentNode.width)) {
      return BorderNodePosition.EAST;
    }
    if (Math.trunc(borderNodePosition.y + borderNode.height) - borderNodeOffset === 0) {
      return BorderNodePosition.NORTH;
    }
    if (Math.trunc(borderNodePosition.y) + borderNodeOffset === Math.trunc(parentNode.height)) {
      return BorderNodePosition.SOUTH;
    }
  }
  return null;
};
const getNewlyAddedBorderNodePosition = (newlyAddedNode, parentNode, referencePosition) => {
  if (parentNode) {
    if (referencePosition.position.x < (parentNode.width ?? 0) * borderNodeReferencePositionRatio) {
      newlyAddedNode.data.borderNodePosition = BorderNodePosition.WEST;
    } else if (referencePosition.position.x > (parentNode.width ?? 0) * (1 - borderNodeReferencePositionRatio)) {
      newlyAddedNode.data.borderNodePosition = BorderNodePosition.EAST;
    } else if (referencePosition.position.y < (parentNode.height ?? 0) * borderNodeReferencePositionRatio) {
      newlyAddedNode.data.borderNodePosition = BorderNodePosition.NORTH;
    } else if (referencePosition.position.y > (parentNode.height ?? 0) * (1 - borderNodeReferencePositionRatio)) {
      newlyAddedNode.data.borderNodePosition = BorderNodePosition.SOUTH;
    }
  }
};
const computePreviousPosition = (previousNode, node) => {
  let previousPosition = null;
  if (node.data.isNew) {
    previousPosition = null;
  } else if (previousNode) {
    previousPosition = previousNode.position;
  } else {
    previousPosition = node.position;
  }
  return previousPosition;
};
const computePreviousSize = (previousNode, node) => {
  let previousDimensions;
  const nodeDefaultHeight = node.data.defaultHeight ?? defaultHeight;
  const nodeDefaultWidth = node.data.defaultWidth ?? defaultWidth;
  if (node.data.isNew) {
    previousDimensions = {
      height: nodeDefaultHeight,
      width: nodeDefaultWidth
    };
  } else if (previousNode) {
    previousDimensions = {
      height: previousNode.height ?? nodeDefaultHeight,
      width: previousNode.width ?? nodeDefaultWidth
    };
  } else {
    previousDimensions = {
      height: node.height ?? nodeDefaultHeight,
      width: node.width ?? nodeDefaultWidth
    };
  }
  return previousDimensions;
};
const computeNodesBox = (allVisibleNodes, nodes) => {
  if (nodes.length <= 0) {
    return boxToRect({ x: 0, y: 0, x2: 0, y2: 0 });
  }
  const contentBox = nodes.reduce(
    (currentBox, node) => {
      const bounds = getNodeFootprint(allVisibleNodes, node);
      const nodeBox = rectToBox({
        x: bounds.x,
        y: bounds.y,
        width: bounds.width ?? 0,
        height: bounds.height ?? 0
      });
      return getBoundsOfBoxes(currentBox, nodeBox);
    },
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );
  return boxToRect(contentBox);
};
const getBoundsOfBoxes = (box1, box2) => {
  return {
    x: Math.min(box1.x, box2.x),
    y: Math.min(box1.y, box2.y),
    x2: Math.max(box1.x2, box2.x2),
    y2: Math.max(box1.y2, box2.y2)
  };
};
const findNodeIndex = (nodes, nodeId) => {
  return nodes.findIndex((node) => node.id === nodeId);
};
const getDefaultOrMinWidth = (minWidth, node) => {
  return Math.max(minWidth ?? -Infinity, node.data.defaultWidth ?? defaultWidth);
};
const getDefaultOrMinHeight = (minHeight, node) => {
  return Math.max(minHeight ?? -Infinity, node.data.defaultHeight ?? defaultHeight);
};
const getChildNodePosition = (allVisibleNodes, child, headerHeightFootprint, borderWidth, previousSibling) => {
  const maxWestBorderNodeWidth = getChildren(child, allVisibleNodes).filter(isWestBorderNode).map((borderNode) => getNodeFootprint(allVisibleNodes, borderNode).width || 0).reduce((a, b) => Math.max(a, b), 0);
  const maxNorthBorderNodeHeight = getChildren(child, allVisibleNodes).filter(isNorthBorderNode).map((borderNode) => getNodeFootprint(allVisibleNodes, borderNode).height || 0).reduce((a, b) => Math.max(a, b), 0);
  if (!previousSibling) {
    return {
      x: rectangularNodePadding$1 + borderWidth + maxWestBorderNodeWidth,
      y: borderWidth + headerHeightFootprint + maxNorthBorderNodeHeight
    };
  } else {
    const previousSiblingsMaxEastBorderNodeWidth = getChildren(previousSibling, allVisibleNodes).filter(isEastBorderNode).map((borderNode) => getNodeFootprint(allVisibleNodes, borderNode).width || 0).reduce((a, b) => Math.max(a, b), 0);
    return {
      ...child.position,
      x: previousSibling.position.x + previousSiblingsMaxEastBorderNodeWidth + maxWestBorderNodeWidth + (previousSibling.width ?? 0) + gap$1
    };
  }
};
const getHeaderHeightFootprint = (labelElement, insideLabel, headerPosition) => {
  let headerHeightFootprint = 0;
  const withHeader = (insideLabel == null ? void 0 : insideLabel.isHeader) ?? false;
  const displayHeaderSeparator = (insideLabel == null ? void 0 : insideLabel.displayHeaderSeparator) ?? false;
  if (!labelElement) {
    return headerHeightFootprint;
  }
  if (withHeader && (insideLabel == null ? void 0 : insideLabel.headerPosition) === headerPosition) {
    headerHeightFootprint = labelElement.getBoundingClientRect().height;
    if (displayHeaderSeparator) {
      headerHeightFootprint += rectangularNodePadding$1;
    }
  } else {
    headerHeightFootprint = rectangularNodePadding$1;
  }
  return headerHeightFootprint;
};
const getInsideLabelWidthConstraint = (insideLabel, labelElement) => {
  if (insideLabel && labelElement) {
    if (insideLabel.overflowStrategy === "NONE") {
      return (labelElement == null ? void 0 : labelElement.getBoundingClientRect().width) ?? 0;
    }
  }
  return 0;
};
const getNodeFootprint = (allVisibleNodes, node) => {
  const borderNodes = allVisibleNodes.filter((visibleNode) => visibleNode.parentNode === node.id).filter((visibleNode) => visibleNode.data.isBorderNode);
  const footPrint = [node, ...borderNodes].reduce(
    (currentFootPrint, child) => {
      const outsideLabel = child.data.outsideLabels.BOTTOM_MIDDLE;
      let outsideLabelHeightFootPrint = 0;
      if (outsideLabel) {
        const outsideLabelElement = document.getElementById(`${outsideLabel.id}-label`);
        outsideLabelHeightFootPrint = (outsideLabelElement == null ? void 0 : outsideLabelElement.getBoundingClientRect().height) ?? 0;
      }
      const nodeBox = rectToBox({
        x: node !== child && child.data.isBorderNode ? node.position.x + child.position.x : child.position.x,
        y: node !== child && child.data.isBorderNode ? node.position.y + child.position.y : child.position.y,
        width: child.width ?? 0,
        height: (child.height ?? 0) + outsideLabelHeightFootPrint
      });
      return getBoundsOfBoxes(currentFootPrint, nodeBox);
    },
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );
  return boxToRect(footPrint);
};
const spreadPositionedNodesFromNonPositionedNodes = (nodes, previousDiagram) => {
  const positionedNodes = [];
  const nonPositionedNodes = [];
  nodes.forEach((borderNode) => {
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode2) => previousNode2.id === borderNode.id);
    if (previousNode) {
      positionedNodes.push(previousNode);
    } else {
      nonPositionedNodes.push(borderNode);
    }
  });
  return {
    positionedNodes,
    nonPositionedNodes
  };
};
const getLowestSibling = (siblings, previousDiagram, index) => {
  return siblings.map((borderNode) => ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === borderNode.id)).reduce((lowestBorderNode, borderNode) => {
    if (!borderNode)
      return lowestBorderNode;
    if (!lowestBorderNode)
      return borderNode;
    const borderNodeBottom = borderNode.position.y + (borderNode.height ?? 0);
    const lowestBorderNodeBottom = lowestBorderNode.position.y + (lowestBorderNode.height ?? 0);
    return borderNodeBottom > lowestBorderNodeBottom ? borderNode : lowestBorderNode;
  }, siblings[index - 1]);
};
const getRightMostSibling = (siblings, previousDiagram, index) => {
  return siblings.map((borderNode) => ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === borderNode.id)).reduce((rightMostBorderNode, borderNode) => {
    if (!borderNode)
      return rightMostBorderNode;
    if (!rightMostBorderNode)
      return borderNode;
    const borderNodeRight = borderNode.position.x + (borderNode.width ?? 0);
    const rightMostBorderNodeRight = rightMostBorderNode.position.x + (rightMostBorderNode.width ?? 0);
    return borderNodeRight > rightMostBorderNodeRight ? borderNode : rightMostBorderNode;
  }, siblings[index - 1]);
};
const setBorderNodesPosition = (borderNodes, nodeToLayout, previousDiagram, calculateCustomNodeBorderNodePosition) => {
  if (calculateCustomNodeBorderNodePosition) {
    borderNodes.forEach((child) => {
      const previousBorderNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === child.id);
      const previousPosition = computePreviousPosition(previousBorderNode, child);
      if (previousPosition) {
        child.position = calculateCustomNodeBorderNodePosition(
          nodeToLayout,
          {
            ...previousPosition,
            width: child.width ?? 0,
            height: child.height ?? 0
          },
          false
        );
      }
    });
  } else {
    const borderNodesEast = borderNodes.filter(isEastBorderNode);
    borderNodesEast.forEach((child, index) => {
      const previousBorderNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === child.id);
      const previousPosition = computePreviousPosition(previousBorderNode, child);
      if (previousPosition) {
        let newY = previousPosition.y;
        if (nodeToLayout.height && newY > nodeToLayout.height) {
          newY = nodeToLayout.height - borderNodeOffset;
        }
        child.position = {
          x: nodeToLayout.width ?? 0,
          y: newY
        };
      } else {
        child.position = { x: nodeToLayout.width ?? 0, y: defaultNodeMargin };
        const previousSibling = getLowestSibling(borderNodesEast, previousDiagram, index);
        if (previousSibling) {
          child.position = { ...child.position, y: previousSibling.position.y + (previousSibling.height ?? 0) + gap$1 };
          child.extent = getBorderNodeExtent(nodeToLayout, child);
        }
      }
      child.position.x = child.position.x - borderNodeOffset;
    });
    const borderNodesWest = borderNodes.filter(isWestBorderNode);
    borderNodesWest.forEach((child, index) => {
      const previousBorderNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === child.id);
      const previousPosition = computePreviousPosition(previousBorderNode, child);
      if (previousPosition) {
        let newY = previousPosition.y;
        if (nodeToLayout.height && newY > nodeToLayout.height) {
          newY = nodeToLayout.height - borderNodeOffset;
        }
        child.position = {
          x: 0 - (child.width ?? 0),
          y: newY
        };
      } else {
        child.position = { x: 0 - (child.width ?? 0), y: defaultNodeMargin };
        const previousSibling = getLowestSibling(borderNodesWest, previousDiagram, index);
        if (previousSibling) {
          child.position = { ...child.position, y: previousSibling.position.y + (previousSibling.height ?? 0) + gap$1 };
        }
      }
      child.position.x = child.position.x + borderNodeOffset;
    });
    const borderNodesSouth = borderNodes.filter(isSouthBorderNode);
    borderNodesSouth.forEach((child, index) => {
      const previousBorderNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === child.id);
      const previousPosition = computePreviousPosition(previousBorderNode, child);
      if (previousPosition) {
        let newX = previousPosition.x;
        if (nodeToLayout.width && newX > nodeToLayout.width) {
          newX = nodeToLayout.width - borderNodeOffset;
        }
        child.position = {
          x: newX,
          y: nodeToLayout.height ?? 0
        };
      } else {
        child.position = { x: defaultNodeMargin, y: nodeToLayout.height ?? 0 };
        const previousSibling = getRightMostSibling(borderNodesSouth, previousDiagram, index);
        if (previousSibling) {
          child.position = { ...child.position, x: previousSibling.position.x + (previousSibling.width ?? 0) + gap$1 };
          child.extent = getBorderNodeExtent(nodeToLayout, child);
        }
      }
      child.position.y = child.position.y - borderNodeOffset;
    });
    const borderNodesNorth = borderNodes.filter(isNorthBorderNode);
    borderNodesNorth.forEach((child, index) => {
      const previousBorderNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode) => previousNode.id === child.id);
      const previousPosition = computePreviousPosition(previousBorderNode, child);
      if (previousPosition) {
        let newX = previousPosition.x;
        if (nodeToLayout.width && newX > nodeToLayout.width) {
          newX = nodeToLayout.width - borderNodeOffset;
        }
        child.position = {
          x: newX,
          y: 0 - (child.height ?? 0)
        };
      } else {
        child.position = { x: defaultNodeMargin, y: 0 - (child.height ?? 0) };
        const previousSibling = getRightMostSibling(borderNodesNorth, previousDiagram, index);
        if (previousSibling) {
          child.position = { ...child.position, x: previousSibling.position.x + (previousSibling.width ?? 0) + gap$1 };
        }
      }
      child.position.y = child.position.y + borderNodeOffset;
    });
  }
};
const getNorthBorderNodeFootprintWidth = (allVisibleNodes, borderNodes, previousDiagram) => {
  const northBorderNodes = borderNodes.filter(isNorthBorderNode);
  const { positionedNodes: previousBorderNodes, nonPositionedNodes: nonPositionedBorderNode } = spreadPositionedNodesFromNonPositionedNodes(northBorderNodes, previousDiagram);
  const previousBorderNodeFootprint = computeNodesBox(allVisibleNodes, previousBorderNodes);
  return nonPositionedBorderNode.reduce(
    (accumulatedSideWidth, currentBorderNode, currentBordernodeIndex) => accumulatedSideWidth + (getNodeFootprint(allVisibleNodes, currentBorderNode).width ?? 0) + (currentBordernodeIndex < nonPositionedBorderNode.length - 1 ? gap$1 : 0),
    // WARN: Once getNodeFootprint will take the margin into account, we will have to merge the footprint margin of two siblings next to each other and remove the next line under.
    // WARN: Because computeNodeBox rely on getNodeFootprint which do not take the margin into account we must add the margin twice.
    // This will be updated once footprint will take the margin into account
    previousBorderNodeFootprint.width + defaultNodeMargin * 2
  );
};
const getSouthBorderNodeFootprintWidth = (allVisibleNodes, borderNodes, previousDiagram) => {
  const southBorderNodes = borderNodes.filter(isSouthBorderNode);
  const { positionedNodes: previousBorderNodes, nonPositionedNodes: nonPositionedBorderNode } = spreadPositionedNodesFromNonPositionedNodes(southBorderNodes, previousDiagram);
  const previousBorderNodeFootprint = computeNodesBox(allVisibleNodes, previousBorderNodes);
  return nonPositionedBorderNode.reduce(
    (accumulatedSideWidth, currentBorderNode, currentBordernodeIndex) => accumulatedSideWidth + (getNodeFootprint(allVisibleNodes, currentBorderNode).width ?? 0) + // WARN: Once getNodeFootprint will take the margin into account, we will have to merge the footprint margin of two siblings next to each other and remove the next line under.
    (currentBordernodeIndex < nonPositionedBorderNode.length - 1 ? gap$1 : 0),
    // WARN: Because computeNodeBox rely on getNodeFootprint which do not take the margin into account we must add the margin twice.
    // This will be updated once footprint will take the margin into account
    previousBorderNodeFootprint.width + defaultNodeMargin * 2
  );
};
const getEastBorderNodeFootprintHeight = (allVisibleNodes, borderNodes, previousDiagram) => {
  const eastBorderNodes = borderNodes.filter(isEastBorderNode);
  const { positionedNodes: previousBorderNodes, nonPositionedNodes: nonPositionedBorderNode } = spreadPositionedNodesFromNonPositionedNodes(eastBorderNodes, previousDiagram);
  const previousBorderNodeFootprint = computeNodesBox(allVisibleNodes, previousBorderNodes);
  return nonPositionedBorderNode.reduce(
    (accumulatedSideHeight, currentBorderNode, currentBordernodeIndex) => accumulatedSideHeight + (getNodeFootprint(allVisibleNodes, currentBorderNode).height ?? 0) + (currentBordernodeIndex < nonPositionedBorderNode.length - 1 ? gap$1 : 0),
    // WARN: Once getNodeFootprint will take the margin into account, we will have to merge the footprint margin of two siblings next to each other and remove the next line under.
    // WARN: Because computeNodeBox rely on getNodeFootprint which do not take the margin into account we must add the margin twice.
    // This will be updated once footprint will take the margin into account
    previousBorderNodeFootprint.height + defaultNodeMargin * 2
  );
};
const getWestBorderNodeFootprintHeight = (allVisibleNodes, borderNodes, previousDiagram) => {
  const westBorderNodes = borderNodes.filter(isWestBorderNode);
  const { positionedNodes: previousBorderNodes, nonPositionedNodes: nonPositionedBorderNode } = spreadPositionedNodesFromNonPositionedNodes(westBorderNodes, previousDiagram);
  const previousBorderNodeFootprint = computeNodesBox(allVisibleNodes, previousBorderNodes);
  return nonPositionedBorderNode.reduce(
    (accumulatedSideHeight, currentBorderNode, currentBordernodeIndex) => accumulatedSideHeight + (getNodeFootprint(allVisibleNodes, currentBorderNode).height ?? 0) + (currentBordernodeIndex < nonPositionedBorderNode.length - 1 ? gap$1 : 0),
    // WARN: Once getNodeFootprint will take the margin into account, we will have to merge the footprint margin of two siblings next to each other and remove the next line under.
    // WARN: Because computeNodeBox rely on getNodeFootprint which do not take the margin into account we must add the margin twice.
    // This will be updated once footprint will take the margin into account
    previousBorderNodeFootprint.height + defaultNodeMargin * 2
  );
};
const getChildren = (node, allVisibleNodes) => {
  return allVisibleNodes.filter((child) => child.parentNode === node.id);
};
const applyRatioOnNewNodeSizeValue = (node) => {
  const initRatio = (node.data.defaultWidth || defaultWidth) / (node.data.defaultHeight || defaultHeight);
  if (node.width && node.height) {
    const newRatio = node.width / node.height;
    if (initRatio > newRatio) {
      node.width = node.height * initRatio;
    }
    if (initRatio < newRatio) {
      node.height = node.width / initRatio;
    }
  }
};
const isDescendantOf = (parent, candidate, nodeInternals) => {
  if (parent.id === candidate.id) {
    return true;
  } else {
    if (candidate.parentNode) {
      const candidateParent = nodeInternals.get(candidate.parentNode);
      return !!candidateParent && isDescendantOf(parent, candidateParent, nodeInternals);
    }
    return false;
  }
};
const isSiblingOrDescendantOf = (sibling, candidate, nodeInternals) => {
  if (sibling.parentNode === candidate.id) {
    return true;
  } else {
    if (candidate.parentNode) {
      const candidateParent = nodeInternals.get(candidate.parentNode);
      return !!candidateParent && isSiblingOrDescendantOf(sibling, candidateParent, nodeInternals);
    }
    return false;
  }
};
const getUpdatedConnectionHandles = (sourceNode, targetNode, sourcePosition, targetPosition, sourceHandle, targetHandle) => {
  const sourceConnectionHandles = sourceNode.data.connectionHandles.map(
    (nodeConnectionHandle) => {
      if (nodeConnectionHandle.id === sourceHandle && nodeConnectionHandle.type === "source") {
        nodeConnectionHandle.position = sourcePosition;
      }
      return nodeConnectionHandle;
    }
  );
  const targetConnectionHandles = targetNode.data.connectionHandles.map(
    (nodeConnectionHandle) => {
      if (nodeConnectionHandle.id === targetHandle && nodeConnectionHandle.type === "target") {
        nodeConnectionHandle.position = targetPosition;
      }
      return nodeConnectionHandle;
    }
  );
  return {
    sourceConnectionHandles,
    targetConnectionHandles
  };
};
const getEdgeParametersWhileMoving = (movingNode, source, target, nodeInternals, layoutDirection) => {
  const { position: sourcePosition } = getParameters(movingNode, source, target, nodeInternals, layoutDirection);
  const { position: targetPosition } = getParameters(movingNode, target, source, nodeInternals, layoutDirection);
  return {
    sourcePosition,
    targetPosition
  };
};
const getEdgeParameters = (source, target, nodeInternals, layoutDirection) => {
  const { position: sourcePosition } = getParameters(null, source, target, nodeInternals, layoutDirection);
  const { position: targetPosition } = getParameters(null, target, source, nodeInternals, layoutDirection);
  return {
    sourcePosition,
    targetPosition
  };
};
const isVerticalLayoutDirection = (layoutDirection) => layoutDirection === "DOWN" || layoutDirection === "UP";
const isHorizontalLayoutDirection = (layoutDirection) => layoutDirection === "RIGHT" || layoutDirection === "LEFT";
const computeBorderNodeHandlePosition = (borderNodePosition, isInside) => {
  switch (borderNodePosition) {
    case BorderNodePosition.EAST:
      return isInside ? Position.Left : Position.Right;
    case BorderNodePosition.SOUTH:
      return isInside ? Position.Top : Position.Bottom;
    case BorderNodePosition.WEST:
      return isInside ? Position.Right : Position.Left;
    case BorderNodePosition.NORTH:
      return isInside ? Position.Bottom : Position.Top;
    default:
      return isInside ? Position.Left : Position.Right;
  }
};
const getParameters = (movingNode, nodeA, nodeB, nodeInternals, layoutDirection) => {
  var _a, _b, _c, _d;
  if (nodeA.data.isBorderNode) {
    const isInside = isSiblingOrDescendantOf(nodeA, nodeB, nodeInternals);
    return {
      position: computeBorderNodeHandlePosition(nodeA.data.borderNodePosition, isInside)
    };
  }
  let centerA;
  if (movingNode && movingNode.id === nodeA.id) {
    centerA = {
      x: (((_a = movingNode.positionAbsolute) == null ? void 0 : _a.x) ?? 0) + (nodeA.width ?? 0) / 2,
      y: (((_b = movingNode.positionAbsolute) == null ? void 0 : _b.y) ?? 0) + (nodeA.height ?? 0) / 2
    };
  } else {
    centerA = getNodeCenter(nodeA, nodeInternals);
  }
  let centerB;
  if (movingNode && movingNode.id === nodeB.id) {
    centerB = {
      x: (((_c = movingNode.positionAbsolute) == null ? void 0 : _c.x) ?? 0) + (nodeB.width ?? 0) / 2,
      y: (((_d = movingNode.positionAbsolute) == null ? void 0 : _d.y) ?? 0) + (nodeB.height ?? 0) / 2
    };
  } else {
    centerB = getNodeCenter(nodeB, nodeInternals);
  }
  const horizontalDifference = Math.abs(centerA.x - centerB.x);
  const verticalDifference = Math.abs(centerA.y - centerB.y);
  const isDescendant = isDescendantOf(nodeB, nodeA, nodeInternals);
  let position;
  if (isVerticalLayoutDirection(layoutDirection)) {
    if (Math.abs(centerA.y - centerB.y) < verticalLayoutDirectionGap) {
      position = centerA.x <= centerB.x ? Position.Right : Position.Left;
    } else if (isDescendant) {
      position = centerA.y <= centerB.y ? Position.Top : Position.Bottom;
    } else {
      position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
    }
  } else if (isHorizontalLayoutDirection(layoutDirection)) {
    if (Math.abs(centerA.x - centerB.x) < horizontalLayoutDirectionGap) {
      position = centerA.y <= centerB.y ? Position.Bottom : Position.Top;
    } else if (isDescendant) {
      position = centerA.x <= centerB.x ? Position.Left : Position.Right;
    } else {
      position = centerA.x > centerB.x ? Position.Left : Position.Right;
    }
  } else {
    if (horizontalDifference > verticalDifference) {
      if (isDescendant) {
        position = centerA.x <= centerB.x ? Position.Left : Position.Right;
      } else {
        position = centerA.x > centerB.x ? Position.Left : Position.Right;
      }
    } else {
      if (isDescendant) {
        position = centerA.y <= centerB.y ? Position.Top : Position.Bottom;
      } else {
        position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
      }
    }
  }
  return {
    position
  };
};
const getNodeCenter = (node, nodeInternals) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  if (((_a = node.positionAbsolute) == null ? void 0 : _a.x) && ((_b = node.positionAbsolute) == null ? void 0 : _b.y)) {
    return {
      x: (((_c = node.positionAbsolute) == null ? void 0 : _c.x) ?? 0) + (node.width ?? 0) / 2,
      y: (((_d = node.positionAbsolute) == null ? void 0 : _d.y) ?? 0) + (node.height ?? 0) / 2
    };
  } else {
    let position = {
      x: (((_e = node.position) == null ? void 0 : _e.x) ?? 0) + (node.width ?? 0) / 2,
      y: (((_f = node.position) == null ? void 0 : _f.y) ?? 0) + (node.height ?? 0) / 2
    };
    if (node.parentNode) {
      let parentNode = nodeInternals.get(node.parentNode);
      while (parentNode) {
        position = {
          x: position.x + (((_g = parentNode.position) == null ? void 0 : _g.x) ?? 0),
          y: position.y + (((_h = parentNode.position) == null ? void 0 : _h.y) ?? 0)
        };
        let parentNodeId = parentNode.parentNode ?? "";
        parentNode = nodeInternals.get(parentNodeId);
      }
    }
    return position;
  }
};
const getHandleCoordinatesByPosition = (node, handlePosition, handleId, calculateCustomNodeEdgeHandlePosition) => {
  var _a, _b, _c, _d, _e, _f;
  let handle = (((_b = (_a = node[internalsSymbol]) == null ? void 0 : _a.handleBounds) == null ? void 0 : _b.source) ?? []).find(
    (handle2) => handle2.id === handleId
  );
  if (!handle) {
    handle = (((_d = (_c = node[internalsSymbol]) == null ? void 0 : _c.handleBounds) == null ? void 0 : _d.target) ?? []).find((handle2) => handle2.id === handleId);
  }
  let handleXYPosition = { x: 0, y: 0 };
  if (handle && handlePosition) {
    if (calculateCustomNodeEdgeHandlePosition) {
      handleXYPosition = calculateCustomNodeEdgeHandlePosition(node, handlePosition, handle);
    } else {
      let offsetX = handle.width / 2;
      let offsetY = handle.height / 2;
      switch (handlePosition) {
        case Position.Left:
          offsetX = handle.width;
          break;
        case Position.Right:
          offsetX = 0;
          break;
        case Position.Top:
          offsetY = handle.height;
          break;
        case Position.Bottom:
          offsetY = 0;
          break;
      }
      handleXYPosition = {
        x: handle.x + offsetX,
        y: handle.y + offsetY
      };
    }
  }
  return {
    x: (((_e = node.positionAbsolute) == null ? void 0 : _e.x) ?? 0) + handleXYPosition.x,
    y: (((_f = node.positionAbsolute) == null ? void 0 : _f.y) ?? 0) + handleXYPosition.y
  };
};
const getHandlesIdsFromNode = (node) => {
  return node.data.connectionHandles.map((handle) => handle.id).filter((handleId) => !!handleId);
};
const getUpdatedConnectionHandlesIndexByPosition = (node, nodeConnectionHandle, position, handleIdToOtherEndNode, nodeIdToNodeCenter) => {
  if (nodeConnectionHandle.position === position && nodeConnectionHandle.id) {
    let indexCounter = 0;
    const currentOtherEndNode = handleIdToOtherEndNode.get(nodeConnectionHandle.id);
    const currentOtherEndCenter = nodeIdToNodeCenter.get((currentOtherEndNode == null ? void 0 : currentOtherEndNode.id) ?? "");
    node.data.connectionHandles.filter((handle) => handle.position === position && handle.id !== nodeConnectionHandle.id).filter((handle) => !handle.hidden).forEach((handle) => {
      if (currentOtherEndCenter && handle.id && handle.position === position) {
        const otherEndNode = handleIdToOtherEndNode.get(handle.id);
        const otherEndCenter = nodeIdToNodeCenter.get((otherEndNode == null ? void 0 : otherEndNode.id) ?? "");
        if (position === Position.Bottom || position === Position.Top) {
          if (otherEndCenter && otherEndCenter.x < currentOtherEndCenter.x) {
            indexCounter = indexCounter + 1;
          } else {
            indexCounter = indexCounter - 1;
          }
        } else {
          if (otherEndCenter && otherEndCenter.y < currentOtherEndCenter.y) {
            indexCounter = indexCounter + 1;
          } else {
            indexCounter = indexCounter - 1;
          }
        }
      }
    });
    nodeConnectionHandle.index = indexCounter;
  }
  return nodeConnectionHandle;
};
const populateHandleIdToOtherEndNode = (edges, nodes, handlesId, handesIdToOtherEndNode) => {
  edges.forEach((edge) => {
    if (edge.sourceNode && edge.targetNode && edge.sourceHandle && edge.targetHandle) {
      if (handlesId.find((id) => id === edge.sourceHandle)) {
        const node = nodes.find((n) => {
          var _a;
          return n.id === ((_a = edge.targetNode) == null ? void 0 : _a.id);
        });
        if (node) {
          handesIdToOtherEndNode.set(edge.sourceHandle, node);
        }
      }
      if (handlesId.find((id) => id === edge.targetHandle)) {
        const node = nodes.find((n) => {
          var _a;
          return n.id === ((_a = edge.sourceNode) == null ? void 0 : _a.id);
        });
        if (node) {
          handesIdToOtherEndNode.set(edge.targetHandle, node);
        }
      }
    }
  });
};
const layoutHandleIndex = (diagram, nodeInternals) => {
  const handleIdToOtherEndNode = /* @__PURE__ */ new Map();
  const nodeIdToNodeCenter = /* @__PURE__ */ new Map();
  diagram.nodes.forEach((node) => {
    const handlesId = getHandlesIdsFromNode(node);
    populateHandleIdToOtherEndNode(diagram.edges, diagram.nodes, handlesId, handleIdToOtherEndNode);
    nodeIdToNodeCenter.set(node.id, getNodeCenter(node, nodeInternals));
  });
  const nodeIdToConnectionHandle = /* @__PURE__ */ new Map();
  handleIdToOtherEndNode.forEach((node) => {
    const connectionHandles = node.data.connectionHandles.map((nodeConnectionHandle) => {
      Object.values(Position).map((position) => {
        nodeConnectionHandle = getUpdatedConnectionHandlesIndexByPosition(
          node,
          nodeConnectionHandle,
          position,
          handleIdToOtherEndNode,
          nodeIdToNodeCenter
        );
      });
      return nodeConnectionHandle;
    });
    nodeIdToConnectionHandle.set(node.id, connectionHandles);
  });
  diagram.nodes = diagram.nodes.map((node) => {
    const newConnectionHandles = nodeIdToConnectionHandle.get(node.id);
    if (newConnectionHandles) {
      node.data = { ...node.data, connectionHandles: newConnectionHandles };
    }
    return node;
  });
};
const layoutHandlePosition = (diagram, diagramDescription, nodeInternals) => {
  diagram.edges.forEach((edge) => {
    const { sourceNode: sourceEdgeNode, targetNode: targetEdgeNode, sourceHandle, targetHandle } = edge;
    const sourceNode = diagram.nodes.find((node) => node.id === (sourceEdgeNode == null ? void 0 : sourceEdgeNode.id));
    const targetNode = diagram.nodes.find((node) => node.id === (targetEdgeNode == null ? void 0 : targetEdgeNode.id));
    if (sourceNode && targetNode && sourceHandle && targetHandle) {
      const { sourcePosition, targetPosition } = getEdgeParameters(
        sourceNode,
        targetNode,
        nodeInternals,
        diagramDescription.arrangeLayoutDirection
      );
      const nodeSourceConnectionHandle = sourceNode.data.connectionHandles.find(
        (connectionHandle) => connectionHandle.id === sourceHandle
      );
      const nodeTargetConnectionHandle = targetNode.data.connectionHandles.find(
        (connectionHandle) => connectionHandle.id === targetHandle
      );
      if ((nodeSourceConnectionHandle == null ? void 0 : nodeSourceConnectionHandle.position) !== sourcePosition || (nodeTargetConnectionHandle == null ? void 0 : nodeTargetConnectionHandle.position) !== targetPosition) {
        const { sourceConnectionHandles, targetConnectionHandles } = getUpdatedConnectionHandles(
          sourceNode,
          targetNode,
          sourcePosition,
          targetPosition,
          sourceHandle,
          targetHandle
        );
        diagram.nodes = diagram.nodes.map((node) => {
          if (edge.sourceNode && edge.targetNode) {
            if (edge.sourceNode.id === node.id) {
              node.data = { ...node.data, connectionHandles: sourceConnectionHandles };
            }
            if (edge.targetNode.id === node.id) {
              node.data = { ...node.data, connectionHandles: targetConnectionHandles };
            }
          }
          return node;
        });
      }
    }
  });
};
const layoutHandles = (diagram, diagramDescription, nodeInternals) => {
  layoutHandlePosition(diagram, diagramDescription, nodeInternals);
  layoutHandleIndex(diagram, nodeInternals);
};
const AlignmentMap = {
  TOP_CENTER: { primaryAlignment: "TOP", secondaryAlignment: "CENTER", isPrimaryVerticalAlignment: true },
  TOP_LEFT: { primaryAlignment: "TOP", secondaryAlignment: "LEFT", isPrimaryVerticalAlignment: true },
  TOP_RIGHT: { primaryAlignment: "TOP", secondaryAlignment: "RIGHT", isPrimaryVerticalAlignment: true },
  BOTTOM_CENTER: { primaryAlignment: "BOTTOM", secondaryAlignment: "CENTER", isPrimaryVerticalAlignment: true },
  BOTTOM_LEFT: { primaryAlignment: "BOTTOM", secondaryAlignment: "LEFT", isPrimaryVerticalAlignment: true },
  BOTTOM_RIGHT: { primaryAlignment: "BOTTOM", secondaryAlignment: "RIGHT", isPrimaryVerticalAlignment: true },
  MIDDLE_CENTER: { primaryAlignment: "MIDDLE", secondaryAlignment: "CENTER", isPrimaryVerticalAlignment: true },
  MIDDLE_LEFT: { primaryAlignment: "MIDDLE", secondaryAlignment: "LEFT", isPrimaryVerticalAlignment: true },
  MIDDLE_RIGHT: { primaryAlignment: "MIDDLE", secondaryAlignment: "RIGHT", isPrimaryVerticalAlignment: true }
};
const convertInsideLabel = (gqlInsideLabel, data, borderStyle, hasVisibleChild = true, padding = "8px 16px") => {
  if (!gqlInsideLabel) {
    return null;
  }
  const labelStyle2 = gqlInsideLabel.style;
  const insideLabel = {
    id: gqlInsideLabel.id,
    text: gqlInsideLabel.text,
    isHeader: gqlInsideLabel.isHeader,
    displayHeaderSeparator: gqlInsideLabel.displayHeaderSeparator,
    style: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding,
      textAlign: convertLabelTextAlign(gqlInsideLabel.textAlign),
      maxWidth: gqlInsideLabel.overflowStrategy === "NONE" ? void 0 : labelStyle2.maxWidth,
      ...convertLabelStyle(labelStyle2)
    },
    contentStyle: {
      ...convertContentStyle(labelStyle2)
    },
    iconURL: labelStyle2.iconURL,
    overflowStrategy: gqlInsideLabel.overflowStrategy,
    headerSeparatorStyle: {
      width: "100%"
    },
    headerPosition: void 0
  };
  const alignement = AlignmentMap[gqlInsideLabel.insideLabelLocation];
  if (alignement && alignement.isPrimaryVerticalAlignment) {
    if (alignement.primaryAlignment === "TOP") {
      if (insideLabel.isHeader) {
        insideLabel.headerPosition = "TOP";
      }
      if (insideLabel.displayHeaderSeparator && hasVisibleChild) {
        insideLabel.headerSeparatorStyle.borderBottom = borderStyle;
      }
      data.style = { ...data.style, display: "flex", flexDirection: "column", justifyContent: "flex-start" };
    }
    if (alignement.primaryAlignment === "BOTTOM") {
      if (insideLabel.isHeader) {
        insideLabel.headerPosition = "BOTTOM";
      }
      if (insideLabel.displayHeaderSeparator && hasVisibleChild) {
        insideLabel.headerSeparatorStyle.borderTop = borderStyle;
      }
      data.style = { ...data.style, display: "flex", flexDirection: "column", justifyContent: "flex-end" };
    }
    if (alignement.primaryAlignment === "MIDDLE") {
      data.style = { ...data.style, display: "flex", flexDirection: "column", justifyContent: "center" };
    }
    data.style = { ...data.style, alignItems: "stretch" };
    if (alignement.secondaryAlignment === "LEFT" || alignement.secondaryAlignment === "CENTER") {
      insideLabel.style = { ...insideLabel.style, marginRight: "auto" };
    }
    if (alignement.secondaryAlignment === "RIGHT" || alignement.secondaryAlignment === "CENTER") {
      insideLabel.style = { ...insideLabel.style, marginLeft: "auto" };
    }
  }
  return insideLabel;
};
const convertOutsideLabels = (gqlOutsideLabels) => {
  const outsideLabels = {};
  const reducer = (allOutsideLabels, gqlOutsideLabel) => {
    const {
      id,
      text,
      style: labelStyle2,
      style: { iconURL },
      overflowStrategy
    } = gqlOutsideLabel;
    allOutsideLabels[gqlOutsideLabel.outsideLabelLocation] = {
      id,
      text,
      iconURL,
      style: {
        justifyContent: "center",
        textAlign: convertLabelTextAlign(gqlOutsideLabel.textAlign),
        maxWidth: gqlOutsideLabel.overflowStrategy === "NONE" ? void 0 : labelStyle2.maxWidth,
        ...convertLabelStyle(labelStyle2)
      },
      contentStyle: {
        ...convertContentStyle(labelStyle2)
      },
      overflowStrategy
    };
    return allOutsideLabels;
  };
  return gqlOutsideLabels.reduce(reducer, outsideLabels);
};
const convertLabelStyle = (gqlLabelStyle) => {
  const style = {};
  if (gqlLabelStyle.bold) {
    style.fontWeight = "bold";
  }
  if (gqlLabelStyle.italic) {
    style.fontStyle = "italic";
  }
  if (gqlLabelStyle.fontSize) {
    style.fontSize = gqlLabelStyle.fontSize;
  }
  if (gqlLabelStyle.color) {
    style.color = gqlLabelStyle.color;
  }
  let decoration = "";
  if (gqlLabelStyle.strikeThrough) {
    decoration = decoration + "line-through";
  }
  if (gqlLabelStyle.underline) {
    const separator = decoration.length > 0 ? " " : "";
    decoration = decoration + separator + "underline";
  }
  if (decoration.length > 0) {
    style.textDecoration = decoration;
  }
  return style;
};
const convertLabelTextAlign = (textAlign) => {
  switch (textAlign) {
    case "JUSTIFY":
      return "justify";
    case "LEFT":
      return "left";
    case "RIGHT":
      return "right";
    case "CENTER":
    default:
      return "center";
  }
};
const convertContentStyle = (gqlLabelStyle) => {
  const style = {};
  style.background = gqlLabelStyle.background;
  style.borderColor = gqlLabelStyle.borderColor;
  style.borderRadius = gqlLabelStyle.borderRadius;
  style.borderWidth = gqlLabelStyle.borderSize;
  style.borderStyle = convertLineStyle(gqlLabelStyle.borderStyle);
  return style;
};
const defaultPosition$3 = { x: 0, y: 0 };
const toIconLabelNode = (gqlDiagram, gqlNode, gqlParentNode, nodeDescription, isBorderNode) => {
  const {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    id,
    insideLabel,
    outsideLabels,
    state,
    pinned,
    style,
    labelEditable
  } = gqlNode;
  const connectionHandles = [];
  const gqlNodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.find(
    (nodeLayoutData2) => nodeLayoutData2.id === id
  );
  const isNew = gqlNodeLayoutData === void 0;
  const resizedByUser = (gqlNodeLayoutData == null ? void 0 : gqlNodeLayoutData.resizedByUser) ?? false;
  const data = {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    style: {
      textAlign: "left",
      background: style.background
    },
    insideLabel: null,
    outsideLabels: convertOutsideLabels(outsideLabels),
    isBorderNode,
    borderNodePosition: isBorderNode ? BorderNodePosition.WEST : null,
    faded: state === GQLViewModifier.Faded,
    pinned,
    nodeDescription,
    defaultWidth: gqlNode.defaultWidth,
    defaultHeight: gqlNode.defaultHeight,
    labelEditable,
    connectionHandles,
    isNew,
    resizedByUser,
    isListChild: isListLayoutStrategy(gqlParentNode == null ? void 0 : gqlParentNode.childrenLayoutStrategy),
    isDropNodeTarget: false,
    isDropNodeCandidate: false,
    isHovered: false
  };
  data.insideLabel = convertInsideLabel(insideLabel, data, "", false, "0 8px 0 8px");
  const node = {
    id,
    type: "iconLabelNode",
    data,
    position: defaultPosition$3,
    hidden: state === GQLViewModifier.Hidden
  };
  if (gqlParentNode) {
    node.parentNode = gqlParentNode.id;
  }
  const nodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.filter((data2) => data2.id === id)[0];
  if (nodeLayoutData) {
    const {
      position,
      size: { height, width }
    } = nodeLayoutData;
    node.position = position;
    node.height = height;
    node.width = width;
    node.style = {
      ...node.style,
      width: `${node.width}px`,
      height: `${node.height}px`
    };
  }
  return node;
};
class IconLabelNodeConverter {
  canHandle(gqlNode) {
    return gqlNode.style.__typename === "IconLabelNodeStyle";
  }
  handle(_convertEngine, gqlDiagram, gqlNode, _gqlEdges, parentNode, isBorderNode, nodes, _diagramDescription, nodeDescriptions) {
    const nodeDescription = nodeDescriptions.find((description) => description.id === gqlNode.descriptionId);
    if (nodeDescription) {
      nodes.push(toIconLabelNode(gqlDiagram, gqlNode, parentNode, nodeDescription, isBorderNode));
    }
  }
}
const convertHandles = (gqlNode, gqlEdges) => {
  const connectionHandles = [];
  let sourceHandlesCounter = 0;
  let targetHandlesCounter = 0;
  gqlEdges.forEach((edge) => {
    if (edge.sourceId === gqlNode.id) {
      connectionHandles.push({
        id: `handle--source--${gqlNode.id}--${sourceHandlesCounter}`,
        index: 0,
        nodeId: gqlNode.id,
        position: Position.Right,
        type: "source",
        hidden: edge.state === GQLViewModifier.Hidden
      });
      sourceHandlesCounter += 1;
    }
    if (edge.targetId === gqlNode.id) {
      connectionHandles.push({
        id: `handle--target--${gqlNode.id}--${targetHandlesCounter}`,
        index: 0,
        nodeId: gqlNode.id,
        position: Position.Left,
        type: "target",
        hidden: edge.state === GQLViewModifier.Hidden
      });
      targetHandlesCounter += 1;
    }
  });
  connectionHandles.push({
    id: `handle--source--${gqlNode.id}--${sourceHandlesCounter}`,
    index: 0,
    nodeId: gqlNode.id,
    position: Position.Right,
    type: "source",
    hidden: true
  });
  connectionHandles.push({
    id: `handle--target--${gqlNode.id}--${targetHandlesCounter}`,
    index: 0,
    nodeId: gqlNode.id,
    position: Position.Left,
    type: "target",
    hidden: true
  });
  return connectionHandles;
};
const defaultPosition$2 = { x: 0, y: 0 };
const toImageNode = (gqlDiagram, gqlNode, gqlParentNode, nodeDescription, isBorderNode, gqlEdges) => {
  var _a;
  const {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    insideLabel,
    outsideLabels,
    id,
    state,
    pinned,
    style,
    labelEditable
  } = gqlNode;
  const connectionHandles = convertHandles(gqlNode, gqlEdges);
  const gqlNodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.find(
    (nodeLayoutData2) => nodeLayoutData2.id === id
  );
  const isNew = gqlNodeLayoutData === void 0;
  const resizedByUser = (gqlNodeLayoutData == null ? void 0 : gqlNodeLayoutData.resizedByUser) ?? false;
  const data = {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    insideLabel: null,
    outsideLabels: convertOutsideLabels(outsideLabels),
    imageURL: style.imageURL,
    style: {
      borderColor: style.borderColor,
      borderRadius: style.borderRadius,
      borderWidth: style.borderSize,
      borderStyle: convertLineStyle(style.borderStyle)
    },
    faded: state === GQLViewModifier.Faded,
    pinned,
    nodeDescription,
    defaultWidth: gqlNode.defaultWidth,
    defaultHeight: gqlNode.defaultHeight,
    isBorderNode,
    borderNodePosition: isBorderNode ? BorderNodePosition.WEST : null,
    labelEditable,
    positionDependentRotation: style.positionDependentRotation,
    connectionHandles,
    isNew,
    resizedByUser,
    isListChild: isListLayoutStrategy(gqlParentNode == null ? void 0 : gqlParentNode.childrenLayoutStrategy),
    isDropNodeTarget: false,
    isDropNodeCandidate: false,
    isHovered: false
  };
  data.insideLabel = convertInsideLabel(
    insideLabel,
    data,
    `${style.borderSize}px ${style.borderStyle} ${style.borderColor}`,
    (_a = gqlNode.childNodes) == null ? void 0 : _a.some((child) => child.state !== GQLViewModifier.Hidden)
  );
  const node = {
    id,
    type: "freeFormNode",
    data,
    position: defaultPosition$2,
    hidden: state === GQLViewModifier.Hidden
  };
  if (gqlParentNode) {
    node.parentNode = gqlParentNode.id;
  }
  const nodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.filter((data2) => data2.id === id)[0];
  if (nodeLayoutData) {
    const {
      position,
      size: { height, width }
    } = nodeLayoutData;
    node.position = position;
    node.height = height;
    node.width = width;
    node.style = {
      ...node.style,
      width: `${node.width}px`,
      height: `${node.height}px`
    };
  }
  return node;
};
class ImageNodeConverter {
  canHandle(gqlNode) {
    return gqlNode.style.__typename === "ImageNodeStyle";
  }
  handle(convertEngine, gqlDiagram, gqlNode, gqlEdges, parentNode, isBorderNode, nodes, diagramDescription, nodeDescriptions) {
    const nodeDescription = nodeDescriptions.find((description) => description.id === gqlNode.descriptionId);
    if (nodeDescription) {
      nodes.push(toImageNode(gqlDiagram, gqlNode, parentNode, nodeDescription, isBorderNode, gqlEdges));
    }
    const borderNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.borderNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    const childNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.childNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.borderNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      borderNodeDescriptions
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.childNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      childNodeDescriptions
    );
  }
}
const defaultPosition$1 = { x: 0, y: 0 };
const toListNode = (gqlDiagram, gqlNode, gqlParentNode, nodeDescription, isBorderNode, gqlEdges) => {
  var _a;
  const {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    insideLabel,
    outsideLabels,
    id,
    state,
    pinned,
    style,
    labelEditable
  } = gqlNode;
  const connectionHandles = convertHandles(gqlNode, gqlEdges);
  const gqlNodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.find(
    (nodeLayoutData2) => nodeLayoutData2.id === id
  );
  const isNew = gqlNodeLayoutData === void 0;
  const resizedByUser = (gqlNodeLayoutData == null ? void 0 : gqlNodeLayoutData.resizedByUser) ?? false;
  const data = {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    style: {
      background: style.background,
      borderTopColor: style.borderColor,
      borderBottomColor: style.borderColor,
      borderLeftColor: style.borderColor,
      borderRightColor: style.borderColor,
      borderRadius: style.borderRadius,
      borderTopWidth: style.borderSize,
      borderBottomWidth: style.borderSize,
      borderLeftWidth: style.borderSize,
      borderRightWidth: style.borderSize,
      borderStyle: style.borderStyle
    },
    insideLabel: null,
    outsideLabels: convertOutsideLabels(outsideLabels),
    isBorderNode,
    borderNodePosition: isBorderNode ? BorderNodePosition.WEST : null,
    faded: state === GQLViewModifier.Faded,
    pinned,
    labelEditable,
    nodeDescription,
    connectionHandles,
    defaultWidth: gqlNode.defaultWidth,
    defaultHeight: gqlNode.defaultHeight,
    isNew,
    areChildNodesDraggable: isListLayoutStrategy(gqlNode.childrenLayoutStrategy) ? gqlNode.childrenLayoutStrategy.areChildNodesDraggable : true,
    topGap: isListLayoutStrategy(gqlNode.childrenLayoutStrategy) ? gqlNode.childrenLayoutStrategy.topGap : 0,
    bottomGap: isListLayoutStrategy(gqlNode.childrenLayoutStrategy) ? gqlNode.childrenLayoutStrategy.bottomGap : 0,
    isListChild: isListLayoutStrategy(gqlParentNode == null ? void 0 : gqlParentNode.childrenLayoutStrategy),
    resizedByUser,
    growableNodeIds: isListLayoutStrategy(gqlNode.childrenLayoutStrategy) ? gqlNode.childrenLayoutStrategy.growableNodeIds : [],
    isDropNodeTarget: false,
    isDropNodeCandidate: false,
    isHovered: false
  };
  data.insideLabel = convertInsideLabel(
    insideLabel,
    data,
    `${style.borderSize}px ${style.borderStyle} ${style.borderColor}`,
    (_a = gqlNode.childNodes) == null ? void 0 : _a.some((child) => child.state !== GQLViewModifier.Hidden)
  );
  const node = {
    id,
    type: "listNode",
    data,
    position: defaultPosition$1,
    hidden: state === GQLViewModifier.Hidden
  };
  if (gqlParentNode) {
    node.parentNode = gqlParentNode.id;
  }
  const nodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.filter((data2) => data2.id === id)[0];
  if (nodeLayoutData) {
    const {
      position,
      size: { height, width }
    } = nodeLayoutData;
    node.position = position;
    node.height = height;
    node.width = width;
    node.style = {
      ...node.style,
      width: `${node.width}px`,
      height: `${node.height}px`
    };
  }
  return node;
};
const adaptChildrenBorderNodes = (nodes, gqlChildrenNodes) => {
  const visibleChildrenNodes = nodes.filter(
    (child) => gqlChildrenNodes.map((gqlChild) => gqlChild.id).find((gqlChildId) => gqlChildId === child.id) !== void 0
  ).filter((child) => !child.hidden);
  visibleChildrenNodes.forEach((child, index) => {
    child.data.style = {
      ...child.data.style,
      borderTopWidth: "0",
      borderLeftWidth: "0",
      borderRightWidth: "0"
    };
    if (index === visibleChildrenNodes.length - 1) {
      child.data.style = {
        ...child.data.style,
        borderBottomWidth: "0"
      };
    }
  });
};
class ListNodeConverter {
  canHandle(gqlNode) {
    var _a;
    return gqlNode.style.__typename === "RectangularNodeStyle" && ((_a = gqlNode.childrenLayoutStrategy) == null ? void 0 : _a.kind) === "List";
  }
  handle(convertEngine, gqlDiagram, gqlNode, gqlEdges, parentNode, isBorderNode, nodes, diagramDescription, nodeDescriptions) {
    const nodeDescription = nodeDescriptions.find((description) => description.id === gqlNode.descriptionId);
    if (nodeDescription) {
      nodes.push(toListNode(gqlDiagram, gqlNode, parentNode, nodeDescription, isBorderNode, gqlEdges));
    }
    const borderNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.borderNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    const childNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.childNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.borderNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      borderNodeDescriptions
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.childNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      childNodeDescriptions
    );
    adaptChildrenBorderNodes(nodes, gqlNode.childNodes ?? []);
  }
}
const defaultPosition = { x: 0, y: 0 };
const toRectangularNode = (gqlDiagram, gqlNode, gqlParentNode, nodeDescription, isBorderNode, gqlEdges) => {
  var _a;
  const {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    id,
    insideLabel,
    outsideLabels,
    state,
    pinned,
    style,
    labelEditable
  } = gqlNode;
  const connectionHandles = convertHandles(gqlNode, gqlEdges);
  const gqlNodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.find(
    (nodeLayoutData2) => nodeLayoutData2.id === id
  );
  const isNew = gqlNodeLayoutData === void 0;
  const resizedByUser = (gqlNodeLayoutData == null ? void 0 : gqlNodeLayoutData.resizedByUser) ?? false;
  const data = {
    targetObjectId,
    targetObjectLabel,
    targetObjectKind,
    descriptionId,
    style: {
      display: "flex",
      background: style.background,
      borderColor: style.borderColor,
      borderRadius: style.borderRadius,
      borderWidth: style.borderSize,
      borderStyle: convertLineStyle(style.borderStyle)
    },
    insideLabel: null,
    outsideLabels: convertOutsideLabels(outsideLabels),
    imageURL: null,
    faded: state === GQLViewModifier.Faded,
    pinned,
    nodeDescription,
    defaultWidth: gqlNode.defaultWidth,
    defaultHeight: gqlNode.defaultHeight,
    isBorderNode,
    borderNodePosition: isBorderNode ? BorderNodePosition.EAST : null,
    labelEditable,
    positionDependentRotation: false,
    connectionHandles,
    isNew,
    resizedByUser,
    isListChild: isListLayoutStrategy(gqlParentNode == null ? void 0 : gqlParentNode.childrenLayoutStrategy),
    isDropNodeTarget: false,
    isDropNodeCandidate: false,
    isHovered: false
  };
  data.insideLabel = convertInsideLabel(
    insideLabel,
    data,
    `${style.borderSize}px ${style.borderStyle} ${style.borderColor}`,
    (_a = gqlNode.childNodes) == null ? void 0 : _a.some((child) => child.state !== GQLViewModifier.Hidden)
  );
  const node = {
    id,
    type: "freeFormNode",
    data,
    position: defaultPosition,
    hidden: state === GQLViewModifier.Hidden
  };
  if (gqlParentNode) {
    node.parentNode = gqlParentNode.id;
  }
  const nodeLayoutData = gqlDiagram.layoutData.nodeLayoutData.filter((data2) => data2.id === id)[0];
  if (nodeLayoutData) {
    const {
      position,
      size: { height, width }
    } = nodeLayoutData;
    node.position = position;
    node.height = height;
    node.width = width;
    node.style = {
      ...node.style,
      width: `${node.width}px`,
      height: `${node.height}px`
    };
  }
  return node;
};
class RectangleNodeConverter {
  canHandle(gqlNode) {
    var _a;
    return gqlNode.style.__typename === "RectangularNodeStyle" && ((_a = gqlNode.childrenLayoutStrategy) == null ? void 0 : _a.kind) !== "List";
  }
  handle(convertEngine, gqlDiagram, gqlNode, gqlEdges, parentNode, isBorderNode, nodes, diagramDescription, nodeDescriptions) {
    const nodeDescription = nodeDescriptions.find((description) => description.id === gqlNode.descriptionId);
    if (nodeDescription) {
      nodes.push(toRectangularNode(gqlDiagram, gqlNode, parentNode, nodeDescription, isBorderNode, gqlEdges));
    }
    const borderNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.borderNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    const childNodeDescriptions = ((nodeDescription == null ? void 0 : nodeDescription.childNodeDescriptionIds) ?? []).flatMap(
      (nodeDescriptionId) => diagramDescription.nodeDescriptions.filter((nodeDescription2) => nodeDescription2.id === nodeDescriptionId)
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.borderNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      borderNodeDescriptions
    );
    convertEngine.convertNodes(
      gqlDiagram,
      gqlNode.childNodes ?? [],
      gqlNode,
      nodes,
      diagramDescription,
      childNodeDescriptions
    );
  }
}
const nodeDepth = (nodeId2node, nodeId) => {
  const node = nodeId2node.get(nodeId);
  let depth = 0;
  let parentNode = (node == null ? void 0 : node.parentNode) ? nodeId2node.get(node.parentNode) : void 0;
  while (parentNode) {
    depth = depth + 1;
    parentNode = parentNode.parentNode ? nodeId2node.get(parentNode.parentNode) : void 0;
  }
  return depth;
};
const convertEdgeLabel = (gqlEdgeLabel) => {
  return {
    id: gqlEdgeLabel.id,
    text: gqlEdgeLabel.text,
    iconURL: gqlEdgeLabel.style.iconURL,
    style: {
      ...convertLabelStyle(gqlEdgeLabel.style)
    },
    contentStyle: {
      ...convertContentStyle(gqlEdgeLabel.style)
    }
  };
};
const convertLineStyle = (lineStyle) => {
  if (lineStyle === "Dash") {
    return "dashed";
  } else if (lineStyle === "Dot") {
    return "dotted";
  } else if (lineStyle === "Dash_Dot") {
    return "dashed";
  }
  return "solid";
};
const isListLayoutStrategy = (strategy) => (strategy == null ? void 0 : strategy.kind) === "List";
const defaultNodeConverters = [
  new RectangleNodeConverter(),
  new ImageNodeConverter(),
  new IconLabelNodeConverter(),
  new ListNodeConverter()
];
const convertDiagram = (gqlDiagram, nodeConverterContributions, diagramDescription) => {
  const nodes = [];
  const convertEngine = {
    convertNodes(gqlDiagram2, gqlNodesToConvert, parentNode, nodes2, diagramDescription2, nodeDescriptions) {
      gqlNodesToConvert.forEach((node) => {
        var _a;
        const nodeConverter = [
          ...defaultNodeConverters,
          ...nodeConverterContributions
        ].find((handler) => handler.canHandle(node));
        if (nodeConverter) {
          const isBorderNode = !!((_a = parentNode == null ? void 0 : parentNode.borderNodes) == null ? void 0 : _a.map((borderNode) => borderNode.id).includes(node.id));
          nodeConverter.handle(
            this,
            gqlDiagram2,
            node,
            gqlDiagram2.edges,
            parentNode,
            isBorderNode,
            nodes2,
            diagramDescription2,
            nodeDescriptions
          );
        }
      });
    }
  };
  convertEngine.convertNodes(
    gqlDiagram,
    gqlDiagram.nodes,
    null,
    nodes,
    diagramDescription,
    diagramDescription.nodeDescriptions
  );
  const nodeId2node = /* @__PURE__ */ new Map();
  nodes.forEach((node) => nodeId2node.set(node.id, node));
  const nodeId2Depth = /* @__PURE__ */ new Map();
  nodes.forEach((node) => nodeId2Depth.set(node.id, nodeDepth(nodeId2node, node.id)));
  let usedHandles = [];
  const edges = gqlDiagram.edges.map((gqlEdge) => {
    const sourceNode = nodeId2node.get(gqlEdge.sourceId);
    const targetNode = nodeId2node.get(gqlEdge.targetId);
    const data = {
      targetObjectId: gqlEdge.targetObjectId,
      targetObjectKind: gqlEdge.targetObjectKind,
      targetObjectLabel: gqlEdge.targetObjectLabel,
      label: null,
      faded: gqlEdge.state === GQLViewModifier.Faded,
      centerLabelEditable: gqlEdge.centerLabelEditable
    };
    if (gqlEdge.beginLabel) {
      data.beginLabel = convertEdgeLabel(gqlEdge.beginLabel);
    }
    if (gqlEdge.centerLabel) {
      data.label = convertEdgeLabel(gqlEdge.centerLabel);
    }
    if (gqlEdge.endLabel) {
      data.endLabel = convertEdgeLabel(gqlEdge.endLabel);
    }
    const sourceHandle = sourceNode == null ? void 0 : sourceNode.data.connectionHandles.filter((connectionHandle) => connectionHandle.type === "source").find((connectionHandle) => !usedHandles.find((usedHandle) => usedHandle === connectionHandle.id));
    const targetHandle = targetNode == null ? void 0 : targetNode.data.connectionHandles.filter((connectionHandle) => connectionHandle.type === "target").find((connectionHandle) => !usedHandles.find((usedHandle) => usedHandle === connectionHandle.id));
    if ((sourceHandle == null ? void 0 : sourceHandle.id) && (targetHandle == null ? void 0 : targetHandle.id)) {
      usedHandles.push(sourceHandle == null ? void 0 : sourceHandle.id, targetHandle.id);
    }
    let strokeDasharray = void 0;
    if (gqlEdge.style.lineStyle === "Dash") {
      strokeDasharray = "5,5";
    } else if (gqlEdge.style.lineStyle === "Dot") {
      strokeDasharray = "2,2";
    } else if (gqlEdge.style.lineStyle === "Dash_Dot") {
      strokeDasharray = "10,5,2,2,2,5";
    }
    return {
      id: gqlEdge.id,
      type: "multiLabelEdge",
      source: gqlEdge.sourceId,
      target: gqlEdge.targetId,
      markerEnd: `${gqlEdge.style.targetArrow}--${gqlEdge.id}--markerEnd`,
      markerStart: `${gqlEdge.style.sourceArrow}--${gqlEdge.id}--markerStart`,
      zIndex: 2e3,
      style: {
        stroke: gqlEdge.style.color,
        strokeWidth: gqlEdge.style.size,
        strokeDasharray
      },
      data,
      hidden: gqlEdge.state === GQLViewModifier.Hidden,
      sourceHandle: sourceHandle == null ? void 0 : sourceHandle.id,
      targetHandle: targetHandle == null ? void 0 : targetHandle.id,
      sourceNode,
      targetNode,
      updatable: false
    };
  });
  const rawDiagram = {
    nodes,
    edges
  };
  const nodeInternals = /* @__PURE__ */ new Map();
  nodes.forEach((node) => {
    nodeInternals.set(node.id, node);
  });
  computeBorderNodeExtents(rawDiagram.nodes);
  computeBorderNodePositions(rawDiagram.nodes);
  layoutHandles(rawDiagram, diagramDescription, nodeInternals);
  return {
    nodes: rawDiagram.nodes,
    edges: rawDiagram.edges
  };
};
const diagramDialogContributionExtensionPoint = {
  identifier: "diagram#diagramDialogContribution",
  fallback: []
};
const defaultValue$8 = {
  showDialog: () => {
  }
};
const DialogContext = React.createContext(defaultValue$8);
const DialogContextProvider = ({ children }) => {
  const [state, setState] = useState({
    dialogDescriptionId: null,
    targetObjectId: null,
    onConfirm: () => {
    },
    open: false
  });
  const { data: dialogContributions } = useData(diagramDialogContributionExtensionPoint);
  const showDialog = (dialogDescriptionId, targetObjectId, onConfirm) => {
    setState({ open: true, dialogDescriptionId, targetObjectId, onConfirm });
  };
  const onFinish = (toolVariables) => {
    state.onConfirm(toolVariables);
    setState((prevState) => ({ ...prevState, open: false, dialogTypeId: void 0 }));
  };
  const onClose = () => {
    setState((prevState) => ({ ...prevState, open: false, dialogTypeId: void 0 }));
  };
  const { editingContextId } = useContext(DiagramContext);
  let DialogComponent = null;
  const dialogComponentProps = {
    editingContextId,
    dialogDescriptionId: state.dialogDescriptionId ?? "",
    targetObjectId: state.targetObjectId ?? "",
    onFinish,
    onClose
  };
  if (state.open && state.dialogDescriptionId) {
    const dialogDescriptionId = state.dialogDescriptionId;
    const dialogContribution = dialogContributions.find(
      (dialogContribution2) => dialogContribution2.canHandle(dialogDescriptionId)
    );
    if (dialogContribution) {
      DialogComponent = dialogContribution.component;
    }
  }
  return /* @__PURE__ */ jsxs(DialogContext.Provider, { value: { showDialog }, children: [
    children,
    state.open && DialogComponent && /* @__PURE__ */ jsx(DialogComponent, { ...dialogComponentProps })
  ] });
};
const useDialog = () => {
  const { showDialog } = useContext(DialogContext);
  return { showDialog };
};
const siriusComponentsDiagrams$1 = {
  edit: {
    enterNewValue: "Enter the new value"
  },
  panel: {
    exitFullScreen: "Exit full screen mode",
    toggleFullScreen: "Toggle full screen mode",
    fitToScreen: "Fit to screen",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    shareDiagram: "Share diagram",
    exportToSvg: "Export to SVG",
    exitSnapToGrid: "Exit snap to grid mode",
    toggleSnapToGrid: "Toggle snap to grid mode",
    hideHelperLines: "Hide helper lines",
    showHelperLines: "Show helper lines",
    arrangeAll: "Arrange all elements",
    revealHidden: "Reveal hidden elements",
    revealFaded: "Reveal faded elements",
    unpinAll: "Unpin all elements"
  },
  palette: {
    unpin: "Unpin element",
    pin: "Pin element",
    adjustSize: "Adjust size",
    fade: "Fade element",
    alignLeft: "Align left",
    alignCenter: "Align center",
    alignRight: "Align right",
    alignTop: "Align top",
    alignMiddle: "Align middle",
    alignBottom: "Align bottom",
    arrangeInRow: "Arrange in row",
    arrangeInColumn: "Arrange in column",
    arrangeInGrid: "Arrange in grid",
    distributeHorizontal: "Distribute horizontal spacing",
    distributeVertical: "Distribute vertical spacing",
    justifyHorizontally: "Justify horizontally",
    justifyVertically: "Justify vertically",
    makeSameSize: "Make same size",
    hideElements: "Hide elements",
    fadeElements: "Fade elements",
    pinElements: "Pin elements"
  }
};
const en = {
  siriusComponentsDiagrams: siriusComponentsDiagrams$1
};
const siriusComponentsDiagrams = {
  edit: {
    enterNewValue: "Укажите новое значение"
  },
  panel: {
    exitFullScreen: "Выйти из полноэкранного режима",
    toggleFullScreen: "Включить полноэкранный режим",
    fitToScreen: "Вписать в экран",
    zoomIn: "Увеличить масштаб",
    zoomOut: "Уменьшить масштаб",
    shareDiagram: "Поделиться диаграммой",
    exportToSvg: "Экспортировать в SVG",
    exitSnapToGrid: "Выйти из режима привязки к сетке",
    toggleSnapToGrid: "Включить режим привязки к сетке",
    hideHelperLines: "Скрыть вспомогательные линии",
    showHelperLines: "Показать вспомогательные линии",
    arrangeAll: "Разместить все элементы",
    revealHidden: "Показать скрытые элементы",
    revealFaded: "Показать затенённые элементы",
    unpinAll: "Открепить все элементы"
  },
  palette: {
    unpin: "Открепить элемент",
    pin: "Закрепить элемент",
    adjustSize: "Подобрать размер",
    fade: "Затенить элемент",
    alignLeft: "Выровнять по левому краю",
    alignCenter: "Выровнять по центру",
    alignRight: "Выровнять по правому краю",
    alignTop: "Выровнять по верхнему краю",
    alignMiddle: "Выровнять по середине",
    alignBottom: "Выровнять по нижнему краю",
    arrangeInRow: "Разместить в строке",
    arrangeInColumn: "Разместить в столбце",
    arrangeInGrid: "Разместить в сетке",
    distributeHorizontal: "Распределить промежутки горизонтально",
    distributeVertical: "Распределить промежутки вертикально",
    justifyHorizontally: "Выровнять горизонтально",
    justifyVertically: "Выровнять вертикально",
    makeSameSize: "Установить одинаковый размер",
    hideElements: "Скрыть элементы",
    fadeElements: "Затенить элементы",
    pinElements: "Закрепить элементы"
  }
};
const ru = {
  siriusComponentsDiagrams
};
const defaultValue$7 = {
  setHoveredNode: () => {
  },
  hoveredNode: null
};
const NodeContext = React.createContext(defaultValue$7);
const NodeContextProvider = ({ children }) => {
  const [state, setState] = useState({
    hoveredNode: null
  });
  const setHoveredNode = (hoveredNode) => setState((prevState) => ({ ...prevState, hoveredNode }));
  return /* @__PURE__ */ jsx(
    NodeContext.Provider,
    {
      value: {
        hoveredNode: state.hoveredNode,
        setHoveredNode
      },
      children
    }
  );
};
const defaultValue$6 = {
  connection: null,
  position: null,
  candidates: [],
  isNewConnection: false,
  setConnection: () => {
  },
  setPosition: () => {
  },
  setCandidates: () => {
  },
  resetConnection: () => {
  },
  setIsNewConnection: () => {
  }
};
const ConnectorContext = React.createContext(defaultValue$6);
const ConnectorContextProvider = ({ children }) => {
  const [state, setState] = useState({
    connection: null,
    position: null,
    candidates: [],
    isNewConnection: false
  });
  const setConnection = (connection) => {
    setState((prevState) => ({ ...prevState, connection }));
  };
  const setPosition = (position) => {
    setState((prevState) => ({ ...prevState, position }));
  };
  const setCandidates = (candidates) => {
    setState((prevState) => ({ ...prevState, candidates }));
  };
  const setIsNewConnection = (isNewConnection) => {
    setState((prevState) => ({ ...prevState, isNewConnection }));
  };
  const resetConnection = () => {
    setState((prevState) => ({
      ...prevState,
      connection: null,
      targetCandidates: [],
      isNewConnection: false,
      selectedNodeId: null
    }));
  };
  return /* @__PURE__ */ jsx(
    ConnectorContext.Provider,
    {
      value: {
        connection: state.connection,
        position: state.position,
        candidates: state.candidates,
        isNewConnection: state.isNewConnection,
        setConnection,
        setPosition,
        setCandidates,
        resetConnection,
        setIsNewConnection
      },
      children
    }
  );
};
const useConnectorNodeStyle = (nodeId, descriptionId) => {
  const theme2 = useTheme();
  const { candidates, isNewConnection } = useContext(ConnectorContext);
  const { hoveredNode } = useContext(NodeContext);
  const style = {};
  if (isNewConnection) {
    const isConnectionCompatibleNode = Boolean(
      candidates.find((nodeDescription) => nodeDescription.id === descriptionId)
    );
    const isSelectedNode = (hoveredNode == null ? void 0 : hoveredNode.id) === nodeId;
    if (isConnectionCompatibleNode) {
      if (isSelectedNode) {
        style.boxShadow = `0px 0px 2px 2px ${theme2.palette.selected}`;
      }
      style.opacity = "1";
    } else {
      style.opacity = "0.4";
    }
  }
  const memoizedStyle = useMemo(
    () => style,
    [candidates.map((candidate) => candidate.id).join("-"), isNewConnection, hoveredNode == null ? void 0 : hoveredNode.id]
  );
  return { style: memoizedStyle };
};
const diagramRendererReactFlowPropsCustomizerExtensionPoint = {
  identifier: "diagramRenderer#reactFlowPropsCustomizer",
  fallback: []
};
const dropOnDiagramMutation = gql`
  mutation dropOnDiagram($input: DropOnDiagramInput!) {
    dropOnDiagram(input: $input) {
      __typename
      ... on DropOnDiagramSuccessPayload {
        diagram {
          id
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$a = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$8 = (payload) => payload.__typename === "DropOnDiagramSuccessPayload";
const useDrop = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const { diagramId, editingContextId, readOnly } = useContext(DiagramContext);
  const [dropMutation, { data: droponDiagramElementData, error: droponDiagramError }] = useMutation(dropOnDiagramMutation);
  useEffect(() => {
    if (droponDiagramError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (droponDiagramElementData) {
      const { dropOnDiagram } = droponDiagramElementData;
      if (isSuccessPayload$8(dropOnDiagram)) {
        addMessages(dropOnDiagram.messages);
      }
      if (isErrorPayload$a(dropOnDiagram)) {
        addMessages(dropOnDiagram.messages);
      }
    }
  }, [coreT, droponDiagramElementData, droponDiagramError]);
  const reactFlowInstance = useReactFlow();
  const onDrop = useCallback(
    (event, diagramElementId) => {
      event.preventDefault();
      event.stopPropagation();
      const data = event.dataTransfer.getData(DRAG_SOURCES_TYPE);
      if (data === "") {
        return;
      }
      const dropPosition = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });
      const selectedIds = JSON.parse(data).map((entry) => entry.id);
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        objectIds: selectedIds,
        startingPositionX: dropPosition.x,
        startingPositionY: dropPosition.y,
        diagramTargetElementId: diagramElementId ? diagramElementId : diagramId
      };
      if (!readOnly) {
        dropMutation({ variables: { input } });
      }
    },
    [reactFlowInstance, readOnly]
  );
  const onDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);
  return { onDrop, onDragOver };
};
const defaultValue$5 = {
  diagramDescription: {
    id: "",
    nodeDescriptions: [],
    dropNodeCompatibility: [],
    debug: false,
    arrangeLayoutDirection: "RIGHT"
  }
};
const DiagramDescriptionContext = React.createContext(defaultValue$5);
const useDiagramDescription = () => {
  const { diagramDescription } = useContext(DiagramDescriptionContext);
  return {
    diagramDescription
  };
};
const defaultValue$4 = {
  getNodes: () => [],
  getNode: () => void 0,
  setNodes: () => {
  },
  getEdges: () => [],
  getEdge: () => void 0,
  setEdges: () => {
  },
  onNodesChange: () => {
  },
  onEdgesChange: () => {
  }
};
const StoreContext = React.createContext(defaultValue$4);
const StoreContextProvider = ({ children }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const onNodesChange = useCallback(
    (changes) => setNodes((prevState) => applyNodeChanges(changes, prevState)),
    [setEdges]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((prevState) => applyEdgeChanges(changes, prevState)),
    [setEdges]
  );
  return /* @__PURE__ */ jsx(
    StoreContext.Provider,
    {
      value: {
        setEdges,
        setNodes,
        getEdges: () => edges,
        getNodes: () => nodes,
        getEdge: (id) => edges.find((edge) => edge.id === id),
        getNode: (id) => nodes.find((node) => node.id === id),
        onNodesChange,
        onEdgesChange
      },
      children
    }
  );
};
const useStore = () => {
  const { getEdges, getNodes, onEdgesChange, onNodesChange, setEdges, setNodes, getEdge, getNode } = useContext(StoreContext);
  return {
    getEdges,
    getNodes,
    onEdgesChange,
    onNodesChange,
    setEdges,
    setNodes,
    getEdge,
    getNode
  };
};
const defaultValue$3 = {
  initialPosition: null,
  initialPositionAbsolute: null,
  droppableOnDiagram: false,
  draggedNodeId: "",
  initializeDrop: () => {
  },
  resetDrop: () => {
  }
};
const DropNodeContext = React.createContext(defaultValue$3);
const DropNodeContextProvider = ({ children }) => {
  const [state, setState] = useState(defaultValue$3);
  const initializeDrop = (dropData) => {
    setState((prevState) => ({ ...prevState, ...dropData }));
  };
  const resetDrop = () => {
    setState((prevState) => ({
      ...prevState,
      initialPosition: null,
      initialPositionAbsolute: null,
      droppableOnDiagram: false,
      draggedNodeId: ""
    }));
  };
  return /* @__PURE__ */ jsx(
    DropNodeContext.Provider,
    {
      value: {
        initialPosition: state.initialPosition,
        initialPositionAbsolute: state.initialPositionAbsolute,
        droppableOnDiagram: state.droppableOnDiagram,
        draggedNodeId: state.draggedNodeId,
        initializeDrop,
        resetDrop
      },
      children
    }
  );
};
const dropNodeMutation = gql`
  mutation dropNode($input: DropNodeInput!) {
    dropNode(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$9 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$7 = (payload) => payload.__typename === "SuccessPayload";
const isListData$4 = (node) => node.type === "listNode";
const getNodeDepth = (node, intersections) => {
  let nodeDepth2 = 0;
  let nodeHierarchy = node;
  while (nodeHierarchy) {
    nodeDepth2++;
    nodeHierarchy = intersections.find((node2) => node2.id === (nodeHierarchy == null ? void 0 : nodeHierarchy.parentNode));
  }
  return nodeDepth2;
};
const useDropNodeMutation = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { diagramId, editingContextId, readOnly } = useContext(DiagramContext);
  const { addErrorMessage, addMessages } = useMultiToast();
  const [dropMutation, { data: dropNodeData, error: dropNodeError }] = useMutation(dropNodeMutation);
  useEffect(() => {
    if (dropNodeError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (dropNodeData) {
      const { dropNode } = dropNodeData;
      if (isSuccessPayload$7(dropNode)) {
        addMessages(dropNode.messages);
      }
      if (isErrorPayload$9(dropNode)) {
        addMessages(dropNode.messages);
      }
    }
  }, [coreT, dropNodeData, dropNodeError]);
  const invokeMutation = useCallback(
    (droppedNode, targetElementId, dropPosition, onDragCancelled) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        droppedElementId: droppedNode.id,
        targetElementId,
        x: dropPosition.x,
        y: dropPosition.y
      };
      if (!readOnly) {
        dropMutation({ variables: { input } }).then((result) => {
          var _a, _b;
          if (((_a = result.data) == null ? void 0 : _a.dropNode) && isErrorPayload$9((_b = result.data) == null ? void 0 : _b.dropNode)) {
            onDragCancelled(droppedNode);
          }
        });
      }
    },
    [readOnly]
  );
  return invokeMutation;
};
const useDropNode = () => {
  const { droppableOnDiagram, initialPosition, initialPositionAbsolute, draggedNodeId, initializeDrop, resetDrop } = useContext(DropNodeContext);
  const { diagramDescription } = useDiagramDescription();
  const onDropNode = useDropNodeMutation();
  const { getNodes, getIntersectingNodes: getIntersectingNodes2, screenToFlowPosition } = useReactFlow();
  const { setNodes } = useStore();
  const storeApi = useStoreApi();
  const getNodeById = (id) => storeApi.getState().nodeInternals.get(id);
  const getDraggableNode = (node) => {
    if (node.parentNode) {
      const parentNode = getNodeById(node.parentNode);
      if (parentNode && isListData$4(parentNode) && !parentNode.data.areChildNodesDraggable) {
        return getDraggableNode(parentNode);
      }
    }
    return node;
  };
  const onNodeDragStart = useCallback(
    (_event, node, nodes) => {
      if (!node || nodes.length > 1) {
        return;
      }
      const computedNode = getDraggableNode(node);
      const dropDataEntry = diagramDescription.dropNodeCompatibility.find(
        (entry) => entry.droppedNodeDescriptionId === computedNode.data.descriptionId
      );
      const compatibleNodes = getNodes().filter(
        (candidate) => !candidate.hidden && !isDescendantOf(computedNode, candidate, storeApi.getState().nodeInternals)
      ).filter(
        (candidate) => dropDataEntry == null ? void 0 : dropDataEntry.droppableOnNodeTypes.includes(candidate.data.descriptionId)
      ).map((candidate) => candidate.id);
      initializeDrop({
        initialPosition: computedNode.position,
        initialPositionAbsolute: computedNode.positionAbsolute || null,
        droppableOnDiagram: (dropDataEntry == null ? void 0 : dropDataEntry.droppableOnDiagram) || false,
        draggedNodeId: computedNode.id
      });
      setNodes(
        (nds) => nds.map((n) => {
          if (compatibleNodes.includes(n.id)) {
            n.data = {
              ...n.data,
              isDropNodeCandidate: true
            };
          }
          return n;
        })
      );
    },
    [getNodes]
  );
  const onNodeDrag = useCallback(
    (_event, node, nodes) => {
      var _a;
      if (!node || nodes.length > 1) {
        return;
      }
      const draggedNode = getNodes().find((node2) => node2.id === draggedNodeId) || null;
      if (draggedNode && !draggedNode.data.isBorderNode) {
        const intersections = getIntersectingNodes2(draggedNode).filter((intersectingNode) => !intersectingNode.hidden);
        const newParentId = ((_a = [...intersections].filter(
          (intersectingNode) => !isDescendantOf(draggedNode, intersectingNode, storeApi.getState().nodeInternals)
        ).sort((n1, n2) => getNodeDepth(n2, intersections) - getNodeDepth(n1, intersections))[0]) == null ? void 0 : _a.id) || null;
        const targetNode = getNodes().find((node2) => node2.data.isDropNodeTarget) || null;
        if ((targetNode == null ? void 0 : targetNode.id) != newParentId) {
          setNodes(
            (nds) => nds.map((n) => {
              if (n.id === newParentId) {
                n.data = {
                  ...n.data,
                  isDropNodeTarget: true
                };
              } else if (n.data.isDropNodeTarget && n.id !== newParentId) {
                n.data = {
                  ...n.data,
                  isDropNodeTarget: false
                };
              }
              return n;
            })
          );
        }
      }
    },
    [droppableOnDiagram, draggedNodeId, getNodes]
  );
  const onNodeDragStop = useCallback(
    (event) => {
      const draggedNode = getNodes().find((node) => node.id === draggedNodeId) || null;
      const dropPosition = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY
      });
      if (draggedNode) {
        const targetNode = getNodes().find((node) => node.data.isDropNodeTarget);
        const isDropOnNode = !!targetNode;
        const isDropOnSameParent = isDropOnNode && !!(draggedNode == null ? void 0 : draggedNode.parentNode) && draggedNode.parentNode === (targetNode == null ? void 0 : targetNode.id);
        const isDropFromDiagramToDiagram = !isDropOnNode && !(draggedNode == null ? void 0 : draggedNode.parentNode);
        const isBorderNodeDrop = draggedNode.data.isBorderNode && (!isDropOnNode || draggedNode.parentNode === (targetNode == null ? void 0 : targetNode.id));
        const isValidDropOnNode = isDropOnNode && !!(targetNode == null ? void 0 : targetNode.data.isDropNodeCandidate);
        const isValidDropOnDiagram = !isDropOnNode && droppableOnDiagram;
        if (isValidDropOnDiagram && !isDropFromDiagramToDiagram || isValidDropOnNode && !isDropOnSameParent) {
          const target = (targetNode == null ? void 0 : targetNode.id) || null;
          onDropNode(draggedNode, target, dropPosition, cancelDrop);
        }
        if (!isDropOnNode && !isValidDropOnDiagram && !isDropFromDiagramToDiagram && !isBorderNodeDrop) {
          cancelDrop(draggedNode);
        } else if (isDropOnNode && !isValidDropOnNode && !isDropOnSameParent) {
          cancelDrop(draggedNode);
        } else if (isDropOnNode && (draggedNode == null ? void 0 : draggedNode.type) === "iconLabelNode" && isDropOnSameParent) {
          cancelDrop(draggedNode);
        }
        resetDrop();
        setNodes(
          (nds) => nds.map((n) => {
            if (n.data.isDropNodeCandidate || n.data.isDropNodeTarget) {
              n.data = {
                ...n.data,
                isDropNodeCandidate: false,
                isDropNodeTarget: false
              };
            }
            return n;
          })
        );
      }
    },
    [droppableOnDiagram, initialPosition, initialPositionAbsolute, draggedNodeId, getNodes]
  );
  const cancelDrop = (node) => {
    if (initialPosition && initialPositionAbsolute) {
      setNodes(
        (nds) => nds.map((n) => {
          if (n.id === node.id) {
            n.position = initialPosition;
            n.positionAbsolute = initialPositionAbsolute;
            n.data = {
              ...n.data
            };
          }
          n.dragging = false;
          return n;
        })
      );
    }
  };
  return {
    onNodeDragStart,
    onNodeDrag,
    onNodeDragStop
  };
};
const useDropNodeStyle = (isDropNodeTarget, isDropNodeCandidate, isDragging) => {
  const { readOnly } = useContext(DiagramContext);
  const { draggedNodeId } = useContext(DropNodeContext);
  const theme2 = useTheme();
  const style = {};
  if (draggedNodeId && !readOnly) {
    if (!isDragging && !isDropNodeCandidate) {
      style.opacity = "0.4";
    }
    if (isDropNodeTarget) {
      style.boxShadow = `0px 0px 2px 2px ${theme2.palette.primary.main}`;
    }
  }
  const memoizedStyle = useMemo(() => style, [isDropNodeTarget, isDropNodeCandidate, isDragging, draggedNodeId]);
  return { style: memoizedStyle };
};
const defaultValue$2 = {
  x: null,
  y: null,
  isOpened: false,
  hideDiagramElementPalette: () => {
  },
  showDiagramElementPalette: () => {
  }
};
const DiagramElementPaletteContext = React.createContext(defaultValue$2);
const DiagramElementPaletteContextProvider = ({ children }) => {
  const [state, setState] = useState({
    x: null,
    y: null,
    isOpened: false
  });
  const showPalette = useCallback((x, y) => {
    setState((prevState) => ({ ...prevState, x, y, isOpened: true }));
  }, []);
  const hidePalette = useCallback(() => {
    if (state.isOpened) {
      setState((prevState) => ({ ...prevState, x: null, y: null, isOpened: false }));
    }
  }, [state.isOpened]);
  return /* @__PURE__ */ jsx(
    DiagramElementPaletteContext.Provider,
    {
      value: {
        x: state.x,
        y: state.y,
        isOpened: state.isOpened,
        showDiagramElementPalette: showPalette,
        hideDiagramElementPalette: hidePalette
      },
      children
    }
  );
};
const computePalettePosition$2 = (event, bounds) => {
  return {
    x: event.clientX - ((bounds == null ? void 0 : bounds.left) ?? 0),
    y: event.clientY - ((bounds == null ? void 0 : bounds.top) ?? 0)
  };
};
const useDiagramElementPalette = () => {
  const { x, y, isOpened, hideDiagramElementPalette, showDiagramElementPalette } = useContext(DiagramElementPaletteContext);
  const store = useStoreApi();
  const { getNodes, getEdges } = useReactFlow();
  const onDiagramElementClick = useCallback(
    (event, elementClicked) => {
      const { domNode } = store.getState();
      const element = domNode == null ? void 0 : domNode.getBoundingClientRect();
      const palettePosition = computePalettePosition$2(event, element);
      const selectedElement = [
        ...getNodes().filter((node) => node.selected),
        ...getEdges().filter((edge) => edge.selected)
      ];
      if (!event.altKey && selectedElement.length === 1 && selectedElement[0] && selectedElement[0].id === elementClicked.id) {
        showDiagramElementPalette(palettePosition.x, palettePosition.y);
      } else {
        hideDiagramElementPalette();
      }
    },
    [showDiagramElementPalette, hideDiagramElementPalette]
  );
  return {
    x,
    y,
    isOpened,
    hideDiagramElementPalette,
    showDiagramElementPalette,
    onDiagramElementClick
  };
};
const tempConnectionLineStyle = (theme2) => {
  return {
    stroke: theme2.palette.selected,
    strokeWidth: theme2.spacing(0.2)
  };
};
const useConnector = () => {
  const {
    connection,
    setConnection,
    position,
    setPosition,
    resetConnection,
    candidates,
    isNewConnection,
    setIsNewConnection
  } = useContext(ConnectorContext);
  const reactFlowInstance = useReactFlow();
  const { getNode, setEdges } = reactFlowInstance;
  const theme2 = useTheme();
  const { hideDiagramElementPalette } = useDiagramElementPalette();
  const updateNodeInternals = useUpdateNodeInternals();
  const { diagramDescription } = useDiagramDescription();
  const store = useStoreApi();
  const isConnectionInProgress = () => {
    const connectionNodeId = store.getState().connectionNodeId;
    return !!connectionNodeId && isNewConnection || !!connection;
  };
  const isReconnectionInProgress = () => {
    const connectionNodeId = store.getState().connectionNodeId;
    return !!connectionNodeId && !isNewConnection;
  };
  const onConnect = useCallback((connection2) => {
    setConnection(connection2);
  }, []);
  const onConnectStart = useCallback(
    (_event, params) => {
      hideDiagramElementPalette();
      resetConnection();
      if (params.nodeId) {
        updateNodeInternals(params.nodeId);
      }
    },
    [hideDiagramElementPalette]
  );
  const onConnectorContextualMenuClose = () => resetConnection();
  const onConnectionStartElementClick = useCallback((event) => {
    if (event.button === 0) {
      setIsNewConnection(true);
    }
  }, []);
  const onConnectEnd = useCallback((event) => {
    var _a, _b;
    if ("clientX" in event && "clientY" in event) {
      setPosition({ x: event.clientX || 0, y: event.clientY });
    } else if ("touches" in event) {
      const touchEvent = event;
      setPosition({ x: ((_a = touchEvent.touches[0]) == null ? void 0 : _a.clientX) || 0, y: ((_b = touchEvent.touches[0]) == null ? void 0 : _b.clientY) || 0 });
    }
    setIsNewConnection(false);
  }, []);
  const addTempConnectionLine = () => {
    const sourceNode = getNode((connection == null ? void 0 : connection.source) ?? "");
    const targetNode = getNode((connection == null ? void 0 : connection.target) ?? "");
    if (sourceNode && targetNode && !!connection) {
      const { targetPosition, sourcePosition } = getEdgeParameters(
        sourceNode,
        targetNode,
        store.getState().nodeInternals,
        diagramDescription.arrangeLayoutDirection
      );
      const edge = {
        id: "temp",
        source: connection.source ?? "",
        target: connection.target ?? "",
        sourceHandle: `creationhandle--${connection.source}--${sourcePosition}`,
        targetHandle: `handle--${connection.target}--temp--${targetPosition}`,
        type: "smoothstep",
        animated: true,
        updatable: false,
        style: tempConnectionLineStyle(theme2),
        zIndex: 2002
      };
      setEdges((oldEdges) => [...oldEdges, edge]);
    }
  };
  const removeTempConnectionLine = () => {
    setEdges((oldEdges) => oldEdges.filter((item) => !item.id.includes("temp")));
  };
  return {
    onConnect,
    onConnectStart,
    onConnectEnd,
    onConnectorContextualMenuClose,
    onConnectionStartElementClick,
    addTempConnectionLine,
    removeTempConnectionLine,
    connection,
    position,
    isConnectionInProgress,
    isReconnectionInProgress,
    candidates
  };
};
const getToolSectionsQuery = gql`
  query getToolSections($editingContextId: ID!, $diagramId: ID!, $diagramElementId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $diagramId) {
          description {
            ... on DiagramDescription {
              palette(diagramElementId: $diagramElementId) {
                tools {
                  __typename
                  ... on SingleClickOnTwoDiagramElementsTool {
                    candidates {
                      sources {
                        id
                      }
                      targets {
                        id
                      }
                    }
                  }
                }
                toolSections {
                  tools {
                    __typename
                    ... on SingleClickOnTwoDiagramElementsTool {
                      candidates {
                        sources {
                          id
                        }
                        targets {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const isDiagramDescription$2 = (representationDescription) => representationDescription.__typename === "DiagramDescription";
const isSingleClickOnTwoDiagramElementsTool = (tool) => tool.__typename === "SingleClickOnTwoDiagramElementsTool";
const useConnectionCandidatesQuery = (editingContextId, diagramId, nodeId) => {
  const { addErrorMessage } = useMultiToast();
  const nodes = useNodes();
  const { data, error } = useQuery(getToolSectionsQuery, {
    variables: {
      editingContextId,
      diagramId,
      diagramElementId: nodeId
    },
    skip: nodes.filter((node) => node.selected).length > 1
  });
  const diagramDescription = (data == null ? void 0 : data.viewer.editingContext.representation.description) ?? null;
  const nodeCandidates = useMemo(() => {
    const candidates = [];
    if (diagramDescription && isDiagramDescription$2(diagramDescription)) {
      diagramDescription.palette.tools.filter(isSingleClickOnTwoDiagramElementsTool).forEach((tool) => {
        tool.candidates.forEach((candidate) => candidates.push(...candidate.targets));
      });
      diagramDescription.palette.toolSections.forEach((toolSection) => {
        toolSection.tools.filter(isSingleClickOnTwoDiagramElementsTool).forEach((tool) => {
          tool.candidates.forEach((candidate) => candidates.push(...candidate.targets));
        });
      });
    }
    return candidates;
  }, [diagramDescription]);
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  return nodeCandidates;
};
const connectionCreationHandleStyle = (position, theme2, isHovered, isMouseDown) => {
  const style = {
    position: "absolute",
    borderRadius: "0",
    border: "solid black",
    borderWidth: "0 2px 2px 0",
    display: "inline-block",
    padding: "2px",
    borderColor: theme2.palette.secondary.light,
    backgroundColor: "transparent",
    width: 12,
    height: 12,
    zIndex: 9999
  };
  switch (position) {
    case Position.Left:
      style.left = "-15px";
      style.transform = "translateY(-50%) rotate(135deg)";
      break;
    case Position.Right:
      style.right = "-15px";
      style.transform = "translateY(-50%) rotate(-45deg)";
      break;
    case Position.Top:
      style.top = "-15px";
      style.transform = "translateX(-50%) rotate(-135deg)";
      break;
    case Position.Bottom:
      style.bottom = "-15px";
      style.transform = "translateX(-50%) rotate(45deg)";
      break;
  }
  if (isHovered === position || isMouseDown === position) {
    style.borderColor = theme2.palette.selected;
  }
  return style;
};
const ConnectionCreationHandles = memo(({ nodeId }) => {
  const theme2 = useTheme();
  const { editingContextId, diagramId, readOnly } = useContext(DiagramContext);
  const { onConnectionStartElementClick, isConnectionInProgress } = useConnector();
  const { setCandidates } = useContext(ConnectorContext);
  const [state, setState] = useState({
    isHovered: null,
    isMouseDown: null
  });
  const candidates = useConnectionCandidatesQuery(editingContextId, diagramId, nodeId);
  const shouldRender = candidates !== null && candidates.length > 0 && !readOnly;
  useEffect(() => {
    if (candidates !== null) {
      setCandidates(candidates);
    }
  }, [candidates]);
  useEffect(() => {
    if (!isConnectionInProgress) {
      setState((prevState) => ({
        ...prevState,
        isMouseDown: null
      }));
    }
  }, [isConnectionInProgress]);
  const handleOnMouseDown = (event, position) => {
    onConnectionStartElementClick(event);
    setState((prevState) => ({
      ...prevState,
      isMouseDown: position
    }));
  };
  const handleOnMouseEnter = (position) => {
    setState((prevState) => ({
      ...prevState,
      isHovered: position
    }));
  };
  const handleOnMouseLeave = () => {
    setState((prevState) => ({
      ...prevState,
      isHovered: null
    }));
  };
  return /* @__PURE__ */ jsx(Fragment, { children: shouldRender ? Object.values(Position).map((position) => {
    return /* @__PURE__ */ jsx(
      Handle,
      {
        id: `creationhandle--${nodeId}--${position}`,
        type: "source",
        position,
        style: connectionCreationHandleStyle(position, theme2, state.isHovered, state.isMouseDown),
        onMouseDown: (event) => handleOnMouseDown(event, position),
        onMouseEnter: () => handleOnMouseEnter(position),
        onMouseLeave: handleOnMouseLeave,
        isConnectableStart: true,
        isConnectableEnd: false,
        "data-testid": `creationhandle-${position}`
      },
      position
    );
  }) : null });
});
const borderHandlesStyle = (position) => {
  const style = {
    display: "flex",
    position: "absolute",
    justifyContent: "space-evenly",
    pointerEvents: "none"
  };
  switch (position) {
    case Position.Left:
      style.height = "100%";
      style.left = "0";
      style.top = "0";
      style.flexDirection = "column";
      break;
    case Position.Right:
      style.height = "100%";
      style.right = "0";
      style.top = "0";
      style.flexDirection = "column";
      break;
    case Position.Top:
      style.width = "100%";
      style.left = "0";
      style.top = "0";
      style.flexDirection = "row";
      break;
    case Position.Bottom:
      style.width = "100%";
      style.left = "0";
      style.bottom = "0";
      style.flexDirection = "row";
      break;
  }
  return style;
};
const handleStyle = (theme2, position, isEdgeSelected, isVirtualHandle, readOnly) => {
  const style = {
    position: "relative",
    transform: "none",
    opacity: "0",
    pointerEvents: "none"
  };
  switch (position) {
    case Position.Left:
    case Position.Right:
      style.top = "auto";
      break;
    case Position.Top:
    case Position.Bottom:
      style.left = "auto";
      break;
  }
  if (isEdgeSelected && !readOnly) {
    style.opacity = 1;
    style.outline = `${theme2.palette.selected} solid 1px`;
  }
  if (isVirtualHandle) {
    style.position = "absolute";
    style.display = "none";
  }
  return style;
};
const handleSelectedSelector = (state) => Array.from(state.edges.values()).filter((edge) => edge.selected).flatMap((edge) => [edge.sourceHandle, edge.targetHandle]).join("#");
const ConnectionHandles = memo(({ connectionHandles }) => {
  const theme2 = useTheme();
  const handleSourceSelected = useStore$1(handleSelectedSelector);
  const { readOnly } = useContext(DiagramContext);
  const isHandleSelected = useCallback(
    (connectionHandle) => {
      return !!connectionHandle.id && handleSourceSelected.includes(connectionHandle.id);
    },
    [handleSourceSelected]
  );
  return /* @__PURE__ */ jsx(Fragment, { children: Object.values(Position).map((position) => {
    const currentSideHandles = connectionHandles.filter((connectionHandle) => connectionHandle.position === position).sort((a, b) => a.index - b.index);
    return /* @__PURE__ */ jsx("div", { style: borderHandlesStyle(position), children: currentSideHandles.map((connectionHandle) => {
      return /* @__PURE__ */ jsx(
        Handle,
        {
          id: connectionHandle.id ?? "",
          style: handleStyle(
            theme2,
            connectionHandle.position,
            isHandleSelected(connectionHandle),
            connectionHandle.hidden,
            readOnly
          ),
          type: connectionHandle.type,
          position: connectionHandle.position,
          isConnectable: true
        },
        connectionHandle.id
      );
    }) }, position);
  }) });
});
const useRefreshTargetHandles = (id, shouldRefresh) => {
  const updateNodeInternals = useUpdateNodeInternals();
  useEffect(() => {
    if (shouldRefresh) {
      updateNodeInternals(id);
    }
  }, [shouldRefresh]);
};
const targetHandleNodeStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  transform: "none",
  borderRadius: 0,
  border: "none",
  opacity: 0,
  pointerEvents: "all",
  cursor: "crosshair"
};
const targetTempHandleStyle = {
  opacity: 0
};
const ConnectionTargetHandle = memo(({ nodeId, nodeDescription, isHovered }) => {
  const { isConnectionInProgress, candidates, isReconnectionInProgress } = useConnector();
  const shouldRender = useMemo(() => {
    if (isHovered) {
      return isConnectionInProgress() && !!(nodeDescription == null ? void 0 : nodeDescription.id) && candidates.map((candidate) => candidate.id).includes(nodeDescription.id) || isReconnectionInProgress();
    } else {
      return false;
    }
  }, [isHovered, isConnectionInProgress, isReconnectionInProgress]);
  useRefreshTargetHandles(nodeId, shouldRender);
  return /* @__PURE__ */ jsx(Fragment, { children: shouldRender ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Handle,
      {
        id: `handle--${nodeId}--temp--top`,
        type: "target",
        position: Position.Top,
        style: targetTempHandleStyle,
        isConnectableEnd: false,
        isConnectableStart: false
      }
    ),
    /* @__PURE__ */ jsx(
      Handle,
      {
        id: `handle--${nodeId}--temp--bottom`,
        type: "target",
        position: Position.Bottom,
        style: targetTempHandleStyle,
        isConnectableEnd: false,
        isConnectableStart: false
      }
    ),
    /* @__PURE__ */ jsx(
      Handle,
      {
        id: `handle--${nodeId}--temp--left`,
        type: "target",
        position: Position.Left,
        style: targetTempHandleStyle,
        isConnectableEnd: false,
        isConnectableStart: false
      }
    ),
    /* @__PURE__ */ jsx(
      Handle,
      {
        id: `handle--${nodeId}--temp--right`,
        type: "target",
        position: Position.Right,
        style: targetTempHandleStyle,
        isConnectableEnd: false,
        isConnectableStart: false
      }
    ),
    /* @__PURE__ */ jsx(
      Handle,
      {
        id: `handle--${nodeId}--target`,
        type: "target",
        position: Position.Top,
        style: targetHandleNodeStyle,
        isConnectableEnd: true,
        isConnectableStart: false
      }
    )
  ] }) : null });
});
const useRefreshConnectionHandles = (id, connectionHandles) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const firstUpdate = useRef(true);
  const connectionHandlesIdentity = connectionHandles.map((handle) => `${handle.id}#${handle.index}#${handle.position}#${handle.nodeId}`).join(", ");
  useEffect(() => {
    if (firstUpdate.current && firstUpdate) {
      firstUpdate.current = false;
    } else {
      updateNodeInternals(id);
    }
  }, [connectionHandlesIdentity]);
};
const initialDirectEditElementLabeQuery = gql`
  query initialDirectEditElementLabel($editingContextId: ID!, $diagramId: ID!, $labelId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $diagramId) {
          description {
            ... on DiagramDescription {
              initialDirectEditElementLabel(labelId: $labelId)
            }
          }
        }
      }
    }
  }
`;
const editLabelMutationOp = gql`
  mutation editLabel($input: EditLabelInput!) {
    editLabel(input: $input) {
      __typename
      ... on EditLabelSuccessPayload {
        diagram {
          id
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$8 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$6 = (payload) => payload.__typename === "EditLabelSuccessPayload";
const DiagramDirectEditInput = ({ labelId, editingKey, onClose }) => {
  const { t } = useTranslation("siriusComponentsDiagrams", { keyPrefix: "edit" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const initialLabel = editingKey === null || editingKey === "" ? "" : editingKey;
  const [state, setState] = useState({
    newLabel: initialLabel
  });
  const editionFinished = useRef(false);
  const { addErrorMessage, addMessages } = useMultiToast();
  const { hideDiagramElementPalette } = useDiagramElementPalette();
  const { editingContextId, diagramId } = useContext(DiagramContext);
  const textInput = useRef(null);
  const { data: initialLabelItemData, error: initialLabelItemError } = useQuery(initialDirectEditElementLabeQuery, {
    variables: {
      editingContextId,
      diagramId,
      labelId
    }
  });
  useEffect(() => {
    hideDiagramElementPalette();
  }, []);
  const [renameElement, { data: editLabelData, error: editLabelError }] = useMutation(editLabelMutationOp);
  useEffect(() => {
    if (editLabelError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (editLabelData) {
      const { editLabel } = editLabelData;
      if (isErrorPayload$8(editLabel)) {
        addMessages(editLabel.messages);
      } else if (isSuccessPayload$6(editLabel)) {
        addMessages(editLabel.messages);
        if (editLabel.__typename === "EditLabelSuccessPayload") {
          onClose();
        }
      }
    }
  }, [coreT, editLabelData, editLabelError]);
  useEffect(() => {
    let cleanup = void 0;
    if (initialLabelItemError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (initialLabelItemData == null ? void 0 : initialLabelItemData.viewer.editingContext.representation.description.initialDirectEditElementLabel) {
      const initialLabel2 = initialLabelItemData == null ? void 0 : initialLabelItemData.viewer.editingContext.representation.description.initialDirectEditElementLabel;
      if (!editingKey) {
        setState((prevState) => {
          return { ...prevState, newLabel: initialLabel2 };
        });
        const timeOutId = setTimeout(() => {
          var _a;
          (_a = textInput.current) == null ? void 0 : _a.select();
        }, 0);
        cleanup = () => clearTimeout(timeOutId);
      }
    }
    return cleanup;
  }, [coreT, initialLabelItemError, initialLabelItemData]);
  useEffect(() => {
    if (textInput.current) {
      const text = textInput.current.value;
      textInput.current.setSelectionRange(text.length, text.length);
    }
  }, [textInput.current]);
  const doRename = () => {
    renameElement({
      variables: {
        input: {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: diagramId,
          labelId,
          newText: state.newLabel
        }
      }
    });
  };
  const handleChange = (event) => {
    const newLabel = event.target.value;
    setState((prevState) => {
      return { ...prevState, newLabel };
    });
  };
  const onBlur = () => {
    if (!editionFinished.current) {
      doRename();
    }
  };
  const onFinishEditing = (event) => {
    const { key } = event;
    if (key === "Enter" && !event.shiftKey) {
      editionFinished.current = true;
      event.preventDefault();
      doRename();
    } else if (key === "Escape") {
      editionFinished.current = true;
      onClose();
    }
  };
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    TextField,
    {
      variant: "standard",
      name: "name",
      size: "small",
      inputRef: textInput,
      placeholder: t("enterNewValue"),
      value: state.newLabel,
      multiline: true,
      onChange: handleChange,
      onKeyDown: onFinishEditing,
      onBlur,
      autoFocus: true,
      spellCheck: false,
      fullWidth: true,
      "data-testid": "name-edit",
      className: "nodrag"
    }
  ) });
};
const defaultValue$1 = {
  currentlyEditedLabelId: null,
  directEditTrigger: null,
  editingKey: null,
  setCurrentlyEditedLabelId: () => {
  },
  resetDirectEdit: () => {
  }
};
const DiagramDirectEditContext = React.createContext(defaultValue$1);
const DiagramDirectEditContextProvider = ({ children }) => {
  const [state, setState] = useState({
    currentlyEditedLabelId: null,
    directEditTrigger: null,
    editingKey: null
  });
  const setCurrentlyEditedLabelId = useCallback(
    (directEditTrigger, currentlyEditedLabelId, editingKey) => {
      setState((prevState) => ({ ...prevState, currentlyEditedLabelId, directEditTrigger, editingKey }));
    },
    []
  );
  const resetDirectEdit = useCallback(
    () => setState((prevState) => ({
      ...prevState,
      currentlyEditedLabelId: null,
      directEditTrigger: null,
      editingKey: null
    })),
    []
  );
  return /* @__PURE__ */ jsx(
    DiagramDirectEditContext.Provider,
    {
      value: {
        currentlyEditedLabelId: state.currentlyEditedLabelId,
        directEditTrigger: state.directEditTrigger,
        editingKey: state.editingKey,
        setCurrentlyEditedLabelId,
        resetDirectEdit
      },
      children
    }
  );
};
const directEditActivationValidCharacters = /[\w&é§èàùçÔØÁÛÊË"«»’”„´$¥€£\\¿?!=+-,;:%/{}[\]–#@*.]/;
const useDiagramDirectEdit = () => {
  const { currentlyEditedLabelId, editingKey, setCurrentlyEditedLabelId, resetDirectEdit } = useContext(DiagramDirectEditContext);
  const { getNodes, getEdges } = useReactFlow();
  const { readOnly } = useContext(DiagramContext);
  const onDirectEdit = useCallback(
    (event) => {
      var _a, _b, _c, _d, _e, _f;
      const { key } = event;
      const isTextField = event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement;
      if ((event.altKey || event.shiftKey) && event.getModifierState(key) || isTextField || readOnly) {
        return;
      }
      event.preventDefault();
      const validFirstInputChar = !event.metaKey && !event.ctrlKey && key.length === 1 && directEditActivationValidCharacters.test(key);
      let currentlyEditedLabelId2;
      let isLabelEditable = false;
      const nodeData = (_a = getNodes().find((node) => node.selected)) == null ? void 0 : _a.data;
      if (nodeData) {
        if (nodeData.insideLabel) {
          currentlyEditedLabelId2 = nodeData.insideLabel.id;
        } else if (nodeData.outsideLabels.BOTTOM_MIDDLE) {
          currentlyEditedLabelId2 = nodeData.outsideLabels.BOTTOM_MIDDLE.id;
        }
        isLabelEditable = nodeData.labelEditable;
      }
      if (!currentlyEditedLabelId2) {
        currentlyEditedLabelId2 = (_d = (_c = (_b = getEdges().find((edge) => edge.selected)) == null ? void 0 : _b.data) == null ? void 0 : _c.label) == null ? void 0 : _d.id;
        isLabelEditable = ((_f = (_e = getEdges().find((edge) => edge.selected)) == null ? void 0 : _e.data) == null ? void 0 : _f.centerLabelEditable) || false;
      }
      if (currentlyEditedLabelId2 && isLabelEditable) {
        if (validFirstInputChar) {
          setCurrentlyEditedLabelId("keyDown", currentlyEditedLabelId2, key);
        } else if (key === "F2") {
          setCurrentlyEditedLabelId("F2", currentlyEditedLabelId2, null);
        }
      }
    },
    [setCurrentlyEditedLabelId]
  );
  return {
    currentlyEditedLabelId,
    editingKey,
    setCurrentlyEditedLabelId,
    resetDirectEdit,
    onDirectEdit
  };
};
const getOverflowStrategy = (label) => {
  if ("overflowStrategy" in label) {
    return label.overflowStrategy;
  }
  return void 0;
};
const isDisplayTopHeaderSeparator = (label) => {
  if ("displayHeaderSeparator" in label) {
    return label.displayHeaderSeparator && label.headerPosition === "BOTTOM";
  }
  return false;
};
const isDisplayBottomHeaderSeparator = (label) => {
  if ("displayHeaderSeparator" in label) {
    return label.displayHeaderSeparator && label.headerPosition === "TOP";
  }
  return false;
};
const getHeaderSeparatorStyle = (label) => {
  if ("headerSeparatorStyle" in label) {
    return label.headerSeparatorStyle;
  }
  return void 0;
};
const labelStyle = (theme2, style, faded) => {
  return {
    opacity: faded ? "0.4" : "",
    pointerEvents: "all",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    whiteSpace: "pre-line",
    maxWidth: "100%",
    ...style,
    color: style.color ? getCSSColor(String(style.color), theme2) : void 0
  };
};
const labelContentStyle = (theme2, label) => {
  const labelContentStyle2 = {
    display: "flex",
    alignItems: "center"
  };
  switch (getOverflowStrategy(label)) {
    case "WRAP":
    case "ELLIPSIS":
      labelContentStyle2.overflow = "hidden";
      break;
    case "NONE":
      labelContentStyle2.whiteSpace = "pre";
      break;
  }
  return {
    ...labelContentStyle2,
    ...label.contentStyle,
    background: label.contentStyle.background ? getCSSColor(String(label.contentStyle.background), theme2) : void 0,
    borderColor: label.contentStyle.borderColor ? getCSSColor(String(label.contentStyle.borderColor), theme2) : void 0
  };
};
const labelOverflowStyle = (label) => {
  const style = {};
  switch (getOverflowStrategy(label)) {
    case "WRAP":
      style.overflow = "hidden";
      style.overflowWrap = "break-word";
      break;
    case "ELLIPSIS":
      style.whiteSpace = "nowrap";
      style.overflow = "hidden";
      style.textOverflow = "ellipsis";
      break;
  }
  return style;
};
const Label = memo(({ diagramElementId, label, faded }) => {
  const theme2 = useTheme();
  const { currentlyEditedLabelId, editingKey, resetDirectEdit } = useDiagramDirectEdit();
  const { readOnly } = useContext(DiagramContext);
  const handleClose = () => {
    resetDirectEdit();
    const diagramElement = document.querySelector(`[data-id="${diagramElementId}"]`);
    if (diagramElement instanceof HTMLElement) {
      diagramElement.focus();
    }
  };
  const content = label.id === currentlyEditedLabelId && !readOnly ? /* @__PURE__ */ jsx(DiagramDirectEditInput, { editingKey, onClose: handleClose, labelId: label.id }) : /* @__PURE__ */ jsxs(
    "div",
    {
      "data-id": `${label.id}-content`,
      "data-testid": `Label content - ${label.text}`,
      style: labelContentStyle(theme2, label),
      children: [
        /* @__PURE__ */ jsx(IconOverlay, { iconURL: label.iconURL, alt: label.text, customIconStyle: { marginRight: theme2.spacing(1) } }),
        /* @__PURE__ */ jsx("div", { style: labelOverflowStyle(label), children: label.text })
      ]
    }
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    isDisplayTopHeaderSeparator(label) && /* @__PURE__ */ jsx("div", { "data-testid": `Label top separator - ${label.text}`, style: getHeaderSeparatorStyle(label) }),
    /* @__PURE__ */ jsx(
      "div",
      {
        "data-id": label.id,
        "data-testid": `Label - ${label.text}`,
        style: labelStyle(theme2, label.style, faded),
        className: "nopan",
        children: content
      }
    ),
    isDisplayBottomHeaderSeparator(label) && /* @__PURE__ */ jsx("div", { "data-testid": `Label bottom separator - ${label.text}`, style: getHeaderSeparatorStyle(label) })
  ] });
});
const PinIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" })
    }
  );
};
const UnpinIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L3.27 3 2 4.27l3.18 3.18C5.07 7.95 5 8.47 5 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L18.73 21 20 19.73l-3.63-3.63z" })
    }
  );
};
const useToolStyle = makeStyles()((theme2) => ({
  toolThumbnail: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme2.spacing(4.5),
    cursor: "pointer",
    paddingTop: theme2.spacing(0.5),
    paddingBottom: theme2.spacing(0.5)
  },
  tool: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    cursor: "pointer"
  },
  toolLabel: {
    marginLeft: theme2.spacing(0.5)
  }
}));
const Tool = ({ tool, onClick, thumbnail }) => {
  const { id, label, iconURL } = tool;
  const { classes } = useToolStyle();
  let image = null;
  if (iconURL.length > 0) {
    image = /* @__PURE__ */ jsx(IconOverlay, { iconURL, alt: label, title: label });
  }
  let labelContent = null;
  if (!thumbnail) {
    labelContent = /* @__PURE__ */ jsx(Typography, { className: classes.toolLabel, children: label });
  }
  const onToolClick = (event) => {
    event.stopPropagation();
    onClick(tool);
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: thumbnail ? classes.toolThumbnail : classes.tool,
      onClick: onToolClick,
      "data-testid": `${tool.label} - Tool`,
      children: [
        image,
        labelContent
      ]
    },
    id
  );
};
const layoutDiagramMutation = gql`
  mutation layoutDiagram($input: LayoutDiagramInput!) {
    layoutDiagram(input: $input) {
      __typename
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$7 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$5 = (payload) => payload.__typename === "SuccessPayload";
const useSynchronizeLayoutData = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { diagramId: representationId, editingContextId } = useContext(DiagramContext);
  const { addErrorMessage, addMessages } = useMultiToast();
  const [layoutDiagram2, { data, error }] = useMutation(
    layoutDiagramMutation
  );
  useEffect(() => {
    if (error) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (data) {
      const { layoutDiagram: layoutDiagram22 } = data;
      if (isSuccessPayload$5(layoutDiagram22)) {
        addMessages(layoutDiagram22.messages);
      }
      if (isErrorPayload$7(layoutDiagram22)) {
        addMessages(layoutDiagram22.messages);
      }
    }
  }, [coreT, data, error]);
  const toDiagramLayoutData = (diagram) => {
    const nodeLayoutData = [];
    diagram.nodes.forEach((node) => {
      const {
        id,
        height,
        width,
        position: { x, y }
      } = node;
      const { resizedByUser } = node.data;
      if (height && width) {
        nodeLayoutData.push({
          id,
          position: {
            x,
            y
          },
          size: {
            height,
            width
          },
          resizedByUser
        });
      }
    });
    return {
      nodeLayoutData
    };
  };
  const synchronizeLayoutData = (id, diagram) => {
    const diagramLayoutData = toDiagramLayoutData(diagram);
    const input = {
      id,
      editingContextId,
      representationId,
      diagramLayoutData
    };
    const variables = { input };
    layoutDiagram2({ variables });
  };
  return {
    synchronizeLayoutData
  };
};
const useAdjustSize = () => {
  const { layout: layout2 } = useLayout();
  const { refreshEventPayloadId } = useContext(DiagramContext);
  const { synchronizeLayoutData } = useSynchronizeLayoutData();
  const { hideDiagramElementPalette } = useDiagramElementPalette();
  const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
  const adjustSize = (nodeId) => {
    const nodes = [...getNodes()];
    const targetedNode = nodes.find((node) => node.id === nodeId);
    const childNodes = nodes.filter((node) => node.parentNode === nodeId).map((node) => {
      node.data.resizedByUser = true;
      return node;
    });
    if (targetedNode) {
      targetedNode.data.resizedByUser = false;
      const diagramToLayout = {
        nodes: [targetedNode, ...childNodes],
        edges: getEdges()
      };
      layout2(diagramToLayout, diagramToLayout, null, (laidOutDiagram) => {
        nodes.map((node) => {
          if (node.id === targetedNode.id) {
            return laidOutDiagram.nodes.find((laidOutNode) => laidOutNode.id === targetedNode.id) ?? node;
          }
          return node;
        });
        setNodes(nodes);
        setEdges(laidOutDiagram.edges);
        const finalDiagram = {
          nodes,
          edges: laidOutDiagram.edges
        };
        synchronizeLayoutData(refreshEventPayloadId, finalDiagram);
        hideDiagramElementPalette();
      });
    }
  };
  return { adjustSize };
};
const fadeDiagramElementMutation = gql`
  mutation fadeDiagramElement($input: FadeDiagramElementInput!) {
    fadeDiagramElement(input: $input) {
      __typename
      ... on SuccessPayload {
        id
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$6 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$4 = (payload) => payload.__typename === "SuccessPayload";
const useFadeDiagramElements = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const { diagramId, editingContextId } = useContext(DiagramContext);
  const [fadeElementMutation, { error: fadeDiagramElementError }] = useMutation(fadeDiagramElementMutation);
  useEffect(() => {
    if (fadeDiagramElementError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
  }, [coreT, fadeDiagramElementError]);
  const fadeDiagramElements = useCallback(
    async (nodeId, fade) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        elementIds: nodeId,
        fade
      };
      const { data: fadeDiagramElementData } = await fadeElementMutation({ variables: { input } });
      if (fadeDiagramElementData) {
        const { fadeDiagramElement } = fadeDiagramElementData;
        if (isSuccessPayload$4(fadeDiagramElement) || isErrorPayload$6(fadeDiagramElement)) {
          addMessages(fadeDiagramElement.messages);
        }
      }
    },
    [editingContextId, diagramId, fadeElementMutation]
  );
  return { fadeDiagramElements };
};
const pinDiagramElementMutation = gql`
  mutation pinDiagramElement($input: PinDiagramElementInput!) {
    pinDiagramElement(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$5 = (payload) => payload.__typename === "ErrorPayload";
const usePinDiagramElements = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage } = useMultiToast();
  const { diagramId, editingContextId, readOnly } = useContext(DiagramContext);
  const [pinElementMutation, { data: pinDiagramElementData, error: pinDiagramElementError }] = useMutation(pinDiagramElementMutation);
  useEffect(() => {
    if (pinDiagramElementError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (pinDiagramElementData) {
      const { pinDiagramElement } = pinDiagramElementData;
      if (isErrorPayload$5(pinDiagramElement)) {
        addErrorMessage(pinDiagramElement.message);
      }
    }
  }, [coreT, pinDiagramElementData, pinDiagramElementError]);
  const pinDiagramElements = useCallback(
    (nodeId, pinned) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        elementIds: nodeId,
        pinned
      };
      if (!readOnly) {
        pinElementMutation({ variables: { input } });
      }
    },
    [editingContextId, diagramId, readOnly, pinElementMutation]
  );
  return { pinDiagramElements };
};
var GQLDeletionPolicy = /* @__PURE__ */ ((GQLDeletionPolicy2) => {
  GQLDeletionPolicy2["SEMANTIC"] = "SEMANTIC";
  GQLDeletionPolicy2["GRAPHICAL"] = "GRAPHICAL";
  return GQLDeletionPolicy2;
})(GQLDeletionPolicy || {});
var GQLCollapsingState = /* @__PURE__ */ ((GQLCollapsingState2) => {
  GQLCollapsingState2["EXPANDED"] = "EXPANDED";
  GQLCollapsingState2["COLLAPSED"] = "COLLAPSED";
  return GQLCollapsingState2;
})(GQLCollapsingState || {});
const defaultValue = {
  x: null,
  y: null,
  isOpened: false,
  hideDiagramPalette: () => {
  },
  showDiagramPalette: () => {
  },
  getLastToolInvoked: () => null,
  setLastToolInvoked: () => {
  }
};
const DiagramPaletteContext = React.createContext(defaultValue);
const DiagramPaletteContextProvider = ({ children }) => {
  const [state, setState] = useState({
    x: null,
    y: null,
    isOpened: false,
    lastToolsInvoked: []
  });
  const showPalette = useCallback((x, y) => {
    setState((prevState) => ({ ...prevState, x, y, isOpened: true }));
  }, []);
  const hidePalette = useCallback(() => {
    if (state.isOpened) {
      setState((prevState) => ({ ...prevState, x: null, y: null, isOpened: false }));
    }
  }, [state.isOpened]);
  const getLastToolInvoked = (toolSectionId) => {
    var _a;
    return ((_a = state.lastToolsInvoked.find(
      (toolSectionWithDefaultTool) => toolSectionWithDefaultTool.toolSectionId === toolSectionId
    )) == null ? void 0 : _a.lastTool) || null;
  };
  const setLastToolInvoked = (toolSectionId, tool) => {
    const lastToolsInvoked = state.lastToolsInvoked;
    if (lastToolsInvoked.some((toolSectionWithLastTool) => toolSectionWithLastTool.toolSectionId === toolSectionId)) {
      lastToolsInvoked.splice(
        lastToolsInvoked.findIndex(
          (toolSectionWithLastTool) => toolSectionWithLastTool.toolSectionId === toolSectionId
        ),
        1
      );
    }
    lastToolsInvoked.push({ toolSectionId, lastTool: tool });
  };
  return /* @__PURE__ */ jsx(
    DiagramPaletteContext.Provider,
    {
      value: {
        x: state.x,
        y: state.y,
        isOpened: state.isOpened,
        showDiagramPalette: showPalette,
        hideDiagramPalette: hidePalette,
        getLastToolInvoked,
        setLastToolInvoked
      },
      children
    }
  );
};
const computePalettePosition$1 = (event, bounds) => {
  return {
    x: event.clientX - ((bounds == null ? void 0 : bounds.left) ?? 0),
    y: event.clientY - ((bounds == null ? void 0 : bounds.top) ?? 0)
  };
};
const useDiagramPalette = () => {
  const { x, y, isOpened, hideDiagramPalette, showDiagramPalette, getLastToolInvoked, setLastToolInvoked } = useContext(DiagramPaletteContext);
  const store = useStoreApi();
  const onDiagramBackgroundClick = useCallback(
    (event) => {
      const { domNode } = store.getState();
      const element = domNode == null ? void 0 : domNode.getBoundingClientRect();
      const palettePosition = computePalettePosition$1(event, element);
      if (!event.altKey) {
        showDiagramPalette(palettePosition.x, palettePosition.y);
      }
    },
    [showDiagramPalette]
  );
  const onDiagramElementClick = useCallback(() => hideDiagramPalette(), [hideDiagramPalette]);
  return {
    x,
    y,
    isOpened,
    hideDiagramPalette,
    showDiagramPalette,
    onDiagramBackgroundClick,
    onDiagramElementClick,
    getLastToolInvoked,
    setLastToolInvoked
  };
};
const useToolSectionStyles = makeStyles()((theme2) => ({
  toolSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: theme2.spacing(4.5)
  },
  toolList: {
    padding: "4px",
    border: `1px solid ${theme2.palette.divider}`,
    borderRadius: "2px",
    width: "max-content"
  },
  arrow: {
    cursor: "pointer",
    height: "14px",
    width: "14px",
    marginLeft: "-4px",
    marginTop: "12px"
  }
}));
const isSingleClickOnDiagramElementTool$1 = (tool) => tool.__typename === "SingleClickOnDiagramElementTool";
const ToolSection = ({ toolSection, onToolClick, toolSectionExpandId, onExpand }) => {
  const tools = toolSection.tools.filter(isSingleClickOnDiagramElementTool$1);
  const { getLastToolInvoked, setLastToolInvoked } = useDiagramPalette();
  const { classes } = useToolSectionStyles();
  const onActiveTool = useCallback(
    (tool) => {
      onToolClick(tool);
      setLastToolInvoked(toolSection.id, tool);
    },
    [onToolClick, setLastToolInvoked, toolSection.id]
  );
  const anchorRef = useRef(null);
  let caretContent;
  if (tools.length > 1) {
    caretContent = /* @__PURE__ */ jsx(
      ExpandMoreIcon,
      {
        className: classes.arrow,
        style: { fontSize: 20 },
        onClick: (event) => {
          event.stopPropagation();
          onExpand(toolSectionExpandId === toolSection.id ? null : toolSection.id);
        },
        "data-testid": "expand",
        ref: anchorRef
      }
    );
  }
  const checkLastToolInvoked = () => {
    const lastToolInvoked = getLastToolInvoked(toolSection.id);
    if (lastToolInvoked && tools.some((tool) => tool.id === lastToolInvoked.id)) {
      return lastToolInvoked;
    }
    return void 0;
  };
  const defaultTool = checkLastToolInvoked() || tools[0];
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    defaultTool && /* @__PURE__ */ jsxs("div", { className: classes.toolSection, "data-testid": toolSection.label, children: [
      /* @__PURE__ */ jsx(Tool, { tool: defaultTool, onClick: () => onToolClick(defaultTool), thumbnail: true }),
      caretContent
    ] }),
    /* @__PURE__ */ jsx(
      Popper,
      {
        open: toolSectionExpandId === toolSection.id,
        anchorEl: anchorRef.current,
        placement: "bottom-start",
        disablePortal: true,
        style: { zIndex: 9999 },
        children: /* @__PURE__ */ jsx(Paper, { className: classes.toolList, elevation: 2, children: /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => onExpand(null), children: /* @__PURE__ */ jsx("div", { children: tools.map((tool) => /* @__PURE__ */ jsx(Tool, { tool, onClick: () => onActiveTool(tool) }, tool.id)) }) }) })
      }
    )
  ] });
};
const diagramPaletteToolExtensionPoint = {
  identifier: "diagramPalette#tool",
  FallbackComponent: () => null
};
const usePaletteStyle$1 = makeStyles()((theme2) => ({
  palette: {
    border: `1px solid ${theme2.palette.divider}`,
    borderRadius: "2px",
    zIndex: 5,
    position: "fixed",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: theme2.spacing(45.25)
  },
  toolIcon: {
    width: theme2.spacing(4.5),
    color: theme2.palette.text.primary
  }
}));
const ToolFields = gql`
  fragment ToolFields on Tool {
    __typename
    id
    label
    iconURL
    ... on SingleClickOnDiagramElementTool {
      targetDescriptions {
        id
      }
      appliesToDiagramRoot
      dialogDescriptionId
    }
  }
`;
const getPaletteQuery = gql`
  ${ToolFields}
  query getPalette($editingContextId: ID!, $diagramId: ID!, $diagramElementId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $diagramId) {
          description {
            ... on DiagramDescription {
              palette(diagramElementId: $diagramElementId) {
                id
                tools {
                  ...ToolFields
                }
                toolSections {
                  id
                  label
                  iconURL
                  tools {
                    ...ToolFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const invokeSingleClickOnDiagramElementToolMutation = gql`
  mutation invokeSingleClickOnDiagramElementTool($input: InvokeSingleClickOnDiagramElementToolInput!) {
    invokeSingleClickOnDiagramElementTool(input: $input) {
      __typename
      ... on InvokeSingleClickOnDiagramElementToolSuccessPayload {
        newSelection {
          entries {
            id
            label
            kind
          }
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const deleteFromDiagramMutation$1 = gql`
  mutation deleteFromDiagram($input: DeleteFromDiagramInput!) {
    deleteFromDiagram(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on DeleteFromDiagramSuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const updateCollapsingStateMutation = gql`
  mutation updateCollapsingState($input: UpdateCollapsingStateInput!) {
    updateCollapsingState(input: $input) {
      __typename
      ... on SuccessPayload {
        id
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
const isErrorPayload$4 = (payload) => payload.__typename === "ErrorPayload";
const isDeleteSuccessPayload = (payload) => payload.__typename === "DeleteFromDiagramSuccessPayload";
const isInvokeSingleClickSuccessPayload = (payload) => payload.__typename === "InvokeSingleClickOnDiagramElementToolSuccessPayload";
const isSingleClickOnDiagramElementTool = (tool) => tool.__typename === "SingleClickOnDiagramElementTool";
const isDiagramDescription$1 = (representationDescription) => representationDescription.__typename === "DiagramDescription";
const Palette = ({
  x: paletteX,
  y: paletteY,
  diagramElementId,
  targetObjectId,
  onDirectEditClick,
  hideableDiagramElement
}) => {
  const { t } = useTranslation("siriusComponentsDiagrams", { keyPrefix: "palette" });
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const [state, setState] = useState({ expandedToolSectionId: null });
  const { fadeDiagramElements } = useFadeDiagramElements();
  const { pinDiagramElements } = usePinDiagramElements();
  const { adjustSize } = useAdjustSize();
  const { getNodes, getEdges } = useReactFlow();
  const { diagramId, editingContextId } = useContext(DiagramContext);
  const { addErrorMessage, addMessages } = useMultiToast();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const { showDialog } = useDialog();
  const paletteToolComponents = useComponents(
    diagramPaletteToolExtensionPoint
  );
  const { data: paletteData, error: paletteError } = useQuery(
    getPaletteQuery,
    {
      variables: {
        editingContextId,
        diagramId,
        diagramElementId
      }
    }
  );
  const description = paletteData == null ? void 0 : paletteData.viewer.editingContext.representation.description;
  const palette = description && isDiagramDescription$1(description) ? description.palette : null;
  const node = getNodes().find((node2) => node2.id === diagramElementId);
  const toolCount = (palette ? palette.tools.filter(isSingleClickOnDiagramElementTool).length + palette.toolSections.filter(
    (toolSection) => toolSection.tools.filter(isSingleClickOnDiagramElementTool).length > 0
  ).length : 0) + (hideableDiagramElement ? node ? 3 : 1 : 0) + paletteToolComponents.length;
  const { classes } = usePaletteStyle$1();
  let pinUnpinTool;
  let adjustSizeTool;
  if (node) {
    pinUnpinTool = node.data.pinned ? /* @__PURE__ */ jsx(Tooltip, { title: t("unpin"), children: /* @__PURE__ */ jsx(
      IconButton,
      {
        className: classes.toolIcon,
        size: "small",
        "aria-label": t("unpin"),
        onClick: () => pinDiagramElements([diagramElementId], !node.data.pinned),
        "data-testid": "Unpin-element",
        children: /* @__PURE__ */ jsx(UnpinIcon, { fontSize: "small" })
      }
    ) }) : /* @__PURE__ */ jsx(Tooltip, { title: t("pin"), children: /* @__PURE__ */ jsx(
      IconButton,
      {
        className: classes.toolIcon,
        size: "small",
        "aria-label": t("pin"),
        onClick: () => pinDiagramElements([diagramElementId], true),
        "data-testid": "Pin-element",
        children: /* @__PURE__ */ jsx(PinIcon, { fontSize: "small" })
      }
    ) });
    adjustSizeTool = /* @__PURE__ */ jsx(Tooltip, { title: t("adjustSize"), children: /* @__PURE__ */ jsx(
      IconButton,
      {
        className: classes.toolIcon,
        size: "small",
        "aria-label": t("adjustSize"),
        onClick: () => adjustSize(diagramElementId),
        "data-testid": "adjust-element",
        children: /* @__PURE__ */ jsx(AdjustIcon, { fontSize: "small" })
      }
    ) });
  }
  let x = 0;
  let y = 0;
  const { x: viewportX, y: viewportY, zoom: viewportZoom } = useViewport();
  if (viewportZoom !== 0 && paletteX && paletteY) {
    x = (paletteX - viewportX) / viewportZoom;
    y = (paletteY - viewportY) / viewportZoom;
  }
  useEffect(() => {
    if (paletteError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
  }, [coreT, paletteError]);
  const handleToolSectionExpand = (expandedToolSectionId) => setState((prevState) => ({ ...prevState, expandedToolSectionId }));
  const [deleteElementsMutation] = useMutation(
    deleteFromDiagramMutation$1
  );
  const [invokeSingleClickOnDiagramElementTool] = useMutation(invokeSingleClickOnDiagramElementToolMutation);
  const invokeSingleClickTool = useCallback(
    async (tool, variables) => {
      if (isSingleClickOnDiagramElementTool(tool)) {
        const { id: toolId } = tool;
        const input = {
          id: crypto.randomUUID(),
          editingContextId,
          representationId: diagramId,
          diagramElementId,
          toolId,
          startingPositionX: x,
          startingPositionY: y,
          variables
        };
        const { data } = await invokeSingleClickOnDiagramElementTool({
          variables: { input }
        });
        if (data) {
          const { invokeSingleClickOnDiagramElementTool: invokeSingleClickOnDiagramElementTool2 } = data;
          if (isInvokeSingleClickSuccessPayload(invokeSingleClickOnDiagramElementTool2)) {
            addMessages(invokeSingleClickOnDiagramElementTool2.messages);
          }
          if (isErrorPayload$4(invokeSingleClickOnDiagramElementTool2)) {
            addMessages(invokeSingleClickOnDiagramElementTool2.messages);
          }
        }
      }
    },
    [
      editingContextId,
      diagramId,
      diagramElementId,
      invokeSingleClickOnDiagramElementToolMutation,
      isSingleClickOnDiagramElementTool
    ]
  );
  const invokeDelete = (diagramElementId2, deletionPolicy) => {
    const nodeId = getNodes().find((node2) => node2.id === diagramElementId2);
    if (nodeId) {
      invokeDeleteMutation([diagramElementId2], [], deletionPolicy);
    } else {
      const edgeId = getEdges().find((edge) => edge.id === diagramElementId2);
      if (edgeId) {
        invokeDeleteMutation([], [diagramElementId2], deletionPolicy);
      }
    }
  };
  const invokeDeleteMutation = useCallback(
    async (nodeIds, edgeIds, deletionPolicy) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        nodeIds,
        edgeIds,
        deletionPolicy
      };
      const { data } = await deleteElementsMutation({ variables: { input } });
      if (data) {
        const { deleteFromDiagram } = data;
        if (isErrorPayload$4(deleteFromDiagram) || isDeleteSuccessPayload(deleteFromDiagram)) {
          addMessages(deleteFromDiagram.messages);
        }
      }
    },
    [editingContextId, diagramId, deleteElementsMutation]
  );
  const [collapseExpandMutation] = useMutation(
    updateCollapsingStateMutation
  );
  const collapseExpandElement = useCallback(
    (nodeId, collapsingState) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        diagramElementId: nodeId,
        collapsingState
      };
      collapseExpandMutation({ variables: { input } });
    },
    [editingContextId, diagramId, collapseExpandMutation]
  );
  const handleDialogDescription = (tool) => {
    const onConfirm = (variables) => {
      invokeSingleClickTool(tool, variables);
    };
    showDialog(tool.dialogDescriptionId, targetObjectId, onConfirm);
  };
  const handleToolClick = (tool) => {
    switch (tool.id) {
      case "edit":
        onDirectEditClick();
        break;
      case "semantic-delete":
        showDeletionConfirmation(() => {
          invokeDelete(diagramElementId, GQLDeletionPolicy.SEMANTIC);
        });
        break;
      case "graphical-delete":
        invokeDelete(diagramElementId, GQLDeletionPolicy.GRAPHICAL);
        break;
      case "expand":
        collapseExpandElement(diagramElementId, GQLCollapsingState.EXPANDED);
        break;
      case "collapse":
        collapseExpandElement(diagramElementId, GQLCollapsingState.COLLAPSED);
        break;
      default:
        if (isSingleClickOnDiagramElementTool(tool)) {
          if (tool.dialogDescriptionId) {
            handleDialogDescription(tool);
          } else {
            invokeSingleClickTool(tool, []);
          }
        }
        break;
    }
  };
  const invokeFadeDiagramElementTool = () => {
    fadeDiagramElements([diagramElementId], true);
  };
  const shouldRender = palette && (node || !node && toolCount > 0);
  if (!shouldRender) {
    return null;
  }
  return /* @__PURE__ */ jsxs(
    Paper,
    {
      className: classes.palette,
      style: { position: "absolute", left: paletteX, top: paletteY },
      "data-testid": "Palette",
      children: [
        palette == null ? void 0 : palette.tools.filter(isSingleClickOnDiagramElementTool).map((tool) => /* @__PURE__ */ jsx(Tool, { tool, onClick: handleToolClick, thumbnail: true }, tool.id)),
        palette == null ? void 0 : palette.toolSections.map((toolSection) => /* @__PURE__ */ jsx(
          ToolSection,
          {
            toolSection,
            onToolClick: handleToolClick,
            onExpand: handleToolSectionExpand,
            toolSectionExpandId: state.expandedToolSectionId
          },
          toolSection.id
        )),
        paletteToolComponents.map(({ Component: PaletteToolComponent }, index) => /* @__PURE__ */ jsx(PaletteToolComponent, { x, y, diagramElementId }, index)),
        hideableDiagramElement ? /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Tooltip, { title: t("fade"), children: /* @__PURE__ */ jsx(
            IconButton,
            {
              className: classes.toolIcon,
              size: "small",
              "aria-label": t("fade"),
              onClick: invokeFadeDiagramElementTool,
              "data-testid": "Fade-element",
              children: /* @__PURE__ */ jsx(TonalityIcon, { fontSize: "small" })
            }
          ) }),
          pinUnpinTool,
          adjustSizeTool
        ] }) : null
      ]
    }
  );
};
const palettePortalStyle = {
  zIndex: 5
};
const PalettePortal = ({ children }) => {
  const wrapperRef = useStoreApi().getState().domNode;
  if (!wrapperRef) {
    return null;
  }
  return createPortal(/* @__PURE__ */ jsx("div", { style: palettePortalStyle, children }), wrapperRef);
};
const DiagramElementPalette = memo(
  ({ diagramElementId, targetObjectId, labelId }) => {
    const { readOnly } = useContext(DiagramContext);
    const { isOpened, x, y, hideDiagramElementPalette } = useDiagramElementPalette();
    const { setCurrentlyEditedLabelId, currentlyEditedLabelId } = useDiagramDirectEdit();
    const escapePressed = useKeyPress("Escape");
    useEffect(() => {
      if (escapePressed) {
        hideDiagramElementPalette();
      }
    }, [escapePressed, hideDiagramElementPalette]);
    if (readOnly) {
      return null;
    }
    const handleDirectEditClick = () => {
      if (labelId) {
        setCurrentlyEditedLabelId("palette", labelId, null);
      }
    };
    return isOpened && x && y && !currentlyEditedLabelId ? /* @__PURE__ */ jsx(PalettePortal, { children: /* @__PURE__ */ jsx(
      Palette,
      {
        x,
        y,
        diagramElementId,
        targetObjectId,
        onDirectEditClick: handleDirectEditClick,
        hideableDiagramElement: true
      }
    ) }) : null;
  }
);
const resizeLineStyle = (theme2) => {
  return { borderWidth: theme2.spacing(0.15) };
};
const resizeControlLineStyle = (theme2) => {
  return { borderColor: "transparent", borderWidth: theme2.spacing(0.25) };
};
const resizeHandleStyle = (theme2) => {
  return {
    width: theme2.spacing(1),
    height: theme2.spacing(1),
    borderRadius: "100%"
  };
};
const Resizer = memo(({ data, selected }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i;
  const { readOnly } = useContext(DiagramContext);
  const theme2 = useTheme();
  if (((_a = data.nodeDescription) == null ? void 0 : _a.userResizable) !== "NONE" && !readOnly) {
    if (data.isListChild) {
      return /* @__PURE__ */ jsx(
        NodeResizeControl,
        {
          variant: ResizeControlVariant.Line,
          position: "bottom",
          shouldResize: () => !data.isBorderNode,
          style: { ...resizeControlLineStyle(theme2) }
        }
      );
    } else if (((_b = data.nodeDescription) == null ? void 0 : _b.userResizable) === "BOTH") {
      return /* @__PURE__ */ jsx(
        NodeResizer,
        {
          handleStyle: { ...resizeHandleStyle(theme2) },
          lineStyle: { ...resizeLineStyle(theme2) },
          color: theme2.palette.selected,
          isVisible: selected,
          shouldResize: () => !data.isBorderNode,
          keepAspectRatio: (_c = data.nodeDescription) == null ? void 0 : _c.keepAspectRatio
        }
      );
    } else if (((_d = data.nodeDescription) == null ? void 0 : _d.userResizable) === "HORIZONTAL" && selected) {
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          NodeResizeControl,
          {
            variant: ResizeControlVariant.Line,
            position: "left",
            shouldResize: () => !data.isBorderNode,
            style: { ...resizeControlLineStyle(theme2) },
            keepAspectRatio: (_e = data.nodeDescription) == null ? void 0 : _e.keepAspectRatio
          }
        ),
        /* @__PURE__ */ jsx(
          NodeResizeControl,
          {
            variant: ResizeControlVariant.Line,
            position: "right",
            shouldResize: () => !data.isBorderNode,
            style: { ...resizeControlLineStyle(theme2) },
            keepAspectRatio: (_f = data.nodeDescription) == null ? void 0 : _f.keepAspectRatio
          }
        )
      ] });
    } else if (((_g = data.nodeDescription) == null ? void 0 : _g.userResizable) === "VERTICAL" && selected) {
      return /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          NodeResizeControl,
          {
            variant: ResizeControlVariant.Line,
            position: "top",
            shouldResize: () => !data.isBorderNode,
            style: { ...resizeControlLineStyle(theme2) },
            keepAspectRatio: (_h = data.nodeDescription) == null ? void 0 : _h.keepAspectRatio
          }
        ),
        /* @__PURE__ */ jsx(
          NodeResizeControl,
          {
            variant: ResizeControlVariant.Line,
            position: "bottom",
            shouldResize: () => !data.isBorderNode,
            style: { ...resizeControlLineStyle(theme2) },
            keepAspectRatio: (_i = data.nodeDescription) == null ? void 0 : _i.keepAspectRatio
          }
        )
      ] });
    }
  }
  return null;
});
const freeFormNodeStyle = (theme2, style, selected, hovered, faded) => {
  const freeFormNodeStyle2 = {
    width: "100%",
    height: "100%",
    opacity: faded ? "0.4" : "",
    ...style,
    borderColor: getCSSColor(String(style.borderColor), theme2)
  };
  if (selected || hovered) {
    freeFormNodeStyle2.outline = `${theme2.palette.selected} solid 1px`;
  }
  return freeFormNodeStyle2;
};
const backgroundNodeStyle = (theme2, style, rotation, imageURL) => {
  const freeFormNodeStyle2 = {
    width: "100%",
    height: "100%",
    transform: rotation,
    background: getCSSColor(String(style.background), theme2),
    borderRadius: style.borderRadius,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1
  };
  if (imageURL) {
    freeFormNodeStyle2.backgroundImage = `url(${imageURL})`;
    freeFormNodeStyle2.backgroundRepeat = "no-repeat";
    freeFormNodeStyle2.backgroundSize = "100% 100%";
  }
  return freeFormNodeStyle2;
};
const computeBorderRotation = (data) => {
  if ((data == null ? void 0 : data.isBorderNode) && data.positionDependentRotation) {
    switch (data.borderNodePosition) {
      case BorderNodePosition.NORTH:
        return "rotate(90deg)";
      case BorderNodePosition.EAST:
        return "rotate(180deg)";
      case BorderNodePosition.SOUTH:
        return "rotate(270deg)";
      default:
        return void 0;
    }
  }
  return void 0;
};
const FreeFormNode = memo(({ data, id, selected, dragging }) => {
  const { httpOrigin } = useContext(ServerContext);
  const theme2 = useTheme();
  const { onDrop, onDragOver } = useDrop();
  const rotation = useMemo(
    () => computeBorderRotation(data),
    [data.isBorderNode, data.positionDependentRotation, data.borderNodePosition]
  );
  let imageURL = void 0;
  if (data.imageURL) {
    imageURL = httpOrigin + data.imageURL;
  }
  const { style: connectionFeedbackStyle } = useConnectorNodeStyle(id, data.nodeDescription.id);
  const { style: dropFeedbackStyle } = useDropNodeStyle(data.isDropNodeTarget, data.isDropNodeCandidate, dragging);
  const nodeStyle = useMemo(
    () => freeFormNodeStyle(theme2, data.style, selected, data.isHovered, data.faded),
    [data.style, selected, data.isHovered, data.faded]
  );
  const backgroundStyle = useMemo(
    () => backgroundNodeStyle(theme2, data.style, rotation, imageURL),
    [data.style, rotation, imageURL]
  );
  const handleOnDrop = (event) => {
    onDrop(event, id);
  };
  useRefreshConnectionHandles(id, data.connectionHandles);
  const getLabelId = (data2) => {
    let labelId = null;
    if (data2.insideLabel) {
      labelId = data2.insideLabel.id;
    } else if (data2.outsideLabels.BOTTOM_MIDDLE) {
      labelId = data2.outsideLabels.BOTTOM_MIDDLE.id;
    }
    return labelId;
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Resizer, { data, selected }),
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          ...nodeStyle,
          ...connectionFeedbackStyle,
          ...dropFeedbackStyle
        },
        onDragOver,
        onDrop: handleOnDrop,
        "data-testid": `FreeForm - ${data == null ? void 0 : data.targetObjectLabel}`,
        children: [
          /* @__PURE__ */ jsx("div", { style: { ...backgroundStyle } }),
          data.insideLabel && /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: data.insideLabel, faded: data.faded }),
          selected ? /* @__PURE__ */ jsx(
            DiagramElementPalette,
            {
              diagramElementId: id,
              targetObjectId: data.targetObjectId,
              labelId: getLabelId(data)
            }
          ) : null,
          selected ? /* @__PURE__ */ jsx(ConnectionCreationHandles, { nodeId: id }) : null,
          /* @__PURE__ */ jsx(ConnectionTargetHandle, { nodeId: id, nodeDescription: data.nodeDescription, isHovered: data.isHovered }),
          /* @__PURE__ */ jsx(ConnectionHandles, { connectionHandles: data.connectionHandles })
        ]
      }
    ),
    data.outsideLabels.BOTTOM_MIDDLE && /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: data.outsideLabels.BOTTOM_MIDDLE, faded: data.faded })
  ] });
});
const listNodeStyle = (theme2, style, selected, hovered, faded) => {
  const listNodeStyle2 = {
    width: "100%",
    height: "100%",
    opacity: faded ? "0.4" : "",
    ...style,
    background: getCSSColor(String(style.background), theme2),
    borderTopColor: getCSSColor(String(style.borderTopColor), theme2),
    borderBottomColor: getCSSColor(String(style.borderBottomColor), theme2),
    borderLeftColor: getCSSColor(String(style.borderLeftColor), theme2),
    borderRightColor: getCSSColor(String(style.borderRightColor), theme2)
  };
  if (selected || hovered) {
    listNodeStyle2.outline = `${theme2.palette.selected} solid 1px`;
  }
  return listNodeStyle2;
};
const ListNode = memo(({ data, id, selected, dragging }) => {
  var _a;
  const theme2 = useTheme();
  const { onDrop, onDragOver } = useDrop();
  const { style: connectionFeedbackStyle } = useConnectorNodeStyle(id, data.nodeDescription.id);
  const { style: dropFeedbackStyle } = useDropNodeStyle(data.isDropNodeTarget, data.isDropNodeCandidate, dragging);
  const nodeStyle = useMemo(
    () => listNodeStyle(theme2, data.style, selected, data.isHovered, data.faded),
    [data.style, selected, data.isHovered, data.faded]
  );
  const handleOnDrop = (event) => {
    onDrop(event, id);
  };
  useRefreshConnectionHandles(id, data.connectionHandles);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Resizer, { data, selected }),
    /* @__PURE__ */ jsxs(
      "div",
      {
        style: {
          ...nodeStyle,
          ...connectionFeedbackStyle,
          ...dropFeedbackStyle
        },
        onDragOver,
        onDrop: handleOnDrop,
        "data-testid": `List - ${(_a = data == null ? void 0 : data.insideLabel) == null ? void 0 : _a.text}`,
        children: [
          data.insideLabel ? /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: data.insideLabel, faded: data.faded }) : null,
          selected ? /* @__PURE__ */ jsx(
            DiagramElementPalette,
            {
              diagramElementId: id,
              targetObjectId: data.targetObjectId,
              labelId: data.insideLabel ? data.insideLabel.id : null
            }
          ) : null,
          selected ? /* @__PURE__ */ jsx(ConnectionCreationHandles, { nodeId: id }) : null,
          /* @__PURE__ */ jsx(ConnectionTargetHandle, { nodeId: id, nodeDescription: data.nodeDescription, isHovered: data.isHovered }),
          /* @__PURE__ */ jsx(ConnectionHandles, { connectionHandles: data.connectionHandles })
        ]
      }
    )
  ] });
});
class FreeFormNodeLayoutHandler {
  canHandle(node) {
    return node.type === "freeFormNode";
  }
  handle(layoutEngine, previousDiagram, node, visibleNodes, directChildren, newlyAddedNode, forceDimensions) {
    var _a;
    const nodeIndex = findNodeIndex(visibleNodes, node.id);
    const nodeElement = (_a = document.getElementById(`${node.id}-freeFormNode-${nodeIndex}`)) == null ? void 0 : _a.children[0];
    const borderWidth = nodeElement ? parseFloat(window.getComputedStyle(nodeElement).borderWidth) : 0;
    if (directChildren.length > 0) {
      this.handleParentNode(
        layoutEngine,
        previousDiagram,
        node,
        visibleNodes,
        directChildren,
        newlyAddedNode,
        borderWidth,
        forceDimensions
      );
    } else {
      this.handleLeafNode(previousDiagram, node, visibleNodes, borderWidth, forceDimensions);
    }
  }
  handleParentNode(layoutEngine, previousDiagram, node, visibleNodes, directChildren, newlyAddedNode, borderWidth, forceDimensions) {
    var _a;
    layoutEngine.layoutNodes(previousDiagram, visibleNodes, directChildren, newlyAddedNode);
    const nodeIndex = findNodeIndex(visibleNodes, node.id);
    const labelElement = document.getElementById(`${node.id}-label-${nodeIndex}`);
    const borderNodes = directChildren.filter((node2) => node2.data.isBorderNode);
    const directNodesChildren = directChildren.filter((child) => !child.data.isBorderNode);
    directNodesChildren.forEach((child, index) => {
      const previousNode2 = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previouseNode) => previouseNode.id === child.id);
      const previousPosition = computePreviousPosition(previousNode2, child);
      const createdNode = (newlyAddedNode == null ? void 0 : newlyAddedNode.id) === child.id ? newlyAddedNode : void 0;
      const headerHeightFootprint = getHeaderHeightFootprint(labelElement, node.data.insideLabel, "TOP");
      if (!!createdNode) {
        child.position = createdNode.position;
        if (child.position.y < borderWidth + headerHeightFootprint) {
          child.position = { ...child.position, y: borderWidth + headerHeightFootprint };
        }
      } else if (previousPosition) {
        child.position = previousPosition;
        if (child.position.y < borderWidth + headerHeightFootprint) {
          child.position = { ...child.position, y: borderWidth + headerHeightFootprint };
        }
        if (child.position.x < borderWidth + rectangularNodePadding$1) {
          child.position = { ...child.position, x: borderWidth + rectangularNodePadding$1 };
        }
      } else {
        child.position = getChildNodePosition(visibleNodes, child, headerHeightFootprint, borderWidth);
        const previousSibling = directNodesChildren[index - 1];
        if (previousSibling) {
          child.position = getChildNodePosition(
            visibleNodes,
            child,
            headerHeightFootprint,
            borderWidth,
            previousSibling
          );
        }
      }
    });
    const childrenContentBox = computeNodesBox(visibleNodes, directNodesChildren);
    const footerHeightFootprint = getHeaderHeightFootprint(labelElement, node.data.insideLabel, "BOTTOM");
    const directChildrenAwareNodeWidth = childrenContentBox.x + childrenContentBox.width + rectangularNodePadding$1;
    const northBorderNodeFootprintWidth = getNorthBorderNodeFootprintWidth(visibleNodes, borderNodes, previousDiagram);
    const southBorderNodeFootprintWidth = getSouthBorderNodeFootprintWidth(visibleNodes, borderNodes, previousDiagram);
    const labelOnlyWidth = rectangularNodePadding$1 + getInsideLabelWidthConstraint(node.data.insideLabel, labelElement) + rectangularNodePadding$1;
    const nodeMinComputeWidth = Math.max(
      directChildrenAwareNodeWidth,
      labelOnlyWidth,
      northBorderNodeFootprintWidth,
      southBorderNodeFootprintWidth
    ) + borderWidth * 2;
    const directChildrenAwareNodeHeight = childrenContentBox.y + childrenContentBox.height + footerHeightFootprint;
    const eastBorderNodeFootprintHeight = getEastBorderNodeFootprintHeight(visibleNodes, borderNodes, previousDiagram);
    const westBorderNodeFootprintHeight = getWestBorderNodeFootprintHeight(visibleNodes, borderNodes, previousDiagram);
    const nodeMinComputeHeight = Math.max(directChildrenAwareNodeHeight, eastBorderNodeFootprintHeight, westBorderNodeFootprintHeight) + borderWidth * 2;
    const nodeWidth = (forceDimensions == null ? void 0 : forceDimensions.width) ?? getDefaultOrMinWidth(nodeMinComputeWidth, node);
    const nodeHeight = (forceDimensions == null ? void 0 : forceDimensions.height) ?? getDefaultOrMinHeight(nodeMinComputeHeight, node);
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previouseNode) => previouseNode.id === node.id);
    const previousDimensions = computePreviousSize(previousNode, node);
    if (node.data.resizedByUser) {
      if (nodeMinComputeWidth > previousDimensions.width) {
        node.width = nodeMinComputeWidth;
      } else {
        node.width = previousDimensions.width;
      }
      if (nodeMinComputeHeight > previousDimensions.height) {
        node.height = nodeMinComputeHeight;
      } else {
        node.height = previousDimensions.height;
      }
    } else {
      node.width = nodeWidth;
      node.height = nodeHeight;
    }
    if ((_a = node.data.nodeDescription) == null ? void 0 : _a.keepAspectRatio) {
      applyRatioOnNewNodeSizeValue(node);
    }
    borderNodes.forEach((borderNode) => {
      borderNode.extent = getBorderNodeExtent(node, borderNode);
    });
    setBorderNodesPosition(borderNodes, node, previousDiagram);
  }
  handleLeafNode(previousDiagram, node, visibleNodes, borderWidth, forceDimensions) {
    var _a;
    const nodeIndex = findNodeIndex(visibleNodes, node.id);
    const labelElement = document.getElementById(`${node.id}-label-${nodeIndex}`);
    const nodeMinComputeWidth = rectangularNodePadding$1 + getInsideLabelWidthConstraint(node.data.insideLabel, labelElement) + rectangularNodePadding$1 + borderWidth * 2;
    const nodeMinComputeHeight = rectangularNodePadding$1 + ((labelElement == null ? void 0 : labelElement.getBoundingClientRect().height) ?? 0) + rectangularNodePadding$1;
    const nodeWith = (forceDimensions == null ? void 0 : forceDimensions.width) ?? getDefaultOrMinWidth(nodeMinComputeWidth, node);
    const nodeHeight = (forceDimensions == null ? void 0 : forceDimensions.height) ?? getDefaultOrMinHeight(nodeMinComputeHeight, node);
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previouseNode) => previouseNode.id === node.id);
    const previousDimensions = computePreviousSize(previousNode, node);
    if (node.data.resizedByUser) {
      if (nodeMinComputeWidth > previousDimensions.width) {
        node.width = nodeMinComputeWidth;
      } else {
        node.width = previousDimensions.width;
      }
      if (nodeMinComputeHeight > previousDimensions.height) {
        node.height = nodeMinComputeHeight;
      } else {
        node.height = previousDimensions.height;
      }
    } else {
      node.width = nodeWith;
      node.height = nodeHeight;
    }
    if ((_a = node.data.nodeDescription) == null ? void 0 : _a.keepAspectRatio) {
      applyRatioOnNewNodeSizeValue(node);
    }
  }
}
const rectangularNodePadding = 8;
class IconLabelNodeLayoutHandler {
  canHandle(node) {
    return node.type === "iconLabelNode";
  }
  handle(_layoutEngine, _previousDiagram, node, visibleNodes, _directChildren, _newlyAddedNode, forceDimensions) {
    const nodeIndex = this.findNodeIndex(visibleNodes, node.id);
    const labelElement = document.getElementById(`${node.id}-label-${nodeIndex}`);
    node.width = (forceDimensions == null ? void 0 : forceDimensions.width) ?? rectangularNodePadding + getInsideLabelWidthConstraint(node.data.insideLabel, labelElement) + rectangularNodePadding;
    node.height = labelElement == null ? void 0 : labelElement.getBoundingClientRect().height;
  }
  findNodeIndex(nodes, nodeId) {
    return nodes.findIndex((node) => node.id === nodeId);
  }
}
class ListNodeLayoutHandler {
  canHandle(node) {
    return node.type === "listNode";
  }
  handle(layoutEngine, previousDiagram, node, visibleNodes, directChildren, newlyAddedNode, forceDimensions) {
    const nodeIndex = findNodeIndex(visibleNodes, node.id);
    const nodeElement = document.getElementById(`${node.id}-listNode-${nodeIndex}`);
    const nodeElementChild = (nodeElement == null ? void 0 : nodeElement.children) && Array.from(nodeElement.children).filter((child) => !child.classList.contains("react-flow__resize-control"))[0];
    const borderWidth = nodeElementChild ? parseFloat(window.getComputedStyle(nodeElementChild).borderLeftWidth) : 0;
    if (directChildren.length > 0) {
      this.handleParentNode(
        layoutEngine,
        previousDiagram,
        node,
        visibleNodes,
        directChildren,
        newlyAddedNode,
        borderWidth,
        forceDimensions
      );
    } else {
      this.handleLeafNode(previousDiagram, node, visibleNodes, borderWidth, forceDimensions);
    }
  }
  handleLeafNode(previousDiagram, node, visibleNodes, borderWidth, forceDimensions) {
    const labelElement = document.getElementById(`${node.id}-label-${findNodeIndex(visibleNodes, node.id)}`);
    const nodeMinComputeWidth = ((labelElement == null ? void 0 : labelElement.getBoundingClientRect().width) ?? 0) + borderWidth * 2;
    const nodeMinComputeHeight = ((labelElement == null ? void 0 : labelElement.getBoundingClientRect().height) ?? 0) + borderWidth * 2;
    const nodeWith = (forceDimensions == null ? void 0 : forceDimensions.width) ?? getDefaultOrMinWidth(nodeMinComputeWidth, node);
    const nodeHeight = (forceDimensions == null ? void 0 : forceDimensions.height) ?? getDefaultOrMinHeight(nodeMinComputeHeight, node);
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previouseNode) => previouseNode.id === node.id);
    const previousDimensions = computePreviousSize(previousNode, node);
    if (node.data.resizedByUser) {
      if (nodeMinComputeWidth > previousDimensions.width) {
        node.width = nodeMinComputeWidth;
      } else {
        node.width = previousDimensions.width;
      }
      if (nodeMinComputeHeight > previousDimensions.height) {
        node.height = nodeMinComputeHeight;
      } else {
        node.height = previousDimensions.height;
      }
    } else {
      node.width = nodeWith;
      node.height = nodeHeight;
    }
  }
  handleParentNode(layoutEngine, previousDiagram, node, visibleNodes, directChildren, newlyAddedNode, borderWidth, forceDimensions) {
    var _a, _b;
    layoutEngine.layoutNodes(previousDiagram, visibleNodes, directChildren, newlyAddedNode, forceDimensions);
    const nodeIndex = findNodeIndex(visibleNodes, node.id);
    const labelElement = document.getElementById(`${node.id}-label-${nodeIndex}`);
    const withHeader = ((_a = node.data.insideLabel) == null ? void 0 : _a.isHeader) ?? false;
    const borderNodes = directChildren.filter((node2) => node2.data.isBorderNode);
    const directNodesChildren = directChildren.filter((child) => !child.data.isBorderNode);
    const northBorderNodeFootprintWidth = getNorthBorderNodeFootprintWidth(visibleNodes, borderNodes, previousDiagram);
    const southBorderNodeFootprintWidth = getSouthBorderNodeFootprintWidth(visibleNodes, borderNodes, previousDiagram);
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find(
      (previouseNode) => previouseNode.id === node.id
    );
    if (!forceDimensions) {
      let previousChildrenContentBoxWidthToConsider = getDefaultOrMinWidth(0, node) - borderWidth * 2;
      let previousChildrenContentBoxHeightToConsider = 0;
      if (node.data.resizedByUser) {
        previousChildrenContentBoxWidthToConsider = ((previousNode == null ? void 0 : previousNode.width) ?? node.width ?? 0) - borderWidth * 2;
        previousChildrenContentBoxHeightToConsider = ((previousNode == null ? void 0 : previousNode.height) ?? node.height ?? 0) - borderWidth * 2 - (withHeader ? (labelElement == null ? void 0 : labelElement.getBoundingClientRect().height) ?? 0 : 0);
      }
      const fixedWidth = Math.max(
        directNodesChildren.reduce(
          (widerWidth, child) => Math.max(child.width ?? 0, widerWidth),
          (labelElement == null ? void 0 : labelElement.getBoundingClientRect().width) ?? 0
        ),
        northBorderNodeFootprintWidth,
        southBorderNodeFootprintWidth,
        previousChildrenContentBoxWidthToConsider
      );
      const nonGrowableChilds = directNodesChildren.filter(
        (child) => !node.data.growableNodeIds.includes(child.data.descriptionId) || child.data.resizedByUser
      );
      nonGrowableChilds.forEach((nonGrowableChild) => {
        layoutEngine.layoutNodes(previousDiagram, visibleNodes, [nonGrowableChild], newlyAddedNode, {
          width: fixedWidth,
          height: null
        });
      });
      previousChildrenContentBoxHeightToConsider -= nonGrowableChilds.reduce(
        (height, node2) => height + (node2.height ?? 0),
        0
      );
      const growableChilds = directNodesChildren.filter(
        (child) => node.data.growableNodeIds.includes(child.data.descriptionId) && !child.data.resizedByUser
      );
      const childHeight = previousChildrenContentBoxHeightToConsider / growableChilds.length;
      growableChilds.forEach((growableChild) => {
        layoutEngine.layoutNodes(previousDiagram, visibleNodes, [growableChild], newlyAddedNode, {
          width: fixedWidth,
          height: Math.max(growableChild.height ?? 0, childHeight)
        });
      });
    }
    directNodesChildren.forEach((child, index) => {
      child.position = {
        x: borderWidth,
        y: borderWidth + (withHeader ? (labelElement == null ? void 0 : labelElement.getBoundingClientRect().height) ?? 0 : 0) + node.data.topGap
      };
      const previousSibling = directNodesChildren[index - 1];
      if (previousSibling) {
        child.position = { ...child.position, y: previousSibling.position.y + (previousSibling.height ?? 0) };
      }
    });
    const childrenContentBox = computeNodesBox(visibleNodes, directNodesChildren);
    const labelOnlyWidth = (labelElement == null ? void 0 : labelElement.getBoundingClientRect().width) ?? 0;
    const nodeMinComputeWidth = Math.max(childrenContentBox.width, labelOnlyWidth) + borderWidth * 2;
    const directChildrenAwareNodeHeight = childrenContentBox.y + childrenContentBox.height + borderWidth + node.data.bottomGap;
    const eastBorderNodeFootprintHeight = getEastBorderNodeFootprintHeight(visibleNodes, borderNodes, previousDiagram);
    const westBorderNodeFootprintHeight = getWestBorderNodeFootprintHeight(visibleNodes, borderNodes, previousDiagram);
    const nodeMinComputeHeight = Math.max(
      directChildrenAwareNodeHeight,
      eastBorderNodeFootprintHeight,
      westBorderNodeFootprintHeight
    );
    const nodeWidth = (forceDimensions == null ? void 0 : forceDimensions.width) ?? getDefaultOrMinWidth(nodeMinComputeWidth, node);
    const nodeHeight = (forceDimensions == null ? void 0 : forceDimensions.height) ?? getDefaultOrMinHeight(nodeMinComputeHeight, node);
    const previousDimensions = computePreviousSize(previousNode, node);
    if (node.data.resizedByUser) {
      if (nodeMinComputeWidth > previousDimensions.width) {
        node.width = nodeMinComputeWidth;
      } else {
        node.width = previousDimensions.width;
      }
      if (nodeMinComputeHeight > previousDimensions.height) {
        node.height = nodeMinComputeHeight;
      } else {
        node.height = previousDimensions.height;
      }
    } else {
      node.width = nodeWidth;
      node.height = nodeHeight;
    }
    if ((_b = node.data.nodeDescription) == null ? void 0 : _b.keepAspectRatio) {
      applyRatioOnNewNodeSizeValue(node);
    }
    borderNodes.forEach((borderNode) => {
      borderNode.extent = getBorderNodeExtent(node, borderNode);
    });
    setBorderNodesPosition(borderNodes, node, previousDiagram);
  }
}
class LayoutEngine {
  constructor() {
    this.nodeLayoutHandlers = [
      new FreeFormNodeLayoutHandler(),
      new ListNodeLayoutHandler(),
      new IconLabelNodeLayoutHandler()
    ];
  }
  layoutNodes(previousDiagram, visibleNodes, nodesToLayout, newlyAddedNode, forceDimensions) {
    nodesToLayout.forEach((node) => {
      const nodeLayoutHandler = this.nodeLayoutHandlers.find(
        (handler) => handler.canHandle(node)
      );
      if (nodeLayoutHandler) {
        const directChildren = visibleNodes.filter((visibleNode) => visibleNode.parentNode === node.id);
        nodeLayoutHandler.handle(
          this,
          previousDiagram,
          node,
          visibleNodes,
          directChildren,
          newlyAddedNode,
          forceDimensions
        );
        node.style = {
          ...node.style,
          width: `${node.width}px`,
          height: `${node.height}px`
        };
      }
    });
  }
  registerNodeLayoutHandlerContribution(nodeLayoutHandlerContribution) {
    this.nodeLayoutHandlers.push(nodeLayoutHandlerContribution);
  }
}
const emptyNodeProps = {
  selected: false,
  isConnectable: true,
  dragging: false,
  xPos: 0,
  yPos: 0,
  zIndex: -1
};
const emptyListNodeProps = {
  ...emptyNodeProps,
  type: "listNode"
};
const emptyRectangularNodeProps = {
  ...emptyNodeProps,
  type: "rectangularNode"
};
const isListNode = (node) => node.type === "listNode";
const isRectangularNode = (node) => node.type === "rectangularNode";
const prepareLayoutArea = (diagram, renderCallback, httpOrigin) => {
  const hiddenContainer = document.createElement("div");
  hiddenContainer.id = "hidden-container";
  hiddenContainer.style.display = "inline-block";
  hiddenContainer.style.position = "absolute";
  hiddenContainer.style.visibility = "hidden";
  hiddenContainer.style.zIndex = "-1";
  document.body.appendChild(hiddenContainer);
  const elements = [];
  const visibleNodes = diagram.nodes.filter((node) => !node.hidden);
  const labelElements = [];
  visibleNodes.forEach((node, index) => {
    var _a;
    if (hiddenContainer && node.data.insideLabel) {
      const children = [
        createElement(Label, {
          diagramElementId: node.id,
          label: node.data.insideLabel,
          faded: false,
          transform: "",
          key: node.data.insideLabel.id
        })
      ];
      const element2 = createElement("div", {
        id: `${node.id}-label-${index}`,
        key: `${node.id}-label-${index}`,
        role: "button",
        // role applied by react flow
        style: {
          maxWidth: ((_a = node.data.insideLabel) == null ? void 0 : _a.overflowStrategy) === "NONE" ? void 0 : node.width,
          borderWidth: node.data.style.borderWidth,
          borderStyle: node.data.style.borderStyle
        },
        children
      });
      labelElements.push(element2);
    }
    if (hiddenContainer && node.data.outsideLabels.BOTTOM_MIDDLE) {
      const outsideLabel = node.data.outsideLabels.BOTTOM_MIDDLE;
      const children = [
        createElement(Label, {
          diagramElementId: node.id,
          label: outsideLabel,
          faded: false,
          transform: "",
          key: outsideLabel.id
        })
      ];
      const element2 = createElement("div", {
        id: `${outsideLabel.id}-label`,
        key: `${outsideLabel.id}-label`,
        role: "button",
        // role applied by react flow
        style: { maxWidth: node.width },
        children
      });
      labelElements.push(element2);
    }
  });
  const labelContainerElement = createElement("div", {
    id: "hidden-label-container",
    key: "hidden-label-container",
    style: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start"
    },
    children: labelElements
  });
  elements.push(labelContainerElement);
  const nodeElements = [];
  visibleNodes.forEach((node, index) => {
    if (hiddenContainer && node) {
      const children = [];
      if (isRectangularNode(node)) {
        const element2 = createElement(FreeFormNode, {
          ...emptyRectangularNodeProps,
          id: node.id,
          data: node.data,
          key: `${node.id}-${index}`
        });
        children.push(element2);
      }
      if (isListNode(node)) {
        const element2 = createElement(ListNode, {
          ...emptyListNodeProps,
          id: node.id,
          data: node.data,
          key: `${node.id}-${index}`
        });
        children.push(element2);
      }
      if (children.length > 0) {
        const elementWrapper = createElement("div", {
          id: `${node.id}-${node.type}-${index}`,
          key: node.id,
          children
        });
        nodeElements.push(elementWrapper);
      }
    }
  });
  const nodeContainerElement = createElement("div", {
    id: "hidden-node-container",
    key: "hidden-node-container",
    style: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start"
    },
    children: nodeElements
  });
  elements.push(nodeContainerElement);
  const hiddenContainerContentElements = createElement(Fragment$1, { children: elements });
  const element = /* @__PURE__ */ jsx(ReactFlowProvider, { children: /* @__PURE__ */ jsx(ApolloProvider, { client: new ApolloClient({ cache: new InMemoryCache(), uri: "" }), children: /* @__PURE__ */ jsx(ThemeProvider, { theme, children: /* @__PURE__ */ jsx(ServerContext.Provider, { value: { httpOrigin }, children: /* @__PURE__ */ jsx(
    ToastContext.Provider,
    {
      value: {
        enqueueSnackbar: (_body, _options) => {
        }
      },
      children: /* @__PURE__ */ jsx(DiagramDirectEditContextProvider, { children: hiddenContainerContentElements })
    }
  ) }) }) }) });
  ReactDOM.render(element, hiddenContainer, renderCallback);
  return hiddenContainer;
};
const cleanLayoutArea = (container) => {
  if (container == null ? void 0 : container.parentNode) {
    container.parentNode.removeChild(container);
  }
};
const gap = 20;
const layout = (previousDiagram, diagram, referencePosition, nodeLayoutHandlerContributions) => {
  layoutDiagram(previousDiagram, diagram, referencePosition, nodeLayoutHandlerContributions);
  return diagram;
};
const layoutDiagram = (previousDiagram, diagram, referencePosition, nodeLayoutHandlerContributions) => {
  const allVisibleNodes = diagram.nodes.filter((node) => !node.hidden);
  const nodesToLayout = allVisibleNodes.filter((node) => !node.parentNode);
  const layoutEngine = new LayoutEngine();
  nodeLayoutHandlerContributions.forEach(
    (nodeLayoutHandler) => layoutEngine.registerNodeLayoutHandlerContribution(nodeLayoutHandler)
  );
  let newlyAddedNode = void 0;
  if (referencePosition) {
    newlyAddedNode = allVisibleNodes.filter((node) => !(previousDiagram == null ? void 0 : previousDiagram.nodes.map((n) => n.id).find((n) => n === node.id))).find((node) => {
      if (node.parentNode) {
        return referencePosition.parentId === node.parentNode;
      }
      return !referencePosition.parentId || referencePosition.parentId === "";
    });
    if (newlyAddedNode) {
      newlyAddedNode = { ...newlyAddedNode, position: referencePosition.position };
      if (newlyAddedNode.data.isBorderNode) {
        getNewlyAddedBorderNodePosition(
          newlyAddedNode,
          allVisibleNodes.find((node) => node.id === (newlyAddedNode == null ? void 0 : newlyAddedNode.parentNode)),
          referencePosition
        );
      }
    }
  }
  layoutEngine.layoutNodes(previousDiagram, allVisibleNodes, nodesToLayout, newlyAddedNode);
  nodesToLayout.forEach((node, index) => {
    const previousNode = ((previousDiagram == null ? void 0 : previousDiagram.nodes) ?? []).find((previousNode2) => previousNode2.id === node.id);
    const previousPosition = computePreviousPosition(previousNode, node);
    const createdNode = (newlyAddedNode == null ? void 0 : newlyAddedNode.id) === node.id ? newlyAddedNode : void 0;
    if (!!createdNode) {
      node.position = createdNode.position;
    } else if (previousPosition) {
      node.position = previousPosition;
    } else {
      const maxBorderNodeWidthWest = getChildren(node, allVisibleNodes).filter(isWestBorderNode).map((borderNode) => borderNode.width || 0).reduce((a, b) => Math.max(a, b), 0);
      node.position = { x: 0, y: 0 };
      const previousSibling = nodesToLayout[index - 1];
      if (previousSibling) {
        const previousSiblingMaxBorderNodeWidthEast = getChildren(previousSibling, allVisibleNodes).filter(isEastBorderNode).map((borderNode) => borderNode.width || 0).reduce((a, b) => Math.max(a, b), 0);
        node.position = {
          x: previousSibling.position.x + maxBorderNodeWidthWest + previousSiblingMaxBorderNodeWidthEast + (previousSibling.width ?? 0) + gap,
          y: 0
        };
      }
    }
  });
};
const initialState = {
  currentStep: "INITIAL_STEP",
  hiddenContainer: null,
  previousDiagram: null,
  diagramToLayout: null,
  laidoutDiagram: null,
  referencePosition: null,
  onLaidoutDiagram: () => {
  }
};
const useLayout = () => {
  const { httpOrigin } = useContext(ServerContext);
  const { nodeLayoutHandlers } = useContext(NodeTypeContext);
  const [state, setState] = useState(initialState);
  const reactFlowInstance = useReactFlow();
  const layoutAreaPrepared = () => {
    const currentStep = "LAYOUT";
    setState((prevState) => ({ ...prevState, currentStep }));
  };
  const layoutDiagram2 = (previousLaidoutDiagram, diagramToLayout, referencePosition, callback) => {
    if (state.currentStep === "INITIAL_STEP") {
      let processedReferencePosition = referencePosition;
      if (processedReferencePosition) {
        let parentNode = reactFlowInstance.getNode(processedReferencePosition.parentId ?? "");
        while (parentNode) {
          processedReferencePosition.position.x -= parentNode.position.x;
          processedReferencePosition.position.y -= parentNode.position.y;
          parentNode = reactFlowInstance.getNode(parentNode.parentNode ?? "");
        }
      }
      setState((prevState) => ({
        ...prevState,
        currentStep: "BEFORE_LAYOUT",
        previousDiagram: previousLaidoutDiagram,
        diagramToLayout,
        referencePosition: processedReferencePosition,
        onLaidoutDiagram: callback
      }));
    }
  };
  useEffect(() => {
    if (state.currentStep === "BEFORE_LAYOUT" && !state.hiddenContainer && state.diagramToLayout) {
      const layoutArea = prepareLayoutArea(state.diagramToLayout, layoutAreaPrepared, httpOrigin);
      setState((prevState) => ({
        ...prevState,
        hiddenContainer: layoutArea
      }));
    } else if (state.currentStep === "LAYOUT" && state.hiddenContainer && state.diagramToLayout) {
      const laidoutDiagram = layout(
        state.previousDiagram,
        state.diagramToLayout,
        state.referencePosition,
        nodeLayoutHandlers
      );
      setState((prevState) => ({
        ...prevState,
        diagramToLayout: null,
        laidoutDiagram,
        currentStep: "AFTER_LAYOUT"
      }));
    } else if (state.currentStep === "AFTER_LAYOUT" && state.hiddenContainer && state.laidoutDiagram) {
      cleanLayoutArea(state.hiddenContainer);
      state.onLaidoutDiagram(state.laidoutDiagram);
      setState(() => initialState);
    }
  }, [state.currentStep, state.hiddenContainer, state.referencePosition]);
  return {
    layout: layoutDiagram2
  };
};
const NodeTypeContribution = ({}) => {
  return null;
};
const diagramPanelActionExtensionPoint = {
  identifier: "diagramPanel#action",
  FallbackComponent: () => null
};
const edgeFragment = `
fragment edgeFragment on Edge {
  id
  type
  targetObjectId
  targetObjectKind
  targetObjectLabel
  descriptionId
  sourceId
  targetId
  state
  beginLabel {
    ...labelFragment
  }
  centerLabel {
    ...labelFragment
  }
  endLabel {
    ...labelFragment
  }
  style {
    size
    lineStyle
    sourceArrow
    targetArrow
    color
  }
  centerLabelEditable
}
`;
const labelFragment = `
fragment labelFragment on Label {
  id
  text
  style {
    color
    fontSize
    bold
    italic
    underline
    strikeThrough
    iconURL
    background
    borderColor
    borderStyle
    borderSize
    borderRadius
    maxWidth
  }
}
`;
const insideLabelFragment = `
fragment insideLabelFragment on InsideLabel {
  id
  text
  insideLabelLocation
  isHeader
  displayHeaderSeparator
  overflowStrategy
  textAlign
  style {
    color
    fontSize
    bold
    italic
    underline
    strikeThrough
    iconURL
    background
    borderColor
    borderStyle
    borderSize
    borderRadius
    maxWidth
  }
}
`;
const outsideLabelFragment = `
fragment outsideLabelFragment on OutsideLabel {
  id
  text
  outsideLabelLocation
  textAlign
  style {
    color
    fontSize
    bold
    italic
    underline
    strikeThrough
    iconURL
    background
    borderColor
    borderStyle
    borderSize
    borderRadius
    maxWidth
  }
  overflowStrategy
}
`;
const nodeFragment = `
fragment nodeFragment on Node {
  id
  type
  targetObjectId
  targetObjectKind
  targetObjectLabel
  descriptionId
  state
  pinned
  insideLabel {
    ...insideLabelFragment
  }
  outsideLabels {
    ...outsideLabelFragment
  }
  style {
    __typename
    ... on RectangularNodeStyle {
      background
      borderColor
      borderStyle
      borderSize
      borderRadius
    }
    ... on ImageNodeStyle {
      imageURL
      borderColor
      borderStyle
      borderSize
      borderRadius
      positionDependentRotation
    }
    ... on IconLabelNodeStyle {
      background
    }
  }
  childrenLayoutStrategy {
    __typename
    ... on ListLayoutStrategy {
      kind
      areChildNodesDraggable
      topGap
      bottomGap
      growableNodeIds
    }
    ... on FreeFormLayoutStrategy {
      kind
    }
  }
  defaultWidth
  defaultHeight
  labelEditable
}
`;
const diagramFragment = `
fragment diagramFragment on Diagram {
  id
  targetObjectId
  metadata {
    label
    kind
  }
  layoutData {
    nodeLayoutData {
      id
      position { x y }
      size { width height }
      resizedByUser
    }
  }
  nodes {
    ...nodeFragment
    borderNodes {
      ...nodeFragment
    }
    childNodes {
      ...nodeFragment
      borderNodes {
        ...nodeFragment
      }
      childNodes {
        ...nodeFragment
        borderNodes {
          ...nodeFragment
        }
        childNodes {
          ...nodeFragment
          borderNodes {
            ...nodeFragment
          }
          childNodes {
            ...nodeFragment
            borderNodes {
              ...nodeFragment
            }
            childNodes {
              ...nodeFragment
              borderNodes {
                ...nodeFragment
              }
            }
          }
        }
      }
    }
  }
  edges {
    ...edgeFragment
  }
}

${nodeFragment}
${edgeFragment}
${labelFragment}
${insideLabelFragment}
${outsideLabelFragment}
`;
const diagramEventSubscription = `
subscription diagramEvent($input: DiagramEventInput!) {
  diagramEvent(input: $input) {
    ... on ErrorPayload {
      id
      message
    }
    ... on DiagramRefreshedEventPayload {
      id
      diagram {
        ...diagramFragment
      }
      cause
      referencePosition {
        parentId
        position { x y }
      }
    }
  }
}

${diagramFragment}
`;
const isNewPositionInsideIsParent = (newNodePosition, movedNode, parentNode) => {
  if (movedNode.width && movedNode.height && (parentNode == null ? void 0 : parentNode.positionAbsolute) && parentNode.width && parentNode.height) {
    return parentNode.positionAbsolute.x + borderNodeOffset < newNodePosition.x + movedNode.width && parentNode.positionAbsolute.x + parentNode.width - borderNodeOffset > newNodePosition.x && parentNode.positionAbsolute.y + borderNodeOffset < newNodePosition.y + movedNode.height && parentNode.positionAbsolute.y + parentNode.height - borderNodeOffset > newNodePosition.y;
  }
  return false;
};
const findNearestBorderPosition = (newNodePosition, parentNode) => {
  if (parentNode.positionAbsolute) {
    const northDistance = Math.abs(newNodePosition.y - parentNode.positionAbsolute.y);
    const eastDistance = Math.abs((parentNode.width ?? 0) + parentNode.positionAbsolute.x - newNodePosition.x);
    const southDistance = Math.abs(newNodePosition.y - (parentNode.height ?? 0) - parentNode.positionAbsolute.y);
    const westDistance = Math.abs(parentNode.positionAbsolute.x - newNodePosition.x);
    const minDistance = Math.min(...[northDistance, eastDistance, southDistance, westDistance]);
    if (minDistance === northDistance) {
      return BorderNodePosition.NORTH;
    } else if (minDistance === eastDistance) {
      return BorderNodePosition.EAST;
    } else if (minDistance === southDistance) {
      return BorderNodePosition.SOUTH;
    } else {
      return BorderNodePosition.WEST;
    }
  }
  return null;
};
const useBorderChange = () => {
  const { getNodes } = useReactFlow();
  const { nodeLayoutHandlers } = useContext(NodeTypeContext);
  const transformBorderNodeChanges = useCallback((changes, oldNodes) => {
    return changes.map((change) => {
      if (change.type === "position" && change.position && change.positionAbsolute) {
        const movedNode = getNodes().find((node) => change.id === node.id);
        if (movedNode && movedNode.data.isBorderNode) {
          const parentNode = getNodes().find((node) => movedNode.parentNode === node.id);
          const parentLayoutHandler = nodeLayoutHandlers.find(
            (nodeLayoutHandler) => nodeLayoutHandler.canHandle(parentNode)
          );
          if (parentNode && parentNode.positionAbsolute && isNewPositionInsideIsParent(change.positionAbsolute, movedNode, parentNode) && !(parentLayoutHandler == null ? void 0 : parentLayoutHandler.calculateCustomNodeBorderNodePosition)) {
            const nearestBorder = findNearestBorderPosition(change.positionAbsolute, parentNode);
            if (nearestBorder === BorderNodePosition.NORTH) {
              change.position.y = borderNodeOffset - (movedNode.height ?? 0);
              change.positionAbsolute.y = parentNode.positionAbsolute.y + borderNodeOffset - (movedNode.height ?? 0);
            } else if (nearestBorder === BorderNodePosition.SOUTH) {
              change.position.y = (parentNode.height ?? 0) - borderNodeOffset;
              change.positionAbsolute.y = parentNode.positionAbsolute.y + (parentNode.height ?? 0) - borderNodeOffset;
            } else if (nearestBorder === BorderNodePosition.WEST) {
              change.position.x = borderNodeOffset - (movedNode.width ?? 0);
              change.positionAbsolute.x = parentNode.positionAbsolute.x + borderNodeOffset - (movedNode.width ?? 0);
            } else if (nearestBorder === BorderNodePosition.EAST) {
              change.position.x = (parentNode.width ?? 0) - borderNodeOffset;
              change.positionAbsolute.x = parentNode.positionAbsolute.x + (parentNode.width ?? 0) - borderNodeOffset;
            } else {
              change.position = movedNode.position;
              change.positionAbsolute = movedNode.positionAbsolute;
            }
          }
          const oldMovedNode = oldNodes.find((n) => n.id === movedNode.id);
          const newPosition = findBorderNodePosition(change.position, movedNode, parentNode);
          if (oldMovedNode && oldMovedNode.data.borderNodePosition !== newPosition) {
            oldMovedNode.data.borderNodePosition = newPosition;
          }
          if ((parentLayoutHandler == null ? void 0 : parentLayoutHandler.calculateCustomNodeBorderNodePosition) && parentNode) {
            change.position = parentLayoutHandler.calculateCustomNodeBorderNodePosition(
              parentNode,
              {
                x: change.position.x,
                y: change.position.y,
                width: movedNode.width ?? 0,
                height: movedNode.height ?? 0
              },
              true
            );
          }
        }
      }
      return change;
    });
  }, []);
  return { transformBorderNodeChanges };
};
const getConnectorToolsQuery = gql`
  query getConnectorTools(
    $editingContextId: ID!
    $representationId: ID!
    $sourceDiagramElementId: ID!
    $targetDiagramElementId: ID!
  ) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $representationId) {
          description {
            ... on DiagramDescription {
              connectorTools(
                sourceDiagramElementId: $sourceDiagramElementId
                targetDiagramElementId: $targetDiagramElementId
              ) {
                id
                label
                iconURL
              }
            }
          }
        }
      }
    }
  }
`;
const invokeSingleClickOnTwoDiagramElementsToolMutation = gql`
  mutation invokeSingleClickOnTwoDiagramElementsTool($input: InvokeSingleClickOnTwoDiagramElementsToolInput!) {
    invokeSingleClickOnTwoDiagramElementsTool(input: $input) {
      __typename
      ... on InvokeSingleClickOnTwoDiagramElementsToolSuccessPayload {
        newSelection {
          entries {
            id
            label
            kind
          }
        }
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isDiagramDescription = (representationDescription) => representationDescription.__typename === "DiagramDescription";
const isErrorPayload$3 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$3 = (payload) => payload.__typename === "InvokeSingleClickOnTwoDiagramElementsToolSuccessPayload";
const ConnectorContextualMenuComponent = memo(({}) => {
  var _a, _b;
  const { editingContextId, diagramId } = useContext(DiagramContext);
  const { connection, position, onConnectorContextualMenuClose, addTempConnectionLine, removeTempConnectionLine } = useConnector();
  const { addMessages, addErrorMessage } = useMultiToast();
  const connectionSource = connection ? document.querySelector(`[data-id="${connection.source}"]`) : null;
  const connectionTarget = connection ? document.querySelector(`[data-id="${connection.target}"]`) : null;
  const sourceDiagramElementId = (connectionSource == null ? void 0 : connectionSource.dataset.id) ?? "";
  const targetDiagramElementId = (connectionTarget == null ? void 0 : connectionTarget.dataset.id) ?? "";
  const variables = {
    editingContextId,
    representationId: diagramId,
    sourceDiagramElementId,
    targetDiagramElementId
  };
  const { loading, data, error } = useQuery(getConnectorToolsQuery, {
    variables,
    skip: !connectionSource || !connectionTarget
  });
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  const [
    invokeSingleClickOnTwoDiagramElementsTool,
    {
      data: invokeSingleClickOnTwoDiagramElementToolData,
      error: invokeSingleClickOnTwoDiagramElementToolError,
      called: invokeSingleClickOnTwoDiagramElementToolCalled,
      reset
    }
  ] = useMutation(invokeSingleClickOnTwoDiagramElementsToolMutation);
  const invokeTool = (tool) => {
    const input = {
      id: crypto.randomUUID(),
      editingContextId,
      representationId: diagramId,
      diagramSourceElementId: sourceDiagramElementId,
      diagramTargetElementId: targetDiagramElementId,
      toolId: tool.id,
      sourcePositionX: 0,
      sourcePositionY: 0,
      targetPositionX: 0,
      targetPositionY: 0
    };
    invokeSingleClickOnTwoDiagramElementsTool({ variables: { input } });
  };
  const onShouldConnectorContextualMenuClose = () => {
    onConnectorContextualMenuClose();
    reset();
  };
  useEffect(() => {
    if (invokeSingleClickOnTwoDiagramElementToolData) {
      const payload = invokeSingleClickOnTwoDiagramElementToolData.invokeSingleClickOnTwoDiagramElementsTool;
      if (isErrorPayload$3(payload)) {
        addMessages(payload.messages);
      }
      if (isSuccessPayload$3(payload)) {
        addMessages(payload.messages);
        onShouldConnectorContextualMenuClose();
      }
    }
    if (invokeSingleClickOnTwoDiagramElementToolError == null ? void 0 : invokeSingleClickOnTwoDiagramElementToolError.message) {
      addErrorMessage(invokeSingleClickOnTwoDiagramElementToolError.message);
    }
  }, [invokeSingleClickOnTwoDiagramElementToolData, invokeSingleClickOnTwoDiagramElementToolError]);
  const connectorTools = [];
  const representationDescription = (_b = (_a = data == null ? void 0 : data.viewer.editingContext) == null ? void 0 : _a.representation) == null ? void 0 : _b.description;
  if (representationDescription && isDiagramDescription(representationDescription)) {
    representationDescription.connectorTools.forEach((tool) => connectorTools.push(tool));
  }
  useEffect(() => {
    if (connectorTools.length > 1) {
      addTempConnectionLine();
    }
  }, [connection, connectorTools.length]);
  useEffect(() => {
    if (!loading && connection && data && connectorTools.length === 0) {
      addMessages([{ body: "No edge found between source and target selected", level: "WARNING" }]);
    }
  }, [loading, data, connection, connectorTools.length]);
  useEffect(() => {
    return () => removeTempConnectionLine();
  }, []);
  useEffect(() => {
    if (!invokeSingleClickOnTwoDiagramElementToolCalled && connectorTools.length === 1 && connectorTools[0]) {
      invokeTool(connectorTools[0]);
    }
  }, [connectorTools]);
  if (!data || connectorTools.length <= 1) {
    return null;
  }
  return /* @__PURE__ */ jsx(
    Menu,
    {
      open: !!connection,
      onClose: onShouldConnectorContextualMenuClose,
      anchorEl: connectionTarget,
      anchorReference: "anchorPosition",
      anchorPosition: { left: (position == null ? void 0 : position.x) || 0, top: (position == null ? void 0 : position.y) || 0 },
      children: connectorTools.map((tool) => /* @__PURE__ */ jsxs(MenuItem, { onClick: () => invokeTool(tool), children: [
        /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(IconOverlay, { iconURL: tool.iconURL, alt: tool.label, title: tool.label }) }),
        /* @__PURE__ */ jsx(Typography, { children: tool.label })
      ] }, tool.id))
    }
  );
});
const ConnectorContextualMenu = memo(({}) => {
  const { isConnectionInProgress } = useConnector();
  return isConnectionInProgress() ? /* @__PURE__ */ jsx(ConnectorContextualMenuComponent, {}) : null;
});
const useStyles$1 = makeStyles()((theme2) => ({
  list: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    wordWrap: "break-word"
  },
  box: {
    minWidth: theme2.spacing(32),
    maxWidth: theme2.spacing(32),
    border: "1px solid black",
    borderRadius: "5px"
  }
}));
const EdgePanelInfos = ({ title, edge }) => {
  var _a, _b;
  const { classes } = useStyles$1();
  return /* @__PURE__ */ jsx(Box, { className: classes.box, children: /* @__PURE__ */ jsxs(List, { dense: true, classes: { root: classes.list }, children: [
    /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", children: title }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Type : Edge` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Label : ${(_b = (_a = edge.data) == null ? void 0 : _a.label) == null ? void 0 : _b.text}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Edge id : ${edge == null ? void 0 : edge.id}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Source : ${edge == null ? void 0 : edge.source}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Target : ${edge == null ? void 0 : edge.target}` }) })
  ] }) });
};
const useStyles = makeStyles()((theme2) => ({
  list: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    wordWrap: "break-word"
  },
  box: {
    minWidth: theme2.spacing(32),
    maxWidth: theme2.spacing(32),
    border: "1px solid black",
    borderRadius: theme2.shape.borderRadius,
    backgroundColor: alpha(theme2.palette.background.paper, 0.8)
  }
}));
const NodePanelInfos = ({ title, node }) => {
  var _a;
  const { classes } = useStyles();
  return /* @__PURE__ */ jsx(Box, { className: classes.box, children: /* @__PURE__ */ jsxs(List, { dense: true, classes: { root: classes.list }, children: [
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", children: title }) }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Type : Node` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Label : ${(_a = node == null ? void 0 : node.data.insideLabel) == null ? void 0 : _a.text}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Node id : ${node == null ? void 0 : node.id}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Height : ${node == null ? void 0 : node.height}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Width : ${node == null ? void 0 : node.width}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `Extent : ${node == null ? void 0 : node.extent}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `x : ${node == null ? void 0 : node.position.x}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(ListItemText, { classes: { root: classes.listItemText }, primary: `y : ${node == null ? void 0 : node.position.y}` }) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(
      ListItemText,
      {
        classes: { root: classes.listItemText },
        primary: `TargetObjectId : ${node == null ? void 0 : node.data.targetObjectId}`
      }
    ) }),
    /* @__PURE__ */ jsx(ListItem, { classes: { root: classes.listItem }, children: /* @__PURE__ */ jsx(
      ListItemText,
      {
        classes: { root: classes.listItemText },
        primary: `TargetObjectKind : ${node == null ? void 0 : node.data.targetObjectKind}`
      }
    ) })
  ] }) });
};
const debugPanelStyle = (reactFlowBounds) => {
  const debugPanelStyles = {
    overflowX: "auto",
    maxWidth: reactFlowBounds
  };
  return debugPanelStyles;
};
const DebugPanel = ({ reactFlowWrapper }) => {
  var _a;
  const reactFlowInstance = useReactFlow();
  const selectedNodes = reactFlowInstance.getNodes().filter((node) => node.selected);
  const selectedEdges = reactFlowInstance.getEdges().filter((edge) => edge.selected);
  const { hoveredNode } = useContext(NodeContext);
  const reactFlowBounds = ((_a = reactFlowWrapper == null ? void 0 : reactFlowWrapper.current) == null ? void 0 : _a.getBoundingClientRect().width) ?? 0;
  return /* @__PURE__ */ jsx(Panel, { position: "bottom-left", style: debugPanelStyle(reactFlowBounds), children: /* @__PURE__ */ jsxs(Box, { display: "flex", style: { columnGap: "5px" }, children: [
    hoveredNode ? /* @__PURE__ */ jsx(NodePanelInfos, { title: "Hovered Node", node: hoveredNode }) : "",
    selectedNodes.map((selectedNode, index) => {
      return /* @__PURE__ */ jsx(NodePanelInfos, { title: "Selected Node", node: selectedNode }, index);
    }),
    selectedEdges.map((selectedEdge, index) => {
      return /* @__PURE__ */ jsx(EdgePanelInfos, { title: "Selected Edge", edge: selectedEdge }, index);
    })
  ] }) });
};
const deleteFromDiagramMutation = gql`
  mutation deleteFromDiagram($input: DeleteFromDiagramInput!) {
    deleteFromDiagram(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on DeleteFromDiagramSuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$2 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$2 = (payload) => payload.__typename === "DeleteFromDiagramSuccessPayload";
const useDiagramDelete = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const { showDeletionConfirmation } = useDeletionConfirmationDialog();
  const { diagramId, editingContextId, readOnly } = useContext(DiagramContext);
  const { getNodes } = useReactFlow();
  const [deleteElementsMutation, { data: deleteElementsData, error: deleteElementsError }] = useMutation(deleteFromDiagramMutation);
  useEffect(() => {
    if (deleteElementsError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (deleteElementsData) {
      const { deleteFromDiagram } = deleteElementsData;
      if (isErrorPayload$2(deleteFromDiagram) || isSuccessPayload$2(deleteFromDiagram)) {
        addMessages(deleteFromDiagram.messages);
      }
    }
  }, [coreT, deleteElementsData, deleteElementsError]);
  const onDelete = useCallback((event) => {
    const { key } = event;
    const isTextField = event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement;
    if ((event.altKey || event.shiftKey) && event.getModifierState(key) || isTextField) {
      return;
    }
    event.preventDefault();
    if (key === "Delete" && editingContextId && diagramId && !readOnly) {
      const nodeToDeleteIds = getNodes().filter((node) => node.selected).map((node) => node.id);
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        nodeIds: nodeToDeleteIds,
        edgeIds: [],
        deletionPolicy: GQLDeletionPolicy.SEMANTIC
      };
      showDeletionConfirmation(() => {
        deleteElementsMutation({ variables: { input } });
      });
    }
  }, []);
  return {
    onDelete
  };
};
const useNodesDraggable = () => {
  const [state, setState] = useState({
    nodesDraggable: true
  });
  useEffect(() => {
    const keydownListener = (event) => {
      if (event.key === "Alt") {
        setState((prevState) => ({ ...prevState, nodesDraggable: false }));
      }
    };
    document.addEventListener("keydown", keydownListener);
    const keyupListener = (event) => {
      if (event.key === "Alt") {
        setState((prevState) => ({ ...prevState, nodesDraggable: true }));
      }
    };
    document.addEventListener("keyup", keyupListener);
    return () => {
      document.removeEventListener("keydown", keydownListener);
      document.removeEventListener("keyup", keyupListener);
    };
  }, []);
  return { nodesDraggable: state.nodesDraggable };
};
const useDropDiagramStyle = () => {
  const { droppableOnDiagram } = useContext(DropNodeContext);
  const { getNodes } = useStore();
  const theme2 = useTheme();
  const targetNode = getNodes().find((node) => node.data.isDropNodeTarget);
  const draggedNode = getNodes().find((node) => node.dragging);
  const diagramTargeted = !!draggedNode && !targetNode && !!(draggedNode == null ? void 0 : draggedNode.parentNode) && !draggedNode.data.isBorderNode;
  const diagramForbidden = diagramTargeted && !!(draggedNode == null ? void 0 : draggedNode.id) !== null && !droppableOnDiagram;
  const backgroundColor = diagramForbidden ? theme2.palette.action.disabledBackground : theme2.palette.background.default;
  const memoizedStyle = useMemo(() => {
    return {
      backgroundColor,
      smallGridColor: diagramForbidden ? backgroundColor : "#f1f1f1",
      largeGridColor: diagramForbidden ? backgroundColor : "#cccccc"
    };
  }, [diagramForbidden]);
  return memoizedStyle;
};
const connectionLineStyle = (theme2) => {
  return {
    stroke: theme2.palette.selected,
    strokeWidth: theme2.spacing(0.2)
  };
};
const ConnectionLine = memo(
  ({ fromX, fromY, toX, toY, fromPosition, toPosition }) => {
    const theme2 = useTheme();
    const [edgePath] = getSmoothStepPath({
      sourceX: fromX,
      sourceY: fromY,
      sourcePosition: fromPosition,
      targetX: toX,
      targetY: toY,
      targetPosition: toPosition
    });
    return /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx("path", { fill: "none", className: "animated", d: edgePath, style: connectionLineStyle(theme2) }) });
  }
);
const multiLabelEdgeStyle = (theme2, style, selected, faded) => {
  const multiLabelEdgeStyle2 = {
    opacity: faded ? "0.4" : "",
    ...style,
    stroke: (style == null ? void 0 : style.stroke) ? getCSSColor(String(style.stroke), theme2) : void 0
  };
  if (selected) {
    multiLabelEdgeStyle2.stroke = `${theme2.palette.selected}`;
  }
  return multiLabelEdgeStyle2;
};
const getTranslateFromHandlePositon = (position) => {
  switch (position) {
    case Position.Right:
      return "translate(2%, -100%)";
    case Position.Left:
      return "translate(-102%, -100%)";
    case Position.Top:
      return "translate(2%, -100%)";
    case Position.Bottom:
      return "translate(2%, 0%)";
  }
};
const labelContainerStyle = (transform) => {
  return {
    transform,
    position: "absolute",
    padding: 5,
    zIndex: 1001
  };
};
const MultiLabelEdge = memo(
  ({
    id,
    source,
    target,
    data,
    style,
    markerEnd,
    markerStart,
    selected,
    sourcePosition,
    targetPosition,
    sourceHandleId,
    targetHandleId
  }) => {
    const { beginLabel, endLabel, label, faded } = data || {};
    const theme2 = useTheme();
    const { nodeLayoutHandlers } = useContext(NodeTypeContext);
    const { nodeInternals } = useStoreApi().getState();
    const sourceNode = nodeInternals.get(source);
    const targetNode = nodeInternals.get(target);
    const edgeStyle = useMemo(() => multiLabelEdgeStyle(theme2, style, selected, faded), [style, selected, faded]);
    const sourceLabelTranslation = useMemo(() => getTranslateFromHandlePositon(sourcePosition), [sourcePosition]);
    const targetLabelTranslation = useMemo(() => getTranslateFromHandlePositon(targetPosition), [targetPosition]);
    if (!sourceNode || !targetNode) {
      return null;
    }
    const sourceLayoutHandler = nodeLayoutHandlers.find(
      (nodeLayoutHandler) => nodeLayoutHandler.canHandle(sourceNode)
    );
    const targetLayoutHandler = nodeLayoutHandlers.find(
      (nodeLayoutHandler) => nodeLayoutHandler.canHandle(targetNode)
    );
    let { x: sourceX, y: sourceY } = getHandleCoordinatesByPosition(
      sourceNode,
      sourcePosition,
      sourceHandleId ?? "",
      sourceLayoutHandler == null ? void 0 : sourceLayoutHandler.calculateCustomNodeEdgeHandlePosition
    );
    let { x: targetX, y: targetY } = getHandleCoordinatesByPosition(
      targetNode,
      targetPosition,
      targetHandleId ?? "",
      targetLayoutHandler == null ? void 0 : targetLayoutHandler.calculateCustomNodeEdgeHandlePosition
    );
    const handleSourceRadius = markerStart == void 0 || markerStart.includes("None") ? 2 : 3;
    switch (sourcePosition) {
      case Position.Right:
        sourceX = sourceX + handleSourceRadius;
        break;
      case Position.Left:
        sourceX = sourceX - handleSourceRadius;
        break;
      case Position.Top:
        sourceY = sourceY - handleSourceRadius;
        break;
      case Position.Bottom:
        sourceY = sourceY + handleSourceRadius;
        break;
    }
    const handleTargetRadius = markerEnd == void 0 || markerEnd.includes("None") ? 2 : 3;
    switch (targetPosition) {
      case Position.Right:
        targetX = targetX + handleTargetRadius;
        break;
      case Position.Left:
        targetX = targetX - handleTargetRadius;
        break;
      case Position.Top:
        targetY = targetY - handleTargetRadius;
        break;
      case Position.Bottom:
        targetY = targetY + handleTargetRadius;
        break;
    }
    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition
    });
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        BaseEdge,
        {
          id,
          path: edgePath,
          style: edgeStyle,
          markerEnd: selected ? `${markerEnd == null ? void 0 : markerEnd.slice(0, markerEnd.length - 1)}--selected)` : markerEnd,
          markerStart: selected ? `${markerStart == null ? void 0 : markerStart.slice(0, markerStart.length - 1)}--selected)` : markerStart
        }
      ),
      selected ? /* @__PURE__ */ jsx(
        DiagramElementPalette,
        {
          diagramElementId: id,
          targetObjectId: (data == null ? void 0 : data.targetObjectId) ?? "",
          labelId: label ? label.id : null
        }
      ) : null,
      /* @__PURE__ */ jsxs(EdgeLabelRenderer, { children: [
        beginLabel && /* @__PURE__ */ jsx("div", { style: labelContainerStyle(`${sourceLabelTranslation} translate(${sourceX}px,${sourceY}px)`), children: /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: beginLabel, faded: !!faded }) }),
        label && /* @__PURE__ */ jsx("div", { style: labelContainerStyle(`translate(${labelX}px,${labelY}px)`), children: /* @__PURE__ */ jsx(Label, { diagramElementId: id, label, faded: !!faded }) }),
        endLabel && /* @__PURE__ */ jsx("div", { style: labelContainerStyle(`${targetLabelTranslation} translate(${targetX}px,${targetY}px)`), children: /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: endLabel, faded: !!faded }) })
      ] })
    ] });
  }
);
const edgeTypes = {
  multiLabelEdge: MultiLabelEdge
};
const useInitialFitToScreen = () => {
  const reactFlowInstance = useReactFlow();
  const [state, setState] = useState({
    initialFitToScreenPerformed: false,
    shouldPerformFitToScreen: false
  });
  const fitToScreen = () => {
    if (!state.initialFitToScreenPerformed) {
      setState((prevState) => ({ ...prevState, shouldPerformFitToScreen: true }));
    }
  };
  useEffect(() => {
    if (state.shouldPerformFitToScreen) {
      reactFlowInstance.fitView({ duration: 200 });
      setState((prevState) => ({ ...prevState, initialFitToScreenPerformed: true, shouldPerformFitToScreen: false }));
    }
  }, [state.shouldPerformFitToScreen]);
  return { fitToScreen };
};
const isNodePositionChange = (change) => change.type === "position" && typeof change.dragging === "boolean" && change.dragging;
const useHandleChange = () => {
  const { getEdges } = useStore();
  const { diagramDescription } = useDiagramDescription();
  const storeApi = useStoreApi();
  const applyHandleChange = useCallback(
    (changes, nodes) => {
      const nodeId2ConnectionHandles = /* @__PURE__ */ new Map();
      changes.filter(isNodePositionChange).forEach((nodeDraggingChange) => {
        const movingNode = nodes.find((node) => nodeDraggingChange.id === node.id);
        if (movingNode) {
          const connectedEdges = getConnectedEdges([movingNode], getEdges());
          connectedEdges.forEach((edge) => {
            const { sourceHandle, targetHandle } = edge;
            const sourceNode = nodes.find((node) => {
              var _a;
              return node.id === ((_a = edge.sourceNode) == null ? void 0 : _a.id);
            });
            const targetNode = nodes.find((node) => {
              var _a;
              return node.id === ((_a = edge.targetNode) == null ? void 0 : _a.id);
            });
            if (sourceNode && targetNode && sourceHandle && targetHandle) {
              const { sourcePosition, targetPosition } = getEdgeParametersWhileMoving(
                nodeDraggingChange,
                sourceNode,
                targetNode,
                storeApi.getState().nodeInternals,
                diagramDescription.arrangeLayoutDirection
              );
              const nodeSourceConnectionHandle = sourceNode.data.connectionHandles.find(
                (connectionHandle) => connectionHandle.id === sourceHandle
              );
              const nodeTargetConnectionHandle = targetNode.data.connectionHandles.find(
                (connectionHandle) => connectionHandle.id === targetHandle
              );
              if ((nodeSourceConnectionHandle == null ? void 0 : nodeSourceConnectionHandle.position) !== sourcePosition || (nodeTargetConnectionHandle == null ? void 0 : nodeTargetConnectionHandle.position) !== targetPosition) {
                const { sourceConnectionHandles, targetConnectionHandles } = getUpdatedConnectionHandles(
                  sourceNode,
                  targetNode,
                  sourcePosition,
                  targetPosition,
                  sourceHandle,
                  targetHandle
                );
                nodeId2ConnectionHandles.set(sourceNode.id, sourceConnectionHandles);
                nodeId2ConnectionHandles.set(targetNode.id, targetConnectionHandles);
              }
            }
          });
        }
      });
      return nodes.map((node) => {
        const connectionHandles = nodeId2ConnectionHandles.get(node.id);
        if (connectionHandles) {
          node.data = { ...node.data, connectionHandles };
        }
        return node;
      });
    },
    [getEdges]
  );
  return { applyHandleChange };
};
const canvasStyle = {
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: 10,
  pointerEvents: "none"
};
const storeSelector = (state) => ({
  width: state.width,
  height: state.height
});
const HelperLines = ({ horizontal, vertical }) => {
  const { width, height } = useStore$1(storeSelector);
  const { x, y, zoom } = useViewport();
  const canvasRef = useRef(null);
  const theme2 = useTheme();
  useEffect(() => {
    const canvas = canvasRef.current;
    const context2D = canvas == null ? void 0 : canvas.getContext("2d");
    if (canvas && context2D) {
      const dpi = window.devicePixelRatio;
      canvas.width = width * dpi;
      canvas.height = height * dpi;
      context2D.scale(dpi, dpi);
      context2D.clearRect(0, 0, width, height);
      context2D.strokeStyle = theme2.palette.primary.main;
      context2D.setLineDash([4, 2]);
      if (vertical) {
        context2D.moveTo(vertical * zoom + x, 0);
        context2D.lineTo(vertical * zoom + x, height);
        context2D.stroke();
      }
      if (horizontal) {
        context2D.moveTo(0, horizontal * zoom + y);
        context2D.lineTo(width, horizontal * zoom + y);
        context2D.stroke();
      }
    }
  }, [width, height, x, y, zoom, horizontal, vertical]);
  return /* @__PURE__ */ jsx("canvas", { ref: canvasRef, className: "react-flow__canvas", style: canvasStyle });
};
const isMove$2 = (change, dragging) => change.type === "position" && (!dragging || typeof change.dragging === "boolean" && change.dragging);
const isResize$2 = (change) => change.type === "dimensions" && (change.resizing ?? false);
const getRectangularBounds = (nodePositionChanges, nodes) => {
  return nodePositionChanges.reduce(
    (rect, change) => {
      if (change.positionAbsolute) {
        const x = change.positionAbsolute.x;
        const y = change.positionAbsolute.y;
        const node = nodes.find((n) => n.id === change.id);
        const right = x + ((node == null ? void 0 : node.width) ?? 0);
        const bottom = y + ((node == null ? void 0 : node.height) ?? 0);
        rect.x1 = Math.min(rect.x1, x);
        rect.x2 = Math.max(rect.x2, right);
        rect.y1 = Math.min(rect.y1, y);
        rect.y2 = Math.max(rect.y2, bottom);
      }
      return rect;
    },
    {
      x1: Infinity,
      x2: -Infinity,
      y1: Infinity,
      y2: -Infinity
    }
  );
};
const getHelperLinesForMove = (movingNodesBounds, movingNodes, nodes, nodeInternal) => {
  const noHelperLines = { horizontal: null, vertical: null, snapX: 0, snapY: 0 };
  let verticalSnapGap = verticalHelperLinesSnapGap;
  let horizontalSnapGap = horizontalHelperLinesSnapGap;
  const movingNodesWidth = movingNodesBounds.x2 - movingNodesBounds.x1;
  const movingNodesHeight = movingNodesBounds.y2 - movingNodesBounds.y1;
  return nodes.filter((node) => !movingNodes.some((n) => n.id === node.id)).filter((node) => !movingNodes.some((n) => isDescendantOf(n, node, nodeInternal))).reduce((helperLines, otherNode) => {
    if (otherNode.positionAbsolute) {
      const otherNodeBounds = {
        x1: otherNode.positionAbsolute.x,
        x2: otherNode.positionAbsolute.x + (otherNode.width ?? 0),
        y1: otherNode.positionAbsolute.y,
        y2: otherNode.positionAbsolute.y + (otherNode.height ?? 0)
      };
      const x1x1Gap = Math.abs(movingNodesBounds.x1 - otherNodeBounds.x1);
      if (x1x1Gap < verticalSnapGap) {
        helperLines.vertical = otherNodeBounds.x1;
        helperLines.snapX = otherNodeBounds.x1;
        verticalSnapGap = x1x1Gap;
      }
      const x2x1Gap = Math.abs(movingNodesBounds.x2 - otherNodeBounds.x1);
      if (x2x1Gap < verticalSnapGap) {
        helperLines.vertical = otherNodeBounds.x1;
        helperLines.snapX = otherNodeBounds.x1 - movingNodesWidth;
        verticalSnapGap = x2x1Gap;
      }
      const x1x2Gap = Math.abs(movingNodesBounds.x1 - otherNodeBounds.x2);
      if (x1x2Gap < verticalSnapGap) {
        helperLines.vertical = otherNodeBounds.x2;
        helperLines.snapX = otherNodeBounds.x2;
        verticalSnapGap = x1x2Gap;
      }
      const x2x2Gap = Math.abs(movingNodesBounds.x2 - otherNodeBounds.x2);
      if (x2x2Gap < verticalSnapGap) {
        helperLines.vertical = otherNodeBounds.x2;
        helperLines.snapX = otherNodeBounds.x2 - movingNodesWidth;
        verticalSnapGap = x2x2Gap;
      }
      const y1y1Gap = Math.abs(movingNodesBounds.y1 - otherNodeBounds.y1);
      if (y1y1Gap < horizontalSnapGap) {
        helperLines.horizontal = otherNodeBounds.y1;
        helperLines.snapY = otherNodeBounds.y1;
        horizontalSnapGap = y1y1Gap;
      }
      const y2y1Gap = Math.abs(movingNodesBounds.y2 - otherNodeBounds.y1);
      if (y2y1Gap < horizontalSnapGap) {
        helperLines.horizontal = otherNodeBounds.y1;
        helperLines.snapY = otherNodeBounds.y1 - movingNodesHeight;
        horizontalSnapGap = y2y1Gap;
      }
      const y1y2Gap = Math.abs(movingNodesBounds.y1 - otherNodeBounds.y2);
      if (y1y2Gap < horizontalSnapGap) {
        helperLines.horizontal = otherNodeBounds.y2;
        helperLines.snapY = otherNodeBounds.y2;
        horizontalSnapGap = y1y2Gap;
      }
      const y2y2Gap = Math.abs(movingNodesBounds.y2 - otherNodeBounds.y2);
      if (y2y2Gap < horizontalSnapGap) {
        helperLines.horizontal = otherNodeBounds.y2;
        helperLines.snapY = otherNodeBounds.y2 - movingNodesHeight;
        horizontalSnapGap = y2y2Gap;
      }
      const verticalCenterGap = Math.abs(
        movingNodesBounds.x1 + movingNodesWidth / 2 - (otherNodeBounds.x1 + (otherNode.width ?? 0) / 2)
      );
      if (verticalCenterGap < verticalSnapGap) {
        helperLines.vertical = otherNode.positionAbsolute.x + (otherNode.width ?? 0) / 2;
        helperLines.snapX = otherNodeBounds.x1 + (otherNode.width ?? 0) / 2 - movingNodesWidth / 2;
      }
      const horizontalCenterGap = Math.abs(
        movingNodesBounds.y1 + movingNodesHeight / 2 - (otherNodeBounds.y1 + (otherNode.height ?? 0) / 2)
      );
      if (horizontalCenterGap < horizontalSnapGap) {
        helperLines.horizontal = otherNode.positionAbsolute.y + (otherNode.height ?? 0) / 2;
        helperLines.snapY = otherNodeBounds.y1 + (otherNode.height ?? 0) / 2 - movingNodesHeight / 2;
      }
    }
    return helperLines;
  }, noHelperLines);
};
const getHelperLinesForResize = (change, resizingNode, nodes, nodeInternal) => {
  const noHelperLines = { horizontal: null, vertical: null, snapX: 0, snapY: 0 };
  if (resizingNode.positionAbsolute && change.dimensions) {
    let verticalSnapGap = verticalHelperLinesSnapGap;
    let horizontalSnapGap = horizontalHelperLinesSnapGap;
    const resizingNodeBounds = {
      x1: resizingNode.positionAbsolute.x,
      x2: resizingNode.positionAbsolute.x + (change.dimensions.width ?? 0),
      y1: resizingNode.positionAbsolute.y,
      y2: resizingNode.positionAbsolute.y + (change.dimensions.height ?? 0)
    };
    return nodes.filter((node) => node.id != resizingNode.id).filter((node) => !isDescendantOf(resizingNode, node, nodeInternal)).reduce((helperLines, otherNode) => {
      if (otherNode.positionAbsolute) {
        const otherNodeBounds = {
          x1: otherNode.positionAbsolute.x,
          x2: otherNode.positionAbsolute.x + (otherNode.width ?? 0),
          y1: otherNode.positionAbsolute.y,
          y2: otherNode.positionAbsolute.y + (otherNode.height ?? 0)
        };
        const x2x1Gap = Math.abs(resizingNodeBounds.x2 - otherNodeBounds.x1);
        if (x2x1Gap < verticalSnapGap) {
          helperLines.vertical = otherNodeBounds.x1;
          helperLines.snapX = otherNodeBounds.x1;
          verticalSnapGap = x2x1Gap;
        }
        const x2x2Gap = Math.abs(resizingNodeBounds.x2 - otherNodeBounds.x2);
        if (x2x2Gap < verticalSnapGap) {
          helperLines.vertical = otherNodeBounds.x2;
          helperLines.snapX = otherNodeBounds.x2;
          verticalSnapGap = x2x2Gap;
        }
        const y2y1Gap = Math.abs(resizingNodeBounds.y2 - otherNodeBounds.y1);
        if (y2y1Gap < horizontalSnapGap) {
          helperLines.horizontal = otherNodeBounds.y1;
          helperLines.snapY = otherNodeBounds.y1;
          horizontalSnapGap = y2y1Gap;
        }
        const y2y2Gap = Math.abs(resizingNodeBounds.y2 - otherNodeBounds.y2);
        if (y2y2Gap < horizontalSnapGap) {
          helperLines.horizontal = otherNodeBounds.y2;
          helperLines.snapY = otherNodeBounds.y2;
          horizontalSnapGap = y2y2Gap;
        }
      }
      return helperLines;
    }, noHelperLines);
  }
  return noHelperLines;
};
const getHelperLinesForResizeAndMove = (resizingChange, movingChange, resizingNode, nodes, nodeInternal) => {
  const noHelperLines = { horizontal: null, vertical: null, snapX: 0, snapY: 0 };
  if (resizingNode.positionAbsolute && resizingChange.dimensions && movingChange.position) {
    let verticalSnapGap = verticalHelperLinesSnapGap;
    let horizontalSnapGap = horizontalHelperLinesSnapGap;
    const nodeBounds = {
      x1: movingChange.position.x + resizingNode.positionAbsolute.x - resizingNode.position.x,
      x2: movingChange.position.x + resizingNode.positionAbsolute.x - resizingNode.position.x + (resizingChange.dimensions.width ?? 0),
      y1: movingChange.position.y + resizingNode.positionAbsolute.y - resizingNode.position.y,
      y2: movingChange.position.y + resizingNode.positionAbsolute.y - resizingNode.position.y + (resizingChange.dimensions.height ?? 0)
    };
    return nodes.filter((node) => node.id != resizingNode.id).filter((node) => !isDescendantOf(resizingNode, node, nodeInternal)).reduce((helperLines, otherNode) => {
      if (otherNode.positionAbsolute) {
        const otherNodeBounds = {
          x1: otherNode.positionAbsolute.x,
          x2: otherNode.positionAbsolute.x + (otherNode.width ?? 0),
          y1: otherNode.positionAbsolute.y,
          y2: otherNode.positionAbsolute.y + (otherNode.height ?? 0)
        };
        const x1x1Gap = Math.abs(nodeBounds.x1 - otherNodeBounds.x1);
        if (x1x1Gap < verticalSnapGap) {
          helperLines.vertical = otherNodeBounds.x1;
          helperLines.snapX = otherNodeBounds.x1;
          verticalSnapGap = x1x1Gap;
        }
        const x1x2Gap = Math.abs(nodeBounds.x1 - otherNodeBounds.x2);
        if (x1x2Gap < verticalSnapGap) {
          helperLines.vertical = otherNodeBounds.x2;
          helperLines.snapX = otherNodeBounds.x2;
          verticalSnapGap = x1x2Gap;
        }
        const y1y1Gap = Math.abs(nodeBounds.y1 - otherNodeBounds.y1);
        if (y1y1Gap < horizontalSnapGap) {
          helperLines.horizontal = otherNodeBounds.y1;
          helperLines.snapY = otherNodeBounds.y1;
          horizontalSnapGap = y1y1Gap;
        }
        const y1y2Gap = Math.abs(nodeBounds.y1 - otherNodeBounds.y2);
        if (y1y2Gap < horizontalSnapGap) {
          helperLines.horizontal = otherNodeBounds.y2;
          helperLines.snapY = otherNodeBounds.y2;
          horizontalSnapGap = y1y2Gap;
        }
      }
      return helperLines;
    }, noHelperLines);
  }
  return noHelperLines;
};
const useHelperLines = () => {
  const [enabled, setEnabled] = useState(true);
  const [state, setState] = useState({ vertical: null, horizontal: null });
  const storeApi = useStoreApi();
  const { getNodes } = useReactFlow();
  const applyHelperLines = useCallback(
    (changes) => {
      const nodeInternal = storeApi.getState().nodeInternals;
      if (enabled && changes.every((change) => isMove$2(change, true))) {
        const nodePositionChanges = changes.map((change) => change);
        const movingNodes = nodePositionChanges.map((change) => nodeInternal.get(change.id)).filter((node) => node !== void 0);
        if (movingNodes) {
          const movingNodesBounds = getRectangularBounds(nodePositionChanges, movingNodes);
          const helperLines = getHelperLinesForMove(
            movingNodesBounds,
            movingNodes,
            getNodes(),
            nodeInternal
          );
          setState({ vertical: helperLines.vertical, horizontal: helperLines.horizontal });
          const movingNodeBoundsSnapX = movingNodesBounds.x1 - (helperLines.snapX ?? 0);
          const movingNodeBoundsSnapY = movingNodesBounds.y1 - (helperLines.snapY ?? 0);
          nodePositionChanges.forEach((change) => {
            if (helperLines.snapX && change.position) {
              change.position.x -= movingNodeBoundsSnapX;
            }
            if (helperLines.snapY && change.position) {
              change.position.y -= movingNodeBoundsSnapY;
            }
          });
        }
      } else if (enabled && changes.length === 1 && changes[0]) {
        const change = changes[0];
        if (isResize$2(change)) {
          const resizingNode = nodeInternal.get(change.id);
          if (resizingNode) {
            const helperLines = getHelperLinesForResize(change, resizingNode, getNodes(), nodeInternal);
            setState({ vertical: helperLines.vertical, horizontal: helperLines.horizontal });
            if (helperLines.snapX && change.dimensions && resizingNode.positionAbsolute) {
              change.dimensions.width = Math.abs(resizingNode.positionAbsolute.x - helperLines.snapX);
            }
            if (helperLines.snapY && change.dimensions && resizingNode.positionAbsolute) {
              change.dimensions.height = Math.abs(resizingNode.positionAbsolute.y - helperLines.snapY);
            }
          }
        }
      } else if (enabled && changes.length === 2 && changes[0] && changes[1]) {
        const movingChange = changes[0];
        const resizingChange = changes[1];
        if (isMove$2(movingChange, false) && isResize$2(resizingChange)) {
          const resizingNode = nodeInternal.get(movingChange.id);
          if (resizingNode) {
            const helperLines = getHelperLinesForResizeAndMove(
              resizingChange,
              movingChange,
              resizingNode,
              getNodes(),
              nodeInternal
            );
            setState({ vertical: helperLines.vertical, horizontal: helperLines.horizontal });
            let snapOffsetX = 0;
            let snapOffsetY = 0;
            let parentNode = nodeInternal.get(resizingNode.parentNode || "");
            while (parentNode) {
              snapOffsetX -= parentNode.position.x;
              snapOffsetY -= parentNode.position.y;
              parentNode = nodeInternal.get((parentNode == null ? void 0 : parentNode.parentNode) ?? "");
            }
            if (helperLines.snapX && resizingChange.dimensions && movingChange.position && resizingNode.positionAbsolute && resizingNode.width) {
              movingChange.position.x = helperLines.snapX + snapOffsetX;
              resizingChange.dimensions.width = resizingNode.width + resizingNode.positionAbsolute.x - helperLines.snapX;
            }
            if (helperLines.snapY && resizingChange.dimensions && movingChange.position && resizingNode.positionAbsolute && resizingNode.height) {
              movingChange.position.y = helperLines.snapY + snapOffsetY;
              resizingChange.dimensions.height = resizingNode.height + resizingNode.positionAbsolute.y - helperLines.snapY;
            }
          }
        }
      }
      return changes;
    },
    [enabled]
  );
  const resetHelperLines = useCallback(
    (changes) => {
      if (enabled && changes.every(
        (change) => change && (change.type === "position" && typeof change.dragging === "boolean" && !change.dragging || change.type === "dimensions" && !change.resizing)
      )) {
        setState({ vertical: null, horizontal: null });
      }
    },
    [enabled]
  );
  return {
    helperLinesEnabled: enabled,
    setHelperLinesEnabled: setEnabled,
    horizontalHelperLine: state.horizontal,
    verticalHelperLine: state.vertical,
    applyHelperLines,
    resetHelperLines
  };
};
const useNodeHover = () => {
  const { setNodes } = useStore();
  const { draggedNodeId } = useContext(DropNodeContext);
  const onNodeMouseEnter = useCallback(
    (_, node) => {
      if (!draggedNodeId) {
        setNodes(
          (nds) => nds.map((n) => {
            if (n.id === node.id) {
              if (!n.data.isHovered) {
                n.data = {
                  ...n.data,
                  isHovered: true
                };
              }
            }
            return n;
          })
        );
      }
    },
    [setNodes, draggedNodeId]
  );
  const onNodeMouseLeave = useCallback(() => {
    if (!draggedNodeId) {
      setNodes(
        (nds) => nds.map((n) => {
          if (n.data.isHovered) {
            n.data = {
              ...n.data,
              isHovered: false
            };
          }
          return n;
        })
      );
    }
  }, [setNodes, draggedNodeId]);
  return {
    onNodeMouseEnter,
    onNodeMouseLeave
  };
};
const isMove$1 = (change) => change.type === "position";
const isResize$1 = (change) => change.type === "dimensions";
const isChangingDiagram = (change) => isMove$1(change) || isResize$1(change);
const useFilterReadOnlyChanges = () => {
  const { readOnly } = useContext(DiagramContext);
  const filterReadOnlyChanges = (changes) => {
    const filteredNodeChange = [];
    changes.forEach((change) => {
      if (!isChangingDiagram(change) || !readOnly) {
        filteredNodeChange.push(change);
      }
    });
    return filteredNodeChange;
  };
  return { filterReadOnlyChanges };
};
const useLayoutOnBoundsChange = (refreshEventPayloadId) => {
  const { layout: layout2 } = useLayout();
  const { synchronizeLayoutData } = useSynchronizeLayoutData();
  const { getEdges, getNodes } = useStore();
  const isMoveFinished = (change, nodes) => {
    return change.type === "position" && typeof change.dragging === "boolean" && !change.dragging && hasDropBeenTriggered(nodes);
  };
  const isResizeFinished = (change) => change.type === "dimensions" && typeof change.resizing === "boolean" && !change.resizing;
  const isBoundsChangeFinished = (changes, nodes) => {
    return changes.find((change) => isMoveFinished(change, nodes) || isResizeFinished(change));
  };
  const hasDropBeenTriggered = (nodes) => {
    const targetNode = nodes.find((node) => node.data.isDropNodeTarget);
    const draggedNode = nodes.find((node) => node.dragging);
    const isDropOnNode = !!targetNode;
    const isDropOnSameParent = isDropOnNode && !!(draggedNode == null ? void 0 : draggedNode.parentNode) && draggedNode.parentNode === (targetNode == null ? void 0 : targetNode.id) && (draggedNode == null ? void 0 : draggedNode.type) !== "iconLabelNode";
    const isDropFromDiagramToDiagram = !isDropOnNode && !(draggedNode == null ? void 0 : draggedNode.parentNode);
    return isDropOnSameParent || isDropFromDiagramToDiagram || !!(draggedNode == null ? void 0 : draggedNode.data.isBorderNode);
  };
  const updateNodeResizeByUserState = (changes, nodes) => {
    return nodes.map((node) => {
      if (changes.filter(isResizeFinished).find((dimensionChange) => dimensionChange.id === node.id)) {
        return {
          ...node,
          data: {
            ...node.data,
            resizedByUser: true
          }
        };
      }
      return node;
    });
  };
  const layoutOnBoundsChange = useCallback(
    (changes, nodes) => {
      const change = isBoundsChangeFinished(changes, getNodes());
      if (change) {
        const updatedNodes = updateNodeResizeByUserState(changes, nodes);
        const diagramToLayout = {
          nodes: updatedNodes,
          edges: getEdges()
        };
        layout2(diagramToLayout, diagramToLayout, null, (laidOutDiagram) => {
          updatedNodes.map((node) => {
            const existingNode = laidOutDiagram.nodes.find((laidoutNode) => laidoutNode.id === node.id);
            if (existingNode) {
              return {
                ...node,
                position: existingNode.position,
                width: existingNode.width,
                height: existingNode.height,
                style: {
                  ...node.style,
                  width: `${existingNode.width}px`,
                  height: `${existingNode.height}px`
                }
              };
            }
            return node;
          });
          const finalDiagram = {
            nodes: updatedNodes,
            edges: laidOutDiagram.edges
          };
          synchronizeLayoutData(refreshEventPayloadId, finalDiagram);
        });
      }
    },
    [refreshEventPayloadId, synchronizeLayoutData, getNodes]
  );
  return { layoutOnBoundsChange };
};
const isListData$3 = (node) => node.type === "listNode";
const applyPositionChangeToParentIfUndraggable = (movedNode, nodes, change) => {
  const parentNode = nodes.find((node) => (movedNode == null ? void 0 : movedNode.parentNode) === node.id);
  if (parentNode && change.position && isListData$3(parentNode) && !parentNode.data.areChildNodesDraggable) {
    change.id = parentNode.id;
    change.position.x = parentNode.position.x + (change.position.x - movedNode.position.x);
    change.position.y = parentNode.position.y + (change.position.y - movedNode.position.y);
    return applyPositionChangeToParentIfUndraggable(parentNode, nodes, change);
  } else {
    return change;
  }
};
const isMove = (change) => change.type === "position" && typeof change.dragging === "boolean" && change.dragging;
const useMoveChange = () => {
  const { getNodes } = useStore();
  const transformUndraggableListNodeChanges = useCallback(
    (changes) => {
      return changes.map((change) => {
        if (isMove(change)) {
          const movedNode = getNodes().find((node) => change.id === node.id);
          if ((movedNode == null ? void 0 : movedNode.parentNode) && !movedNode.data.isBorderNode) {
            applyPositionChangeToParentIfUndraggable(movedNode, getNodes(), change);
          }
          if (movedNode == null ? void 0 : movedNode.data.pinned) {
            change.position = void 0;
          }
        }
        return change;
      });
    },
    [getNodes]
  );
  const applyMoveChange = (changes, nodes) => {
    changes.forEach((change) => {
      if (isMove(change)) {
        const movedNode = nodes.find((node) => node.id === change.id);
        if (movedNode) {
          movedNode.zIndex = 1;
        }
      }
    });
    return nodes;
  };
  return { transformUndraggableListNodeChanges, applyMoveChange };
};
const iconlabelStyle = (style, theme2, selected, hovered, faded) => {
  const iconLabelNodeStyle = {
    opacity: faded ? "0.4" : "",
    ...style
  };
  if (selected || hovered) {
    iconLabelNodeStyle.outline = `${theme2.palette.selected} solid 1px`;
  }
  return iconLabelNodeStyle;
};
const IconLabelNode = memo(({ data, id, selected, dragging }) => {
  var _a, _b;
  const theme2 = useTheme();
  const { onDrop, onDragOver } = useDrop();
  const { style: dropFeedbackStyle } = useDropNodeStyle(data.isDropNodeTarget, data.isDropNodeCandidate, dragging);
  const nodeStyle = useMemo(
    () => iconlabelStyle(data.style, theme2, selected, data.isHovered, data.faded),
    [data.style, selected, data.isHovered, data.faded]
  );
  const handleOnDrop = (event) => {
    onDrop(event, id);
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      style: {
        ...nodeStyle,
        ...dropFeedbackStyle
      },
      onDragOver,
      onDrop: handleOnDrop,
      "data-testid": `IconLabel - ${(_a = data == null ? void 0 : data.insideLabel) == null ? void 0 : _a.text}`,
      children: [
        data.insideLabel ? /* @__PURE__ */ jsx(Label, { diagramElementId: id, label: data.insideLabel, faded: data.faded }) : null,
        selected ? /* @__PURE__ */ jsx(
          DiagramElementPalette,
          {
            diagramElementId: id,
            targetObjectId: data.targetObjectId,
            labelId: ((_b = data == null ? void 0 : data.insideLabel) == null ? void 0 : _b.id) ?? null
          }
        ) : null
      ]
    }
  );
});
const nodeTypes = {
  freeFormNode: FreeFormNode,
  listNode: ListNode,
  iconLabelNode: IconLabelNode
};
const useNodeType = () => {
  const { nodeTypeContributions } = useContext(NodeTypeContext);
  const nodeTypeMap = nodeTypeContributions.reduce((map, contrib) => {
    map[contrib.props.type] = React.memo(contrib.props.component);
    return map;
  }, {});
  const contributedNodeTypes = useMemo(() => {
    return nodeTypeMap;
  }, []);
  const nodeTypes$1 = useMemo(() => {
    return { ...nodeTypes, ...contributedNodeTypes };
  }, [contributedNodeTypes]);
  return { nodeTypes: nodeTypes$1 };
};
const DiagramPalette = memo(({ diagramElementId, targetObjectId }) => {
  const { readOnly } = useContext(DiagramContext);
  const { isOpened, x, y, hideDiagramPalette } = useDiagramPalette();
  const escapePressed = useKeyPress("Escape");
  useEffect(() => {
    if (escapePressed) {
      hideDiagramPalette();
    }
  }, [escapePressed, hideDiagramPalette]);
  if (readOnly) {
    return null;
  }
  return isOpened && x && y ? /* @__PURE__ */ jsx(PalettePortal, { children: /* @__PURE__ */ jsx(
    Palette,
    {
      x,
      y,
      diagramElementId,
      targetObjectId,
      onDirectEditClick: () => {
      }
    }
  ) }) : null;
});
const AlignHorizontalCenterIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("polygon", { points: "11,2 13,2 13,7 21,7 21,10 13,10 13,14 18,14 18,17 13,17 13,22 11,22 11,17 6,17 6,14 11,14 11,10 3,10 3,7 11,7" })
    }
  );
};
const AlignHorizontalLeftIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M4,22H2V2h2V22z M22,7H6v3h16V7z M16,14H6v3h10V14z" })
    }
  );
};
const AlignHorizontalRightIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M20,2h2v20h-2V2z M2,10h16V7H2V10z M8,17h10v-3H8V17z" })
    }
  );
};
const AlignVerticalBottomIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M22,22H2v-2h20V22z M10,2H7v16h3V2z M17,8h-3v10h3V8z" })
    }
  );
};
const AlignVerticalCenterIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("polygon", { points: "22,11 17,11 17,6 14,6 14,11 10,11 10,3 7,3 7,11 1.84,11 1.84,13 7,13 7,21 10,21 10,13 14,13 14,18 17,18 17,13 22,13" })
    }
  );
};
const AlignVerticalTopIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M22,2v2H2V2H22z M7,22h3V6H7V22z M14,16h3V6h-3V16z" })
    }
  );
};
const JustifyHorizontalIcon = (props) => {
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      style: { transform: "rotate(90deg)" },
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M0 0h24v24H0z", fill: "none" }),
        /* @__PURE__ */ jsx("path", { d: "M6 7h2.5L5 3.5 1.5 7H4v10H1.5L5 20.5 8.5 17H6V7zm4-2v2h12V5H10zm0 14h12v-2H10v2zm0-6h12v-2H10v2z" })
      ]
    }
  );
};
const JustifyVerticalIcon = (props) => {
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M0 0h24v24H0z", fill: "none" }),
        /* @__PURE__ */ jsx("path", { d: "M6 7h2.5L5 3.5 1.5 7H4v10H1.5L5 20.5 8.5 17H6V7zm4-2v2h12V5H10zm0 14h12v-2H10v2zm0-6h12v-2H10v2z" })
      ]
    }
  );
};
const hideDiagramElementMutation = gql`
  mutation hideDiagramElement($input: HideDiagramElementInput!) {
    hideDiagramElement(input: $input) {
      __typename
      ... on SuccessPayload {
        id
        messages {
          body
          level
        }
      }
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload$1 = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload$1 = (payload) => payload.__typename === "SuccessPayload";
const useHideDiagramElements = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const { diagramId, editingContextId } = useContext(DiagramContext);
  const [hideElementMutation, { error: hideDiagramElementError }] = useMutation(hideDiagramElementMutation);
  useEffect(() => {
    if (hideDiagramElementError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
  }, [coreT, hideDiagramElementError]);
  const hideDiagramElements = useCallback(
    async (nodeId, hide) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        elementIds: nodeId,
        hide
      };
      const { data: hideDiagramElementData } = await hideElementMutation({ variables: { input } });
      if (hideDiagramElementData) {
        const { hideDiagramElement } = hideDiagramElementData;
        if (isSuccessPayload$1(hideDiagramElement) || isErrorPayload$1(hideDiagramElement)) {
          addMessages(hideDiagramElement.messages);
        }
      }
    },
    [editingContextId, diagramId, hideElementMutation]
  );
  return { hideDiagramElements };
};
const nodesOverlap = (nodeA, nodeB) => {
  const topLeftX = Math.max(nodeA.position.x, nodeB.position.x);
  const topLeftY = Math.max(nodeA.position.y, nodeB.position.y);
  const bottomRightX = Math.min(nodeA.position.x + (nodeA.width ?? 0), nodeB.position.x + (nodeB.width ?? 0));
  const bottomRightY = Math.min(nodeA.position.y + (nodeA.height ?? 0), nodeB.position.y + (nodeB.height ?? 0));
  return topLeftX < bottomRightX && topLeftY < bottomRightY;
};
const getIntersectingNodes = (node, nodes) => {
  return nodes.filter((n) => {
    if (n.parentNode !== node.parentNode || n.id === node.id) {
      return false;
    }
    return nodesOverlap(node, n);
  });
};
const useOverlap = () => {
  const [enabled, setEnabled] = useState(true);
  const applyOverlap = (movingNode, nodes, direction = "horizontal") => {
    getIntersectingNodes(movingNode, nodes).forEach((node) => {
      const overlapNode = nodes.find((n) => n.id === node.id);
      if (overlapNode) {
        if (overlapNode.data.pinned) {
          if (direction === "horizontal") {
            movingNode.position = {
              ...movingNode.position,
              x: overlapNode.position.x + (overlapNode.width ?? 0) + 40
            };
          } else {
            movingNode.position = {
              ...movingNode.position,
              y: overlapNode.position.y + (overlapNode.height ?? 0) + 40
            };
          }
          applyOverlap(movingNode, nodes, direction);
        } else {
          if (direction === "horizontal") {
            overlapNode.position = {
              ...overlapNode.position,
              x: movingNode.position.x + (movingNode.width ?? 0) + 40
            };
          } else {
            overlapNode.position = {
              ...overlapNode.position,
              y: movingNode.position.y + (movingNode.height ?? 0) + 40
            };
          }
          applyOverlap(overlapNode, nodes, direction);
        }
      }
    });
  };
  const resolveNodeOverlap = useCallback(
    (nodes, direction) => {
      if (enabled) {
        nodes.filter((node) => !node.data.pinned).forEach((node) => applyOverlap(node, nodes, direction));
      }
      return nodes;
    },
    [enabled]
  );
  return {
    nodeOverlapEnabled: enabled,
    setNodeOverlapEnabled: setEnabled,
    resolveNodeOverlap
  };
};
function getComparePositionFn(direction) {
  return (node1, node2) => {
    const positionNode1 = node1.position;
    const positionNode2 = node2.position;
    if (positionNode1 && positionNode2) {
      return direction === "horizontal" ? positionNode1.x - positionNode2.x : positionNode1.y - positionNode2.y;
    }
    return 0;
  };
}
const arrangeGapBetweenElements = 32;
const useDistributeElements = () => {
  const { getNodes, getEdges, setNodes } = useReactFlow();
  const { layout: layout2 } = useLayout();
  const { synchronizeLayoutData } = useSynchronizeLayoutData();
  const { addMessages } = useMultiToast();
  const { refreshEventPayloadId } = useContext(DiagramContext);
  const { resolveNodeOverlap } = useOverlap();
  const processLayoutTool = (selectedNodeIds, layoutFn, sortFn = null, refElementId = null, direction = "horizontal") => {
    var _a;
    const selectedNodes = getNodes().filter((node) => selectedNodeIds.includes(node.id));
    const firstParent = (_a = selectedNodes[0]) == null ? void 0 : _a.parentNode;
    const sameParent = selectedNodes.reduce(
      (isSameParent, node) => isSameParent && node.parentNode === firstParent,
      true
    );
    if (selectedNodes.length < 2) {
      return;
    }
    if (!sameParent) {
      addMessages([{ body: "This tool can only be applied on elements on the same level", level: "WARNING" }]);
      return;
    }
    if (sortFn) {
      selectedNodes.sort(sortFn);
    }
    let refNode = selectedNodes[0];
    if (refElementId) {
      refNode = selectedNodes.find((node) => node.id === refElementId);
    }
    if (refNode) {
      const updatedNodes = layoutFn(selectedNodes, refNode);
      const overlapFreeNodes = resolveNodeOverlap(updatedNodes, direction);
      const diagramToLayout = {
        nodes: [...overlapFreeNodes],
        edges: getEdges()
      };
      layout2(diagramToLayout, diagramToLayout, null, (laidOutDiagram) => {
        const overlapFreeNodesAfterLayout = resolveNodeOverlap(laidOutDiagram.nodes, "horizontal");
        setNodes(overlapFreeNodesAfterLayout);
        const finalDiagram = {
          nodes: overlapFreeNodesAfterLayout,
          edges: laidOutDiagram.edges
        };
        synchronizeLayoutData(refreshEventPayloadId, finalDiagram);
      });
    }
  };
  const distributeNodesOnGap = (direction) => {
    return useCallback((selectedNodeIds) => {
      var _a;
      const selectedNodes = getNodes().filter((node) => selectedNodeIds.includes(node.id));
      const firstParent = (_a = selectedNodes[0]) == null ? void 0 : _a.parentNode;
      const sameParent = selectedNodes.reduce(
        (isSameParent, node) => isSameParent && node.parentNode === firstParent,
        true
      );
      if (selectedNodes.length < 3 || !sameParent) {
        return;
      }
      selectedNodes.sort(getComparePositionFn(direction));
      const firstNode = selectedNodes[0];
      const lastNode = selectedNodes[selectedNodes.length - 1];
      if (firstNode && lastNode) {
        const totalSize = selectedNodes.filter((node) => node.id !== firstNode.id && node.id !== lastNode.id).reduce((total, node) => total + (direction === "horizontal" ? node.width ?? 0 : node.height ?? 0), 0);
        const numberOfGap = selectedNodes.length - 1;
        const gap2 = ((direction === "horizontal" ? lastNode.position.x - firstNode.position.x - (firstNode.width ?? 0) : lastNode.position.y - firstNode.position.y - (firstNode.height ?? 0)) - totalSize) / numberOfGap;
        const updatedNodes = getNodes().map((node) => {
          if (!selectedNodeIds.includes(node.id) || node.data.pinned) {
            return node;
          }
          const index = selectedNodes.findIndex((selectedNode) => selectedNode.id === node.id);
          const currentSelectedNode = selectedNodes[index];
          const previousNode = selectedNodes[index - 1];
          let newPosition = direction === "horizontal" ? node.position.x : node.position.y;
          if (index > 0 && index < selectedNodes.length - 1 && previousNode && currentSelectedNode) {
            newPosition = direction === "horizontal" ? previousNode.position.x + (previousNode.width ?? 0) + gap2 : previousNode.position.y + (previousNode.height ?? 0) + gap2;
            currentSelectedNode.position[direction === "horizontal" ? "x" : "y"] = newPosition;
          }
          return {
            ...node,
            position: {
              ...node.position,
              [direction === "horizontal" ? "x" : "y"]: newPosition
            }
          };
        });
        const overlapFreeNodes = resolveNodeOverlap(updatedNodes, direction);
        const diagramToLayout = {
          nodes: [...overlapFreeNodes],
          edges: getEdges()
        };
        layout2(diagramToLayout, diagramToLayout, null, (laidOutDiagram) => {
          setNodes(laidOutDiagram.nodes);
          const finalDiagram = {
            nodes: laidOutDiagram.nodes,
            edges: laidOutDiagram.edges
          };
          synchronizeLayoutData(refreshEventPayloadId, finalDiagram);
        });
      }
    }, []);
  };
  const distributeAlign = (orientation) => {
    return useCallback(
      (selectedNodeIds, refElementId) => {
        processLayoutTool(
          selectedNodeIds,
          (_selectedNodes, refNode) => {
            return getNodes().map((node) => {
              if (!selectedNodeIds.includes(node.id) || node.data.pinned) {
                return node;
              }
              const referencePositionValue = (() => {
                switch (orientation) {
                  case "left":
                    return refNode.position.x;
                  case "right":
                    return refNode.position.x + (refNode.width ?? 0) - (node.width ?? 0);
                  case "center":
                    return refNode.position.x + (refNode.width ?? 0) / 2 - (node.width ?? 0) / 2;
                  case "top":
                    return refNode.position.y;
                  case "bottom":
                    return refNode.position.y + (refNode.height ?? 0) - (node.height ?? 0);
                  case "middle":
                    return refNode.position.y + (refNode.height ?? 0) / 2 - (node.height ?? 0) / 2;
                }
              })();
              const referencePositionVariable = (() => {
                switch (orientation) {
                  case "left":
                  case "right":
                  case "center":
                    return "x";
                  case "top":
                  case "bottom":
                  case "middle":
                    return "y";
                }
              })();
              return {
                ...node,
                position: {
                  ...node.position,
                  [referencePositionVariable]: referencePositionValue
                }
              };
            });
          },
          null,
          refElementId,
          ["left", "right", "center"].includes(orientation) ? "vertical" : "horizontal"
        );
      },
      [resolveNodeOverlap]
    );
  };
  const justifyElements = (justifyElementsFn) => {
    return useCallback(
      (selectedNodeIds, refElementId) => {
        processLayoutTool(
          selectedNodeIds,
          (selectedNodes, refNode) => {
            selectedNodes.sort(getComparePositionFn("horizontal"));
            return justifyElementsFn(selectedNodes, selectedNodeIds, refNode);
          },
          null,
          refElementId
        );
      },
      [resolveNodeOverlap]
    );
  };
  const justifyHorizontally = justifyElements(
    (selectedNodes, selectedNodeIds, refNode) => {
      const largestWidth = selectedNodes.reduce((width, node) => Math.max(width, node.width ?? 0), 0);
      return getNodes().map((node) => {
        var _a;
        if (!selectedNodeIds.includes(node.id) || ((_a = node.data.nodeDescription) == null ? void 0 : _a.userResizable) === "NONE" || node.data.pinned) {
          return node;
        }
        return {
          ...node,
          width: largestWidth,
          position: {
            ...node.position,
            x: refNode.position.x
          },
          data: {
            ...node.data,
            resizedByUser: true
          }
        };
      });
    }
  );
  const justifyVertically = justifyElements(
    (selectedNodes, selectedNodeIds, refNode) => {
      const largestHeight = selectedNodes.reduce((height, node) => Math.max(height, node.height ?? 0), 0);
      return getNodes().map((node) => {
        var _a;
        if (!selectedNodeIds.includes(node.id) || ((_a = node.data.nodeDescription) == null ? void 0 : _a.userResizable) === "NONE" || node.data.pinned) {
          return node;
        }
        return {
          ...node,
          height: largestHeight,
          position: {
            ...node.position,
            y: refNode.position.y
          },
          data: {
            ...node.data,
            resizedByUser: true
          }
        };
      });
    }
  );
  const arrangeInRow = (selectedNodeIds) => {
    processLayoutTool(
      selectedNodeIds,
      (selectedNodes, refNode) => {
        let nextXPosition = refNode.position.x;
        const updatedSelectedNodes = selectedNodes.filter((node) => !node.data.pinned).map((node) => {
          const updatedNode = {
            ...node,
            position: {
              ...node.position,
              x: nextXPosition,
              y: refNode.position.y
            }
          };
          nextXPosition = updatedNode.position.x + (updatedNode.width ?? 0) + arrangeGapBetweenElements;
          return updatedNode;
        });
        return getNodes().map((node) => {
          const replacedNode = updatedSelectedNodes.find((updatedSelectedNode) => updatedSelectedNode.id === node.id);
          if (replacedNode) {
            return replacedNode;
          }
          return node;
        });
      },
      getComparePositionFn("horizontal")
    );
  };
  const arrangeInColumn = (selectedNodeIds) => {
    processLayoutTool(
      selectedNodeIds,
      (selectedNodes, refNode) => {
        let nextYPosition = refNode.position.y;
        const updatedSelectedNodes = selectedNodes.filter((node) => !node.data.pinned).map((node) => {
          const updatedNode = {
            ...node,
            position: {
              ...node.position,
              x: refNode.position.x,
              y: nextYPosition
            }
          };
          nextYPosition = updatedNode.position.y + (updatedNode.height ?? 0) + arrangeGapBetweenElements;
          return updatedNode;
        });
        return getNodes().map((node) => {
          const replacedNode = updatedSelectedNodes.find((updatedSelectedNode) => updatedSelectedNode.id === node.id);
          if (replacedNode) {
            return replacedNode;
          }
          return node;
        });
      },
      getComparePositionFn("vertical"),
      null,
      "vertical"
    );
  };
  const arrangeInGrid = (selectedNodeIds) => {
    processLayoutTool(
      selectedNodeIds,
      (selectedNodes, refNode) => {
        const columnNumber = Math.round(Math.sqrt(selectedNodeIds.length));
        let nextXPosition = refNode.position.x;
        let nextYPosition = refNode.position.y;
        const updatedSelectedNodes = selectedNodes.filter((node) => !node.data.pinned).map((node, index) => {
          const columnIndex = index + 1;
          const updatedNode = {
            ...node,
            position: {
              ...node.position,
              x: nextXPosition,
              y: nextYPosition
            }
          };
          nextXPosition = updatedNode.position.x + (updatedNode.width ?? 0) + arrangeGapBetweenElements;
          if (columnIndex % columnNumber === 0) {
            nextXPosition = refNode.position.x;
            nextYPosition = updatedNode.position.y + arrangeGapBetweenElements + selectedNodes.slice(columnIndex - columnNumber, columnIndex).reduce((maxHeight, rowNode) => Math.max(maxHeight, rowNode.height ?? 0), 0);
          }
          return updatedNode;
        });
        return getNodes().map((node) => {
          const replacedNode = updatedSelectedNodes.find((updatedSelectedNode) => updatedSelectedNode.id === node.id);
          if (replacedNode) {
            return replacedNode;
          }
          return node;
        });
      },
      getComparePositionFn("horizontal")
    );
  };
  const distributeGapHorizontally = distributeNodesOnGap("horizontal");
  const distributeGapVertically = distributeNodesOnGap("vertical");
  const distributeAlignLeft = distributeAlign("left");
  const distributeAlignRight = distributeAlign("right");
  const distributeAlignCenter = distributeAlign("center");
  const distributeAlignTop = distributeAlign("top");
  const distributeAlignBottom = distributeAlign("bottom");
  const distributeAlignMiddle = distributeAlign("middle");
  const makeNodesSameSize = useCallback(
    (selectedNodeIds, refElementId) => {
      processLayoutTool(
        selectedNodeIds,
        (_selectedNodes, refNode) => {
          return getNodes().map((node) => {
            var _a;
            if (!selectedNodeIds.includes(node.id) || ((_a = node.data.nodeDescription) == null ? void 0 : _a.userResizable) === "NONE") {
              return node;
            }
            return {
              ...node,
              width: refNode.width,
              height: refNode.height,
              data: {
                ...node.data,
                resizedByUser: true
              }
            };
          });
        },
        null,
        refElementId
      );
    },
    [resolveNodeOverlap]
  );
  return {
    distributeGapVertically,
    distributeGapHorizontally,
    distributeAlignLeft,
    distributeAlignRight,
    distributeAlignCenter,
    distributeAlignTop,
    distributeAlignBottom,
    distributeAlignMiddle,
    justifyHorizontally,
    justifyVertically,
    arrangeInRow,
    arrangeInColumn,
    arrangeInGrid,
    makeNodesSameSize
  };
};
const usePaletteToolStyle = makeStyles()((theme2) => ({
  toolIcon: {
    width: theme2.spacing(4.5),
    color: theme2.palette.text.primary
  }
}));
const PaletteTool = ({ toolName, onClick, children }) => {
  const { classes } = usePaletteToolStyle();
  return /* @__PURE__ */ jsx(Tooltip, { title: toolName, children: /* @__PURE__ */ jsx(
    IconButton,
    {
      className: classes.toolIcon,
      size: "small",
      "aria-label": toolName,
      onClick,
      "data-testid": toolName,
      children
    }
  ) });
};
const usePaletteStyle = makeStyles()((theme2) => ({
  palette: {
    border: `1px solid ${theme2.palette.divider}`,
    borderRadius: "2px",
    zIndex: 5,
    position: "fixed",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: theme2.spacing(45.25)
  },
  toolSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: theme2.spacing(4.5)
  },
  toolList: {
    padding: theme2.spacing(0.5),
    border: `1px solid ${theme2.palette.divider}`,
    borderRadius: "2px",
    width: "max-content"
  },
  distributeElementList: {
    display: "flex",
    flexDirection: "column"
  },
  distributeElementSeparator: {
    borderTop: `1px solid ${theme2.palette.divider}`
  },
  distributeElementTool: {
    flexDirection: "row",
    cursor: "pointer"
  },
  arrow: {
    cursor: "pointer",
    height: "14px",
    width: "14px",
    marginLeft: -theme2.spacing(0.5),
    marginTop: theme2.spacing(1.5)
  }
}));
const isListData$2 = (node) => node.type === "listNode";
const canSelectedNodesBeDistributed = (selectedNodes) => selectedNodes.length > 1;
const GroupPalette = memo(({ x, y, isOpened, refElementId, hidePalette }) => {
  var _a;
  const { t } = useTranslation("siriusComponentsDiagrams", { keyPrefix: "palette" });
  const { hideDiagramElements } = useHideDiagramElements();
  const { fadeDiagramElements } = useFadeDiagramElements();
  const { pinDiagramElements } = usePinDiagramElements();
  const {
    distributeGapVertically,
    distributeGapHorizontally,
    distributeAlignLeft,
    distributeAlignRight,
    distributeAlignCenter,
    distributeAlignTop,
    distributeAlignBottom,
    distributeAlignMiddle,
    justifyHorizontally,
    justifyVertically,
    arrangeInRow,
    arrangeInColumn,
    arrangeInGrid,
    makeNodesSameSize
  } = useDistributeElements();
  const [state, setState] = useState({
    selectedElementIds: [],
    isMinimalPalette: false,
    isDistributeElementToolSectionExpand: false,
    lastDistributeElementToolId: null
  });
  const { getNode } = useReactFlow();
  const onChange = useCallback(({ nodes, edges }) => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedListChildFiltered = selectedNodes.filter((node) => {
      const parent = getNode(node.parentNode ?? "");
      if (parent) {
        return !isListData$2(parent);
      }
      return true;
    });
    const selectedEdges = edges.filter((edge) => edge.selected);
    const isMinimalPalette = !canSelectedNodesBeDistributed(selectedListChildFiltered);
    const selectedElements = isMinimalPalette ? [...selectedNodes, ...selectedEdges] : [...selectedListChildFiltered];
    if (selectedElements.length > 1) {
      setState((prevState) => ({
        ...prevState,
        selectedElementIds: selectedElements.map((element) => element.id),
        isMinimalPalette
      }));
    } else {
      setState((prevState) => ({ ...prevState, selectedElementIds: [] }));
    }
  }, []);
  useOnSelectionChange({
    onChange
  });
  const { classes } = usePaletteStyle();
  const anchorRef = useRef(null);
  const distributeElementTools = state.isMinimalPalette ? [] : [
    [
      {
        id: "align-left",
        title: t("alignLeft"),
        action: () => distributeAlignLeft(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignHorizontalLeftIcon, { fontSize: "small" })
      },
      {
        id: "align-center",
        title: t("alignCenter"),
        action: () => distributeAlignCenter(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignHorizontalCenterIcon, { fontSize: "small" })
      },
      {
        id: "align-right",
        title: t("alignRight"),
        action: () => distributeAlignRight(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignHorizontalRightIcon, { fontSize: "small" })
      }
    ],
    [
      {
        id: "align-top",
        title: t("alignTop"),
        action: () => distributeAlignTop(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignVerticalTopIcon, { fontSize: "small" })
      },
      {
        id: "align-middle",
        title: t("alignMiddle"),
        action: () => distributeAlignMiddle(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignVerticalCenterIcon, { fontSize: "small" })
      },
      {
        id: "align-bottom",
        title: t("alignBottom"),
        action: () => distributeAlignBottom(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(AlignVerticalBottomIcon, { fontSize: "small" })
      }
    ],
    [
      {
        id: "arrange-in-row",
        title: t("arrangeInRow"),
        action: () => arrangeInRow(state.selectedElementIds),
        icon: /* @__PURE__ */ jsx(ViewColumnIcon, { fontSize: "small" })
      },
      {
        id: "arrange-in-column",
        title: t("arrangeInColumn"),
        action: () => arrangeInColumn(state.selectedElementIds),
        icon: /* @__PURE__ */ jsx(ViewStreamIcon, { fontSize: "small" })
      },
      {
        id: "arrange-in-grid",
        title: t("arrangeInGrid"),
        action: () => arrangeInGrid(state.selectedElementIds),
        icon: /* @__PURE__ */ jsx(ViewModuleIcon, { fontSize: "small" })
      }
    ],
    [
      {
        id: "distribute-horizontal-spacing",
        title: t("distributeHorizontal"),
        action: () => distributeGapHorizontally(state.selectedElementIds),
        icon: /* @__PURE__ */ jsx(VerticalAlignCenterIcon, { style: { transform: "rotate(90deg)" }, fontSize: "small" })
      },
      {
        id: "distribute-vertical-spacing",
        title: t("distributeVertical"),
        action: () => distributeGapVertically(state.selectedElementIds),
        icon: /* @__PURE__ */ jsx(VerticalAlignCenterIcon, { fontSize: "small" })
      }
    ],
    [
      {
        id: "justify-horizontally",
        title: t("justifyHorizontally"),
        action: () => justifyHorizontally(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(JustifyHorizontalIcon, { fontSize: "small" })
      },
      {
        id: "justify-vertically",
        title: t("justifyVertically"),
        action: () => justifyVertically(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(JustifyVerticalIcon, { fontSize: "small" })
      }
    ],
    [
      {
        id: "make-same-size",
        title: t("makeSameSize"),
        action: () => makeNodesSameSize(state.selectedElementIds, refElementId),
        icon: /* @__PURE__ */ jsx(PhotoSizeSelectSmallIcon, { fontSize: "small" })
      }
    ]
  ];
  const shouldRender = state.selectedElementIds.length > 1 && isOpened && x && y;
  if (!shouldRender) {
    if (state.isDistributeElementToolSectionExpand) {
      setState((prevState) => ({ ...prevState, isDistributeElementToolSectionExpand: false }));
    }
    return null;
  }
  let caretContent;
  caretContent = /* @__PURE__ */ jsx(
    ExpandMoreIcon,
    {
      className: classes.arrow,
      style: { fontSize: 20 },
      onClick: (event) => {
        event.stopPropagation();
        setState((prevState) => ({ ...prevState, isDistributeElementToolSectionExpand: true }));
      },
      "data-testid": "expand",
      ref: anchorRef
    }
  );
  const handleDistributeElementToolClick = (tool) => {
    tool.action();
    hidePalette();
    setState((prevState) => ({
      ...prevState,
      lastDistributeElementToolId: tool.id,
      isDistributeElementToolSectionExpand: false
    }));
  };
  const defaultDistributeTool = distributeElementTools.flatMap((toolSection) => toolSection).find((tool) => tool.id === state.lastDistributeElementToolId) ?? ((_a = distributeElementTools[0]) == null ? void 0 : _a[0]);
  return /* @__PURE__ */ jsx(PalettePortal, { children: /* @__PURE__ */ jsxs(Paper, { className: classes.palette, style: { position: "absolute", left: x, top: y }, "data-testid": "GroupPalette", children: [
    defaultDistributeTool && /* @__PURE__ */ jsxs("div", { className: classes.toolSection, children: [
      /* @__PURE__ */ jsx(
        PaletteTool,
        {
          toolName: defaultDistributeTool.title,
          onClick: () => handleDistributeElementToolClick(defaultDistributeTool),
          children: defaultDistributeTool.icon
        },
        defaultDistributeTool.id
      ),
      caretContent
    ] }),
    /* @__PURE__ */ jsx(
      Popper,
      {
        open: state.isDistributeElementToolSectionExpand,
        anchorEl: anchorRef.current,
        placement: "bottom-start",
        disablePortal: true,
        style: { zIndex: 9999 },
        children: /* @__PURE__ */ jsx(Paper, { className: classes.toolList, elevation: 2, children: /* @__PURE__ */ jsx(
          ClickAwayListener,
          {
            onClickAway: () => {
              setState((prevState) => ({ ...prevState, isDistributeElementToolSectionExpand: false }));
            },
            children: /* @__PURE__ */ jsx("div", { className: classes.distributeElementList, children: distributeElementTools.map((toolSection, index) => /* @__PURE__ */ jsxs(Fragment$1, { children: [
              toolSection.map((tool) => /* @__PURE__ */ jsxs(
                "div",
                {
                  className: classes.distributeElementTool,
                  onClick: () => handleDistributeElementToolClick(tool),
                  children: [
                    /* @__PURE__ */ jsx(
                      PaletteTool,
                      {
                        toolName: tool.title,
                        onClick: () => handleDistributeElementToolClick(tool),
                        children: tool.icon
                      },
                      tool.id
                    ),
                    tool.title
                  ]
                },
                tool.id
              )),
              index < distributeElementTools.length - 1 && /* @__PURE__ */ jsx("hr", { className: classes.distributeElementSeparator })
            ] }, index)) })
          }
        ) })
      }
    ),
    /* @__PURE__ */ jsx(PaletteTool, { toolName: t("hideElements"), onClick: () => hideDiagramElements(state.selectedElementIds, true), children: /* @__PURE__ */ jsx(VisibilityOffIcon, { fontSize: "small" }) }),
    /* @__PURE__ */ jsx(PaletteTool, { toolName: t("fadeElements"), onClick: () => fadeDiagramElements(state.selectedElementIds, true), children: /* @__PURE__ */ jsx(TonalityIcon, { fontSize: "small" }) }),
    !state.isMinimalPalette && /* @__PURE__ */ jsx(PaletteTool, { toolName: t("pinElements"), onClick: () => pinDiagramElements(state.selectedElementIds, true), children: /* @__PURE__ */ jsx(PinIcon, { fontSize: "small" }) })
  ] }) });
});
const computePalettePosition = (event, bounds) => {
  return {
    x: event.clientX - ((bounds == null ? void 0 : bounds.left) ?? 0),
    y: event.clientY - ((bounds == null ? void 0 : bounds.top) ?? 0)
  };
};
const useGroupPalette = () => {
  const [state, setState] = useState({ position: null, isOpened: false, refElementId: null });
  const store = useStoreApi();
  const escapePressed = useKeyPress("Escape");
  useEffect(() => {
    if (escapePressed) {
      hideGroupPalette();
    }
  }, [escapePressed]);
  const onDiagramElementClick = useCallback(
    (event, refElement) => {
      const { domNode } = store.getState();
      const element = domNode == null ? void 0 : domNode.getBoundingClientRect();
      const palettePosition = computePalettePosition(event, element);
      const refElementId = (refElement == null ? void 0 : refElement.id) ?? null;
      setState((prevState) => ({ ...prevState, position: palettePosition, isOpened: true, refElementId }));
    },
    []
  );
  const hideGroupPalette = useCallback(() => {
    if (state.isOpened) {
      setState((prevState) => ({ ...prevState, position: null, isOpened: false }));
    }
  }, [state.isOpened]);
  return {
    position: state.position,
    isOpened: state.isOpened,
    refElementId: state.refElementId,
    hideGroupPalette,
    onDiagramElementClick
  };
};
const HelperLinesIcon = (props) => {
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: /* @__PURE__ */ jsx("path", { d: "M22,11V9h-7V2h-2v7h-2V2H9v7H2v2h7v2H2v2h7v7h2v-7h2v7h2v-7h7v-2h-7v-2H22z M13,13h-2v-2h2V13z" })
    }
  );
};
const HelperLinesIconOff = (props) => {
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 24 24",
      "aria-labelledby": "title",
      "aria-describedby": "desc",
      role: "img",
      ...props,
      children: [
        /* @__PURE__ */ jsx("path", { d: "M22,11V9h-7V2h-2v7h-2V2H9v7H2v2h7v2H2v2h7v7h2v-7h2v7h2v-7h7v-2h-7v-2H22z M13,13h-2v-2h2V13z" }),
        /* @__PURE__ */ jsx("line", { fill: "none", id: "svg_10", stroke: "#757575", strokeWidth: "2", x1: "3", x2: "20", y1: "3.5", y2: "21" }),
        /* @__PURE__ */ jsx("line", { fill: "none", id: "svg_12", stroke: "#ffffff", strokeWidth: "1.75", x1: "4.12", x2: "21.06", y1: "2.42", y2: "19.78" })
      ]
    }
  );
};
const FullscreenContext = React.createContext({
  fullscreen: false,
  setFullscreen: () => {
  }
});
const FullscreenContextProvider = ({ children }) => {
  const [state, setState] = useState({ fullscreen: false });
  const setFullscreen = useCallback((fullscreen) => {
    setState((prevState) => ({ ...prevState, fullscreen }));
  }, []);
  return /* @__PURE__ */ jsx(FullscreenContext.Provider, { value: { fullscreen: state.fullscreen, setFullscreen }, children });
};
const useFullscreen = () => {
  const { domNode } = useStoreApi().getState();
  const { fullscreen, setFullscreen } = useContext(FullscreenContext);
  useEffect(() => {
    const onFullscreenChange = () => setFullscreen(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);
  const onFullscreen = useCallback(
    (fullscreen2) => {
      if (domNode) {
        if (fullscreen2) {
          domNode.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      }
    },
    [domNode]
  );
  return {
    fullscreen,
    onFullscreen
  };
};
const isListData$1 = (node) => node.type === "listNode";
function reverseOrdreMap(map) {
  const reversedNodes = Array.from(map.entries()).reverse();
  return new Map(reversedNodes);
}
const getSubNodes = (nodes) => {
  var _a;
  const subNodes = /* @__PURE__ */ new Map();
  for (const node of nodes.filter((n) => !n.hidden)) {
    const parentNodeId = node.parentNode ?? "root";
    if (!subNodes.has(parentNodeId)) {
      subNodes.set(parentNodeId, []);
    }
    (_a = subNodes.get(parentNodeId)) == null ? void 0 : _a.push(node);
  }
  return subNodes;
};
const elkOptions = (direction) => {
  return {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": "100",
    "layering.strategy": "NETWORK_SIMPLEX",
    "elk.spacing.nodeNode": "100",
    "elk.direction": `${direction}`,
    "elk.layered.spacing.edgeNodeBetweenLayers": "40",
    "elk.layered.nodePlacement.strategy": "NETWORK_SIMPLEX"
  };
};
const computeHeaderVerticalFootprint = (node, viewportZoom, reactFlowWrapper) => {
  var _a, _b;
  if (node && ((_a = node.data.insideLabel) == null ? void 0 : _a.isHeader)) {
    const label = (_b = reactFlowWrapper == null ? void 0 : reactFlowWrapper.current) == null ? void 0 : _b.querySelector(
      `[data-id="${node.data.insideLabel.id}-content"]`
    );
    if (label) {
      return label.getBoundingClientRect().height / viewportZoom + labelVerticalPadding * 2;
    }
  }
  return 0;
};
const computeLabels = (node, viewportZoom, reactFlowWrapper) => {
  var _a, _b;
  const labels = [];
  if (node && node.data.insideLabel) {
    const label = (_a = reactFlowWrapper == null ? void 0 : reactFlowWrapper.current) == null ? void 0 : _a.querySelector(
      `[data-id="${node.data.insideLabel.id}-content"]`
    );
    if (label) {
      const elkLabel = {
        id: node.data.insideLabel.id,
        width: label.getBoundingClientRect().width / viewportZoom + labelHorizontalPadding * 2,
        height: label.getBoundingClientRect().height / viewportZoom + labelVerticalPadding * 2,
        text: node.data.insideLabel.text,
        x: 0,
        y: 0
      };
      labels.push(elkLabel);
    }
  }
  if (node && node.data.outsideLabels.BOTTOM_MIDDLE) {
    const label = (_b = reactFlowWrapper == null ? void 0 : reactFlowWrapper.current) == null ? void 0 : _b.querySelector(
      `[data-id="${node.data.outsideLabels.BOTTOM_MIDDLE.id}-content"]`
    );
    if (label) {
      const elkLabel = {
        id: node.data.outsideLabels.BOTTOM_MIDDLE.id,
        width: label.getBoundingClientRect().width / viewportZoom + labelHorizontalPadding * 2,
        height: label.getBoundingClientRect().height / viewportZoom + labelVerticalPadding * 2,
        text: node.data.outsideLabels.BOTTOM_MIDDLE.text,
        x: 0,
        y: node.height
      };
      labels.push(elkLabel);
    }
  }
  return labels;
};
const useArrangeAll = (reactFlowWrapper) => {
  const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
  const viewport = useViewport();
  const { layout: layout2 } = useLayout();
  const { synchronizeLayoutData } = useSynchronizeLayoutData();
  const { diagramDescription } = useDiagramDescription();
  const { refreshEventPayloadId } = useContext(DiagramContext);
  const { resolveNodeOverlap } = useOverlap();
  const { addErrorMessage } = useMultiToast();
  const elk = new ELK();
  const getELKLayout = async (nodes, edges, options = {}, parentNodeId, headerVerticalFootprint) => {
    var _a;
    const graph = {
      id: parentNodeId,
      layoutOptions: options,
      children: nodes.map((node) => ({
        labels: computeLabels(node, viewport.zoom, reactFlowWrapper),
        ...node
      })),
      edges
    };
    try {
      const layoutedGraph = await elk.layout(graph);
      return {
        nodes: ((_a = layoutedGraph == null ? void 0 : layoutedGraph.children) == null ? void 0 : _a.map((node) => {
          const originalNode = nodes.find((node_1) => node_1.id === node.id);
          if (originalNode && originalNode.data.pinned) {
            return { ...node };
          } else {
            return {
              ...node,
              position: { x: node.x ?? 0, y: (node.y ?? 0) + headerVerticalFootprint }
            };
          }
        })) ?? [],
        layoutReturn: layoutedGraph
      };
    } catch (message) {
      addErrorMessage("An error occurred during the arrange all elements ");
      return [];
    }
  };
  const applyElkOnSubNodes = async (subNodes, allNodes) => {
    let layoutedAllNodes = [];
    const parentNodeWithNewSize = [];
    const edges = getEdges();
    for (const [parentNodeId, nodes] of subNodes) {
      const parentNode = allNodes.find((node) => node.id === parentNodeId);
      if (parentNode && isListData$1(parentNode) || nodes.every((node) => node.data.isBorderNode)) {
        layoutedAllNodes = [...layoutedAllNodes, ...nodes.reverse()];
        continue;
      }
      const headerVerticalFootprint = computeHeaderVerticalFootprint(
        parentNode,
        viewport.zoom,
        reactFlowWrapper
      );
      const subGroupNodes = nodes.filter((node) => !node.data.isBorderNode).map((node) => {
        return parentNodeWithNewSize.find((layoutNode) => layoutNode.id === node.id) ?? node;
      });
      const subGroupEdges = [];
      edges.forEach((edge) => {
        const isTargetInside = subGroupNodes.some((node) => node.id === edge.target);
        const isSourceInside = subGroupNodes.some((node) => node.id === edge.source);
        if (isTargetInside && isSourceInside) {
          subGroupEdges.push(edge);
        }
        if (isTargetInside && !isSourceInside) {
          edge.target = parentNodeId;
        }
        if (!isTargetInside && isSourceInside) {
          edge.source = parentNodeId;
        }
      });
      await getELKLayout(
        subGroupNodes,
        subGroupEdges,
        elkOptions(diagramDescription.arrangeLayoutDirection),
        parentNodeId,
        headerVerticalFootprint
      ).then(({ nodes: layoutedSubNodes, layoutReturn }) => {
        const parentNode2 = allNodes.find((node) => node.id === parentNodeId);
        if (layoutReturn) {
          if (parentNode2) {
            parentNode2.width = layoutReturn.width;
            parentNode2.height = layoutReturn.height + headerVerticalFootprint;
            parentNode2.style = { width: `${parentNode2.width}px`, height: `${parentNode2.height}px` };
            parentNodeWithNewSize.push(parentNode2);
          }
          layoutedAllNodes = [
            ...layoutedAllNodes,
            ...layoutedSubNodes,
            ...nodes.filter((node) => node.data.isBorderNode)
          ];
        } else {
          layoutedAllNodes = nodes;
        }
      });
    }
    return layoutedAllNodes;
  };
  const arrangeAll = async () => {
    const nodes = [...getNodes()];
    const subNodes = reverseOrdreMap(getSubNodes(nodes));
    await applyElkOnSubNodes(subNodes, nodes).then(async (nodes2) => {
      const laidOutNodesWithElk = nodes2.reverse();
      laidOutNodesWithElk.filter((laidOutNode) => {
        const parentNode = nodes2.find((node) => node.id === laidOutNode.parentNode);
        return !parentNode || !isListData$1(parentNode);
      });
      const diagramToLayout = {
        nodes: laidOutNodesWithElk,
        edges: getEdges()
      };
      const layoutPromise = new Promise((resolve) => {
        layout2(diagramToLayout, diagramToLayout, null, (laidOutDiagram) => {
          const overlapFreeLaidOutNodes = resolveNodeOverlap(
            laidOutDiagram.nodes.filter((n) => !n.data.isBorderNode),
            "horizontal"
          );
          laidOutNodesWithElk.map((node) => {
            const existingNode = overlapFreeLaidOutNodes.find((laidOutNode) => laidOutNode.id === node.id);
            if (existingNode) {
              return {
                ...node,
                position: existingNode.position,
                width: existingNode.width,
                height: existingNode.height,
                style: {
                  ...node.style,
                  width: `${existingNode.width}px`,
                  height: `${existingNode.height}px`
                }
              };
            }
            return node;
          });
          setNodes(laidOutNodesWithElk);
          setEdges(laidOutDiagram.edges);
          const finalDiagram = {
            nodes: laidOutNodesWithElk,
            edges: laidOutDiagram.edges
          };
          synchronizeLayoutData(refreshEventPayloadId, finalDiagram);
          resolve();
        });
      });
      await layoutPromise;
    });
  };
  return {
    arrangeAll
  };
};
const downloadImage = (dataUrl) => {
  const a = document.createElement("a");
  a.setAttribute("download", "diagram.svg");
  a.setAttribute("href", dataUrl);
  a.click();
};
const useExportToImage = () => {
  const reactFlow = useReactFlow();
  const exportToImage = useCallback(() => {
    const nodesBounds = getRectOfNodes(reactFlow.getNodes());
    const imageWidth = nodesBounds.width;
    const imageHeight = nodesBounds.height;
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2, 0.2);
    const viewport = document.querySelector(".react-flow__viewport");
    const edges = document.querySelector(".react-flow__edges");
    const edgeMarkersDefs = document.getElementById("edge-markers-defs");
    if (viewport && edges && edgeMarkersDefs) {
      const clonedEdgeMarkersDefs = edgeMarkersDefs.cloneNode(true);
      edges.insertBefore(clonedEdgeMarkersDefs, edges.firstChild);
      toSvg(viewport, {
        backgroundColor: "#ffffff",
        width: imageWidth,
        height: imageHeight,
        style: {
          width: imageWidth.toString(),
          height: imageHeight.toString(),
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
        }
      }).then(downloadImage).finally(() => edges.removeChild(clonedEdgeMarkersDefs));
    }
  }, []);
  return { exportToImage };
};
const DiagramPanel = memo(
  ({ snapToGrid, onSnapToGrid, helperLines, onHelperLines, reactFlowWrapper }) => {
    const { t } = useTranslation("siriusComponentsDiagrams", { keyPrefix: "panel" });
    const [state, setState] = useState({
      dialogOpen: null,
      arrangeAllDone: false,
      arrangeAllInProgress: false
    });
    const { readOnly } = useContext(DiagramContext);
    const diagramPanelActionComponents = useComponents(
      diagramPanelActionExtensionPoint
    );
    const { getNodes, getEdges, zoomIn, zoomOut, fitView } = useReactFlow();
    const getAllElementsIds = () => [...getNodes().map((elem) => elem.id), ...getEdges().map((elem) => elem.id)];
    const getSelectedNodes = () => getNodes().filter((node) => node.selected);
    const { fullscreen, onFullscreen } = useFullscreen();
    const { arrangeAll } = useArrangeAll(reactFlowWrapper);
    const nodesInitialized = useNodesInitialized();
    useEffect(() => {
      if (nodesInitialized && state.arrangeAllDone) {
        fitView({ duration: 400 });
        setState((prevState) => ({ ...prevState, arrangeAllDone: false }));
      }
    }, [nodesInitialized, state.arrangeAllDone]);
    const handleFitToScreen = () => fitView({ duration: 200, nodes: getSelectedNodes() });
    const handleZoomIn = () => zoomIn({ duration: 200 });
    const handleZoomOut = () => zoomOut({ duration: 200 });
    const handleShare = () => setState((prevState) => ({ ...prevState, dialogOpen: "Share" }));
    const handleCloseDialog = () => setState((prevState) => ({ ...prevState, dialogOpen: null }));
    const { fadeDiagramElements } = useFadeDiagramElements();
    const { hideDiagramElements } = useHideDiagramElements();
    const { pinDiagramElements } = usePinDiagramElements();
    const onUnfadeAll = () => fadeDiagramElements([...getAllElementsIds()], false);
    const onUnhideAll = () => hideDiagramElements([...getAllElementsIds()], false);
    const onUnpinAll = () => pinDiagramElements([...getAllElementsIds()], false);
    const { exportToImage } = useExportToImage();
    const { editingContextId, diagramId } = useContext(DiagramContext);
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Panel, { position: "top-left", children: /* @__PURE__ */ jsxs(Paper, { children: [
        fullscreen ? /* @__PURE__ */ jsx(Tooltip, { title: t("exitFullScreen"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("exitFullScreen"), onClick: () => onFullscreen(false), children: /* @__PURE__ */ jsx(FullscreenExitIcon, {}) }) }) : /* @__PURE__ */ jsx(Tooltip, { title: t("toggleFullScreen"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("toggleFullScreen"), onClick: () => onFullscreen(true), children: /* @__PURE__ */ jsx(FullscreenIcon, {}) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("fitToScreen"), children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("fitToScreen"),
            onClick: handleFitToScreen,
            "data-testid": "fit-to-screen",
            children: /* @__PURE__ */ jsx(AspectRatioIcon, {})
          }
        ) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("zoomIn"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("zoomIn"), onClick: handleZoomIn, children: /* @__PURE__ */ jsx(ZoomInIcon, {}) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("zoomOut"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("zoomOut"), onClick: handleZoomOut, children: /* @__PURE__ */ jsx(ZoomOutIcon, {}) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("shareDiagram"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("shareDiagram"), onClick: handleShare, "data-testid": "share", children: /* @__PURE__ */ jsx(ShareIcon, {}) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("exportToSvg"), children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("exportToSvg"),
            onClick: exportToImage,
            "data-testid": "export-diagram-to-svg",
            children: /* @__PURE__ */ jsx(ImageIcon, {})
          }
        ) }),
        snapToGrid ? /* @__PURE__ */ jsx(Tooltip, { title: t("exitSnapToGrid"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("exitSnapToGrid"), onClick: () => onSnapToGrid(false), children: /* @__PURE__ */ jsx(GridOffIcon, {}) }) }) : /* @__PURE__ */ jsx(Tooltip, { title: t("toggleSnapToGrid"), children: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": t("toggleSnapToGrid"), onClick: () => onSnapToGrid(true), children: /* @__PURE__ */ jsx(GridOnIcon, {}) }) }),
        helperLines ? /* @__PURE__ */ jsx(Tooltip, { title: t("hideHelperLines"), children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("hideHelperLines"),
            onClick: () => onHelperLines(false),
            "data-testid": "hide-helper-lines",
            children: /* @__PURE__ */ jsx(HelperLinesIconOff, {})
          }
        ) }) : /* @__PURE__ */ jsx(Tooltip, { title: t("showHelperLines"), children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("showHelperLines"),
            onClick: () => onHelperLines(true),
            "data-testid": "show-helper-lines",
            children: /* @__PURE__ */ jsx(HelperLinesIcon, {})
          }
        ) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("arrangeAll"), children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("arrangeAll"),
            onClick: () => {
              setState((prevState) => ({
                ...prevState,
                arrangeAllInProgress: true
              }));
              arrangeAll().then(
                () => setState((prevState) => ({
                  ...prevState,
                  arrangeAllDone: true,
                  arrangeAllInProgress: false
                }))
              );
            },
            "data-testid": "arrange-all",
            disabled: readOnly,
            children: state.arrangeAllInProgress ? /* @__PURE__ */ jsx(CircularProgress, { size: "24px", "data-testid": "arrange-all-circular-loading" }) : /* @__PURE__ */ jsx(AccountTreeIcon, {})
          }
        ) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("revealHidden"), children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("revealHidden"),
            onClick: onUnhideAll,
            "data-testid": "reveal-hidden-elements",
            disabled: readOnly,
            children: /* @__PURE__ */ jsx(VisibilityOffIcon, {})
          }
        ) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("revealFaded"), children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("revealFaded"),
            onClick: onUnfadeAll,
            "data-testid": "reveal-faded-elements",
            disabled: readOnly,
            children: /* @__PURE__ */ jsx(TonalityIcon, {})
          }
        ) }) }),
        /* @__PURE__ */ jsx(Tooltip, { title: t("unpinAll"), children: /* @__PURE__ */ jsx("span", { children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            "aria-label": t("unpinAll"),
            onClick: onUnpinAll,
            "data-testid": "unpin-all-elements",
            disabled: readOnly,
            children: /* @__PURE__ */ jsx(UnpinIcon, {})
          }
        ) }) }),
        diagramPanelActionComponents.map(({ Component: DiagramPanelActionComponent }, index) => /* @__PURE__ */ jsx(DiagramPanelActionComponent, { editingContextId, diagramId }, index))
      ] }) }),
      state.dialogOpen === "Share" ? /* @__PURE__ */ jsx(
        ShareRepresentationModal,
        {
          editingContextId,
          representationId: diagramId,
          onClose: handleCloseDialog
        }
      ) : null
    ] });
  }
);
var GQLReconnectKind = /* @__PURE__ */ ((GQLReconnectKind2) => {
  GQLReconnectKind2["SOURCE"] = "SOURCE";
  GQLReconnectKind2["TARGET"] = "TARGET";
  return GQLReconnectKind2;
})(GQLReconnectKind || {});
const reconnectEdgeMutation = gql`
  mutation reconnectEdge($input: ReconnectEdgeInput!) {
    reconnectEdge(input: $input) {
      __typename
      ... on ErrorPayload {
        messages {
          body
          level
        }
      }
      ... on SuccessPayload {
        messages {
          body
          level
        }
      }
    }
  }
`;
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload = (payload) => payload.__typename === "SuccessPayload";
const useReconnectEdge = () => {
  const { t: coreT } = useTranslation("siriusComponentsCore");
  const { addErrorMessage, addMessages } = useMultiToast();
  const { diagramId, editingContextId, readOnly } = useContext(DiagramContext);
  const [updateEdgeEnd, { data: reconnectEdgeData, error: reconnectEdgeError }] = useMutation(reconnectEdgeMutation);
  const handleReconnectEdge = useCallback(
    (edgeId, newEdgeEndId, reconnectEdgeKind) => {
      const input = {
        id: crypto.randomUUID(),
        editingContextId,
        representationId: diagramId,
        edgeId,
        newEdgeEndId,
        reconnectEdgeKind,
        newEdgeEndPosition: { x: 0, y: 0 }
      };
      if (!readOnly) {
        updateEdgeEnd({ variables: { input } });
      }
    },
    [editingContextId, diagramId, readOnly, updateEdgeEnd]
  );
  useEffect(() => {
    if (reconnectEdgeError) {
      addErrorMessage(coreT("errors.unexpected"));
    }
    if (reconnectEdgeData) {
      const { reconnectEdge: reconnectEdge2 } = reconnectEdgeData;
      if (isErrorPayload(reconnectEdge2) || isSuccessPayload(reconnectEdge2)) {
        addMessages(reconnectEdge2.messages);
      }
    }
  }, [coreT, reconnectEdgeData, reconnectEdgeError]);
  const reconnectEdge = useCallback(
    (oldEdge, newConnection) => {
      const edgeId = oldEdge.id;
      let reconnectEdgeKind = GQLReconnectKind.TARGET;
      let newEdgeTargetId = newConnection.target;
      if (oldEdge.target === newConnection.target) {
        reconnectEdgeKind = GQLReconnectKind.SOURCE;
        newEdgeTargetId = newConnection.source;
      }
      if (newEdgeTargetId) {
        handleReconnectEdge(edgeId, newEdgeTargetId, reconnectEdgeKind);
      }
    },
    [handleReconnectEdge]
  );
  return { reconnectEdge };
};
const isListData = (node) => node.type === "listNode";
const getLeftRightBorderWidth = (resizedNode) => {
  let borderLeftWidth = 1;
  if (resizedNode.data.style.borderLeftWidth) {
    if (typeof resizedNode.data.style.borderLeftWidth === "number") {
      borderLeftWidth = resizedNode.data.style.borderLeftWidth;
    } else {
      borderLeftWidth = parseFloat(resizedNode.data.style.borderLeftWidth);
    }
  }
  let borderRightWidth = 1;
  if (resizedNode.data.style.borderRightWidth) {
    if (typeof resizedNode.data.style.borderRightWidth === "number") {
      borderRightWidth = resizedNode.data.style.borderRightWidth;
    } else {
      borderRightWidth = parseFloat(resizedNode.data.style.borderRightWidth);
    }
  }
  return borderLeftWidth + borderRightWidth;
};
const applyResizeToListContain = (resizedNode, nodes, change) => {
  const newChanges = [];
  if (isListData(resizedNode)) {
    const borderWidth = getLeftRightBorderWidth(resizedNode);
    nodes.forEach((node) => {
      var _a, _b;
      if (node.parentNode === resizedNode.id && ((_a = change.dimensions) == null ? void 0 : _a.width)) {
        newChanges.push({
          id: node.id,
          type: "dimensions",
          resizing: true,
          updateStyle: true,
          dimensions: { width: ((_b = change.dimensions) == null ? void 0 : _b.width) - borderWidth, height: node.height ?? 0 }
        });
        newChanges.push(...applyResizeToListContain(node, nodes, change));
      }
    });
  }
  return newChanges;
};
const isResize = (change) => change.type === "dimensions" && (change.resizing ?? false);
const useResizeChange = () => {
  const { getNodes } = useStore();
  const transformResizeListNodeChanges = useCallback(
    (changes) => {
      const newChanges = [];
      const updatedChanges = changes.map((change) => {
        if (isResize(change)) {
          const resizedNode = getNodes().find((node) => change.id === node.id);
          if (resizedNode) {
            newChanges.push(...applyResizeToListContain(resizedNode, getNodes(), change));
          }
        }
        return change;
      });
      return [...newChanges, ...updatedChanges];
    },
    [getNodes]
  );
  return { transformResizeListNodeChanges };
};
const useDiagramSelection = (onShiftSelection) => {
  const { selection, setSelection } = useSelection();
  const [shiftSelection, setShiftSelection] = useState([]);
  const { fitView } = useReactFlow();
  const { getNodes, setNodes, getEdges, setEdges } = useStore();
  useEffect(() => {
    const diagramElementIds = [
      ...getNodes().map((node) => node.data.targetObjectId),
      ...getEdges().map((edge) => {
        var _a;
        return ((_a = edge.data) == null ? void 0 : _a.targetObjectId) ?? "";
      })
    ];
    const selectionDiagramEntryIds = selection.entries.map((entry) => entry.id).filter((id) => diagramElementIds.includes(id)).sort((id1, id2) => id1.localeCompare(id2));
    const selectedDiagramElementIds = [...getNodes(), ...getEdges()].filter((element) => element.selected).map((element) => {
      var _a;
      return ((_a = element.data) == null ? void 0 : _a.targetObjectId) ?? "";
    }).sort((id1, id2) => id1.localeCompare(id2));
    if (JSON.stringify(selectionDiagramEntryIds) !== JSON.stringify(selectedDiagramElementIds)) {
      const newNodeSelection = getNodes().map((node) => {
        return { ...node, selected: selectionDiagramEntryIds.includes(node.data.targetObjectId) };
      });
      const newEdgeSelection = getEdges().map((edge) => {
        return { ...edge, selected: selectionDiagramEntryIds.includes(edge.data ? edge.data.targetObjectId : "") };
      });
      setNodes(newNodeSelection);
      setEdges(newEdgeSelection);
      const fitViewNodes = newNodeSelection.filter((node) => {
        return node.selected || newEdgeSelection.filter((edge) => edge.selected).flatMap((edge) => [edge.source, edge.target]).includes(node.id);
      });
      fitView({ nodes: fitViewNodes, maxZoom: 1.5, duration: 1e3 });
    }
  }, [selection]);
  const onSelectionChange = useCallback(
    ({ nodes, edges }) => {
      const diagramElementIds = [
        ...getNodes().map((node) => node.data.targetObjectId),
        ...getEdges().map((edge) => {
          var _a;
          return ((_a = edge.data) == null ? void 0 : _a.targetObjectId) ?? "";
        })
      ];
      const selectionEntries = [...nodes, ...edges].filter((element) => element.selected).map((node) => {
        const { targetObjectId, targetObjectKind, targetObjectLabel } = node.data;
        return {
          id: targetObjectId,
          kind: targetObjectKind,
          label: targetObjectLabel
        };
      });
      const selectionDiagramEntryIds = selection.entries.map((selectionEntry) => selectionEntry.id).filter((id) => diagramElementIds.includes(id)).sort((id1, id2) => id1.localeCompare(id2));
      const selectedDiagramElementIds = selectionEntries.map((entry) => entry.id).sort((id1, id2) => id1.localeCompare(id2));
      if (JSON.stringify(selectedDiagramElementIds) !== JSON.stringify(selectionDiagramEntryIds)) {
        if (onShiftSelection) {
          setShiftSelection(selectionEntries);
        } else {
          setSelection({ entries: selectionEntries });
        }
      }
    },
    [selection, onShiftSelection]
  );
  useOnSelectionChange({
    onChange: onSelectionChange
  });
  useEffect(() => {
    if (shiftSelection.length > 0 && !onShiftSelection) {
      setSelection({ entries: shiftSelection });
      setShiftSelection([]);
    }
  }, [onShiftSelection]);
};
const useShiftSelection = () => {
  const [onShiftSelection, setShiftSelection] = useState(false);
  return {
    onShiftSelection,
    setShiftSelection
  };
};
const useSnapToGrid = () => {
  const [state, setState] = useState(false);
  return {
    snapToGrid: state,
    onSnapToGrid: setState
  };
};
const GRID_STEP = 10;
const DiagramRenderer = memo(({ diagramRefreshedEventPayload }) => {
  const { readOnly } = useContext(DiagramContext);
  const { diagramDescription } = useDiagramDescription();
  const { getEdges, onEdgesChange, getNodes, setEdges, setNodes } = useStore();
  const nodes = getNodes();
  const edges = getEdges();
  const { onDirectEdit } = useDiagramDirectEdit();
  const { onDelete } = useDiagramDelete();
  const ref = useRef(null);
  const { layout: layout2 } = useLayout();
  const { synchronizeLayoutData } = useSynchronizeLayoutData();
  const {
    onDiagramBackgroundClick,
    onDiagramElementClick: diagramPaletteOnDiagramElementClick,
    hideDiagramPalette,
    isOpened: isDiagramPaletteOpened
  } = useDiagramPalette();
  const {
    onDiagramElementClick: elementPaletteOnDiagramElementClick,
    hideDiagramElementPalette,
    isOpened: isDiagramElementPaletteOpened
  } = useDiagramElementPalette();
  const {
    onDiagramElementClick: groupPaletteOnDiagramElementClick,
    hideGroupPalette,
    position: groupPalettePosition,
    isOpened: isGroupPaletteOpened,
    refElementId: groupPaletteRefElementId
  } = useGroupPalette();
  const { onConnect, onConnectStart, onConnectEnd } = useConnector();
  const { reconnectEdge } = useReconnectEdge();
  const { onDrop, onDragOver } = useDrop();
  const { onNodeDragStart, onNodeDrag, onNodeDragStop } = useDropNode();
  const { backgroundColor, largeGridColor, smallGridColor } = useDropDiagramStyle();
  const { nodeTypes: nodeTypes2 } = useNodeType();
  const { nodeConverters } = useContext(NodeTypeContext);
  const { fitToScreen } = useInitialFitToScreen();
  const { setSelection } = useSelection();
  useEffect(() => {
    const { diagram, cause } = diagramRefreshedEventPayload;
    const convertedDiagram = convertDiagram(diagram, nodeConverters, diagramDescription);
    const selectedNodeIds = nodes.filter((node) => node.selected).map((node) => node.id);
    const selectedEdgeIds = edges.filter((edge) => edge.selected).map((edge) => edge.id);
    if (cause === "layout") {
      convertedDiagram.nodes.filter((node) => selectedNodeIds.includes(node.id)).forEach((node) => node.selected = true);
      convertedDiagram.edges.filter((edge) => selectedEdgeIds.includes(edge.id)).forEach((edge) => edge.selected = true);
      setNodes(convertedDiagram.nodes);
      setEdges(convertedDiagram.edges);
      fitToScreen();
    } else if (cause === "refresh") {
      const previousDiagram = {
        nodes,
        edges
      };
      layout2(previousDiagram, convertedDiagram, diagramRefreshedEventPayload.referencePosition, (laidOutDiagram) => {
        laidOutDiagram.nodes.filter((node) => selectedNodeIds.includes(node.id)).forEach((node) => node.selected = true);
        laidOutDiagram.edges.filter((edge) => selectedEdgeIds.includes(edge.id)).forEach((edge) => edge.selected = true);
        setNodes(laidOutDiagram.nodes);
        setEdges(laidOutDiagram.edges);
        closeAllPalettes();
        synchronizeLayoutData(diagramRefreshedEventPayload.id, laidOutDiagram);
      });
    }
  }, [diagramRefreshedEventPayload, diagramDescription]);
  useEffect(() => {
    setEdges((oldEdges) => oldEdges.map((edge) => ({ ...edge, updatable: !!edge.selected })));
  }, [edges.map((edge) => edge.id + edge.selected).join()]);
  const { onShiftSelection, setShiftSelection } = useShiftSelection();
  useDiagramSelection(onShiftSelection);
  const { transformBorderNodeChanges } = useBorderChange();
  const { transformUndraggableListNodeChanges, applyMoveChange } = useMoveChange();
  const { transformResizeListNodeChanges } = useResizeChange();
  const { applyHandleChange } = useHandleChange();
  const { layoutOnBoundsChange } = useLayoutOnBoundsChange(diagramRefreshedEventPayload.id);
  const { filterReadOnlyChanges } = useFilterReadOnlyChanges();
  const {
    helperLinesEnabled,
    setHelperLinesEnabled,
    horizontalHelperLine,
    verticalHelperLine,
    applyHelperLines,
    resetHelperLines
  } = useHelperLines();
  const handleNodesChange = useCallback(
    (changes) => {
      var _a;
      const noReadOnlyChanges = filterReadOnlyChanges(changes);
      const isResetChange = changes.find((change) => change.type === "reset");
      if (isResetChange || noReadOnlyChanges.length === 1 && ((_a = noReadOnlyChanges[0]) == null ? void 0 : _a.type) === "dimensions" && typeof noReadOnlyChanges[0].resizing !== "boolean") {
        setNodes((oldNodes) => applyNodeChanges(noReadOnlyChanges, oldNodes));
      } else {
        setNodes((oldNodes) => {
          resetHelperLines(changes);
          let transformedNodeChanges = transformBorderNodeChanges(noReadOnlyChanges, oldNodes);
          transformedNodeChanges = transformUndraggableListNodeChanges(transformedNodeChanges);
          transformedNodeChanges = transformResizeListNodeChanges(transformedNodeChanges);
          transformedNodeChanges = applyHelperLines(transformedNodeChanges);
          let newNodes = applyNodeChanges(transformedNodeChanges, oldNodes);
          newNodes = applyMoveChange(transformedNodeChanges, newNodes);
          newNodes = applyHandleChange(transformedNodeChanges, newNodes);
          layoutOnBoundsChange(transformedNodeChanges, newNodes);
          return newNodes;
        });
      }
    },
    [setNodes, layoutOnBoundsChange, getNodes, getEdges]
  );
  const handleEdgesChange = useCallback(
    (changes) => {
      onEdgesChange(changes);
    },
    [onEdgesChange]
  );
  const handlePaneClick = useCallback(
    (event) => {
      const {
        diagram: {
          id,
          metadata: { kind }
        }
      } = diagramRefreshedEventPayload;
      const selection = {
        entries: [
          {
            id,
            kind
          }
        ]
      };
      setSelection(selection);
      onDiagramBackgroundClick(event);
    },
    [setSelection]
  );
  const onKeyDown = useCallback((event) => {
    onDirectEdit(event);
    onDelete(event);
  }, []);
  const { snapToGrid, onSnapToGrid } = useSnapToGrid();
  const closeAllPalettes = useCallback(() => {
    hideDiagramPalette();
    hideDiagramElementPalette();
    hideGroupPalette();
  }, [hideDiagramPalette, hideDiagramElementPalette, hideGroupPalette]);
  const handleMove = useCallback(() => {
    closeAllPalettes();
  }, [isDiagramElementPaletteOpened, isDiagramPaletteOpened]);
  const handleDiagramElementCLick = useCallback(
    (event, element) => {
      diagramPaletteOnDiagramElementClick();
      elementPaletteOnDiagramElementClick(event, element);
      groupPaletteOnDiagramElementClick(event, element);
    },
    [elementPaletteOnDiagramElementClick, diagramPaletteOnDiagramElementClick, groupPaletteOnDiagramElementClick]
  );
  const handleSelectionStart = useCallback(() => {
    closeAllPalettes();
    setShiftSelection(true);
  }, [closeAllPalettes, setShiftSelection]);
  const handleSelectionEnd = useCallback(
    (event) => {
      groupPaletteOnDiagramElementClick(event, null);
      setShiftSelection(false);
    },
    [groupPaletteOnDiagramElementClick, setShiftSelection]
  );
  const { onNodeMouseEnter, onNodeMouseLeave } = useNodeHover();
  const handleNodeDrag = useCallback(
    (event, node, nodes2) => {
      onNodeDrag(event, node, nodes2);
      closeAllPalettes();
    },
    [onNodeDrag, closeAllPalettes]
  );
  const { nodesDraggable } = useNodesDraggable();
  let reactFlowProps = {
    nodes,
    nodeTypes: nodeTypes2,
    onNodesChange: handleNodesChange,
    edges,
    edgeTypes,
    edgesUpdatable: !readOnly,
    onKeyDown,
    onConnect,
    onConnectStart,
    onConnectEnd,
    connectionLineComponent: ConnectionLine,
    onEdgesChange: handleEdgesChange,
    onEdgeUpdate: reconnectEdge,
    onPaneClick: handlePaneClick,
    onEdgeClick: handleDiagramElementCLick,
    onNodeClick: handleDiagramElementCLick,
    onMove: handleMove,
    nodeDragThreshold: 1,
    onDrop,
    onDragOver,
    onNodeDrag: handleNodeDrag,
    onNodeDragStart,
    onNodeDragStop,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onSelectionStart: handleSelectionStart,
    onSelectionEnd: handleSelectionEnd,
    maxZoom: 40,
    minZoom: 0.1,
    snapToGrid,
    snapGrid: useMemo(() => [GRID_STEP, GRID_STEP], []),
    connectionMode: ConnectionMode.Loose,
    zoomOnDoubleClick: false,
    connectionLineType: ConnectionLineType.SmoothStep,
    nodesDraggable,
    children: /* @__PURE__ */ jsxs(Fragment, { children: [
      snapToGrid ? /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(
          Background,
          {
            id: "small-grid",
            style: { backgroundColor },
            variant: BackgroundVariant.Lines,
            gap: GRID_STEP,
            color: smallGridColor
          }
        ),
        /* @__PURE__ */ jsx(
          Background,
          {
            id: "large-grid",
            variant: BackgroundVariant.Lines,
            gap: 10 * GRID_STEP,
            offset: 1,
            color: largeGridColor
          }
        )
      ] }) : /* @__PURE__ */ jsx(Background, { style: { backgroundColor }, color: backgroundColor }),
      /* @__PURE__ */ jsx(
        DiagramPanel,
        {
          snapToGrid,
          onSnapToGrid,
          helperLines: helperLinesEnabled,
          onHelperLines: setHelperLinesEnabled,
          reactFlowWrapper: ref
        }
      ),
      /* @__PURE__ */ jsx(
        GroupPalette,
        {
          x: groupPalettePosition == null ? void 0 : groupPalettePosition.x,
          y: groupPalettePosition == null ? void 0 : groupPalettePosition.y,
          isOpened: isGroupPaletteOpened,
          refElementId: groupPaletteRefElementId,
          hidePalette: hideGroupPalette
        }
      ),
      /* @__PURE__ */ jsx(
        DiagramPalette,
        {
          diagramElementId: diagramRefreshedEventPayload.diagram.id,
          targetObjectId: diagramRefreshedEventPayload.diagram.targetObjectId
        }
      ),
      diagramDescription.debug ? /* @__PURE__ */ jsx(DebugPanel, { reactFlowWrapper: ref }) : null,
      /* @__PURE__ */ jsx(ConnectorContextualMenu, {}),
      helperLinesEnabled ? /* @__PURE__ */ jsx(HelperLines, { horizontal: horizontalHelperLine, vertical: verticalHelperLine }) : null
    ] })
  };
  const { data: reactFlowPropsCustomizers } = useData(
    diagramRendererReactFlowPropsCustomizerExtensionPoint
  );
  reactFlowPropsCustomizers.forEach((customizer) => {
    reactFlowProps = customizer(reactFlowProps);
  });
  return /* @__PURE__ */ jsx(ReactFlow, { ...reactFlowProps, ref });
});
const markerSelector = (state) => {
  const ids = [];
  const initialMarkerProps = [];
  const reducer = (allMarkerProps, edge) => {
    [edge.markerStart, edge.markerEnd].forEach((marker) => {
      var _a;
      if (marker && typeof marker === "string" && ((_a = edge.style) == null ? void 0 : _a.stroke)) {
        if (!ids.includes(marker)) {
          allMarkerProps.push(
            {
              id: marker,
              edgeColor: edge.style.stroke
            },
            {
              id: `${marker}--selected`,
              edgeColor: edge.style.stroke
            }
          );
          ids.push(marker);
          ids.push(`${marker}--selected`);
        }
      }
    });
    return allMarkerProps;
  };
  return state.edges.reduce(reducer, initialMarkerProps).sort((firstMarkerProps, secondMarkerProps) => firstMarkerProps.id.localeCompare(secondMarkerProps.id));
};
const equalityFunction = (oldMarkerProps, newMarkerProps) => {
  return !(oldMarkerProps.length !== newMarkerProps.length || oldMarkerProps.some((edge, index) => {
    var _a;
    return !!newMarkerProps[index] && edge.id !== ((_a = newMarkerProps[index]) == null ? void 0 : _a.id);
  }));
};
const getMarker = (markerProps) => {
  const markerType = markerProps.id.split("--")[0];
  switch (markerType) {
    case "None":
      return null;
    case "OutputArrow":
      return /* @__PURE__ */ createElement(OutputArrow, { ...markerProps, key: markerProps.id });
    case "InputArrow":
      return /* @__PURE__ */ createElement(InputArrow, { ...markerProps, key: markerProps.id });
    case "OutputClosedArrow":
      return /* @__PURE__ */ createElement(OutputClosedArrow, { ...markerProps, key: markerProps.id });
    case "InputClosedArrow":
      return /* @__PURE__ */ createElement(InputClosedArrow, { ...markerProps, key: markerProps.id });
    case "OutputFillClosedArrow":
      return /* @__PURE__ */ createElement(OutputFillClosedArrow, { ...markerProps, key: markerProps.id });
    case "InputFillClosedArrow":
      return /* @__PURE__ */ createElement(InputFillClosedArrow, { ...markerProps, key: markerProps.id });
    case "Diamond":
      return /* @__PURE__ */ createElement(Diamond, { ...markerProps, key: markerProps.id });
    case "FillDiamond":
      return /* @__PURE__ */ createElement(FillDiamond, { ...markerProps, key: markerProps.id });
    case "InputArrowWithDiamond":
      return /* @__PURE__ */ createElement(InputArrowWithDiamond, { ...markerProps, key: markerProps.id });
    case "InputArrowWithFillDiamond":
      return /* @__PURE__ */ createElement(InputArrowWithFillDiamond, { ...markerProps, key: markerProps.id });
    case "Circle":
      return /* @__PURE__ */ createElement(Circle, { ...markerProps, key: markerProps.id });
    case "FillCircle":
      return /* @__PURE__ */ createElement(FillCircle, { ...markerProps, key: markerProps.id });
    case "CrossedCircle":
      return /* @__PURE__ */ createElement(CrossedCircle, { ...markerProps, key: markerProps.id });
    case "ClosedArrowWithVerticalBar":
      return /* @__PURE__ */ createElement(ClosedArrowWithVerticalBar, { ...markerProps, key: markerProps.id });
    case "ClosedArrowWithDots":
      return /* @__PURE__ */ createElement(ClosedArrowWithDots, { ...markerProps, key: markerProps.id });
    default:
      return /* @__PURE__ */ createElement(InputFillClosedArrow, { ...markerProps, key: markerProps.id });
  }
};
const OutputArrowPath = "m 8.75 1 L 1.5 5 L 8.75 9";
const InputArrowPath = "m 1 1 L 8 5 L 1 9";
const OutputClosedArrowPath = "m 8.5 1 L 1.5 5 L 8.5 9 L 8.5 1 z";
const InputClosedArrowPath = "m 1 1 L 8 5 L 1 9 L 1 1 z";
const DiamondPath = "m 8 1 L 1 5 L 8 9 L 15 5 L 8 1 z";
const buildMarkerAttributes = (id, markerWidth, markerHeight, refX, refY) => {
  return {
    id,
    markerUnits: "strokeWidth",
    markerWidth,
    markerHeight,
    refX,
    refY,
    orient: "auto-start-reverse"
  };
};
const buildArrow = (id, path, edgeColor, fillColor) => {
  return /* @__PURE__ */ jsx("marker", { ...buildMarkerAttributes(id, 10, 10, 8, 5), children: /* @__PURE__ */ jsx("path", { fill: fillColor ? fillColor : "white", d: path, stroke: edgeColor, strokeWidth: 1 }) });
};
const buildDiamond = (id, path, edgeColor, fill) => {
  return /* @__PURE__ */ jsx("marker", { ...buildMarkerAttributes(id, 16, 10, 15, 5), children: /* @__PURE__ */ jsx("path", { fill: fill ? edgeColor : "white", d: path, stroke: edgeColor, strokeWidth: 1 }) });
};
const buildCircle = (id, edgeColor, fill) => {
  return /* @__PURE__ */ jsx("marker", { ...buildMarkerAttributes(id, 10, 10, 9, 5), children: /* @__PURE__ */ jsx("circle", { r: 4.5, cx: 5, cy: 5, fill: fill ? edgeColor : "white", stroke: edgeColor, strokeWidth: 1 }) });
};
const MarkerDefinitions = () => {
  const selector = useCallback(markerSelector, []);
  const allMarkerProps = useStore$1(selector, equalityFunction);
  return /* @__PURE__ */ jsx("svg", { id: "edge-markers", style: { position: "absolute", zIndex: -1, top: "0px" }, children: /* @__PURE__ */ jsx("defs", { id: "edge-markers-defs", children: allMarkerProps.map((markerProps) => getMarker(markerProps)) }) });
};
const OutputArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, OutputArrowPath, strokeColor, theme2.palette.background.default);
};
const InputArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, InputArrowPath, strokeColor, "transparent");
};
const OutputClosedArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, OutputClosedArrowPath, strokeColor, theme2.palette.background.default);
};
const InputClosedArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, InputClosedArrowPath, strokeColor, theme2.palette.background.default);
};
const OutputFillClosedArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, OutputClosedArrowPath, strokeColor, strokeColor);
};
const InputFillClosedArrow = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildArrow(id, InputClosedArrowPath, strokeColor, strokeColor);
};
const Diamond = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildDiamond(id, DiamondPath, strokeColor, false);
};
const FillDiamond = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildDiamond(id, DiamondPath, strokeColor, true);
};
const Circle = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildCircle(id, strokeColor, false);
};
const FillCircle = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return buildCircle(id, strokeColor, true);
};
const CrossedCircle = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return /* @__PURE__ */ jsxs("marker", { ...buildMarkerAttributes(id, 10, 10, 9, 5), children: [
    /* @__PURE__ */ jsx("circle", { r: 4.5, cx: 5, cy: 5, stroke: strokeColor, fill: theme2.palette.background.default }),
    /* @__PURE__ */ jsx("path", { d: "M 1 5 L 9 5", stroke: strokeColor, strokeWidth: 1 }),
    /* @__PURE__ */ jsx("path", { d: "m 5 1 L 5 9", stroke: strokeColor, strokeWidth: 1 })
  ] });
};
const InputArrowWithDiamond = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return /* @__PURE__ */ jsxs("marker", { ...buildMarkerAttributes(id, 24, 10, 23, 5), children: [
    /* @__PURE__ */ jsx("path", { d: InputArrowPath, stroke: strokeColor, fill: "transparent", strokeWidth: 1 }),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "m 16 1 L 9 5 L 16 9 L 23 5 L 16 1 z",
        stroke: strokeColor,
        fill: theme2.palette.background.default,
        strokeWidth: 1
      }
    )
  ] });
};
const InputArrowWithFillDiamond = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return /* @__PURE__ */ jsxs("marker", { ...buildMarkerAttributes(id, 24, 10, 23, 5), children: [
    /* @__PURE__ */ jsx("path", { d: InputArrowPath, stroke: strokeColor, fill: "transparent", strokeWidth: 1 }),
    /* @__PURE__ */ jsx("path", { d: "m 16 1 L 9 5 L 16 9 L 23 5 L 16 1 z", stroke: strokeColor, fill: strokeColor, strokeWidth: 1 })
  ] });
};
const ClosedArrowWithVerticalBar = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return /* @__PURE__ */ jsxs("marker", { ...buildMarkerAttributes(id, 13, 10, 12, 5), children: [
    /* @__PURE__ */ jsx(
      "path",
      {
        strokeWidth: 1,
        fill: theme2.palette.background.default,
        d: "m 5 1 L 12 5 L 5 9 L 5 1 z",
        stroke: strokeColor
      }
    ),
    /* @__PURE__ */ jsx("path", { fill: strokeColor, d: "m 1 10 V 0", stroke: strokeColor })
  ] });
};
const ClosedArrowWithDots = ({ id, edgeColor }) => {
  const theme2 = useTheme();
  const strokeColor = getSelectedColor(id, edgeColor, theme2);
  return /* @__PURE__ */ jsxs("marker", { ...buildMarkerAttributes(id, 13, 10, 12, 5), children: [
    /* @__PURE__ */ jsx(
      "path",
      {
        strokeWidth: 1,
        fill: theme2.palette.background.default,
        d: "m 5 1 L 12 5 L 5 9 L 5 1 z",
        stroke: strokeColor
      }
    ),
    /* @__PURE__ */ jsx("circle", { r: 1, cx: 1, cy: 2, fill: strokeColor, stroke: "none" }),
    /* @__PURE__ */ jsx("circle", { r: 1, cx: 1, cy: 8, fill: strokeColor, stroke: "none" })
  ] });
};
const getSelectedColor = (id, edgeColor, theme2) => {
  const selectedColor = id.endsWith("--selected") && theme2.palette.selected ? theme2.palette.selected : getCSSColor(edgeColor, theme2);
  return selectedColor;
};
const subscription = gql(diagramEventSubscription);
const getDiagramDescription = gql`
  query getDiagramDescription($editingContextId: ID!, $representationId: ID!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representation(representationId: $representationId) {
          description {
            ... on DiagramDescription {
              id
              nodeDescriptions {
                id
                userResizable
                keepAspectRatio
                childNodeDescriptionIds
                borderNodeDescriptionIds
              }
              childNodeDescriptionIds
              dropNodeCompatibility {
                droppedNodeDescriptionId
                droppableOnDiagram
                droppableOnNodeTypes
              }
              debug
              arrangeLayoutDirection
            }
          }
        }
      }
    }
  }
`;
const isDiagramRefreshedEventPayload = (payload) => payload.__typename === "DiagramRefreshedEventPayload";
const DiagramRepresentation = ({
  editingContextId,
  representationId,
  readOnly
}) => {
  const [state, setState] = useState({
    id: crypto.randomUUID(),
    diagramRefreshedEventPayload: null,
    payload: null,
    complete: false,
    message: null
  });
  const { addErrorMessage } = useMultiToast();
  const variables = {
    input: {
      id: state.id,
      editingContextId,
      diagramId: representationId
    }
  };
  const onData = ({ data }) => {
    if (data.data) {
      const { diagramEvent } = data.data;
      if (isDiagramRefreshedEventPayload(diagramEvent)) {
        setState((prevState) => ({ ...prevState, diagramRefreshedEventPayload: diagramEvent }));
      }
      setState((prevState) => ({ ...prevState, payload: diagramEvent }));
    }
  };
  const {
    loading: diagramDescriptionLoading,
    data: diagramDescriptionData,
    error: diagramDescriptionError
  } = useQuery(getDiagramDescription, {
    variables: {
      editingContextId,
      representationId
    },
    skip: state.diagramRefreshedEventPayload === null
  });
  useEffect(() => {
    if (!diagramDescriptionLoading) {
      setState((prevState) => ({
        ...prevState,
        diagramDescription: diagramDescriptionData == null ? void 0 : diagramDescriptionData.viewer.editingContext.representation.description
      }));
    }
    if (diagramDescriptionError) {
      const { message } = diagramDescriptionError;
      addErrorMessage(message);
    }
  }, [diagramDescriptionLoading, diagramDescriptionData, diagramDescriptionError]);
  const onComplete = () => {
    setState((prevState) => ({ ...prevState, diagramRefreshedEventPayload: null, complete: true }));
  };
  const { error } = useSubscription(subscription, {
    variables,
    fetchPolicy: "no-cache",
    onData,
    onComplete
  });
  const diagramDescription = diagramDescriptionData == null ? void 0 : diagramDescriptionData.viewer.editingContext.representation.description;
  if (state.message) {
    return /* @__PURE__ */ jsx("div", { children: state.message });
  }
  if (error) {
    return /* @__PURE__ */ jsx("div", { children: error.message });
  }
  if (state.complete) {
    return /* @__PURE__ */ jsx("div", { children: "The representation is not available anymore" });
  }
  if (!state.diagramRefreshedEventPayload || !diagramDescription) {
    return /* @__PURE__ */ jsx("div", {});
  }
  return /* @__PURE__ */ jsx(ReactFlowProvider, { children: /* @__PURE__ */ jsx(
    DiagramContext.Provider,
    {
      value: {
        editingContextId,
        diagramId: representationId,
        refreshEventPayloadId: state.diagramRefreshedEventPayload.id,
        payload: state.payload,
        readOnly
      },
      children: /* @__PURE__ */ jsx(DiagramDescriptionContext.Provider, { value: { diagramDescription }, children: /* @__PURE__ */ jsx(StoreContextProvider, { children: /* @__PURE__ */ jsx(DiagramDirectEditContextProvider, { children: /* @__PURE__ */ jsx(DiagramPaletteContextProvider, { children: /* @__PURE__ */ jsx(DiagramElementPaletteContextProvider, { children: /* @__PURE__ */ jsx(ConnectorContextProvider, { children: /* @__PURE__ */ jsx(DropNodeContextProvider, { children: /* @__PURE__ */ jsx(NodeContextProvider, { children: /* @__PURE__ */ jsxs(
        "div",
        {
          style: { display: "inline-block", position: "relative" },
          "data-representation-kind": "diagram",
          "data-representation-label": state.diagramRefreshedEventPayload.diagram.metadata.label,
          children: [
            /* @__PURE__ */ jsx(MarkerDefinitions, {}),
            /* @__PURE__ */ jsx(FullscreenContextProvider, { children: /* @__PURE__ */ jsx(DialogContextProvider, { children: /* @__PURE__ */ jsx(
              DiagramRenderer,
              {
                diagramRefreshedEventPayload: state.diagramRefreshedEventPayload
              },
              state.diagramRefreshedEventPayload.diagram.id
            ) }) })
          ]
        }
      ) }) }) }) }) }) }) }) })
    }
  ) });
};
export {
  AlignmentMap,
  BorderNodePosition,
  ConnectionCreationHandles,
  ConnectionHandles,
  ConnectionTargetHandle,
  DiagramContext,
  DiagramElementPalette,
  DiagramRepresentation,
  GQLViewModifier,
  Label,
  NodeContext,
  NodeTypeContext,
  NodeTypeContribution,
  applyRatioOnNewNodeSizeValue,
  computeBorderNodeExtents,
  computeBorderNodePositions,
  computeNodesBox,
  computePreviousPosition,
  computePreviousSize,
  convertHandles,
  convertInsideLabel,
  convertLabelStyle,
  convertLineStyle,
  convertOutsideLabels,
  diagramDialogContributionExtensionPoint,
  diagramPaletteToolExtensionPoint,
  diagramPanelActionExtensionPoint,
  diagramRendererReactFlowPropsCustomizerExtensionPoint,
  findBorderNodePosition,
  findNodeIndex,
  getBorderNodeExtent,
  getChildNodePosition,
  getChildren,
  getDefaultOrMinHeight,
  getDefaultOrMinWidth,
  getEastBorderNodeFootprintHeight,
  getHeaderHeightFootprint,
  getInsideLabelWidthConstraint,
  getNewlyAddedBorderNodePosition,
  getNorthBorderNodeFootprintWidth,
  getSouthBorderNodeFootprintWidth,
  getWestBorderNodeFootprintHeight,
  isDescendantOf,
  isEastBorderNode,
  isListLayoutStrategy,
  isNorthBorderNode,
  isSiblingOrDescendantOf,
  isSouthBorderNode,
  isWestBorderNode,
  setBorderNodesPosition,
  en as siriusComponentsDiagramsEn,
  ru as siriusComponentsDiagramsRu,
  useConnectorNodeStyle,
  useDialog,
  useDrop,
  useDropNode,
  useDropNodeStyle,
  useLayout,
  useRefreshConnectionHandles
};
