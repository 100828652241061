import "@mui/material/styles/styled";
import { amber, blue, blueGrey, brown, common, cyan, deepOrange, deepPurple, green, grey, indigo, lightBlue, lightGreen, lime, orange, pink, purple, red, teal, yellow } from "@mui/material/colors";
import React, { useContext, memo, useState, useEffect, useCallback, useRef, createElement } from "react";
import { jsx, Fragment, jsxs } from "react/jsx-runtime";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import WarningOutlined from "@mui/icons-material/WarningOutlined";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { gql, useQuery, useSubscription } from "@apollo/client";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { useMachine } from "@xstate/react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Machine, assign } from "xstate";
const muiColors = {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
};
const getCSSColor = (value2, theme2) => {
  let cssColor = value2;
  if (value2) {
    if (value2 === "theme.palette.primary.main") {
      cssColor = theme2.palette.primary.main;
    } else if (value2 === "theme.palette.primary.light") {
      cssColor = theme2.palette.primary.light;
    } else if (value2 === "theme.palette.primary.dark") {
      cssColor = theme2.palette.primary.dark;
    } else if (value2 === "theme.palette.secondary.main") {
      cssColor = theme2.palette.secondary.main;
    } else if (value2 === "theme.palette.secondary.light") {
      cssColor = theme2.palette.secondary.light;
    } else if (value2 === "theme.palette.secondary.dark") {
      cssColor = theme2.palette.secondary.dark;
    } else if (value2 === "theme.palette.text.primary") {
      cssColor = theme2.palette.text.primary;
    } else if (value2 === "theme.palette.text.disabled") {
      cssColor = theme2.palette.text.disabled;
    } else if (value2 === "theme.palette.error.main") {
      cssColor = theme2.palette.error.main;
    } else if (value2 === "theme.palette.error.light") {
      cssColor = theme2.palette.error.light;
    } else if (value2 === "theme.palette.error.dark") {
      cssColor = theme2.palette.error.dark;
    } else {
      const colorName = value2.substring(0, value2.indexOf("["));
      const colorValue = value2.substring(value2.indexOf("[") + 1, value2.length - 1);
      if (colorName && colorValue) {
        cssColor = muiColors[colorName][colorValue];
      }
    }
  }
  return cssColor;
};
const value$2 = {
  getRepresentationPath: () => ""
};
const RepresentationPathContext = React.createContext(value$2);
const value$1 = { httpOrigin: "" };
const ServerContext = React.createContext(value$1);
const value = {
  enqueueSnackbar: () => {
  }
};
const ToastContext = React.createContext(value);
const DRAG_SOURCES_TYPE = "sirius-component/drag/sources";
class ExtensionRegistry {
  constructor() {
    this.components = {};
    this.data = {};
  }
  addComponent(extensionPoint, extension) {
    if (!this.components[extensionPoint.identifier]) {
      this.components[extensionPoint.identifier] = [];
    }
    const componentExtensions = this.components[extensionPoint.identifier];
    if (componentExtensions) {
      componentExtensions.push(extension);
    }
  }
  findComponentById(extensionPoint) {
    const componentExtensions = this.components[extensionPoint.identifier];
    if (componentExtensions && componentExtensions[0]) {
      return componentExtensions[0];
    }
    return null;
  }
  findAllComponents(extensionPoint) {
    return this.components[extensionPoint.identifier] || [];
  }
  putData(extensionPoint, extension) {
    this.data[extensionPoint.identifier] = extension;
  }
  getData(extensionPoint) {
    return this.data[extensionPoint.identifier] ?? null;
  }
  addAll(extensionRegistry, extensionRegistryMergeStrategy) {
    Object.entries(extensionRegistry.components).forEach((entry) => {
      const extensionPointIdentifier = entry[0];
      const existingValues = this.components[extensionPointIdentifier];
      const newValues = entry[1];
      if (existingValues) {
        this.components[extensionPointIdentifier] = extensionRegistryMergeStrategy.mergeComponentExtensions(
          extensionPointIdentifier,
          existingValues,
          newValues
        );
      } else {
        this.components[extensionPointIdentifier] = newValues;
      }
    });
    Object.entries(extensionRegistry.data).forEach((entry) => {
      const extensionPointIdentifier = entry[0];
      const existingValues = this.data[extensionPointIdentifier];
      const newValues = entry[1];
      if (existingValues) {
        this.data[extensionPointIdentifier] = extensionRegistryMergeStrategy.mergeDataExtensions(
          extensionPointIdentifier,
          existingValues,
          newValues
        );
      } else {
        this.data[extensionPointIdentifier] = newValues;
      }
    });
  }
}
const ExtensionContext = React.createContext({
  registry: new ExtensionRegistry()
});
const ExtensionProvider = ({ children, registry }) => {
  return /* @__PURE__ */ jsx(ExtensionContext.Provider, { value: { registry }, children });
};
function useComponent(extensionPoint) {
  const { registry } = useContext(ExtensionContext);
  const componentExtension = registry.findComponentById(extensionPoint);
  if (!componentExtension) {
    const componentExtension2 = {
      identifier: "fallback_" + extensionPoint.identifier,
      Component: extensionPoint.FallbackComponent
    };
    return componentExtension2;
  }
  return componentExtension;
}
function useComponents(extensionPoint) {
  const { registry } = useContext(ExtensionContext);
  return registry.findAllComponents(extensionPoint);
}
function useData(extensionPoint) {
  const { registry } = useContext(ExtensionContext);
  const dataExtension = registry.getData(extensionPoint);
  if (dataExtension) {
    return dataExtension;
  }
  return {
    identifier: "fallback_" + extensionPoint.identifier,
    data: extensionPoint.fallback
  };
}
const IconOverlay = memo(
  ({ iconURL, alt, title, customIconWidth, customIconHeight, customIconStyle }) => {
    const iconWidth = customIconWidth || 16;
    const iconHeight = customIconHeight || 16;
    const { httpOrigin } = useContext(ServerContext);
    return /* @__PURE__ */ jsx(Fragment, { children: (iconURL == null ? void 0 : iconURL.length) > 0 && /* @__PURE__ */ jsx("div", { style: { position: "relative", width: iconWidth, height: iconHeight, ...customIconStyle }, children: iconURL.map((url, index) => /* @__PURE__ */ jsx(Tooltip, { title: title || "", children: /* @__PURE__ */ jsx(
      "img",
      {
        height: iconHeight,
        width: iconWidth,
        alt,
        src: httpOrigin + url,
        style: { zIndex: index, position: "absolute", top: 0, left: 0 }
      },
      index
    ) }, "tooltip_" + index)) }) });
  }
);
const siriusComponentsCore$1 = {
  messages: {
    loading: "Loading..."
  },
  errors: {
    unexpected: "An unexpected error has occurred, please refresh the page",
    fileTooLarge: "An unexpected error has occurred, the file uploaded may be too large"
  },
  share: {
    link: "Shareable link",
    copied: "(copied into the clipboard)"
  }
};
const en = {
  siriusComponentsCore: siriusComponentsCore$1
};
const siriusComponentsCore = {
  messages: {
    loading: "Загрузка..."
  },
  errors: {
    unexpected: "Произошла неожиданная ошибка, пожалуйста, обновите страницу",
    fileTooLarge: "Произошла неожиданная ошибка, возможно загружаемый файл слишком большой"
  },
  share: {
    link: "Ссылка",
    copied: "(скопирована в буфер обмена)"
  }
};
const ru = {
  siriusComponentsCore
};
const useConfirmationDialogStyles = makeStyles()((theme2) => ({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme2.spacing(2)
  },
  titleIcon: {
    color: theme2.palette.error.main,
    fontSize: "50px"
  },
  message: {
    whiteSpace: "pre-line",
    textAlign: "center"
  },
  checkbox: {
    "&:hover": {
      color: theme2.palette.error.main
    }
  },
  checkboxChecked: {
    color: theme2.palette.error.main
  },
  checkboxLabel: {
    color: theme2.palette.text.secondary
  },
  confirmButton: {
    backgroundColor: theme2.palette.error.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme2.palette.error.dark
    }
  }
}));
const ConfirmationDialog = ({
  open,
  title,
  message,
  buttonLabel,
  allowConfirmationDisabled,
  confirmationDisabled,
  onConfirmationDisabledChange,
  onConfirm,
  onCancel
}) => {
  const { classes } = useConfirmationDialogStyles();
  let confirmationDisabledElement = null;
  if (allowConfirmationDisabled) {
    confirmationDisabledElement = /* @__PURE__ */ jsx(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx(
          Checkbox,
          {
            "data-testid": "confirmation-dialog-checkbox-disabled",
            className: classes.checkbox,
            checked: confirmationDisabled,
            onChange: (event) => onConfirmationDisabledChange(event.target.checked),
            icon: /* @__PURE__ */ jsx(CheckBoxOutlineBlankIcon, { fontSize: "small" }),
            checkedIcon: /* @__PURE__ */ jsx(CheckBoxIcon, { className: classes.checkboxChecked, fontSize: "small" })
          }
        ),
        label: /* @__PURE__ */ jsx(Typography, { className: classes.checkboxLabel, variant: "body2", children: "Disable this confirmation dialog" })
      }
    );
  }
  return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsxs(Dialog, { open, onClose: onCancel, "aria-labelledby": "confirmation-dialog", "data-testid": "confirmation-dialog", children: [
    /* @__PURE__ */ jsxs("div", { className: classes.title, children: [
      /* @__PURE__ */ jsx(WarningOutlined, { className: classes.titleIcon }),
      /* @__PURE__ */ jsx(DialogTitle, { "data-testid": "confirmation-dialog-title", children: title })
    ] }),
    /* @__PURE__ */ jsxs(DialogContent, { children: [
      /* @__PURE__ */ jsx(DialogContentText, { className: classes.message, "data-testid": "confirmation-dialog-message", children: message }),
      confirmationDisabledElement
    ] }),
    /* @__PURE__ */ jsxs(DialogActions, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          "data-testid": "confirmation-dialog-button-ok",
          className: classes.confirmButton,
          onClick: onConfirm,
          variant: "contained",
          children: buttonLabel
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          "data-testid": "confirmation-dialog-button-cancel",
          onClick: onCancel,
          variant: "outlined",
          color: "secondary",
          autoFocus: true,
          children: "Cancel"
        }
      )
    ] })
  ] }) });
};
const defaultValue$1 = {
  showConfirmation: () => {
  }
};
const isLocalStorageAvailable = () => {
  const test = "localStorageTest";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (_) {
    return false;
  }
};
const ConfirmationDialogContext = React.createContext(defaultValue$1);
const ConfirmationDialogContextProvider = ({ children }) => {
  const localStorageKey = "sirius-confirmation-dialog-disabled";
  let allowConfirmationDisabled = false;
  let storedPreference = null;
  if (isLocalStorageAvailable()) {
    allowConfirmationDisabled = true;
    storedPreference = localStorage.getItem(localStorageKey);
  }
  const initialConfirmationDisabled = storedPreference ? JSON.parse(storedPreference) : false;
  const [state, setState] = useState({
    open: false,
    allowConfirmationDisabled,
    confirmationDisabled: initialConfirmationDisabled,
    title: "",
    message: "",
    buttonLabel: "",
    onConfirm: () => {
    }
  });
  const showConfirmation = (title, message, buttonLabel, onConfirm) => {
    let allowConfirmationDisabled2 = false;
    let storedPreference2 = null;
    if (isLocalStorageAvailable()) {
      allowConfirmationDisabled2 = true;
      storedPreference2 = localStorage.getItem(localStorageKey);
    }
    const confirmationDisabled = storedPreference2 ? JSON.parse(storedPreference2) : false;
    if (confirmationDisabled) {
      onConfirm();
    } else {
      setState({ open: true, allowConfirmationDisabled: allowConfirmationDisabled2, confirmationDisabled, title, message, buttonLabel, onConfirm });
    }
  };
  const handleConfirmationDisabledChange = (checked) => {
    setState((prevState) => ({ ...prevState, confirmationDisabled: checked }));
  };
  const handleConfirm = () => {
    state.onConfirm();
    if (isLocalStorageAvailable()) {
      localStorage.setItem(localStorageKey, JSON.stringify(state.confirmationDisabled));
    }
    handleClose();
  };
  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };
  return /* @__PURE__ */ jsxs(ConfirmationDialogContext.Provider, { value: { showConfirmation }, children: [
    children,
    state.open && /* @__PURE__ */ jsx(
      ConfirmationDialog,
      {
        open: state.open,
        title: state.title,
        message: state.message,
        buttonLabel: state.buttonLabel,
        allowConfirmationDisabled: state.allowConfirmationDisabled,
        confirmationDisabled: state.confirmationDisabled,
        onConfirmationDisabledChange: handleConfirmationDisabledChange,
        onCancel: handleClose,
        onConfirm: handleConfirm
      }
    )
  ] });
};
const useConfirmationDialog = () => {
  const { showConfirmation } = useContext(ConfirmationDialogContext);
  return { showConfirmation };
};
const useDeletionConfirmationDialog = () => {
  const { showConfirmation } = useConfirmationDialog();
  return {
    showDeletionConfirmation: (onConfirm) => {
      showConfirmation(
        "Are you sure?",
        "This action will delete the selected element(s).\nYou won't be able to revert this.",
        "Delete",
        onConfirm
      );
    }
  };
};
const ShareRepresentationModal = ({
  editingContextId,
  representationId,
  onClose
}) => {
  const { t } = useTranslation("siriusComponentsCore", { keyPrefix: "share" });
  const refCallback = (node) => {
    if (node !== null) {
      var range = document.createRange();
      range.selectNodeContents(node);
      var selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };
  const { getRepresentationPath } = useContext(RepresentationPathContext);
  const path = window.location.origin + getRepresentationPath(editingContextId, representationId);
  let title = t("link");
  if (navigator.clipboard && document.hasFocus()) {
    navigator.clipboard.writeText(path);
    title += " " + t("copied");
  }
  return /* @__PURE__ */ jsxs(Dialog, { open: true, onClose, "aria-labelledby": "dialog-title", fullWidth: true, children: [
    /* @__PURE__ */ jsx(DialogTitle, { children: title }),
    /* @__PURE__ */ jsx(DialogContent, { ref: refCallback, children: /* @__PURE__ */ jsx(DialogContentText, { children: path }) })
  ] });
};
const getVariantFromMessageLevel = (level) => {
  switch (level) {
    case "ERROR":
      return "error";
    case "INFO":
      return "info";
    case "WARNING":
      return "warning";
    case "SUCCESS":
      return "success";
    default:
      return "default";
  }
};
const useMultiToast = () => {
  const { enqueueSnackbar } = useContext(ToastContext);
  const addMessages = (messages) => messages.map((message) => enqueueSnackbar(message.body, { variant: getVariantFromMessageLevel(message.level) }));
  const addErrorMessage = (message) => addMessages([{ body: message, level: "error" }]);
  return {
    addErrorMessage,
    addMessages
  };
};
const getRepresentationMetadata = gql`
  query getRepresentationMetadata($editingContextId: ID!, $representationIds: [ID!]!) {
    viewer {
      editingContext(editingContextId: $editingContextId) {
        representations(representationIds: $representationIds) {
          edges {
            node {
              id
              label
              kind
            }
          }
        }
      }
    }
  }
`;
const useRepresentationMetadata = (editingContextId, selection) => {
  const { addErrorMessage } = useMultiToast();
  const representationIds = selection.entries.map((entry) => entry.id);
  const variables = {
    editingContextId,
    representationIds
  };
  const { data, error } = useQuery(
    getRepresentationMetadata,
    { variables }
  );
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [error]);
  return { data: data ?? null };
};
const defaultValue = {
  selection: { entries: [] },
  setSelection: () => {
  }
};
const SelectionContext = React.createContext(defaultValue);
const SelectionContextProvider = ({ initialSelection, children }) => {
  const [state, setState] = useState({
    selection: initialSelection ?? { entries: [] }
  });
  const setSelection = useCallback((selection) => {
    setState((prevState) => ({ ...prevState, selection }));
  }, []);
  return /* @__PURE__ */ jsx(SelectionContext.Provider, { value: { selection: state.selection, setSelection }, children });
};
const useSelection = () => {
  const { selection, setSelection } = useContext(SelectionContext);
  return { selection, setSelection };
};
const fallbackTheme = createTheme({
  palette: {
    navigation: {
      leftBackground: "white",
      rightBackground: "white"
    },
    navigationBar: {
      border: "black",
      background: "black"
    },
    selected: "gray"
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: "3.42rem",
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: "0em"
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: "0em"
    },
    h3: {
      fontSize: "2.142rem",
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: "0.3px"
    },
    h4: {
      fontSize: "1.7rem",
      fontWeight: 400,
      lineHeight: 1.235,
      letterSpacing: "0.00735em"
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.334,
      letterSpacing: "0em"
    },
    h6: {
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: "0.0075em"
    },
    subtitle1: {
      fontWeight: 700,
      lineHeight: 1.7,
      letterSpacing: "0.03em"
    },
    subtitle2: {
      fontWeight: 700,
      lineHeight: 2,
      letterSpacing: "0.06em"
    },
    body1: {
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em"
    },
    body2: {
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: "0.01071em"
    }
  }
});
const theme = responsiveFontSizes(fallbackTheme);
const Toast = ({ message, open, onClose }) => {
  return /* @__PURE__ */ jsx(
    Snackbar,
    {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
      },
      open,
      autoHideDuration: 3e3,
      onClose,
      message,
      action: /* @__PURE__ */ jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: onClose, children: /* @__PURE__ */ jsx(CloseIcon, { fontSize: "small" }) }),
      "data-testid": "toast"
    }
  );
};
const isErrorPayload = (payload) => payload.__typename === "ErrorPayload";
const isSuccessPayload = (payload) => payload.__typename === "SuccessPayload";
const useReporting = (result, extractPayload) => {
  const { addErrorMessage, addMessages } = useMultiToast();
  useEffect(() => {
    const { loading, data, error } = result;
    if (!loading) {
      if (error) {
        addErrorMessage(error.message);
      }
      if (data) {
        const payload = extractPayload(data);
        if (isSuccessPayload(payload) || isErrorPayload(payload)) {
          const { messages } = payload;
          if (messages) {
            addMessages(messages);
          }
        }
      }
    }
  }, [result.loading, result.data, result.error]);
};
const useSiteStyles = makeStyles()((theme2) => ({
  leftSite: {
    display: "flex",
    flexDirection: "row"
  },
  rightSite: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  viewSelectorLeft: {
    display: "flex",
    flexDirection: "column",
    background: theme2.palette.navigation.leftBackground
  },
  viewSelectorRight: {
    display: "flex",
    flexDirection: "column",
    background: theme2.palette.navigation.rightBackground
  },
  viewSelectorIconLeft: {
    color: theme2.palette.text.disabled,
    borderLeftStyle: "solid",
    borderLeftSize: "2px",
    borderColor: theme2.palette.navigation.leftBackground,
    borderRadius: 0
  },
  viewSelectorIconRight: {
    color: theme2.palette.text.disabled,
    borderRightStyle: "solid",
    borderRightSize: "2px",
    borderRightColor: theme2.palette.navigation.rightBackground,
    borderRadius: 0
  },
  viewSelectorIconSelectedLeft: {
    color: theme2.palette.primary.main,
    borderLeft: "solid 2px",
    borderRadius: 0
  },
  viewSelectorIconSelectedRight: {
    color: theme2.palette.primary.main,
    borderRight: "solid 2px",
    borderRadius: 0
  },
  viewIcon: {
    padding: theme2.spacing(1)
  },
  view: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    overflow: "auto"
  },
  viewHeader: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: theme2.palette.divider,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  },
  viewHeaderIcon: {
    margin: theme2.spacing(1)
  },
  viewHeaderTitle: {
    marginTop: theme2.spacing(1),
    marginRight: theme2.spacing(1),
    marginBottom: theme2.spacing(1)
  },
  viewContent: {
    flexGrow: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    "& > *": {
      flexGrow: 1
    }
  }
}));
const Site = ({ editingContextId, readOnly, side, expanded, toggleExpansion, contributions }) => {
  const { classes } = useSiteStyles();
  const [isExpanded, setExpanded] = useState(expanded);
  const [selectedViewIndex, setSelectedViewIndex] = useState(0);
  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);
  const viewSelector = /* @__PURE__ */ jsx("div", { className: side === "left" ? classes.viewSelectorLeft : classes.viewSelectorRight, children: contributions.map((contribution2, index) => {
    const { title, icon } = contribution2;
    let iconClassName = side === "left" ? classes.viewSelectorIconLeft : classes.viewSelectorIconRight;
    if (index === selectedViewIndex) {
      iconClassName = side === "left" ? classes.viewSelectorIconSelectedLeft : classes.viewSelectorIconSelectedRight;
    }
    return /* @__PURE__ */ jsx(Tooltip, { enterDelay: 250, title, className: classes.viewIcon, children: /* @__PURE__ */ jsx(
      IconButton,
      {
        className: iconClassName,
        "aria-label": title,
        "data-testid": `viewselector-${title}`,
        onClick: () => {
          if (index === selectedViewIndex) {
            toggleExpansion();
          } else {
            setSelectedViewIndex(index);
            if (!expanded) {
              toggleExpansion();
            }
          }
        },
        children: icon
      }
    ) }, index);
  }) });
  let selectedView = null;
  const contribution = contributions[selectedViewIndex];
  if (isExpanded && contribution) {
    const { title, icon, component: Component } = contribution;
    selectedView = /* @__PURE__ */ jsxs("div", { className: classes.view, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.viewHeader, children: [
        React.cloneElement(icon, { className: classes.viewHeaderIcon }),
        /* @__PURE__ */ jsx(Typography, { className: classes.viewHeaderTitle, children: title })
      ] }),
      /* @__PURE__ */ jsx("div", { className: classes.viewContent, "data-testid": `view-${title}`, children: /* @__PURE__ */ jsx(Component, { editingContextId, readOnly }) })
    ] });
  }
  let classSite = side === "left" ? classes.leftSite : classes.rightSite;
  return /* @__PURE__ */ jsxs("div", { className: classSite, "data-testid": `site-${side}`, children: [
    viewSelector,
    selectedView
  ] });
};
const MIN_PANEL_WIDTH = 42;
const MAIN_AREA_MIN_WIDTH = 100;
const RESIZER_WIDTH = 4;
const usePanelStyles = makeStyles()((theme2) => ({
  panel: {
    display: "grid",
    gridTemplateRows: "minmax(0, 1fr)",
    gridTemplateColumns: "minmax(0, 1fr)"
  },
  verticalResizer: {
    display: "grid",
    width: `${RESIZER_WIDTH}px`,
    cursor: "col-resize",
    "& div": {
      backgroundColor: theme2.palette.divider,
      borderColor: theme2.palette.divider,
      borderRightStyle: "solid",
      borderRightWidth: "1px"
    }
  }
}));
const Panels = ({
  editingContextId,
  readOnly,
  leftContributions,
  rightContributions,
  mainArea,
  leftPanelInitialSize,
  rightPanelInitialSize
}) => {
  const ref = useRef(null);
  const [leftPanelState, setLeftPanelState] = useState({
    isDragging: false,
    initialPosition: 0,
    expanded: true,
    resizablePanelSize: leftPanelInitialSize
  });
  const [rightPanelState, setRightPanelState] = useState({
    isDragging: false,
    initialPosition: 0,
    expanded: true,
    resizablePanelSize: rightPanelInitialSize
  });
  const startResizeLeft = (event) => {
    const initialPosition = event.clientX;
    event.preventDefault();
    setLeftPanelState((prevState) => {
      return {
        isDragging: true,
        initialPosition,
        expanded: prevState.expanded,
        resizablePanelSize: prevState.expanded ? prevState.resizablePanelSize : MIN_PANEL_WIDTH
      };
    });
  };
  const startResizeRight = (event) => {
    const initialPosition = event.clientX;
    event.preventDefault();
    setRightPanelState((prevState) => {
      return {
        isDragging: true,
        initialPosition,
        expanded: prevState.expanded,
        resizablePanelSize: prevState.expanded ? prevState.resizablePanelSize : MIN_PANEL_WIDTH
      };
    });
  };
  const resizePanel = (event) => {
    if (leftPanelState.isDragging) {
      const initialPosition = event.clientX;
      event.preventDefault();
      setLeftPanelState((prevState) => {
        const delta = initialPosition - prevState.initialPosition;
        let resizablePanelSize = Math.max(MIN_PANEL_WIDTH, prevState.resizablePanelSize + delta);
        if (ref.current) {
          resizablePanelSize = Math.min(
            resizablePanelSize,
            ref.current.clientWidth - rightPanelState.resizablePanelSize - 2 * RESIZER_WIDTH - MAIN_AREA_MIN_WIDTH
          );
        }
        return {
          ...prevState,
          initialPosition,
          resizablePanelSize,
          expanded: resizablePanelSize > MIN_PANEL_WIDTH
        };
      });
    }
    if (rightPanelState.isDragging) {
      const initialPosition = event.clientX;
      event.preventDefault();
      setRightPanelState((prevState) => {
        const delta = initialPosition - prevState.initialPosition;
        let resizablePanelSize = Math.max(MIN_PANEL_WIDTH, prevState.resizablePanelSize - delta);
        if (ref.current) {
          resizablePanelSize = Math.min(
            resizablePanelSize,
            ref.current.clientWidth - leftPanelState.resizablePanelSize - 2 * RESIZER_WIDTH - MAIN_AREA_MIN_WIDTH
          );
        }
        return {
          ...prevState,
          initialPosition,
          resizablePanelSize,
          expanded: resizablePanelSize > MIN_PANEL_WIDTH
        };
      });
    }
  };
  const stopResize = () => {
    if (leftPanelState.isDragging) {
      setLeftPanelState((prevState) => {
        return { ...prevState, isDragging: false, initialPosition: 0 };
      });
    }
    if (rightPanelState.isDragging) {
      setRightPanelState((prevState) => {
        return { ...prevState, isDragging: false, initialPosition: 0 };
      });
    }
  };
  const leftWidth = leftPanelState.expanded ? leftPanelState.resizablePanelSize : MIN_PANEL_WIDTH;
  const rightWidth = rightPanelState.expanded ? rightPanelState.resizablePanelSize : MIN_PANEL_WIDTH;
  let style = {
    display: "grid",
    gridTemplateRows: "minmax(0, 1fr)",
    gridTemplateColumns: `${leftWidth}px min-content minmax(0, 1fr) min-content ${rightWidth}px`
  };
  const { classes: styles } = usePanelStyles();
  return /* @__PURE__ */ jsxs("div", { style, onMouseMove: resizePanel, onMouseUp: stopResize, onMouseLeave: stopResize, ref, children: [
    /* @__PURE__ */ jsx("div", { className: styles.panel, children: /* @__PURE__ */ jsx(
      Site,
      {
        editingContextId,
        readOnly,
        side: "left",
        expanded: leftPanelState.expanded,
        toggleExpansion: () => {
          setLeftPanelState((prevState) => {
            const newExpanded = !prevState.expanded;
            let newWidth = prevState.resizablePanelSize;
            if (newExpanded && newWidth === MIN_PANEL_WIDTH) {
              newWidth = leftPanelInitialSize;
            }
            return {
              ...prevState,
              expanded: !prevState.expanded,
              resizablePanelSize: newWidth
            };
          });
        },
        contributions: leftContributions
      }
    ) }),
    /* @__PURE__ */ jsx("div", { className: styles.verticalResizer, onMouseDown: startResizeLeft, "data-testid": "left-resizer", children: /* @__PURE__ */ jsx("div", {}) }),
    /* @__PURE__ */ jsx("div", { className: styles.panel, children: mainArea }),
    /* @__PURE__ */ jsx("div", { className: styles.verticalResizer, onMouseDown: startResizeRight, "data-testid": "right-resizer", children: /* @__PURE__ */ jsx("div", {}) }),
    /* @__PURE__ */ jsx("div", { className: styles.panel, children: /* @__PURE__ */ jsx(
      Site,
      {
        editingContextId,
        readOnly,
        side: "right",
        expanded: rightPanelState.expanded,
        toggleExpansion: () => {
          setRightPanelState((prevState) => {
            const newExpanded = !prevState.expanded;
            let newWidth = prevState.resizablePanelSize;
            if (newExpanded && newWidth === MIN_PANEL_WIDTH) {
              newWidth = rightPanelInitialSize;
            }
            return {
              ...prevState,
              expanded: newExpanded,
              resizablePanelSize: newWidth
            };
          });
        },
        contributions: rightContributions
      }
    ) })
  ] });
};
const useRepresentationNavigationStyles = makeStyles()((theme2) => ({
  tabsRoot: {
    minHeight: theme2.spacing(4),
    borderBottomColor: theme2.palette.divider,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid"
  },
  tabRoot: {
    minHeight: theme2.spacing(4),
    textTransform: "none"
  },
  tabLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "inherit"
  },
  tabLabelText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  tabCloseIcon: {
    marginLeft: theme2.spacing(1)
  }
}));
const a11yProps = (id) => {
  return {
    id: `simple-tab-${id}`,
    "aria-controls": `simple-tabpanel-${id}`
  };
};
const RepresentationNavigation = ({
  representations,
  displayedRepresentation,
  onRepresentationClick,
  onClose
}) => {
  const { classes } = useRepresentationNavigationStyles();
  const onChange = (_event, value2) => {
    const representationSelected = representations.find((representation) => representation.id === value2);
    if (representationSelected) {
      const { id, label, kind } = representationSelected;
      const representation = {
        id,
        label,
        kind
      };
      onRepresentationClick(representation);
    }
  };
  const onRepresentationClose = (event, representation) => {
    event.stopPropagation();
    onClose(representation);
  };
  return /* @__PURE__ */ jsx(
    Tabs,
    {
      classes: { root: classes.tabsRoot },
      value: displayedRepresentation.id,
      onChange,
      variant: "scrollable",
      scrollButtons: true,
      textColor: "primary",
      indicatorColor: "primary",
      children: representations.map((representation) => {
        return /* @__PURE__ */ createElement(
          Tab,
          {
            ...a11yProps(representation.id),
            classes: { root: classes.tabRoot },
            value: representation.id,
            label: /* @__PURE__ */ jsxs("div", { className: classes.tabLabel, children: [
              /* @__PURE__ */ jsx("div", { className: classes.tabLabelText, children: representation.label }),
              /* @__PURE__ */ jsx(
                CloseIcon,
                {
                  className: classes.tabCloseIcon,
                  fontSize: "small",
                  onClick: (event) => onRepresentationClose(event, representation),
                  "data-testid": `close-representation-tab-${representation.label}`
                }
              )
            ] }),
            key: representation.id,
            "data-testid": `representation-tab-${representation.label}`,
            "data-testselected": representation.id === displayedRepresentation.id,
            "data-representationid": representation.id
          }
        );
      })
    }
  );
};
const workbenchMainAreaExtensionPoint = {
  identifier: "workbench#mainArea",
  FallbackComponent: () => null
};
const workbenchViewContributionExtensionPoint = {
  identifier: "workbench#viewContribution",
  fallback: []
};
const representationFactoryExtensionPoint = {
  identifier: "workbench#representationFactory",
  fallback: []
};
const isRepresentationRenamedEventPayload = (payload) => payload.__typename === "RepresentationRenamedEventPayload";
const workbenchMachine = Machine(
  {
    type: "parallel",
    context: {
      id: crypto.randomUUID(),
      representations: [],
      displayedRepresentation: null,
      message: null
    },
    states: {
      toast: {
        initial: "hidden",
        states: {
          hidden: {
            on: {
              SHOW_TOAST: {
                target: "visible",
                actions: "setMessage"
              }
            }
          },
          visible: {
            on: {
              HIDE_TOAST: {
                target: "hidden",
                actions: "clearMessage"
              }
            }
          }
        }
      },
      workbench: {
        initial: "initial",
        states: {
          initial: {
            on: {
              UPDATE_SELECTED_REPRESENTATION: {
                target: "initial",
                actions: "updateSelectedRepresentation"
              },
              HIDE_REPRESENTATION: {
                target: "initial",
                actions: "hideRepresentation"
              },
              HANDLE_SUBSCRIPTION_RESULT: {
                target: "initial",
                actions: "handleSubscriptionResult"
              },
              HANDLE_COMPLETE: {
                target: "complete"
              }
            }
          },
          complete: {
            type: "final"
          }
        }
      }
    }
  },
  {
    actions: {
      updateSelectedRepresentation: assign((context, event) => {
        const { representations: selectedRepresentations } = event;
        if (selectedRepresentations.length > 0) {
          const displayedRepresentation = selectedRepresentations[0];
          const representations = [...context.representations];
          const newRepresentations = selectedRepresentations.filter(
            (selectedRepresentation) => !representations.find((representation) => selectedRepresentation.id === representation.id)
          );
          const newSelectedRepresentations = [...representations, ...newRepresentations];
          return { displayedRepresentation, representations: newSelectedRepresentations };
        }
        return {};
      }),
      hideRepresentation: assign((context, event) => {
        const { representation: representationToHide } = event;
        const previousIndex = context.representations.findIndex(
          (representation) => context.displayedRepresentation && representation.id === context.displayedRepresentation.id
        );
        const newRepresentations = context.representations.filter(
          (representation) => representation.id !== representationToHide.id
        );
        if (newRepresentations.length === 0) {
          return { displayedRepresentation: null, representations: [] };
        } else {
          const newIndex = newRepresentations.findIndex(
            (representation) => context.displayedRepresentation && representation.id === context.displayedRepresentation.id
          );
          if (newIndex !== -1) {
            return { representations: newRepresentations };
          } else if (newRepresentations.length === previousIndex) {
            const displayedRepresentation = newRepresentations[previousIndex - 1];
            return { displayedRepresentation, representations: newRepresentations };
          } else {
            const displayedRepresentation = newRepresentations[previousIndex];
            return { displayedRepresentation, representations: newRepresentations };
          }
        }
      }),
      handleSubscriptionResult: assign((context, event) => {
        const { result } = event;
        const { data } = result;
        if (data && isRepresentationRenamedEventPayload(data.editingContextEvent)) {
          const { representationId, newLabel } = data.editingContextEvent;
          const representations = context.representations;
          for (var i = 0; i < representations.length; i++) {
            const representation = representations[i];
            if (!!representation && representation.id === representationId) {
              representation.label = newLabel;
            }
          }
          return {
            representations
          };
        }
        return {};
      }),
      setMessage: assign((_, event) => {
        const { message } = event;
        return { message };
      }),
      clearMessage: assign((_) => {
        return { message: null };
      })
    }
  }
);
const editingContextEventSubscription = gql`
  subscription editingContextEvent($input: EditingContextEventInput!) {
    editingContextEvent(input: $input) {
      __typename
      ... on RepresentationRenamedEventPayload {
        representationId
        newLabel
      }
    }
  }
`;
const useWorkbenchStyles = makeStyles()(() => ({
  main: {
    display: "grid",
    gridTemplateRows: "minmax(0, 1fr)",
    gridTemplateColumns: "1fr"
  },
  representationArea: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content minmax(0, 1fr)",
    overflow: "auto"
  }
}));
const Workbench = ({
  editingContextId,
  initialRepresentationSelected,
  onRepresentationSelected,
  readOnly
}) => {
  const { classes } = useWorkbenchStyles();
  const [{ value: value2, context }, dispatch] = useMachine(workbenchMachine, {
    context: {
      displayedRepresentation: initialRepresentationSelected,
      representations: initialRepresentationSelected ? [initialRepresentationSelected] : []
    }
  });
  const { toast } = value2;
  const { id, representations, displayedRepresentation, message } = context;
  const { selection, setSelection } = useSelection();
  const { data } = useRepresentationMetadata(editingContextId, selection);
  const { data: representationFactories } = useData(representationFactoryExtensionPoint);
  const { error } = useSubscription(editingContextEventSubscription, {
    variables: {
      input: {
        id,
        editingContextId
      }
    },
    fetchPolicy: "no-cache",
    onData: ({ data: data2 }) => {
      const handleDataEvent = {
        type: "HANDLE_SUBSCRIPTION_RESULT",
        result: data2
      };
      dispatch(handleDataEvent);
    },
    onComplete: () => {
      const completeEvent = { type: "HANDLE_COMPLETE" };
      dispatch(completeEvent);
    }
  });
  useEffect(() => {
    if (error) {
      const { message: message2 } = error;
      const showToastEvent = { type: "SHOW_TOAST", message: message2 };
      dispatch(showToastEvent);
    }
  }, [error, dispatch]);
  useEffect(() => {
    const updateSelectedRepresentation = {
      type: "UPDATE_SELECTED_REPRESENTATION",
      representations: (data == null ? void 0 : data.viewer.editingContext.representations.edges.map((edge) => edge.node)) ?? []
    };
    dispatch(updateSelectedRepresentation);
  }, [data, selection, dispatch]);
  const onRepresentationClick = (representation) => {
    setSelection({ entries: [{ id: representation.id, kind: representation.kind }] });
  };
  const onClose = (representation) => {
    const hideRepresentationEvent = { type: "HIDE_REPRESENTATION", representation };
    dispatch(hideRepresentationEvent);
  };
  useEffect(() => {
    if (displayedRepresentation && displayedRepresentation.id !== (initialRepresentationSelected == null ? void 0 : initialRepresentationSelected.id)) {
      onRepresentationSelected(displayedRepresentation);
    } else if (displayedRepresentation === null && initialRepresentationSelected) {
      onRepresentationSelected(null);
    }
  }, [onRepresentationSelected, initialRepresentationSelected, displayedRepresentation]);
  const workbenchViewLeftSideContributions = [];
  const workbenchViewRightSideContributions = [];
  const { data: workbenchViewContributions } = useData(workbenchViewContributionExtensionPoint);
  for (const workbenchViewContribution of workbenchViewContributions) {
    if (workbenchViewContribution.side === "left") {
      workbenchViewLeftSideContributions.push(workbenchViewContribution);
    } else if (workbenchViewContribution.side === "right") {
      workbenchViewRightSideContributions.push(workbenchViewContribution);
    }
  }
  const { Component: MainComponent } = useComponent(workbenchMainAreaExtensionPoint);
  let main = /* @__PURE__ */ jsx(MainComponent, { editingContextId, readOnly });
  if (displayedRepresentation) {
    const RepresentationComponent = representationFactories.map((representationFactory) => representationFactory(displayedRepresentation)).find((component) => component != null);
    const props = {
      editingContextId,
      readOnly,
      representationId: displayedRepresentation.id
    };
    if (RepresentationComponent) {
      main = /* @__PURE__ */ jsxs("div", { className: classes.representationArea, "data-testid": "representation-area", children: [
        /* @__PURE__ */ jsx(
          RepresentationNavigation,
          {
            representations,
            displayedRepresentation,
            onRepresentationClick,
            onClose
          }
        ),
        /* @__PURE__ */ jsx(RepresentationComponent, { ...props }, `${editingContextId}#${displayedRepresentation.id}`)
      ] });
    }
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Panels,
      {
        editingContextId,
        readOnly,
        leftContributions: workbenchViewLeftSideContributions,
        leftPanelInitialSize: 400,
        rightContributions: workbenchViewRightSideContributions,
        rightPanelInitialSize: 450,
        mainArea: main
      }
    ),
    /* @__PURE__ */ jsx(
      Toast,
      {
        message: message ?? "",
        open: toast === "visible",
        onClose: () => dispatch({ type: "HIDE_TOAST" })
      }
    )
  ] });
};
export {
  ConfirmationDialogContext,
  ConfirmationDialogContextProvider,
  DRAG_SOURCES_TYPE,
  ExtensionProvider,
  ExtensionRegistry,
  IconOverlay,
  Panels,
  RepresentationPathContext,
  SelectionContext,
  SelectionContextProvider,
  ServerContext,
  ShareRepresentationModal,
  Toast,
  ToastContext,
  Workbench,
  getCSSColor,
  isErrorPayload,
  isSuccessPayload,
  representationFactoryExtensionPoint,
  en as siriusComponentsCoreEn,
  ru as siriusComponentsCoreRu,
  theme,
  useComponent,
  useComponents,
  useConfirmationDialog,
  useData,
  useDeletionConfirmationDialog,
  useMultiToast,
  useReporting,
  useRepresentationMetadata,
  useSelection,
  workbenchMainAreaExtensionPoint,
  workbenchViewContributionExtensionPoint
};
