import { jsx } from "react/jsx-runtime";
import * as d3 from "d3";
import { useRef, useEffect } from "react";
const getFontWeight = (style) => {
  if (style == null ? void 0 : style.bold) {
    return "bold";
  }
  return "normal";
};
const getFontSize = (style) => {
  return (style == null ? void 0 : style.fontSize) ?? 14;
};
const getFontStyle = (style) => {
  if (style == null ? void 0 : style.italic) {
    return "italic";
  }
  return "normal";
};
const getTextDecoration = (style) => {
  let decoration = "";
  if (style == null ? void 0 : style.strikeThrough) {
    decoration = decoration + "line-through";
  }
  if (style == null ? void 0 : style.underline) {
    let separator = decoration.length > 0 ? " " : "";
    decoration = decoration + separator + "underline";
  }
  return decoration.length > 0 ? decoration : "none";
};
const marginTop = 30;
const marginBottom = 30;
const marginRight = 0;
const marginLeft = 50;
const xPadding = 0.1;
const yType = d3.scaleLinear;
const yFormat = "f";
const x = (d) => d.key;
const y = (d) => d.value;
const BarChart = ({ chart }) => {
  const d3Container = useRef(null);
  useEffect(() => {
    const width = chart.width;
    const height = chart.height;
    const xRange = [marginLeft, width - marginRight];
    const yRange = [height - marginBottom, marginTop];
    if (d3Container.current && chart) {
      const {
        entries: data,
        metadata: { label: yLabel },
        style
      } = chart;
      const fontSize = getFontSize(style);
      const fontStyle = getFontStyle(style);
      const fontWeight = getFontWeight(style);
      const textDecoration = getTextDecoration(style);
      let color;
      if ((style == null ? void 0 : style.barsColor) != null && (style == null ? void 0 : style.barsColor.length) > 0) {
        color = style == null ? void 0 : style.barsColor;
      } else {
        color = "steelblue";
      }
      const X = d3.map(data, x);
      const Y = d3.map(data, y);
      const xDomain = new d3.InternSet(X);
      const yDomain = [0, d3.max(Y)];
      const I = d3.range(X.length).filter((i) => xDomain.has(X[i]));
      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = yType(yDomain, yRange);
      const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);
      const yAxis = d3.axisLeft(yScale).ticks(height / 40, yFormat);
      const formatValue = yScale.tickFormat(100, yFormat);
      const title = (i) => `${X[i]}
${formatValue(Y[i])}`;
      const selection = d3.select(d3Container.current);
      selection.selectAll("*").remove();
      const svg = selection.attr("viewBox", [0, 0, width, height]).attr("text-decoration", textDecoration).attr("pointer-events", "none");
      svg.append("g").attr("transform", `translate(${marginLeft},0)`).attr("style", `font-size:${fontSize}; font-style: ${fontStyle}; font-weight: ${fontWeight}`).call(yAxis).call((g) => g.select(".domain").remove()).call(
        (g) => g.selectAll(".tick line").clone().attr("x2", width - marginLeft - marginRight).attr("stroke-opacity", 0.1)
      ).call(
        (g) => g.append("text").attr("x", -marginLeft).attr("y", 10).attr("fill", "currentColor").attr("text-anchor", "start").text(yLabel)
      );
      const bar = svg.append("g").attr("fill", color).selectAll("rect").data(I).join("rect").attr("x", (i) => xScale(X[i])).attr("y", (i) => yScale(Y[i])).attr("height", (i) => yScale(0) - yScale(Y[i])).attr("width", xScale.bandwidth());
      if (title) {
        bar.append("title").text(title);
      }
      svg.append("g").attr("transform", `translate(0,${height - marginBottom})`).attr("style", `font-size:${fontSize}; font-style: ${fontStyle}; font-weight: ${fontWeight}`).call(xAxis);
    }
  }, [chart, d3Container]);
  return /* @__PURE__ */ jsx("svg", { ref: d3Container, width: chart.width, height: chart.height });
};
const name = (d) => d.key;
const value = (d) => d.value;
const innerRadius = 0;
const format = ",";
const strokeLinejoin = "round";
const PieChart = ({ width, height, chart }) => {
  const d3Container = useRef(null);
  useEffect(() => {
    const { entries: data, style } = chart;
    const outerRadius = Math.min(width, height) / 2;
    const labelRadius = innerRadius * 0.2 + outerRadius * 0.8;
    const stroke = getStrokeColorValue(style);
    const padAngle = stroke === "none" ? 1 / outerRadius : 0;
    const strokeWidth = getStrokeWidth(style);
    const fontWeight = getFontWeight(style);
    const fontStyle = getFontStyle(style);
    const textDecoration = getTextDecoration(style);
    const fontSize = getFontSize(style);
    const N = d3.map(data, name);
    const V = d3.map(data, value);
    const I = d3.range(N.length).filter((i) => !isNaN(V[i]));
    const names = new d3.InternSet(N);
    let colors = style == null ? void 0 : style.colors;
    if (colors === void 0 || colors.length === 0) {
      colors = d3.quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);
    }
    const color = d3.scaleOrdinal(names, colors);
    const formatValue = d3.format(format);
    const title = (i) => `${N[i]}
${formatValue(V[i])}`;
    const label = (d) => {
      const lines = `${title(d.data)}`.split(/\n/);
      let value2 = d.endAngle - d.startAngle > 0.25 ? lines : lines.slice(0, 1);
      return value2.map((v) => {
        if (v.length > 13) {
          return v.substring(0, 10).concat("...");
        }
        return v;
      });
    };
    const arcs = d3.pie().padAngle(padAngle).sort(null).value((i) => V[i])(I);
    const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
    const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);
    const selection = d3.select(d3Container.current);
    selection.selectAll("*").remove();
    const svg = selection.attr("width", width).attr("height", height).attr("viewBox", [-width / 2, -height / 2, width, height]).attr("style", "max-width: 100%; height: auto; height: intrinsic;").attr("pointer-events", "none");
    svg.append("g").attr("stroke", stroke).attr("stroke-width", strokeWidth).attr("stroke-linejoin", strokeLinejoin).selectAll("path").data(arcs).join("path").attr("fill", (d) => color(N[d.data])).attr("d", arc).append("title").text((d) => title(d.data));
    svg.append("g").attr("font-family", "sans-serif").attr("font-size", fontSize).attr("font-style", fontStyle).attr("text-decoration", textDecoration).attr("font-weight", fontWeight).attr("text-anchor", "middle").selectAll("text").data(arcs).join("text").attr("transform", (d) => `translate(${arcLabel.centroid(d)})`).selectAll("tspan").data(label).join("tspan").attr("x", 0).attr("y", (_, i) => `${i * 1.1}em`).attr("font-weight", (_, i) => i ? null : "bold").text((d) => d);
    Object.assign(svg.node(), { scales: { color } });
  }, [width, height, chart, d3Container]);
  const getStrokeColorValue = (style) => {
    return (style == null ? void 0 : style.strokeColor) ?? "white";
  };
  const getStrokeWidth = (style) => {
    return (style == null ? void 0 : style.strokeWidth) ?? 1;
  };
  return /* @__PURE__ */ jsx("svg", { ref: d3Container });
};
export {
  BarChart,
  PieChart
};
